import React, { Component, useState } from 'react'
import { SearchBarDetailGenericAdvancedSearch } from '../../SearchBarGeneric'
import { connect, useSelector } from 'react-redux';

const ContainerUserDocumentSearch = (props) => {
    const [state, setState ] = useState ({
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
    })
  

  const viewList = useSelector((state) => state.userDocumentReducer.viewList)
  const viewGrid = useSelector((state) => state.userDocumentReducer.viewGrid)
    return (
      <SearchBarDetailGenericAdvancedSearch searchBarState={state.searchBarState} popUpOrderState={state.popUpOrderState}
      popUpFilterState={state.popUpFilterState} typeButton={'attachments'} viewGrid={viewGrid} showAZ={props.showAZ} 
      textPlaceHolder={props.textPlaceHolder} isVisible={props.isVisible} handleSelectAll={props.handleSelectAll} 
      selectCheckBox={props.selectCheckBox} handleClickDownload={props.handleClickDownload} 
      isVisibleCheck={props.isVisibleCheck} checkedAll={props.checkedAll} loadingDownload ={props.loadingDownload} loader ={props.loader}/>
    )
  }


export default ContainerUserDocumentSearch