import React, { Component, useRef } from 'react'
import { Popup } from "semantic-ui-react"
import styled from "styled-components";

const AttachDivIcon = styled.div`
  width: 100%;
`

const AttachButtonStyle = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px; 
  color: ${props => (props.disabled ? '#B0B0B0' : '#22C1D0')};  
  text-align: center;
  padding-inline: 24px;
  padding-block: 14px;
  border: 1px solid ${props => (props.disabled ? '#B0B0B0' : '#22C1D0')};  
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')}; 
  opacity: ${props => (props.disabled ? 0.5 : 1)};  
  
  @media only screen and (max-width: 1200px) {
    width: 180px;
  }
  
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-inline: unset;
  }
`;


export const InputAttachButton = (props) => {

    const inputRef = useRef(null);

    const onInputClick = (event) => {
        event.target.value = ''
    }

    const { onSelectFile, accept, content, className, id, popUp, disabled ,text ='',marginL=''} = props
    return (
        <>
                <Popup
                    content={popUp}
                    mouseEnterDelay={500}
                    position='bottom left'
                    on='hover'
                    inverted
                    trigger={
                        <AttachButtonStyle
                            className={className}
                            content={content}
                            onClick={(e) => {
                                e.preventDefault()
                                inputRef.current.click()
                            }}
                            disabled={disabled}
                            style={marginL !== ''?{marginLeft:`${marginL}`}:{}}
                        >
                            <AttachDivIcon>
                                {text !=='' ?text :'Adjuntar vinculación' }
                            </AttachDivIcon>
                        </AttachButtonStyle>
                    }
                />
                <input
                    ref={inputRef}
                    id={id}
                    type="file"
                    multiple
                    hidden
                    accept={accept}
                    onChange={(e) => onSelectFile(e)}
                    required
                    onClick={onInputClick}
                />
            </>
    )
}

export default InputAttachButton
