import React from 'react'
import {MainSegment} from '../../../components/Sengment/MainSegment'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/sent/leftArrow.svg'
import WarningModal from '../../../components/ModalGeneralForms/WarningModal'
import { useNavigate } from "react-router-dom-v5-compat"
import FormEditProvider from '../../../components/FormProvider/FormEditProvider'
import {warningFormModal} from '../../../actions/modalActions'
import PropTypes from "prop-types";

const Divider = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll !important;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
` 
const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin:10px;
  }
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
`

const EditProviders = (props) => {

  const dispatch = useDispatch()
  const {activeForm} = useSelector(state => state.providerReducer)
  const navigate = useNavigate()

  const handleClick = () => {
    if (activeForm === true) {
      dispatch(warningFormModal({type: 'warningLeaveUpdate'}))
    } else {
      navigate(-1)
    }
  }

  return (
    <Divider>
      <MainSegment>
        <Container>
          <Arrow>
            <LeftArrow
              onClick={handleClick}
            >
              <LeftArrowIcon/>
              <WarningModal type='warningLeaveUpdate'/>
            </LeftArrow>
          </Arrow>
          <TitleContainer>
            <Title>Editar operador</Title>
          </TitleContainer>
        </Container>
        <FormEditProvider/>
       
      </MainSegment>
    </Divider>
  )
}
EditProviders.propTypes = {
  providerActiveEdit: PropTypes.object,
}

export default EditProviders