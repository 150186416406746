import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import {ReactComponent as AzIcon} from '../../../assets/icons/write/previewList/AZicon.svg'
import {ReactComponent as FilterIcon} from '../../../assets/icons/write/previewList/Filtericon.svg'
import {ReactComponent as SearchIcon} from '../../../assets/icons/write/previewList/Searchicon.svg'
import {ReactComponent as StatusNotificationIcon} from '../../../assets/icons/write/previewList/StatusNotificationicon.svg'
import {ReactComponent as NotificationDisabled} from '../../../assets/icons/write/previewList/NotificationDisabled.svg'
import {ReactComponent as NotificationMenu} from '../../../assets/icons/write/previewList/NotificationMenu.svg'
import _ from "lodash";
import {changeDatePreview} from "../../../actions/writerActions";

const HeaderWsp = styled.div`
  background: linear-gradient(180deg, #0A4A83 0%, #003057 100%, #003057 100%);
  max-width: 313px;
  max-height: 48px;
  width:100%;
  height: 48px;
  border-radius: 3px 3px 0px 0px;
  padding: 16px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  letter-spacing: 0px;
  text-align: center;

`
const NotificationDisabledImg= styled(NotificationDisabled)`
  width: 100% !important;
  height: 100% !important;
`
const NotificationMenuImg= styled(NotificationMenu)`
  height: 100%;
`

export  const PreviewList = () => {

  const dispatch = useDispatch()
  const form = useSelector(state=> state.writerReducer.notificationForm)
  const companyName = useSelector(state=> state.authReducer.user.companyID.name)
  const subject = useSelector(state=> state.writerReducer.notificationForm.subject)
  const text = useSelector(state=> state.writerReducer.notificationForm.textEditor)

  const [state, setState] = useState({
    dateHour: ''
  })

  const [formInitial, setFormInitial]= useState(form)

  useEffect(()=>{
    setState((prevState)=>({...prevState, dateHour:hourFormat()}))
  },[])

  useEffect(()=>{
    if(formInitial.subject !== form.subject){
      if(form.subject && form.subject.length !== 0) {
        let date = new Date();
        setState({dateHour:hourFormat()})
        /*await*/ dispatch(changeDatePreview(date.toLocaleString("en-AU").replace(",", " - ")))
      }
    }
  },[form])


  const hourFormat = () =>{
    const time = new Date();
    return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase()
  }

    return (
      <>
        <div className='contents-previewList'>
          <HeaderWsp>
            Notificaciones
          </HeaderWsp>
          <div className='content-filters'>
            <AzIcon/>
            <FilterIcon/>
            <div className='content-input-search'>
              Buscar en mis notificaciones
              <SearchIcon/>
            </div>
          </div>
          <div className='content-notification-status'>
            <StatusNotificationIcon/>
          </div>
          <div className='svg-and-contents-read-confirmation-pro'>
            <div className='container-notification-message'>
              <div className="title-message-notification">
                {companyName}
              </div>
              <div className='hour-message-notification'>
                {state.dateHour}
              </div>
              <div className="subject-message-notification">
                {subject}
              </div>
              <div className='hola'>
              <div className="text-message-notification" 
                dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(text)
                }}/>
              </div>
            </div>
          </div>
          <div className='content-notification-disabled'>
            <NotificationDisabledImg/>
          </div>
          <div className='content-notification-menu'>
            <NotificationMenuImg/>
          </div>
        </div>
      </>
    )
}

export default PreviewList