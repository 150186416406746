import React from 'react'
import moment from 'moment'
import { useDispatch, connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import '../../index.css'
import { changeDocumentCurrent } from '../../../../actions/seeAttachmentAction'
import { CellIcon, Cell, CellPending } from '../../../../components/Table/CellComponent'
import { actionClearFilter } from '../../../../actions/filterDetailActions'
import { useNavigate } from 'react-router-dom-v5-compat'

const Row = styled(Table.Row)`
  cursor: pointer !important;
  font-size: 15px;
  height: 50px !important;
  padding-right: 13px;
  @media only screenand (max-width: 1500px) {
    padding-right: 0px;
  }
  @media only screen and (max-width: 768px) {
    height: 55px !important;
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #979E9F !important;
    padding: 0.5em 0em!important;
    box-sizing: content-box;
  }
`

const RowTableUserDocuments = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const redirectToAttachments = () => {
    props.changeDocumentCurrent(props.data.documentNumberRecipient)
    navigate({ pathname: '/userDocuments-detail/' + props.data.documentNumberRecipient })
    dispatch(actionClearFilter())
  }

  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')
  return (
    <Row onClick={redirectToAttachments}>
      <CellIcon width={10} columnName={props.headerTable[0].name} text={`${props.data.nameElement}`} />
      <Cell width={3} columnName={props.headerTable[1].name} text={formatDate(props.data.date)} />
      <CellPending width={2} status={props.data.status} />
    </Row>
  )
};

RowTableUserDocuments.propTypes = {
  data: PropTypes.object.isRequired,
  changeDocumentCurrent: PropTypes.func,
};

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  changeDocumentCurrent,
}

export default connect(mapStateToProps, mapDispatchToProps)(RowTableUserDocuments)