import React, {  useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closeDateModal } from "../../../actions/modalActions";
import FormFilter from "./FormFilter/FormFilter";
import DateFilter from "./DateFilter/DateFilter";
import { actionSearchAdvanced } from "../../../actions/filterActions";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import "./style.css";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const TextoHide = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 0em;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
const TextoHide2 = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  line-height: 24px;
  margin-bottom: 16px !important;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
`;

const ButtonDeleteFilter = styled.button`
  color: #22c1d0;
  border: none;
  background: #ffffff;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 10px;
    width: 47%;
  }
`;
const ButtonStartFilter = styled.button`
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid #22c1d0;
  background: #22c1d0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 2%;
    width: 47%;
  }
`;
const ModalDate = styled.div`
  width: 100%;
  padding-top: 0px !important;
  @media only screen and (max-width: 1024px) {
    width: 100% !important;
    padding-left: 0 !important;
  }
`;

const ModalSent = styled.div`
  width: 80% !important;
  max-width: 854px;
  min-height: 575px;
  height: auto;
  position: relative;
  padding: 20px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  margin-top: 4rem;
  margin-left: 17rem;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: -webkit-fill-available;
    margin: -16px 0 0 0 !important;
  }
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-center;
  justify-content: center;
  align-items: center;
  z-index: 4000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25.76px;
  right: 24px;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ModalFilterDate = (props) => {
 

  const dispatch = useDispatch();

  const dateModal = useSelector(state => state.modalReducer.dateModal)

  const stateSearch = useSelector(
    (state) => state.filterReducer.searchAdvanced.notificationState
  );

  const dateSearch = useSelector(state => state.filterReducer.searchAdvanced.date)
  const tabFilter = useSelector(state => state.filterReducer.tabFilter)
  const modalState = useSelector(state => state.modalReducer.dateModal)


  const [state, setState] = useState({
    attachedFile: false,
    notificationState: "",
    date: {},
    dropValue: "",
  });

  useEffect(() => {
    setState((prevstate) => ({ ...prevstate, notificationState: stateSearch }));
  }, [stateSearch]);

  const handleOnChange = (e, { value }) => {
    setState(
      (prevState) => ({
        ...prevState,
        notificationState: value,
      }),
      () => {}
    );
  };

  const toggleCheckbox = (e) => {
    let name = e.target.id;
    setState((prevState) => ({
      ...prevState,
      [name]: !state[name],
    }));
  };

  const reset = () => {
    setState({
      attachedFile: false,
      notificationState: "",
      date: {},
    });
  };

  const setDateState = (date) => {
    setState((prevState) => ({ ...prevState, date: date }));
  };

  const orderTranslator = (order) => {
    const options = {
      masReciente: { notificationDate: -1 },
      masAntiguo: { notificationDate: 1 },
      "A-Z": { notificationDate: -1 },
      "Z-A": { notificationDate: 1 },
    };
    return options[order];
  };

  const makeBodyRequest = () => {
    const body = {
      pag: 1,
      sort: "masReciente",
      starred: false,
      searchAdvanced: {
        date: state.date,
        notificationState: state.notificationState,
        attachedFile: state.attachedFile,
      },
    };
    dispatch(actionSearchAdvanced(body));
    dispatch(closeDateModal());
  };

  const makeBodyRequestDeleteFilter = () => {
    const body = {
      pag: 1,
      sort: "masReciente",
      starred: false,
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: "",
        },
        notificationState: "",
        attachedFile: false,
      },
    };
    dispatch(actionSearchAdvanced(body));
    dispatch(closeDateModal());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    makeBodyRequest();
  };

  const handleDeleteFilter = (e) => {
    e.preventDefault();
    makeBodyRequestDeleteFilter();
    reset();
  };

  const validClose = () => {
    setState((prevState) => ({
      ...prevState,
      notificationState: stateSearch,
    }));
    return dispatch(closeDateModal());
  };

  return (
    <>
      {modalState && (
        <Overlay>
          <ModalSent id={"modalSent"}>
            <ModalDate>
              <Divider>
                <TextoHide>Búsqueda avanzada</TextoHide>
                <Texto>Mostrar</Texto>
                <CloseButton onClick={validClose}>
                  <Close />
                </CloseButton>
              </Divider>
              <TextoHide2>Mostrar</TextoHide2>
              <div>
                <FormFilter
                  state={state}
                  handleOnChange={handleOnChange}
                  handleSubmit={handleSubmit}
                  toggleCheckbox={toggleCheckbox}
                  tabFilter={tabFilter}
                />
                <Texto2>Período de tiempo</Texto2>
              </div>
              <DateFilter setDateState={setDateState} />
            </ModalDate>
            <Divider2>
              <ButtonDeleteFilter onClick={handleDeleteFilter}>
                Borrar filtros
              </ButtonDeleteFilter>
              <ButtonStartFilter onClick={handleSubmit}>
                Aplicar filtro
              </ButtonStartFilter>
            </Divider2>
          </ModalSent>
        </Overlay>
      )}
    </>
  );
};

export default ModalFilterDate;
