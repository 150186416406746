import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { changeStateSearchReportsModal } from "../../../actions/modalActions";
import FormFilterReports from "../AdvancedSearch/FormFilter/FormFilterReports";
import DateFilter from "../AdvancedSearch/DateFilter/DateFilter";
import { actionSearchAdvanced } from "../../../actions/filterActions";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";

import { CHANGE_STATE_REPORTS_SEARCH_MODAL } from "../../../actions/actionsUtilities/types";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height:24px;
  margin-bottom: 20px !important;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const TextoHide = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 0em;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
const TextoHide2 = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
`;

const ButtonDeleteFilter = styled.button`
  color: #22c1d0;
  border: none;
  background: #ffffff;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 10px;
    width: 47%;
  }
`;
const ButtonStartFilter = styled.button`
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid #22c1d0;
  background: #22c1d0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 2%;
    width: 47%;
  }
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-center; 
  justify-content: center;
  align-items: center;    
  z-index: 3000;
`;

const ModalSent = styled.div`
  width: 80% !important;
  max-width: 854px;
  max-height: 633px;
  min-height: 575px;
  height: 80%;
  position: relative;
  padding: 20px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  margin-top: 10rem;
  margin-right: 17rem;
  align-items:center;
  @media only screen and (max-height: 800px) {
  margin-top: 0;
  }
  @media only screen and (max-width: 1200px) {
    margin-right: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: 100%;
    margin: -16px 0 0 0 !important;
  }
`;
const ModalDate = styled.div`
  width:100%;
  @media only screen and (max-width: 1024px) {
    width:100% !important;
    padding-left:0 !important;
}

`;
const CloseButton = styled.button`
  position: absolute;
  top: 25.76px;
  right: 24px;
  border: none;
  background: none;
  cursor: pointer;
`;

const ModalFilterReports = (props) => {
  const [attachedFile, setAttachedFile] = useState(false);
  const [reportType, setReportType] = useState("");
  const [date, setDate] = useState({});
  const [dropValue, setDropValue] = useState("");
  const modalState = useSelector(
    (state) => state.modalReducer.reportSearchModal
  );
  const tabFilter = useSelector((state) => state.filterReducer.tabFilter);
  const dispatch = useDispatch();

  const componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.stateSearch !== props.stateSearch) {
      setReportType(props.stateSearch);
    }
  };

  const handleOnChange = (e, { value }) => {
    setReportType(value);
  };
  
  const reset = () => {
    setAttachedFile(false);
    setReportType("");
    setDate({});
  };

  const setDateState = (date) => {
    setDate(date);
  };

  const orderTranslator = (order) => {
    const options = {
      masReciente: { notificationDate: -1 },
      masAntiguo: { notificationDate: 1 },
      "A-Z": { notificationDate: -1 },
      "Z-A": { notificationDate: 1 },
    };
    return options[order];
  };
  
  const makeBodyRequest = () => {
    const body = {
      reportsFilter: {
        reportStatus: reportType
      },
      pag: 1,
      sort: "masReciente",
      starred: false,
      searchAdvanced: {
        date: date,
        reportType: reportType,
        attachedFile: attachedFile,
      },
    };
    dispatch(actionSearchAdvanced(body));
    dispatch(changeStateSearchReportsModal()); // cerrar modal
  };

  const makeBodyRequestDeleteFilter = () => {
    const body = {reportsFilter: {
      reportStatus: ''
    },
      pag: 1,
      sort: "masReciente",
      starred: false,
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: "",
        },
        reportType: "",
        attachedFile: false,
      },
    };

    
    dispatch(actionSearchAdvanced(body));
    dispatch(changeStateSearchReportsModal()); // cerrar modal
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    makeBodyRequest();
  };

  const handleDeleteFilter = (e) => {
    e.preventDefault();
    makeBodyRequestDeleteFilter();
    reset();
  };

  const validClose = () => {
    dispatch({ type: CHANGE_STATE_REPORTS_SEARCH_MODAL });
  };

  return (
    <>
      {modalState && (
        <Overlay>
          <ModalSent>
            <ModalDate>
              <Divider>
                <TextoHide>Búsqueda avanzada</TextoHide>
                <Texto>Mostrar</Texto>
                <CloseButton onClick={validClose}>
                  <Close />
                </CloseButton>
              </Divider>
              <TextoHide2>Mostrar</TextoHide2>
                <FormFilterReports
                  state={{
                    attachedFile: attachedFile,
                    reportType: reportType,
                    date: date,
                    dropValue: dropValue,
                  }}
                  handleOnChange={handleOnChange}
                  handleSubmit={handleSubmit}
                  tabFilter={tabFilter}
                />
                <Texto2>Período de tiempo</Texto2>
            </ModalDate>
              
              <DateFilter setDateState={setDateState} />

              <Divider2>
                <ButtonDeleteFilter onClick={handleDeleteFilter}>
                  Borrar filtros
                </ButtonDeleteFilter>
                <ButtonStartFilter onClick={handleSubmit}>
                  Aplicar filtro
                </ButtonStartFilter>
              </Divider2>
          </ModalSent>
        </Overlay>
      )}
    </>
  );
};

export default ModalFilterReports;
