import React, { useState } from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ReactComponent as EnabledButton } from "../../../src/assets/icons/clients/enabled-button.svg";
import { ReactComponent as DisabledButton } from "../../../src/assets/icons/clients/disabled-button.svg";
import { ReactComponent as EditImage } from "../../../src/assets/icons/clients/edit-button.svg";
import { useDispatch } from "react-redux";
import { getClientDetail, getClientEdit } from "../../actions/clientActions";
import { useNavigate } from "react-router-dom-v5-compat"

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`;

const Row = styled(Table.Row)`
  cursor: pointer !important;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #979e9f !important;
    padding: 0.5em 0em !important;
    box-sizing: content-box;
  }
`;

const Enabled = styled(EnabledButton)`
  margin: 4px 0 0 0 !important;
`;

const Disabled = styled(DisabledButton)`
  margin: 4px 0 0 0 !important;
`;

const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
`;


const RowTableClients = (props) => {
  const dispatch = useDispatch();
  const [Isvisible, setIsVisible] = useState(false);
  const navigate = useNavigate()

  const redirectToClientDetail = () => {
    sessionStorage.setItem("id", props.data.companySerialID);
    dispatch(getClientDetail(props.data.companySerialID));
    navigate({
      pathname: "/clients-detail/" + props.data.companySerialID,
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    sessionStorage.setItem("id", props.data.companySerialID);
    dispatch(getClientEdit(props.data.companySerialID));
    navigate("/editClients");
  };
  return (
    <Row
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      onClick={redirectToClientDetail}
    >
      <SentCell width={3}>{props.data.name}</SentCell>
      <SentCell width={2}>{props.data.numberDocumentCompany}</SentCell>
      <SentCell width={2}>{props.data.phone}</SentCell>
      <SentCell width={3}>{props.data.email}</SentCell>
      <SentCell width={3}>{props.data.legalManagerName}</SentCell>
      <SentCell width={3}>
        {props.data.status !== "ENABLE" ? <Disabled /> : <Enabled />}
        {Isvisible && <Edit onClick={handleClick} />}
      </SentCell>
    </Row>
  );
};

RowTableClients.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RowTableClients;
