import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom-v5-compat";

const  RequireAuth = ({ children, redirectTo }) => {
    var user = useSelector((state) => state.authReducer.user)
    user = Object.keys(user)
    let isAuthenticated = user.length;
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default RequireAuth