import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import {activePagination} from '../../../../actions/filterActions'
import { getUserDocumentsV2 } from '../../../../actions/userDocumentsActions'
import RowTableUserDocuments from './RowTableUserDocuments'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableUserDocumentsMix from './RowTableUserDocumentsMix'

const UserDocuments = ({ setPaginationLoader}) => {

  const dispatch = useDispatch()

  const filterReducer =  useSelector(state=> state.filterReducer )
  const userDocuments =  useSelector(state=> state.userDocumentReducer.userDocuments )


  const [state, setState] = useState({
    loader: true,
    columnData: [
      { name: "No. de identificación", width: 8, arrow: false },
      { name: "Última modificación", width: 3, arrow: false },
      { name: "", width: 2, arrow: false },
    ],
    columnDataMix: [
      { name: "", width: 1, arrow: false },
      { name: "Carpeta/archivos", width: 4, arrow: false },
      { name: "No. de identificación", width: 3, arrow: false },
      { name: "Última modificación", width: 3, arrow: false },
      { name: "", width: 3, arrow: false },
      { name: "", width: 2, arrow: false },
    ],
    init: true,
  })


  useEffect(() => {
    makeUserDocumentsRequest(filterReducer)
  }, [])



  useEffect(() => {
    if (!state.init) {
      makeUserDocumentsRequest(filterReducer)
      setState((prevstate)=> ({...prevstate,init: true, loader: true }))
    }
  }, [filterReducer.sort,filterReducer.searchBar,filterReducer.count,filterReducer.pag,filterReducer.searchAdvanced])


  const makeUserDocumentsRequest = (filterReducer) => {
    const body = {
      pag: filterReducer.pag,
      limit: filterReducer.count,
      sort: orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      status: filterReducer.searchAdvanced.notificationState,
      typeSearch: filterReducer.searchAdvanced.typeSearch,
      signDocumentFilter: filterReducer.searchAdvanced.signDocumentFilter,
      initDate: !!filterReducer.searchAdvanced.date.startDate
          ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
          ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
    }
    dispatch(getUserDocumentsV2(body)).then(() => {
      dispatch(activePagination(false))
      setState((prevstate)=>({...prevstate, init: false, loader: false }))
      setPaginationLoader(false)
    })
  }

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "lastModification": -1 },
      'masAntiguo': { "lastModification": 1 },
      'A-Z': { "nameElement": 1 },
      'Z-A': { "nameElement": -1 }
    }
    return options[order]
  }


  return (
      <GeneralTable
          headerTable={filterReducer.viewTableMix === true ? state.columnDataMix : state.columnData}
          rowComponent={filterReducer.viewTableMix === true ? RowTableUserDocumentsMix : RowTableUserDocuments}
          list={userDocuments}
          loader={state.loader}
          typeList="userDocuments"
          listHeight="userDocuments"
      />
  )
}

export default (UserDocuments)

