import React, {Component, useRef} from 'react'
import {Popup} from "semantic-ui-react"
import styled, { css } from "styled-components";
import {AttachSvgIcon} from "../IconSVG/AttachSvgIcon";

const AttachDivIcon = styled.div`
  height: 100%;
  width: 100%;
`
const AttachButtonIcon = styled(AttachSvgIcon)`
  width: 70%;
  height: 90%;
  transform: translate(0px, -2px);
`
const AttachButtonStyle = styled.button`    
  background: #e0e1e2;
  color: #133F4F;
  border: 1px solid #e0e1e2;
  border-radius: 5px 0 0 5px;
  margin-right: 0!important;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  &:hover {
    background: #babbbc;
  }

  
`

const AttachButton = (props) => {
  const ref= useRef()
  const {onSelectFile, accept, content, id, popUp, disabled, buttonAttachment} = props;
  
  const onInputClick = (event) => {
    event.target.value = ''
  }
    return (
      <>
        <Popup
          content={popUp}
          mouseEnterDelay={500}
          position='bottom left'
          on='hover'
          inverted
          trigger={
            <AttachButtonStyle
              content={content}
              onClick={(e) => {
                e.preventDefault()
                ref.current.click()
              }}
              disabled={disabled}
            >
              {buttonAttachment}
            </AttachButtonStyle>
          }
        />
        <input
          ref={ref}
          id={id}
          type="file"
          multiple
          hidden
          accept={accept}
          onChange={(e) => onSelectFile(e)}
          onClick={onInputClick}
        />
      </>
    )
  }

export default AttachButton
