import axios from 'axios'
import {
  GET_PROVIDERS,
  SAVE_PROVIDER,
  CHANGE_STATE_MESSAGE_MODAL,
  CHANGE_STATE_PROVIDER,
  INACTIVE_STATE_PROVIDER_FORM,
  EDIT_PROVIDER,
  GET_PROVIDER_EDIT,
  CHANGE_EDIT_PROVIDER,
  INACTIVE_FORM_EDIT,
  INACTIVE_FORM_EDIT_PROVIDER,
  ACTIVE_FORM_EDIT_PROVIDER,
  DETAIL_PROVIDER,
  CHANGE_STATE_REFRESH_MODULE,
  VIEW_PAGINATION,
  CHANGE_HAVE_CERTIFICATE,
  INACTIVE_STATE_CLIENT_FORM,
  INACTIVE_STATE_FORM_AGREEMENT,
} from './actionsUtilities/types.js'
import { API_ENDPOINT} from "../config/config"
import { deleteFromListActions, reasonDispatch,  updateListActions} from './DispatchGeneric.js'
import { GET_PROVIDER_DETAIL_ROUTE_V2, GET_PROVIDER_LIST_ROUTE_V2, GET_ROLES_PROVIDER_ROUTE, POST_CREATE_PROVIDER_ROUTE,
   PUT_PROVIDER_ROUTE_V2, PUT_STATE_PROVIDER_ROUTE } from './actionsUtilities/actionsRoutes.js'
import { decrypt, encrypt } from '../helpers/cypher.js'
import { getHeaders } from '../helpers/globalHelpers.js'


export const getProvidersV2 = (body, stateInitial) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_PROVIDER_LIST_ROUTE_V2}`,
    {data:encryptedBody},
    {headers: await getHeaders()}
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_PROVIDERS,
      payload:  { providers: decryptedData.finalResponse, elementsCount: decryptedData.elementsCount,}
    })
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
          total: decryptedData.elementsCount,
      }
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const postCreateProviders = (body) => async (dispatch) => {
  dispatch(updateListActions('CREATE_PROVIDER'))
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + POST_CREATE_PROVIDER_ROUTE}`, {data:encryptedBody}, {headers: await getHeaders()}
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
      dispatch({
        type: SAVE_PROVIDER,
        payload: decryptedData
      })
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'operatorCreate' }
      },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'operatorCreate' }
        })
        dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })
        dispatch({
          type: INACTIVE_STATE_PROVIDER_FORM
        })
        dispatch({ type: INACTIVE_STATE_CLIENT_FORM })
        dispatch({
          type: INACTIVE_STATE_FORM_AGREEMENT
      })
      }, 2000)
      )
      dispatch(deleteFromListActions('CREATE_PROVIDER'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    const statusCode = reason.response && reason.response.status; // Obtén el código de estado
    if (statusCode !== 500) {
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'errorCreateEntities' }
      })
    }
    dispatch(deleteFromListActions('CREATE_PROVIDER'))
  })
}

export const activeFormProvider = () => async dispatch => {
  dispatch({
    type: CHANGE_STATE_PROVIDER
  })
}

export const inactiveFormProvider = () => async dispatch => {
  dispatch({
    type: INACTIVE_STATE_PROVIDER_FORM
  })
}
 
export const getProviderEditV2 = (id) => async dispatch => {
  await axios.get(
    `${API_ENDPOINT + GET_PROVIDER_DETAIL_ROUTE_V2}${id}`,
    {headers: await getHeaders()}
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_PROVIDER_EDIT,
      payload: {
        codeCompany: decryptedData.provider.codeCompany !==undefined ? decryptedData.provider.codeCompany : '',
        companySerialID: decryptedData.provider.companySerialID,
        name: decryptedData.provider.name,
        providerDefinition: decryptedData.provider.definition,
        typePersonCompany: decryptedData.provider.typePersonCompany,
        typeDocumentCompany: decryptedData.provider.typeDocumentCompany,
        numberDocumentCompany: decryptedData.provider.numberDocumentCompany,
        DV: decryptedData.provider.DV,
        phone: decryptedData.provider.phone,
        email: decryptedData.provider.email,
        domain: decryptedData.provider.domain !== undefined ? decryptedData.provider.domain : '',
        address: decryptedData.provider.address,
        country: decryptedData.provider.country,
        countryName: decryptedData.provider.countryName,
        department: decryptedData.provider.department,
        departmentName: decryptedData.provider.departmentName,
        city: decryptedData.provider.city,
        cityName: decryptedData.provider.cityName,
        legalManagerName: decryptedData.provider.legalManagerName,
        legalManagerLastName: decryptedData.provider.legalManagerLastName,
        typeDocumentLegalManager: decryptedData.provider.typeDocumentLegalManager,
        numberDocumentLegalManager: decryptedData.provider.numberDocumentLegalManager,
        status: decryptedData.provider.status,
        contactInformation: decryptedData.provider.contactInformation.length >0 ?decryptedData.provider.contactInformation : [
          {
            name:'',
            surNames:'',
            email:'',
            phoneContact:'',
            occupation:''
          },
          {
            name:'',
            surNames:'',
            email:'',
            phoneContact:'',
            occupation:''
          },
        ],
        supportFiles: decryptedData.provider.supportFiles !== null && decryptedData.provider.supportFiles.length > 0 ? decryptedData.provider.supportFiles: [],
        supportDescription: decryptedData.provider.supportDescription
      }
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const inactiveFormProviderEdit = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_FORM_EDIT_PROVIDER,
  })
}

export const changeFormProvider = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_EDIT_PROVIDER,
    payload: {
      key,
      value
    }
  })
  dispatch({
    type: ACTIVE_FORM_EDIT_PROVIDER,
  })
  return Promise.resolve();
}

export const putProviderEditV2 = (body) => async dispatch => {
  dispatch(updateListActions('EDIT_PROVIDER'))
  const encryptedBody = await encrypt(body);
  await axios.put( `${API_ENDPOINT + PUT_PROVIDER_ROUTE_V2}${body.companySerialID}`, { data: encryptedBody }, { headers: await getHeaders() })
    .then(async (res) => {
      const decryptedData = await decrypt(res.data.result);
      if (!!decryptedData.success) {
        dispatch({
          type: EDIT_PROVIDER,
          payload: decryptedData,
        });
        dispatch(
          {
            type: CHANGE_STATE_MESSAGE_MODAL,
            payload: { type: "providerEdit" },
          },
          setTimeout(() => {
            dispatch({
              type: CHANGE_STATE_MESSAGE_MODAL,
              payload: { type: "providerEdit" },
            });
            dispatch({
              type: CHANGE_STATE_REFRESH_MODULE,
              payload: true,
            });
          }, 2000)
        );
        dispatch({
          type: INACTIVE_STATE_PROVIDER_FORM,
        });
        dispatch({
          type: INACTIVE_FORM_EDIT_PROVIDER,
        });
        dispatch({
          type: INACTIVE_STATE_CLIENT_FORM,
        })
        dispatch({
          type: INACTIVE_FORM_EDIT,
        })
        dispatch(deleteFromListActions('EDIT_PROVIDER'))
      } else {
        let reason = res;
        reasonDispatch(dispatch, reason, false);
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: "unknownError" },
        });
      }
    })
    .catch(async reason => {
      await reasonDispatch(dispatch, reason, false);
      dispatch(deleteFromListActions('EDIT_PROVIDER'))
    });
}

export const getProviderDetail = (id) => async dispatch => {
  await axios.get(
    `${API_ENDPOINT + GET_PROVIDER_DETAIL_ROUTE_V2}${id}`,
    { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: DETAIL_PROVIDER,
      payload: decryptedData.provider
    })
    dispatch({
      type: CHANGE_HAVE_CERTIFICATE,
      payload: true,
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_HAVE_CERTIFICATE,
      payload: true,
    })
  })
}

export const getRolesProvider = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT + GET_ROLES_PROVIDER_ROUTE}`,
      { headers: await getHeaders() }
    )
    const decryptedData = await decrypt(res.data.result);
    if (res.status === 200) {
      return decryptedData
    }
  } catch (error) {
  }
}

export const putStateActiveProvider = (body, id) => async (dispatch) => {
  
  await axios.put(
    `${API_ENDPOINT+PUT_STATE_PROVIDER_ROUTE}${id}`,
    body,
    { headers: await getHeaders() }
  ).then(async (res) => {
    dispatch({
      type: EDIT_PROVIDER,
      payload: res.data.result
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'providerActive' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'providerActive' }
        })
       /* dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })*/
      }, 2000)
    )
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'providerCantActive' }
    })
  })
}

export const putStateInactiveProvider = (body, id) => async (dispatch) => {
  
  await axios.put(
    `${API_ENDPOINT+PUT_STATE_PROVIDER_ROUTE}${id}`,
    body,
    { headers: await getHeaders() }
  ).then(async (res) => {
    dispatch({
      type: EDIT_PROVIDER,
      payload: res.data.result
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'providerInactive' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'providerInactive' }
        })
        /*dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })*/
      }, 2000)
    )
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'providerCantInactive' }
    })
  })
}