import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {Form, Label, Icon, Divider} from "semantic-ui-react"

import PropTypes from "prop-types";
import {
  addMassiveRecipientsList, changeWriteNotificationForm,
  filterMassiveRecipients,
  getRecipientv2, replaceRecipientDisplay, setMassiveRecipientFileInfo, updateHistoryMassive,
  updateRecipientDisplay, changeDisabledGetRecipient, resetRecipient
} from "../../actions/writerActions";
import {warningFormModalWrite} from '../../actions/modalActions'
import { saveDraftData, saveDraftV2, deleteDraftRecipients} from '../../actions/draftsActions';
import {connect} from "react-redux";
import cogoToast from '@successtar/cogo-toast';
import {toastOptions} from "../../helpers/globalHelpers";
import styled, {css} from "styled-components";
import './dropdownStyle.css'
import DropdownOptions from "./DropdownOptions";
import {ReactComponent as IconVector} from '../../assets/icons/write/vector.svg'
import {ReactComponent as IconToClose} from '../../assets/icons/write/to-close.svg'

const FormField = styled(Form.Field)`
  width: 100%;
  min-width: 0;
`

const LabelFor = styled.label`
  display: inline-block;
  width: 6%;
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  ${props => props.labelerrorstatus &&
          css`
            color: ${props.labelerrorstatus};
          `} 
`
const DivRecipientsAdded = styled.div`
  max-width: 63%;
  line-height: 1em;
  overflow-x: hidden;
  width:0;
  ${props => props.fieldHeightDivFocused &&
          css`
            overflow-y: ${props.fieldHeightDivFocused.overflowY};
            z-index: ${props.fieldHeightDivFocused.zIndex};
          `}
  position: absolute;
  background-color: white;
  margin-left: 40px;
  top:-8px;
  @media only screen and (max-width: 1500px)  {
    max-width: 71%;

  }

  @media only screen and (max-width: 992px)  {
    max-width: 100%;
  }

`
const DivRecipientsMassiveAdded = styled.div `
  max-width: 63%;
  line-height: 1em;
  overflow-x: hidden;
  
  overflow-y: hidden;
   z-index: 1;
   position: absolute;
  background-color: white;
  margin-left: 40px;
  top:-8px;
  @media only screen and (max-width: 1500px)  {
    max-width: 80%;

  }
`
const LabelItemsFor = styled(Label)`
  max-width: 200px;
  max-height: 30px;
  overflow: hidden;
  position:relative;
  text-overflow: ellipsis;
  border-radius: 15px !important;
  font-family: 'Mulish' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: 98%;
  padding: 5px 8px 5px 8px !important;
  padding-right: 20px !important;
  margin-bottom: 2px !important;
  ${props => props.labelerrorstatusitem &&
          css`
            color: ${props.labelerrorstatusitem.color} !important;
            background: ${props.labelerrorstatusitem.background} !important;
            border: ${props.labelerrorstatusitem.border} !important;
          `}
`
const InputForInside = styled.input`
  outline: none;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 1.21428571em;
  font-size: 16px;

  ${props => props.fieldwidthinside &&
          css`
            width: ${props.fieldwidthinside};
          `}
  ::placeholder {
    color: #BA3555;
    padding-bottom: 1px;
  }
`

const InputForOutside = styled.input`
  width:100%;
  display: inline-block;
  padding-left: 45px;
  outline: none;
  border-radius: 0;
  line-height: 1.21428571em;
  font-size: 16px;
  border: none;
  ${props => props.fieldwidthoutside &&
          css`
            visibility: ${props.fieldwidthoutside.visibility};
            z-index: ${props.fieldwidthoutside.zIndex};
          `}
  ::placeholder {
    color: #BA3555;
  }
`

const DividerInputFor = styled(Divider)`
  position: absolute;
  width: 75%;
  line-height: 1px;
  z-index: 0;
  top: 28px;
  @media only screen and (max-width: 992px)  {
    width: 100%;
  }
  ${props => props.mandatoryfield && 
          css`
            background-color: ${props.mandatoryfield.borderColor};
          `} 
`

const ErrorLabel = styled.label`
  display: inline-block;
  width: max-content;
  height: 5%;
  top: 30px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #BA3555;
  left: 6.19%;
`
const IconDelete = styled(IconToClose)`
  width: 9px;
  height: 9px;
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 7px;

`
const DropdownAllowAddition= (props) => {

  const recipientData = useSelector(state => state.writerReducer.recipientData)
  const listIdsSendMassive = useSelector(state => state.writerReducer.listIdsSendMassive)
  const onEdition = useSelector(state => state.writerReducer.notificationForm.onEdition)
  const historyRecipient = useSelector(state => state.writerReducer.historyRecipient)
  const draftData = useSelector(state => state.draftReducer.toSaveDraft)
  const isMassiveRecipient = useSelector(state => state.writerReducer.isMassiveRecipient)
  const isDisabledGetRecipient = useSelector(state => state.writerReducer.isDisabledGetRecipient)
  const dispatch = useDispatch()

  //const [formValidator,setShow,setFields] = useValidator(reactValidatorOptions)
  const [state, setState] = useState({
    focused: false,
    onValidation: false,
    onEdition: false,
    historyTo: '',
    historyVisible: false,
    valueToLocal: [{}],
  })

  const [idNumberCurrent, setIdNumberCurrent] = useState("")
  const [allInfoCurrent, setAllInfoCurrent] = useState()

  useEffect(()=>{
    if(allInfoCurrent !== undefined){
      if (!!allInfoCurrent) {
        dispatch(updateRecipientDisplay({key: allInfoCurrent.ids[0], value: allInfoCurrent.fullData[0]}))
      } else {
        cogoToast.error("Ocurrio un error obteniendo datos del destinatario", toastOptions)
      }
      let errorObj = {status: false, content: ''}
      if (isInvalidRecipient(allInfoCurrent.backendRequest))
        errorObj = {status: true, content: 'Existen registros no válidos'}
      props.changeState({
        value: "",
        recipientError: {status: errorObj.status, content: errorObj.content}
      })
      resetInputValues()
      textInputInside.current.focus()
    }
    setAllInfoCurrent(undefined)
  },[allInfoCurrent])

  useEffect(()=>{
    if(!!recipientData.documentNumber){
      let allInfo
      let ids = []
      let backendRequest = []
      let fullData = []
      if (!isRecipientDataInfo(idNumberCurrent)) {
        ids.push(idNumberCurrent)
        fullData.push(idNumberCurrent)
        backendRequest.push({documentNumber: idNumberCurrent})
        allInfo = {ids: ids, fullData: fullData, backendRequest: backendRequest}
        awaitAddMassiveRecipientsList(allInfo)
      } else {
        const name = recipientData.variableInformation
        const obj = {
          documentNumber: idNumberCurrent,
          firstName: recipientData.firstName,
          secondName: recipientData.secondName,
          surname: recipientData.surname,
          secondSurname: recipientData.secondSurname
        }
        ids.push(idNumberCurrent)
        fullData.push(name)
        backendRequest.push(obj)
        allInfo = {ids: ids, fullData: fullData, backendRequest: backendRequest}
        awaitAddMassiveRecipientsList(allInfo)
        if (isValidHistory(idNumberCurrent))
          awaitUpdateHistoryMassive([{value: idNumberCurrent, key: idNumberCurrent}])
      }
      if(!!allInfo.ids[0] !== ""){
        setAllInfoCurrent(allInfo)
      }
    }
  },[recipientData.documentNumber
  ])

  const textInputInside = useRef(0);

  useEffect(()=>{
    setState((prevState) =>({
      ...prevState,
      onEdition: onEdition
    }))
  },[onEdition])

  useEffect(()=>{
    selectOptionHistory()
  },[state.historyTo])

  const selectOptionHistory = async () => {
    if (state.historyTo && isValid(state.historyTo)) {
      await performAction(state.historyTo)
      const recipients = props.items.map(item => item.key);
      await saveDraft(recipients)
    }else{
      dispatch(changeDisabledGetRecipient(false))
    }
    setState((prevState)=> ({...prevState, historyVisible: false}))
  }

  const handleKeyDown = async evt => {
    if (["Enter", ","].includes(evt.key)) {
      dispatch(changeDisabledGetRecipient(true))
      if (listIdsSendMassive.ids.length <= 100) {
        const value = props.value && props.value.trim()
        if (value && isValid(value)) {
          setIdNumberCurrent(value)
          await performAction(value)
        }else{
          dispatch(changeDisabledGetRecipient(false))
        }
      } else
        cogoToast.error("Has alcanzado el límite máximo de destinatarios", toastOptions)
    }

    if (["Tab"].includes(evt.key)) {
      dispatch(changeDisabledGetRecipient(true))
      if (listIdsSendMassive.ids.length <= 100) {
        const value = props.value && props.value.trim()
        if (value && isValid(value)) {
          setIdNumberCurrent(value)
          await performAction(value)
        }else{
          dispatch(changeDisabledGetRecipient(false))
        }
      } else
        cogoToast.error("Has alcanzado el límite máximo de destinatarios", toastOptions)
    }
  }

  const handleOnBlurInput = async (value) =>{
    if (value && isValid(value)) {
      setIdNumberCurrent(value)
      await performAction(value)
      const recipients = props.items.map(item => item.key);
      await saveDraft(recipients)
    }else{
      dispatch(changeDisabledGetRecipient(false))
    }
  }

  const performAction = async (value) => {
     calculateRecipient(value)
  }

  const resetInputValues = () => {
    Object.keys(state.valueToLocal).forEach((key, index) => {
      const newObject = {...state.valueToLocal};
      newObject[`${index}`] = {value: '', key: index, width: fieldWidthInside('')}
      setState((prevState) => ({
        ...prevState,
        valueToLocal: newObject
      }))
    })
  }

  const handleChange = (evt, index) => {
    evt.persist()
    if (evt.target.value === '' || isNumber(evt.target.value)) {
      props.changeState({
        value: evt.target.value,
        recipientError: {status: false, content: ''}
      })
      if (index !== -1) {
        const newObject = {...state.valueToLocal};
        newObject[`${index}`] = {value: evt.target.value, key: index, width: fieldWidthInside(evt.target.value)}
        setState((prevState) => ({
          ...prevState,
          valueToLocal: newObject
        }))
      }
      setState((prevState)=>({
        ...prevState,
        focused: false,
        onValidation: false,
        isHovering: false,
        historyVisible: false,
      }))
    }
  }

  const awaitAddMassiveRecipientsList = async (allInfo) => {
    return dispatch(addMassiveRecipientsList(allInfo))
  }

  const awaitUpdateHistoryMassive = async (allInfo) => {
    return dispatch(updateHistoryMassive(allInfo))
  }

  const calculateRecipient = async (idNumber) => {
    await dispatch(getRecipientv2({documentNumber: idNumber})).then(async () => {
    }).catch((err) => {
      console.error(err)
      return null
    })
  }

  const isRecipientDataInfo = (idNumber) => {
    return !!(Object.keys(recipientData).length > 1 && recipientData.documentNumber === idNumber)
  }

  const handleDelete = async item => {
    
    if (isNumber(item.key))
      await removeReduxRecipientsNormal(item)
    else
      await removeReduxRecipientsFromFile(item)
    //se debe modificar logica para solucionar el error adecuadamente debido a que presenta bug
    const listIdsSendMassiveTemp= listIdsSendMassive.backendRequest
    listIdsSendMassiveTemp.pop()
    if (!isInvalidRecipient(listIdsSendMassiveTemp))
      props.changeState({
        recipientError: {status: false, content: ''}
      })
      dispatch(resetRecipient())
  }

  const handleDeleteMassive = async (item) => {
    dispatch(warningFormModalWrite({ type: 'warningDeleteMassiveFor' }))
  }
  
  const awaitFilterMassiveRecipients = async (allInfo) => {
    dispatch(filterMassiveRecipients(allInfo))
  }

  const awaitReplaceRecipientDisplay = async (resultItems) => {
    dispatch(replaceRecipientDisplay(resultItems))
  }

  const removeReduxRecipientsNormal = async (item) => {
    let allInfo
    const resultIds = listIdsSendMassive.ids.filter((arrayValueElement) => arrayValueElement !== item.key)
    const resultFullData = listIdsSendMassive.fullData.filter((arrayValueElement) => arrayValueElement !== item.value)
    const resultBackedReq = listIdsSendMassive.backendRequest.filter((arrayValueElement) => arrayValueElement.documentNumber !== item.key)
    allInfo = {ids: resultIds, fullData: resultFullData, backendRequest: resultBackedReq}
    await awaitFilterMassiveRecipients(allInfo)
    const resultItems = props.items.filter(i => i.key !== item.key)
    await awaitReplaceRecipientDisplay(resultItems)
  }

  const awaitSetMassiveRecipientFileInfo = async (resultItems) => {
    dispatch(setMassiveRecipientFileInfo(resultItems))
  }

  const removeReduxRecipientsFromFile = async (fileId) => {
    let allInfo
    let resultFullData = []
    let resultBackedReq = []
    const resultIds = listIdsSendMassive.ids.filter((arrayValueElement) => !arrayValueElement.includes(fileId.key))
    for (const resultId of resultIds) {
      resultFullData = listIdsSendMassive.fullData.filter((arrayValueElement) => arrayValueElement !== resultId.split("_")[0])
      resultBackedReq = listIdsSendMassive.backendRequest.filter((arrayValueElement) => arrayValueElement.documentNumber !== resultId.split("_")[0])
    }
    allInfo = {ids: resultIds, fullData: resultFullData, backendRequest: resultBackedReq}
    await awaitFilterMassiveRecipients(allInfo)
    const resultItems = props.items.filter(i => i.key !== fileId.key)
    await awaitSetMassiveRecipientFileInfo(resultItems)
    await awaitReplaceRecipientDisplay(resultItems)
    return resultItems
  }


  const isValid = (idNumber) => {
    let recipientError = {status: false, content: ''}
    if (isInList(idNumber)) recipientError = {
      status: true,
      content: `${idNumber} ya se encuentra en la lista de destinatarios.`
    }
    if (recipientError.status) {
      cogoToast.info(recipientError.content, toastOptions)
      return false
    }
    return true
  }

  const isValidHistory = (idNumber) => {
    return !isInListHistory(idNumber)
  }

  const isInList = (idNumber) => {
    return props.items.some(e => e.key === idNumber)
  }

  const isInListHistory = (idNumber) => {
    return historyRecipient.some(e => e.key === idNumber)
  }

  const isInvalidRecipient = (backendRequestDataArray) => {
    return backendRequestDataArray.filter(e => !e.firstName).length > 0
  }

  const isRecipientEmpty = (backendRequestDataArray) => {
    return backendRequestDataArray.length <= 0 || props.items.length <= 0;
  }

  const isNumber = (idNumber) => {
    return /^[0-9\b]+$/.test(idNumber)
  }

  const onBlurDropdown = async () => {
    if(!isMassiveRecipient)
      setState((prevState) => (
        {
          ...prevState,
          focused: false, 
          onValidation: true, 
          historyVisible: false}
      ))
      const recipients = props.items.map(item => item.key);
      saveDraft(recipients)
  }

  const saveDraft = async (recipients) => {
    if (recipients.length) {
      dispatch(saveDraftData({recipients}))
      /*await dispatch(saveDraftV2(draftData))*/
    }
  }

  const onClickDropdown = () => {
    if(!isMassiveRecipient){
      dispatch(changeWriteNotificationForm('onEdition', true))
      setState((prevState)=>(
        {...prevState, 
          focused: true, 
          onValidation: false, 
          isHovering: true, 
          historyVisible: true
        }))

        if (!!props.items.length){
          textInputInside.current.focus()  
        }
    }
  }

  const fieldHeightDivFocused = (listSize) => {
    if (listSize > 1)
      return {overflowY: `visible`, zIndex: `1`}
    return {overflowY: `hidden`, zIndex: `0`}
  }

  const mandatoryField = (editing, isInvalidRecipient, isRecipientEmpty, onValidation) => {
    if (onValidation)
      if (editing && isInvalidRecipient) {
        return {borderHeight: `2px`,borderColor: `#BA3555`, positionTop:`28px`}
      } else if (editing && isRecipientEmpty){
        return {borderHeight: `2px`,borderColor: `#BA3555`, positionTop:`27px`}
      }
    
    if(!isRecipientEmpty){
      return {borderHeight: `1px`,borderColor: `rgba(34,36,38,.15)`, positionTop:`28px`}
    }else{
      return {borderHeight: `1px`,borderColor: `rgba(34,36,38,.15)`, positionTop:`27px`}
    }
  }

  const fieldWidthVisibilityOutside = (listSize) => {
    if (listSize === 0) {
      return {width: `60%`, visibility: `visible`, zIndex: `1`}
    }
    return {width: `0%`, visibility: `hidden`, zIndex: `0`}
  }

  const fieldWidthInside = (typedText) => {
    if (typedText.length === 0)
      return `3%`
    return `${typedText.length * 3}%`
  }

  const handleMouseHover = () => {
    setState((prevState)=> ({...prevState, isHovering: true, focused: false}))
  }

  const handleMouseOut = () => {
    setState((prevState)=> ({...prevState,isHovering: false}))
  }

  const labelErrorStatus = (errorStatus) => {
    if (errorStatus) {
      return `#BA3555`
    }
    return `#B2B2B2`
  }

  const labelErrorStatusItem = (itemValue) => {
    if (isNumber(itemValue)) {
      return {color: `#BA3555`, background: `rgba(196, 19, 46, 0.05)`, border: `1px solid #C3132E`}
    }
    return {color: `#3C3C3B`, background: `#F3F3F3`, border: `1px solid #C1C1C1`}
  }

  const onFocusInnerInput = () => {
    setState((prevState)=> ({...prevState,historyVisible: false}))
  }

  /**
   * Options Part
   */

  const changeOptionsState = async (data) => {
    setIdNumberCurrent(data.value)
    setState((prevState) => ({
      ...prevState,
      historyTo: data.value === undefined ? "" : data.value,
    }))
  }

    const {validator, label, validateOptions, required} = props
    let errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : '', props.value, validateOptions) : ''
    return (
      <FormField onBlur={onBlurDropdown} onClick={onClickDropdown}
                 error={!!props.recipientError.status || !!errorValidator} required={required && true}>
        <LabelFor labelerrorstatus={labelErrorStatus(props.recipientError.status)}>{label}</LabelFor>
        <InputForOutside
          value={props.value}
          placeholder={!state.focused && state.onEdition && props.items.length <= 0 ? "Campo obligatorio" : ""}
          onKeyDown={handleKeyDown}
          onBlur= {() => handleOnBlurInput(props.value)}
          onChange={(event) => handleChange(event, -1)}
          fieldwidthoutside={fieldWidthVisibilityOutside(props.items.length)}
          mandatoryfield={mandatoryField(state.onEdition, isInvalidRecipient(listIdsSendMassive.backendRequest), isRecipientEmpty(listIdsSendMassive.backendRequest), state.onValidation)}
          disabled={isDisabledGetRecipient}
          fitted/>
        {isMassiveRecipient? 
            <DivRecipientsMassiveAdded>
                <LabelItemsFor labelerrorstatusitem={labelErrorStatusItem(props.items)}>
                  {props.items}
                  <IconDelete name='delete' onClick={() => handleDeleteMassive("massiveFor")}/>
                </LabelItemsFor>
            </DivRecipientsMassiveAdded>
        
          :  
            <DivRecipientsAdded onMouseOut={handleMouseOut} onMouseOver={handleMouseHover} /*onBlur={handleOnBlur}*/
                                fieldHeightDivFocused={fieldHeightDivFocused(props.items.length)}
                                className={(state.focused || state.isHovering) && !!props.items.length  ? "dropdownForNotFocus" : (!!props.items.length)?"dropdownForNotFocus":''}
            >
          {props.items.map((item) => (
            <>
              <LabelItemsFor labelerrorstatusitem={labelErrorStatusItem(item.value)} key={item.key}>
                {item.value}
                  <IconDelete name='delete' onClick={() => handleDelete(item)}/>
              </LabelItemsFor>
            </>
          ))}
          <InputForInside
            ref={textInputInside}
            value={!!state.valueToLocal[0] && !!state.valueToLocal[0].value ? state.valueToLocal[0].value : ''}
            onKeyDown={handleKeyDown}
            onBlur= {() => handleOnBlurInput(props.value)}
            onChange={(event) => handleChange(event, 0)}
            fieldwidthinside={!!state.valueToLocal[0] && !!state.valueToLocal[0].width ? state.valueToLocal[0].width : '3%'}
            onFocus={onFocusInnerInput}
            disabled={isDisabledGetRecipient}
          />
        </DivRecipientsAdded>
          }
        <>
          <div className='iconArrowDiv'>
            <IconVector/>
          </div>
          <DividerInputFor
            mandatoryfield={mandatoryField(state.onEdition, isInvalidRecipient(listIdsSendMassive.backendRequest), isRecipientEmpty(listIdsSendMassive.backendRequest), state.onValidation)}
            fitted/>
        </>
        <DropdownOptions
          value={state.historyTo}
          items={historyRecipient}
          changeOptionsState={changeOptionsState}
          visible={state.historyVisible}
        />
        {
          props.recipientError.status &&
          <ErrorLabel size={'mini'}>{props.recipientError.content}</ErrorLabel>
        }
        {
          (errorValidator && !props.recipientError.status) &&
          <Label prompt basic pointing>{errorValidator}</Label>
        }
      </FormField>
    )
  }

export default DropdownAllowAddition
