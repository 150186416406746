import React, { useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { activePagination } from '../../../../../actions/filterDetailActions'
import { detailAttachmentsV2, viewPDF, updateList, removeFromList, deleteAllFromList } from '../../../../../actions/seeAttachmentAction'
import styled from 'styled-components'
import CardAttachment from './CardAttachment'
import CardAttachmentDetail from './CardAttachmentDetail'

const Divider = styled.div`
  background: #EBEBEB;
  height: 45px;
  width: 100%;
`
const Title = styled.h1`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  padding-top: 11px;
  padding-bottom: 10px;
  padding-left: 23px;
    
`

export const AllAttachmentDetailGrid = ({activeAll,setState,state}) => {

    const dispatch =  useDispatch()
    const { attachmentID } = useParams();

  

    const filterReducer = useSelector(state => state.filterDetailReducer)
    const listAttachments = useSelector(state => state.userDocumentReducer.detailAttachment)
    const sort = useSelector(state => state.filterDetailReducer.sort)
    const isSearch = useSelector(state => state.filterDetailReducer.isSearch)


    useEffect(() => {
        makeDetailAttachmentsRequest(filterReducer)
        dispatch(viewPDF({ "viewPDF": false }))
    }, [])


    useEffect(() => {

        if (!state.init) {
            makeDetailAttachmentsRequest(filterReducer)
            setState({init: true, loader: true })
        }
      
    }, [filterReducer])

     


    const makeDetailAttachmentsRequest = async (filterReducer) => {
        dispatch(deleteAllFromList())
        const body = {
            count: filterReducer.count,
            pag: filterReducer.pag,
            limit: 20,
            sort: orderTranslator(filterReducer.sort),
            status: validateStatus(filterReducer.searchAdvanced.notificationState),
            initDate: !!filterReducer.searchAdvanced.date.startDate
                ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
            endDate: !!filterReducer.searchAdvanced.date.endDate
                ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
            starred: filterReducer.starred,
            attachmentsName: filterReducer.searchBar[0],
            to: attachmentID,
        }

        if (filterReducer.searchAdvanced.notificationState === 'REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'REQUIRED_SIGNATURE' ) {
            body.requiredSignature = true;
          }
      
          if (filterReducer.searchAdvanced.notificationState === 'NO_REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'NO_REQUIRED_SIGNATURE') {
            body.requiredSignature = false;
          }
      

        if (body.initDate === "") {
            delete body.initDate
            delete body.endDate
        }

        dispatch(detailAttachmentsV2(body))
            .then(() => {
                dispatch(activePagination(false)) 
                setState((prevstate) => ({...prevstate, init: false, loader: false }))
            })
    }

    const validateStatus = (status) =>{
        return status === 'REQUIRED_SIGNATURE'  || status === 'NO_REQUIRED_SIGNATURE' ? '' : status
      }
    

    const orderTranslator = (order) => {
        const options = {
            'masReciente': { "_id": -1 },
            'masAntiguo': { "_id": 1 },
            'A-Z': { "fileOriginalName": 1 },
            'Z-A': { "fileOriginalName": -1 }
        }
        return options[order]
    }

    const addCheckedRow = (id) => {
        dispatch(updateList(id))
      }
    
    const removeCheckedRow = (id) => {
        dispatch(removeFromList(id))
      }

    return (
            <>

                <CardAttachment
                    list={listAttachments}
                    loader={state.loader}
                    cardComponent={CardAttachmentDetail}
                    isOrder={sort}
                    addCheckedRow={addCheckedRow}
                    removeCheckedRow={removeCheckedRow}
                    activeAll={activeAll} 
                    to={attachmentID}
                    isSearch={isSearch}
                    />
                
            </>
    )
}

export default (AllAttachmentDetailGrid)
