import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import RowTableAuditorImproperClients from "../RowTableAuditorImproperClients";
import _ from "lodash";
import PropTypes from "prop-types";
import GeneralTable from "../../../../components/Table/GeneralTable";
import {
  actionClearFilter,
  activePagination,
} from "../../../../actions/filterActions";
import ModalFilterReports from "../../../../components/Search/SearchReports/ModalFilterReports";
import { getImproperClientsList } from '../../../../actions/improperClientsActions';

const AllAuditorImproperClients = ({  }) => {
  const [loader, setLoader] = useState(true);
  const [stateInitial, setStateInitial] = useState(true);
  const [columnData] = useState([
    { name: "Nombre", width: 3, arrow: false },
    { name: "Documento", width: 2, arrow: false },
    { name: "Teléfono", width: 2, arrow: false },
    { name: "Email", width: 3, arrow: false },
    { name: "Representante legal", width: 3, arrow: false },
    { name: "Reportes erróneos", width: 3, arrow: false },
  ]);

  const filterReducer = useSelector(state => state.filterReducer);
  const improperClientsArray = useSelector(state => state.improperClientsReducer.improperClients);
  const dispatch = useDispatch();

  useEffect(() => {
    makeImproperClientsRequest(filterReducer);
  }, []);

  useEffect(() => {
      if (!stateInitial) {
        makeImproperClientsRequest(filterReducer);
        setLoader(true);
        setStateInitial(true)
      }
  }, [filterReducer]);

  const makeImproperClientsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      filterPorcentages: filterReducer.improperClientsFilter.filterOptions,
    };

    dispatch(getImproperClientsList(body)).then(() => {
      dispatch(activePagination(false))
      setStateInitial(false);
      setLoader(false);
    });
  };

  const orderTranslator = (order) => {
    const options = {
      "masReciente": { _id: -1 },
      "masAntiguo": { _id: 1 },
      "A-Z": { name: 1 },
      "Z-A": { name: -1 },
    };
    return options[order];
  };

  return (
    <>
      <GeneralTable
        list={improperClientsArray}
        typeList={filterReducer.isSearch ? "default" : "default"}
        listHeight={'improperClients'}
        headerTable={columnData}
        rowComponent={RowTableAuditorImproperClients}
        loader={loader}
      />
      <ModalFilterReports />
    </>
  );
};

AllAuditorImproperClients.propTypes = {
};

export default AllAuditorImproperClients;
