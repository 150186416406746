import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as EditIcon } from '../../../assets/icons/clients/EditIcon.svg'
import { permissionButtonValidate } from '../../../components/PermissionValidate/PermissionValidate'
import moment from "moment/moment";
import { getAgreementEdit } from '../../../actions/agreementActions'
import { useNavigate } from 'react-router-dom-v5-compat'

const Container = styled.div`
  margin-bottom: 40px;
`

const ContainerDetailAgreement = styled.div`
  margin: 24px 0px;
  padding: 0px 29px 0px 57px;
`

const TextTitleElement = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;

`

const ContainerElementContent = styled.div`
  padding: 32px;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  margin-botton:10px;
  color: #3C3C3B;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
`
const Divider = styled.div`
`

const StyleDate = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
`
const Styletext = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color:#7C7C74;
`
const StyleDateAgreements = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  padding-left:5px;
  color: #22C1D0;
  text-decoration-line: underline;
  cursor: pointer;
`

const ButtonEdit = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  padding: 8px 8px;
  display:flex;
  float:right;
  cursor: pointer;
    @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
      svg{
      width: 100 !important;
      height: 16;
      fill: none;
    }
  }
`

const Title3 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  margin: 0px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
  font-size: 16px;
}

`

const BoxFiles = styled.div`
  gap: 0;
  max-height: 53px !important;
  height: 53px !important;
  overflow-y: auto;
  overflow-x:hidden;
  ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;   
  -ms-hyphens: auto;          
  -moz-hyphens: auto;         
  -webkit-hyphens: auto;
  hyphens: auto;
`

const AgreementDetail = (props) => {

  const agreementStatus = {
    ACTIVE: "En curso",
    PENDING: "Aprobado",
    INACTIVE: "Anulado",
    EXPIRED: "Vencido",
  }

  const entityType = {
    PUBLIC: "Pública",
    PRIVATE: "Privada"
  }

  const personType = {
    LEGAL_PERSON: "Jurídica"
  }

  const entityAactive = {
    ENABLE: "Activa",
    DISABLE: "Inactiva"
  }

  const { detailAgreement } = useSelector((state) => state.agreementReducer)
  const { role } = useSelector((state) => state.authReducer.user)
  const [agreementsList = [], setAgreementsList] = useState([])
  const formatDate = (agreementDate) => moment(agreementDate).format('DD/MM/YYYY')
  const formatDate2 = (agreementDate) => moment(agreementDate).format('DD/MM/YYYY - h:mm a')
  const formatDate3 = (agreementDate) => moment(agreementDate).format('DD/MMM/YYYY - h:mm a')
  useEffect(() => {
    const list = []
    if (detailAgreement && detailAgreement.agreements) {
        Object.values(detailAgreement.agreements).forEach((value, index) => {
          list.push({fileName:value.originalFileName, base64:value.base64})
        })
        setAgreementsList(list)
    }
  }, [detailAgreement])

  const servicesDetail = (data) => {
    let concat = ''
    Object.values(data).forEach((value, index) => {
      if (index !== data.length - 1) {
        concat += `${value}/ `
      } else {
        concat += `${value}`
      }
    })
    return concat
  }
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = (e) => {
    sessionStorage.setItem("idAgreement", detailAgreement.id)
    dispatch(getAgreementEdit(detailAgreement.id))
    if (detailAgreement.status === 'PENDING') {
      navigate('/editAgreements')
    }
  }

  
  return (
    <>
      <Container>
        <ContainerDetailAgreement>
          <TextTitleElement>
            {detailAgreement.agreementCode?`Vinculación ${detailAgreement.agreementCode}`:"Vinculación"}
          </TextTitleElement>
          <ContainerElementContent>
            {(detailAgreement.status === 'PENDING') && permissionButtonValidate(
              {componentButton: <ButtonEdit onClick={handleClick}> <EditIcon/> </ButtonEdit>, role: role})}
            <Divider>{"Código vinculación: "}<StyleDate>{detailAgreement.agreementCode} </StyleDate></Divider>
            <Divider>{"Fecha suscripción: "}<StyleDate>{formatDate(detailAgreement.subscriptionDate)} </StyleDate></Divider>
            <Divider>{"Fecha inicio: "}<StyleDate>{formatDate(detailAgreement.startDate)} </StyleDate></Divider>
            <Divider>{"Fecha vencimiento: "}<StyleDate>{formatDate(detailAgreement.endDate)} </StyleDate></Divider>
            <Divider>{"Cantidad envío de notificaciones: "}<StyleDate>{detailAgreement.clientData.providerDefinition ==='PUBLIC' ?'Ilimitadas':detailAgreement.quantity} </StyleDate></Divider>
            <Divider>{"Número de oficio: "}<StyleDate>{detailAgreement.tradeNumber} </StyleDate></Divider>
            <Divider>{"Fecha de oficio: "}<StyleDate>{formatDate(detailAgreement.tradeDate)} </StyleDate></Divider>
            <Divider>{"Detalles vinculación: "}<StyleDate>{detailAgreement.tradeDetails} </StyleDate></Divider>
            <Divider>{"Soporte: "}<StyleDate>{detailAgreement.tradeSupport} </StyleDate></Divider>
            <Divider>{"Archivo(s) de vinculación: "}
            <BoxFiles>
              {agreementsList.map((agremmentFile, i) =>
                <>
                 <a href={agremmentFile.base64} download={agremmentFile.fileName}> <StyleDateAgreements key={agremmentFile.fileName}>{agremmentFile.fileName}</StyleDateAgreements></a>
                  {agreementsList[i + 1] === undefined ? '' : <b key={i + 'agremmentFile'}>{' '}/</b>}
                </>
              )}
            </BoxFiles>
            </Divider>
            <Divider>{"Servicios de notificación: "}<StyleDate>{servicesDetail(detailAgreement.services)} </StyleDate></Divider>
            {
              detailAgreement.inactiveReason
              && 
              <Divider>{"Motivo de anulación de vinculación: "}<StyleDate>{detailAgreement.inactiveReason} </StyleDate></Divider>
            }
            {
              detailAgreement.inactiveActionUser
              && 
              <Divider>{"Usuario que anuló la vinculación: "}<StyleDate>{detailAgreement.inactiveActionUser}</StyleDate></Divider>
            }
            {
              detailAgreement.inactiveDate
              && 
              <Divider>{"Fecha y hora de anulación de la vinculación: "}<StyleDate>{formatDate2(detailAgreement.inactiveDate)} </StyleDate></Divider>
            }
            <Divider>{"Estado de la vinculación: "}<StyleDate>{agreementStatus[detailAgreement.status]}
              {detailAgreement.status === ('ACTIVE' || 'EXPIRED') && <Styletext> (No cuenta con permisos de inactivación ni edición)</Styletext>
              } </StyleDate></Divider>
          </ContainerElementContent>
        </ContainerDetailAgreement>

        <ContainerDetailAgreement>
          <TextTitleElement>
            {detailAgreement.providerData.name?`Operador: ${detailAgreement.providerData.name}`:"Operador"}
          </TextTitleElement>
          <ContainerElementContent>
            <Grid columns={3} container stackable>
              <Grid.Column width={5}>
                <Divider>{"Código operador: "}<StyleDate>{detailAgreement.providerData.codeCompany} </StyleDate></Divider>
                <Divider>{"Tipo empresa: "}<StyleDate>{entityType[detailAgreement.providerData.providerDefinition]} </StyleDate></Divider>
                <Divider>{"Tipo persona: "}<StyleDate>{personType[detailAgreement.providerData.typePersonCompany]} </StyleDate></Divider>
                <Divider>{"Tipo documento: "}<StyleDate>{detailAgreement.providerData.typeDocumentCompany} </StyleDate></Divider>
                <Divider>{"Documento: "}<StyleDate>{detailAgreement.providerData.numberDocumentCompany} </StyleDate></Divider>
                <Divider>{"Teléfono: "}<StyleDate>{detailAgreement.providerData.phone === undefined ? 'Dato no ingresado' : detailAgreement.providerData.phone} </StyleDate></Divider>
              </Grid.Column>

              <Grid.Column width={5}>
                <Divider>{"Email: "}<StyleDate>{detailAgreement.providerData.email} </StyleDate></Divider>
                <Divider>{"Dominio: "}<StyleDate>{detailAgreement.providerData.domain === undefined ? 'Dato no ingresado' : detailAgreement.providerData.domain} </StyleDate></Divider>
                <Divider>{"Dirección: "}<StyleDate>{detailAgreement.providerData.address === undefined ? 'Dato no ingresado' : detailAgreement.providerData.address} </StyleDate></Divider>
                <Divider>{"País: "}<StyleDate>{detailAgreement.providerData.country} </StyleDate></Divider>
                <Divider>{"Departamento: "}<StyleDate>{detailAgreement.providerData.department} </StyleDate></Divider>
              </Grid.Column>

              <Grid.Column width={6}>
                <Divider>{"Ciudad: "}<StyleDate>{detailAgreement.providerData.city} </StyleDate></Divider>
                <Divider>{"Representante legal: "}<StyleDate>{detailAgreement.providerData.legalManagerName  + ' ' + detailAgreement.providerData.legalManagerLastName} </StyleDate></Divider>
                <Divider>{"Documento representante legal: "}<StyleDate>{`${detailAgreement.providerData.typeDocumentLegalManager} ${detailAgreement.providerData.numberDocumentLegalManager}`} </StyleDate></Divider>
                <Divider>{"Habilitación de empresa: "}<StyleDate>{entityAactive[detailAgreement.providerData.status]} </StyleDate></Divider>
                <Divider>{"Soporte de autorización: "}
                <BoxFiles>
                {detailAgreement.providerData.supportFiles?.map((supportFile, i) =>
                          <>
                            <a href={supportFile.base64} download={supportFile.originalFileName} > <StyleDateAgreements key={supportFile.fileName}>{supportFile.originalFileName}</StyleDateAgreements></a>
                            {detailAgreement.providerData.supportFiles[i + 1] === undefined ? '' : <b key={i + 'supportFile'}>{' '}/</b>}
                          </>
                        )}
                </BoxFiles>
                </Divider>
              </Grid.Column>
            </Grid>
            <Grid columns={3} container stackable>
            {detailAgreement.providerData.contactInformation?.map((person, index) => {
                    return (
                        <Grid.Column width={5}>
                          <Divider><Title3>Datos contacto {index + 1}</Title3></Divider>
                          <Divider>{"Nombres: "}<StyleDate>{person.name} </StyleDate></Divider>
                          <Divider>{"Apellidos: "}<StyleDate>{person.surNames} </StyleDate></Divider>
                          <Divider>{"Email: "}<StyleDate>{person.email} </StyleDate></Divider>
                          <Divider>{"Teléfono: "}<StyleDate>{person.phoneContact} </StyleDate></Divider>
                          <Divider>{"Cargo: "}<StyleDate>{person.occupation} </StyleDate></Divider>
                        </Grid.Column>
                    )}
                    
            )}
            </Grid>
          </ContainerElementContent>
        </ContainerDetailAgreement>

        <ContainerDetailAgreement style={{marginBottom:'70px'}}>
          <TextTitleElement>
            {detailAgreement.clientData.name?`Cliente: ${detailAgreement.clientData.name}`:"Cliente"}
          </TextTitleElement>
          <ContainerElementContent>
            <Grid container stackable>
              <Grid.Column width={5}>
                <Divider>{"Código cliente: "}<StyleDate>{detailAgreement.clientData.codeCompany} </StyleDate></Divider>
                <Divider>{"Tipo empresa: "}<StyleDate>{entityType[detailAgreement.clientData.providerDefinition]} </StyleDate></Divider>
                <Divider>{"Tipo persona: "}<StyleDate>{personType[detailAgreement.clientData.typePersonCompany]} </StyleDate></Divider>
                <Divider>{"Tipo documento: "}<StyleDate>{detailAgreement.clientData.typeDocumentCompany} </StyleDate></Divider>
                <Divider>{"Documento: "}<StyleDate>{detailAgreement.clientData.numberDocumentCompany} </StyleDate></Divider>
                <Divider>{"Teléfono: "}<StyleDate>{detailAgreement.clientData.phone === undefined ? 'Dato no ingresado' :detailAgreement.clientData.phone} </StyleDate></Divider>
              </Grid.Column>

              <Grid.Column width={5}>
                <Divider>{"Email: "}<StyleDate>{detailAgreement.clientData.email} </StyleDate></Divider>
                <Divider>{"Dominio: "}<StyleDate>{detailAgreement.clientData.domain  === undefined ? 'Dato no ingresado' : detailAgreement.clientData.domain} </StyleDate></Divider>
                <Divider>{"Dirección: "}<StyleDate>{detailAgreement.clientData.address === undefined ? 'Dato no ingresado' : detailAgreement.clientData.address} </StyleDate></Divider>
                <Divider>{"País: "}<StyleDate>{detailAgreement.clientData.country} </StyleDate></Divider>
                <Divider>{"Departamento: "}<StyleDate>{detailAgreement.clientData.department} </StyleDate></Divider>
              </Grid.Column>

              <Grid.Column width={6}>
                <Divider>{"Ciudad: "}<StyleDate>{detailAgreement.clientData.city} </StyleDate></Divider>
                <Divider>{"Representante legal: "}<StyleDate>{detailAgreement.clientData.legalManagerName + ' '+ detailAgreement.clientData.legalManagerLastName} </StyleDate></Divider>
                <Divider>{"Documento representante legal: "}<StyleDate>{`${detailAgreement.clientData.typeDocumentLegalManager} ${detailAgreement.clientData.numberDocumentLegalManager}`} </StyleDate></Divider>
                <Divider>{"Habilitación de empresa: "}<StyleDate>{entityAactive[detailAgreement.clientData.status]} </StyleDate></Divider>
                <Divider>{"Soporte de autorización: "}
                <BoxFiles>
                {detailAgreement.clientData.supportFiles?.map((supportFile, i) =>
                          <>
                            <a href={supportFile.base64} download={supportFile.originalFileName} > <StyleDateAgreements key={supportFile.fileName}>{supportFile.originalFileName}</StyleDateAgreements></a>
                            {detailAgreement.clientData.supportFiles[i + 1] === undefined ? '' : <b key={i + 'supportFile'}>{' '}/</b>}
                          </>
                        )}
                </BoxFiles>
                </Divider>
              </Grid.Column>
            </Grid>
            <Grid columns={3} container stackable>
            {detailAgreement.clientData.contactInformation?.map((person, index) => {
                    return (
                        <Grid.Column width={5}>
                          <Divider><Title3>Datos contacto {index + 1}</Title3></Divider>
                          <Divider>{"Nombres: "}<StyleDate>{person.name} </StyleDate></Divider>
                          <Divider>{"Apellidos: "}<StyleDate>{person.surNames} </StyleDate></Divider>
                          <Divider>{"Email: "}<StyleDate>{person.email} </StyleDate></Divider>
                          <Divider>{"Teléfono: "}<StyleDate>{person.phoneContact} </StyleDate></Divider>
                          <Divider>{"Cargo: "}<StyleDate>{person.occupation} </StyleDate></Divider>
                        </Grid.Column>
                    )}
                    
            )}
            </Grid>
          </ContainerElementContent>
        </ContainerDetailAgreement>
      </Container>
    </>
  )
}

export default AgreementDetail
