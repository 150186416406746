import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { closeDateModal } from '../../../../actions/modalActions'
import FormFilterAttachment from './FormFilterAttachment/FormFilterAttachment'
import DateFilterAttachment from './DateFilterAttachment/DateFilterAttachment'
import { actionSearchAdvanced } from '../../../../actions/filterDetailActions'
import { ReactComponent as Close } from '../../../../assets/icons/close.svg'
import { ModalFilterSignatureSelection } from './ModalFilterSignatureSelection'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
`

const ButtonDeleteFilter = styled.button`
  color: #22C1D0;
  border: none;
  background: #FFFFFF;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`
const ButtonStartFilter = styled.button`
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #22C1D0;
  background: #22C1D0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
`
const ModalDate = styled.div`
  width:100%;
  padding-top: 0px !important;
  @media only screen and (max-width: 1024px) {
    width:100% !important;
    padding-left:0 !important;
}
`

const ModalSent = styled.div`
  width: 80% !important;
  max-width: 854px;
  
  min-height: 575px;
  height: auto; 
  position: relative;
  padding: 20px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  margin-top: 3rem;
  margin-left: 10rem;
  align-items:center;
  @media only screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: -webkit-fill-available;
    margin: -16px 0 0 0 !important;
    
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-center; 
  justify-content: center;
  align-items: center;
  z-index:4000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25.76px;
  right: 24px;
  border: none;
  background: none;
  cursor: pointer;
`;


export const ModalFilterDetailDateAttachment = () => {

  // const mapStateToProps = (state) => ({
//   dateModal: state.modalReducer.dateModal,
//   stateSearch: state.filterDetailReducer.searchAdvanced.notificationState,
//   dateSearch: state.filterDetailReducer.searchAdvanced.date,
//   modalState: state.modalReducer.dateModal
// })

  const dateModal =  useSelector(state => state.modalReducer.dateModal)
  const stateSearch =  useSelector(state => state.filterDetailReducer.searchAdvanced.notificationState)
  const dateSearch =  useSelector(state => state.filterDetailReducer.searchAdvanced.date)
  const modalState =  useSelector(state => state.modalReducer.dateModal)

  const dispatch = useDispatch()

  const [state, setState] = useState({
          attachedFile: false,
          notificationState: '',
          date: {},
          selected:''
        })

  useEffect(() => {
    if (stateSearch === 'NO_REQUIRED_SIGNATURE' || stateSearch === 'REQUIRED_SIGNATURE') {

     setState((prevState)=>({...prevState, notificationState: '',selected: stateSearch}))

    }else{
      setState((prevState)=>({ ...prevState,notificationState: stateSearch }))
    }
  }, [stateSearch])


  const handleOnChange = (e, { value }) => {
    setState((prevState)=>({
      ...prevState,
      notificationState: value
    }), () => {})
  }

  const toggleCheckbox = (e) => {
    let name = e.target.id;
    setState((prevState)=>({
      [name]: !state[name]
    }))
  }

  const reset = () => {
    setState((prevState)=>({
      ...prevState,
      attachedFile: false,
      notificationState: '',
      date: {}
    }))
  }

  const setDateState = (date) => {
    setState((prevState)=>({...prevState, date: date }))
  }

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { notificationDate: -1 },
      'masAntiguo': { notificationDate: 1 },
      'A-Z': { notificationDate: -1 },
      'Z-A': { notificationDate: 1 }
    }
    return options[order]
  }

  const makeBodyRequest = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      starred: false,
      searchAdvanced: {
        date: state.date,
        notificationState: state.notificationState,
        attachedFile: state.attachedFile
      }
    }
    
    body.requiredSignature = state.selected
    
    dispatch(actionSearchAdvanced(body))
    dispatch(closeDateModal())
  }

  const makeBodyRequestDeleteFilter = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      starred: false,
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: ""
        },
        notificationState: '',
        attachedFile: false
      }
    }
    body.requiredSignature = ''
    setState((prevState)=>({
      ...prevState,
      selected: ''
    }))
    dispatch(actionSearchAdvanced(body))
    dispatch(closeDateModal())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    makeBodyRequest()
  }

  const handleDeleteFilter = (e) => {
    e.preventDefault()
    makeBodyRequestDeleteFilter()
    reset()
  }

  const validClose = () => {
    setState((prevState)=>({
      ...prevState,
      notificationState: stateSearch
    }))
    return dispatch(closeDateModal())
  }

  const setSelected = (option) =>{
    setState((prevState)=>({
      ...prevState,
      selected: option
    }))
  }


  
  return (
    <>
      {modalState &&
        <Overlay>
      <ModalSent
        
      >
        <ModalDate>
          <Divider>
            <Texto>Mostrar</Texto>
            <CloseButton onClick={validClose}>
                    <Close />
                  </CloseButton>
          </Divider>
          <div >
            <FormFilterAttachment
              state={state}
              handleOnChange={handleOnChange}
              handleSubmit={handleSubmit}
              toggleCheckbox={toggleCheckbox}
            />
            <Texto>Ver documentos</Texto>
            <ModalFilterSignatureSelection selected={state.selected} setSelected={setSelected}/>
            <Texto2>Período de tiempo</Texto2>
          </div>
          <DateFilterAttachment
            setDateState={setDateState}
          />
        </ModalDate>
        <Divider2>
          <ButtonDeleteFilter
            onClick={handleDeleteFilter}
          >
            Borrar filtros
          </ButtonDeleteFilter>
          <ButtonStartFilter
            onClick={handleSubmit}
          >
            Aplicar filtro
          </ButtonStartFilter>
        </Divider2>
      </ModalSent>
      </Overlay>
    }
  </>
  )
}

export default (ModalFilterDetailDateAttachment) 
