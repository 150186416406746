import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled, { css, keyframes } from 'styled-components'
import { changeReportsLoadingModal } from '../../actions/modalActions'
import PrimaryButton from '../../components/PrimaryButton'
import { ReactComponent as CreateEntity } from '../../assets/icons/createdEntity.svg'
import './reportModal.css'
import { clearEditorChange } from "../../actions/writerActions";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom-v5-compat'

const ModalElement = styled(Modal)`
  padding: 40px !important;
  ${props => !!props.modalData.buttonMessage ? 
    css`
    width: 496px !important;
    `
:
css`
    width: 328px !important;
    `
  }
`

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;


`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-bottom: 15px !important;
`

const DividerContent = styled.div`  
  
  position: relative;
  max-width: 104px;
  max-height:104px;
  justify-content:center;
  margin: 30px 0 30px 0;
`


const MessageModal = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // modalState: state.modalReducer.reportLoadingModal
  const modalState = useSelector(state => state.modalReducer.reportLoadingModal)

  useEffect(() => {
    if (modalState.status === false) {
      dispatch(clearEditorChange(false))
    }
  }, [modalState.status, dispatch])

  const modalData = (type) => {
    const data = {
      reportLoadingMessage:{
        InitialMessage: "Generando reporte",
        message: "Esto podría tardar más de lo esperado. ",
        message2: "Una vez finalice podrás verlo en la lista de reportes.",
        buttonMessage: 'Cerrar',
      },
      reportCreate: {
        icon: <CreateEntity />,
        InitialMessage: "Reporte creado!"
      },
    }
    return data[type]
  }

  const onClose = () => {
    dispatch(changeReportsLoadingModal({ type: modalState.type }))
    navigate('/reports')

  }

  const data = modalData(modalState.type)
  return (
    <ModalElement
      open={modalState.status}
      onClose={onClose}
      closeOnDimmerClick = {modalState.type === 'finish' ? false : true}
      className= {modalState.type === 'noAgreementsActive' && 'modalNoAgreements'}
      modalData={data}
    >
      <ModalContent>
      {!!data.icon && data.icon}
          <ModalMessage>
            <Texto>{data.InitialMessage}</Texto>
            {modalState.type !== 'reportCreate' &&
              <div className='loading-container-report'>
                <div class="lds-spinner-reportLoading"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
            }
            {!modalState.message
              ? data.message
              : modalState.message}
            <div>
                {data.message2}
            </div>
          </ModalMessage>
        {
          modalState.type !== 'reportCreate' &&
          <ModalActions>
            <PrimaryButton
              txtBtn={data.buttonMessage}
              onPress={onClose}
            />
          </ModalActions>
        }
      </ModalContent>
    </ModalElement>
  )
}
MessageModal.propTypes = {
  modalState: PropTypes.object,
  changeMessageModal: PropTypes.func,
  clearEditorChange: PropTypes.func,
}

export default MessageModal
