import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Label from '../../../../components/Label/MainLabel'
import moment from 'moment/moment'
import { Cell, CellDownload, CellIconAttachment, CellIconSelect } from '../../../../components/Table/CellComponent'
import { useDispatch, useSelector } from 'react-redux'
import { downloadMassiveAttachments } from '../../../../actions/seeNotificationAction'
import { UseWindowDimensions } from '../../../../utils/UseWindowsDimentions'
import { CheckBoxGeneric } from '../../../../components/CheckboxForm/CheckBoxGeneric'
import { ReactComponent as Attached } from '../../../../assets/icons/sent/attached.svg'
import { ReactComponent as Download } from '../../../../assets/icons/download-attachment.svg'
import { toast } from 'react-toastify'
import { CHANGE_TOAST_ID } from '../../../../actions/actionsUtilities/types'
import { ReactComponent as IconClose } from '../../../../assets/icons/report/iconCloseDark.svg';

const Row = styled(Table.Row)`
  cursor: default;
  @media only screen and (max-width: 768px) {
    display:flex!important;
    flex-direction: row !important;
  }
`

const SentCellIcon = styled(Table.Cell)`
  display: flex;
  width: 100% !important;
  height:52.44px !important;
  .ui.basic.label{
    min-width: 75.5px;
  }
  @media only screen and (max-width: 1024px) {
    height: 53.35px  !important;
  }
`
const StatusContainer = styled.div`
  float: left!important;
`
const SentCellResponsive = styled(Table.Cell)`
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display block;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  padding: 0.25em 0em!important;
  heigth:52.44px !important;
  @media only screen and (max-width: 1024px) {
    .ui.basic.label{
      min-width: 18px;
      padding-bottom: 0px !important;
      height: 53.44px  !important;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    line-height: 18px;
    .ui.basic.label{
      padding-bottom: 0px !important;
      height: 28px !important;
      max-height: 20px;
    }
  }
`
const Divider0 = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  `

const Divider1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  padding: 0 0 0.5rem 1rem !important;
  `

const Divider2 = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  padding-left:${props => props.haveAttachments ? '0.5rem' : '2%'};
  width: ${props => props.haveAttachments ? '90%' : '100%'};
`;

const Divider3 = styled.div`
  display: flex;
  flex-direction: row;
  color:#000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-bottom:0.5rem;
`;

const Divider4 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
 
`;

const SentTitle = styled.p`
  color:#133F4F;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-right: 0.5rem
  `

  const ContainerWarningMessage = styled.div`
  background: #E8FDFE;
  min-width: 293px;
  width:100%;
  max-height: 40px;
  height: 40px;
  border: 1px solid #4E8D93;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  padding: 12px 24px 12px 24px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;

const ContainerText= styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  gap: 3px;
`;

const ContainerTextOne = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0px;
  text-align: left;
  color: #133F4F;
`;

const IconCloseDark = styled(IconClose)`
  cursor: pointer;
}
`;

export const RowTableSentMasive = (props) => {

  const formatDate = moment(props.data.lastModification).utc().format('DD/MM/YYYY')
  const handleClick = () => {
    props.navigate('/sent-notification-detail/' + props.data.notificationID)
  }
  const [selectDocument, setSelectDocument] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const { haveAttachments } = useSelector(state => state.sentReducer.detailMassiveNotification)
  const dispatch = useDispatch()
  const [showMessage, setShowMessage] = useState(false)
  const {toastId} = useSelector((state) => state.sentReducer)

  useEffect(() => {
    if (showMessage) {
        if (toastId !== null) {
            toast.dismiss(toastId);
        }
        const id = toast(createToast(), { autoClose: 5000 ,
                                        // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
                                    } );
        dispatch({type:CHANGE_TOAST_ID,payload:id});
    }
}, [showMessage]);

  const createToast = () => (
    <ContainerWarningMessage>
        <ContainerText>
            <ContainerTextOne>Descargando archivos</ContainerTextOne>
        </ContainerText>
        <IconCloseDark onClick={closeToast} />
    </ContainerWarningMessage>
);

  const closeToast = () => {
    setShowMessage(false);
    toast.dismiss()
  };


  useEffect(() => {
    setSelectDocument(props.activeAll)
  }, [props.activeAll])

  useEffect(() => {
    setSelectDocument(false)
  }, [props.data])

  const handleSelectDocument = (e) => {
    e.stopPropagation()
    setSelectDocument(!selectDocument)
    if (!selectDocument) {
      props.addCheckedRow(props.data.notificationID)
    } else {
    props.removeCheckedRow(props.data.notificationID)
    }
  }
  const handleClickDownload = (e) =>{
    e.stopPropagation()
    setShowMessage(false);
    setShowMessage(true);
    dispatch(downloadMassiveAttachments({notificationSerialIDList: [props.data.notificationID]}))
    .finally(() => {
      setShowMessage(false);
  });
  }

  const windowDimensions = UseWindowDimensions();
 
  return (
    windowDimensions.width > 768 ?
    <Row  key={props.data.notificationID} onClick={handleClick} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      {haveAttachments ? <CellIconSelect width={1} onChange={handleSelectDocument} checked={selectDocument} />: <Cell width={1} ></Cell>}
      <Cell width={2} columnName={props.headerTable[1].name} text={props.data.nameRecipient}></Cell>
      <CellIconAttachment width={4} haveAttachments={haveAttachments} />
      <Cell width={4} columnName={props.headerTable[3].name} text={formatDate}></Cell>
          <SentCellIcon width={2} textAlign='center'>
            <StatusContainer>
              {props.data.recipient !== null ?
                <Label style={{ minWidth: '75px' }} text={props.data.currentStatus.name} icon={props.data.currentStatus.id} color={props.data.currentStatus.color} />
                : ''}
            </StatusContainer>
          </SentCellIcon>
                
      { !showMessage ?
      <CellDownload haveAttachments={haveAttachments} width={3} onChange={handleSelectDocument} checked={selectDocument}
        isVisible={isVisible && !selectDocument && haveAttachments} handleClickDownload={handleClickDownload} />
        :
        <Cell width={3} onClick={(e)=>{e.stopPropagation()}} >
          <div style={{display:'flex', alignItems:'center', color: '#22C1D0', a: 'center', fontSize: '14px',opacity:'0.3' }} onClick={(e)=>{}}>
            <i class={"loading spinner icon"} style={{ marginRight:'5px' }}></i>
            <div style={{ marginLeft:'5px' }} >Descargando adjuntos</div>
          </div>
        </Cell>
      }
    </Row>
    :
    <Row onClick={handleClick}>
      <Divider0>
        {haveAttachments && <Divider1>
              <CheckBoxGeneric onChange={handleSelectDocument} checked={selectDocument} />
        </Divider1>}
        <Divider2 haveAttachments={haveAttachments}>
          <Divider4>
            <Divider3><SentTitle>No. documento:</SentTitle> <p>{ props.data.nameRecipient}</p></Divider3>
            {haveAttachments ? <Attached/> : <div></div>}
          </Divider4>
          <Divider3><SentTitle>Última modificación</SentTitle> <p>{formatDate}</p></Divider3>
          <Divider4>
            {props.data.recipient !== null ?
                <Label style={{ minWidth: '75px' }} text={props.data.currentStatus.name} icon={props.data.currentStatus.id} color={props.data.currentStatus.color} />
                : ''
            }
            
            {!selectDocument && haveAttachments && <div onClick={handleClickDownload} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
              <Download style={{ margin: '0px 6px 0px 0px' }} />
              <div style={{color:'#22C1D0', height: '22px'}}>Descargar adjuntos</div>
            </div>  } 
          </Divider4>
        </Divider2>
      </Divider0>
    </Row>
  )
}

RowTableSentMasive.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableSentMasive