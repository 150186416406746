import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import styled from 'styled-components'
import MassiveLoadContent from './MassiveLoadContent'
import { useDispatch, useSelector } from 'react-redux'
import { sendMassiveFileV2 } from '../../../actions/modalActions'
import { Form } from 'semantic-ui-react'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import { Dimmer, Loader } from 'semantic-ui-react'

const ContainerScroll = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`

const MassiveLoad = (props) => {

  const [loader, setLoader] = useState(true)
  const { columnList, fileSerialID } = useSelector((state) => state.writerReducer.resultValidateFileMassive)
  //const fileInfo = useSelector((state) => state.writerReducer.fileMassive)
  const [companyName, setCompanyName] = useState('')
  const dispatch = useDispatch()
  const [errors] = useState({})
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)
  const valueFile = useSelector(state => state.writerReducer.fileInfoValue)

  useEffect(() => {
    dispatch(sendMassiveFileV2(valueFile, props.navigate)).then(()=>{
      setLoader(false)
    })
  }, [])


  const onChangeCompanyName = e => {
    setCompanyName(e.target.value)
  }

  return (
    <>
     <Dimmer.Dimmable>
     <Dimmer active={loader} inverted >
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
      <ContainerScroll>
        <CustomForm>
          <Header
            errors={errors}
            companyName={companyName}
            onChangeCompanyName={onChangeCompanyName}
            validator={validator}
          />
          <MassiveLoadContent
            data={columnList}
            navigate={props.navigate}
            errors={errors}
            validator={validator}
          />
          <Footer
            navigate={props.navigate}
            errors={errors}
            companyName={companyName}
            fileSerialID={fileSerialID}
            validator={validator}
            showValidationMessage={showValidationMessage}
          />
        </CustomForm>
      </ContainerScroll>
      </Dimmer.Dimmable>
    </>
  )
}

MassiveLoad.propTypes = {
  navigate: PropTypes.any.isRequired,
}

export default MassiveLoad