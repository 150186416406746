import React, { Component } from 'react'
import AttachButton from '../../components/InputFile/AttachButton'

const ToolBarContent = (props) => {
    return(
        <div id="toolbar">
            <span class="ql-formats" title="Adjuntar archivos">
                <AttachButton
                onSelectFile={props.onChangeHandlerAttachment}
                accept='application/pdf'
                id='attachment'
                popUp='Adjuntar archivos'
                disabled={props.disableAtt}
                buttonAttachment={<button id={props.disableAtt?"attachment_disabled":"attachment"}></button>}
                />
            </span>
            <span class="ql-formats">
                <button class='ql-bold' title="Negrita"></button>
                <button class='ql-italic' title="Crusiva"></button>
                <button class='ql-underline' title="Subrayado"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-align" value="center" title="Alinear en el centro"></button>
                <button class="ql-align" value="justify" title="Justificado"></button>
                <button class="ql-align" value="left" title="Alinear a la izquierda"></button>
                <button class="ql-align" value="right" title="Alinear a la derecha"></button>
            </span>
            <span class="ql-formats">
                <select class="ql-size" title="Tamaño de fuente">
                    <option value="10px">Pequeño</option>
                    <option value= "16px" selected></option>
                    <option value="22px">Mediano</option>
                    <option value="28px">Grande</option>
                </select>
                <select class="ql-color" title="color de fuente">
                    <option value="#4D4D4D"></option>
                    <option value="#999999"></option>
                    <option value="#FFFFFF"></option>
                    <option value="#F44E3B"></option>
                    <option value="#FE9200"></option>
                    <option value="#FCDC00"></option>
                    <option value="#DBDF00"></option>
                    <option value="#A4DD00"></option>
                    <option value="#68CCCA"></option>
                    <option value="#73D8FF"></option>
                    <option value="#AEA1FF"></option>
                    <option value="#FDA1FF"></option>

                    <option value="#333333"></option>
                    <option value="#808080"></option>
                    <option value="#CCCCCC"></option>
                    <option value="#D33115"></option>
                    <option value="#E27300"></option>
                    <option value="#FCC400"></option>
                    <option value="#B0BC00"></option>
                    <option value="#68BC00"></option>
                    <option value="#16A5A5"></option>
                    <option value="#009CE0"></option>
                    <option value="#7B64FF"></option>
                    <option value="#FA28FF"></option>

                    <option value="#000000" selected></option>
                    <option value="#666666"></option>
                    <option value="#B3B3B3"></option>
                    <option value="#9F0500"></option>
                    <option value="#C45100"></option>
                    <option value="#FB9E00"></option>
                    <option value="#808900"></option>
                    <option value="#194D33"></option>
                    <option value="#0C797D"></option>
                    <option value="#0062B1"></option>
                    <option value="#653294"></option>
                    <option value="#AB149E"></option>
                </select>
            </span>
            
            
    </div>
    )
}
      
export default (ToolBarContent)
