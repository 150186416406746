import React from 'react'
import { Modal } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { ReactComponent as CloseDark } from '../../../assets/icons/closeDark.svg'
import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import InputSendMassiveFile from "../../InputFile/InputSendMassiveFile"
import { fileToBase64Clean } from "../../../helpers/filesLib"
import { closeSendMassiveModal, sendMassiveFileV2 } from '../../../actions/modalActions'
import { useDispatch, useSelector } from 'react-redux'
import ListDescription from './ListDescription'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import './style.css'
import { FILE_MASSIVE_DATA, SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL } from '../../../actions/actionsUtilities/types'
import { downloadUsersGuide, setFileInfoValue } from '../../../actions/writerActions'
import { useNavigate } from 'react-router-dom-v5-compat'


const CustomModal = styled(Modal)`
  ${props => props.modalheight &&
    css`
            height: ${props.modalheight};
          `}
  ${props => props.modalwidth &&
    css`
            width: ${props.modalwidth};`}
  @media screen and (max-width:792px){
    width: 100% !important;
    border-radius: 0 !important;
    height: 100vh !important;
    margin: 0 !important;
    overflow-y: auto;
  }
  @media only screen and (min-width: 1200px){
   
        width: 862px !important;
   
}
`
const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  @media screen and (max-width:792px){
    position: absolute;
    top: 24px;
    right: 24px;
 }
`
const Texto = styled.p`
  color: #133F4F;
  font-weight: 900;
  font-size: 30px;
  line-height:35.16px
  font-style: normal;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 44px;
  @media screen and (max-width:792px){
    text-align: inherit;
    margin: 0;
  }
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  @media screen and (max-width:792px){
    width: 95%;
    margin:auto;
    display: block;
  }
`
const Divider3 = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding-bottom: 56px;
`
const ButtonPlanilla = styled.button`
  width: 233px;
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  height: 48px;

  @media screen and (max-width:792px){
    width:100%;
}
`
const ModalDescription = styled(Modal.Description)`
  margin: 16px;
  @media screen and (max-width:792px){
    margin: 24px;
  }
`

const CustomLink = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  padding-left:5px;
  color: #22C1D0;
  text-decoration-line: underline;
  cursor: pointer;
`
export const ModalSendMassiveTwo = () => {

  const dispatch = useDispatch()
  const { sendMassiveModal } = useSelector((state) => state.modalReducer)
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)
  const navigate = useNavigate()

  const handleCloseModal = () => {
    dispatch(closeSendMassiveModal())
  }

  const handleChangeFile = async (e) => {
    const fileInput = e.target;
    const file = e.target.files
    if (file.length) {
      if (file[0].size <= 10458760) {//10458760 = valor de 10 mb en windows
        const fileInfo = {
          fileName: file[0].name,
          fileExtension: file[0].type.split("/").pop(),
          base64: await fileToBase64Clean(file[0].name, file[0]),
        }
        if (fileInfo.fileExtension === 'csv') {
          dispatch({
            type: FILE_MASSIVE_DATA, payload: {
              massiveDataExtension: fileInfo.fileExtension,
              massiveDataName: fileInfo.fileName,
              massiveData64: fileInfo.base64
            }
          })
           dispatch(setFileInfoValue(JSON.stringify(fileInfo)))
          dispatch(sendMassiveFileV2(fileInfo, navigate))
        } else {
          dispatch({ type: SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL, payload: { status: 'visible', message: 'El formato del archivo fue modificado o no contiene la información correcta, verifícalo y vuelve a intentarlo.' } })
        }
      } else {
        dispatch({ type: SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL, payload: { status: 'visible', message: 'El archivo supera el limite de 10MB permitido.' } })
      }
      fileInput.value = '';
    }
  }

  const downloadUserGuide = async (fileSerialID) => {
    const url = await dispatch(downloadUsersGuide())
    const urlTemp = 'https://demo-t.gse.com.co/documents/handbooks/manual_de_ayuda_portal_notificaciones.pdf';
    const link = document.createElement("a");
    link.href = url
    link.target = '_blank'
    link.download = urlTemp.split('/')[5]
    link.setAttribute("download", urlTemp.split('/')[5]);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <CustomModal
      onClose={handleCloseModal}
      open={sendMassiveModal.status}
      className="massive-modal"

    >
      <>
        <ModalDescription>
          <Divider>
            <CloseDark onClick={handleCloseModal} />
          </Divider>
          <Texto>Carga masiva</Texto>
          <ListDescription />
        </ModalDescription>
        <Divider2>
          <a
            href={require("../../../utils/Formato_Plantilla.csv")}
            download="Formato_Plantilla.csv">
            <ButtonPlanilla>
              <Download style={{ marginRight: "16px" }} />
              Descargar plantilla
            </ButtonPlanilla>
          </a>
          <InputSendMassiveFile
            id='sendMassiveTemplate'
            content='Cargar plantilla masiva'
            name='sendMassiveInputFile'
            accept='.csv'
            onSelectFile={handleChangeFile}
            validator={validator}
            validateOptions='required'
          />
        </Divider2>
        <Divider3>
          <a onClick={downloadUserGuide}> <CustomLink >Ver manual de usuario</CustomLink></a>
        </Divider3>
      </>
    </CustomModal>
  )
}