import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import MenuButton from './MenuButton'
import WriteButton from './WriteButton'
import { ReactComponent as CloseX } from '../../../assets/icons/menu/close-icon.svg'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'
import { SHOW_AVATAR_INFO } from '../../../actions/actionsUtilities/types'
import { Button, Checkbox, Popup } from 'semantic-ui-react'
import { updateViewAvatarInfo } from '../../../actions/authActions'

const ContentDataUserMenu = styled.div`
  width:100%;
  height: 80px;
  display:none;
  background: #133F4F;
  position:relative;
  @media only screen and (max-width: 1280px) {
    display: contents;
  }
`

const ContainerClose = styled.div`
  width:100vw;
  height: 50px;
  background: #133F4F;
  display: none;
  @media only screen and (max-width: 768px) {
    display: table-cell;
  }
`
const ContentOptions = styled.div`
`

const CloseXIcon = styled(CloseX)`
  width: 24px;
  height: 24px;
  margin: 15px;
  float: right;
  cursor: pointer;
  z-index:1902;
`
const CustomCheckbox = styled(Checkbox)`
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 18px !important;
    height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
  }
  input:checked ~ .box:after, input:checked ~ label:after {
    color: #0091F9 !important;
    background: #FFFFFF 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 1px solid #FFFFFF !important;
    width: 19px;
    height: 19px;
  }
  & label:before {
    border: 2px solid #ffffff !important;
    border-radius: 2px !important;
    width: 18px !important;
    height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
    background: #0091F9 !important;
  }
  input[disabled] ~ label{
    opacity: 0.2 !important;
  }
  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
    }
@media only screen and (max-width: 768px) {
    & label:before {
        width: 17px !important;
        height: 17px !important;
        max-width: 18px !important;
        max-height: 18px !important;
    }
}
    padding: 2px 0 0 0 !important;
    margin: 11px 5px -3px 0;
`
const CustomButton = styled(Button)`
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  border: 1px solid #ffffff !important;
  border-radius: 4px !important;
  padding: 16px 32px !important;
  background: #0091F9 !important;
  color: #FFFFFF !important;
  margin: -10px auto 0 auto !important;
  display: block !important;
`
const PopupUser = styled(Popup)`
  padding: 22px !important;
  position:relative;
  font-size:16px !important;
  font-weight: 500 !important;
  gap: 16px; 
  border-radius: 4px 0px 0px 0px; 
  opacity: 0px;
  border: 0px !important;
  background: #0091F9 !important;
  color: #FFFFFF !important;
  width: 328px;
  max-width: unset !important;
  height: 209px;
  z-index: 1900;
  top: 88px !important;
  //right: 50% !important;
  :before{
    background: #0091F9 !important;
    left: 80px!important;
  }
  @media only screen and (max-height: 900px)  {
    top:13vh !important;
  }
  @media only screen and (max-width: 768px) {
    position: fixed !important;
    left: -66vw !important;
  }
  @media only screen and (max-width: 510px) {
    left: -80vw !important;
    :before{
      left: 130px!important;
    }
  }

  @media only screen and (max-width: 425px) {
    left: -93vw !important;
    :before{
      left: 90px!important;
    }
  }

`
const Blurred = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(0,0,0, 0.7);
  z-index: 1755;
  position: absolute;
`


export const UsersMenu = ({ menuRoutes, redirect, url, changeView, dataUser, menuIsOpen }) => {

  const { showAvatarInfo } = useSelector((state) => state.authReducer)
  const dispatch = useDispatch()

  const validateRouteClidren = (route) => {
    if (route.children) {
      for (let i = 0; i < route.children.length; i++) {
        const child = route.children[i];
        if (child === url.split('/')[1])
          return true
      }
    }
    return false
  }

  const [showMessage, setShowMessage] = useState(false)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setShowMessage(showAvatarInfo)
  }, [showAvatarInfo])

  const handleClick = () => {
    setShowMessage(false)
    dispatch({ type: SHOW_AVATAR_INFO, payload: false })
    if (checked) dispatch(updateViewAvatarInfo({ viewInfoAvatar: false }))
  }

  // useEffect(() => {
  //   dispatch(updateViewAvatarInfo({ viewInfoAvatar: true }))
  // }, [])
  
  const onChange = () => {
    setChecked(!checked)
  }
  var { width } = UseWindowDimensions()

  return (
    <>
      {showMessage && width <= 768 && menuIsOpen &&
        <Blurred>
          <PopupUser style={{ position: 'relative' }} position='bottom right' open={true}>
            Toca en el ávatar para ver tus datos, cambiar de rol (si tienes más de uno) y/o cerrar sesión.
            <br />
            <CustomCheckbox onChange={onChange} checked={checked} /> No volver a mostrar
            <br />
            <br />
            <CustomButton onClick={handleClick}> Entendido </CustomButton>
          </PopupUser>
        </Blurred>
      }
      <ContentDataUserMenu>
        <ContainerClose onClick={changeView}>
          <CloseXIcon />
        </ContainerClose>
        {dataUser}
      </ContentDataUserMenu>
      <ContentOptions onClick={changeView}>
        {
          menuRoutes &&
          menuRoutes.map((value, index) => {
            return (
              <div key={index}>
                {
                  index === 0 && value.name === "write"
                    ? <WriteButton value={value} />
                    :
                    <MenuButton
                      value={value}
                      active={url === value.component || validateRouteClidren(value)}
                      redirect={redirect}
                    />
                }
              </div>
            )
          })
        }
      </ContentOptions>
    </>
  )
}

export default UsersMenu
