import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralTable from "../../../../components/Table/GeneralTable";
import {
  actionClearFilter,
  activePagination,
} from "../../../../actions/filterDetailActions";
import { getLogsList } from "../../../../actions/clientActions";
import _ from "lodash";
import RowTableLogs from "./RowTableLogs";

const Logs = (props) => {
  const filterReducer = useSelector((state) => state.filterDetailReducer);
  const clientDetail = useSelector((state) => state.providerReducer.detailProvider);
  const logsList = useSelector((state) => state.clientReducer.logsList);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    loader: true,
    init: true,
  });

  const prevFilterReducer = useRef(filterReducer);
  const prevClientDetail = useRef(clientDetail);

  useEffect(() => {
    if (filterReducer.tabFilter !== "Logs") {
      setState({ ...state, init: false });
      dispatch(actionClearFilter());
    } else {
      makeLogsRequest(filterReducer);
    }
  }, []);

  useEffect(() => {
    if (!state.init && !_.isEqual(prevClientDetail.current, clientDetail)) {
      makeLogsRequest(filterReducer);
      setState({ ...state, init: true, loader: true });
    }

    prevClientDetail.current = clientDetail;
    
  }, [clientDetail]);

  useEffect(() => {
    if (!_.isEqual(prevFilterReducer.current, filterReducer)) {
      makeLogsRequest(filterReducer);
      setState({ ...state, init: true, loader: true });
    }

    prevFilterReducer.current = filterReducer;

  }, [filterReducer]);

  const makeLogsRequest = () => {
    const body = {
      companySerialID: clientDetail.companySerialID,
      pag: filterReducer.pag,
      count: !!filterReducer.count ? filterReducer.count : 20,
      order: orderTranslator(filterReducer.sort),
      userName: filterReducer.searchBar[0],
      action: filterReducer.logsFilter.action,
    };
    dispatch(getLogsList(body)).then(() => {
      dispatch(activePagination(false));
      setState({ ...state, init: false, loader: false });
    });
  };

  const orderTranslator = (order) => {
    const options = {
      masReciente: -1,
      masAntiguo: 1,
    };
    return options[order];
  };

  const columnData = [
    { name: "Acción", width: 2, arrow: false },
    { name: "Usuario", width: 2, arrow: false },
    { name: "Fecha y hora", width: 2, arrow: false },
  ];

  return (
    <GeneralTable
      list={logsList}
      typeList="logs"
      headerTable={columnData}
      rowComponent={RowTableLogs}
      loader={state.loader}
      heigthHeader={"45px"}
      noWrap={false}
    />
  );
};

export default Logs;
