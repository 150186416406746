import React from 'react';
import { MainSegment } from '../../../components/Sengment/MainSegment'
import CreateUsers from './CreateUsers';

const AddUsers = () => {
  return (
    <MainSegment>
      <CreateUsers />
    </MainSegment>
  );
};

export default AddUsers;