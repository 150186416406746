import {
  GET_REPORTS_LIST,
  DETAIL_CSV_ERROR,
  REPORT_DETAIL,
  CHANGE_TYPE_REPORT_CURRENT,
  GET_CLIENTS_REPORT,
  GET_All_CLIENTS_REPORT,
  CHANGE_DATA_CREATE_REPORT,
  GET_RESPONSE_CREATE_REPORT,
  RESPONSE_BASE64_REPORT,
  SUCCESS_CREATE_REPORT,
  CHANGE_TO_BE_NOTIFIED,
  INTERVAL_TO_BE_NOTIFIED,
  CLEAR_INTERVAL_TO_BE_NOTIFIED,
  GET_ALL_CAMPAIGN_FOR_DATE,
  CHANGE_ACTIVE_INTERVAL,
  CHANGE_LIST_CREATE_REPORT,
  LOGOUT,
} from "../actions/actionsUtilities/types"
import moment from 'moment'

const initialState = {
  reportsList: [],
  elementsCount: 0,
  clientsList: [],
  typeReportCurrent: "STATUS",
  detailCsvError: {
    typeReport: '',
    initialDate: '',
    finalDate: '',
    documentNumber: '',
    subject: '',
    reportStatus: '',
    base64Report: '',
  },
  reportDetail: {
    reportSerialID: '',
    initialDate: '',
    finalDate: '',
    client: '',
    notificationStatus: [],
    documentRecipient: '',
    subject: '',
    reportStatus: '',
    base64: '',
    reportType: '',
  },
  dataCreateReport: {
    reportType: '',
    status: ["ENABLE", "DISABLE"],
    providerType: ''
  },
  responseCreateReport: {},
  responseBase64Report: {},
  successCreateReport: false,
  toBeNotifiedList: [],
  activeInterval: false,
  intervalToBeNotified: null,
  listCampaign: [],
  optionsCampaign: [],
  isCreateReport: false,
}
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_LIST:
      return {
        ...state,
        reportsList: action.payload.reportsList,
        elementsCount: action.payload.elementsCount
      }
    case DETAIL_CSV_ERROR:
      return {
        ...state,
        detailCsvError: action.payload
      }
    case REPORT_DETAIL:
      return {
        ...state,
        reportDetail: action.payload
      }
    case CHANGE_TYPE_REPORT_CURRENT:
      return {
        ...state,
        typeReportCurrent: action.payload
      }
    case GET_CLIENTS_REPORT:
      return {
        ...state,
        clientsList: [{ key: 'empty', text: '', value: '' }, ...action.payload.map((client, index) => {
          return { key: index, text: client.name, value: client.companySerialID }
        })]
      }
    case GET_All_CLIENTS_REPORT:
      return {
        ...state,
        clientsList: [{ key: 'empty', text: '', value: '' }, ...action.payload.map((client, index) => {
          return { key: index, text: client.name, value: client.companySerialID }
        })]
      }
    case CHANGE_DATA_CREATE_REPORT:
      return {
        ...state,
        dataCreateReport: action.payload
      }
    case GET_RESPONSE_CREATE_REPORT:
      return {
        ...state,
        responseCreateReport: action.payload
      }
    case RESPONSE_BASE64_REPORT:
      return {
        ...state,
        responseBase64Report: action.payload
      }
    case SUCCESS_CREATE_REPORT:
      return {
        ...state,
        successCreateReport: action.payload
      }
    case CHANGE_TO_BE_NOTIFIED:
      return {
        ...state,
        successCreateReport: true,
        toBeNotifiedList: action.payload.map((item, index) => {
          return { visible: true, isHide: false, reportType: item.reportType, reportDate: item.reportDate, reportStatus: item.reportStatus }
        })
      }
    case INTERVAL_TO_BE_NOTIFIED:
      return {
        ...state,
        intervalToBeNotified: action.payload
      }
    case CLEAR_INTERVAL_TO_BE_NOTIFIED:
      clearInterval(state.intervalToBeNotified)
      return {
        ...state,
        intervalToBeNotified: null
      }
    case GET_ALL_CAMPAIGN_FOR_DATE:
      return {
        ...state,
        listCampaign: action.payload.listCampign,
        optionsCampaign: [/*{ key: 'empty', text: '', value: ''},*/ ...action.payload.listCampign.map((campaign, index) => {
          return { key: index, text: `${campaign.campaignName}`, text2: ` ${campaign.companyName} / ${campaign.notificationDateFormat}.`, value: campaign.campaignName, notificationDate:campaign.notificationDate }
        })]
      }
    case CHANGE_ACTIVE_INTERVAL:
      return {
        ...state,
        activeInterval: action.payload
      }
    case CHANGE_LIST_CREATE_REPORT:
      return {
        ...state,
        isCreateReport: action.payload
      }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}