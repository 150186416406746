import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Form } from "semantic-ui-react";
import InputFormDropdown from '../InputAnimatedGeneralForms/InputFormDropdown'
import { useDispatch, useSelector } from "react-redux";
import useValidator from "../../helpers/simpleReactValidatorHook";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";
import SimpleReactValidator from 'simple-react-validator'
import InputFormDate from "../InputAnimatedGeneralForms/InputDate";
import InputSelectClient from "../InputAnimatedGeneralForms/InputSelectClient";
import InputCheckbokForm from "../InputAnimatedGeneralForms/InputCheckboxForm";
import InputFormDropdownSearch from "../InputAnimatedGeneralForms/InputFormDropdownSearch"
import InputSelectAgreement from "../InputAnimatedGeneralForms/InputSelectAgreement"
import { ReactComponent as ReportIcon } from "../../assets/icons/report/iconCreateReport.svg";
import { InputRadio } from "../InputAnimatedGeneralForms/InputRadio";
import { getAllClientSearch, getClientSearch, sendReport, getListCampign } from "../../actions/reportsActions";
import { CHANGE_STATE_REPORTS_LOADING_MODAL } from "../../actions/actionsUtilities/types";
import { PopupAlertReport } from "./popupAlertReport/PopupAlertReport";
import { useNavigate } from "react-router-dom-v5-compat";

const Divider1 = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 15px;
  }
`;
const Divider2 = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  align-items: center;
`;
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  width: 50%;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    margin: 0;
  }
`;
const Divider = styled.div`
  margin-bottom: 16px;
`;
const ContainerFormDate = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between !important;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`;
const ContainerPositionDateOne = styled.div`
  width: 48%;
  @media only screen and (max-width: 700px) {
    //width: 100%;
    float: left;
  }
`;
const ContainerPositionDateTwo = styled.div`
  width: 48%;
  @media only screen and (max-width: 700px) {
   // width: 100%;
   // margin-top: 8px;
    float: right;
  }
`;
const TextDate = styled.p`
  color: #133f4f;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  margin: 0px 0 8px 0 !important;
`;
const Text = styled.p`
  color: #133f4f;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 16px 0 !important;
`;
const Text2 = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 16px 0 !important;
  opacity: 0.3;
`;
const TextError = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #C3132E;
  margin-left: 16px;
  margin-top: 5px;
`;
const ContainerRadioError = styled.div`
  display: flex;
`;
const ContainerChecbook = styled.div`
  display: flex;
  margin-left: 50px;
`;
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 16px;
  @media only screen and (max-width: 1200px) {
    display: block;
  }
`;
const ButtoSave = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 180px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  height: 48px;
  border: 1px solid #22c1d0;
  border-radius: 4px;
  background: #22c1d0;
  cursor: pointer;
  padding: 0 32px;
  white-space: nowrap;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 0;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    margin: 0;
  }
`;
const reportOptions = [
  { key: "", text: "", value: "" },
  {
    key: "STATUS_SINGLE",
    text: "Reporte de estado",
    value: "STATUS_SINGLE"
  },
  {
    key: "WRONG_NOTIFICATION",
    text: "Reporte notificaciones erróneas",
    value: "WRONG_NOTIFICATION",
  },

];

const initialState = {
  typeReport: "",
  initialDate: "",
  finalDate: "",
  recipient: {
    firstName: "",
    secondName: "",
    surname: "",
    secondSurname: "",
    fullName: "",
    documentNumber: ""
  },
  subject: "",
  client: "",
  clientForCampaign: "",
  individual: true,
  massive: false,
  specificCampaign: false,
  nameCampaign: '',
  notificationDate: '',
  all: true,
  selectState: false,
  unRead: false,
  novelty: false,
  pending: false,
  read: false,
  complete: false,
  rejected: false,
  error: false,
  cedulaNumber: "",
  subjectNotification: "",
}

export const FormCreateReport = () => {
  const clientsList = useSelector(state => state.reportsReducer.clientsList)
  const listCampaign = useSelector(state => state.reportsReducer.listCampaign)
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [validator, showValidationMessage] = useValidator(
    reactValidatorOptions
  );
  const formValidator = useRef(new SimpleReactValidator(reactValidatorOptions))

  const [errorStateNotification, setErrorStateNotification] = useState(false);
  const [errors] = useState({});
  const [values, setValues] = useState(initialState);

  const [dataHasMore, setDataHasMore] = useState (true)

  const InfiniteScrollOptionsCampaign = useSelector(state => state.reportsReducer.optionsCampaign)
  const [concatInfiniteScrollSpecificCampaign, setConcatInfiniteScrollSpecificCampaign]= useState([{ key: 'empty', text: '', value: '',notificationDate:''}])
  const [objectFilterSpecificCampaign, setObjectFilterSpecificCampaign]= useState (
    {
      count: 20,
      pag: 1,
    }
  )
  const [isChangeDate, setIsChangeDate]= useState(false)
  
  useEffect(() => {
  }, [dataHasMore])

  useEffect(() => {
    dispatch(getClientSearch(''))
  }, [])

  useEffect(() => {
    if(values.initialDate !== '' && values.finalDate !== ''){
      dispatch(getListCampign({
        "startDate": values.initialDate,
        "endDate": values.finalDate,
        "companySerialID": values.client,
        "textToSearch": values.nameCampaign,
        "count": objectFilterSpecificCampaign.count,
        "pag": objectFilterSpecificCampaign.pag,
      }))
    }
  }, [objectFilterSpecificCampaign])


  useEffect(() =>{
    if(concatInfiniteScrollSpecificCampaign !== InfiniteScrollOptionsCampaign){
      if(concatInfiniteScrollSpecificCampaign.length > 0 && !InfiniteScrollOptionsCampaign.length){
        setDataHasMore(false)
      }else{
        setDataHasMore(true)
        if(InfiniteScrollOptionsCampaign.length < 20){
          setDataHasMore(false)
        }else{
          setDataHasMore(true)
        }
        setConcatInfiniteScrollSpecificCampaign(
          concatInfiniteScrollSpecificCampaign.concat(InfiniteScrollOptionsCampaign)
        )
      }
    }
  },[InfiniteScrollOptionsCampaign])

  useEffect(() => {
    if(values.initialDate !== '' && values.finalDate !== ''){
      setConcatInfiniteScrollSpecificCampaign([{ key: 'empty', text: '', value: '',notificationDate:''}])
      setObjectFilterSpecificCampaign({count: 20,pag: 1})
      setIsChangeDate(true)
      setValues((prevState)=>({
        ...prevState,
        nameCampaign: '',
       }))
    }
  }, [values.initialDate,values.finalDate, values.client])



  const handleOnChange = (e,  {id, value} ) => {
    let name = id
    if (name === 'client') setSearchQuery('')
    if (name === 'typeReport') {
      setValues({
        ...values,
        ...initialState,
        [name]: value,
        specificCampaign: false
      })
    }else{
      setValues({
        ...values,
        [name]: value,
      })
    }
  };

  const handleOnFocusSearchClient = () => {
    if (!searchQuery) {
      dispatch(getAllClientSearch())
    }
  }

  const onChange = (e) => {
    const name = e.target.id;
    if (e.target.id === "documentNumber") {
      setValues({
        ...values,
        recipient: {
          documentNumber: e.target.value
        }
      })
    } else {
      setValues({
        ...values,
        [name]: e.target.value,
      });
    }
  };

  const onChangeDate = (e) => {
    const name = e.target.id;
    setValues({
      ...values,
      [name]: e.target.value,
      finalDate: name === "initialDate" ? "": name === "finalDate"? e.target.value: values.finalDate
    });
  };

  const toggle = (e) => {
    let name = e.target.id;
    setValues({
      ...values,
      [name]: !values[name],
    });
    if (!values[name] === true) {
      setErrorStateNotification(false);
    }
  };

  const handleOnChangeCampign = (event, { value }) => {
    let dataValue = value;
  
    if (event.currentTarget.hasOwnProperty('name')) {
      dataValue = event.currentTarget.getAttribute('name');
    } else {
      if (value === undefined) {
        dataValue = event.currentTarget.getAttribute('name');
      }
    }

    const campaing = concatInfiniteScrollSpecificCampaign.find(
      (campaign) => campaign.text=== dataValue
    );

    setValues({
      ...values,
      nameCampaign: dataValue,
      notificationDate: campaing !== undefined ? campaing.notificationDate : '' ,
      unRead: dataValue === '' ? false : values.unRead,
      novelty: dataValue === '' ? false : values.novelty,
      pending: dataValue === '' ? false : values.pending,
      read: dataValue === '' ? false : values.read,
      complete: dataValue === '' ? false : values.complete,
      rejected: dataValue === '' ? false : values.rejected,
      error: dataValue === '' ? false : values.error,
    });
  };

  const handleChangeRadio = (e) => {
    let name = e.target.id;
    if (name === "all") {
      setValues({
        ...values,
        all: true,
        selectState: false,
        unRead: false,
        novelty: false,
        pending: false,
        read: false,
        complete: false,
        rejected: false,
        error: false,
        individual: values.individual,
        massive: values.massive,
      });
    } else if (name === "selectState"){
      setValues({
        ...values,
        all: false,
        selectState: true,
        individual: values.individual,
        massive: values.massive,
      });
    } else if (name === "individual") {
      setValues({
        ...values,
        individual: true,
        massive: false,
        specificCampaign: false, 
        nameCampaign: '',
      });
    } else {
      setValues({
        ...values,
        individual: false,
        massive: true,
      });
    }
  };
  const makeSaveNotificationReq = () => {
    if (values.typeReport === 'WRONG_NOTIFICATION') {
      const body = {
        "initialDate": values.initialDate,
        "finalDate": values.finalDate,
        "reportType": values.typeReport,
        "documentNumber": values.recipient.documentNumber,
        "subject": values.subject,
      }
      dispatch(sendReport(body, navigate));

    } else if (values.typeReport === 'STATUS_SINGLE') {
      if(values.individual === true){
        const body = {
          "reportType": "STATUS_SINGLE",
          "notificationStatus": values.all ? ['READ', 'UNREAD', 'PENDING', 'REJECTED', 'FINALIZED', 'RELEASE', 'ERROR'] : selectedStates(),
          "documentNumber": values.recipient.documentNumber,
          "subject": values.subject,
          "companySerialID": values.client,
          "initialDate": values.initialDate,
          "finalDate": values.finalDate,
        };
        dispatch(sendReport(body, navigate))
      }else if(values.massive === true && values.nameCampaign === ''){
        const body = {
          "reportType": "LIST_MASSIVE",
          "subject": values.subject,
          "companySerialID": values.client,
          "initialDate": values.initialDate,
          "finalDate": values.finalDate,
        };
        dispatch(sendReport(body, navigate))
      } else if(values.massive === true && values.nameCampaign !== '' ){
        const body = {
          "reportType": "STATUS_SECUNDARY",
          "notificationStatus": values.all ? ['READ', 'UNREAD', 'PENDING', 'REJECTED', 'FINALIZED', 'RELEASE', 'ERROR'] : selectedStates(),
          "companySerialID": values.client === '' ? values.clientForCampaign : values.client,
          "campaignName": values.nameCampaign,
          "notificationDate": values.notificationDate,
        }
        dispatch(sendReport(body, navigate))
      }
    }
  };

  const selectedStates = () => {
    let statesList = []
    if (values.error) statesList = [...statesList, 'ERROR']
    if (values.pending) statesList = [...statesList, 'PENDING']
    if (values.novelty) statesList = [...statesList, 'RELEASE']
    if (values.read) statesList = [...statesList, 'READ']
    if (values.rejected) statesList = [...statesList, 'REJECTED']
    if (values.unRead) statesList = [...statesList, 'UNREAD']
    if (values.complete) statesList = [...statesList, 'FINALIZED']
    return statesList
  }


  const handleSend = (e) => {
    e.preventDefault();
    if (formValidator.current.allValid() && values.initialDate <= values.finalDate ) {
      dispatch({
        type: CHANGE_STATE_REPORTS_LOADING_MODAL,
        payload: { type: 'reportLoadingMessage', status : true }
      });
      if (values.all === false) {
        if (values.unRead === true || values.pending === true || values.rejected === true || values.novelty === true ||
          values.error === true || values.read === true || values.complete === true) {
          makeSaveNotificationReq();
        } else {
          setErrorStateNotification(true)
        }
      } else {
        makeSaveNotificationReq();
      }
    } else {
      formValidator.current.showMessages()
      if (values.unRead === false && values.pending === false && values.rejected === false && values.novelty === false && values.error === false && values.read === false && values.complete === false) {
        setErrorStateNotification(true)
      }
    }
  };

  const handlerOnClick = (e) => {
    const label = e.target.name
    formValidator.current.showMessageFor(label)
  }

  const fetchMoreData = () => {
    setObjectFilterSpecificCampaign(
      {
        ...objectFilterSpecificCampaign,
        pag: objectFilterSpecificCampaign.pag+1
      }
    )
  };

  const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
  };

  const isDisabled = () => {
    return values.initialDate === '' || values.finalDate === '' ? true : false || values.specificCampaign === true && values.nameCampaign === ''
  }

  const isDisabledStates = () => {
    return values.all === true ? true : false || values.specificCampaign === true  && values.nameCampaign === ''
  }
  
  return (
    <>
      <Divider1>
        <CustomForm>
          <Divider2>
            <ReportIcon />
          </Divider2>
          <Divider>
            {/*<div id="scrollableDiv" style={{width: '100%', height: '500px', overflowY:'auto'}}>
            <InfiniteScroll
              dataLength={concatInfiniteScrollSpecificCampaign.length}
              next={fetchMoreData}
              hasMore={dataHasMore}
              loader={
              <div className='loading-container-report loading-mini'>
                <div class="lds-spinner-reportLoading"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {concatInfiniteScrollSpecificCampaign.map((item, index) => (
                <div style={style} key={index}>
                  div - #{item.text}
                </div>
              ))}
            </InfiniteScroll>
            </div>*/}
            <InputFormDropdown
              options={reportOptions}
              placeholder={"Tipo de reporte"}
              value={values.typeReport}
              handleOnChange={handleOnChange}
              id="typeReport"
              validator={formValidator.current}
              validateOptions={"required"}
              error={errors}
            />
          </Divider>
          {values.typeReport !== "" && (
            <>
              <TextDate>Rango fecha envío de notificación (máximo 30 días)</TextDate>
              <ContainerFormDate>
                <ContainerPositionDateOne>
                  <InputFormDate
                    type="date"
                    id="initialDate"
                    label={"Fecha inicio"}
                    value={values.initialDate}
                    showErrorInitialDateEqualsEndDate={false}
                    endDate={values.finalDate}
                    onChange={onChangeDate}
                    validator={formValidator.current}
                    validateOptions={["required"]}
                    error={errors}
                    limitDateToday={true}
                  />
                </ContainerPositionDateOne>
                <ContainerPositionDateTwo>
                  <InputFormDate
                    type="date"
                    id="finalDate"
                    label={"Fecha fin"}
                    value={values.finalDate}
                    startDate={values.initialDate}
                    onChange={onChangeDate}
                    validator={formValidator.current}
                    validateOptions={["required"]}
                    error={errors}
                    limitDateToday={true}
                  />
                </ContainerPositionDateTwo>
              </ContainerFormDate>
              {values.typeReport !== "WRONG_NOTIFICATION" && (
                <>
                  <Divider>
                    <InputFormDropdownSearch
                      options={clientsList}
                      placeholder={"Buscar cliente"}
                      value={values.client}
                      handleOnChange={handleOnChange}
                      id="client"
                      validator={formValidator.current}
                      validateOptions={''}
                      error={errors}
                      searchQuery={searchQuery}
                      disabled={values.initialDate === '' || values.finalDate === '' ? true : false}
                      onFocus={handleOnFocusSearchClient}
                    />
                  </Divider>
                  {values.initialDate === '' || values.finalDate === '' 
                    ? <Text2 >Tipo de envío</Text2>
                    : <Text >Tipo de envío</Text>
                  }
                  <div style={{ marginLeft: "18px", marginBottom: "10px", display: "flex" }}>
                    <InputRadio
                      id="individual"
                      label="Individual"
                      onChange={handleChangeRadio}
                      value={values.individual}
                      checked={values.individual}
                      validator={formValidator.current}
                      validateOptions=""
                      disabled={values.initialDate === '' || values.finalDate === '' ? true : false}
                    /> 
                    <PopupAlertReport type={'warningIndividual'} initialDate={values.initialDate} finalDate={values.finalDate} active={'actived'} />
                    <div 
                      style={{ marginLeft: "18px", display: "flex"  }}>
                        <InputRadio
                          id="massive"
                          label="Masivo"
                          onChange={handleChangeRadio}
                          value={values.massive}
                          checked={values.massive}
                          validator={formValidator.current}
                          validateOptions=""
                          disabled={values.initialDate === '' || values.finalDate === '' ? true : false}
                    /> 
                    <PopupAlertReport type={'warningMassive'} initialDate={values.initialDate} finalDate={values.finalDate} active={'actived'}/>
                    </div>
                  </div>
                    {
                      values.massive === true 
                      && 
                      <>
                        <Text>Filtros adicionales</Text>
                        <div style={{display: "flex"  }}>
                          <InputCheckbokForm
                            label="Buscar una campaña en específico"
                            value={values.individual ? false : values.specificCampaign}
                            onChange={toggle}
                            validator={formValidator.current}
                            validateOptions=""
                            id="specificCampaign"
                          />
                          <PopupAlertReport type={'warningspecificCampaign'} initialDate={values.initialDate} finalDate={values.finalDate} active={''}/>
                        </div>
                      </>
                    }
                    {
                      values.specificCampaign === true && values.massive === true
                      &&
                      <div style={{marginBottom: "10px" }}>
                        <InputSelectAgreement
                          data={concatInfiniteScrollSpecificCampaign}
                          value={values.nameCampaign}
                          validator={formValidator.current}
                          //errorProvider={values.nameCampaign}
                          handleOnChange={handleOnChangeCampign}
                          idName='nameCampaign'
                          placeholder={'Nombre de la campaña'}
                          name={'nameCampaign'}
                          next={fetchMoreData}
                          hasMore={dataHasMore}
                          setHasMore={setDataHasMore}
                          isInfiniteScroll={true}
                          setIsChangeDate={setIsChangeDate}
                          changeDate={isChangeDate}
                        />
                      </div>
                    }
                    {  values.individual === true || values.specificCampaign === true ?
                      <>
                      {values.initialDate === '' || values.finalDate === '' || values.specificCampaign && values.nameCampaign === ''
                    ? <Text2>Estados de entrega de notificación</Text2>
                    : <Text>Estados de entrega de notificación</Text>
                      }
                  <div style={{ marginLeft: "18px", marginBottom: "10px" }}>
                    <InputRadio
                      id="all"
                      label="Todos"
                      onChange={handleChangeRadio}
                      value={values.all}
                      checked={values.all}
                      validator={formValidator.current}
                      validateOptions=""
                      disabled={isDisabled()}
                    />
                    <ContainerRadioError>
                      <InputRadio
                        id="selectState"
                        label="Seleccionar"
                        onChange={handleChangeRadio}
                        value={values.selectState}
                        checked={values.selectState}
                        validator={formValidator.current}
                        validateOptions=""
                        disabled={isDisabled()}
                      />
                      {errorStateNotification === true &&
                        values.selectState === true && (
                          <TextError>
                            Debes seleccionar mínimo un estado
                          </TextError>
                        )}
                    </ContainerRadioError>
                  </div>
                  <ContainerChecbook>
                    <InputCheckbokForm
                      label="No Leida"
                      value={values.all ? false : values.unRead}
                      onChange={toggle}
                      validator={formValidator.current}
                      validateOptions=""
                      id="unRead"
                      disabled={isDisabledStates()}
                    />
                    <div style={{ marginLeft: "25px" }}>
                      <InputCheckbokForm
                        label="Novedad"
                        value={values.all ? false : values.novelty}
                        onChange={toggle}
                        validator={formValidator.current}
                        validateOptions=""
                        id="novelty"
                        disabled={isDisabledStates()}
                      />
                    </div>
                  </ContainerChecbook>
                  <ContainerChecbook>
                    <InputCheckbokForm
                      label="Pendiente"
                      value={values.all ? false : values.pending}
                      onChange={toggle}
                      validator={formValidator.current}
                      validateOptions=""
                      id="pending"
                      disabled={isDisabledStates()}
                    />
                    <div style={{ marginLeft: "18px" }}>
                      <InputCheckbokForm
                        label="Leida"
                        value={values.all ? false : values.read}
                        onChange={toggle}
                        validator={formValidator.current}
                        validateOptions=""
                        id="read"
                        disabled={isDisabledStates()}
                      />
                    </div>
                  </ContainerChecbook>
                  <ContainerChecbook>
                    <InputCheckbokForm
                      label="Completada"
                      value={values.all ? false : values.complete}
                      onChange={toggle}
                      validator={formValidator.current}
                      validateOptions=""
                      id="complete"
                      disabled={isDisabledStates()}
                    />
                    <div style={{ marginLeft: "4px" }}>
                      <InputCheckbokForm
                        label="Error"
                        value={values.all ? false : values.error}
                        onChange={toggle}
                        validator={formValidator.current}
                        validateOptions=""
                        id="error"
                        disabled={isDisabledStates()}
                      />
                    </div>
                  </ContainerChecbook>
                  <ContainerChecbook>
                    <InputCheckbokForm
                      label="Rechazada"
                      value={values.all ? false : values.rejected}
                      onChange={toggle}
                      validator={formValidator.current}
                      validateOptions=""
                      id="rejected"
                      disabled={isDisabledStates()}
                    />

                  </ContainerChecbook>
                      </>
                      :''
                    }
                </>
              )}
              {
                 values.massive === false && values.specificCampaign === false
                 && 
                 <>
              <Divider>
              <InputSelectClient
                id={"documentNumber"}
                label={"Cédula destinatario (opcional)"}
                type={"text"}
                onChange={onChange}
                value={values.recipient.documentNumber}
                validator={formValidator.current}
                validateOptions={values.typeReport === "WRONG_NOTIFICATION" ? [{ min: 4 }, { max: 15 }] : [{ min: 4 }, { max: 15 }]}
                error={errors}
                onClick={handlerOnClick}
                disabled={values.initialDate === '' || values.finalDate === '' ? true : false}
              />
            </Divider>
            <Divider>
              <InputSelectClient
                id={"subject"}
                label={"Asunto de notificación (opcional)"}
                type={"text"}
                onChange={onChange}
                value={values.subject}
                validator={formValidator.current}
                validateOptions={[{ max: 300 }]}
                error={errors}
                onClick={handlerOnClick}
                disabled={values.initialDate === '' || values.finalDate === '' ? true : false}
              />
            </Divider>
            </>
              }
              <ContainerButtons>
                <ButtoSave 
                  className='putButton'
                  disabled={values.initialDate === '' || values.finalDate === '' ? true : false || values.specificCampaign === true  && values.nameCampaign === '' }
                  onClick={handleSend}
                  >Generar reporte
                </ButtoSave>
              </ContainerButtons>
            </>
          )}
        </CustomForm>
      </Divider1>
    </>
  );
};
