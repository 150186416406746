import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionOrder } from '../../actions/filterDetailActions'
import ButtonIcon from '../Button/IconButton'
import { ReactComponent as OrderIcon } from '../../assets/icons/Order.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import { chageStateLoadNotificationsList, chageStateLoadCitizensList } from '../../actions/writerActions'


const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilterOrder = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  max-width: 260px !important;
  min-width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    position: sticky !important;
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
  position: absolute !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
`

const PopupOrderDetail = (props) => {

  const dispatch = useDispatch()
  const order = useSelector(state => state.filterDetailReducer.sort)
  const statusLoadNotifications = useSelector(state => state.writerReducer.loadNotifications)

  const [show, setShow] = useState(false)
  const [state, setState] = useState(order)

  useEffect(() => {
    dispatch(actionOrder(state))
  }, [state]);

  useEffect(() => {
    setState(order)
  }, [order]);



  const toggle = () => {
    setShow(!show)
    if (statusLoadNotifications)
      dispatch(chageStateLoadNotificationsList(false))
    else
      dispatch(chageStateLoadCitizensList(false))
  }

  const handleButtonClick = (newOrder) => {
    if (!props.loader) {
      setState(newOrder);
      // Cerrar el Popup
      setShow(false);
    }
  }


  return (
    <Divider>
      <PopupFilterOrder position='bottom left' on='click' onClose={toggle} open={show}
        trigger={
          <ButtonIcon onClick={toggle}>
            <Texto> Ordenar</Texto>
            <OrderIcon />
          </ButtonIcon>
        } >
        <ContainerOption>
          {props.showOldestNewest &&
            <>
              {!!props.hideOptions && !!props.hideOptions.find(hide => hide === 'masReciente') ? <></> :
                <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('masReciente')}>
                  {state === 'masReciente' && <Reviewed className='iconReReviewed' />}
                  {state !== 'masReciente' ? <Text>{props.options === 'reports' ? 'Fecha generación (más reciente)' : 'Más reciente'}</Text> : props.options === 'reports' ? 'Fecha generación (más reciente)' : 'Más reciente'}
                </ButtonOption>
              }
              {!!props.hideOptions && !!props.hideOptions.find(hide => hide === 'masAntiguo') ? <></> :
                <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('masAntiguo')} >
                  {state === 'masAntiguo' && <Reviewed className='iconReReviewed' />}
                  {state !== 'masAntiguo' ? <Text>{props.options === 'reports' ? 'Fecha generación (más antigua)' : 'Más antiguo'}</Text> : props.options === 'reports' ? 'Fecha generación (más antigua)' : 'Más antiguo'}
                </ButtonOption>
              }
            </>
          }
          {props.showAZ &&
            <>
              {!!props.hideOptions && !!props.hideOptions.find(hide => hide === 'A-Z') ? <></> :
                <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('A-Z')}>
                  {state === 'A-Z' && <Reviewed className='iconReReviewed' />}
                  {state !== 'A-Z' ? <Text>{props.options === 'reports' ? 'Usuario (A-Z)' : 'A-Z'}</Text> : props.options === 'reports' ? 'Usuario (A-Z)' : 'A-Z'}
                </ButtonOption>
              }
              {!!props.hideOptions && !!props.hideOptions.find(hide => hide === 'Z-A') ? <></> :
                <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('Z-A')}>
                  {state === 'Z-A' && <Reviewed className='iconReReviewed' />}
                  {state !== 'Z-A' ? <Text>{props.options === 'reports' ? 'Usuario (Z-A)' : 'Z-A'}</Text> : props.options === 'reports' ? 'Usuario (Z-A)' : 'Z-A'}
                </ButtonOption>
              }
            </>
          }
        </ContainerOption>
      </PopupFilterOrder>
    </Divider>
  )
}
export default PopupOrderDetail