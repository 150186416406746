import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {changeMessageModalReport } from '../../../actions/modalActions'
import Event from './Event'
import './events.css'
import { ReactComponent as EventIncons } from '../../../assets/icons/eventsIcon.svg'
import { ReactComponent as EventIconsCloset } from '../../../assets/icons/eventIconCloset.svg'
import ReportModal from '../../../components/ModalReport/ReportModal'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/sent/download.svg'

const Container = styled.div`
  width: 100%;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
  
  @media only screen and (max-width: 1200px)  {
    border: none;
  }
`
const Divider = styled.div`
  border: none;
  height: 60vh;
  @media only screen and (max-width: 1200px)  {
    border: 1px solid #C6C6C6;
    margin-bottom: 8px;
    border-radius: 8px;
    height: auto;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #EBEBEB;
  border-radius: 8px 8px 0 0;
  padding: 0 15px;

  @media only screen and (max-width: 1200px)  {
    justify-content: space-between;
  }
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  padding-top: 15px;
`
const EventList = styled.div`
  width: 100%;
  height: calc(100% - 52px);
  overflow-y: auto;
  padding: 17px 30px;
  @media only screen and (max-width: 1200px)  {
    display: none;
  }
`
const EventList2 = styled.div`
  display: none;

  @media only screen and (max-width: 1200px)  {
    display: block;
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 17px 30px;
  }
`
const Footer = styled.div`
  height: 60px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #C6C6C6;
  padding: 0 13px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 1200px)  {
    display: inline-block;
    border: none;
    padding: 0;
  }
`
const Button = styled.button`
  width: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background: unset;
  border: 1px solid #22C1D0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #22C1D0;
  cursor: pointer;
  & svg {
    margin-right: 8px;
  }
  @media only screen and (max-width: 1200px)  {
    width: 100%;
    justify-content: center;
  }
`

export const NotificationEvents = () => {

  const [state, setState] = useState(false)
  const dispatch = useDispatch() 

  const validateDownload = () => {
    dispatch(changeMessageModalReport({ type:'warningReport' }))
  }

  const viewsEvent = () => {
    setState(!state)
  }

  return (
    <Container>
      <Divider>
        <Header>
          <Title>Eventos</Title>
          {
            state === true  ? 
            <EventIconsCloset className={'iconS'}  onClick={viewsEvent} />
            : 
            <EventIncons className={'iconS'} onClick={viewsEvent} />
          }
        </Header>
        <EventList>
          <Event />
        </EventList>
        {
          state === true &&
          <EventList2 className={state === true ? 'list' : 'withoutList'}>
              <Event />
          </EventList2>
        }
      </Divider>
      <Footer>    
        <Button onClick={validateDownload} >
          <DownloadIcon/> 
          Descargar informe
        </Button>
      </Footer>
      
      <ReportModal/>
    </Container>
  )
}

export default NotificationEvents

