import React, { useState } from 'react'
import { SearchBarGenericSent } from './SearchBarGeneric'

const ContainerSearch = (props) => {

  const [searchBarState, setSearchBarState] = useState("")
  const [popUpOrderState, setPopUpOrderState] = useState("")
  const [popUpFilterState, setpopUpFilterState] = useState("")

    return (
      <SearchBarGenericSent searchBarState={searchBarState} popUpOrderState={popUpOrderState}
      popUpFilterState={popUpFilterState} showAdvancedSearch={props.showAdvancedSearch} loader={props.loader}  generalLoader={props.generalLoader? props.generalLoader : false}/>
    )
}

export default ContainerSearch
