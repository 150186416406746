import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AllAttachmentDetailList from './viewList/AllAttachmentDetailList/AllAttachmentDetailList'
import AllAttachmentDetailGrid from './viewGrid/AllAttachmentDetailGrid/AllAttachmentDetailGrid'
import ContainerUserDocumentSearch from '../../../components/Search/SearchAttachment/Container/ContainerDetailDocumentSeacrh'
import Header from './Header'
import { PDFViewer } from '../../../components/viewPdf/PDFViewer'
import { ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS, CHANGE_TOAST_ID, CLEAR_ATTACHMENT_DETAIL, DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS } from '../../../actions/actionsUtilities/types'
import { exportDocumentsToPDF } from '../../../actions/userDocumentsActions'
import { toast } from 'react-toastify'
import { ReactComponent as IconClose } from '../../../assets/icons/report/iconCloseDark.svg';
import styled from 'styled-components'

const ContainerWarningMessage = styled.div`
  background: #E8FDFE;
  min-width: 293px;
  width:100%;
  max-height: 40px;
  height: 40px;
  border: 1px solid #4E8D93;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  padding: 12px 24px 12px 24px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;

const ContainerText= styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  gap: 3px;
`;

const ContainerTextOne = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0px;
  text-align: left;
  color: #133F4F;
`;

const IconCloseDark = styled(IconClose)`
  cursor: pointer;
}
`;

const AttacmentDetail = (props) => {
    const dispatch = useDispatch()

    const { viewList } = useSelector(state => state.userDocumentReducer)
    const [selectCheckBox, setSelectCheckBox] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const customEqual = (oldValue, newValue) => oldValue === newValue
    var listToDelete = useSelector((state) => state.userDocumentReducer.listToDelete, customEqual)
    const [activeAll, setActiveAll] = useState(false)
    const {hasSlectedAllAttachmentsToDownload} = useSelector((state) => state.userDocumentReducer)
    const {downloadingUserDocs,toastId} = useSelector((state) => state.sentReducer)

    const [loadingDownload, setLoadingDownload] = useState(false);
    const [showMessage, setShowMessage] = useState(false)

    const [state, setState] = useState({
        loader: true,
        init: true,
    })


    const listdraft = useSelector((state) => state.draftReducer.draftListArray)

    useEffect(()=>{
        dispatch({type:CLEAR_ATTACHMENT_DETAIL})
    },[])

    useEffect(() => {
        if (showMessage) {
            if (toastId !== null) {
                toast.dismiss(toastId);
                setTimeout(() => {
                    const id = toast(createToast(), { autoClose: 5000 ,
                        // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
                    } );
                    dispatch({type:CHANGE_TOAST_ID,payload:id});

                }, 500);
            }else{
                const id = toast(createToast(), { autoClose: 5000 ,
                    // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
                } );

                dispatch({type:CHANGE_TOAST_ID,payload:id});
            }

        }
    }, [showMessage]);

    useEffect(() => {
        if (listToDelete.length > 0) {
            setIsVisible(true)
            setSelectCheckBox(true)
        } else {
            setActiveAll(false)
            setSelectCheckBox(false)
            setIsVisible(false)
        }
    }, [listToDelete])

    const handleSelectAll = (e) => {
        e.preventDefault()
        setActiveAll(!activeAll)
        setIsVisible(!isVisible)
        setSelectCheckBox(!selectCheckBox)
        if (!activeAll && listToDelete.length <= 0) {
            dispatch({ type: ADD_ALL_TO_LIST_TO_DELETE_DOCUMENTS })
        } else {
            dispatch({ type: DELETE_ALL_FROM_LIST_TO_DELETE_DOCUMENTS })
        }
    }

    const handleClickDownload = () => {
        setShowMessage(false);
        setShowMessage(true);
        setLoadingDownload(true);
        dispatch(exportDocumentsToPDF({attachmentSerialIDList: listToDelete}))
            .finally(() => {
                setLoadingDownload(false);
                setShowMessage(false);
            });
    }

    const createToast = () => (
        <ContainerWarningMessage>
            <ContainerText>
                <ContainerTextOne>Descargando archivos</ContainerTextOne>
            </ContainerText>
            <IconCloseDark onClick={closeToast} />
        </ContainerWarningMessage>
    );

    const closeToast = () => {
        setShowMessage(false);
        toast.dismiss()
    };


    return (
        <>
            <Header />
            <PDFViewer />
            <ContainerUserDocumentSearch showAZ={true} textPlaceHolder={'Nombre de documento  '} isVisible={isVisible || downloadingUserDocs}
                                         handleSelectAll={handleSelectAll} selectCheckBox ={selectCheckBox} handleClickDownload={handleClickDownload}
                                         isVisibleCheck={listdraft.length >0} checkedAll={hasSlectedAllAttachmentsToDownload} loadingDownload ={loadingDownload || downloadingUserDocs} loader={ state.loader}/>
            {viewList === true ? <AllAttachmentDetailList activeAll={activeAll} state={state} setState={setState}/> :
                <AllAttachmentDetailGrid activeAll={activeAll} state={state} setState={setState} />}
        </>
    )
}

export default AttacmentDetail