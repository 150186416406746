import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import GeneralTable from "../../../../components/Table/GeneralTable";
import RowTableUsers from "./RowTableUsers";
import {
  actionClearFilter,
  activePagination,
} from "../../../../actions/filterDetailActions";
import { getEmployees } from "../../../../actions/clientActions";

const Users = (props) => {

  const [state, setState] = useState({
    loader: false,
    columnData: [
      { name: "Nombre ", width: 3, arrow: false },
      { name: "Documento", width: 2, arrow: false },
      { name: "Email", width: 4, arrow: false },
      { name: "Rol", width: 2, arrow: false },
      { name: "Activo", width: 1, arrow: false },
      { name: "", width: 1, arrow: false },
    ],
    init: true,
  });

  const dispatch = useDispatch();

  const filterReducer = useSelector((state) => state.filterDetailReducer);
  const employees = useSelector((state) => state.clientReducer.employees);

  useEffect(() => {

    dispatch(actionClearFilter());
    makeClientsRequest(filterReducer);

  }, []);

  useEffect(() => {

    if (!state.init) {

      makeClientsRequest(filterReducer);
      setState({ ...state, loader: true, init: true });

    }

  }, [filterReducer]);

  const makeClientsRequest = (filterReducer) => {

    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      companySerialID: props.companySerialID,
      textToSearch: filterReducer.searchBar[0],
    };
    
    dispatch(getEmployees(body)).then(() => {

      dispatch(activePagination(false));
      setState({ ...state, init: false, loader: false });

    });
  };

  const orderTranslator = (order) => {

    const options = {
      masReciente: { _id: -1 },
      masAntiguo: { _id: 1 },
      "A-Z": { firstName: 1 },
      "Z-A": { firstName: -1 },
    };

    return options[order];

  };

  return (
    <GeneralTable
      headerTable={state.columnData}
      list={employees}
      typeList="users"
      rowComponent={RowTableUsers}
      loader={state.loader}
    />
  );
};

export default Users;

