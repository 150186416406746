import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from 'react-redux'
import './input-from-entities.css'
import styled from "styled-components"
import { Checkbox } from "semantic-ui-react"
import { activeFormClient } from "../../actions/clientActions"

const CustomCheckbox = styled(Checkbox)`
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0px 0px 10px 0px;
  }

  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 1px solid #22C1D0 !important;
    width: 18px;
    height: 18px;
  }

  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label{
    opacity: 0.2 !important;
  }

  padding-right: 10px !important;

  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
}
`
const InputFormArea = ({ ...props }) => {

    const dispatch = useDispatch()

    const { placeholder, id, onChange, value, label, disabled, validator, validateOptions, error, classNameDiv, classNameCheck } = props
    let errorValidator = validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions)

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    return (
        <>
            <div className={`${classNameDiv} ${error || errorValidator ? 'inline field' : ''} ${disabled ? 'disabled' : ''}`}>
                <CustomCheckbox
                    id={id}
                    label={label}
                    onChange={onChange}
                    checked={value}
                    disabled={disabled}
                    className={classNameCheck}
                    onBlur={() => validator2(value)}
                />
                {error ?
                    <div className="ui red left pointing basic label">{error}</div>
                    : null
                }
                {!disabled && errorValidator ?
                    <div className="ui red left pointing basic label">{errorValidator}</div>
                    : null
                }
                <br />
            </div>
        </>
    )
}


InputFormArea.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormArea