import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Divider = styled.div`
  margin-bottom: 5px;
`
const TagContainer = styled.div`
  display:flex;
  width: 100%;

  @media only screen and (max-width: 1800px) {
    display:block;
  }

  @media only screen and (max-width: 992px) {
    display: block;
  }
`

const Text = styled.p`
  margin-right: 12px;
  color: #979E9F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: 992px) {
    width:100%; 
  }
`
const ContainerText = styled.div`
  display: block; 
  @media only screen and (max-width: 992px) {
    display: none;
  }
`
const ContainerTextTwo = styled.div`
  display: none;
  float:left;
  @media only screen and (max-width: 992px) {
    display: block;
  }
`

const Tags = styled.div`
  
`
const ButtonTag = styled.button` 
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 15px;
  box-sizing: border-box;
  color: #22C1D0;
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 14px;
  height: 28px;
  margin-right: 8px;
  margin-bottom: 4px;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const IncludeTag = (props) => {

  const tags = useSelector(state => state.writerReducer.notificationForm.tags)
  const parametrizationTags = useSelector(state => state.writerReducer.notificationForm.parametrizationTags)

  const handleTag = (tagName) => {
    props.quill.focus()
    const range = props.quill.getSelection()
    let rangeCurrent = !!range ? range.index : 0
    const currentColor = props.quill.getFormat(rangeCurrent)
    let colorValue = '#22C1D0'
    let activeColor = false
    if (currentColor.hasOwnProperty('color')) {
      if (currentColor.color === "#000000") {
        activeColor = false
      } else {
        colorValue = currentColor.color;
        activeColor = true
      }
    } else {
      activeColor = false
    }
    if (!!activeColor) {
      props.quill.insertText(rangeCurrent, ' ', { 'bold': false, 'color': colorValue })
    } else {
      props.quill.insertText(rangeCurrent, ' ', { 'bold': false })
    }

    props.quill.insertText(rangeCurrent, tags[tagName], { 'bold': true, 'color': colorValue, 'focus': true })

  }

  const handleNewTags = (tagName) => {

    props.quill.focus()
    const range = props.quill.getSelection()
    let rangeCurrent = !!range ? range.index : 0
    const currentColor = props.quill.getFormat(rangeCurrent)
    let colorValue = '#22C1D0'
    let activeColor = false
    if (currentColor.hasOwnProperty('color')) {
      if (currentColor.color === "#000000") {
        activeColor = false
      } else {
        colorValue = currentColor.color;
        activeColor = true
      }
    } else {
      activeColor = false
    }
    if (!!activeColor) {
      props.quill.insertText(rangeCurrent, ' ', { 'bold': false, 'color': colorValue })
    } else {
      props.quill.insertText(rangeCurrent, ' ', { 'bold': false })
    }

    props.quill.insertText(rangeCurrent, `[${tagName}]`, { 'bold': true, 'color': colorValue, 'focus': true })
  }

  const configParametrizationTags = (parametrizationTags) => {
    const updateTags = parametrizationTags.slice(1, parametrizationTags.length)
    return updateTags
      .map((tag, x) =>
        tag.type === 'TAG'
        &&
        <ButtonTag
          onMouseDown={(e) => {
            e.preventDefault()
            handleNewTags(tag.tagName)
          }}
        >
          {tag.tagName}
        </ButtonTag>)
  }

  return (
    <Divider>
      <TagContainer>
        <ContainerText>
          <Text>Incluir etiquetas</Text>
        </ContainerText>
        <ContainerTextTwo>
          <Text>Etiquetas</Text>
        </ContainerTextTwo>
        <Tags>
          <ButtonTag
            onMouseDown={(e) => {
              e.preventDefault()
              handleTag('nombres')
            }}
          >
            Nombres
          </ButtonTag>
          <ButtonTag
            onMouseDown={(e) => {
              e.preventDefault()
              handleTag('nombres_apellidos')
            }}
          >
            Nombres y apellidos
          </ButtonTag>
          <ButtonTag
            onMouseDown={(e) => {
              e.preventDefault()
              handleTag('apellidos')
            }}
          >
            Apellidos
          </ButtonTag>
          <ButtonTag
            onMouseDown={(e) => {
              e.preventDefault()
              handleTag('primer_nombre')
            }}
          >
            Primer Nombre
          </ButtonTag>
          <ButtonTag
            onMouseDown={(e) => {
              e.preventDefault()
              handleTag('primer_apellido')
            }}
          >
            Primer Apellido
          </ButtonTag>
          <ButtonTag
            onMouseDown={(e) => {
              e.preventDefault()
              handleTag('cedula')
            }}
          >
            Cédula
          </ButtonTag>
          {configParametrizationTags(parametrizationTags)}
        </Tags>
      </TagContainer>
    </Divider>
  )
}

export default IncludeTag
