import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import GeneralTable from '../../../components/Table/GeneralTable'
import RowTableSent from './RowTableSent'
import { getNotificationsStarred } from '../../../actions/starredAction'
import { actionUpdateTabFilter } from '../../../actions/filterActions'
import ContainerSearch from '../../../components/Search/ContainerSearch'



const AllNotificationsStarreds = (props) =>{
  const dispatch = useDispatch()
  const notificationsStarreds = useSelector(state => state.starredReducer.notificationsStarreds)
  const filterReducer = useSelector(state => state.filterReducer)
  const stateNotificationStarred = useSelector(state => state.starredReducer.stateNotificationStarred)
  const [state, setState]= useState({
    loader: true
  })

  useEffect(()=>{
    dispatch(makeNotificationsRequest(filterReducer))
  },[])

  useEffect(()=>{
    dispatch(makeNotificationsRequest(filterReducer))
  },[filterReducer])

  useEffect(()=>{
    dispatch(actionUpdateTabFilter(props.tabFilter))
  },[props.tabFilter])

  useEffect(()=>{
    dispatch(makeNotificationsRequest(filterReducer))
  },[stateNotificationStarred])

  const makeNotificationsRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      status: filterReducer.searchAdvanced.notificationState,
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      starred: true
    }

    dispatch(getNotificationsStarred(body)).then(() => 
      setState({ loader: false })
    )
  }

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { notificationDate: -1 },
      'masAntiguo': { notificationDate: 1 },
      'A-Z': { notificationDate: -1 },
      'Z-A': { notificationDate: 1 }
    }
    return options[order]
  }

    return (
      <>
        <ContainerSearch />
        <GeneralTable
          list={notificationsStarreds}
          rowComponent={RowTableSent}
          navigate={props.navigate}
          loader={state.loader} />
      </>
    )
}

export default AllNotificationsStarreds
