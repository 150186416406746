import React from 'react'
import styled from 'styled-components'
import Navbar from './components/Navbar'
import PrimaryButton from '../PrimaryButton'
import { ReactComponent as ExpirateIcon } from '../../assets/icons/login/expirateError.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 429px;
  margin-top: 5%;
`
const Text = styled.p`
  font-family: Roboto;
  font-size: 26px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #133F4F;
`

const ExpirateMessage = ({text}) => {

  const history = useHistory()
  const handleBack = () => {
    history.push('/login');
  };

  return (
    <>
        <Navbar/>
        <Divider>
            <Text>{text}</Text>
            <ExpirateIcon 
              style={{
                marginBottom: 32, 
                marginTop: 32}}
            />
            <PrimaryButton 
              txtBtn={'Ir a la aplicación'} 
              onPress={handleBack} 
              style={{
                width: "182px",
                height: "48px",
                padding: "16px 32px 16px 32px",
                fontFamily: "Roboto",
              }}
            />
        </Divider>
    </>
  )
}

export default ExpirateMessage