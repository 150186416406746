import { useState } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { changeMessageModal } from "../../../actions/modalActions"
import { createExportCSVService, createExportPDFService, getBase64ReportService } from "../../../interceptors/services/reports.service"
import { ReactComponent as ExportIcon } from "../../../assets/icons/reports/exportIcon.svg";

const ContainerButtons = styled.div`
  display:flex;
  gap: 16px;
  @media only screen and (max-width: 800px){
    margin-top: 15px;
    margin-left: auto;
  }
  @media only screen and (max-width: 768px){
    margin: 0px 16px 16px 16px;
  }
`

const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  width: max-content;
  height: 40px;
  font-size: 16px;
  font-family: Roboto;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
  @media only screen and (max-width: 740px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 768px) {
    padding: 8px 14px 8px 14px;
  }
`

const ButtonText = styled.label`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #22C1D0;
  padding-left: 8px;
  cursor: pointer;
`

const ExportButtons = (props) => {
  const { detailAgreement } = useSelector((state) => state.agreementReducer)
  const dispatch = useDispatch()
  const [loaderPDF, setLoaderPDF] = useState(false)
  const [loaderCSV, setLoaderCSV] = useState(false)
  const [createPDF, setCreatePDF] = useState()
  const [createCSV, setCreateCSV] = useState()
  const [responseBase64Report, setResponseBase64Report] = useState()

  const downloadPDF = (base64, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = fileName
    downloadLink.click();
  }

  const downloadCSV = (base64, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const fetchCreatePDF = async (param) => {
    try {
      await createExportPDFService(param).then((report) => {
        if (!!report){
          downloadPDF(report.data.base64, report.data.fileName)
          setLoaderPDF(false)
        }
      })
    } catch (error) {
      dispatch(changeMessageModal({ type: 'exportInterceptorError' }))
    }
  }

  const fetchCreateCSV = async (body) => {
    try {
      await createExportCSVService(body).then((report) => {
        if (!!report)
          getBase64ReportService({ reportSerialID: report.reportSerialID }).then((base64) => {
            if (!!base64) {
              downloadCSV(base64.base64, report.fileName)
              setLoaderCSV(false)
            }
          })
      })
    } catch (error) {
      setLoaderCSV(false)
      dispatch(changeMessageModal({ type: 'exportInterceptorError' }))
    }

  }

  const fetchGetBase64Report = async (body) => {
    const data = await getBase64ReportService(body)
    setResponseBase64Report(data)
  }

  const onclickPDF = () => {
    setLoaderPDF(true)
    fetchCreatePDF(detailAgreement.id)
  }

  const onclickCSV = () => {
    setLoaderCSV(true)
    const body = {
      reportType: "DETAIL_AGREEMENT",
      agreementSerialID: detailAgreement.id
    }
    fetchCreateCSV(body)
  }

  // useEffect(() => {
  //   if (!!loaderCSV) {
  //     downloadCSV(responseBase64Report.base64, createCSV.fileName)
  //     setLoaderCSV(false)
  //   }
  // }, [responseBase64Report])

  // useEffect(() => {

  //   if (!!createPDF) {
  //     downloadPDF(createPDF.data.base64, createPDF.data.fileName)
  //     setLoaderPDF(false)
  //   } else {

  //   }
  // }, [createPDF])

  // useEffect(() => {

  //   if (!!createCSV) {
  //     const body = {
  //       reportSerialID: createCSV.reportSerialID
  //     }
  //     fetchGetBase64Report(body)
  //   } else {

  //   }
  // }, [createCSV])

  return (
    <ContainerButtons>
      <Button onClick={onclickPDF} disabled={loaderPDF}>
        {!!loaderPDF
          ?
          <i class={"loading spinner icon"}></i>
          :
          <ExportIcon />
        }
        <ButtonText>{"Exportar PDF"}</ButtonText>
      </Button>

      <Button onClick={onclickCSV} disabled={loaderCSV}>
        {!!loaderCSV
          ?
          <i class={"loading spinner icon"}></i>
          :
          <ExportIcon />
        }
        <ButtonText>{"Exportar CSV"}</ButtonText>
      </Button>
    </ContainerButtons>
  )


}

export default ExportButtons