import React, {Component, useState} from 'react'
import PropTypes from "prop-types";
import {
  addMassiveRecipientsList, changeWriteNotificationForm,
  filterMassiveRecipients,
  replaceRecipientDisplay, setMassiveRecipientFileInfo, updateHistoryMassive,
  updateRecipientDisplay, changeDisabledGetRecipient
} from "../../actions/writerActions";
import {connect, useDispatch, useSelector} from "react-redux";
import styled, {css} from "styled-components";
import './dropdownStyle.css'
import { ClickAwayListener } from '@mui/material';

const DivHistoryRecipientsAdded = styled.div`
  ${props => props.fieldvisibility &&
          css`
            visibility: ${props.fieldvisibility.visibility};
            z-index: ${props.fieldvisibility.zIndex};
          `}
  max-width: 84%;
  overflow-x: visible;
  ${props => props.fieldHeightDivFocused &&
          css`
            overflow-y: ${props.fieldHeightDivFocused};
          `}
  ${props => props.fieldtop &&
          css`
            top: ${props.fieldtop};
          `}
  max-height: 7rem;
  position: relative;
  background-color: white;
  width: auto;
  margin-left: 10%;

  &:hover {
    visibility: visible;
  }
`


const ButtonItemsHistoryFor = styled.button`
  padding: 0.78571429rem 1.14285714rem!important;
  white-space: normal;
  word-wrap: normal;
  position: relative;
  cursor: pointer;
  display: block;
  height: auto;
  width: 100%;
  text-align: left;
  color: rgba(0,0,0,.87);
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
  background-color: #FFFFFF;
  outline: none;
  border: none;
  &:hover{
    background-color: #E4E4E4;
  }
`

const DropdownOptions = (props) => {

  const [state, setState] = useState({
        isHovering: false,
      })

  const dispatch =  useDispatch()

  const listIdsSendMassive = useSelector((state)=>  state.writerReducer.listIdsSendMassive)
  const isDisabledGetRecipient = useSelector((state)=>  state.writerReducer.isDisabledGetRecipient)

  const handleClick = async evt => {
    dispatch(changeDisabledGetRecipient(true))
    props.changeOptionsState({
      value: evt.target.value
    })
  }

  const fieldHeightDivFocused = (listSize) => {
    if (listSize > 1)
      return `scroll`
    return `hidden`
  }

  const fieldVisible = (visible, onHover) => {
    let attributes
    if (visible || onHover) {
      attributes = {visibility: `visible`, zIndex: `2`}
      return attributes
    } else {
      attributes = {visibility: `hidden`, zIndex: `-1`}
      return attributes
    }
  }
  
  const fieldTop = (listSize) => {
    if (listSize <= 0)
      return `7%`
    return `0`
  }

  const handleMouseHover = () => {
    setState((prevState)=>({...prevState,isHovering: true}))
  }

  const handleMouseOut = () => {
    setState((prevState)=>({...prevState,isHovering: false}))
  }

  const handleClickAway= () => {
    setState((prevState)=>({...prevState,isHovering: false}))
  }

  return (
    <>
    <ClickAwayListener onClickAway={handleClickAway}>
      <DivHistoryRecipientsAdded onMouseOver={handleMouseHover}
                                fieldvisibility={fieldVisible(props.visible, state.isHovering)}
                                fieldtop={fieldTop(listIdsSendMassive.ids.length)}
                                fieldHeightDivFocused={fieldHeightDivFocused(props.items.length)}
                                className={"dropdownForFocusOptions"}>
       
        {props.items.map(item => (
          <ButtonItemsHistoryFor onClick={handleClick} key={item.key} value={item.value} disabled={isDisabledGetRecipient}>
            Cédula: {item.value}
          </ButtonItemsHistoryFor>
        ))}
      </DivHistoryRecipientsAdded>
      
    </ClickAwayListener>
    </>
  )
}

export default (DropdownOptions)


