import React from 'react'
import {SearchBarGenericButtonType} from '../../SearchBarGeneric'
import PopUpFilterAgreements from '../../../Search/SearchAgreements/PopUpFilterAgreements';

export const ContainerSearchAgreementsSuperAdmin = ({showAZ, reportType, providerType, disabledExportButton, customWidthDiv2,
   searchbarMaxWidth, loader}) => {
  return (
    <SearchBarGenericButtonType 
        buttonType={'agreement'} 
        text={'Nueva vinculación'} 
        showButton={true} 
        textPlaceHolder={'Código de la vinculación'} 
        searchBarState={''} 
        showAZ={showAZ} 
        dataFilter={<PopUpFilterAgreements reportType={reportType} providerType={providerType} />} 
        showExportButton={true}
        disabledExportButton={disabledExportButton}
        showAdvancedSearch={false}
        searchbarMaxWidth={searchbarMaxWidth}
        customWidthDiv2={customWidthDiv2}
        loader={loader}
      />
  )
}

export default ContainerSearchAgreementsSuperAdmin
