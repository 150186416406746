import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {convert} from 'html-to-text'
import Label from '../../../components/Label/MainLabel'
import StarredButton from '../../../components/Starred/StarredButton'
import { ReactComponent as Attached } from '../../../assets/icons/sent/attached.svg'
import { ReactComponent as MasiveIcon } from '../../../assets/icons/masiveIcon.svg'
import { postStarredV2 } from '../../../actions/starredAction'
import { useDispatch } from 'react-redux'
import { changeStateHaveAttachmentMassive } from '../../../actions/seeNotificationAction'
import './styles.css'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
  height: 50px !important;
  max-height: 50px!important;
  @media only screen (max-width: 1024px) {
  width: 100% !important;
}
`
const SentCellAttachment = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 5px !important;
  height: 50px !important;
  max-height: 50px!important;
  @media only screen (max-width: 1024px) {
  width: 100% !important;
}
`
const SentCellIcon = styled(Table.Cell)`
  display: flex;
  width: 100% !important;
  height: 100%;
  .ui.basic.label{
    text-wrap: nowrap;
    padding: 0px 4px !important;
  }
`

const StatusContainer = styled.div`
  float: left!important;
`

const Row = styled(Table.Row)`
  cursor: pointer !important;
  overflow-x: hidden;
  overflow: hidden;
  height: 50px !important;
  max-height: 50px!important;
  @media only screen and (max-width: 1024px) {
    height: auto !important;
    max-height: unset !important;
  }
  @media only screen and (max-width: 768px){
    padding-top: 8px!important;
    padding-bottom: 8px!important;
    padding-left: 1% !important;
    height: auto !important;
    box-sizing: content-box;
    justify-content: space-between;
    display: flex !important;
    flex-direction: column;
    width: 100vw;
  }
  @media only screen and (max-width: 420px){
    width: 100vw;
  }
`
const SentCellResponsive = styled(Table.Cell)`
  width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display block;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  padding: 0.25em 0em!important;
  min-height: 24px !important;
  @media only screen and (max-width: 1024px) {
    padding-left: 1% !important;
    .ui.basic.label{
      min-width: 18px;
      padding-bottom: 0px !important;
      height: 24px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    line-height: 18px;
    .ui.basic.label{
      padding-bottom: 0px !important;
      height: 24px !important;
      max-height: 22px;
    }
  }
`
const SentCellResponsive2 = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  display: flex !important;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  padding: 0em 0em!important;
  line-height: 24px;
  min-height: 24px !important;
  font-size: 16px;
  .subjectDate{
    width: 85vw;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 1% !important;
    .ui.basic.label{
      min-width: 18px;
      padding-bottom: 0px !important;
      height: 24px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .ui.basic.label{
      padding-bottom: 0px !important;
      height: 24px !important;
      max-height: 22px;
    }
    .subjectDate{
      width: 94vw;
    }
  }
`
const DivText = styled.div`
  max-width: 500px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display block;
  font-size: 14px !important;
  line-height: 18px;
  @media only screen and (max-width: 600px){
    max-width: 450px;
  }
  @media only screen and (max-width: 500px){
    max-width: 300px;
  }
  @media only screen and (max-width: 350px){
    max-width: 250px;
  }
`
function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width
  };
}


function useWindowDimensions() {
  const [windowDimensions = 0, setWindowDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return windowDimensions;
}

const RowTableSent = (props) => {

  const dispatch = useDispatch()
  const [stateLocalStarred,  setStateLocalStarred] = useState(props.data.starred)
  const formatDate = (notificationDate) => (moment(notificationDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'))
      ? moment(notificationDate).format('h:mm a') : moment(notificationDate).format('D MMM')

  const redirectToNotiDetail = () => {
    if (props.data.recipient !== null && props.data.recipient !== null) {
      props.navigate('/sent-notification-detail/' + props.data.notificationID)
    } else {
      dispatch(changeStateHaveAttachmentMassive(props.data.haveAttachments))
      props.navigate('/sentMasive/'+ props.data.notificationID)
    }
  }

  const getDescriptionText = (description) => {
    let text = convert(description, { wordwrap: 130, ignoreImage: true, ignoreHref: true })
    text = text.split('\n')[0]
    return text
  }

  const hadleStarred = () => {
    setStateLocalStarred(!stateLocalStarred)
    const body = { "notificationID": props.data.notificationID }
    dispatch(postStarredV2(body))
  }

  useEffect(() => {
    setStateLocalStarred(props.data.starred)
  }, [props.data])

  return (
      <>
        {useWindowDimensions().width > 1024 ?
            <Row onClick={redirectToNotiDetail}>
              <SentCell width={4} >
                <div style={{display:'flex', alignItems: 'center'}}>
                  <StarredButton active={ stateLocalStarred } onClick={hadleStarred} customStyles={{margin: '-4px 8px 0 0'}}/>
                  {props.data.recipient !== null ?
                      <b>{props.data.recipient.detail}</b>
                      : <>
                        <MasiveIcon style={{minHeight:'24px', minWidth: '24px'}} />
                        <b style={{ margin: "3px 0 0 8px" }}>{props.data.nameCampaign}</b>
                      </>
                  }

                </div>
              </SentCell>
              <SentCellAttachment width={1} textAlign='center'>
                <div style={{ maxHeight: '50px', alignItems: 'center', display: 'flex', justifyContent: 'center' }} >
                  {props.data.haveAttachments && <Attached />}
                </div>
              </SentCellAttachment>
              <SentCell width={8}>
                <b>{props.data.subject}</b>
                <span>&nbsp;&nbsp;</span>
                {getDescriptionText(props.data.description)}
              </SentCell>
              <SentCellIcon width={2} textAlign='center'>
                <StatusContainer>
                  {props.data.recipient !== null ?
                      <Label text={props.data.currentStatus.name} icon={props.data.currentStatus.id}
                             color={props.data.currentStatus.color} /> : ''}
                </StatusContainer>
              </SentCellIcon>
              <Table.Cell width={1} style={{ color: '#7C7C74', textWrap: 'nowrap', padding: '5px' }}>{formatDate(props.data.notificationDate)}</Table.Cell>
            </Row>
            :
            <Row onClick={redirectToNotiDetail} >
              <SentCellResponsive2 style={{ lineHeight: '21px' }}>
                <div className='subjectDate' style={{ justifyContent: 'space-between', display: 'flex', lineHeight: '21px' }}>
                  {props.data.recipient !== null ?
                      <div style={{ fontWeight: '700', fontSize: '16px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{props.data.recipient.detail}</div>
                      : <div style={{ justifyContent: 'start'}}> <MasiveIcon /> <b >{props.data.nameCampaign}</b> </div>}
                  <div style={{ color: '#7C7C74', fontWeight: '400' }}>{formatDate(props.data.notificationDate)}</div>
                </div>
              </SentCellResponsive2>
              <SentCellResponsive width={16}>
                <DivText> <div style={{ fontWeight: '700', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'  }}>{props.data.subject}</div> </DivText>
              </SentCellResponsive>
              <SentCellResponsive width={16} style={{ lineHeight: '19px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: "2%", alignItems: 'center' }}>
                  <DivText style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', lineHeight: '19px'}}> {getDescriptionText(props.data.description)} </DivText>
                  <StarredButton active={stateLocalStarred} onClick={hadleStarred} />
                </div>
              </SentCellResponsive>

              <SentCellResponsive width={16} style={{ lineHeight: '24px',fontSize: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: "2%" }}>
                  {props.data.recipient !== null ?
                      <Label text={props.data.currentStatus.name} icon={props.data.currentStatus.id} color={props.data.currentStatus.color} />
                      : ''}
                  <div style={{ width: '16px', height: '24px' }}>
                    {props.data.haveAttachments && <Attached />}
                  </div>
                </div>
              </SentCellResponsive>
            </Row>}
      </>

  )
}

RowTableSent.propTypes = {
  data: PropTypes.object.isRequired
}

export default RowTableSent