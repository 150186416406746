import React from 'react'
import { useSelector } from 'react-redux';
import { configMenu } from '../../routes/menuRoutes'
import PrivateComponent from '../PrivateComponent'
import { permissionValidate } from '../../components/PermissionValidate/PermissionValidate'
import Sent from '../../views/sent/Sent'
import Starred from '../../views/starred/Starred'
import Drafts from '../../views/drafts/Drafts'
import Dashboard from '../../views/dashboard/Dashboard'
import UserDocuments from '../../views/userDocuments/UserDocuments'
import Clients from '../../views/clients/Clients'
import OperatorClients from '../../views/clients/operatorClients/OperatorClients'
import SuperAdminClients from '../../views/clients/superAdminClients/SuperAdminClients'
import Providers from '../../views/providers/Providers'
import ClientAgreements from '../../views/agreements/clientsAgreements/ClientAgreements'
import OperatorAgreements from '../../views/agreements/operatorAgreements/OperatorAgreements'
import SuperAdminAgreements from '../../views/agreements/superAdminAgreements/SuperAdminAgreements'
import Reports from '../../views/reports/Reports'
import Users from '../../views/users/Users'
import AddUsers from '../../views/users/addUsers/AddUsers'
import EditUsers from '../../views/users/editUsers/EditUsers'
import AppContainer from '../AppContainer/AppContainer'
import NotificationDetail from '../../views/sent/NotificationDetail/NotificationDetail'
import AddClients from '../../views/clients/addClients/AddClients'
import AddProviders from '../../views/providers/addProviders/AddProviders'
import EditClients from '../../views/clients/editClients/EditClients'
import AttacmentDetail from '../../views/userDocuments/AttachmentDetail/AttacmentDetail'
import ViewClientsDetail from '../../views/clients/DetailClients/ViewClientsDetail';
import AddEmployee from '../../views/clients/DetailClients/Users/addUsers/AddEmployee'
import EditUserEmployee from "../../views/clients/DetailClients/Users/editUserEmployee/EditUserEmployee"
import EditProviders from '../../views/providers/editProviders/EditProviders'
import viewProvidersDetail from '../../views/providers/DetailProvider/viewProvidersDetail'
import AddAgreements from '../../views/agreements/providerAgreements/addAgreements/AddAgreements';
import AgreementDetail from '../../views/agreements/agreementDetail/AgreementDetail'
import EditAgreements from '../../views/agreements/superAdminAgreements/editAgreements/EditAgreements';
import AddEmployeeProvider from '../../views/providers/DetailProvider/Users/addUsers/AddEmployee'
import SentMasive from '../../views/sent/ContainerMasivo/SentMasive';
import MassiveLoad from '../../views/sent/MassiveLoad/MassiveLoad';
import CreateReport from '../../views/reports/CreateReport/CreateReport';
import ReportDetail from '../../views/reports/ReportsDetail/ReportDetail';
import SuperAdminImproperClients from '../../views/improperClients/superAdminImproperClients/SuperAdminImproperClients';
import AuditorImproperClients from '../../views/improperClients/auditorImproperClients/AuditorImproperClients';
import AuditorImproperClientsDetail from '../../views/improperClients/auditorImproperClients/auditorImproperClientsDetail/AuditorImproperClientsDetail';
import SuperAdminImproperClientsDetail from '../../views/improperClients/superAdminImproperClients/superAdminImproperClientsDetail/SuperAdminImproperClientsDetail';
import ImproperClientsNotificationDetail from '../../views/improperClients/improperClientsNotificationDetail/ImproperClientsNotificationDetail';
import RoutesWithNotFound from '../../utils/routes-with-not-found';
import { SessionClose } from '../../views/auth/SessionClose';
import { Switch } from 'react-router-dom';

export const HomeMenu = props => {
  const { history } = props
  const { userPermission, role } = useSelector((state) => state.authReducer.user)
  const ConfiguredRoute = configMenu(userPermission)
  const views = [
    { sent: Sent },
    { starred: Starred },
    { drafts: Drafts },
    { dashboard: Dashboard },
    { userDocuments: UserDocuments },
    { clients: permissionValidate({ role: role, views: { client: Clients, operator: OperatorClients, superAdmin: SuperAdminClients } }) },
    { providers: Providers },
    { agreements: permissionValidate({ role: role, views: { client: ClientAgreements, operator: OperatorAgreements, superAdmin: SuperAdminAgreements } }) },
    { reports: Reports },
    { users: Users },
    { createUsers: AddUsers },
    { editUsers: EditUsers },
    { sentNotificationDetail: NotificationDetail },
    { createClients: AddClients },
    { clientsDetail: ViewClientsDetail },
    { createProviders: AddProviders },
    { editClients: EditClients },
    { userDocumentsDetail: AttacmentDetail },
    { createEmployee: AddEmployee },
    { editEmployee: EditUserEmployee },
    { editProviders: EditProviders },
    { providersDetail: viewProvidersDetail },
    { createAgreements: AddAgreements },
    { agreementsDetail: AgreementDetail },
    { editAgreements: EditAgreements },
    { createEmployeeProvider: AddEmployeeProvider },
    { sentMasive: SentMasive },
    { massiveLoad: MassiveLoad },
    { createReports: CreateReport },
    { reportsDetail: ReportDetail },
    { improperClients: permissionValidate({ role: role, views: { auditor: AuditorImproperClients, superAdmin: SuperAdminImproperClients } }) },
    { improperClientsDetail: permissionValidate({ role: role, views: { auditor: AuditorImproperClientsDetail, superAdmin: SuperAdminImproperClientsDetail } }) },
    { improperClientsNotificationDetail: ImproperClientsNotificationDetail },
    { notFound: RoutesWithNotFound },
    { sessionExpired: SessionClose }
  ]

  const validView = (name) => {
    for (const value of views) {
      if (name === Object.keys(value)[0]) {
        return value[name]
      }
    }
  }

  return (
    <AppContainer history={history}>
      <Switch>
          {ConfiguredRoute.map((modules, key) =>
            <PrivateComponent
              key={key}
              pathObject={modules}
              path={modules.route}
              view={validView(modules.name)}
              exact
            />)}
        </Switch>
    </AppContainer>
  )
}

// HomeMenu