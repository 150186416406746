import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import RowMassiveLoad from './RowMassiveLoad'
import GeneralTableTwo from '../../../../components/Table/GeneralTableTwo'
import { getAttributeListV2 } from '../../../../actions/writerActions'

export const MassiveLoadContent = ({ data, errors, validator }) => {

  const dispatch = useDispatch()
  //const [loader, setLoader] = useState(true)

  const columnData = [
    { name: "Encabezado de la columna", width: 3, arrow: false },
    { name: "Dato", width: 3, arrow: false },
    { name: "Atributo", width: 3, arrow: false },
  ]

  useEffect(() => {
    //setLoader(false)
    dispatch(getAttributeListV2())
  }, [])

  return (
    <GeneralTableTwo
      list={data === undefined ? [] : data}
      typeList={'massiveLoad'}
      listHeight={'massiveLoad'}
      headerTable={columnData}
      rowComponent={RowMassiveLoad}
      loader={false}
      striped={true}
      validator={validator}
      errors={errors}
    />
  )
}

export default MassiveLoadContent