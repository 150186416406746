import React from 'react'
import styled from 'styled-components'
import { Icon, Label } from 'semantic-ui-react'
import { CustomComponent } from '../CustomComponent'
import { ReactComponent as Unread } from '../../assets/icons/sent/unread.svg'
import { ReactComponent as Read } from '../../assets/icons/sent/read.svg'
import { ReactComponent as Pending } from '../../assets/icons/sent/pending.svg'
import { ReactComponent as Complete } from '../../assets/icons/sent/complete.svg'
import { ReactComponent as Rejected } from '../../assets/icons/sent/rejected.svg'
import { ReactComponent as Full } from '../../assets/icons/sent/full.svg'
import { ReactComponent as Bounced } from '../../assets/icons/sent/bounced.svg'
import { ReactComponent as Release } from '../../assets/icons/sent/novelty.svg'
import { ReactComponent as Error } from '../../assets/icons/sent/error.svg'

const CustomLabel = styled(Label)`
  color: ${props => !props.customcolor ? '#0E3B52' : props.customcolor} !important;
  border: 1px solid ${props => !props.customcolor ? '#0E3B52' : props.customcolor} !important;
  border-radius: 5px !important;
  height: 32px !important;
  padding: 4px 8px !important;
  width: min-content !important;
  margin-top:16px !important;
  .icon svg {
    min-height: 16px;
    min-width: 16px;
    width: 1.4em;
    margin-top: -3px;
  }
`
const CustomP = styled.p`
  font-family: Roboto;
  font-size: 14px;
  min-width: 14px;
  min-height: 14px;
  text-overflow: ellipsis;
  white-space:nowrap;
  margin-left: 4px;
  font-weight 400px;
  line-height: 24px;
  margin-top: 3px;
`

const CustomP2 = styled.p`
color: #3C3C3C;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.1px;
margin-left: 8px;
margin-top: -1px;
`

const data = {
  1: {
    icon: Unread,
    text: 'No leída',
    color: '#0E3B52'
  },
  2: {
    icon: Read,
    text: 'Leída',
    color: '#A4A4A4'
  },
  3: {
    icon: Pending,
    text: 'Pendiente',
    color: '#E35F00'
  },
  4: {
    icon: Rejected,
    text: 'Rechazada',
    color: '#BA3555'
  },
  5: {
    icon: Complete,
    text: 'Completada',
    color: '#009838'
  },
  6: {
    icon: Complete,
    text: 'Completada',
    color: '#009838'
  },
  7: {
    icon: Bounced,
    text: 'Rebotada',
    color: '#E49300'
  },
  8: {
    icon: Error,
    text: 'Error',
    color: '#FF0000'
  },
  9: {
    icon: Release,
    text: 'Novedad',
    color: '#2291D0'
  },
  'default': {
    icon: Full,
    text: 'N/A',
    color: '#A4A4A4'
  }
}

const MainLabelWithQuantity = (props) => {
  const labelData = data[props.icon] ? data[props.icon] : data['default']

  return (
    <CustomLabel basic image customcolor={labelData.color} >
      <Icon style={{marginTop: props.iconMargin}}>
          <CustomComponent component={labelData.icon} />
      </Icon>
      <CustomP>{labelData.text}</CustomP>
      <CustomP2>{'('+ props.quantity+ ')'}</CustomP2>
    </CustomLabel>
  )
}

export default MainLabelWithQuantity
