import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { warningFormModal } from '../../../actions/modalActions'
import { ReactComponent as LeaveIcon } from '../../../assets/icons/leaveIcon.svg'
import '../../Modal/messageModal.css'
import { getProvidersV2, inactiveFormProvider, inactiveFormProviderEdit, putStateActiveProvider, putStateInactiveProvider } from '../../../actions/providerActions'
import { getClients, inactiveFormClient, inactiveFormClientEdit, putStateActiveClient, putStateInactiveClient } from '../../../actions/clientActions'
import { inactiveFormAgreement } from '../../../actions/agreementActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ModalElement = styled(Modal)`
  height: min-content !important;
  padding: 40px !important;
  width: 496px !important;
`;
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  display:flex;
  gap:32px;
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  cursor: pointer;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  cursor: pointer;
`
const orderTranslator = (order) => {
    const options = {
        'masReciente': { "_id": -1 },
        'masAntiguo': { "_id": 1 },
        'A-Z': { "name": 1 },
        'Z-A': { "name": -1 }
    }
    return options[order]
}

const WarningModal = () => {
    const modalData = (type) => {
        const data = {
            warningLeave: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, esta nueva entidad no quedará guardada",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveProvider: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo operador no quedará guardado. ",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo cliente no quedará guardado. ",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveUser: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo usuario no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estás seguro de inactivar esta entidad?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveUpdate: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, las modificaciones no quedarán actualizadas.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveAgreements: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, esta nueva vinculación no quedará guardada.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningSendCommentsAuditor: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Enviar revisión!",
                message: "Estas a punto de enviar la revisión de auditoría, recuerda que una vez enviada no podrás hacer modificaciones.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateProviderActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar este operador?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateProviderInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar este operador?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateClientActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar este cliente?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateClientInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar este cliente?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
        }
        return data[type]
    }
    const { count, pag, searchBar, sort } = useSelector(state => state.filterReducer)
    const modalState = useSelector(state => state.modalReducer.warningModal)
    const activeFormProvider = useSelector(state => state.providerReducer.activeForm)
    const activeFormClient = useSelector(state => state.clientReducer.activeForm)

    const dispatch = useDispatch()
    const history = useHistory()
    const idProvider = sessionStorage.getItem("idProvider")
    const idClient = sessionStorage.getItem("idClient")

    const body = {
        count: count,
        pag: pag,
        sort: orderTranslator(sort),
        textToSearch: searchBar[0]
    }
    const onClose = () => {
        if (activeFormProvider === false || activeFormClient === false) {
            dispatch(warningFormModal({ type: 'warningLeaveProvider' }))
        }
    }

    const handleOnLoad = () => {
        if (modalState.type === 'warningUpdateProviderActive') {
            dispatch(putStateActiveProvider({ "status": 'ENABLE' }, idProvider)).then(() => {
                dispatch(getProvidersV2(body))
            })
        } else if (modalState.type === 'warningUpdateProviderInactive') {
            dispatch(putStateInactiveProvider({ "status": 'DISABLE' }, idProvider)).then(() => {
                dispatch(getProvidersV2(body))
            })
        } else if (modalState.type === 'warningUpdateClientActive') {
            dispatch(putStateActiveClient({ "status": 'ENABLE' }, idClient)).then(() => {
                dispatch(getClients(body))
            })
        } else if (modalState.type === 'warningUpdateClientInactive') {
            dispatch(putStateInactiveClient({ "status": 'DISABLE' }, idClient)).then(() => {
                dispatch(getClients(body))
            })
        } else {
            history.goBack()
            dispatch(inactiveFormClientEdit())
            dispatch(inactiveFormProviderEdit())
            dispatch(inactiveFormClient())
            dispatch(inactiveFormProvider())
            dispatch(inactiveFormAgreement())
        }

        if (activeFormProvider === false || activeFormClient === false) {
            dispatch(warningFormModal({ type: 'warningLeaveUser' }))
        }
    }


    const data = modalData(modalState.type)
    return (
        <ModalElement autoHeight={modalState.type === 'warningUpdateProviderInactive'}
            open={modalState.status} onClose={onClose} >
            <ModalContent>
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!modalState.message && !!modalState.message.length &&
                    modalState.message.map((message) => (
                        <>
                            <ModalMessage> {message} </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!modalState.message ? data.message : modalState.message }
                        {
                            modalState.type === 'warningLeaveAgreements' || modalState.type === 'warningLeaveUser' &&
                            <> <br /> <br /> </>
                        }
                        {!modalState.message2 && data.message2 }
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button onClick={handleOnLoad}> {data.buttonMessage} </Button>
                        <Button2 onClick={onClose}> {data.buttonMessage2} </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}

export default WarningModal
