import React from 'react'
import {useSelector} from 'react-redux'
import  iconTitle from '../../../assets/icons/write/notification-title.svg'

export const PreviewNotification = () => {

  const companyName = useSelector( state=> state.authReducer.user.companyID.name)

    return (
      <>
        <div className='contents-previewNotification'>
          <div className='contents-segmentNotification'>
            <div class="SegmentNotification-pro">
                  <div class="Header">
                    <div class="HeaderContent">

                      <img className="iconTitle" src={iconTitle}/> 
                      <div class="Title">
                        Cédula Digital
                      </div>
                    </div>
                    <div class="Date">DD/MM/AAAA</div>
                  </div>
                  <div class="Message">
                    ¡Hola! {companyName} te ha enviado una notificación. 
                  </div>
            </div>
          </div>
          
        </div>
      </>
    )
}

export default PreviewNotification