import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import {  getListCertificate } from '../../actions/authActions'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import ImageSegment from '../../components/Sengment/ImageSegment'
import PrimaryButton from '../../components/PrimaryButton'
import InputForm from '../../components/InputLogin/InputForm'
import { generateKeyTosessionStorage } from '../../helpers/cypher'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { Recaptcha } from './Recaptcha'
import { VALID_TOKEN } from '../../actions/actionsUtilities/types'
import { useNavigate } from 'react-router-dom-v5-compat'

const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px 32px !important;;
  width: 141px !important;
`
const LoginForm = styled.div`
  height: 80%;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  background-color: #fff;
  align-items: center !important;
  gap: 5px;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
  padding-bottom: 15px;
`
const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: #FFE2E9 !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 2px 10px 10px 10px !important;
  margin-bottom: 15px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
`

const MessageLogin2 = styled.div`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: #FFE2E9 !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
  padding: 10px 5px;
`
const TextVersion = styled.p`
  font-family: Roboto; 
  font-size: 14px; 
  font-weight: 400; 
  line-height: 24px; 
  //text-align: left; 
  color: #3C3C3B 
`

export const LoginWithNumberDocument = () => {
  const customEqual = (oldValue, newValue) => oldValue === newValue
  var errorReducer = useSelector(state => state.errorReducer, customEqual)
  const navigate = useNavigate()
  const [loginValidator, showValidationMessage] = useValidator(reactValidatorOptions)
  const dispatch = useDispatch()
  var { statusIP, accessTime, validToken } = useSelector(state => state.authReducer, customEqual)

  const [state, setState] = useState({ documentNumber: '' })
  const [showError, setShowError] = useState(false)
  const appVersion = process.env.REACT_APP_VERSION

  const [errors, setErrors] = useState({
    error: '',
    message: '',
    documentNumber: '',
    internalCode: ''
  })

  const onChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value })
  }

  useEffect(() => {
    //dispatch(checkLoginStatus())
    if (!sessionStorage.getItem('publicKey')) generateKeyTosessionStorage()
  }, [])

  useEffect(() => {
    setErrors(errorReducer.errorsForm)
    if(errorReducer.showError)document.getElementById('documentNumber').focus()
  }, [errorReducer])

  const setLogin = async (e) => {
    e.preventDefault()
    if (validToken && Object.keys(validToken).length === 0 && state.documentNumber !== '') {
      setShowError(true)
    } else {
      if (loginValidator.allValid() ) { //&& statusIP === 'ALLOWED'
        const { documentNumber } = state
        dispatch(getListCertificate(navigate, documentNumber))
      } else {
        showValidationMessage(true)
      }
    }
  }
  const onExpiredCaptcha = () => {
    dispatch({ type: VALID_TOKEN, payload: {} })
  }

  useEffect(() => {
    if (validToken && Object.keys(validToken).length !== 0) {
      if ( validToken.valid) { //statusIP === 'ALLOWED' &&
        setShowError(false)
        //dispatch({ type: VALID_TOKEN, payload: {} })
      }
    }
  }, [validToken])

  return (
    <ImageSegment>
      <LoginForm>
        <Title>Bienvenido</Title>
        <CustomForm className='ui form' onSubmit={setLogin}>
          <InputForm
            id={'documentNumber'}
            label={'Número de documento'}
            type={'text'}
            onChange={onChange}
            value={state.documentNumber}
            validator={loginValidator}
            validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
            autocomplete="documentNumber"
            customStyle={{ marginTop: '5px' }}
            error={errors.documentNumber}
          />
          <Recaptcha onExpiredCaptcha={onExpiredCaptcha} showError={showError} 
          //accessTime={accessTime} 
          />
          {errors.internalCode === 'UNF' 
          //&& !accessTime 
          ?
            <MessageLogin content={'¡Documento no válido!'} />
            : errors.internalCode === 'UNP' && 
            //!accessTime &&
            <MessageLogin >
              ¡Acceso no permitido! <br /> <br /> Por favor comunicate con el administrador.
            </MessageLogin>
          }

          {/* Bloqueo de inicio de sesión por intentos fallidos
           {!!accessTime &&
            <MessageLogin2>
              <Timer message ={'Se ha detectado actividad inusual al ingresar el número de documento, por seguridad, el inicio de sesión será bloqueado durante '}/>
            </MessageLogin2>
          } */}
          <CustomPrimaryButton 
          //disable={!!accessTime} 
          fluid={true} txtBtn='Continuar' login={true} />
           <TextVersion>{appVersion}</TextVersion>
        </CustomForm>
      </LoginForm>
    </ImageSegment>
  )
}

export default LoginWithNumberDocument
