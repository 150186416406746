import React from 'react'
import { SearchBarDetailGenericButton } from '../../SearchBarGeneric'
import { useSelector } from 'react-redux'

export const ContainerEmployeeSearch = (props) => {
    const originalEmployeesCount = useSelector(state => state.clientReducer.employees)
    return (
        <div>
            <SearchBarDetailGenericButton buttonType={'employee'} text={'Nuevo usuario'} showButton={true} 
            disabledButton={originalEmployeesCount.length !== 0? true:false } textPlaceHolder={'Buscar'} 
            showFilters={false} paddingLeft={'20px'} shortPaddingTop={true} filterPosition={props.filterPosition}/>
        </div>
    )
}

export default ContainerEmployeeSearch