import axios from 'axios'
import {
  GET_CLIENTS,
  SAVE_CLIENT,
  CHANGE_STATE_MESSAGE_MODAL,
  CHANGE_STATE,
  INACTIVE_STATE_CLIENT_FORM,
  INACTIVE_STATE_PROVIDER_FORM,
  EDIT_CLIENT,
  GET_CLIENT_EDIT,
  CHANGE_EDIT_CLIENT,
  ACTIVE_FORM_EDIT,
  INACTIVE_FORM_EDIT,
  INACTIVE_FORM_EDIT_PROVIDER,
  DETAIL_CLIENT,
  CHANGE_STATE_REFRESH_MODULE,
  GET_EMPLOYEES,
  SAVE_EMPLOYEE,
  EDIT_EMPLOYEE,
  GET_EMPLOYEE_EDIT,
  CHANGE_EDIT_EMPLOYEE,
  VIEW_PAGINATION,
  VIEW_PAGINATION_INTERNAL,
  CHANGE_HAVE_CERTIFICATE,
  SET_ORIGINAL_EMPLOYEES_COUNT,
  SET_LOGS_LIST_CLIENT,
  INACTIVE_STATE_FORM_AGREEMENT,
} from './actionsUtilities/types.js'
import { API_ENDPOINT } from "../config/config"
import { deleteFromListActions, reasonDispatch, updateListActions } from './DispatchGeneric.js'
import {GET_CLIENTS_ROUTE, POST_CREATE_ENTITIES_ROUTE, PUT_CLIENTS_ROUTE, PUT_STATE_CLIENT_ROUTE, GET_EMPLOYEES_ROUTE,
  POST_CREATE_EMPLOYEE_ROUTE, GET_EMPLOYEE_ROUTE, PUT_EMPLOYEE_ROUTE, GET_ROLES_CLIENT_ROUTE, TRACKING_LOGS_ROUTE
} from './actionsUtilities/actionsRoutes.js'
import { decrypt, encrypt } from '../helpers/cypher'
import { getHeaders } from '../helpers/globalHelpers.js'

export const getClients = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  
  await axios.post(
    `${API_ENDPOINT+GET_CLIENTS_ROUTE}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_CLIENTS,
      payload:  { clients: decryptedData.finalResponse, elementsCount: decryptedData.elementsCount}
    })
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
          total: decryptedData.elementsCount,
      }
  })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}


export const postCreateEntities = (body) => async (dispatch) => {
  dispatch(updateListActions('CREATE_CLIENTS'))
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT+POST_CREATE_ENTITIES_ROUTE}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_CLIENT,
      payload: decryptedData
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'entityCreate' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'entityCreate' }
        })

        dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })
      }, 2000)
    )
    dispatch({
      type: INACTIVE_STATE_PROVIDER_FORM
    })
    dispatch({ type: INACTIVE_STATE_CLIENT_FORM })
    dispatch({
      type: INACTIVE_STATE_FORM_AGREEMENT
  })
    dispatch(deleteFromListActions('CREATE_CLIENTS'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    const statusCode = reason.response && reason.response.status; 
    if (statusCode !== 500) {
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'errorCreateEntities' }
      })
    }
    dispatch(deleteFromListActions('CREATE_CLIENTS'))
  })
}

export const activeFormClient = () => async dispatch => {
  dispatch({
    type: CHANGE_STATE
  });
}

export const inactiveFormClient = () => async dispatch => {
  dispatch({
    type: INACTIVE_STATE_CLIENT_FORM
  });
}

export const getClientEdit = (id) => async dispatch => {
  await axios.get(
    `${API_ENDPOINT+GET_CLIENTS_ROUTE}${id}`, { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_CLIENT_EDIT,
      payload: {
        codeCompany: decryptedData.client.codeCompany !==undefined ? decryptedData.client.codeCompany : '',
        companySerialID: decryptedData.client.companySerialID,
        name: decryptedData.client.name,
        clientDefinition: decryptedData.client.definition,
        typePersonCompany: decryptedData.client.typePersonCompany,
        typeDocumentCompany: decryptedData.client.typeDocumentCompany,
        numberDocumentCompany: decryptedData.client.numberDocumentCompany,
        DV: decryptedData.client.DV,
        phone: decryptedData.client.phone,
        email: decryptedData.client.email,
        domain: decryptedData.client.domain,
        address: decryptedData.client.address,
        country: decryptedData.client.country,
        department: decryptedData.client.department,
        city: decryptedData.client.city,
        legalManagerName: decryptedData.client.legalManagerName,
        legalManagerLastName: decryptedData.client.legalManagerLastName,
        typeDocumentLegalManager: decryptedData.client.typeDocumentLegalManager,
        numberDocumentLegalManager: decryptedData.client.numberDocumentLegalManager,
        status: decryptedData.client.status,
        contactInformation: decryptedData.client.contactInformation.length >0 ? decryptedData.client.contactInformation : [
          {
            name:'',
            surNames:'',
            email:'',
            phoneContact:'',
            occupation:''
          },
          {
            name:'',
            surNames:'',
            email:'',
            phoneContact:'',
            occupation:''
          },
        ],
        supportFiles: decryptedData.client.supportFiles !== null ? decryptedData.client.supportFiles: [],
        supportDescription: decryptedData.client.supportDescription
      }
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const inactiveFormClientEdit = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_FORM_EDIT,
  })
}

export const changeFormClient = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_EDIT_CLIENT,
    payload: {
      key,
      value
    }
  })
  dispatch({
    type: ACTIVE_FORM_EDIT,
  })
  return Promise.resolve();
}

export const putClientEdit = (body) => async dispatch => {
  dispatch(updateListActions('EDIT_CLIENTS'))
  const encryptedBody = await encrypt(body);
  await axios.put(
    `${API_ENDPOINT+PUT_CLIENTS_ROUTE}${body.companySerialID}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    if (!!decryptedData.success) {
      dispatch({
        type: EDIT_CLIENT,
        payload: decryptedData
      })
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'clientEdit' }
      },
        setTimeout(() => {
          dispatch({
            type: CHANGE_STATE_MESSAGE_MODAL,
            payload: { type: 'clientEdit' }
          })
          dispatch({
            type: CHANGE_STATE_REFRESH_MODULE,
            payload: true
          })

        }, 2000)
      )
      dispatch({ type: INACTIVE_STATE_CLIENT_FORM })
      dispatch({ type: INACTIVE_FORM_EDIT })
      dispatch({ type: INACTIVE_STATE_PROVIDER_FORM });
      dispatch({ type: INACTIVE_FORM_EDIT_PROVIDER });
    } else {
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'unknownError' }
      })
    }
    dispatch(deleteFromListActions('EDIT_CLIENTS'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch(deleteFromListActions('EDIT_CLIENTS'))
  })
}

export const getClientDetail = (id) => async dispatch => {
  await axios.get(
    `${API_ENDPOINT+GET_CLIENTS_ROUTE}${id}`,  { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: DETAIL_CLIENT,
      payload: decryptedData.client
    })
    dispatch({
      type: CHANGE_HAVE_CERTIFICATE,
      payload: true,
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getEmployees = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT+GET_EMPLOYEES_ROUTE}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_EMPLOYEES,
      payload: { employees: decryptedData.users, employeesCount: decryptedData.elementsCount }
    })
    dispatch({
      type: VIEW_PAGINATION_INTERNAL,
      payload: {
          count: 20,
          total: decryptedData.elementsCount,
    }
  })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const setOriginalNumberOfEmployeees = (count) => async (dispatch) => {
  dispatch({
    type:SET_ORIGINAL_EMPLOYEES_COUNT,
    payload: count
  })
}

export const postCreateEmployee = (body) => async (dispatch) => {
  dispatch(updateListActions('CREATE_USER'))
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT+POST_CREATE_EMPLOYEE_ROUTE}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(() => {
    dispatch({
      type: SAVE_EMPLOYEE,
      payload: body
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'employeeCreate' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'employeeCreate' }
        })
        dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })
      }, 2000)
    )
    dispatch({
      type: INACTIVE_STATE_CLIENT_FORM,
    })
    dispatch(deleteFromListActions('CREATE_USER'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'errorCreateEntities' }
    })
    dispatch(deleteFromListActions('CREATE_USER'))
  })
}

export const getEmployeeEdit = (id) => async dispatch => {
  await axios.get(
    `${API_ENDPOINT+GET_EMPLOYEE_ROUTE}${id}`, { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: GET_EMPLOYEE_EDIT,
      payload: {
        id: id,
        firstName: decryptedData.firstName,
        lastName: decryptedData.lastName,
        email: decryptedData.email,
        role: decryptedData.role,
        documentType: decryptedData.documentType,
        documentNumber: decryptedData.documentNumber,
        roleID: decryptedData.roleID,
        status: decryptedData.status
      }
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const changeFormEmployee = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_EDIT_EMPLOYEE,
    payload: {
      key,
      value
    }
  })
  dispatch({
    type: ACTIVE_FORM_EDIT,
  })
}

export const putEditEmployee = (body, id) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.put(
    `${API_ENDPOINT+PUT_EMPLOYEE_ROUTE}${id}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(() => {
    dispatch({
      type: EDIT_EMPLOYEE,
      payload: body
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'employeeEdit' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'employeeEdit' }
        })
        dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })
      }, 2000)
    )
    dispatch({
      type: INACTIVE_STATE_CLIENT_FORM,
    })
    dispatch({
      type: INACTIVE_FORM_EDIT,
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'errorCreateEntities' }
    })
  })
}

export const getRolesClient = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT+GET_ROLES_CLIENT_ROUTE}`, { headers: await getHeaders() }
    )
    const decryptedData = await decrypt(res.data.result);
    if (res.status === 200) {
      return decryptedData
    }
  } catch (error) {
  }
}

export const putStateActiveClient = (body, id) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.put(
    `${API_ENDPOINT+PUT_STATE_CLIENT_ROUTE}${id}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: EDIT_CLIENT,
      payload: decryptedData
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'clientActive' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'clientActive' }
        })
        /*dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })*/
      }, 2000)
    )
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'clientCantActive' }
    })
  })
}

export const putStateInactiveClient = (body, id) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.put(
    `${API_ENDPOINT+PUT_STATE_CLIENT_ROUTE}${id}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: EDIT_CLIENT,
      payload: decryptedData
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'clientInactive' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'clientInactive' }
        })
      /*  dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })*/
      }, 2000)
    )
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'clientCantInactive' }
    })
  })
}

export const getLogsList = (body) => async (dispatch) =>{
  const encryptedBody = await encrypt(body)
  await axios.post(
    `${API_ENDPOINT+TRACKING_LOGS_ROUTE}`, {data : encryptedBody}, { headers: await getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SET_LOGS_LIST_CLIENT,
      payload: {logsList: decryptedData.logs, logsCount: decryptedData.elementsCount}
    })
    dispatch({
      type: VIEW_PAGINATION_INTERNAL,
      payload: {
          count: 20,
          total: decryptedData.elementsCount,
    }
  })
  }).catch(async reason =>{
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: SET_LOGS_LIST_CLIENT,
      payload: {logsList: [], logsCount: 0}
    })
    dispatch({
      type: VIEW_PAGINATION_INTERNAL,
      payload: {
          count: 20,
          total: 0,
    }
  })
  })
}