import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { configMenu } from '../../routes/menuRoutes'
import { errors } from '../../utils/errorsActions'
import { cleanErrorsAction } from '../../actions/errorsActions'
import { getRefreshToken, logoutUser, updateViewAvatarInfo } from '../../actions/authActions'
import TopMenuBar from './TopMenuBar'
import AppContent from './AppContent'
import { ReactComponent as GseIcon } from '../../assets/icons/Logo_registraduria.svg'
import { recordsMassiveFileV2 } from '../../actions/modalActions';
import { chageStateLoadCitizens, chageStateLoadNotifications, consultNotificationsProgressV2, saveHiredV2 } from '../../actions/writerActions';
import SendNotifications from '../SendNotifications/SendNotifications';
import SendCitizens from '../TemporalDashboard/SendCitizens';
import { LOGOUT, RESET_DASHBOARD, SET_LOGIN_TYPE, SHOW_AVATAR_INFO, STATE_CLEAR_FILTER } from '../../actions/actionsUtilities/types';
import { Button, Checkbox, Popup } from 'semantic-ui-react';
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions';
import { Dimmer, Loader } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom-v5-compat';

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const Blurred = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(0,0,0, 0.7);
  z-index: 1800;
  position: absolute;
  @media only screen and (max-width: 768px)  {
    display:none;
  }
`

const Footer = styled.footer`
  display: none;
  background: #F7F7F7;
  z-index: 1000;
  @media only screen and (max-width: 1500px)  {
    display: block;
    width:100%;
    text-align: center;
  }
`
const CourtainsContainer = styled.div`
  position:fixed;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  bottom 0;
  rigth: 16px;
  padding: 0 15% 0 0;
  z-index:1000;
  @media only screen and (max-height: 900px){
  } 
  @media only screen and (max-width: 1500px){
    padding: 0;
  }
  @media only screen and (max-width: 1124px){
    padding: 0;
    rigth: 0;
  }
  @media only screen and (max-width: 1024px){
    gap:8px;
    padding: 0 8px 0 8px;
  }
  @media only screen and (max-width: 768px){
    gap:63px;
    rigth: 0;
    flex-direction: column;
    padding:0;
    bottom:0;
    width: 100%;
  }
`
const Courtain = styled.div`
  margin: auto 10px 0 0;
  padding: 0;
  display: block;
  justify-content: end;
  width: 544px;
  position:relative;
  @media only screen and (max-width: 1280px){
    width:43%;
  }
  @media only screen and (max-width: 1024px){
    width:50%;
    margin: auto 0 0 0;
  }
  
  @media only screen and (max-width: 768px){
    margin: 0;
    width: 100%;
  }
`
const PopupUser = styled(Popup)`
  padding: 22px !important;
  position:relative;
  font-size:16px !important;
  font-weight: 500 !important;
  gap: 16px; 
  border-radius: 4px 0px 0px 0px; 
  opacity: 0px;
  border: 0px !important;
  background: #0091F9 !important;
  color: #FFFFFF !important;
  width: 328px;
  max-width: unset !important;
  height: 209px;
  z-index: 1900;
  top: 88px !important;
  right: 37px !important;
  @media only screen and (max-height: 900px)  {
    top:10.8vh !important;
  }
  :before{
      background: #0091F9 !important;
  }
`

const CourtainsBackground = styled.div`
    position: absolute;
    top:0;
    left:0;
    display: none;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.4);
    @media only screen and (max-width: 768px){
      display:block;
      z-index:1000;
    }
`

const CustomCheckbox = styled(Checkbox)`
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 18px !important;
    height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
  }
  input:checked ~ .box:after, input:checked ~ label:after {
    color: #0091F9 !important;
    background: #FFFFFF 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 1px solid #FFFFFF !important;
    width: 19px;
    height: 19px;
  }
  & label:before {
    border: 2px solid #ffffff !important;
    border-radius: 2px !important;
    width: 18px !important;
    height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
    background: #0091F9 !important;
  }
  input[disabled] ~ label{
    opacity: 0.2 !important;
  }
  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
    }
@media only screen and (max-width: 768px) {
    & label:before {
        width: 17px !important;
        height: 17px !important;
        max-width: 18px !important;
        max-height: 18px !important;
    }
}
    padding: 2px 0 0 0 !important;
    margin: 11px 5px -3px 0;
`
const CustomButton = styled(Button)`
  font-family: Roboto !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  border: 1px solid #ffffff !important;
  border-radius: 4px !important;
  padding: 16px 32px !important;
  background: #0091F9 !important;
  color: #FFFFFF !important;
  margin: -10px auto 0 auto !important;
  display: block !important;
`

const AppContainer = (props) => {
  const history = useHistory()
  const { userPermission, role, companyID } = useSelector((state) => state.authReducer.user)
  const { typeOfLogin, showAvatarInfo } = useSelector((state) => state.authReducer)
  const errorReducer = useSelector((state) => state.errorReducer)
  errors(props, errorReducer)

  const stateLoadingCitizens = useSelector(state => state.writerReducer.loadCitizens)
  const stateLoadNotifications = useSelector(state => state.writerReducer.loadNotifications)
  const { loadCitizensList, loadNotificationsList } = useSelector(state => state.writerReducer)
  const resultNotificationsProgress = useSelector(state => state.writerReducer.resultNotificationsProgress)
  const resultRecordFileMassive = useSelector(state => state.writerReducer.resultRecordFileMassive.listFileSearchMassive)
  const writeNotification = useSelector(state => state.modalReducer.writeNotification)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (role === '62ab678573b800f3b2f0abee') dispatch(saveHiredV2())
  }, [dispatch])

  useEffect(() => {
    setLoader(false)
  }, [])

  useEffect(() => {
    if (userPermission) {
      if (companyID && userPermission.includes('RECORDS_FILE_MASSIVE') && userPermission.includes('PROCESS_FILE_MASSIVE') && !location.pathname.includes("/login")) {
        dispatch(recordsMassiveFileV2({ companyID: companyID }))
        dispatch(consultNotificationsProgressV2({}))
      }
    }
  }, [])

  useEffect(() => {
    if (userPermission) {
      if (userPermission.includes('RECORDS_FILE_MASSIVE') && userPermission.includes('PROCESS_FILE_MASSIVE') && !location.pathname.includes("/login")) {
        validateLoadCitizens();
        validateLoadNotifications();
      }
    }
  }, [resultRecordFileMassive.length !== 0, resultNotificationsProgress.progress.length !== 0])

  const validateLoadCitizens = () => {
    if (resultRecordFileMassive.length > 0) {
      dispatch(chageStateLoadCitizens(true));
    }
  }

  const validateLoadNotifications = () => {
    const statusList = ['En espera', 'En progreso']
    resultNotificationsProgress.progress.forEach((element) => {
      if (statusList.includes(element.state)) {
        dispatch(chageStateLoadNotifications(true));
      }
    });
  }

  useEffect(() => {
    if (!sessionStorage.getItem('secretKey') && userPermission) {
      dispatch({ type: LOGOUT })
      dispatch({ type: STATE_CLEAR_FILTER })
      dispatch({ type: RESET_DASHBOARD })
      if (!!history)
        if (typeOfLogin !== '' && typeOfLogin === 'withOutCertificate') {
          history.push({ pathname: '/login-user', state: { message: 'Sesión finalizada exitosamente' } })
        } else {
          history.push({ pathname: '/', state: { message: 'Sesión finalizada exitosamente' } })
        }
    }
  }, [])

  const [showMessage, setShowMessage] = useState(false)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setShowMessage(showAvatarInfo)
  }, [showAvatarInfo])

  const handleClick = () => {
    setShowMessage(false)
    dispatch({ type: SHOW_AVATAR_INFO, payload: false })
    if (checked) dispatch(updateViewAvatarInfo({viewInfoAvatar: false}))
  }

  const onChange = () => {
    setChecked(!checked)
  }
  var {width} = UseWindowDimensions()
  return (
    <>
      <Dimmer.Dimmable>
        <Dimmer active={loader} inverted page={false} >
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
      {showMessage && width > 768 &&
        <Blurred>
          <PopupUser style={{ position: 'relative' }} position='bottom right' open={true}>
            Haz clic en el ávatar para ver tus datos, cambiar de rol (si tienes más de uno) y/o cerrar sesión.
            <br />
            <CustomCheckbox onChange={onChange} checked={checked} /> No volver a mostrar
            <br />
            <br />
            <CustomButton onClick={handleClick}> Entendido </CustomButton>
          </PopupUser>
        </Blurred>
      }
      <VerticalContainer>
        <TopMenuBar menuRoutes={configMenu(userPermission)} history={history} />
        <AppContent menuRoutes={configMenu(userPermission)} history={history} children={props.children} />
        <Footer> <GseIcon /> </Footer>
        {(loadCitizensList || loadNotificationsList) && <CourtainsBackground />}
        {
          !!role ?
            <>
              {!!companyID && !writeNotification && !!userPermission && !location.pathname.includes("/login") && role === '62ab678573b800f3b2f0abee' &&

                <CourtainsContainer>
                  {stateLoadingCitizens && <Courtain ><SendCitizens /></Courtain>}
                  {stateLoadNotifications && <Courtain ><SendNotifications history={history} /></Courtain>}
                </CourtainsContainer>
              }
            </>
            :
            <>
            </>
        }
      </VerticalContainer>
    </Dimmer.Dimmable>
    </>
  )
}

export default AppContainer
