import React from 'react'
import styled from 'styled-components'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import { useDispatch, useSelector } from 'react-redux'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/sent/leftArrow.svg'
import { FormCreateReport } from '../../../components/FormReport/FormCreateReport'
import { useNavigate } from 'react-router-dom-v5-compat'

const Divider = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll !important;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
` 
const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin: 10px;
  }
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
`

const CreateReport = () => {

  const dispatch = useDispatch()
  const {activeForm} = useSelector(state => state.clientReducer)
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(-1)
  }

  return (
    
    <Divider>
      <MainSegment>
        <Container>
          <Arrow>
            <LeftArrow
              onClick={handleClick}
            >
              <LeftArrowIcon/>
            </LeftArrow>
          </Arrow>
          <TitleContainer>
            <Title>Generar reporte</Title>
          </TitleContainer>
        </Container>
        <FormCreateReport/>
      </MainSegment>
    </Divider>
  )
}

export default CreateReport