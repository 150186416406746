import React from 'react'
import styled from 'styled-components'
import MenuUser from '../MenuBar/MenuUser'
import { ReactComponent as Logo } from '../../assets/icons/sent/logo.svg'
import { Button } from 'semantic-ui-react'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: #133F4F;
  color: #FFFF;
  height: 100px;
  margin: 0;
  padding: 0 40px;
  @media only screen and (max-height: 900px)  {
    height:12vh;
  }
  @media only screen and (max-width: 768px)  {
    height:50px;
    padding: 0px 15px;
  }
`

const ButtonIcon= styled(Button)`
  padding: 0px !important;
  @media only screen and (max-width: 768px)  {
    padding: 0px 15px !important;
    position: absolute;
    left: 10px;
  }
`

const LogoButton= styled(Logo)`
  @media only screen and (max-width: 768px)  {
    width:266px;
  }
`


const TopMenuBar = (props) => {

  const onClickEvent = (evt)=>{
    evt.preventDefault()
    props.history.push('/dashboard')
  }
  return (
    <Container>
      
      <ButtonIcon style={{ backgroundColor: '#133F4F' }} onClick={onClickEvent}>
        <LogoButton />
      </ButtonIcon>
      
      <MenuUser menuRoutes={props.menuRoutes} history={props.history} />
    </Container>
  )
}

export default TopMenuBar
