import React, { useEffect } from "react"
import PropTypes from "prop-types"
import './input-from-massive-load.css'
import styled from "styled-components"

const Text = styled.div`

`
const Text2 = styled.span`
    
`
const InputFormMassiveLoad = ({ ...props }) => {

    const { placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled, readOnly, onKeyDown } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''
    return (
            <div style={{width:'100%'}}>
                <label className={errorValidator ? 'labelInputFormMassiveLoadError' : 'labelInputFormMassiveLoad'}>
                    <input
                        id={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={!errorValidator? placeholder:''}
                        onChange={onChange}
                        required
                        className={'inputFormMassiveLoad'}
                        disabled={disabled}
                        readOnly={readOnly}
                        onKeyDown={onKeyDown}
                        autoComplete="off"
                    />
                    {
                        errorValidator && value === ''
                        &&
                        <>
                            <Text2 className={errorValidator && value === '' && 'spanLabelInputCompanyNameTopError'}>{label}</Text2>
                            <Text className={(errorValidator) && 'input-error3'}>{errorValidator}</Text>
                        </>
                    }
                    {
                        errorValidator === undefined && value !== ''
                        &&
                        <>
                            <span className={'spanLabelInputCompanyNameTop'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === ''
                        &&
                        <>
                            <span className={'spanLabelInputCompanyName'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator && value !== ''
                        &&
                        <>
                            <Text2 className={'spanLabelInputCompanyNameTopError'}>{label}</Text2>
                            <Text className={'input-error2'}>{errorValidator}</Text>
                        </>
                    }
                </label>
            </div>
    )
}


InputFormMassiveLoad.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormMassiveLoad