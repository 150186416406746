import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/sent/leftArrow.svg'
import {warningFormModal} from '../../../actions/modalActions'
import WarningModal from '../../../components/ModalGeneralForms/WarningModal'
import { useNavigate } from "react-router-dom-v5-compat"
import FormCreateProviders from '../../../components/FormProvider/FormCreateProviders'
import { getGeographicList } from '../../../actions/defaultListActions'

const Divider = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`

const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin:10px;
  }

`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
`
const CreateProviders = () => {

  const dispatch = useDispatch()
  const {activeForm} = useSelector(state => state.providerReducer)
  const navigate = useNavigate()
  const [countryList, setCountryList] = useState([])

  const geographicList = useSelector((state) => state.defaultListReducer.geographicList)

  const handleClick = () => {
    if (activeForm === true) {
      dispatch(warningFormModal({type: 'warningLeaveProvider'}))
    } else {
      navigate('/providers')
    }
  }

  const fetchGeographicList = async () => {
    await dispatch(getGeographicList());
  };

useEffect(() => {
  fetchGeographicList().then(() => {
  });
}, []);


useEffect(() => {
  if(geographicList.length > 0){
    const dataCountry = geographicList.map((x) => {
      return { key: x.code, text: x.name, value: x.code };
    });
    dataCountry.unshift({ key: 'xxx', text: '', value: '' });
    setCountryList(dataCountry);
    }
}, [geographicList])

  return (
    <Divider>
      <Container>
        <Arrow>
          <LeftArrow
            onClick={handleClick}
          >
            <LeftArrowIcon/>
            <WarningModal/>
          </LeftArrow>
        </Arrow>
        <TitleContainer>
          <Title>Nuevo operador</Title>
        </TitleContainer>
      </Container>
      <FormCreateProviders
       countryList={countryList}
      />
      
    </Divider>
  )
};

export default CreateProviders
