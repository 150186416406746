import React, { Component } from 'react'
import {
  Checkbox,
  Dropdown,
  Form
} from 'semantic-ui-react'
import './index.css'
import styled from "styled-components"

const CustomCheckbox = styled(Checkbox)`
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 2px solid #22C1D0 !important;
    width: 18px;
    height: 18px;
  }

  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label{
    opacity: 0.2 !important;
  }
  
  padding-right: 10px !important;

  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
}
`
const TextPlaceholder = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: absolute;
  top: 35px;
  left: 15px;
  z-index: 3;
`
const DropdownGeneric = styled(Dropdown)`
&&& .icon {
  top: 25% !important; 
}
&&& .item {
  height: 32px; 
  line-height: 16px; 
  align-items:center;
  font-size: 16px;
}
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
`
const options = [
  { key: '', text: '', value: '' },
  { key: 'UNREAD', text: 'No leída', value: 'UNREAD' },
  { key: 'READ', text: 'Leída ', value: 'READ' },
  { key: 'PENDING', text: 'Pendiente', value: 'PENDING' },
  { Key: 'REJECTED', text: 'Rechazada', value: 'REJECTED' },
  { key: 'COMPLETE', text: 'Completada', value: 'SIGNED' },
  { key: 'ERROR', text: 'Error', value: 'ERROR' },
  { key: 'REALEASE', text: 'Novedad', value: 'RELEASE' },
]

const options2 = [
  { key: '', text: '', value: '' },
  { key: 'UNREAD', text: 'No leída', value: 'UNREAD' },
  { key: 'PENDING', text: 'Pendiente', value: 'PENDING' },
  { Key: 'REJECTED', text: 'Rechazada', value: 'REJECTED' },
  { key: 'COMPLETE', text: 'Completada', value: 'SIGNED' },
  { key: 'BOUNCED', text: 'Rebotada', value: 'BOUNCED' },
  { key: 'ERROR', text: 'Error', value: 'ERROR' },
  { key: 'REALEASE', text: 'Novedad', value: 'RELEASE' },
]

const options3 = [
  { key: '', text: '', value: '' },
  { key: 'STATE', text: 'Reporte de estado', value: 'STATE' },
  { key: 'WRONG', text: 'Notificaciones erróneas', value: 'WRONG' },
  { Key: 'LOG', text: 'Log de trazabilidad', value: 'LOG' },
]


export const FormFilter = (props) => {
  return (
    <Form onSubmit={props.handleSubmit}>
         <CustomCheckbox
              control={Checkbox}
              label='Contiene adjuntos'
              type='checkbox'
              onChange={(e) => { props.toggleCheckbox(e) }}
              checked={props.state.attachedFile}
              id="attachedFile"
            />
            <br />
            <br />
            <>
              <DropdownGeneric
                selection
                button
                options={props.tabFilter !== 'notificationSignature' && props.tabFilter !== 'docSignature' ? options : options2}
                style={{ width: "314px", marginBottom: "5px" }}
                value={props.state.notificationState}
                onChange={props.handleOnChange}
                id="state"
              />
              {
                props.state.notificationState === ''
                  ? <TextPlaceholder>Estado</TextPlaceholder>
                  : ''
              }
            </>
          </Form >
  )
}


export default FormFilter
