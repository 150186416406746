import React from "react"
import PropTypes from "prop-types"
import { Dropdown } from "semantic-ui-react"
import styled from 'styled-components'
import './input-from-massive-load.css'

const Divider = styled.div`
  display: flex;
`
const InputFormDropdown = ({ ...props }) => {

    const { className, placeholder, options,validator, validateOptions, value, name, id, index, onChangeAttribute, disabled } = props
    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions)

    return (
        <Divider className={errorValidator ? 'divInputDropdownFormMassiveLoadError' : 'divInputDropdownFormMassiveLoad'}>
            <Dropdown
                upward={false}
                name={name}
                selection
                button
                options={options}
                className={errorValidator ? "input-error-attribute-list": className}
                value={value}
                type={value}
                placeholder={placeholder}
                onChange={onChangeAttribute}
                id={id}
                index={index}
                autoComplete='off'
                style={errorValidator ?{ border: "1px solid #C3132E", paddingBottom: "29px" }: {paddingBottom: "29px" }}
                disabled={disabled}
            />
        </Divider>
    )
}


InputFormDropdown.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormDropdown