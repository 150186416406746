import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import RowTableReports from "../RowTableReports";
import _ from "lodash";
import moment from 'moment'
import GeneralTable from "../../../components/Table/GeneralTable";
import {
  activePagination
} from "../../../actions/filterActions";
import { getReports } from "../../../actions/reportsActions";
import ModalFilterReports from "../../../components/Search/SearchReports/ModalFilterReports";

const orderTranslator = (order) => {

  const options = {
    "masReciente": { _id: -1 },
    "masAntiguo": { _id: 1 },
    "A-Z": { name: 1 },
    "Z-A": { name: -1 },
  };
  return options[order];
}

const AllReports = ({setPaginationLoader}) => {

  const dispatch = useDispatch()
  const filterReducer = useSelector(state=> state.filterReducer)
  const reportsListArray = useSelector(state=> state.reportsReducer.reportsList)
  const isCreateReport = useSelector(state=> state.reportsReducer.isCreateReport)

  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(false)
  const columnData = [
    { name: "Fecha generación", width: 3, arrow: false },
    { name: "Usuario que generó", width: 3, arrow: false },
    { name: "Tipo reporte", width: 3, arrow: false },
    { name: "Fecha inicio", width: 2, arrow: false },
    { name: "Fecha fin", width: 2, arrow: false },
    { name: "Estado", width: 3, arrow: false },
  ]

  useEffect(() => {
    makeReportsRequest(filterReducer)
  },[])

  useEffect(() => {
    if (init) {
      makeReportsRequest(filterReducer);
      setInit(true)
      setLoader(true)
    }
  },[filterReducer.sort,filterReducer.searchBar,filterReducer.count,filterReducer.pag,filterReducer.searchAdvanced])

  useEffect(() => {
    if(!!isCreateReport){
      setInit(false)
      setLoader(true)
      makeReportsRequest(filterReducer);
    }
  },[isCreateReport])

  const makeReportsRequest = useCallback((filter) => {
    const body = {
      count: filter.count,
      pag: filter.pag,
      //reportType: filter.reportsFilter.reportStatus,
      sort: orderTranslator(filter.sort),
      textToSearch: filter.searchBar[0],
      initialDate: !!filter.searchAdvanced.date.startDate
          ? moment(filter.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      finalDate: !!filter.searchAdvanced.date.endDate
          ? moment(filter.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      reportType: filter.searchAdvanced.reportType!= ''? filter.searchAdvanced.reportType
          :filter.reportsFilter.reportStatus,
    };

    dispatch(getReports(body)).then(()=>{
      dispatch(activePagination(false))
      setInit(true)
      setLoader(false)
      setPaginationLoader(false)
    })
  }, [dispatch])

  return (
      <>
        <GeneralTable
            list={reportsListArray}
            typeList={filterReducer.isSearch ? "default" : "reports"}
            listHeight = {'reports'}
            headerTable={columnData}
            rowComponent={RowTableReports}
            loader={loader}
            heigthHeader={'45px'}
        />
        <ModalFilterReports />
      </>
  );
}

export default AllReports
