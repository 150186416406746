import React, {Component} from 'react'
import PreviewMessage from './PreviewMessage'
import PreviewNotification from './PreviewNotification'
import PreviewList from './PreviewList'
import './preview.css'


const Preview = (props) => {

  const showPreview = () => {
    const {activePreview} = props
    const previews = {
      'previewMessage': <PreviewMessage/>,
      'previewList': <PreviewList/>,
      'previewNotification': <PreviewNotification/>,
      'default': <PreviewMessage/>
    }
    return !!previews[activePreview] ? previews[activePreview] : previews['default']
  }

    return (
      <>
      <div className='responsive'>
        <div className="ContainerTypeViewRes">
                    {showPreview()}
                  <div>
                    <div className={props.classViewArrow}>
                      {props.leftArrowPreview}
                      {props.rightArrowPreview}
                    </div>
                  </div>
        </div>
         
      </div>
      </>
    )
}

export default Preview
