import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { warningFormModal } from '../../../actions/modalActions'
import { actionClearFilter } from '../../../actions/filterActions'
import { clearDashboardSearch} from '../../../actions/dashboardActions'
import MenuIcon from './MenuIcon'


const CustomButton = styled.div`
  background: ${props => props.active ? 'rgba(0, 0, 0, 0.05)' : 'transparent'} 0 0 no-repeat padding-box;
  height: 40px;
  min-height: 33px;
  border-radius: 5px;
  opacity: 1;
  padding: 8px 6px 8px 32px;
  cursor: pointer;
  position: relative;
  display: table;
  overflow: hidden;
  @media only screen and (max-width: 1700px) {
    height: auto;
  }
  @media only screen and (max-width: 1330px) and (min-width:769px) {
    padding: 8px;
    height: auto;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
`
const MenuContent = styled.div`
  height: 40px;
  margin-bottom:8px;
  @media only screen and (max-width: 1700px) {
    height: auto;
    margin-bottom: 0px;
  }
`

const ButtonIcon = styled.div`
  display: table-cell;
  vertical-align: middle;

  @media only screen and (max-width: 1330px) and (min-width:769px) {
    width: 100%;
    display: block;
  })
`

const ButtonText = styled.div`
  color: ${props => props.color ? props.color : 'white'};
  display: table-cell;
  vertical-align: middle;
  width: 100%;

  @media only screen and (max-width: 1330px) and (min-width:769px) {
    width: 100%;
    padding:0px 5px !important;
    font-size: 12px;
    line-height: 14px;
    display: block;
  })
`
const ButtonCount = styled.div`
  color: ${props => props.color ? props.color : 'white'};
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  font-family: Roboto;
  font-weight: 500;
  line-height: 23px;
  padding-right: 24px;
  @media only screen and (max-width: 1600px) {
    padding-right: 16px;
  }
  @media only screen and (max-width: 1500px) {
    padding-right: 5px;
  }
  @media only screen and (max-width: 1400px) {
    padding-right: 0px;
  }
  @media only screen and (max-width: 1400px) {
    padding-right: 0px;
  }
  @media only screen and (max-width: 769px) {
    padding-right: 24px;
  }
  @media only screen and (max-width: 1330px) and (min-width:769px) {
    width: 100%;
    padding:0px 5px !important;
    font-size: 12px;
    line-height: 14px;
    display: block;
  })
`

const MenuButton = (props) => {
  const color = '#133F4F'
  const dispatch = useDispatch()
  const { activeForm } = useSelector(state => state.clientReducer)
  const { elementsCount } = useSelector(state => state.draftReducer)
  const history = useHistory()
  const ruta = history.location.pathname


  const handleRedirec = () => {
    dispatch(actionClearFilter())
    dispatch(clearDashboardSearch())
    if (ruta === '/createClients' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeave' }))
    } else if (ruta === '/createProviders' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveProvider' }))
    } else if (ruta === '/editClients' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
    } else if (ruta === '/editProviders' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
    } else if (ruta === '/createEmployee' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveUser' }))
    } else if (ruta === '/editEmployee' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
    } else if (ruta === '/createAgreements' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveAgreements' }))
    } else if (ruta === '/editAgreements' && activeForm === true) {
      dispatch(warningFormModal({ type: 'warningLeaveAgreements' }))
    } else {
      props.redirect(props.value)
    }
  }
  return (
    <MenuContent style={props.value.visible === true ? { display: 'block' } : { display: 'none' }}>
      <CustomButton
        onClick={handleRedirec}
        active={props.active}
        style={props.value.visible === true ? { display: 'block' } : { display: 'none' }}
      >
        <ButtonIcon>
          <MenuIcon name={props.value.name} color={color} />
        </ButtonIcon>
        <ButtonText color={color}>
          {props.value.title}
        </ButtonText>
          {props.value.showCount && 
            <ButtonCount color={color}>
              {elementsCount > 0 && elementsCount}
            </ButtonCount>
          }
      </CustomButton>
    </MenuContent>
  )
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps, {})(MenuButton)