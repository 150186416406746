import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as EnabledButton } from '../../../../src/assets/icons/clients/enabled-button.svg'
import { ReactComponent as DisabledButton } from '../../../../src/assets/icons/clients/disabled-button.svg'
import { ReactComponent as EditImage } from '../../../../src/assets/icons/clients/edit-button.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getClientDetail, getClientEdit, getClients, putClientEdit, putEditEmployee } from '../../../actions/clientActions'
import { useHistory } from 'react-router-dom'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'
import { Cell, CellOptions } from '../../../components/Table/CellComponent'
import { refreshStateModule, warningFormModal } from '../../../actions/modalActions'
import { useNavigate } from "react-router-dom-v5-compat"

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const Row = styled(Table.Row)`
  cursor: pointer !important;
  height: 50px;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    height: unset;
  }
`

const Enabled = styled(EnabledButton)`
  margin: 4px 0 0 0 !important;
`

const Disabled = styled(DisabledButton)`
  margin: 4px 0 0 0 !important;
`

const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
`



const RowTableClientsSuperAdmin = (props) => {

  const dispatch = useDispatch()
  const [Isvisible, setIsVisible] = useState(false); 
  const navigate = useNavigate()
  const { count, pag, searchBar, sort } = useSelector(state => state.filterReducer)
  
  const orderTranslator = (order) => {
    const options = {
      'masReciente': {"_id": -1},
      'masAntiguo': {"_id": 1},
      'A-Z': {"name": 1},
      'Z-A': {"name": -1}
    }
    return options[order]
  }
  
  const body= {
    count: count,
    pag: pag,
    sort: orderTranslator(sort),
    textToSearch: searchBar[0]
  }

  const redirectToClientDetail = () => {
    sessionStorage.setItem("id", props.data.companySerialID)
    dispatch(getClientDetail(props.data.companySerialID))
    navigate({ pathname: '/clients-detail/' + props.data.companySerialID })
  }

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(refreshStateModule())
    sessionStorage.setItem("id", props.data.companySerialID)
    dispatch(getClientEdit(props.data.companySerialID))
    navigate('/editClients')
  }

  const handleChangeActive = (e) => {
    sessionStorage.setItem("idClient", props.data.companySerialID)
    sessionStorage.setItem("codeCompany", props.data.codeCompany)
    dispatch(warningFormModal({ type: 'warningUpdateClientActive' }))
  }
  const handleChangeInactive = (e) => {
    sessionStorage.setItem("idClient", props.data.companySerialID)
    sessionStorage.setItem("codeCompany", props.data.codeCompany)
    dispatch(warningFormModal({ type: 'warningUpdateClientInactive' }))
  }

  const width = UseWindowDimensions().width
  return (
      <Row onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} >
        <Cell width={3} text={props.data.name} columnName={props.headerTable[0].name} onClick={redirectToClientDetail}/>
        <Cell width={2} text={props.data.numberDocumentCompany} columnName={props.headerTable[1].name} onClick={redirectToClientDetail}/>
        <Cell width={2} text={props.data.phone} columnName={props.headerTable[2].name} onClick={redirectToClientDetail}/>
        <Cell width={3} text={props.data.email} columnName={props.headerTable[3].name} onClick={redirectToClientDetail}/>
        <Cell width={3} text={props.data.legalManagerName} columnName={props.headerTable[4].name} onClick={redirectToClientDetail}/>
        <CellOptions isVisible={width >768 ? Isvisible && !props.data.hasAgreementAsociate:!props.data.hasAgreementAsociate} columnName={props.headerTable[5].name} 
        clickEdit={handleClick} active={props.data.status!== 'ENABLE'?'INACTIVE':'ACTIVE'} handleChangeActive={handleChangeActive} 
        handleChangeInactive={handleChangeInactive} toggleDisabled={props.data.hasAgreementAsociate} customMargin={true}/>
      </Row>
  )
}

RowTableClientsSuperAdmin.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableClientsSuperAdmin