import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './main.css'
import { actionSearchBar, inactiveViewMix, activeViewMix } from '../../actions/filterDetailActions'
import { ReactComponent as Close } from '../../assets/icons/write/close.svg'


const SearchBarDetail = (props) => {

  const dispatch = useDispatch()

  const [searchValue, setSearchValue] = useState('')
  const [placeholder, setPlaceholder] = useState(props.textPlaceHolder);

  const searchBar = useSelector(state => state.filterDetailReducer.searchBar)

  const inputRef = useRef(null);
  const focusRef = useRef(false);

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(actionSearchBar(searchValue))
    dispatch(activeViewMix())
  }

  const handleOnChange = () => e => {
    setSearchValue(e.target.value)
    focusRef.current = false;
  }

  const inputSpace = () => {
    if (searchValue !== searchBar[0]) {
      if (!focusRef.current) {
        dispatch(actionSearchBar(searchValue))
      }
    }
  }

  const clearInput = () => {
    setSearchValue('')
    dispatch(actionSearchBar('', 'initialState'))
    dispatch(inactiveViewMix())
  }

  const changeFocus = () => {
    focusRef.current = true;
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  return (
    <form onSubmit={handleSubmit}>
      <div className="search-content">
        <input
          value={searchValue}
          onChange={handleOnChange()}
          onBlur={searchValue === '' && (Array.isArray(searchBar) ? searchBar[0] === searchValue : searchBar === searchValue) ? () =>
            setPlaceholder(!placeholder ? 'Número de identificación del destinatario o asunto' : props.textPlaceHolder)
            : inputSpace
          }
          onFocus={() => setPlaceholder(props.textPlaceHolder)}
          placeholder={(!placeholder) ? '           Número de identificación del destinatario o asunto' : '           ' + placeholder}
          className='inputSearch'
          type='text'
          id='searchValue'
          autoComplete='off'
          maxLength="100"
          style={{ textOverflow: 'ellipsis' }}
          ref={inputRef}
        />
        <Close
          className={(searchValue === "" || searchValue === undefined) ? 'iconInput iconClose close' : 'iconInput iconClose'}
          onClick={clearInput}
          onMouseEnter={changeFocus}
        />
      </div>
    </form>
  )
}
export default SearchBarDetail