import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Divider } from 'semantic-ui-react'
import ToolBarContent from '../../../components/Toolbar/ToolBarContent'
import { Quill } from 'react-quill';
import IncludeTag from '../../../components/TextEditor/TagButton/IncludeTag'
import 'react-quill/dist/quill.snow.css';
import './style.css'
import './ToolBarEditor.css'
import {
  changeMessageModal, changeMassiveNotificationModal, closewriteNotificationModal, changeMessageModalTwo, recordsMassiveFileV2,
  warningFormModalWrite
} from '../../../actions/modalActions'
import _ from 'lodash'
import { LIMIT_FILE_SIZE } from '../../../helpers/globalHelpers'
import { fileToBase64Clean } from '../../../helpers/filesLib'
import {
  changeTextEditor,
  changeAttButton,
  changeWriteNotificationFormAdd,
  openSendNotificationMassive,
  saveNotification,
  saveNotificationMassiveV2,
  saveNotificationOne,
  saveNotificationMultiple,
  warningModal,
  updateSendNotificationMassiveLoadingModal,
  inactiveFormProvider,
  chageStateLoadNotifications,
  chageStateLoadCitizens,
  chageStateLoadNotificationsList,
  changeIsSendEmail,
} from '../../../actions/writerActions'
import { saveDraftData, saveDraftV2, validateHyperlinks } from '../../../actions/draftsActions';
import { cleanErrorsAction } from '../../../actions/errorsActions';
import BoldIcon from '../../../components/Toolbar/bold.svg'
import ItalicIcon from '../../../components/Toolbar/italic.svg'
import UnderlineIcon from '../../../components/Toolbar/underline.svg'
import AlignCenterIcon from '../../../components/Toolbar/align_center.svg'
import AlignJustifyIcon from '../../../components/Toolbar/align_justify.svg'
import AlignLeftIcon from '../../../components/Toolbar/align_left.svg'
import AlignRightIcon from '../../../components/Toolbar/align_right.svg'
import ColorIcon from '../../../components/Toolbar/color.svg'
import BulletIcon from '../../../components/Toolbar/bullet.svg'
import NumberIcon from '../../../components/Toolbar/number.svg'
import IndentSumIcon from '../../../components/Toolbar/indent1.svg'
import IndentResIcon from '../../../components/Toolbar/indent_1.svg'
import PrimaryButton from '../../../components/PrimaryButton'
import SpecificFileContainer from '../../../components/TextEditor/SpecificFileContainer';
import { actionSearchBar, inactiveViewMix } from '../../../actions/filterActions'
import { actionClearFilter } from '../../../actions/filterActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const EditorQuill = (props) => {

  const dispatch = useDispatch()
  const [state, setState] = useState({
    quill: Object,
    bold: false,
    italic: false,
    underline: false,
    align: '',
    header: '',
    color: '',
    list: '',
    indentUL: 1,
    indentOL: 1,
    sending: false,
    fileList: [],
    errorFiles: [],
    filesLoaded: false,
    errorLoadingFiles: false,
    filesLoading: false,
    numberOfFiles: 0,
  })

  const customEqual = (oldValue, newValue) => oldValue === newValue
  const disableAtt = useSelector(state=> state.writerReducer.disableAtt)
  const form = useSelector(state => state.writerReducer.notificationForm)
  const files = useSelector(state=> state.writerReducer.notificationForm.files)
  const specificFiles = useSelector(state=> state.writerReducer.listDocumentsSpecific)
  const isMassiveRecipientValue = useSelector(state=> state.writerReducer.isMassiveRecipient)
  const loadingSendNotification = useSelector(state=> state.writerReducer.loadingSendNotification)
  const loadingSendNotificationMassive = useSelector(state=> state.writerReducer.loadingSendNotificationMassive)
  const listIdsSendMassive = useSelector(state=> state.writerReducer.listIdsSendMassive)
  const textEditor = useSelector(state=> state.writerReducer.notificationForm.textEditor)
  const textEditorFromDraft = useSelector(state=> state.writerReducer.notificationForm.textEditorFromDraft)
  const fileSerialID = useSelector(state=> state.writerReducer.fileSerialID)
  const draftData = useSelector(state=> state.draftReducer.toSaveDraft)
  const draftSerialID = useSelector(state=> state.writerReducer.draftSerialID)
  const resultRecordsFileMassive = useSelector(state=> state.writerReducer.resultRecordFileMassive.listFileSearchMassive)
  const companyID = useSelector(state=> state.authReducer.user.companyID._id)
  const IsSendEmail = useSelector(state=> state.writerReducer.IsSendEmail)
  const checkboxHeaderDisabled = useSelector(state=> state.writerReducer.checkboxHeaderDisabled)
  const formError = useSelector(state=> state.errorReducer.errorsForm, customEqual)
  const servicesList = useSelector(state=> state.writerReducer.hiredData.servicesList)
  const hiredData = useSelector(state=> state.writerReducer.hiredData)
  const messageModal = useSelector(state => state.messageModal)

  const history = useHistory()

  const [prevIsSendEmail, setPrevIsSendEmail]= useState(IsSendEmail)

  const [textEditorFromDraftInitial, setTextEditorFromDraftInitial] = useState(textEditorFromDraft)

  let quill = null;
  useEffect(() => {
    const Parchment = Quill.import('parchment')
    const validAlign = {
      right: "end",
      center: "center",
      justify: "auto",
      left: 'start',
    }

    class IndentAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        if (value === 0) {
          this.remove(node)
          return true
        } else {
          if (node.tagName === 'LI') {
            if (value === 1) {
              if (node.parentNode.dataValue < 9) {
                node.parentNode.dataValue++
              }
            } else {
              if (node.parentNode.dataValue > 1) {
                node.parentNode.dataValue--
              }
            }
            node.parentNode.style.paddingLeft = `${node.parentNode.dataValue}em`
          } else {
            return super.add(node, `${value}em`)
          }
        }
      }
    }

    class AlignAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        if (value === 0) {
          this.remove(node)
          return true
        } else {
          node.style.alignSelf = validAlign[value]
          return super.add(node, `${value}`)
        }
      }
    }

    class SizeAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        if (value === 0) {
          this.remove(node)
          return true
        } else {
          return super.add(node, `${value}`)
        }
      }
    }

    let IndentStyle = new IndentAttributor('indent', 'padding-left', {
      scope: Parchment.Scope.BLOCK,
      whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
    })

    let AlignStyle = new AlignAttributor('align', 'text-align', {
      scope: Parchment.Scope.LIST,
      whitelist: ['center', 'justify', 'left', 'right']
    })

    let SizeStyle = new SizeAttributor('size', 'font-size', {
      scope: Parchment.Scope.INLINE,
      whitelist: ['10px', '16px', '22px', '28px']
    })

    Quill.register(IndentStyle, true)
    Quill.register(AlignStyle, true)
    Quill.register(SizeStyle, true)

    var icons = Quill.import('ui/icons');

    icons['bold'] = `<img src="${BoldIcon}">`
    icons['italic'] = `<img src="${ItalicIcon}">`
    icons['underline'] = `<img src="${UnderlineIcon}">`
    icons['align']['center'] = `<img src="${AlignCenterIcon}">`
    icons['align']['justify'] = `<img src="${AlignJustifyIcon}">`
    icons['align']['left'] = `<img src="${AlignLeftIcon}">`
    icons['align']['right'] = `<img src="${AlignRightIcon}">`
    icons['color'] = `<img src="${ColorIcon}">`
    icons['list']['bullet'] = `<img src="${BulletIcon}">`
    icons['list']['ordered'] = `<img src="${NumberIcon}">`
    icons['indent']['-1'] = `<img src="${IndentResIcon}">`
    icons['indent']['+1'] = `<img src="${IndentSumIcon}">`

    quill = new Quill('#editor-container', {
      modules: {
        toolbar: {
          container: '#toolbar',  // Selector for toolbar container
        }
      },
      placeholder: 'Escribe tu mensaje',
      theme: 'snow'
    });

    quill.on('text-change', (e) => {
      if (quill.root.innerText === "\n") {
        dispatch(changeTextEditor(""))
      } else {
        dispatch(changeTextEditor(quill.root.innerHTML))
      }

    });

    quill.on('selection-change', (range, oldRange, source) => {
      if (range === null && oldRange !== null) {
        if (!!quill.root.innerHTML) {
          dispatch(saveDraftData({ description: quill.root.innerHTML }))
          //dispatch(saveDraftV2(draftData))
        }
      }
    });
    setState((prevState)=> ({...prevState, quill: quill }))
    if (!!textEditor) quill.root.innerHTML = textEditor
  },[])

  useEffect(()=>{
    if(textEditorFromDraftInitial !== textEditorFromDraft){
      if(textEditorFromDraft){
        state.quill.root.innerHTML = textEditorFromDraft
      }
    }
  },[quill != null, textEditorFromDraft ])

  useEffect(()=>{
    if(Object.keys(form.files).length !== 0) {
      dispatch(changeAttButton(validateFileSizeTotalFiles(form.files)))
      setState((prevState)=>({...prevState, numberOfFiles: Object.keys(form.files).length }));
    }else{
      setState((prevState)=>({...prevState, numberOfFiles: 0 }));
    }
  },[form.files])

  useEffect(()=>{
    const editor = document.getElementById("editor-container")
      if (!!editor.querySelectorAll('p')) {
        const p = editor.querySelectorAll('p')
        for (var i = 0; i < p.length; i++) {
          if (p[i].style.fontFamily === "") {
            p[i].style.fontFamily = `Roboto`
          }
        }
      }
      if (!!editor.querySelectorAll('ul')) {
        const ul = editor.querySelectorAll('ul')
        for (var i = 0; i < ul.length; i++) {
          if (ul[i].style.paddingLeft === "") {
            ul[i].style.paddingLeft = `${state.indentUL}em`
            ul[i].dataValue = state.indentUL
          }

          setState((prevState) => ({...prevState, indentUL: ul[i].dataValue }))
          ul[i].style.display = 'flex';
          ul[i].style.flexDirection = 'column'
        }
      }

      if (!!editor.querySelectorAll('ol')) {
        const ol = editor.querySelectorAll('ol')
        for (var i = 0; i < ol.length; i++) {
          if (ol[i].style.paddingLeft === "") {
            ol[i].style.paddingLeft = `${state.indentOL}em`
            ol[i].dataValue = state.indentOL
          }

          setState((prevState) => ({...prevState, indentOL: ol[i].dataValue }))
          ol[i].style.display = 'flex';
          ol[i].style.flexDirection = 'column'
        }
      }

      if (!!editor.querySelectorAll('li')) {
        const li = editor.querySelectorAll('li')
        for (var i = 0; i < li.length; i++) {
          li[i].style.listStyleType = 'inherit'
          if (li[i].style.textAlign === '') {
            li[i].style.alignSelf = 'start'
            dispatch(changeTextEditor(state.quill.root.innerHTML))
          }
        }
      }
  },[textEditor])

  useEffect(()=>{
    if(IsSendEmail !== prevIsSendEmail){
      setPrevIsSendEmail(IsSendEmail)
      if (!!IsSendEmail) {
        handleSendEmail()
      }
    }
  },[IsSendEmail])

  const onChangeHandlerAttachment = async (e) => {
    const myFiles = e.target.files,
      filesTemp = Array.prototype.slice.call(myFiles, 0),
      validateFileSize = validateFileSizeSearch(filesTemp),
      validateFileType = validateFileTypeSearch(filesTemp),
      validateFileName = validateFileNameSearch(form.files, filesTemp)


    if (!validateFileSize) {
      if (!validateFileType) {
        await updateFileState(filesTemp, form.files, validateFileName.isLongName)
      } else {
        dispatch(changeMessageModal({
          type: 'warning',
          message: 'Solo son permitidos documentos PDF.'
        }))
      }
    } else {
      dispatch(changeMessageModal({
        type: 'warningFiles',
        message: [`El archivo supera el tamaño máximo permitido`, `Ten presente que el peso total de los adjuntos no puede superar los 10 MB`]
      }))
    }
  }

  const validateFileSizeSearch = (files) => {
    files.find(v => v.size === 0 || v.size > LIMIT_FILE_SIZE)
  }

  const validateFileSizeTotalFiles = files => {
    let sizeTotal = 0
    if (Object.keys(files).length >= 5)
      return true
    Object.keys(files).map((row) => (
      sizeTotal += files[row].size
    ))
    return sizeTotal > LIMIT_FILE_SIZE
  }

  const numberOfPropFiles = files => {
    return Object.keys(files).length
  }

  const getFileSizePropFiles = files => {
    let sizeTotal = 0
    Object.keys(files).map((row) => (
      sizeTotal += files[row].size
    ))
    return sizeTotal
  }

  const validateFileNameSearch = (files, newFile) => {
    let isFileNameRepeated = false
    let nameRepeated = ''
    let isLongName = false
    if (newFile[0].name.length > 120) {
      isLongName = true
    }
    Object.keys(files).map((row) => {
      newFile.forEach(file => {
        if (files[row].name === file.name) {
          nameRepeated = file.name
          isFileNameRepeated = true
        }
      })
    })
    return { isFileNameRepeated, nameRepeated, isLongName }
  }

  const validateFileTypeSearch = (files) => {
    files.find(v => v.type !== 'application/pdf')
  }


  const updateFileState = async (files, propFiles, isLongName) => {
    const descriptionFile = [];
    let i = 0,
      totalSizeNewFiles = 0,
      finalSize,
      numberOfFiles;  
    if (state.numberOfFiles + files.length + specificFiles.length <= 5) {
      for (let file of files) {
        let uniqueFileName = file.name;
        let counter = 1;
        let hasLongName = file.name.length > 120

        while (validateFileNameSearch(form.files, [{ name: uniqueFileName }]).isFileNameRepeated) {
          const extensionIndex = file.name.lastIndexOf('.');
          const name = file.name.substring(0, extensionIndex);
          const extension = file.name.substring(extensionIndex);
          uniqueFileName = `${name}(${counter})${extension}`;
          counter++;
        }
        if(!hasLongName){
         descriptionFile.push({
           base64: await fileToBase64Clean(file.name, file),
           type: file.type,
           name: uniqueFileName,
           sign: false,
           size: file.size,
           id: `documentSignature-${i}-${Date.now()}`,
           loaded: false,
           isLongName: hasLongName
          });
          i++;
          totalSizeNewFiles += file.size;
        }else{
          setState(prevState => ({...prevState, errorFiles: [...prevState.errorFiles, {name:uniqueFileName, isLongName: hasLongName, size: file.size, type: file.type }] }))

        }
      }

      finalSize = totalSizeNewFiles + getFileSizePropFiles(propFiles);
      numberOfFiles = i + numberOfPropFiles(propFiles) + specificFiles.length;
      setState((prevState) => ({...prevState, numberOfFiles }));
    } else {
      setState((prevState)=>({...prevState, errorLoadingFiles: true }));
      dispatch(changeMessageModal({
        type: 'warning',
        message: `El número de archivos supera el máximo permitido`
      }))
    }

    if (numberOfFiles <= 5) {
      if (finalSize < LIMIT_FILE_SIZE) {
        setState((prevState)=>({...prevState, errorLoadingFiles: false }))
        setState(prevState => ({...prevState, fileList: [...prevState.fileList, ...descriptionFile] }))
        validatePDFHyperlinks(descriptionFile);
      
      } else {
        setState((prevState)=>({...prevState, errorLoadingFiles: true }));
        dispatch(changeMessageModal({
          type: 'warningFiles',
          message: [`El archivo supera el tamaño máximo permitido`, `Ten presente que el peso total de los adjuntos no puede superar los 10 MB`]
        }))
      }
    }
  }

  const deleteChargingFile = (index) => {
    let list = state.errorFiles.map(file => file)
    list.splice(index, 1)
    setState((prevState)=>({...prevState, errorFiles: list }))
    dispatch(saveDraftData({ attachments: requestsPropFiles() }))
  }

  const validatePDFHyperlinks = async (files) => {
    dispatch(cleanErrorsAction())
    const draftDataTemp = draftData
    draftDataTemp.attachments = 
    [...Object.keys(files)?.map(key => ({
      "fileName": files[key].name,
      "fileOriginalName": files[key].name,
      "mimeType": files[key].type,
      "fileExtension": "pdf",
      "size": files[key].size,
      "requiredSignature": files[key].sign,
      "base64": files[key].base64
    })), ...requestsPropFiles()] 

    await dispatch(validateHyperlinks(draftDataTemp)).then(async (res) => {
      if (!!res.message) {
        if (res.message.includes('hipervinculos')) {
          dispatch(changeMessageModalTwo({
            type: 'warningFiles',
            message: ['Por seguridad, no es posible adjuntar archivos que contengan hipervínculos', 'Elimina todos los hipervínculos de los archivos y vuelve a intentarlo.', 'Para mayor información comunícate con tu administrador.']
          }))
          if (res.linkFiles) {
            setState((prevState)=>({...prevState, filesLoaded: true }))
            setState((prevState)=>({...prevState, fileList: [] }))
            setTimeout(async () => {
              const errorFiles = files.filter(file => res.linkFiles.includes(file.name))
              setState(prevState => ({...prevState, errorFiles: [...prevState.errorFiles, ...errorFiles.filter(file => !prevState.errorFiles.some(errorFile => errorFile.name === file.name))] }))
              const filesCharged = files.filter(file => !res.linkFiles.includes(file.name))
              const numberOfFiles = state.numberOfFiles - filesCharged.length
              setState((prevState)=>({...prevState, numberOfFiles: state.numberOfFiles - errorFiles.length }));
            }, 200)
          }
          setState((prevState)=>({...prevState, errorLoadingFiles: true }))
        } else {
          dispatch(changeMessageModalTwo({
            type: 'reportInternalError',
            message: ['¡Lo sentimos!', 'Ocurrió un error interno al adjuntar el archivo, por favor inténtalo de nuevo. Si el problema persiste, debes comunicarte con tu administrador.']
          }))
          if (res.notSavedFiles) {
            setState((prevState)=>({...prevState, filesLoaded: true }))
            setState((prevState)=>({...prevState, fileList: [] }))
            setTimeout(async () => {
              const errorFiles = files.filter(file => res.notSavedFiles.includes(file.name))
              setState(prevState => ({...prevState, errorFiles: [...prevState.errorFiles, ...errorFiles.filter(file => !prevState.errorFiles.some(errorFile => errorFile.name === file.name))] }))
              const filesCharged = files.filter(file => !res.notSavedFiles.includes(file.name))
              const numberOfFiles = state.numberOfFiles - filesCharged.length
              setState(prevState =>({...prevState, numberOfFiles: state.numberOfFiles - errorFiles.length }));
            }, 200)
          }
          setState((prevState)=>({...prevState, errorLoadingFiles: true }))
        }
      } else {
        setState((prevState)=>({...prevState, filesLoaded: true }))
        setState((prevState)=>({...prevState, fileList: [] }))
      }
    })


  }
  const requestsPropFiles = () => {
    return Object.keys(form.files)?.map(key => ({
      "fileName": form.files[key].name,
      "fileOriginalName": form.files[key].name,
      "mimeType": form.files[key].type,
      "fileExtension": "pdf",
      "size": form.files[key].size,
      "requiredSignature": form.files[key].sign,
      "base64": form.files[key].base64,
      "fileUrl": !form.files[key].fileUrl ? null : form.files[key].fileUrl
    }))
  }

  const validateChecksNotificationType = () => {
    const { notificationSignature, readReceipt, pushNotification, documentSignature } = form
    if (!!servicesList.notificationSignature || !!servicesList.notificationReading || servicesList.pushNotification || (servicesList.documentSignature && !_.isEmpty(files))) {
      if (!notificationSignature && !readReceipt && !pushNotification && !documentSignature && !checkboxHeaderDisabled) {
        dispatch(warningFormModalWrite({ type: 'warningSendEmail', errorFiles: state.errorFiles.length > 0 }))
        //to do
      } else if (state.errorFiles.length > 0) {
        dispatch(warningFormModalWrite({ type: 'warningErrorFiles' }))
      } else {
        dispatch(changeIsSendEmail(true))
      }
    } else {
      dispatch(changeIsSendEmail(true))
    }
  }

  const handleValidateSendEmail = () => {

    props.validateSubject()
    if (form.recipient.length === 0 || form.subject === "" || form.textEditor === "") {
      dispatch(warningModal())
    } else if (props.stateFormValidator) {
      validateChecksNotificationType()
    }
  }

  const handleSendEmail = async () => {
    if (isMassiveRecipientValue) {
      await handleSendEmailMassive()
    } else {
      if (listIdsSendMassive.backendRequest.length > 1) {
        if (hiredData.availableQuantity < listIdsSendMassive.backendRequest.length) {
          dispatch(changeMessageModal({ type: 'insuficientBag' }))
        } else {
          const body = makeSaveMultipleNotificationReq()
          setState((prevState)=>({...prevState, sending: true }))
          dispatch(saveNotificationMultiple(body)).then(() => {
            if (formError.success !== false) {
              history.push("/sent")
              setState((prevState)=>({...prevState, sending: false }))
            }
          })

        }
      } else {
        const body = makeSaveNotificationReq()
        setState((prevState)=>({...prevState, sending: true }))
        dispatch(saveNotificationOne(body)).then(() => {
          history.push("/sent")
          setState((prevState)=>({...prevState, sending: false }))
        })
      }
    }
    dispatch(actionClearFilter())
  }

  const makeSaveNotificationReq = () => {
    const attachedFiles = requestsPropFiles()
    return {
      requiredSignature: form.notificationSignature,
      requiredConfirmationRead: form.readReceipt,
      isPushNotification: form.pushNotification,
      subject: form.subject,
      description: form.textEditor,
      documentNumberRecipient: listIdsSendMassive.ids,
      attachments: attachedFiles,
      draftSerialID: draftSerialID !== "" && draftSerialID
    }
  }
  const makeSaveMultipleNotificationReq = () => {

    const attachedFiles = requestsPropFiles()
    return {
      requiredSignature: form.notificationSignature,
      requiredConfirmationRead: form.readReceipt,
      isPushNotification: form.pushNotification,
      subject: form.subject,
      description: form.textEditor,
      attachments: attachedFiles,
      campaignName: ' ',
      arrayDocumentNumbers: listIdsSendMassive.ids,
      draftSerialID: draftSerialID !== "" && draftSerialID
    }
  }

  const decrementNumberOfFiles = () => {
    // this.setState(prevState => ({
    //   numberOfFiles: prevState.numberOfFiles - 1,
    // }));
  };

  const makeSaveNotificationReqMassive = () => {
    const attachedFiles = requestsPropFiles()
    return {
      requiredSignature: form.notificationSignature,
      requiredConfirmationRead: form.readReceipt,
      isPushNotification: form.pushNotification,
      subject: form.subject,
      description: form.textEditor,
      fileSerialID: fileSerialID,
      attachments: attachedFiles,
      draftSerialID: draftSerialID !== "" && draftSerialID
    }
  }

  const calculateNotificationsSent = (notificationsSent, totalNotifications) => {
    return (notificationsSent / totalNotifications) * 100
  }

  const handleSendEmailMassive = async () => {
    const body = makeSaveNotificationReqMassive()
    await dispatch(openSendNotificationMassive())
    if (loadingSendNotificationMassive.abortSend) {
      dispatch(changeMessageModal({ type: 'error' }))
    } else {
      dispatch(saveNotificationMassiveV2(body, history))
      // .then(() => {
      //   if (formError.success === false) {
      //     if (formError.message === "No tiene saldo suficiente para el envio de notificaciones") {
      //       dispatch(changeMessageModal({ type: 'insuficientBag' }))
      //     }
      //   }
      // })
    }
  }



    return (
      <>
        <IncludeTag quill={state.quill} />
        <div id="editor-container">
        </div>
        <div id="container-files">
          <SpecificFileContainer filesCharging={state.fileList} errorFiles={state.errorFiles} filesLoaded={state.filesLoaded} errorLoadingFiles={state.errorLoadingFiles} deleteChargingFile={deleteChargingFile} decrementNumberOfFiles={decrementNumberOfFiles} />
        </div>
        <div class="contentToolbarAndButton">
          <ToolBarContent
            disableAtt={state.numberOfFiles >= 5 || Object.keys(files).length + Object.keys(specificFiles).length >= 5 ? true : disableAtt}
            onChangeHandlerAttachment={onChangeHandlerAttachment}
          />
          <Divider />
          <div className='divCustomPrimaryButton'>
            <PrimaryButton
              loading={loadingSendNotification}
              className='customPrimaryButton'
              txtBtn={state.sending ? 'Enviando' : 'Enviar'}
              onPress={handleValidateSendEmail}
              disable={state.sending || (state.fileList.length > 0)}
              sending={state.sending}
            />

          </div>
        </div>
      </>
    )
}

export default EditorQuill
