import React from 'react'
import MenuConfig from './MenuConfig'

export const Configuration = () => {
  return (
    <div align={"right"}>
      <MenuConfig />
    </div>
  )
}
export default Configuration
