import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import SimpleReactValidator from 'simple-react-validator'
import CheckboxForm from '../CheckboxForm/CheckboxForm'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import { ReactComponent as Pdf } from '../../assets/icons/pdf.svg'
import { changeAttachedDocSign, changeWriteNotificationForm, changeHeaderCheckbox } from '../../actions/writerActions'
import { saveDraftData, saveDraftV2 } from '../../actions/draftsActions'
import { CloseSvgIcon } from "../IconSVG/CloseSvgIcon";
import _ from "lodash";
import PropTypes from "prop-types";
import '../CheckboxForm/signature-checkbox.css'
import { Popup } from 'semantic-ui-react'
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { Tooltip } from '@mui/material'


const CloseIcon = styled(CloseSvgIcon)`
  width: 100%;
  height: 100%;
  color: #133F4F;
  padding-top: 3px;
  padding-left: 3px;
  @media screen and (max-width:768px){
    padding:0;
    margin-top:3px;
}
`
const CloseButton = styled.div`
  float: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: 20px;
  height: 20px;

  @media screen and (max-width:1500px){
    visibility:visible;
}
@media screen and (max-width:768px){
    width: 20px;
  height: 20px;
}
`

const Divider = styled.div`
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin-bottom: 5px;
  &:hover{
    box-shadow: rgba(0,0,0,0.35) 0px 0px 6px;
  }
  &:hover ${CloseButton} {
    visibility: visible;
  }

  width: 100%;
  ${props => props.fileheight &&
    css`
            height: ${props.fileheight};
          `}
  height: 40px;

  ${props => props.nameTooLong &&
    css`
    @media screen and (max-width:768px){
      height: 68px;
      padding: 8px;
  }
          `}
  
`
const Divider2 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  
`
const DividerSvg = styled.div`
  float: left;
  width: 15px;
  height: 15px;
`

const Text = styled.div`
  float: left;
  width: auto;
  color: #133F4F;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 1% 0;
  margin-left: 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  ${props => props.nameTooLong &&
    css`
    @media screen and (max-width:768px){  
    width: 100%;
    }
`}

`
const Text2 = styled.div`
  float: left;
  height: 100%;
  font-size: 14px;
  padding-left: 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
`
const TextLongName = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #C3132E;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 10px;
  @media screen and (max-width:768px){
    margin-left: 0;
    padding-left: 2%;
}
`

const Divider3 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media screen and (max-width:768px){
    flex-direction: column;
}
  
`
const NameTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(60, 60, 59, 1);
    font-size: 12px;
    line-height: 14px;
    font-weight:400;
    padding: 10px;
    white-space: nowrap;
    max-width:100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  }

  & .MuiTooltip-arrow {
    color: rgba(255, 255, 255, 1);
  }
`;


const AttachedFileCard = (props) => {

  const dispatch = useDispatch()
  const [validator, setShow, setFields] = useValidator(reactValidatorOptions)
  const checkboxHeaderDisabled = useSelector(state => state.writerReducer.checkboxHeaderDisabled)
  const servicesList = useSelector(state => state.writerReducer.hiredData.servicesList)
  const files = useSelector(state => state.writerReducer.notificationForm.files)
  const draftData = useSelector(state => state.draftReducer.toSaveDraft)
  const draftSerialID = useSelector(state => state.writerReducer.draftSerialID)

  const [state, setState] = useState(
    {
      checkboxId: props.data.id,
      [props.data.id]: false,
    }
  )

  useEffect(() => {
    setState(() => ({ checkboxId: props.data.id, [props.data.id]: props.data.sign }))
  }, [props.data.id])

  useEffect(() => {
    const realFiles = Object.keys(props.files).map((row) => {
      return props.files[row]
    })
    let disable = false
    for (const file of realFiles) {
      if (file.sign)
        disable = true
    }
    if (checkboxHeaderDisabled !== disable)
      dispatch(changeHeaderCheckbox(disable))
  }, [props.files])

  const fileHeight = (listSize) => (`${(100 / (listSize))}%`)

  const toggle = async () => {
    setState((prevState) => ({ ...prevState, [prevState.checkboxId]: !prevState[prevState.checkboxId] }))
    dispatch(changeAttachedDocSign(state.checkboxId, !state[state.checkboxId]))
  }

  const removeCustomFile = async (indexInfo) => {
    const realFiles = Object.keys(props.files).map((row) => {
      return props.files[row]
    })
    realFiles.splice(indexInfo, 1)
    let disable = false
    for (const file of realFiles) {
      if (file.sign) {
        disable = true
      }
    }
    if (checkboxHeaderDisabled !== disable)
      await dispatch(changeHeaderCheckbox(disable))

    const descriptionFile = realFiles.map((file) => {
      return {
        name: file.name,
        fileOriginalName: file.fileOriginalName,
        type: file.type,
        fileExtension: file.fileExtension,
        size: file.size,
        sign: file.sign,
        fileUrl: file.fileUrl,
        id: file.id
      }
    }
    )
    const filesObject = _.keyBy(descriptionFile, 'id')
    await dispatch(changeWriteNotificationForm('files', filesObject))
    //await dispatch(saveDraftData({ attachments: requestsPropFiles(filesObject) }))
    dispatch(saveDraftV2({ ...draftData, attachments: requestsPropFiles(filesObject) }))
    props.decrementNumberOfFiles()
  }

  const requestsPropFiles = (files) => {
    return Object.keys(files)?.map(key => ({
      "fileName": files[key].name,
      "fileOriginalName": files[key].name,
      "mimeType": files[key].type,
      "fileExtension": "pdf",
      "size": files[key].size,
      "requiredSignature": files[key].sign,
      "base64": files[key].base64,
      "fileUrl": !files[key].fileUrl ? null : files[key].fileUrl
    }))
  }

  const truncateFileName = (fileName) => {
    const maxLength = 40;
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    let truncatedLength = fileNameWithoutExtension.length;

    if (truncatedLength > maxLength) {
      truncatedLength -= 3;
    }

    let truncatedName = fileNameWithoutExtension.substring(0, maxLength);
    if (truncatedLength > maxLength) {
      const numberMatch = fileNameWithoutExtension.match(/\(\d+\)$/);
      if (numberMatch) {
        const numberLength = numberMatch[0].length;
        if (maxLength >= numberLength) {
          truncatedName = truncatedName.substring(0, maxLength) + '...' + numberMatch[0];
        } else {
          truncatedName += '...';
        }
      } else {
        truncatedName += '...';
      }
    }

    return truncatedName + '.pdf';
  };


  return (
    <Divider
      fileheight={fileHeight(Object.keys(props.files).length)}
      nameTooLong={props.data.isLongName}
    >
      <Divider2>

        <DividerSvg>
          <Pdf width='16px' height='16px' />
        </DividerSvg>

        {!!props.data.isLongName ?
          <Divider3>
            
            <Text nameTooLong={props.data.isLongName} style={{ width: !!props.data.isLongName && props.windowDimensions.width > 768 ? '10vw' : '' }}>{truncateFileName(props.data.name)}</Text>
            <TextLongName>Error al cargar, nombre del documento máx. 120 caracteres.</TextLongName>
          </Divider3>
          :
          <>
          <NameTooltip title={props.data.name} placement='top-start' >
              <Text nameTooLong={props.data.isLongName} style={{ width: !!props.data.isLongName && props.windowDimensions.width > 768 ? '10vw' : '' }}>
                {truncateFileName(props.data.name)}
              </Text>
            </NameTooltip>
            {/* <Text nameTooLong={props.data.isLongName} style={{ width: !!props.data.isLongName && props.windowDimensions.width > 768 ? '10vw' : '' }}>{truncateFileName(props.data.name)}</Text> */}

            <Text2>({(props.data.size / (1024 * 1024)).toFixed(2)}MB)</Text2>
            <CheckboxForm
              classNameDiv='signature-checkbox-div'
              classNameCheck='signature-checkbox-check'
              label='Firma en documento'
              onChange={toggle}
              value={state[state.checkboxId]}
              validator={validator}
              validateOptions='required'
              id={state.checkboxId}
              maxWidth={'11vw'}
              width={'10%'}
              disabled={(!servicesList.documentSignature) ? 'disabled' : ''}
            />
          </>
        }
        <CloseButton
          onClick={() => removeCustomFile(props.index)}
        >
          <CloseIcon />
        </CloseButton>
      </Divider2>
    </Divider>
  )
}
/*
AttachedFileCard.propTypes = {
  checkboxHeaderDisabled: PropTypes.bool,
  changeHeaderCheckbox: PropTypes.func,
  updateForm: PropTypes.func,
  changeWriteNotificationForm: PropTypes.func,
  servicesList: PropTypes.object,
}

const mapStateToProps = state => ({
  checkboxHeaderDisabled: state.writerReducer.checkboxHeaderDisabled,
  servicesList: state.writerReducer.hiredData.servicesList,
  files: state.writerReducer.notificationForm.files,
  draftData: state.draftReducer.toSaveDraft,
  draftSerialID: state.writerReducer.draftSerialID,
})

const mapDispatchToProps = {
  updateForm: changeAttachedDocSign,
  changeWriteNotificationForm: changeWriteNotificationForm,
  changeHeaderCheckbox: changeHeaderCheckbox,
  saveDraftData,
  saveDraftV2
}*/


export default AttachedFileCard
