import React, { useEffect, useState } from 'react'
import ContainerSearchClientsAndProviders from '../../../components/Search/SearchClientsAndProviders/ContainerSearchClientsAndProviders';
import AllClients from './AllClients'
import styled from 'styled-components';
import Paginacion from '../../../components/Search/Paginacion';
import WarningModal from '../../../components/ModalGeneralForms/WarningModal'
import MessageModal from '../../../components/Modal/MessageModal';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom-v5-compat"
import { getGeographicList } from '../../../actions/defaultListActions';

const DivFooter = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2% 0;
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`
const SuperAdminClients = () => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const clients = useSelector(state => state.clientReducer.clients)
  const [paginationLoader, setPaginationLoader] = useState(true)

  return (
    <>
      <ContainerSearchClientsAndProviders 
        textPlaceHolder={'Buscar' } 
        buttonType= {'client'} 
        text={'Nuevo cliente'} 
        showAZ={true}
        showButton={true} 
        showFilters={true}
        showExportButton={true}
        disabledExportButton={!clients.length > 0} 
        reportType={"LIST_CLIENTS"} 
        providerType={"CLIENT"}
        showAdvancedSearch={false}
        loader={paginationLoader}
      />
      <WarningModal/>
      
      <AllClients
        navigate={navigate}
        setPaginationLoader={setPaginationLoader}
      />
    </>
  )
}


export default SuperAdminClients