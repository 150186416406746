import axios from 'axios'
import { API_ENDPOINT } from '../config/config'
import { GET_LIST_NOTIFICATIONS, STARRED_NOTIFICATION, STARRED_NOTIFICATION_V2 } from './actionsUtilities/actionsRoutes.js'
import { GET_NOTIFICATIONS_STARRED, POST_STARRED, CLEAR_NOTIFICATIONS_STARRED } from './actionsUtilities/types.js'
import { reasonDispatch } from './DispatchGeneric'
import { decrypt, encrypt } from '../helpers/cypher.js'
import { getCookieHeaders, getHeaders } from '../helpers/globalHelpers.js'


export const postStarred = (body) => async (dispatch) => {
    const headers = { 'Authorization': await getCookieHeaders('__Host-jwt') }
    await axios.post(
        `${API_ENDPOINT + STARRED_NOTIFICATION}`,
        body,
        { headers }
    ).then(res => {
        dispatch({
            type: POST_STARRED,
            payload: res.data.result
        })
    }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const postStarredV2 = (body) => async (dispatch) => {
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + STARRED_NOTIFICATION_V2}`,
       {data: encryptedBody},
        { headers: await getHeaders() }
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        dispatch({
            type: POST_STARRED,
            payload: decryptedData
        })
    }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

/** 
 * No usada, anteriormente se usaba en el módulo destacados. 
 * Se deja preparada para encripcion en caso de volverse a usar
 **/
export const getNotificationsStarred = (body) => async (dispatch) => {
    const encryptedBody = await encrypt(body);
    await axios.post(
        `${API_ENDPOINT + GET_LIST_NOTIFICATIONS}`,
        {data: encryptedBody},
        { headers: await getHeaders()}
    ).then(async res => {
        const decryptedData = await decrypt(res.data.result);
        
        dispatch({
            type: GET_NOTIFICATIONS_STARRED,
            payload: decryptedData
        })
    }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const clearStarred = () => async (dispatch) => {
    dispatch({
        type: CLEAR_NOTIFICATIONS_STARRED,
    })
}

