import React from 'react'
import styled from "styled-components"
import {  Grid } from 'semantic-ui-react'
import InputFormTextAndNumber from '../InputAnimatedGeneralForms/InputFormTextAndNumber'


const Title2 = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-Top: 22px;
  margin-bottom: 2rem;
`

const Title3 = styled.p`
  color: #133F4F;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
`

const ColumnGrid = styled(Grid.Column)`
  width:50% !important;
  @media only screen and (max-width: 1200px) {
    width: 100% !important;
  }
`


export const SingleContactForm = ({ onChange, contactInformation, formValidator, errors,numberOfContact }) => {
    return (
     
        <> 
          <div style={{width:'100%'}}>
            <Title2>Datos contacto {numberOfContact}</Title2>
          </div>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={`name${numberOfContact}`}
              label={'Nombres'}
              type={'text'}
              onChange={(e) => onChange(e, contactInformation.id)}
              value={contactInformation.name}
              validator={formValidator}
              validateOptions={['required','nameOfProviderClient']}
              error={errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={`surNames${numberOfContact}`}
              label={'Apellidos'}
              type={'text'}
              onChange={(e) => onChange(e, contactInformation.id)}
              value={contactInformation.surNames}
              validator={formValidator}
              validateOptions={['required','nameOfProviderClient']}
              error={errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={`occupation${numberOfContact}`}
              label={'Cargo'}
              type={'text'}
              onChange={(e) => onChange(e, contactInformation.id)}
              value={contactInformation.occupation}
              validator={formValidator}
              validateOptions={["required","occupation",{ min: 3 },{ max: 50 }]}
              error={errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={`email${numberOfContact}`}
              label={'Email'}
              type={'text'}
              onChange={(e) => onChange(e, contactInformation.id)}
              value={contactInformation.email}
              validator={formValidator}
              validateOptions={['required', 'email']}
              error={errors}
            />
          </ColumnGrid>
          <ColumnGrid>
            <InputFormTextAndNumber
              id={`phoneContact${numberOfContact}`}
              label={'Teléfono'}
              type={'text'}
              onChange={(e) => onChange(e, contactInformation.id)}
              value={contactInformation.phoneContact}
              validator={formValidator}
              validateOptions={['required', 'phones']}
              error={errors}
            />
          </ColumnGrid>
        </>
       
    );
}
