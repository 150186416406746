import React, { useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import Write from '../views/write/Write'
import { getCookieHeaders } from '../helpers/globalHelpers'
import { CompatRoute } from 'react-router-dom-v5-compat'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'

export const PrivateComponent = ({
  view: Component,
  pathObject,
  ...rest
}) => {

  const modalReducer = rest.modalReducer

  const getCookie = () => {
    return getCookieHeaders('__Host-jwt')
  }
  const selectComponent = props => {
    const tokenR = getCookie().then((result) => {
      return result
    })

    if (tokenR) {
      if (modalReducer.writeNotification) {
        return <Write {...props} pathObject={pathObject} />
      } else {
        return <Component {...props} pathObject={pathObject} />
      }
    } else {
      return <Redirect to='/dashboard' />
    }
  }

  return (
    <>
      
        <CompatRoute {...rest} children={selectComponent} />
    

    </>
  )
}

const mapStateToProps = (state) => ({
  modalReducer: state.modalReducer
})

export default connect(mapStateToProps, {})(PrivateComponent)
