import React from 'react'
import styled from 'styled-components'
import { Divider } from 'semantic-ui-react'
import { Element } from 'react-scroll'
import { useSelector } from 'react-redux'
import moment from 'moment'

const Divider2 = styled.div`
  padding: 0 14px;

  @media only screen and (max-width: 1200px)  {
   padding: 0;
`
const EventType = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #575756;
`
const EventDate = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  color: #575756;
`

const Event = () => {

  const { detailNotification } = useSelector((state) => state.sentReducer)

  const formatDate = (date) => {
    return moment(date).format('dddd, Do MMMM YYYY - h:mm:ss a');
  }

  return (
    <>
      <Element style={{
        position: 'relative',
        height: '200px',
        marginBottom: '100px'
      }}>
        <Divider2>
        {
          detailNotification
          && detailNotification.events?.map((ele, index) => (
            <div key={index}>
              <EventType>
                {ele.eventDetailDescription}
              </EventType>
              <EventDate>
                {formatDate(`${ele.eventDate}`)}
              </EventDate>
              <Divider />
            </div>
          ))
        }
        </Divider2>
      </Element>
    </>
  )
}

export default Event
