import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from "moment";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {configMoment} from "./config/config";
import { axiosInterceptor } from "./interceptors/axios.interceptor";

axiosInterceptor()

const divRoot = document.querySelector('#root');
const root = createRoot(divRoot);

root.render(<App />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

moment.updateLocale('es', configMoment)