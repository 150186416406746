import React from 'react'
import styled from 'styled-components'
import { ReactComponent as clientIcon } from '../../../src/assets/icons/clients/Client-Icon.svg'
import { useNavigate } from 'react-router-dom-v5-compat';

const Button = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  width: 208px;
  height: 48px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.3;
    cursor: no-drop;
  }
  @media only screen and (max-width: 1700px) {
    width: 190px;
    height: 48px;
  }
  @media only screen and (max-width: 1700px) {
    width: 180px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    height: 40px;
  }
  @media only screen and (max-width: 740px) {
    font-size: 14px;
  }
`
const IconNew = styled(clientIcon)`{
  margin: 0px 10px 0px 0px !important;
  @media only screen and (max-width: 520px) {
    margin: 0px 0px 0px 0px !important;
  }
}`

const pathname = {
  user: '/createUsers',
  agreement: '/createAgreements',
  provider: '/createProviders',
  client: '/createClients',
  reports: '/createReports',
  employee: '/createEmployee',
  employeeProvider: '/createEmployeeProvider'
}

export const CreateButton = ({ text, buttonType, disabledButton }) => {
const navigate = useNavigate()
  const handleClick = (evt) => {
    evt.preventDefault()
    navigate(`${pathname[buttonType]}`)
  }

  return (
    <Button onClick={handleClick} disabled={disabledButton}> <IconNew /> {text} </Button>
  )
}

