import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableUsers from './RowTableUsers'
import {actionClearFilter, activePagination } from '../../../../actions/filterDetailActions'
import { getEmployees } from '../../../../actions/clientActions'

const Users = ({ companySerialID }) => {

  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(true)
  const columnData = [
      { name: "Nombre ", width: 3, arrow: false },
      { name: "Documento", width: 3, arrow: false },
      { name: "Email", width: 4, arrow: false },
      { name: "Rol", width: 2, arrow: false },
      { name: "Activo", width: 1, arrow: false },
      { name: "", width: 2, arrow: false },
  ]
  const clientDetail = useSelector(state => state.clientReducer.detailClient)
  const filterReducer = useSelector(state => state.filterDetailReducer)
  const employees = useSelector(state => state.clientReducer.employees)
  const dispatch = useDispatch()

  const makeClientsRequest = useCallback((filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      companySerialID: companySerialID,
      textToSearch: filterReducer.searchBar[0],
    }
    dispatch(getEmployees(body)).then(() => {
      dispatch(activePagination(false))
      setInit(false)
      setLoader(false)
    })
  }, [dispatch, clientDetail])

  useEffect(() => {
    if(filterReducer.tabFilter !== 'users'){
      setInit(false)
      dispatch(actionClearFilter())
    }else{
     makeClientsRequest(filterReducer)
    }
  }, [filterReducer.tabFilter])

  useEffect(() => {
    if (!init) {
      makeClientsRequest(filterReducer)
      setLoader(true)
      setInit(true)
    }
  },[filterReducer.pag, filterReducer.count, filterReducer.sort, filterReducer.searchBar])

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "firstName": 1 },
      'Z-A': { "firstName": -1 }
    }
    return options[order]
  }

  return (
      <GeneralTable
        list={employees}
        typeList="users"
        headerTable={columnData}
        rowComponent={RowTableUsers}
        loader={loader}
      />
    )
}

Users.propTypes = {
  getEmployees: PropTypes.func.isRequired,
  actionClearFilter: PropTypes.func,
  activePagination: PropTypes.func,
  employees: PropTypes.object.isRequired,
  filterReducer: PropTypes.object.isRequired,
}

export default Users