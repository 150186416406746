import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledInput = styled.input`
  margin-right: 4px;
  width: 18px !important;
  height: 18px !important;

  &:checked::after {
    content: '';
    display: inline-block;
    width: 10px !important;
    height: 10px !important; 
    border-radius: 50%;
    margin-left: 5px;
    position: relative;
    background-color: black;
    left: -2px !important;
  }
`;

const Divider = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1024px) {
    margin-bottom: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Important for vertical centering */
  line-height: 18px; /* Match the height of StyledInput */
`;

export const ModalFilterSignatureSelection = ({selected,setSelected}) => {

    
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(setrequiredSignatureAttatchments(selected))
    // }, [selected]);
    

    const handleOnChange = (e) => {
        setSelected(translatorSignature(e.target.value));
    }

    const translatorSignature = (order) => {
        const options = {
            'Todos': '',
            'Con solicitud de firma': 'REQUIRED_SIGNATURE',
            'Sin solicitud de firma': 'NO_REQUIRED_SIGNATURE',
        }
        return options[order]
    }

    const translatorSignatureReverse = (order) => {
        const options = {
            '': 'Todos',
            'REQUIRED_SIGNATURE':'Con solicitud de firma',
            'NO_REQUIRED_SIGNATURE':'Sin solicitud de firma',
        }
        return options[order]
    }

  return (
    <Divider>
      {['Todos', 'Con solicitud de firma', 'Sin solicitud de firma'].map((option, index) => (
        <Container key={index}>
          <StyledInput type="radio" name={index} value={option} id={index} onChange={handleOnChange} checked={translatorSignatureReverse(selected) === option}/>
          <p style={{ whiteSpace: 'nowrap' }}>{option}</p>
        </Container>
      ))}
    </Divider>
  );
};
