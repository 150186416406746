import React, { useState } from "react";
import { useSelector } from 'react-redux'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {
  changeWriteNotificationForm,
  closeLoadMassiveRecipients,
  addMassiveRecipientsList,
  updateHistory, updateHistoryMassive, updateRecipientDisplay, isMassiveRecipient, filterMassiveRecipients
} from '../../../actions/writerActions'
import { saveDraftData } from '../../../actions/draftsActions' 
import './main.css'
import PropTypes from "prop-types";
import SendMassiveButton from "../Button/SendMassiveButton"
import DropdownAllowAddition from "../../Dropdown/DropdownAllowAddition"

const Content = styled.div`
  width: 100%;
  display:block;
`

const InputsFor = (props) => {

  const form = useSelector(state => state.writerReducer.notificationForm)
  const isMassiveRecipient = useSelector(state => state.writerReducer.isMassiveRecipient)
  
  const [state, setState] = useState({
    disableRecipientField: true,
    options: [],
    to: '',
    recipientError: {status: false, content: ''}
  })
  

  const changeState = (data) => {
    setState((prevState) => ({
      ...prevState,
      to: data.value === undefined ? "" : data.value,
      recipientError: {
        ...prevState.recipientError,
        status: data.recipientError.status,
        content: data.recipientError.content
      }
    }))
  }

    return (
      <>
        <Content>
          <div className='forContent'>
              <DropdownAllowAddition
                label={"Para"}
                value={isMassiveRecipient? form.recipient : state.to}
                items={form.recipient}
                recipientError={state.recipientError}
                required={true}
                changeState={changeState}
                massiveRecipient={isMassiveRecipient}  
              />
          </div>
          <SendMassiveButton/>
        </Content>
      </>
    )
  }


export default InputsFor
