import React from 'react';
import styled from 'styled-components';
import { ReactComponent as GseIcon } from '../../assets/icons/Logo_registraduria.svg'
import './menuBar.css'

const Divider = styled.div`
  background-color: unset;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  position: relative;
  min-height: auto;
`

export const MenuConfig = () => {
  return (
    <Divider>
      <GseIcon className='iconBar' />
    </Divider>
  )
}
export default MenuConfig
