import React, { useState } from 'react'
import { SearchBarGenericSentMassiveDetail } from './SearchBarGeneric'

const  ContainerSearchMassive = (props) => {

  const [searchBarState, setSearchBarState] = useState("")
  const [popUpOrderState, setPopUpOrderState] = useState("")
  const [popUpFilterState, setpopUpFilterState] = useState("")

    return (
      <SearchBarGenericSentMassiveDetail searchBarState={searchBarState} popUpOrderState={popUpOrderState}
      popUpFilterState={popUpFilterState} showAdvancedSearch={props.showAdvancedSearch} textPlaceHolder={'Número de identificación del destinatario'} showAZ={false} filterSmall={props.filterSmall}/>
    )
}

export default ContainerSearchMassive
