import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, ListItem, Modal } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { hideMessageModal } from '../../actions/modalActions'
import PrimaryButton from '../PrimaryButton'
import { ReactComponent as ChangeRole } from '../../assets/icons/role/changeRole.svg'
import './messageModal.css'
import { clearEditorChange} from "../../actions/writerActions";
import { CHANGE_ROLE_STATE_MODAL } from '../../actions/actionsUtilities/types'

const ModalElement = styled(Modal)`
${props => !!props.modalState.buttonMessage ?
    css`width: 496px !important;`
    :
    css`width: 328px !important;`
  }
  padding: 40px !important;  
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;

`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 35px;
  text-align: center;
  margin-bottom: 15px !important;
`
const ModalActions = styled.div`
  margin-top: 32px;
`

const MessageModalChangeRole = (props) => {
  const dispatch = useDispatch()

  const modalState = useSelector((state)=> state.modalReducer.messageModalChangeRole)
  const hiredData = useSelector((state)=>state.writerReducer.hiredData)
  const actionsRunning =  useSelector((state)=>state.authReducer.actionsRunning)

  useEffect(() => {
    if (modalState.status === false) {
      dispatch(clearEditorChange(false))
    }
    if (modalState.status === true && (modalState.type === 'clientEdit' || modalState.type === 'providerEdit' || modalState.type === 'agreementEdit')) {
      setTimeout(() => {
        dispatch(hideMessageModal())
      }, 2000);
    }
  }, [modalState.status, modalState.type, dispatch])

  useEffect(() => {
    actionsRunning?.length === 0 && modalState.status? dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: modalState.type } }) : null
  },[actionsRunning, dispatch])

  const modalData = (type) => {
    const data = {
      changeRoleError: {
        icon: <ChangeRole />,
        InitialMessage: "¡Por ahora no puedes cambiar de rol!",
        message: `Para poder hacerlo, deberás esperar a que termine el proceso de 
        ${actionsRunning?.length === 0 ? '' : options[actionsRunning]}`,
        buttonMessage: 'Entendido',
      },
      changeRoleErrorPlusTwoActions: {
        icon: <ChangeRole />,
        InitialMessage: "¡Por ahora no puedes cambiar de rol!",
        message: 'Para poder hacerlo, deberás esperar a que terminen los siguientes procesos en curso:',
        list: actionsRunning?.length === 0 ? [] :actionsRunning?.map(item => options[item]),
        buttonMessage: 'Entendido',
      },

    }
    return data[type]
  }
  const options = {
    SEND_NOTIFICATION: 'Envío de notificación',
    CREATE_REPORT: 'Crear reporte',
    CREATE_USER: 'Creación de usuario',
    EDIT_USER: 'Edición de usuario',
    CREATE_PROVIDER: 'Creación de operador',
    EDIT_PROVIDER: 'Edición de operador',
    EDIT_CLIENTS: 'Edición de cliente',
    CREATE_CLIENTS: 'Creación de cliente',
    CREATE_AGREEMENT: 'Creación de vinculación',
    EDIT_AGREEMENT: 'Edición de vinculación',
    CREATE_REPORT: 'Creación de reporte',
  }

  const onClose = () => {
    dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: modalState.type } })
  }
  
  const data = modalData(modalState.type)
  return (
    <ModalElement
      open={modalState.status}
      // size={modalState.type === 'errorHyperlinks' || modalState.type === 'warningFiles' || modalState.type === 'errorBag'?'tiny':'mini'}
      closeOnDimmerClick={modalState.type === 'finish' ? false : true}
      // className= {modalState.type === 'noAgreementsActive' && 'modalNoAgreements'}
      modaltype={modalState.type}
      modalState={data}
    >
      <ModalContent>
      {!!data.icon && data.icon}
        {data.multipleMessages && !!modalState.message && !!modalState.message.length &&
          modalState.message.map((message) => (
            <>
              <ModalMessage>
                {message}
              </ModalMessage>
              <br />
            </>
          ))
        }
        {!data.multipleMessages &&
          <ModalMessage>
            <Texto>{data.InitialMessage}</Texto>
            {!modalState.message
              ? data.message
              : modalState.message}
          </ModalMessage>
        }
        {!!data.list &&
          <List bulleted>
            {data.list.map(name => <ListItem key={name}>{name}</ListItem>)}
          </List>
        }
        {
          modalState.type !== 'deleteDraft' && modalState.type !== 'deleteDraftList' && modalState.type !== 'finish' && modalState.type !== 'entityCreate' && modalState.type !== 'inactiveEntity' && modalState.type !== 'operatorCreate' && modalState.type !== 'entityEdit' && modalState.type !== 'clientEdit' && modalState.type !== 'employeeCreate' && modalState.type !== 'employeeEdit' && modalState.type !== 'providerEdit' && modalState.type !== 'agreementCreate' && modalState.type !== 'agreementEdit' && modalState.type !== 'userActive' && modalState.type !== 'userInactive' && modalState.type !== 'providerInactive' && modalState.type !== 'providerActive' && modalState.type !== 'clientInactive' && modalState.type !== 'clientActive' && modalState.type !== 'finishCommentAuditor' && modalState.type !== 'agreementCanceleSucces' &&
          <ModalActions>
            <PrimaryButton
              txtBtn={data.buttonMessage}
              onPress={onClose}
            />
          </ModalActions>
        }
      </ModalContent>
    </ModalElement>

  )
}

export default MessageModalChangeRole
