import React from "react"
import { useDispatch } from 'react-redux'
import './input-from-improperClients.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"

const text = styled.p`

`
const text2 = styled.span`
    
`
const InputFormArea = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''

    const validator2 = (value) => {
        if (value !== "") {
            
        }
    }
    return (
        <Form.Field>
            <div>
                <label className={errorValidator ? 'labelInputFormEntitiesError' : 'labelInputFormEntities'}>
                    <textarea
                        id={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={onChange}
                        onBlur={() => validator2(value)}
                        onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                        required
                        min={1}
                        max={1000}
                        className={'textArea-comments'}
                        disabled={disabled}
                    />
                    {
                        errorValidator && value === ''
                        &&
                        <>
                            <text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</text2>
                            <text className={(errorValidator) && 'input-error3'}>{errorValidator}</text>
                        </>
                    }
                    {
                        errorValidator === undefined && value !== ''
                        &&
                        <>
                            <span className={'spanLabelInputTop'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === ''
                        &&
                        <>
                            <span className={'spanLabelInput'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator && value !== ''
                        &&
                        <>
                            <text2 className={'spanLabelInputTopError'}>{label}</text2>
                            <text className={'input-textarea-error'}>{errorValidator}</text>
                        </>
                    }
                </label>
            </div>

        </Form.Field>
    )
}

export default InputFormArea