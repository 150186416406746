
export const configMenu = (userPermission) => {
  const permissions = [
    { name: "COMPANY_CREATE", key: 1 },
    { name: "USER_PERMISSION_READ", key: 2 },
    { name: "CREATE_EVENT_ELASTICSEARCH", key: 3 },
    { name: "WEB_LIST_NOTIFICATION", key: 4 },
    { name: "CRUD_COMPANY_PROVIDER", key: 5 },
    { name: "CRUD_COMPANY_CLIENT", key: 6 },
    { name: "LIST_USERS", key: 7 },
    { name: "LIST_COMPANY_PROVIDER", key: 8 },
    { name: "LIST_COMPANY_CLIENT", key: 9 },
    { name: "CREATE_NOTIFICATIONS", key: 10 },
    { name: "LIST_NOTIFICATION_COMPANY", key: 11 },
    { name: "CRUD_BAGS", key: 12 },
    { name: "LIST_BAGS", key: 13 },
    { name: "LIST_AGREEMENTS", key: 14 },
    { name: "LIST_REPORTS", key: 15 },
    { name: "LIST_ROLES", key: 16 },
    { name: "VALIDATE_FILE_MASSIVE", key: 17 },
    { name: "CREATE_CERTIFICATE", key: 18 },
    { name: "DRAFTS_NOTIFICATIONS", key: 19 },
    { name: "BACKUPS_NOTIFICATIONS", key: 20 },
    { name: "STARREDS_NOTIFICATIONS", key: 21 },
    { name: "USERS_DOCUMENTS_NOTIFICATIONS", key: 22 },
    { name: "DASHBOARD", key: 23 },
    { name: "CREATE_AGREEMENT", key: 24 },
    { name: "EDIT_AGREEMENT", key: 25 },
    { name: "VIEW_MENU_PROVIDERS", key: 26 },
    { name: "VIEW_MENU_CLIENTS", key: 27 },
    { name: "IMPROPER_CLIENTS_LIST", key: 28 },
    { name: "IMPROPER_NOTIFICATIONS_CLIENT_LIST", key: 29 },
    { name: "IMPROPER_NOTIFICATION_DETAIL", key: 30 },
    { name: "SAVE_AUDITOR_REVIEW_COMMENTS", key: 31 } ,
  ]
  const mainRoutes = [
    {
      route: '/write',
      name: 'write',
      keysPermissions: [10],
      title: "Redactar",
      component: '/write',
      icon: "write",
      visible: false
    },
    {
      route: '/dashboard',
      name: 'dashboard',
      keysPermissions: [23],
      title: "Dashboard",
      component: '/dashboard',
      icon: "pencil",
      visible: false
    },
    {
      route: '/',
      name: 'dashboard',
      keysPermissions: [23],
      visible: "none"
    },
    {
      route: '/sent',
      name: 'sent',
      keysPermissions: [4, 10],
      children: ['sentMasive', 'sent-notification-detail'],
      title: "Enviados",
      component: '/sent',
      icon: "sent",
      visible: false
    },
    {
      route: '/sentMasive/:notificationID',
      name: 'sentMasive',
      keysPermissions: [4, 10],
      visible: "none"
    },
    {
      route: '/massiveLoad',
      name: 'massiveLoad',
      keysPermissions: [10],
      visible: "none"
    },
    {
      route: '/starred',
      name: 'starred',
      keysPermissions: [21],
      title: "Destacados",
      component: '/starred',
      icon: "starred",
      visible: false
    },
    {
      route: '/drafts',
      name: 'drafts',
      keysPermissions: [19],
      title: "Borradores",
      component: '/drafts',
      icon: "pencil",
      visible: false,
      showCount: true
    },
    {
      route: '/userDocuments',
      name: 'userDocuments',
      keysPermissions: [22],
      children: ['userDocuments-detail'],
      title: "Documentos por usuario",
      component: '/userDocuments',
      icon: "pencil",
      visible: false
    },
    {
      route: '/users',
      name: 'users',
      keysPermissions: [7],
      title: "Usuarios",
      component: '/users',
      children: ['createUsers', 'editUsers'],
      icon: "pencil",
      visible: false
    },
    {
      route: '/sent-notification-detail/:notificationID',
      name: 'sentNotificationDetail',
      keysPermissions: [4, 10],
      visible: "none"
    },
    {
      route: '/createEmployee',
      name: 'createEmployee',
      keysPermissions: [6],
      visible: "none"
    },
    {
      route: '/editEmployee',
      name: 'editEmployee',
      keysPermissions: [6],
      visible: "none"
    },
    {
      route: '/userDocuments-detail/:attachmentID',
      name: 'userDocumentsDetail',
      keysPermissions: [10],
      visible: "none"
    },
    {
      route: '/createUsers',
      name: 'createUsers',
      keysPermissions: [7],
      visible: "none"
    },
    {
      route: '/editUsers',
      name: 'editUsers',
      keysPermissions: [7],
      visible: "none"
    },
    {
      route: '/agreements',
      name: 'agreements',
      keysPermissions: [14],
      title: "Vinculaciones",
      component: '/agreements',
      children :['createAgreements', 'agreementsDetail', 'editAgreements'],
      icon: "pencil",
      visible: false
    },
    {
      route: '/createAgreements',
      name: 'createAgreements',
      keysPermissions: [24],
      visible: "none"
    },
    {
      route: '/agreementsDetail/:id',
      name: 'agreementsDetail',
      keysPermissions: [14],
      visible: "none"
    },
    {
      route: '/editAgreements',
      name: 'editAgreements',
      keysPermissions: [25],
      visible: "none"
    },
    {
      route: '/providers',
      name: 'providers',
      keysPermissions: [8, 26],
      title: "Operadores",
      component: '/providers',
      children: ['createProviders','editProviders','providers-detail', 'createEmployeeProvider'],
      icon: "pencil",
      visible: false
    },
    {
      route: '/createProviders',
      name: 'createProviders',
      keysPermissions: [5],
      visible: "none"
    },
    {
      route: '/editProviders',
      name: 'editProviders',
      keysPermissions: [8],
      visible: "none"
    },
    {
      route: '/providers-detail/:companySerialID',
      name: 'providersDetail',
      keysPermissions: [8],
      visible: "none"
    },
    {
      route: '/createEmployeeProvider',
      name: 'createEmployeeProvider',
      keysPermissions: [6],
      visible: "none"
    },
    {
      route: '/clients',
      name: 'clients',
      keysPermissions: [9, 27],
      title: "Clientes",
      component: '/clients',
      children: ['createClients', 'editClients', 'clients-detail', 'createEmployee'],
      icon: "pencil",
      visible: false
    },
    {
      route: '/createClients',
      name: 'createClients',
      keysPermissions: [6],
      visible: "none"
    },
    {
      route: '/editClients',
      name: 'editClients',
      keysPermissions: [6],
      visible: "none"
    },
    {
      route: '/clients-detail/:companySerialID',
      name: 'clientsDetail',
      keysPermissions: [6],
      visible: "none"
    },
    {
      route: '/reports',
      name: 'reports',
      keysPermissions: [15],
      title: "Reportes",
      component: '/reports',
      children: ['createReports','reportsDetail'],
      icon: "pencil",
      visible: false
    },
    {
      route: '/createReports',
      name: 'createReports',
      keysPermissions: [15],
      visible: "none"
    },
    {
      route: '/reportsDetail/:reportSerialID',
      name: 'reportsDetail',
      keysPermissions: [15],
      visible: "none"
    },
    {
      route: '/sessionExpired',
      name: 'sessionExpired',
      keysPermissions: [23],
      title: "sessionExpired",
      component: '/SessionClose',
      visible: 'none'
    },
    // ** No eliminar** Se ocultó módulo de clientes con uso indebido momentaneamente.
    /*
    {
      route: '/improperClients',
      name: 'improperClients',
      keysPermissions: [28],
      children: ['improperClientsDetail','improperClientsNotificationDetail'],
      title: "Clientes uso indebido",
      component: '/improperClients',
      icon: "pencil",
      visible: false
    },
    {
      route: '/improperClientsDetail/:clientId',
      name: 'improperClientsDetail',
      keysPermissions: [28],
      visible: "none"
    },
    {
      route: '/improperClientsNotificationDetail/:notificationID',
      name: 'improperClientsNotificationDetail',
      keysPermissions: [28],
      visible: "none"
    },
    */
    {
      route: '*',
      name: 'notFound',
      keysPermissions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
      visible: "none"
    }
  ]

  if (userPermission) {
    let userPermissionSet = new Set(userPermission);
    let viewPermissions = permissions.filter(per => userPermissionSet.has(per.name));

    let lst = mainRoutes.reduce((acc, route) => {
      if (route.keysPermissions.every(key => viewPermissions.some(per => per.key === key))) {
        if (route.visible !== 'none') route.visible = true
        acc.push(route);
      }
      return acc;
    }, []);
    mainRoutes.map((element) => {
      if (lst.some(elmt => element === elmt && element.visible !== 'none')) return { ...element, visible: true };
      return element;
    })
  }
  return mainRoutes.filter(route => route.visible === true || route.visible === "none")
}