import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionOrder } from '../../actions/filterActions'
import ButtonIcon from '../Button/IconButton'
import { ReactComponent as OrderIcon } from '../../assets/icons/Order.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import {chageStateLoadNotificationsList, chageStateLoadCitizensList} from '../../actions/writerActions'


const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 450px) {
    margin-left: -10px;
  }
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilterOrder = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  max-width: 260px !important;
  min-width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

const PopUpOrder =({showOldestNewest, showAZ, popUpOrderState, options,loader})=> {
  const dispatch = useDispatch()
  const order = useSelector (state => state.filterReducer.sort)
  const statusLoadNotifications= useSelector(state => state.writerReducer.loadNotifications)

  const [state, setState] = useState({
    show: false,
    state: order
  })

  const hideOptions= []

  useEffect(()=>{
    if(state.order !== order){
      setState((prevState)=>(
        { ...prevState,
          state: order 
        }
      ))
    }
  },[order])

  useEffect(()=>{
      dispatch(actionOrder(state.state))
  },[state.state])

  const toggle = () => {
    setState((prevState) =>({
      ...prevState, 
      show: !state.show 
    }));

    if (statusLoadNotifications)
      dispatch(chageStateLoadNotificationsList(false))
    else
      dispatch(chageStateLoadCitizensList(false))
  }

  const handleButtonClick = (newOrder) => {
    if (!loader) {
      setState((prevState)=>({
          ...prevState,
          state: newOrder 
        }));
      // Cerrar el Popup
      setState((prevState)=>({
          ...prevState,
          show: false 
        }));
    }
  }

    return (
      <Divider>
        <PopupFilterOrder position='bottom left' on='click' onClose={toggle} open={state.show}
          trigger={
            <ButtonIcon onClick={toggle}>
              <Texto> Ordenar</Texto>
              <OrderIcon />
            </ButtonIcon>
          } >
          <ContainerOption>
            {showOldestNewest &&
              <>
                {!!hideOptions && !!hideOptions.find(hide => hide === 'masReciente') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('masReciente')}>
                    {state.state === 'masReciente' && <Reviewed className='iconReReviewed' />}
                    {state.state !== 'masReciente' ? <Text>{options === 'reports' ? 'Fecha generación (más reciente)' : 'Más reciente'}</Text> : options === 'reports' ? 'Fecha generación (más reciente)' : 'Más reciente'}
                  </ButtonOption>
                }
                {!!hideOptions && !!hideOptions.find(hide => hide === 'masAntiguo') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('masAntiguo')} >
                    {state.state === 'masAntiguo' && <Reviewed className='iconReReviewed' />}
                    {state.state !== 'masAntiguo' ? <Text>{options === 'reports' ? 'Fecha generación (más antigua)' : 'Más antiguo'}</Text> : options === 'reports' ? 'Fecha generación (más antigua)' : 'Más antiguo'}
                  </ButtonOption>
                }
              </>
            }
            {showAZ &&
              <>
                {!!hideOptions && !!hideOptions.find(hide => hide === 'A-Z') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('A-Z')}>
                    {state.state === 'A-Z' && <Reviewed className='iconReReviewed' />}
                    {state.state !== 'A-Z' ? <Text>{options === 'reports' ? 'Usuario (A-Z)' : 'A-Z'}</Text> : options === 'reports' ? 'Usuario (A-Z)' : 'A-Z'}
                  </ButtonOption>
                }
                {!!hideOptions && !!hideOptions.find(hide => hide === 'Z-A') ? <></> :
                  <ButtonOption className='buttonOptionHover' onClick={() => handleButtonClick('Z-A')}>
                    {state.state === 'Z-A' && <Reviewed className='iconReReviewed' />}
                    {state.state !== 'Z-A' ? <Text>{options === 'reports' ? 'Usuario (Z-A)' : 'Z-A'}</Text> : options === 'reports' ? 'Usuario (Z-A)' : 'Z-A'}
                  </ButtonOption>
                }
              </>
            }
          </ContainerOption>
        </PopupFilterOrder>
      </Divider>
    )
  }

export default PopUpOrder