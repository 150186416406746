import {
  AGREEMENT_LIST,
  SAVE_AGREEMENT,
  ACTIVE_STATE_FORM_AGREEMENT,
  INACTIVE_STATE_FORM_AGREEMENT,
  ACTIVE_FORM_EDIT_AGREEMENT,
  INACTIVE_FORM_EDIT_AGREEMENT,
  GET_PROVIDERS_LIST,
  GET_CLIENTS_LIST,
  DETAIL_AGREEMENT,
  CHANGE_EDIT_AGREEMENT,
  GET_AGREEMENT_EDIT,
  EDIT_AGREEMENT,
  CLEAR_AGREEMENT_DETAIL,
  CLEAR_AGREEMENT_EDIT,
  SET_LOGS_LIST_AGREEMENTS,
  LOGOUT
} from '../actions/actionsUtilities/types.js'

const initialState = {
  agreementListArray: [],
  elementsCount: 0,
  savedAgreement: [],
  activeForm: false,
  stateFormEdit: true,
  providerList: [],
  clientList: [],
  agreementActiveEdit: {},
  detailAgreement: {
    agreements: {},
    services: {},
    providerData: {},
    clientData: {},
  },
  agreementPut: [],
  logsList: [],
  logsCount: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AGREEMENT_LIST:
      return {
        ...state,
        agreementListArray: action.payload.agreements,
        elementsCount: action.payload.elementsCount
      }
    case SAVE_AGREEMENT:
      return {
        ...state,
        savedClient: action.payload
      }
    case ACTIVE_STATE_FORM_AGREEMENT:
      return {
        ...state,
        activeForm: true,
      }
    case INACTIVE_STATE_FORM_AGREEMENT:
      return {
        ...state,
        activeForm: false,
      }
    case ACTIVE_FORM_EDIT_AGREEMENT:
      return {
        ...state,
        stateFormEdit: false,
      }
    case INACTIVE_FORM_EDIT_AGREEMENT:
      return {
        ...state,
        stateFormEdit: true,
      }
    case GET_PROVIDERS_LIST:
      return {
        ...state,
        providerList: action.payload
      }
    case GET_CLIENTS_LIST:
      return {
        ...state,
        clientList: action.payload
      }
    case DETAIL_AGREEMENT:
      return {
        ...state,
        detailAgreement: action.payload
      }
    case GET_AGREEMENT_EDIT:
      return {
        ...state,
        agreementActiveEdit: { ...action.payload }
      }
    case CHANGE_EDIT_AGREEMENT:
      return {
        ...state,
        agreementActiveEdit: {
          ...state.agreementActiveEdit,
          [action.payload.key]: action.payload.value
        }
      }
    case EDIT_AGREEMENT:
      return {
        ...state,
        agreementPut: action.payload,
      }
    case CLEAR_AGREEMENT_DETAIL:
      return {
        ...state,
        detailAgreement: initialState.detailAgreement
      }
    case CLEAR_AGREEMENT_EDIT:
      return {
        ...state,
        agreementActiveEdit: initialState.agreementActiveEdit
      }
    case SET_LOGS_LIST_AGREEMENTS:
      return {
        ...state,
        logsList: action.payload.logsList,
        logsCount: action.payload.logsCount
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
