import React from 'react'
import { CustomComponent } from '../CustomComponent'
import { ReactComponent as Canceled } from '../../assets/icons/sent/MassiveStatus/Canceled.svg'
import { ReactComponent as Completed } from '../../assets/icons/sent/MassiveStatus/Completed.svg'
import { ReactComponent as Error } from '../../assets/icons/sent/MassiveStatus/Error.svg'
import { ReactComponent as InProcess } from '../../assets/icons/sent/MassiveStatus/InProcess.svg'
import { ReactComponent as Wait } from '../../assets/icons/sent/MassiveStatus/Wait.svg'
import { ReactComponent as Incompleted } from '../../assets/icons/sent/MassiveStatus/Incompleted.svg'
import styled from 'styled-components'
import { Icon, Label } from 'semantic-ui-react'

const data = {
  'COMPLETED': {
    icon: Completed,
    text: 'Completo',
    color: '#009838'
  },
  'INCOMPLETED': {
    icon: Incompleted,
    text: 'Incompleto',
    color: '#E49300'
  },
  'ERROR': {
    icon: Error,
    text: 'Error',
    color: ' #BA3555'
  },
  'READY_SEND_NOTIFICATION': {
    icon: Wait,
    text: 'En espera',
    color: '#E35F00'
  },
  'SEND_NOTIFICATION': {
    icon: InProcess,
    text: 'En proceso',
    color: '#008F98'
  },
  'NOT_CHARGED': {
    icon: Error,
    text: 'Completada',
    color: '#009838'
  },
  'CANCELED': {
    icon: Canceled,
    text: 'Cancelado',
    color: '#787D83'
  },
  'default': {
    icon: Wait,
    text: 'En espera',
    color: '#E35F00'
  }
}


const CustomLabel = styled(Label)`
  color: ${props => !props.customcolor ? '#0E3B52' : props.customcolor} !important;
  border: 1px solid ${props => !props.customcolor ? '#0E3B52' : props.customcolor} !important;
  border-radius: 5px !important;
  height: 32px !important;
  padding: 4px 8px !important;
  width: min-content !important;
  .icon svg {
    min-height: 16px;
    min-width: 16px;
    width: 1.4em;
    margin-top: -3px;
  }
`
const CustomP = styled.p`
  font-family: Roboto;
  font-size: 14px;
  min-width: 14px;
  min-height: 14px;
  text-overflow: ellipsis;
  white-space:nowrap;
  margin-left: 4px;
  font-weight 400px;
  line-height: 24px;
  font-weight: 400;
`

export const LabelMassiveStatus = ({status}) => {
  const labelData = data[status] ? data[status] : data['default']
  
  return (
    <>
      <CustomLabel basic image customcolor={labelData.color} >
        <Icon  style={{path: { fill: labelData.color } }}>
            <CustomComponent component={labelData.icon}   style={{'path' :{ fill: labelData.color } }}/>
        </Icon>
        <CustomP>{labelData.text}</CustomP>
      </CustomLabel>
    </>
  )
}
