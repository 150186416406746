import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { SearchBarGenericAdvancedSearch } from './SearchBarGeneric'


const ContainerUserDocumentSearch = (props) => {

  const viewList = useSelector(state => state.userDocumentReducer.viewList)
  const viewGrid = useSelector(state => state.userDocumentReducer.viewGrid)
  
  const [searchBarState, setSearchBarState] = useState("")
  const [popUpOrderState, setPopUpOrderState] = useState("")
  
    return (
      <>
        <SearchBarGenericAdvancedSearch 
        viewGrid={viewGrid} 
        filterSmall={props.filterSmall}
        popUpOrderState={popUpOrderState} 
        textPlaceHolder={props.textPlaceHolder}  
        searchBarState={searchBarState} typeButton={'documents'} showAZ={props.showAZ}
        loader={props.loader}
        />
      </>
    )
}

export default ContainerUserDocumentSearch