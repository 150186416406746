import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import PrimaryButton from '../../../components/PrimaryButton'
import { ReactComponent as NextButtonIcon } from "../../../assets/icons/nextButtonIcon.svg"
import {
  Checkbox
} from 'semantic-ui-react'
import MassiveLoadModal from '../../../components/ModalMassiveLoad/MassiveLoadModal'
import { openwriteNotificationModal, recordsMassiveFileParametrizationV2, clearRecordsMassiveFileParametrization, closewriteNotificationModal, recordsMassiveFileV2 } from '../../../actions/modalActions'
import { chageStateLoadCitizens, chageStateLoadCitizensList, changeWriteNotificationForm, isMassiveRecipient, setFileSerialId, createNewParametrizationTags, sendMassiveFileV2 } from '../../../actions/writerActions'
import { RESET_FIELD_PARAMETRIZATION, SPECIFIC_DOCUMENTS } from '../../../actions/actionsUtilities/types'
import { AlertLimitFilesMassiveLoad } from '../../../components/Alert/AlertLimitFilesMassiveLoad'
import { saveDraftData, saveDraftV2 } from '../../../actions/draftsActions'
import { validateTagsMassiveFile } from '../../../actions/actionsTest/writerActions'
import { useNavigate } from 'react-router-dom-v5-compat'


const Container = styled.div`
  padding: 30px;
  padding-left:15px;
  padding-bottom:80px;
  display: flex;
  flex-direction: column;
  width:100%;
  align-items: start;

  @media only screen and (max-width: 768px){
    padding: 16px 11px;
  }
`
const Container2 = styled.div`
  display: flex;
  flex-direction: initial;
  align-items: baseline;
  flex-wrap: wrap;
`

const Label = styled.span`
  text-align: left !important;
  letter-spacing: 0 !important;
  color: #C3132E !important;
  opacity: 1 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 5px;
`

const CustomCheckbox = styled(Checkbox)`
  width:auto;
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 5px;
    padding-right: 10px;

    @media only screen and (min-width: 1024px) and (max-width: 768px) {
      margin: 0;
    }

    @media only screen and (max-width: 768px){
      padding: 0;
      margin: 0;
    }
  }

  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 2px solid #22C1D0 !important;
    width: 18px;
    height: 18px;
  }

  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label{
    opacity: 0.2 !important;
  }

  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
  }

  &.ui.checkbox label{
    @media only screen and (max-width: 768px){
      padding-left:26px;
    }
  }
`
const ContainerButtons = styled.div`
  display:flex;
  justify-content: center;
  margin: 32px 0px;
  gap: 16px;
  text-align: center;
  align-items: center;
  width:100%;

`
const PrimaryButtonWhite = styled(PrimaryButton)`
  width: 8em;
  color: #22C1D0 !important;
  background: #FFFFFF !important;
  border: 1px solid #22C1D0 !important;
  border-radius: 4px !important;
  cursor: pointer;

  @media only screen and (max-width: 768px){
    width: 10.938em;
  }
`

const ButtonIcon = styled.button`
  width: 9.621em;
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px !important;
  color: #FFFFFF;
  padding: 4px 20px 10px 20px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;
  vertical-align: baseline;

  @media only screen and (min-width: 1024px) and (max-width: 1465px) {
    font-size: 16px;
    width: 130px;
    margin-right: 5px;
  }
  @media only screen and (max-width: 768px){
    width: 10.938em;
  }
`

const NextIcon = styled(NextButtonIcon)`
  padding: 1px;
  position:relative;
  top: 5px;
  left: 10px;
`

const Footer = (props) => {

  const {columnList} = useSelector((state) => state.writerReducer.fieldParametrizationData)
  const {whiteTime} = useSelector((state) => state.writerReducer)
  const [successCheckbox, setSuccessCheckbox] = useState(false)
  const [activeMessage, setActiveMessage] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [sendBody, setSendBody] = useState(false)
  const [listFileResult, setlistFileResult] = useState(false)
  const [validatingTags, setValidatingTags] = useState(false)
  const [intervalId, setIntervalId] = useState(()=>{})
  const resultRecordParametrization = useSelector(state => state.writerReducer.resultRecordParametrization.listFileSearchMassive)
  const columnListFull = useSelector(state => state.writerReducer.resultValidateFileMassive.columnList)
  const companyID = useSelector(state => state.authReducer.user.companyID._id)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { toSaveDraft } = useSelector(state => state.draftReducer)
  const [showAlertNoFolderFound, setShowAlertNoFolderFound] = useState(false)
  const [alertMessage, setAlertMessage] = useState({})
  const { pathnameCurrent } = useSelector(state => state.menuReducer)
  const validateErrorInput = document.getElementsByClassName('labelInputFormMassiveLoadError')
  const validateErrorDropDown = document.getElementsByClassName('divInputDropdownFormMassiveLoadError')
  const validateErrorLabelsName = document.getElementsByClassName('labelInputFormLabelsNameError')
  const toggle = (e) => {
    setSuccessCheckbox(!successCheckbox)
  }

  const makeSaveFileMassive =  async () => {
    const body = {
      "fileSerialID": props.fileSerialID,
      "campaignName": props.companyName,
      "columnList": columnList,
    }
    const res = await dispatch(validateTagsMassiveFile(body));
    setAlertMessage(res);
    return res? res.success: false;
  }

  const onClick =  async (e) => {
    e.preventDefault()
    if (!hasMaxOfFiles(columnListFull)) {
      if (props.validator.allValid()  && !!successCheckbox) {
        setValidatingTags(true)
        const res = await makeSaveFileMassive()
        if(res){
          setModalActive(true)
          setValidatingTags(false)
          const fileRead = await dispatch(sendMassiveFileV2({ fileSerialID: props.fileSerialID })).then(res=>{
            dispatch(recordsMassiveFileParametrizationV2({ fileSerialID: props.fileSerialID }))
            setSendBody(true)
          })
          if(fileRead) dispatch(createNewParametrizationTags(columnList))
        }else{
          setShowAlertNoFolderFound(true)
          setValidatingTags(false)
        }
      } else {
        props.showValidationMessage(true);
        setActiveMessage(true)
        if(!!props.validator.messagesShown){
          validateErrors()
        }
      }
    }
  }

  useEffect(() => {
    dispatch(clearRecordsMassiveFileParametrization())
  }, [])

  useEffect(() => {
    if(!!props.validator.messagesShown){
      validateErrors()
    }


  }, [props.validator.messagesShown])

  const validateErrors = () => {
    if(validateErrorInput.length > 0){
      validateErrorInput[0].focus()
    }else if(validateErrorLabelsName.length > 0){
      validateErrorLabelsName[0].focus()
    }else if(validateErrorDropDown.length > 0){
      validateErrorDropDown[0].firstChild.focus()
    }
  }

  useEffect(() => {
    if(!!sendBody){
      setIntervalId(
          setInterval(() => {
            dispatch(recordsMassiveFileParametrizationV2({ fileSerialID: props.fileSerialID }))
          }, whiteTime)
      )
    }
  }, [sendBody])

  useEffect(() => {
    setlistFileResult(resultRecordParametrization[0])
  }, [resultRecordParametrization])



  const onClose = () => {
    clearInterval(intervalId)
    setModalActive(false)
    navigate(pathnameCurrent)
    dispatch(recordsMassiveFileV2({companyID:companyID}))
    dispatch(chageStateLoadCitizens(true))
  }

  const onClose2 = () => {
    clearInterval(intervalId)
    setModalActive(false)
    if(resultRecordParametrization[0].status === 'Completo'){
      navigate(pathnameCurrent)
      dispatch(openwriteNotificationModal())
      dispatch(setFileSerialId(resultRecordParametrization[0].fileSerialID))
      dispatch(changeWriteNotificationForm('recipient',resultRecordParametrization[0].fieldTo))
      dispatch(isMassiveRecipient(true))
      dispatch({type: SPECIFIC_DOCUMENTS, payload: resultRecordParametrization[0].listDocumentsSpecific})
      dispatch(saveDraftData({fileSerialID: resultRecordParametrization[0].fileSerialID}))
      dispatch(saveDraftV2({...toSaveDraft,fileSerialID: resultRecordParametrization[0].fileSerialID}))
    }else{
      navigate(pathnameCurrent)
      dispatch(closewriteNotificationModal())
      dispatch(chageStateLoadCitizens(true))
      dispatch(chageStateLoadCitizensList(true))
    }
  }

  const handleOpenModalWrite = () => {
    dispatch({type: RESET_FIELD_PARAMETRIZATION})
    dispatch(openwriteNotificationModal())
    navigate('/dashboard')
  }


  const hasMaxOfFiles = (list) => {
    let fileCounter = 0;
    if (list !== undefined) {
      for (let index = 0; index < list.length; index++) {
        if (list[index].example.endsWith(".pdf")) {
          if (columnList[index] !== undefined) {
            if(columnList[index].type !== 'DONT_MIND'){
              fileCounter++;
            }
          }else{
            fileCounter++;
          }
        }
      }
    }
    return fileCounter > 5; // Si se cumplen las condiciones, devuelve verdadero.
  };

  return (
      <Container>
        <Container2>

          {hasMaxOfFiles(columnListFull) && <AlertLimitFilesMassiveLoad/>}
          {showAlertNoFolderFound  ?  alertMessage.internalCode === 'FSI'? <AlertLimitFilesMassiveLoad
                      text1='La ubicación de los documentos no está disponible. '
                      text2='Por favor comunícate con tu administrador para solucionar este inconveniente.'/>
                  :
                  <AlertLimitFilesMassiveLoad
                      text1={alertMessage.message}  text2={''}/>

              :<></>}
          <CustomCheckbox
              label='Entiendo que los datos ingresados no podrán ser editados ni eliminados posteriormente'
              value={successCheckbox}
              onChange={toggle}
              id="successCheckbox"
              disabled={false}
          />
          <Label style={!!activeMessage ? !successCheckbox ?{display:'block'}:{display:'none'}:{display:'none'}}>Debes seleccionar esta opción</Label>
        </Container2>
        <ContainerButtons>
          <PrimaryButtonWhite
              txtBtn='Cancelar'
              onPress={handleOpenModalWrite}
          />
          <ButtonIcon
              disabled={validatingTags}
              className='putButton'
              onClick={onClick}
          >
            Continuar
            <NextIcon />
          </ButtonIcon>
        </ContainerButtons>
        <MassiveLoadModal
            onClose={onClose}
            modalActive={modalActive}
            resultRecord= {listFileResult}
            intervalId={intervalId}
            onClose2={onClose2}
        />
      </Container>
  )
}

Footer.propTypes = {
  navigate: PropTypes.any.isRequired,
}

export default Footer
