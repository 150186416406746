import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import RowTableSent from './RowTableSent'
import GeneralTable from '../../../components/Table/GeneralTable'
import { getNotificationsv2 } from '../../../actions/sentActions'
import { actionUpdateTabFilter, activePagination } from '../../../actions/filterActions'
import { actionClearFilter } from '../../../actions/filterDetailActions'
import { closePDFModal } from '../../../actions/modalActions'
import { saveHiredV2 } from '../../../actions/writerActions'
import { CHANGE_TAB_FILTER } from '../../../actions/actionsUtilities/types'
import { useNavigate } from 'react-router-dom-v5-compat'

const orderTranslator = (order) => {
  const options = {
    'masReciente': { "_id": -1 },
    'masAntiguo': { "_id": 1 },
    'A-Z': { "recipient.firstName": 1 },
    'Z-A': { "recipient.firstName": -1 }
  }
  return options[order]
}
const usePrevious = (value) => {
  const prevValueRef = useRef();
  useEffect(() => {
    prevValueRef.current = value;
  }, [value]);

  return { prev: prevValueRef.current, current: value };
};

export const AllNotifications = ({ tabFilter, setPaginationLoader,state,setState }) => {
  const customEqual = (oldValue, newValue) => oldValue === newValue
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const filterReducer = useSelector(state => state.filterReducer, customEqual)
  const { notifications } = useSelector(state => state.sentReducer)
  const stateNotificationStarred = useSelector(state => state.stateNotificationStarred)
  const { changeTabFilter } = useSelector(state => state.sentReducer)

  // const [state, setState] = useState({
  //   loader: true,
  //   init: false,
  // })

  useEffect(() => {
    if (state.init) {
      switch (filterReducer.tabFilter) {
        case 'all':
          filterReducer.requiredType = { key: "", value: "" }
          break;
        case 'readConfirmation':
          filterReducer.requiredType = { key: "requiredConfirmationRead", value: true }
          break;
        case 'notificationSignature':
          filterReducer.requiredType = { key: "requiredSignature", value: true }
          break;
        case 'docSignature':
          filterReducer.requiredType = { key: "requiredSignatureAttachments", value: true }
          break;
        case 'push':
          filterReducer.requiredType = { key: "isPushNotification", value: true }
          break;
        default:
          break;
      }
      makeNotificationsRequest(filterReducer)
      setState({ loader: true })
    }
  }, [filterReducer.tabFilter, filterReducer.sort,filterReducer.searchBar,filterReducer.count,filterReducer.pag,filterReducer.searchAdvanced])

  useEffect(() => {
    if (changeTabFilter) {
      dispatch(actionUpdateTabFilter(tabFilter))
      dispatch({ type: CHANGE_TAB_FILTER })
    }
  }, [tabFilter])

  useEffect(() => {
    if (state.init) makeNotificationsRequest(filterReducer)
  }, [stateNotificationStarred])

  const makeNotificationsRequest = useCallback((filterReducer) => {

    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      status: filterReducer.searchAdvanced.notificationState === "MASSIVE" ? '' : filterReducer.searchAdvanced.notificationState,
      initDate: !!filterReducer.searchAdvanced.date.startDate
          ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
          ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      starred: filterReducer.starred,
      [filterReducer.requiredType.key]: filterReducer.requiredType.value,
      haveAttachments: filterReducer.searchAdvanced.attachedFile,
      textToSearch: filterReducer.searchBar[0],
      massiveDeliveryType: filterReducer.searchAdvanced.notificationState === "MASSIVE"
          ? true : ''
    }

    dispatch(getNotificationsv2(body)).then(() => {
      dispatch(activePagination(false))
      setState({ init: true, loader: false })
      setPaginationLoader(false)
    })
  }, [dispatch])

  useEffect(() => {
    dispatch(actionClearFilter())
    makeNotificationsRequest(filterReducer)
    closePDFModal()
    dispatch(saveHiredV2())
  }, [])

  return (
      <GeneralTable
          list={notifications === undefined ? [] : notifications}
          typeList={filterReducer.isSearch === true ? 'default' : 'notifications'}
          headerTable={[]}
          rowComponent={RowTableSent}
          navigate={navigate}
          loader={state.loader}
          listHeight={'notifications'}
      />
  )
}

export default AllNotifications