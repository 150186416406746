import React from "react"
import styled from "styled-components"
import {  Grid } from 'semantic-ui-react'
import { useState } from "react"
import { SingleContactForm } from "./SingleContactForm"


const Title2 = styled.p`
  color: #133F4F;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-Top: 22px
  margin-bottom: 2rem;
`
const Title3 = styled.p`
  color: #133F4F;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
`

const ColumnGrid = styled(Grid.Column)`
  width:50% !important;
  @media only screen and (max-width: 1200px) {
    width: 100% !important;
  }
`




const ContainerAddButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
`

const ButtonAdd = styled.button`
border: 1px solid #22C1D0;
border-radius: 4px;
background: #22C1D0;
color:white;
cursor: pointer;
font-size: 20px;
padding: 7px 11px;
min-width: 36px;
`

export const FormContact = ({setContactInformation,contactInformation,formValidator,errors,addNewContactFields,removeContactsFields}) => {


    const [numberOfContacts, setNumberOfContacts] = useState([1,2])

    const onChange = (e) => {
        let idElement = e.target.id
        let numberOfContact = idElement.match(/\d+$/)[0];
        let field = idElement.replace(/\d+/g, '');
        setContactInformation(numberOfContact-1, field ,e.target.value);

    }

    
  return (
    <>
      <Grid>
        
        {contactInformation!== undefined && numberOfContacts.map((contact, index)=>(
            <SingleContactForm
                key={index}
                onChange={onChange}
                contactInformation={contactInformation[index]}
                formValidator={formValidator}
                errors={errors}
                numberOfContact={contact}
            />
        ))}
       
      </Grid>
    </>
  );
};
