import axios from 'axios'
import { API_ENDPOINT} from "../config/config"
import {
  CHANGE_STATE_MESSAGE_MODAL,
  CHANGE_STATE_REFRESH_MODULE,
  GET_USERS_LIST,
  GET_USER_EDIT,
  CHANGE_STATE_USER,
  SAVE_USER,
  EDIT_USER,
  INACTIVE_STATE_USER_FORM,
  CHANGE_EDIT_USER,
  ACTIVE_FORM_EDIT_USER,
  INACTIVE_FORM_EDIT_USER,
  SAVE_USER_CERTIFICATE,
  CHANGE_HAVE_CERTIFICATE,
  VIEW_PAGINATION,
} from "./actionsUtilities/types.js"
import { deleteFromListActions, reasonDispatch, updateListActions } from "./DispatchGeneric"
import { GET_LIST_USERS, 
          GET_ONE_USER, GET_ROLES_USER_ROUTE, 
          PUT_EDIT_USERS_ROUTE_V2, 
          GET_ROLES_USER_ROUTE_PROVIDER,
          CREATE_USERS_V3,
          GET_CERTIFICATE_USER_V3
        } from './actionsUtilities/actionsRoutes'
import { decrypt, encrypt } from '../helpers/cypher'
import { getHeaders } from '../helpers/globalHelpers'
 
export const getUsers = (body, stateInitial) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + GET_LIST_USERS}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async res => {
    const {users, elementsCount} = await decrypt(res.data.result);
    dispatch({
      type: GET_USERS_LIST,
      payload: {user: users, elementsCount: elementsCount}
    })
    dispatch({
      type: VIEW_PAGINATION,
      payload: {
          total: elementsCount,
      }
  })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getUserEdit = (body) => async dispatch => {
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT + GET_ONE_USER}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async res => {
    const {data} = await decrypt(res.data.result);
    dispatch({
      type: GET_USER_EDIT,
      payload: data,
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const getRolesUser = () => async (dispatch) => {
  try {
    return await axios.get(`${API_ENDPOINT+GET_ROLES_USER_ROUTE}`, { headers: await getHeaders() }).then(async res => {
      const decryptedData = await decrypt(res.data.result);
      if (res.status === 200) {
        return decryptedData
      }
    })
  } catch (error) {
  }
}
export const getRolesUserProvider = () => async (dispatch) => {

  try {
    return await axios.get( `${API_ENDPOINT+GET_ROLES_USER_ROUTE_PROVIDER}`, { headers: await getHeaders() })
    .then(async res =>{
      const decryptedData = await decrypt(res.data.result);
      if (res.status === 200) {
        return decryptedData
      }
    })
  } catch (error) {
  }
}

export const activeFormUser = () => async dispatch => {
  dispatch({
    type: CHANGE_STATE_USER
  });
}

export const inactiveFormUser = () => async dispatch => {
  dispatch({
    type: INACTIVE_STATE_USER_FORM
  });
}

export const inactiveFormUserEdit = () => async (dispatch) => {
  dispatch({
    type: INACTIVE_FORM_EDIT_USER,
  })
}

export const postCreateUsersv3 = (body) => async (dispatch) => {
  dispatch(updateListActions('CREATE_USER'))
  const encryptedBody = await encrypt(body);
  await axios.post(`${API_ENDPOINT+CREATE_USERS_V3}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async (res) => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_USER,
      payload: decryptedData
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'employeeCreate' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'employeeEdit' }
        })

        dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })
      }, 2000)
    )
    dispatch({
      type: INACTIVE_STATE_USER_FORM,
    })
    dispatch(deleteFromListActions('CREATE_USER'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'errorCreateEntities' }
    })
    dispatch(deleteFromListActions('CREATE_USER'))
  })
}

export const changeFormUser = (key, value) => async (dispatch) => {
  dispatch({
    type: CHANGE_EDIT_USER,
    payload: {
      key,
      value
    }
  })
  dispatch({
    type: ACTIVE_FORM_EDIT_USER,
  })
}

export const putUserEdit = (body, id) => async (dispatch) => {
  dispatch(updateListActions('EDIT_USER'))
  const encryptedBody = await encrypt(body);
  await axios.put(
    `${API_ENDPOINT+PUT_EDIT_USERS_ROUTE_V2}${id}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async(res) => {
      dispatch({
        type: EDIT_USER,
        payload: body
      })
      dispatch({
        type: CHANGE_STATE_MESSAGE_MODAL,
        payload: { type: 'employeeEdit' }
      },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'employeeEdit' }
        })
        dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })
      }, 2000)
      )
      dispatch({ type: INACTIVE_STATE_USER_FORM })
      dispatch({ type: INACTIVE_FORM_EDIT_USER, })
    dispatch(deleteFromListActions('EDIT_USER'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'userCantChangeStatus' }
    })
    dispatch(deleteFromListActions('EDIT_USER'))
  })
}

export const putStateActive = (body, id) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch(updateListActions('EDIT_USER'))
  await axios.put(
    `${API_ENDPOINT+PUT_EDIT_USERS_ROUTE_V2}${id}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async (res) => {
    dispatch({
      type: EDIT_USER,
      payload: body
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'userActive' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'userActive' }
        })
        /*dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })*/
      }, 2000)
    )
    dispatch(deleteFromListActions('EDIT_USER'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'userCantChangeStatus' }
    })
    dispatch(deleteFromListActions('EDIT_USER'))
  })
}

export const putStateInactive = (body, id) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch(updateListActions('EDIT_USER'))
  await axios.put( `${API_ENDPOINT+PUT_EDIT_USERS_ROUTE_V2}${id}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async (res) => {
    dispatch({
      type: EDIT_USER,
      payload: body
    })
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'userInactive' }
    },
      setTimeout(() => {
        dispatch({
          type: CHANGE_STATE_MESSAGE_MODAL,
          payload: { type: 'userInactive' }
        })
       /* dispatch({
          type: CHANGE_STATE_REFRESH_MODULE,
          payload: true
        })*/
      }, 2000)
    )
    dispatch(deleteFromListActions('EDIT_USER'))
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_MESSAGE_MODAL,
      payload: { type: 'userCantChangeStatus' }
    })
    dispatch(deleteFromListActions('EDIT_USER'))
  })
}

export const getCertificateUserV3 = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT+GET_CERTIFICATE_USER_V3}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async (res) => {
    var decryptedData = await decrypt(res.data.result);
    dispatch({
      type: SAVE_USER_CERTIFICATE,
      payload: decryptedData.preRegisterUserData,
    })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_HAVE_CERTIFICATE,
      payload: false,
    })
  })
}