import React, { Children, useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import styled from "styled-components";
import { ReactComponent as EditImage } from '../../../src/assets/icons/clients/edit-button.svg'
import { ReactComponent as IconFile } from '../../../src/assets/icons/userDocuments/fileIcon.svg'
import Label from '../Label/MainLabelMale'
import { ReactComponent as Active } from '../../assets/icons/users/active.svg'
import { ReactComponent as Delete } from '../../assets/icons/delete.svg'
import { ReactComponent as Inactive } from '../../assets/icons/users/inactive.svg'
import { ReactComponent as Disabled } from '../../assets/icons/toogle-disabled.svg'
import { ReactComponent as Attached } from '../../assets/icons/sent/attached.svg'
import { ReactComponent as Massive } from '../../assets/icons/drafts/massiveIcon.svg'
import { ReactComponent as Download } from '../../assets/icons/download-attachment.svg'
import InputFormDropdown from "../../components/InputDropdown/InputFormDropdown";
import { ReactComponent as IconAttachment } from '../../../src/assets/icons/userDocuments/Attachment-icon.svg'
import StarredButton from "../Starred/StarredButton";
import { CheckBoxGeneric } from "../CheckboxForm/CheckBoxGeneric";

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 5px !important;
  padding-right: 10px !important;
`
const SentCellOptions = styled(Table.Cell)`
  white-space: nowrap !important;
  max-width: 1px !important;
  padding: 5px !important;
  padding-right: 10px !important;
`
const SentCellIcon = styled(Table.Cell)`
  max-width: 1px !important;
  padding: 10px !important;
  padding-right:21px !important;
  align-items: center;
`

const CellEditSuperAdmin = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 5px 10px 5px 5px !important;
  @media screen and (min-width: 1024px) {
    padding: 5px 10px 5px 5px !important;
  }
`
const SentCellDropDown = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow-y: visible !important;
  max-width: 1px !important;
  padding: 10px !important;
`

const StatusContainer = styled.div`
  float: left!important;
  padding-left: 10% !important;
`

const SentCellResponsive = styled(Table.Cell)`
  width: auto;
  min-width: 0;
  max-width: 100%;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  display block;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  line-height: 10px;
  padding: 0.25em 0em!important;
  @media only screen and (max-width: 1280px) {
    font-size:14px;
  }
`
const MassiveLoadResponsive = styled.div`
  width: 100%;
`

const SentCellResponsiveIcon = styled(Table.Cell)`
  width: 100%;
  min-width: 0;
  max-width: 100%;
  max-height: 30px;
  text-overflow: ellipsis;
  display block;
  border-top: 0px solid rgba(34,36,38,.1) !important;
  line-height: 0px;
  padding: 0.25em 0em!important;
  @media only screen and (max-width: 1024px) {
    .ui.basic.label{
      padding-bottom: 0px !important;
      height: 24px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    font-size:14px;
    .ui.basic.label{
      padding-bottom: 0px !important;
      max-height: 20px;
    }
  }
`

const Edit = styled(EditImage)`
  float: right !important;
  margin: 4px 25px 0 0 !important;
  @media only screen and (min-width: 100px) and (max-width: 900px) {
    margin: 4px 0px 0 0 !important;
  }
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    margin: 0px 0 0 0 !important;
  }
`

const Icon = styled.div`
  margin: 4px 25px 0 0 !important;
  @media only screen and (max-width: 1024px) {
    margin: 0px 0 0 10px !important;
  }
`
const ContainerDropDown = styled.div`
  display:block;
  padding: 4px 0px;

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`
const Divider = styled.div`
  display: flex
`
const DividerIcons = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`
const DividerIconsResponsive = styled.div`
  margin-right: 10px;
  margin: -4px 10px 0 0;
`
const DividerIconsResponsiveMix = styled.div`
  margin-right: 10px;
  margin: 1px 10px 0 0;
`

const ContainerDisabledMessage = styled.div`
  position: fixed;
  top: ${props => props.top };
  left: ${props => props.left };
  z-index: 100;
  height: 48px;
  background-color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  box-shadow: 0px 4px 16px 0px #00000040;
  padding: 5px;
  border-radius: 6px;
  color: #3C3C3B;

`;

const DraftReceipentCell = styled(Table.Cell)`
  padding: 0px !important;
`

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width
  };
}

function useWindowDimensions() {
  const [windowDimensions = 0, setWindowDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return windowDimensions;
}

export const Cell = ({ text, width = 0, columnName = '', color, fontWeight, justifyContent, children, onClick}) => {
  return (
    <> {useWindowDimensions().width > 768 ?
        <SentCell onClick={onClick} width={width}>
          <div style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: color, fontWeight: fontWeight, justifyContent: justifyContent }}>
            {text}
          </div>
          {children}
        </SentCell>
       
        :
        <SentCellResponsive onClick={onClick}> 
          <div style={{ width: '95vw', display: 'flex', justifyContent: 'center' }}>
            <Grid style={{ width: '100%', display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} divided='vertically'>
              <Grid.Row className='row' style={{ width: '100%', display: 'flex', flexDirection: 'row', overflow: 'hidden' }} columns={2}>
                <Grid.Column width={3} style={{ marginBottom: '0px', color: '#133F4F', fontWeight: '700', minWidth: '100px', display: 'flex', lineHeight: '22px',alignItems:'center' }} >
                  {columnName}: <div style={{ color: '#000000', fontWeight: '400',display: 'flex' }}>&nbsp;{text}{children}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          
        </SentCellResponsive>
        }        
    </>
  );
};

export const CellSubjectDesc = ({ text, subject, width = 0, columnName = '', onClick }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCell width={width} onClick={onClick}>
        <b>{subject}</b>
        <span>&nbsp;&nbsp;</span>
        {`${text}`}
      </SentCell>
      : <SentCellResponsive onClick={onClick}>
        <div style={{ width: '95vw', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} divided='vertically'>
            <Grid.Row className='row' style={{ width: '100%', display: 'flex', flexDirection: 'row', overflow: 'hidden' }} columns={2}>
              <Grid.Column width={16} style={{ marginBottom: '0px', color: '#133F4F', fontWeight: '700', minWidth: '100px', display: 'flex', lineHeight: '22px' }} >
                {columnName}: <div style={{ color: '#000000', fontWeight: '400' }}>&nbsp;<b>{subject}</b>&nbsp;{text}</div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>
    }
  </>
}
export const CellIconSelect = ({ onChange, checked,width=0 }) => {
  return <>
    {
      <SentCellIcon width={width}>
        <CheckBoxGeneric
          onChange={onChange}
          checked={checked}
        />
      </SentCellIcon>
    }
  </>
}
export const CellIconAttachment = ({ haveAttachments, onClick, width }) => {
  return <>
    {useWindowDimensions().width > 768 &&
      <SentCellIcon onClick={onClick} width={width}>
        <div style={{ maxHeight: '50px' }} >
          {haveAttachments && <Attached />}
        </div>
      </SentCellIcon>
    }
  </>
}
export const CellDelete = ({ haveAttachments = false, width = 3, isVisible, handleClickDelete, onChange, checked }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCell width={width} style={{ color: '#22C1D0', a: 'center' }}>
        {isVisible && <div onClick={handleClickDelete} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
          <Delete style={{ margin: '2px 6px 0px 0px' }} />
          <div>Eliminar</div>
        </div>}
      </SentCell>
      : <SentCellResponsive>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex' }} divided='vertically'>
            <Grid.Row className='row' style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: '0px' }} columns={1}>
              <Grid.Column width={16} style={{ display: 'flex', color: '#22C1D0', lineHeight: '17px', justifyContent: 'space-between' }} >
                <CheckBoxGeneric onChange={onChange} checked={checked} />
                <div style={{ maxHeight: '50px' }} >
                  {haveAttachments && <Attached />}
                </div>
                <div onClick={handleClickDelete} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
                  <Delete style={{ margin: '0px 6px 0px 0px' }} />
                  <div>Eliminar</div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>
    }
  </>
}


export const CellOptions = ({ isVisible, columnName, clickEdit, active, handleChangeInactive, handleChangeActive,paddLeft='0', width=3,
toggleDisabled ,customMargin=false}) => {
const [showDisabledMessage,setShowDisabledMessage] = useState(false)
const [positionMessage, setPositionMessage] = useState({top:'100px',left:'100px'})


const handleOnClickToogeDisable = (event) => {
  const rect = event.target.getBoundingClientRect();
  const topR = rect.top + window.scrollY;
  const leftR = rect.left + window.scrollX;

  setPositionMessage({ top: `${topR - 58}px`, left: `${leftR - 80}px` });

  setShowDisabledMessage(!showDisabledMessage);
};

  const handleMouseLeaveDisabled = () => {
    setShowDisabledMessage(false);
  };

  return <>
    {useWindowDimensions().width > 768 ?
      <SentCellOptions width={width} style={{ position: 'relative' }}>
        <div style={{display:'flex',justifyContent:'space-between',paddingLeft:paddLeft}}>
          {active === 'ACTIVE' ? toggleDisabled? 
          <div>
            {showDisabledMessage && (
              <ContainerDisabledMessage top={positionMessage.top} left={positionMessage.left}>
                <p>No puedes inactivarlo,</p>
                <p>usuario con vinculaciones asociadas</p>
              </ContainerDisabledMessage>
            )}
            <Disabled 
            id="DisabledButton"
              onClick={handleOnClickToogeDisable}
              onMouseLeave={handleMouseLeaveDisabled}
              style={{ marginTop: '5px', cursor: 'pointer' }}
            />
          </div>
          : 
          <Active style={{ cursor: 'pointer', marginTop: '5px'}} 
          onClick={active === 'INACTIVE' ? handleChangeActive : handleChangeInactive} />
            : <Inactive style={{ cursor: 'pointer' }} onClick={active === 'INACTIVE' ? handleChangeActive : handleChangeInactive} />}
          {isVisible ? (<Edit customMargin={customMargin} style={{ cursor: 'pointer' }} onClick={clickEdit} />):<div></div>}
        </div>
      </SentCellOptions>
      : <SentCellResponsive style={{ position: 'relative' }}>
        <div style={{  display: 'flex', justifyContent: 'space-between',marginInline:'15px' }}>
        {active === 'ACTIVE' ? toggleDisabled? 
          <div>
            {showDisabledMessage && (
              <ContainerDisabledMessage top={positionMessage.top} left={positionMessage.left}>
                <p>No puedes inactivarlo,</p>
                <p>usuario con vinculaciones asociadas</p>
              </ContainerDisabledMessage>
            )}
            <Disabled 
            id="DisabledButton"
              onClick={handleOnClickToogeDisable}
              onMouseLeave={handleMouseLeaveDisabled}
              style={{ marginTop: '5px', cursor: 'pointer' }}
            />
          </div>
          : 
          <Active style={{ cursor: 'pointer', marginTop: '5px'}} 
          onClick={active === 'INACTIVE' ? handleChangeActive : handleChangeInactive} />
            : <Inactive style={{ cursor: 'pointer' }} onClick={active === 'INACTIVE' ? handleChangeActive : handleChangeInactive} />}
          {isVisible ? (<Edit customMargin={customMargin} style={{ cursor: 'pointer' }} onClick={clickEdit} />):<div></div>}
        </div>
      </SentCellResponsive>
    }
  </>
}

export const CellEdit = ({ isVisible, clickEdit, text, columnName = '', inProgress = false }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <CellEditSuperAdmin width={3} style={!!inProgress ? { fontWeight: '600' } : { fontWeight: '100' }}>
        {`${text}`}
        {isVisible && (
          <Edit onClick={clickEdit} />
        )}
      </CellEditSuperAdmin>
      : <SentCellResponsive >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex' }} divided='vertically'>
            <Grid.Row className='row' style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: '0px' }} columns={3}>
              <Grid.Column width={3} style={{ color: '#133F4F', fontWeight: '700', minWidth: '100px' }} >
                {columnName}
              </Grid.Column>
              <Grid.Column width={8} style={!!inProgress ? { fontWeight: '600' } : { fontWeight: '100' }}>
                {text}
                {isVisible && (<Edit onClick={clickEdit} />)}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>


    }
  </>
}
export const CellDropDown = ({ className, columnName = '', attribute, attributesTypes, onChangeAttribute, name, id, index, isReadyOnly, isDisabled, inputLabel, validator, error }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCellDropDown width={3}>
        <ContainerDropDown>
          <InputFormDropdown
            className={className}
            options={attributesTypes}
            placeholder={'Seleccionar tipo de atributo'}
            value={attribute}
            onChangeAttribute={onChangeAttribute}
            name={name}
            id={id}
            index={index}
            validator={validator}
            error={error}
            validateOptions={'required'}
            readyOnly={isReadyOnly}
            disabled={isDisabled}
          />
          {inputLabel}
        </ContainerDropDown>
      </SentCellDropDown>
      :
        <SentCellResponsive>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex' }} divided='vertically'>
            <Grid.Row className='row' style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: '0px' }} columns={3}>
              <Grid.Column width={3} style={{ color: '#133F4F', fontWeight: '700', minWidth: '100px' }} >
                {columnName}
              </Grid.Column>
              <Grid.Column width={8} style={{ fontWeight: '100' }}>
                <ContainerDropDown>
                  <InputFormDropdown options={attributesTypes} placeholder={'Atributo'} value={attribute} onChangeAttribute={onChangeAttribute}
                    id={id} validator={validator} validateOptions={'required'} readyOnly={isReadyOnly} disabled={isDisabled} />
                  {inputLabel}
                </ContainerDropDown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>
    }
  </>
}

export const CellDropDownResponsive = ({ className, columnName = '', attribute, attributesTypes, onChangeAttribute, name, id, index, isReadyOnly, isDisabled, inputLabel, validator, error }) => {
  return <>
      <MassiveLoadResponsive>
        <ContainerDropDown>
          <InputFormDropdown options={attributesTypes} placeholder={'Atributo'} value={attribute} onChangeAttribute={onChangeAttribute}
            id={id} validator={validator} validateOptions={'required'} readyOnly={isReadyOnly} disabled={isDisabled} />
            {inputLabel}
        </ContainerDropDown>
      </MassiveLoadResponsive>
  </>
}

export const CellIcon = ({ onClick = null, text, width = 0, columnName = '',fontWeight, showIconFile = true }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCell width={width} onClick={onClick}>
        <Divider style={{fontWeight:fontWeight}}>
          <DividerIcons>
            {showIconFile ? <IconFile /> : <Massive/>}
          </DividerIcons>
          {`${text}`}
        </Divider>
      </SentCell>
      : <SentCellResponsive onClick={onClick}>
        <div style={{ width: '95vw', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} divided='vertically'>
            <Grid.Row columns={2}>
              <Grid.Column width={3} style={{ color: '#133F4F', fontWeight: '700', minWidth: '100px', display: 'flex',marginBottom: '0px' }} >
                <Divider>
                  <DividerIconsResponsive>
                  {showIconFile ? <IconFile /> : <Massive/>}
                  </DividerIconsResponsive>
                  {`${columnName}:`}&nbsp;
                </Divider>
                <div style={{ fontWeight: '400', color: 'rgb(0, 0, 0)' }}>
                  {text}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>
    }
  </>
}
export const CellIconMix = ({ fullNameRecipient, nameElement, width = 0, columnName = '', typeSearch }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCell width={width}>
        <Divider>
          {<>
            <DividerIcons>
              {typeSearch === "1" ? <IconFile /> : <IconAttachment />}
            </DividerIcons>
            {typeSearch === "1" ? fullNameRecipient : nameElement}
          </>}
        </Divider>
      </SentCell>
      : <SentCellResponsive>
        <div style={{ width: '95vw', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} divided='vertically'>
            <Grid.Row columns={1}>
              <Grid.Column width={16} style={{ marginBottom: '0px', color: '#133F4F', fontWeight: '700', minWidth: '100px', display: 'flex', lineHeight: '22px' }} >
                <DividerIconsResponsiveMix> {typeSearch === "1" ? <IconFile /> : <IconAttachment />}</DividerIconsResponsiveMix>
                {columnName}: <div style={{ color: '#000000', fontWeight: '400', whiteSpace: 'nowrap', textOverflow: 'ellipsis', 
                overflow: 'hidden' }}>&nbsp;{typeSearch === "1" ? fullNameRecipient : nameElement}</div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>
    }
  </>
}
export const CellIconStarred = ({typeSearch, onClick, active}) => {
  return <>
    {useWindowDimensions().width > 768 &&
      <SentCell width={1}>
        {typeSearch === "2" && <StarredButton onClick={onClick} active={active} />}
      </SentCell>
    }
  </>
}

export const CellPending = ({ status }) => {
  return <>
    {useWindowDimensions().width > 1024 ?
      <SentCell width={3}>
        <StatusContainer>
          {status === 'Pendiente' && <Label className={'labelDocument'} text={'Pendiente'} icon={3} color={'#E35F00'} />}
        </StatusContainer>
      </SentCell>
      : <SentCellResponsiveIcon>
        <div className='labelIcon' style={{ display: 'flex', paddingLeft: '1rem', color: '#133F4F', fontWeight: '700', minWidth: '125px', maxWidth: '125px' }}>
          {status === 'Pendiente' && <Label text={'Pendiente'} icon={3} color={'#E35F00'} />}
        </div>
      </SentCellResponsiveIcon>
    }
  </>
}
const statusInfo = {
  Pendiente: { text: 'Pendiente', icon: 3, color: '#E35F00' },
  Firmado: { text: 'Firmado', icon: 6, color: '#E35F00' },
  'No Leido': { text: 'No leído', icon: 1, color: '#0E3B52' },
  Leido: { text: 'leído', icon: 2, color: '#A4A4A4' },
  Rechazado: { text: 'Rechazado', icon: 4, color: '#BA3555' },
  Rebotado: { text: 'Rebotado', icon: 7, color: '#E49300' },
  Error: { text: 'Error', icon: 8, color: '#E35F00' },
  "No pendiente": { text: '', icon: 0, color: '' }
}

export const CellStatus = ({ status = '', typeSearch, width = 3}) => {
  const { text, icon, color } = statusInfo[status]
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCell width={width}>
        {status !== "No pendiente" &&
          <StatusContainer>
            <Label className={'labelDocument'} text={text} icon={icon} color={color} />
          </StatusContainer>}
      </SentCell>
      : <SentCellResponsiveIcon width={16}>

        <div className='labelIcon' style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '1rem', color: '#133F4F', fontWeight: '700', minWidth: '125px' }}>
          {status !== "No pendiente" && <Label className={'labelDocument'} text={text} icon={icon} color={color} />}
          {typeSearch === "2" &&
            <StarredButton active={false} noChange='default' />}
        </div>
      </SentCellResponsiveIcon>
    }
  </>
}

export const CellDownload = ({ haveAttachments = false, width = 3, isVisible, handleClickDownload, onChange, checked }) => {
  return <>
    {useWindowDimensions().width > 768 ?
      <SentCell width={width} style={{ color: '#22C1D0', a: 'center' }}>
        {isVisible && <div onClick={handleClickDownload} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
          <Download style={{ margin: '2px 6px 0px 0px' }} />
          <div>Descargar adjuntos</div>
        </div>}
      </SentCell>
      : <SentCellResponsive>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '100%', display: 'flex' }} divided='vertically'>
            <Grid.Row className='row' style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingBottom: '0px' }} columns={1}>
              <Grid.Column width={16} style={{ display: 'flex', color: '#22C1D0', lineHeight: '17px', justifyContent: 'space-between' }} >
                <CheckBoxGeneric onChange={onChange} checked={checked} />
                <div style={{ maxHeight: '50px' }} >
                  {haveAttachments && <Attached />}
                </div>
                <div onClick={handleClickDownload} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
                  <Download style={{ margin: '0px 6px 0px 0px' }} />
                  <div>Descargar adjuntos</div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </SentCellResponsive>
    }
  </>
}

export const CellReceipentDraft = ({ onChange, checked,data, handleClick }) => {
  return (
    <DraftReceipentCell width={4} onClick={handleClick} >
      <div style={{display:'flex',paddingLeft:'10px'}}>
        <CheckBoxGeneric
          onChange={onChange}
          checked={checked}
        />
        <div style={{paddingLeft:'16px' }} >
          {
            data.notificationDeliveryType === 'MASSIVE' ?  
              <Divider style={{fontWeight:700,whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                <DividerIcons>
                  { <Massive/>}
                </DividerIcons>
                {`${data.campaignName == undefined || data.campaignName == [] ? '(Sin destinatario)' :data.campaignName}`}
              </Divider>
            :
            <div style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 700,  }}>
              {data.recipients == undefined || data.recipients == [] ? '(Sin destinatario)' : data.recipients}
            </div>   
          }
        </div>
      </div>
    </DraftReceipentCell>
  )
}


export const CellAttatchedDraft = ({ handleClick,haveAttachments}) => {
  return (
    <DraftReceipentCell width={2} onClick={handleClick}>
      <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>

        <div style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#C3132E', fontWeight: 400, justifyContent: 'center' }}>
            {"Borrador"}
        </div>
        <div style={{ maxHeight: '50px' }} >
          {haveAttachments && <Attached />}
        </div>
      </div>
    </DraftReceipentCell>
  )
}

export const CellDateAndDeleteDraft = ({ handleClick,createdDate,formatDate,haveAttachments,handleSelectDraft,selectDraft,isVisible,handleClickDelete,status}) => {
  return (
    <DraftReceipentCell  width={3} onClick={handleClick}>
      <div style={{display:'flex',justifyContent:'space-between',paddingRight:'10px'}}>
        <div style={{ display: 'block', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', color: '#7C7C74', fontWeight: 400, }}>
              {formatDate(createdDate)}
        </div>
        {isVisible ?
          <div onClick={handleClickDelete} style={{ display: 'flex', cursor: 'pointer', fontSize: '14px' }}>
            <Delete style={{ margin: '2px 6px 0px 0px' }} />
            <div style={{ color: '#22C1D0', a: 'center' }}>Eliminar</div>
          </div>
          :<div></div>
          }
      </div>
    </DraftReceipentCell>
  )
}


