import React from "react"
import PropTypes from "prop-types"
import { Checkbox } from "semantic-ui-react"
import styled from "styled-components"

const CustomCheckbox = styled(Checkbox)`

  padding-right: 10px !important;

  label{
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 17px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
   // line-height: 24px;
  }

  @media screen and (max-width:768px){
    line-height: 24px;
    label {
      font-size: 12px !important;
      line-height: 17px;
    }
    
  }
  @media screen and (max-width:1500px){
    //margin-top:15px;
  }

  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    // background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    // border: 2px solid #22C1D0 !important;
    // width: 18px;
    // height: 22px;
  }
 
  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label{
    opacity: 0.3 !important;
  }

  padding-right: 10px !important;

//   .ui.checkbox .box:after, .ui.checkbox label:after {
//     size: 12px
// }
`

const CheckboxForm = ({ ...props }) => {
  const { placeholder, id, onChange, value, label, disabled, validator, validateOptions, error, name, classNameDiv, classNameCheck } = props
  let errorValidator = validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions)
  return (
    <>
      <div className={`${classNameDiv} ${error || errorValidator ? 'inline field' : ''} ${disabled ? 'disabled' : ''}`} >
        <CustomCheckbox
          id={id}
          label={label}
          onChange={onChange}
          checked={value}
          disabled={disabled}
          name={name}
          className={classNameCheck}
        />
        {error ?
          <div className="ui red left pointing basic label">{error}</div>
          : null
        }
        {!disabled && errorValidator ?
          <div className="ui red left pointing basic label">{errorValidator}</div>
          : null
        }
        <br />
      </div>
    </>
  )
}


CheckboxForm.propTypes = {
  labelProps: PropTypes.object,
  id: PropTypes.string,
}
export default CheckboxForm
