import React, { useEffect, useRef, useState } from 'react'
import { Popup, Image, Grid } from "semantic-ui-react"
import styled from "styled-components"
import cogoToast from '@successtar/cogo-toast';
import { toastOptions } from "../../helpers/globalHelpers"
import { logoutUser, changeRole, showChangeRoleModal } from "../../actions/authActions"
import ButtonWhite from "./menu/ButtonWhite"
import { useDispatch, useSelector } from 'react-redux'
import userImg from "../../assets/icons/user.png"
import singnOff from "../../assets/icons/sign-off.png"
import { deleteLoadNotificationItemV2 } from '../../actions/writerActions'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { STATE_NOTIFICATION_PROGRESS, STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION } from '../../actions/actionsUtilities/types';

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media only screen and (max-width: 768px) {
    display:none;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 16px;
`
const Name = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 16px;
  color: #FFFFFF;
  padding-top: 8px;
`
const NameCompany = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-transform: capitalize !important;
`
const PopupUser = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0 !important;
  :before{
    z-index: 0 !important;T
  }
`

const LineBorder = styled.hr`
  width:92%;
  height:0px;
  margin: auto;
  background-color: #B2B2B;
  margin-top:10px;
`

const DropDownName = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #133F4F;
  text-align: center;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`

const DropDownText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6D6E71;
  text-align: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`
const FullEmailtext = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: auto;
  box-shadow: 0px 4px 16px 0px #00000040;
  background: white;
  border-radius:4px;
  height: 34px;
  padding: 10px;
  position: absolute;
  top: -50px;
`

const ButtonOption = styled.button`
  color: #7C7C74;
  cursor: pointer;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 16px;
  height: 24px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-top: 6px
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 16px;
  padding-left: 24px
`
const ContainerOption = styled.div`
  padding: 16px 16px !important;
`

export const MenuUser = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(state => state.authReducer.user)
  const typeOfLogin = useSelector(state => state.authReducer.typeOfLogin)
  const companyID = useSelector(state => state.authReducer.user.companyID)
  const resultNotificationsProgress = useSelector(state => state.writerReducer.resultNotificationsProgress)
  const actionsRunning = useSelector(state => state.authReducer.actionsRunning)

  const [customState, setCustomState] = useState({
    show: false,
    showFullEmail: false,
    emailisCut: false,
  })

  const onLogout = async (e) => {
    e.preventDefault()
    await deleteNotificationSentRecords()
    dispatch({type: STATE_NOTIFICATION_PROGRESS, payload: []})
    dispatch({ type: STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION, payload: []})
    dispatch(logoutUser(history))
    cogoToast.info('Sesión finalizada exitosamente', toastOptions)
  }

  const deleteNotificationSentRecords = async () => {
    const statusList = ['Completado', 'Incompleto', 'No cargado', 'Error']
    const body = {
      fileSerialIDList: resultNotificationsProgress.progress.filter((item) => statusList.includes(item.state)).map(item => item.fileSerialID),
      newVisibility: false
    }
    if (body.fileSerialIDList.length > 0) {
      await dispatch(deleteLoadNotificationItemV2(body))
    }
  }

  const cutName = (name) => {
    let [beforeAtSign, afterAtSign] = name.split("@");
    if (beforeAtSign.length > 18) {
      beforeAtSign = beforeAtSign.substring(0, 15) + "...";

      customState.emailisCut == false && setCustomState((prevState) => ({ ...prevState, emailisCut: true }));
    }
    if (afterAtSign.length > 13) {
      afterAtSign = afterAtSign.substring(0, 13) + "...";
      customState.emailisCut == false && setCustomState((prevState) => ({ ...prevState, emailisCut: true }));
    }
    return beforeAtSign + '@' + afterAtSign;
  }

  const handleHoverEmail = () => {
    setCustomState((prevState) => ({ ...prevState, showFullEmail: true }))
  }
  const handleHoverExitEmail = () => {
    setCustomState((prevState) => ({ ...prevState, showFullEmail: false }))
  }

  const handleChangeRole = async (id, role) => {
    // await this.addActionToList()
    if (actionsRunning.length > 0) {
      dispatch(showChangeRoleModal(actionsRunning.length > 1))
    } else {
      if (user.role !== role) {
        dispatch(changeRole(history, { userSerialID: id }))
        setCustomState((prevState) => ({ ...prevState, show: false }))
      }
    }
  }

  const btnRef = useRef();
  const ref = useRef(null);
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && !btnRef.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickIcon = (e) => {
    if (ref.current && ref.current.contains(e.target)) return
    if (btnRef.current.contains(e.target)) setIsComponentVisible(!isComponentVisible)
  }

  return (
    <Divider >
      <Container>
        {
          user.firstName !== undefined && user.lastName !== undefined
          && <Name>{user.firstName} {user.lastName}</Name>
        }
        {
          companyID !== undefined && <NameCompany>{companyID.name}</NameCompany>
        }
        {
          user.roleName !== undefined && <NameCompany>{user.roleName}</NameCompany>
        }
      </Container>
      <PopupUser trigger={
        <div ref={btnRef}>
          <Image src={userImg}
            style={{ width: '40.77px', paddingTop: '4px', cursor: 'pointer', zIndex: '1801' }}
            onClick={handleClickIcon} />

        </div>
      }
        position='bottom right' on='click' open={isComponentVisible} >
        <div ref={ref}>

          <Grid style={{ width: '270px' }}>
            <Grid.Column style={{ padding: '10px 11.5px 10px 11.7px', borderRadius: '10px' }}>
              {
                user.firstName !== undefined && user.lastName !== undefined ?
                  (props.documentNumber !== undefined) ?
                    <>
                      <DropDownName>{user.firstName} {user.lastName}</DropDownName>
                      <DropDownText>C.C {user.documentNumber}</DropDownText>
                      <DropDownText>{cutName(user.email)}</DropDownText>
                      {customState.showFullEmail && customState.emailisCut &&
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                          <FullEmailtext >
                            {user.email}
                          </FullEmailtext>
                        </div>}
                    </>
                    :
                    <>
                      <DropDownName>{user.firstName} {user.lastName}</DropDownName>
                      <DropDownText onMouseOver={handleHoverEmail} onMouseLeave={handleHoverExitEmail} >
                        {cutName(user.email)}
                      </DropDownText>
                      {customState.showFullEmail && customState.emailisCut &&
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                          <FullEmailtext >  {user.email} </FullEmailtext>
                        </div>}

                    </>
                  : ''
              }
              <LineBorder />
              {!!user.roles && user.roles.length >= 1 && typeOfLogin === 'withCertificate' &&
                <>
                  <ContainerOption>
                    <DropDownName style={{
                      justifyContent: 'flex-start', display: 'flex', padding: '0px 0px', fontSize: '16px',
                      fontWeight: '400'
                    }}>Tus roles</DropDownName>
                    {user.roles.map(({ userSerialID, role, roleName }) =>
                      <ButtonOption key={userSerialID} className='buttonOptionHover'
                        onClick={() => handleChangeRole(userSerialID, role)}>
                        {user.role === role && <Reviewed style={{ margin: '1px 6px 0 0' }} />}
                        {user.role !== role ? <Text>{roleName}</Text> : roleName}
                      </ButtonOption>
                    )}
                  </ContainerOption>
                  <LineBorder style={{ marginTop: '0px' }} />
                </>
              }
              <ButtonWhite icon={singnOff} value={{ title: 'Cerrar sesión' }} onClick={onLogout} endLine={true}
                color={'#22C1D0'} />
            </Grid.Column>
          </Grid>
        </div>

      </PopupUser>

    </Divider>
  )
}

export default MenuUser
