import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionFilterDraft, actionFilterReports } from '../../actions/filterActions'
import { actionFilterLogs } from '../../actions/filterDetailActions'
import ButtonIcon from '../Button/IconButton'
import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import _ from 'lodash'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 210px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    position: sticky !important;
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);

`

const PopUpFilterDetailGeneric = (props) => {

  const agreementStatusReducer = useSelector(state => state.filterReducer.reportsFilter.reportStatus)
  const draftsStatus = useSelector(state => state.filterReducer.draftsFilter.draftsStatus)

  const [show, setShow] = useState(false)
  const [notificationState, setNotificationState] = useState('')
  const [starred, setStarred] = useState(false)
  const [reportType, setReportType] = useState('')
  const [logAction, setLogAction] = useState('')
  const [changeAdvanced, setChangeAdvanced] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.options && props.options === 'logs') {

      if (!changeAdvanced) {
        dispatch(actionFilterLogs(logAction))
      } else {
        setChangeAdvanced(false)
      }

    }

  }, [logAction])

  useEffect(() => {
    if (props.options && props.options === 'reports') {

      if (!changeAdvanced) {
        dispatch(actionFilterReports(reportType))
      } else {
        setChangeAdvanced(false)
      }

    }

  }, [reportType])

  useEffect(() => {
    dispatch(actionFilterDraft(notificationState))
  }, [draftsStatus, notificationState])

  const toggle = () => {
    setShow(!show)
  }

  const changeStatus = (status) => {
    if (status === 'STARRED') {
      setNotificationState(status)
      setStarred(true)
    } else {
      setNotificationState(status)
      setStarred(false)
    }
    setShow(!show)
  }

  const changeReportStatus = (status) => {
    setReportType(status)
    setStarred(false)
    setShow(!show)
  }

  const changeLogsAction = (status) => {
    setLogAction(status)
    setStarred(false)
    setShow(!show)
  }

    return (
      <Divider>
        <PopupFilter position='bottom left' on='click' pinned open={show} onClose={toggle} trigger={
          <ButtonIcon onClick={toggle}>
            <Texto> Filtrar </Texto>
            <Filter />
          </ButtonIcon>}>

          {props.options && props.options === 'reports' ?
            <ContainerOption style={{ top: filterPosition }}>
              <ButtonOption className='buttonOptionHover' onClick={() => changeReportStatus('STATUS')}>
                {agreementStatusReducer === 'STATUS' && <Reviewed className='iconReReviewed' />}
                {agreementStatusReducer !== 'STATUS' ? <Text>Reporte de estado</Text> : 'Reporte de estado'}
              </ButtonOption>
              <ButtonOption className='buttonOptionHover' onClick={() => changeReportStatus('WRONG_NOTIFICATION')}>
                {agreementStatusReducer === 'WRONG_NOTIFICATION' && <Reviewed className='iconReReviewed' />}
                {agreementStatusReducer !== 'WRONG_NOTIFICATION' ? <Text>Notificaciones Erróneas</Text> : 'Notificaciones Erróneas'}
              </ButtonOption>
              <ButtonOption className='buttonOptionHover' onClick={() => changeReportStatus('LOG')}>
                {agreementStatusReducer === 'LOG' && <Reviewed className='iconReReviewed' />}
                {agreementStatusReducer !== 'LOG' ? <Text>Log de trazabilidad</Text> : 'Log de trazabilidad'}
              </ButtonOption>
            </ContainerOption>
            : props.options && props.options === 'logs' ?
              <ContainerOption>
                <ButtonOption className='buttonOptionHover' onClick={() => changeLogsAction('CREATE')}>
                  {logAction === 'CREATE' && <Reviewed className='iconReReviewed' />}
                  {logAction !== 'CREATE' ? <Text>Creación</Text> : 'Creación'}
                </ButtonOption>
                <ButtonOption className='buttonOptionHover' onClick={() => changeLogsAction('UPDATE')}>
                  {logAction === 'UPDATE' && <Reviewed className='iconReReviewed' />}
                  {logAction !== 'UPDATE' ? <Text>Edición</Text> : 'Edición'}
                </ButtonOption>
                {props.agreementsLogs &&
                  <ButtonOption className='buttonOptionHover' onClick={() => changeLogsAction('INACTIVE')}>
                    {logAction === 'INACTIVE' && <Reviewed className='iconReReviewed' />}
                    {logAction !== 'INACTIVE' ? <Text>Anulación</Text> : 'Anulación'}
                  </ButtonOption>

                }
                {props.showCleanFilter &&
                  <ButtonOption className='buttonOptionHover' onClick={() => changeLogsAction('')}>
                    {logAction === '' && <Reviewed className='iconReReviewed' />}
                    {logAction !== '' ? <Text>Limpiar filtro</Text> : 'Limpiar filtro'}
                  </ButtonOption>}
              </ContainerOption>
              :
              <ContainerOption>
                <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('ADJUNTOS')}>
                  {notificationState === 'ADJUNTOS' && <Reviewed className='iconReReviewed' />}
                  {notificationState !== 'ADJUNTOS' ? <Text>Con archivos adjuntos</Text> : 'Con archivos adjuntos'}
                </ButtonOption>
                <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('NOADJUNTOS')}>
                  {notificationState === 'NOADJUNTOS' && <Reviewed className='iconReReviewed' />}
                  {notificationState !== 'NOADJUNTOS' ? <Text>Sin archivos adjuntos</Text> : 'Sin archivos adjuntos'}
                </ButtonOption>
                <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('MASIVO')}>
                  {notificationState === 'MASIVO' && <Reviewed className='iconReReviewed' />}
                  {notificationState !== 'MASIVO' ? <Text>Envío masivo</Text> : 'Envío masivo'}
                </ButtonOption>
                {props.showFeatured &&
                  <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('DESTACADO')}>
                    {notificationState === 'DESTACADO' && <Reviewed className='iconReReviewed' />}
                    {notificationState !== 'DESTACADO' ? <Text>Destacada</Text> : 'Destacada'}
                  </ButtonOption>}
                {props.showCleanFilter &&
                  <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('')}>
                    {notificationState === '' && <Reviewed className='iconReReviewed' />}
                    {notificationState !== '' ? <Text>Limpiar filtro</Text> : 'Limpiar filtro'}
                  </ButtonOption>}
              </ContainerOption>
          }
        </PopupFilter>
      </Divider>
    )
}


export default PopUpFilterDetailGeneric
