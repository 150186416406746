import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import GeneralTable from '../../../../../components/Table/GeneralTable'
import RowTableImproperClientsDetail from '../RowTableImproperClientsDetail'
import { actionClearFilter } from "../../../../../actions/filterActions";
import { getImproperClientDetail } from '../../../../../actions/improperClientsActions'
import { activePagination } from '../../../../../actions/filterActions'
import { useDispatch, useSelector } from 'react-redux';

const AllImproperClientsDetail = (props) => {

  const filterReducer = useSelector(state => state.filterReducer)
  const improperClientsDetailArray = useSelector(state => state.improperClientsReducer.improperClientDetail.notifications)

  const [loader, setLoader] = useState(true)
  const [columnData, setColumnData] = useState([
    { name: "Cédula destinatario ", width: 3, arrow: false },
    { name: "Asunto", width: 7, arrow: false },
    { name: "Fecha de reporte", width: 3, arrow: false },
  ])
  const [initialState, setInitialState] = useState(true)
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(actionClearFilter())
    makeImproperClientsDetailRequest(filterReducer, initialState)
  }, [])

  useEffect(() => {
    if (!initialState) {
      makeImproperClientsDetailRequest(filterReducer)
      setLoader(true)
      setInitialState(true)
    }
  }, [filterReducer])


  const makeImproperClientsDetailRequest = (filterReducer, initialState = false) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      idClient: props.idClient,
      textToSearch: filterReducer.searchBar[0]
    }
    dispatch(getImproperClientDetail(body)).then(() => {
      dispatch(activePagination(false))
      setLoader(false)
      setInitialState(false)
    })
  }

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "firstName": 1 },
      'Z-A': { "firstName": -1 }
    }
    return options[order]
  }
  return (
    <GeneralTable
      list={improperClientsDetailArray}
      typeList="improperClients"
      headerTable={columnData}
      rowComponent={RowTableImproperClientsDetail}
      loader={loader}
    />
  )

}

export default AllImproperClientsDetail