import React, { Component } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import CreateClients from './CreateClients'

const AddClients = () => {

    return (
      <MainSegment>
        <CreateClients />
      </MainSegment>
    )
}
export default AddClients