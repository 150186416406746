import React from 'react'
import EditorQuill from '../EditorQuill/EditorQuill'

const RichEditor = (props) => {
  return (
    <EditorQuill 
      history={props.history}
      stateFormValidator={props.stateFormValidator}
      validateSubject={props.validateSubject}
    />
    )
}

export default RichEditor