import React from 'react'
import { SearchBarGenericButtonType } from '../../SearchBarGeneric'
import PopUpFilterAgreements from '../../../Search/SearchAgreements/PopUpFilterAgreements';

export const ContainerSearchAgreementsClient = ({ showAZ, reportType, providerType, disabledExportButton, customWidthDiv2, showAdvancedSearch, loader }) => {
  return (
    <SearchBarGenericButtonType
      showButton={false}
      textPlaceHolder={'Código de la vinculación'}
      searchBarState={''}
      showAZ={showAZ}
      dataFilter={<PopUpFilterAgreements reportType={reportType} providerType={providerType} />}
      showExportButton={true}
      disabledExportButton={disabledExportButton}
      customWidthDiv2={customWidthDiv2}
      showAdvancedSearch={showAdvancedSearch}
      loader={loader}
    />
  )
}


export default ContainerSearchAgreementsClient
