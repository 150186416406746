import  Cookies from 'universal-cookie';
import { HTTP_ONLY } from "../config/config"

export const createCookie = (value) => { 
    const cookies = new Cookies();
    const newDate = new Date();
    const sessionSum = 50;
    const minutes = newDate.getMinutes();
    newDate.setMinutes(minutes + sessionSum);

    Object.entries(value).forEach(([key, val]) => {
        cookies.set(key, val, {
            path: '/',
            httpOnly: HTTP_ONLY,
            secure: true,
            sameSite: 'strict',
            expires: 0
        })
    });
}

export const getCookie = (name) => {
    const cookies = new Cookies();
    return cookies.get(name)
}

export const deleteCookies = () => {
    const cookies = new Cookies();
    Object.entries(cookies.getAll()).forEach(([key, val]) => {
        cookies.remove(key,{
            path: '/',
            httpOnly: HTTP_ONLY,
            secure: true,
            sameSite: 'strict',
            expires: 0
        })
    });
}

