import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux"
import UsersMenu from '../MenuBar/menu/UsersMenu'
import Configuration from '../MenuBar/Configuration'
import { ReactComponent as MenuRes } from '../../assets/icons/menu/menu-responsive-icon.svg'
import { ReactComponent as IconClose } from '../../assets/icons/report/iconCloseDark.svg'
import { Image, Popup } from "semantic-ui-react"
import userIcon from "../../assets/icons/user.png"
import singnOff from "../../assets/icons/sign-off.png"
import { logoutUser, changeRole, showChangeRoleModal } from "../../actions/authActions"
import ButtonWhite from "../MenuBar/menu/ButtonWhite"
import cogoToast from '@successtar/cogo-toast';
import { toastOptions } from "../../helpers/globalHelpers"
import MessageModal from '../Modal/MessageModal'
import ReportModalLoading from '../ModalReport/ReportModalLoading'
import ModalMassiveIncomplete from '../ModalMassiveLoad/ModalMassiveIncomplete'
import MessageModalTwo from '../Modal/MessageModalTwo'
import { chageStateLoadNotificationsList, chageStateLoadCitizensList } from '../../actions/writerActions';
import { hideMessageModal } from '../../actions/modalActions';
import IdleTimerComponentsC from '../IdelComponent/IdleTimerComponentsC'
import { setPathnameCurrent } from '../../actions/menuActions'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import MessageModalChangeRole from '../Modal/MessageModalChangeRole'
import {
  getToBeNotified, setIntervalToBeNotified, changeActiveInterval,
  changeListCreateReport
} from '../../actions/reportsActions'
import { ToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import { ClickAwayListener } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions'

const HorizontalContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  position: relative;
`
const LeftMenuBar = styled.div`
  width: 15%;
  min-height: 88vh;
  background: #E4E4E4;
  @media only screen and (max-width: 1330px){
    width:96px;
  }

  @media only screen and (max-width: 768px) {
    width: 35%;
    position: fixed;
    z-index: 1750;
    width: 30vw;
    min-height: 100vh;
    top: 0;
    right: 0;
  }

  @media only screen and (max-width: 768px) {
    width: 60vw;
  }

  @media only screen and (max-width: 425px) {
    width: 80vw;
  }
`
const Content = styled.div`
  width: 70%;
  @media only screen and (max-width: 1500px) {
    width: 85%;
  }
  @media only screen and (max-width: 1330px) {
    width: calc(100% - 96px);
  }
    @media only screen and (max-width: 768px) {
      width:100%;
    }
`
const ConfigurationContent = styled.div`
  width: 15%;
  background: rgba(0, 0, 0, 0.03);
  @media only screen and (max-width: 1500px) {
    display:none;
  }
`

const MenuResIcon = styled(MenuRes)`
  display:none;
  position: absolute;
  right: 15px;
  top: -38px;
  z-index:1901;
  cursor: pointer;
  @media only screen and (max-width: 768px)  {
    display:block;
  }
`

const ContainerMenu = styled.div`
  display: contents;
`

const ContainerDataUser = styled.div`

  position: relative;
  display: none;
  flex-direction: column;
  align-items: start;
  width:100%;
  background: #133F4F;
  @media only screen and (max-width: 768px)  {
    display: -webkit-inline-box;
    padding: 0px 0px 25px 22px;
  }
`

const Name = styled.p`
  width: 90%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
  color: white;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size:14px;
  }
`
const NameCompany = styled.p`
  width: 90%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: white;
  text-transform: capitalize !important;
  margin: 0;

  @media only screen and (max-width: 800px) {
    font-size:14px;
  }
`

const UserIcon = styled(Image)`
  z-index: 1801;
`

const ContainerName = styled.div`
  padding-left:10px;
  margin-top: 2px;
  width: 80%;
`
const ContainerButtonWhite = styled.div`
  border-top: 1px solid #979E9F;
  display: none;
  position: absolute;
  height: 70px;
  bottom:0;
  width: 100%; 
  margin-top: 10px !important;
  background: #E4E4E4; 
  @media only screen and (max-width: 768px)  {
    display: inline-flex !important;
  }
`
const OpacityContainer = styled.div`
  position: fixed;
  width:100vw;
  height:100vh;
  background: black;
  opacity: 0.7;
  z-index: 1749;
  @media only screen and (min-width: 769px)  {
    display: none !important;
  }
`

const PopUpUserInfo = styled.div`
  padding:16px;
  position: absolute;
  width: calc(100% - 32px);
  background: white;
  position: absolute; /* Cambia a posición absoluta */
  top: 50px;
  left: 16px;
  border-radius:4px;
  z-index: 1750;
  box-shadow: 0px 5px 10px 0px #0000004D;

   &::after {
    position: absolute;
    content: '';
    width: 0.71428571em;
    height: 0.71428571em;
    top: -0.30714286em;
    bottom: auto;
    left: 20px;
    margin-left: 0;
    background: #fff;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`

const DropDownName = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #133F4F;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px !important;

`

const DropDownText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6D6E71;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LineBorder = styled.hr`
  height:0px;
  margin: auto;
  background-color: #B2B2B2;
  margin-top: 16px;
`
const ContainerOption = styled.div`
`
const ButtonOption = styled.button`
  color: #7C7C74;
  cursor: pointer;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 16px;
  height: 24px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 16px;
  padding-left: 24px
`

const ContainerWarningMessage = styled.div`
  background: #E8FDFE;
  min-width: 293px;
  width:100%;
  max-height: 40px;
  height: 40px;
  border: 1px solid #4E8D93;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  padding: 12px 24px 12px 24px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;
const ContainerText = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  gap: 3px;
`;

const ContainerTextOne = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0px;
  text-align: left;
  color: #133F4F;
`;
const ContainerTextTwo = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  /*text-decoration-line: underline;*/
  color: #133F4F;
`;

const IconCloseDark = styled(IconClose)`
  cursor: pointer;
}
`;

const ContainerListWarningMessage = styled.div`
  min-width: 293px;
  width: min-content;
  height: auto;
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
const Blurred = styled.div`
  width: 100%;
  height: 100vh;
  background: rgb(0,0,0, 0.7);
  z-index: 1800;
  position: absolute;
`
const PopupUser = styled(Popup)`
  padding: 22px !important;
  position:relative;
  font-size:16px !important;
  font-weight: 500 !important;
  gap: 16px; 
  border-radius: 4px 0px 0px 0px;
  margin: 50px 0 0 0;
  opacity: 0px;
  border: 0px !important;
  background: #0091F9 !important;
  color: #FFFFFF !important;
  width: 328px;
  max-width: unset !important;
  height: 67px;
  z-index: 1900;
  top: 39px !important;
  right: 5px !important;
  :before{
      background: #0091F9 !important;
  }
`

export const AppContent = ({ menuRoutes, children }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  var { width } = UseWindowDimensions()

  const { user, actionsRunning, typeOfLogin, showAvatarInfo } = useSelector((customState) => customState.authReducer)
  const { activeInterval, isCreateReport, toBeNotifiedList } = useSelector((customState) => customState.reportsReducer)

  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    setShowMessage(showAvatarInfo)
  }, [showAvatarInfo])

  const [customState, setCustomState] = useState({
    isView: false,
    classCurrent: 'hide-menu reset-menu',
    showInfouser: false,
    emailisCut: false,
    showFullEmail: false,
    isAdmin: false,
    showMessage: false,
    checked: false
  })

  useEffect(() => {
    dispatch(hideMessageModal())
    setCustomState((prevState) => ({ ...prevState, showMessage: showAvatarInfo }))
    if (user.role === "61b36e2fc34e22351bf8cfb4") {
      setCustomState((prevState) => ({ ...prevState, isAdmin: true }))
      dispatch(changeActiveInterval(true))
    } else {
      setCustomState((prevState) => ({ ...prevState, isAdmin: false }))
    }
  }, [])

  useEffect(() => {
    if (!!activeInterval) {
      dispatch(setIntervalToBeNotified(setInterval(() => { dispatch(getToBeNotified()) }, 30000)))
    }
  }, [activeInterval])

  const changeIsView = () => {
    const isview = customState.isView
    validateClass(!isview)
    setCustomState((prevState) => ({ ...prevState, showInfouser: prevState.isView }))
    setCustomState((prevState) => ({ ...prevState, isView: !prevState.isView }))
  }

  const refreshIsView = () => {
    setCustomState((prevState) => ({ ...prevState, isView: false }))
    validateClass()
  }

  const redirect = (component) => {
    history.push({ pathname: component.component })
    dispatch(setPathnameCurrent(history.location.pathname))
    dispatch(chageStateLoadNotificationsList(false))
    dispatch(chageStateLoadCitizensList(false))
  }

  const onLogout = e => {
    e.preventDefault()
    dispatch(logoutUser(history))
    cogoToast.info('Sesión finalizada exitosamente', toastOptions)
  }

  const validateClass = (validate) => {
    if (!!validate) {
      setCustomState((prevState) => ({ ...prevState, classCurrent: 'show-menu' }))
    } else {
      setCustomState((prevState) => ({ ...prevState, classCurrent: 'hide-menu' }))
      setTimeout(() => {
        setCustomState((prevState) => ({ ...prevState, classCurrent: 'hide-menu reset-menu' }))
      }, 300)
    }
  }

  const handleClickUserIcon = () => {
    setCustomState({ ...customState, showInfouser: !customState.showInfouser })
  }

  const cutName = (name) => {
    let [beforeAtSign, afterAtSign] = name.split("@");
    if (beforeAtSign.length > 18) {
      beforeAtSign = beforeAtSign.substring(0, 15) + "...";
      customState.emailisCut == false && setCustomState({ ...customState, emailisCut: true });
    }
    if (afterAtSign.length > 13) {
      afterAtSign = afterAtSign.substring(0, 13) + "...";
      customState.emailisCut == false && setCustomState({ ...customState, emailisCut: true });
    }
    return beforeAtSign + '@' + afterAtSign;
  }

  const handleChangeRole = (id, role) => {
    if (actionsRunning.length > 0) {
      showChangeRoleModal(actionsRunning.length > 1)
    } else {
      if (user.role !== role) dispatch(changeRole(history, { userSerialID: id }))
    }
    refreshIsView()
  }

  const redirectReportList = (closeToast) => {

    if (history.location.pathname === '/reports') {
      dispatch(changeListCreateReport(!isCreateReport))
    } else {
      history.push('/reports')
    }
    closeToast()

  }

  const createToast = (closeToast, reportType, reportDate) => {

    return (
      <ContainerWarningMessage>
        <ContainerText>
          <ContainerTextTwo onClick={() => redirectReportList(closeToast)}>
            {reportType === "STATUS_SINGLE" ?
              `Reporte_de_estado_${reportDate}`
              :
              `Reporte_notificaciones_erróneas_${reportDate}`
            }
          </ContainerTextTwo>
          <ContainerTextOne>
            generado correctamente
          </ContainerTextOne>
        </ContainerText>
        <IconCloseDark onClick={closeToast} />
      </ContainerWarningMessage>
    )
  }

  return (
    <>
      <IdleTimerComponentsC history={history} />
      <OpacityContainer style={!!customState.isView ? { display: 'block' } : { display: 'none' }} />
      <HorizontalContent >
        {customState.classCurrent !== 'show-menu' && width <= 768 && showMessage &&
          <Blurred>
            <div style={{ margin: '50px' }}>
              <PopupUser style={{ position: 'relative' }} position='bottom right' open={true}>
                Toca en el menú para ver las opciones.
              </PopupUser>

            </div>
          </Blurred>
        }

        <MessageModal history={history} />
        <MessageModalChangeRole history={history} />
        <ReportModalLoading history={history} />
        <ModalMassiveIncomplete />

        <ClickAwayListener onClickAway={refreshIsView}>
          <ContainerMenu>
            <MenuResIcon style={!!customState.isView ? { display: 'none' } : {}} onClick={changeIsView} />
            <LeftMenuBar className={customState.classCurrent} id='LeftMenuBar'>
              <UsersMenu
                menuRoutes={menuRoutes}
                redirect={redirect}
                history={history}
                url={window.location.pathname}
                changeView={changeIsView}
                menuIsOpen={customState.classCurrent === 'show-menu'}
                dataUser={
                  <ContainerDataUser>
                    <UserIcon src={userIcon} onClick={handleClickUserIcon}></UserIcon>
                    {
                      customState.showInfouser &&
                      <PopUpUserInfo>
                        {
                          user.firstName !== undefined && user.lastName !== undefined ?
                            (user.documentNumber !== undefined) ?
                              <>
                                <DropDownName>{user.firstName} {user.lastName}</DropDownName>
                                <DropDownText>C.C {user.documentNumber}</DropDownText>
                                <DropDownText>{cutName(user.email)}</DropDownText>
                                {customState.showFullEmail && customState.emailisCut &&
                                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                    <FullEmailtext >
                                      {user.email}
                                    </FullEmailtext>
                                  </div>}
                              </>
                              :
                              <>
                                <DropDownName>{user.firstName} {user.lastName}</DropDownName>
                                <DropDownText>C.C {user.documentNumber}</DropDownText>
                                <DropDownText  >
                                  {cutName(user.email)}
                                </DropDownText>
                                {customState.showFullEmail && customState.emailisCut &&
                                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                    <FullEmailtext >
                                      {user.email}
                                    </FullEmailtext>
                                  </div>}

                              </>
                            : ''
                        }
                        <LineBorder />

                        {!!user.roles && user.roles.length >= 1 && typeOfLogin === 'withCertificate' &&
                          <>
                            <ContainerOption>
                              <DropDownName style={{
                                justifyContent: 'flex-start', display: 'flex', padding: '0px 0px', fontSize: '16px',
                                fontWeight: '400'
                              }}>Tus roles</DropDownName>
                              {user.roles.map(({ userSerialID, role, roleName }) =>
                                <ButtonOption key={userSerialID} className='buttonOptionHover'
                                  onClick={() => handleChangeRole(userSerialID, role)}>
                                  {user.role === role && <Reviewed style={{ margin: '1px 6px 0 0' }} />}
                                  {user.role !== role ? <Text>{roleName}</Text> : roleName}
                                </ButtonOption>
                              )}
                            </ContainerOption>
                            <LineBorder />
                          </>
                        }

                      </PopUpUserInfo>
                    }

                    <ContainerName>
                      {
                        user.firstName !== undefined && user.lastName !== undefined
                        && <Name>{user.firstName} {user.lastName}</Name>
                      }
                      {
                        user.companyID !== undefined && <NameCompany>{user.companyID.name}</NameCompany>
                      }
                      {
                        user.roleName !== undefined && <NameCompany>{user.roleName}</NameCompany>
                      }
                    </ContainerName>
                  </ContainerDataUser>
                }
              />
              <ContainerButtonWhite>
                <ButtonWhite icon={singnOff} value={{ title: 'Cerrar sesión' }} onClick={onLogout}
                  endLine={true} color={'#22C1D0'} />
              </ContainerButtonWhite>
            </LeftMenuBar>
          </ContainerMenu>
        </ClickAwayListener>
        <Content>
          {children}

        </Content>
        <ConfigurationContent>
          <Configuration />
        </ConfigurationContent>
        <MessageModalTwo />
        <ContainerListWarningMessage>
        </ContainerListWarningMessage>
        <ContainerListWarningMessage>
          {
            !!customState.isAdmin && !!toBeNotifiedList ?
              toBeNotifiedList.filter(item => item.reportStatus !== "PROCESSING" && !!item.visible).map((item, index) => (
                toast((props) => createToast(props.closeToast, item.reportType, item.reportDate), { delay: (index * 1000) }),
                item.visible = false
              ))
              :
              ''
          }
        </ContainerListWarningMessage>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
          closeButton={false}
          transition={Slide}
        />
      </HorizontalContent>
    </>
  )
}

export default AppContent