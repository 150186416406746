import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionOrder } from '../../actions/filterActions'
import ButtonIcon from '../../components/Button/IconButton'
import { ReactComponent as OrderIcon } from '../../assets/icons/Order.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;

`
const PopupFilterOrder = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
`
const Text = styled.p`
  color: #7C7C74;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`
const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

const PopUpOrder = (props) => {

  const dispatch = useDispatch()
  const order = useSelector(state => state.filterReducer.sort)

  const [show, setShow] = useState(false)
  const [state, setState] = useState(order)

  useEffect(() => {
    dispatch(actionOrder(state))
  }, [state]);

  useEffect(() => {
    setState(order)
  }, [order]);


  const toggle = () => {
    setShow(!show)
  }

  return (
    <Divider>
      <PopupFilterOrder
        position='bottom left'
        on='click'
        onClose={toggle}
        trigger={
          <ButtonIcon onClick={toggle}>
            <Texto>
              Ordenar
            </Texto>
            <OrderIcon />
          </ButtonIcon>
        }
      >
        <ContainerOption>
          {!!props.hideOptions && !!props.hideOptions.find(hide => hide === 'masReciente') ? <></> :
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => setState('masReciente')}
              style={{ "paddingTop": "14px", "paddingBottom": "22px" }}
            >
              {
                state === 'masReciente'
                && <Reviewed className='iconReReviewed' />
              }
              {
                state !== 'masReciente'
                  ? <Text>Más reciente</Text>
                  : 'Más reciente'
              }
            </ButtonOption>
          }
          {!!props.hideOptions && !!props.hideOptions.find(hide => hide === 'masAntiguo') ? <></> :
            <ButtonOption
              className='buttonOptionHover'
              onClick={() => setState('masAntiguo')}
            >
              {
                state === 'masAntiguo'
                && <Reviewed className='iconReReviewed' />
              }
              {
                state !== 'masAntiguo'
                  ? <Text>Más antiguo</Text>
                  : 'Más antiguo'
              }
            </ButtonOption>
          }
          <ButtonOption
            className='buttonOptionHover'
            onClick={() => setState('A-Z')}
          >
            {
              state === 'A-Z'
              && <Reviewed className='iconReReviewed' />
            }
            {
              state !== 'A-Z'
                ? <Text>A-Z</Text>
                : 'A-Z'
            }
          </ButtonOption>
          <ButtonOption
            className='buttonOptionHover'
            onClick={() => setState('Z-A')}
          >
            {
              state === 'Z-A'
              && <Reviewed className='iconReReviewed' />
            }
            {
              state !== 'Z-A'
                ? <Text>Z-A</Text>
                : 'Z-A'
            }
          </ButtonOption>
        </ContainerOption>
      </PopupFilterOrder>
    </Divider>
  )
}
export default PopUpOrder
