import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import modulo from 'modulo-11'
import InputFormTextAndNumber from '../InputAnimatedGeneralForms/InputFormTextAndNumber'
import InputFormDropdown from '../InputAnimatedGeneralForms/InputFormDropdown'
import InputDV from '../InputAnimatedGeneralForms/InputDV'
import { postCreateEntities, activeFormClient } from '../../actions/clientActions'
import { refreshStateModule, warningFormModal } from '../../actions/modalActions'
import './index.css'
import { getGeographicList } from "../../actions/defaultListActions"
import { FormContact } from '../FormContact/FormContact'
import { AttachmentButtonForm } from '../InputAnimatedGeneralForms/AttachmentButtonForm'
import { useDispatch, useSelector } from 'react-redux'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { useNavigate } from "react-router-dom-v5-compat"

const Divider = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 15px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
`
const Title2 = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-Top: 22px
`

const TextInformation = styled.p`
font-family: Roboto;
font-size: 18px;
font-weight: 400;
text-align: left;
`
const Divider2 = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1200px) {
    display: block;
    margin: 30px 0px;
  }
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:right;
    width:49%;
    margin:0;
  }
`
const ColumnGrid = styled(Grid.Column)`
  width:50% !important;
  @media only screen and (max-width: 1200px) {
    width: 100% !important;
  }
`
const ColumnGridDividerOne = styled(Grid.Column)`
  width:40% !important;
  @media only screen and (max-width: 1200px) {
    width: 80% !important;
  }
`
const ColumnGridDividerTwo = styled(Grid.Column)`
  width:10% !important;
  @media only screen and (max-width: 1200px) {
    width: 20% !important;
  }
`
const optionsTypeOfCompany = [
  { key: 'yyyy', text: '', value: '' },
  { key: 'PUBLIC', text: 'PÚBLICA', value: 'PUBLIC' },
  { key: 'PRIVATE', text: 'PRIVADA', value: 'PRIVATE' },
]

const optionsTypePersonCompany = [
  { key: 'yyy', text: '', value: '' },
  { key: 'LEGAL_PERSON', text: 'PERSONA JURÍDICA', value: 'LEGAL_PERSON' },
]

const optionsTypeDocumentCompany = [
  { key: 'y', text: '', value: '' },
  { key: 'NIT', text: 'NIT', value: 'NIT' },
  { key: 'ID_EXTRANJERO', text: 'ID EXTRANJERO', value: 'ID_EXTRANJERO' },
]

const optionsTypeDocumentLegalManager = [
  { key: 'yy', text: '', value: '' },
  { key: 'C.C', text: 'C.C', value: 'C.C' },
  { key: 'C.E', text: 'C.E', value: 'C.E' },
  { key: 'DNI', text: 'DNI', value: 'DNI' },
  { key: 'NIT', text: 'NIT', value: 'NIT' },
  { key: 'NUI', text: 'NUI', value: 'NUI' },
  { key: 'PAS', text: 'PAS', value: 'PAS' },
  { key: 'PEP', text: 'PEP', value: 'PEP' },
  { key: 'R.C', text: 'R.C', value: 'R.C' },
  { key: 'T.I', text: 'T.I', value: 'T.I' },
]

export const FormCreateClients = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [values, setValues] = useState({
    clientCode:'',
    nameOfCompany: '',
    typeOfCompany: '',
    typePersonCompany: '',
    typeDocumentCompany: '',
    numberDocumentCompany: '',
    DV: '',
    phone: '',
    email: '',
    domain: '',
    address: '',
    //countryList: [],
    selectedCountry: '',
    departmentList: [],
    selectedDepartment: '',
    cityList: [],
    selectedCity: '',
    legalManagerName: '',
    legalManagerLastName: '',
    typeDocumentLegalManager: '',
    numberDocumentLegalManager: '',
    status: false,
    errors: {},
    departaments: [],
    cities: [],
    isChangedState: false,
    contactInformation:[
      {
        name:'',
        surNames:'',
        email:'',
        phoneContact:'',
        occupation:''
      },
      {
        name:'',
        surNames:'',
        email:'',
        phoneContact:'',
        occupation:''
      },
    ],
    supportFiles:[],
    progress: [0, 0, 0],
    errorFile:false,
    isVisible: false,
    supportDescription:''
  })
  const geographicList = useSelector((state) => state.defaultListReducer.geographicList)
  const activeForm = useSelector((state) => state.clientReducer.activeForm)
  const moduleType = useSelector((state) => state.modalReducer.refreshModule)
  const warningModal = useSelector((state) => state.modalReducer.warningModal)
  const [messagesShown, setMessagesShown] = useState(false)
  const [formValidator,setShow,setFields] = useValidator(reactValidatorOptions)
  const errorsForm = useSelector(state=> state.errorReducer.errors)
  const errors = useSelector(state=> state.errorReducer.errorsForm)
  /*useEffect(() => {
  return () => {
    initialState.contactInformation = [
      {
        name: '',
        surNames: '',
        email: '',
        phoneContact: '',
        occupation: '',
      },
      {
        name: '',
        surNames: '',
        email: '',
        phoneContact: '',
        occupation: '',
      },
    ];
    setState({ ...initialState })
  }
},[])*/

/*const fetchGeographicList = () => {
   dispatch(getGeographicList()).then( ()=> {
    const dataCountry = geographicList.map((x) => {
      return { key: x.code, text: x.name, value: x.code }
    })
    dataCountry.unshift({ key: 'xxx', text: '', value: '' });
    setValues((prevState) => ({ ...prevState, countryList: dataCountry }))
  })
}

useEffect(() => {
    fetchGeographicList()
}, [])  */

useEffect(() => {
  geographicList.forEach((data) => {
    if (data.code === values.selectedCountry) {
      const dataDepartments = data.departments.map((ele) => {
        return { key: ele.code, text: ele.name, value: ele.code }
      })
      dataDepartments?.unshift({ key: 'x', text: '', value: '' })
      setValues(({
        ...values,
        departmentList: dataDepartments || [],
        selectedDepartment: '',
        selectedCity: '',
      }))
    }
  })
}, [values.selectedCountry]) 

useEffect(() => { 
  geographicList.forEach((data) => {
    if (data.code === values.selectedCountry) {
      if (!!data.departments) {
        const dataDepartments = data.departments.forEach((data) => {
          if (data.code === values.selectedDepartment) {
            if (!!data.cities) {
              const dataCities = data.cities.map((ele) => {
              return { key: ele.code, text: ele.name, value: ele.code }
            })
            dataCities?.unshift({ key: 'xx', text: '', value: '' })
            setValues(({
              ...values,
              cityList: dataCities || [],
              selectedCity: '',
            }))
          }
        }
      })
    }}
  })
}, [values.selectedDepartment]) 

useEffect(() => {
  setTimeout(() => {
    validateErrors()
  }, 500);
}, [errors,errorsForm])

useEffect(() => {
  if (messagesShown===true) {
    validateErrors();
  }
}, [messagesShown]);

useEffect(() => {
  if (activeForm === false && warningModal.status === true) {
    dispatch(activeFormClient())
  }

  if (moduleType === true) {
    navigate(-1)
    dispatch(refreshStateModule())
  }

}, [activeForm, warningModal, moduleType, dispatch, navigate])

  const handleOnChange = (e, { id, value }) => {
    let name = ''
    
    if (e.target.id) {
      name = e.target.id
      setValues((prevValues) => {
        const newValues = { ...prevValues, [name]: value }
        return newValues
      })
    } else {
      name = e.currentTarget.parentNode.parentNode.id
      setValues((prevValues) => {
        const newValues = { ...prevValues, [name]: value }
        return newValues
      })
    }
  }


  const trimStart = (input)=> {
    let start = 0;
    while (start < input.length && input[start] === ' ') {
        start++;
    }
    return input.substring(start);
  }

  const onChange = (e) => {
    const fieldName = e.target.id;
    const fieldlabel = e.target.getAttribute("label");
    let value = e.target.value; // Obtener el valor sin aplicar trim

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = trimStart(value);
    const diffLength = value.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    value = trimmedValue;
    setValues((prevValues) => {
      const newValues = {...prevValues, [fieldName]: value }
      return newValues
    })
    const input = document.getElementById(fieldName);
    input.setSelectionRange(cursorPosition, cursorPosition);
    setFields(fieldName)
}

  const onEmailChange = (e) => {
    let inputValue = e.target.value;
    const fieldlabel = e.target.getAttribute("label");
    const inputId = e.target.id;

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = inputValue.trim();
    const diffLength = inputValue.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    inputValue = trimmedValue;
    
    setValues((prevValues) => { 
      const newValues = {...prevValues, [inputId]: inputValue }
      return newValues
    })
    const input = document.getElementById(inputId)
    input.setSelectionRange(cursorPosition, cursorPosition)
    setFields(inputId)
  }
  
  const onBlurMail = (e) => {
    setValues((prevValues) => {
      const newValues = {...prevValues, email: values.email.toLowerCase() }
      return newValues
    })
  }

  const onBlurDomain = (e) => {
    setValues((prevValues) => { 
      const newValues = {...prevValues, domain: values.domain.toLowerCase() }
      return newValues
    })
  }

  const handleCancel = () => {
    formValidator.hideMessages()
    dispatch(warningFormModal({ type: 'warningLeaveClient' }))
  }

  const validateDiferentContactInformation = (contactinfo) => {
    if (contactinfo !== undefined) {
      const contact1 = contactinfo[0]
      const contact2 = contactinfo[1]

      if (
        contact1.name.trim() !== contact2.name.trim() ||
        contact1.surNames.trim() !== contact2.surNames.trim() ||
        contact1.email.trim() !== contact2.email.trim() ||
        contact1.phoneContact.trim() !== contact2.phoneContact.trim() ||
        contact1.occupation.trim() !== contact2.occupation.trim()
      ) {
        return true; 
      }

      return false;
    }
  }

  const makeSaveNotificationReq = () => {

    const {
      clientCode,
      nameOfCompany,
      typeOfCompany,
      typePersonCompany,
      typeDocumentCompany,
      numberDocumentCompany,
      DV,
      phone,
      email,
      domain,
      address,
      selectedCountry,
      selectedDepartment,
      selectedCity,
      legalManagerName,
      legalManagerLastName,
      typeDocumentLegalManager,
      numberDocumentLegalManager,
      status,
      supportFiles,
      contactInformation,
      supportDescription
    } = values

      const body = {
        "codeCompany":clientCode,
        "name": nameOfCompany,
        "providerDefinition": typeOfCompany,
        "typePersonCompany": typePersonCompany,
        "typeDocumentCompany": typeDocumentCompany,
        "numberDocumentCompany": numberDocumentCompany,
        "DV": '',
        "phone": phone,
        "email": email,
        "domain": domain,
        "address": address,
        "country": selectedCountry,
        "department": selectedDepartment,
        "city": selectedCity,
        "legalManagerName": legalManagerName,
        "legalManagerLastName": legalManagerLastName,
        "typeDocumentLegalManager": typeDocumentLegalManager,
        "numberDocumentLegalManager": numberDocumentLegalManager,
        "status": status,
        "supportFiles":supportFiles,
        "contactInformation":contactInformation,
        'supportDescription':supportDescription
      }
      dispatch(postCreateEntities(body))
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (formValidator.allValid()) {
      if (values.supportFiles.length > 0) {
        makeSaveNotificationReq()
      }else{
        setValues((prevValues) => { 
          const newValues = {...prevValues, errorFile: true }
          return newValues
        })
        setMessagesShown(true)
      }
    } else {
      setShow(true)
      if (values.supportFiles.length ===0) {
        setValues((prevValues) => { 
          const newValues = {...prevValues, errorFile: true }
          return newValues
        })
      }
      setMessagesShown(true)
    }
  }

  const calculateDV = () =>{
    let generatedDV = ''
    try {
      generatedDV = modulo.calc(values.numberDocumentCompany).toString()
      setValues((prevValues) => { 
        const newValues = {...prevValues, DV: generatedDV }
        return newValues
      })
    } catch (error) {
      
    }
  }

  const setContactInformation= (index, property, value) => {
    const updatedContactInformation = [...values.contactInformation];
    updatedContactInformation[index][property] = value;
    setValues((prevValues) => { 
      const newValues = {...prevValues, ['contactInformation']: updatedContactInformation }
      return newValues 
    })
    setFields(`${property}${index+1}`)
  }

  const setSupportFiles = (supportFiles) => {
    setValues((prevValues) => { 
      const newValues = {...values, ['supportFiles']: supportFiles }
      return newValues
    })
  }
  const setErrorFile = (errorFile) => {
    setValues((prevValues) => { 
    const newValues = {...prevValues, errorFile: errorFile }
    return newValues
    })
  }

  const addNewContactFields =()=>{
    setValues((prevValues) => { 
      const newValues = {...prevValues, 
        contactInformation: [...values.contactInformation, {
          name:'',
          surNames:'',
          email:'',
          phoneContact:'',
          occupation:''
        }] 
      }
    return newValues
    })
  }
  
  const removeContactsFields = () =>{
    if (values.contactInformation.length >= 3) {
      const updatedContactInformation = values.contactInformation.slice(0, -1);
      setValues((prevValues) => { 
        const newValues = {...prevValues, contactInformation: updatedContactInformation }
        return newValues
      })
    }
  }

  const validateErrors = () => {
    const elementError= document.querySelector('.labelInputFormEntitiesError, .divInputDropdownFormEntitiesError, .supportFilesError, .spanLabelInputTopError')
    if (elementError !== null) {
      elementError.setAttribute('tabindex', '0');
      if(elementError.className.includes('divInputDropdownFormEntitiesError')){
        elementError.firstChild.focus()
      }else{
        elementError.firstChild.focus()
      }
    }
    setMessagesShown(false)
  }
  
  const setMessagesShownTwo =(value) => {
    setMessagesShown(value)
}
    return (
      <Divider>
        <CustomForm>
          <Title>Datos del cliente</Title>
          <Grid>
            <ColumnGrid>
            <InputFormTextAndNumber
                id={"clientCode"}
                label={"Código del cliente"}
                type={"text"}
                onChange={onChange}
                value={values.clientCode}
                validator={formValidator}
                validateOptions={
                 'required|providerClientCode'
                }
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'nameOfCompany'}
                label={'Nombre'}
                type={'text'}
                onChange={onChange}
                value={values.nameOfCompany}
                validator={formValidator}
                validateOptions={'required|nameOfCompany'}
                error={values.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeOfCompany}
                placeholder={'Tipo de empresa'}
                value={values.typeOfCompany}
                handleOnChange={handleOnChange}
                id='typeOfCompany'
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypePersonCompany}
                placeholder={'Tipo de persona'}
                value={values.typePersonCompany}
                handleOnChange={handleOnChange}
                id={'typePersonCompany'}
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeDocumentCompany}
                placeholder={'Tipo de documento'}
                value={values.typeDocumentCompany}
                handleOnChange={handleOnChange}
                id={'typeDocumentCompany'}
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGridDividerOne>
              <InputFormTextAndNumber
                id={'numberDocumentCompany'}
                label={'No. documento'}
                type={'text'}
                onChange={onChange}
                value={values.numberDocumentCompany}
                onBlur={calculateDV}
                validator={formValidator}
                validateOptions={['required', 'numeric', { min: 8 }, { max: 12 }]}
                error={values.errors}
              />
            </ColumnGridDividerOne>
            <ColumnGridDividerTwo>
              <InputDV
                id={'DV'}
                label={'DV'}
                type={'text'}
                onChange={onChange}
                value={values.DV}
                disable={true}
                validator={formValidator}
                validateOptions={values.typeDocumentCompany === 'NIT' ? ['required', 'numeric', { min: 1 }, { max: 1 }] : ['numeric', { min: 1 }, { max: 1 }]}
                error={values.errors}
              />
            </ColumnGridDividerTwo>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'phone'}
                label={'Teléfono'}
                type={'text'}
                onChange={onChange}
                value={values.phone}
                validator={formValidator}
                validateOptions={'phone'}
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'email'}
                label={'Email'}
                type={'text'}
                onChange={onEmailChange}
                value={values.email}
                validator={formValidator}
                validateOptions={['required', 'email', { min: 6 }, { max: 200 }]}
                error={values.errors}
                onBlur={onBlurMail}
                setMessageShown= {setMessagesShownTwo}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'domain'}
                label={'Dominio'}
                type={'text'}
                onChange={onEmailChange}
                value={values.domain}
                validator={formValidator}
                validateOptions={["url",{ min: 1 }, { max: 50 }]}
                error={values.errors}
                onBlur={onBlurDomain}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'address'}
                label={'Dirección'}
                type={'text'}
                onChange={onChange}
                value={values.address}
                validator={formValidator}
                validateOptions={['address', { min: 1 }, { max: 200 }]}
                error={values.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={props.countryList}
                placeholder={'País'}
                value={values.selectedCountry}
                handleOnChange={handleOnChange}
                id={'selectedCountry'}
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={values.departmentList}
                placeholder={'Departamento'}
                value={values.selectedDepartment}
                handleOnChange={handleOnChange}
                id={'selectedDepartment'}
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>

            <ColumnGrid>
              <InputFormDropdown
                options={values.cityList}
                placeholder={'Ciudad'}
                value={values.selectedCity}
                handleOnChange={handleOnChange}
                id={'selectedCity'}
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
          <Title2>Datos representante legal</Title2>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'legalManagerName'}
                label={'Nombres representante legal'}
                type={'text'}
                onChange={onChange}
                value={values.legalManagerName}
                validator={formValidator}
                validateOptions={['required', 'nameOfProviderClient', { min: 1 }, { max: 100 }]}
                error={values.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'legalManagerLastName'}
                label={'Apellidos representante legal'}
                type={'text'}
                onChange={onChange}
                value={values.legalManagerLastName}
                validator={formValidator}
                validateOptions={['required', 'nameOfProviderClient', { min: 1 }, { max: 100 }]}
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeDocumentLegalManager}
                placeholder={'Tipo de documento representante legal'}
                value={values.typeDocumentLegalManager}
                handleOnChange={handleOnChange}
                id={'typeDocumentLegalManager'}
                validator={formValidator}
                validateOptions={'required'}
                error={values.errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'numberDocumentLegalManager'}
                label={'No. documento representante legal'}
                onChange={onChange}
                value={values.numberDocumentLegalManager}
                validator={formValidator}
                validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
                error={values.errors}
              />
            </ColumnGrid>
          </Grid>
    

          <AttachmentButtonForm supportFiles ={values.supportFiles} setSupportFiles={setSupportFiles} setErrorFile={setErrorFile} formValidator={formValidator} errorFile={values.errorFile} supportDetail={values.supportDescription} onChange={onChange} validator={formValidator}/>

          <TextInformation>Para tener más información del cliente, debes ingresar los siguientes datos de contacto.</TextInformation>

          <FormContact setContactInformation={setContactInformation} contactInformation={values.contactInformation} formValidator={formValidator} errors={values.errors} supportFiles={values.supportFiles} setSupportFiles={setSupportFiles} addNewContactFields={addNewContactFields} removeContactsFields = {removeContactsFields} errorFile={values.errorFile} setErrorFile={setErrorFile}/>


          <Divider2>
            <ButtoCancel
              onClick={handleCancel}
            >Cancelar
            </ButtoCancel>
            <ButtoSave
              onClick={handleSend}
            >Guardar
            </ButtoSave>
          </Divider2>
        </CustomForm>
      </Divider>
    )
  }
export default FormCreateClients
