import React from 'react'
import moment from 'moment'
import { Table } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {convert} from 'html-to-text'
import Label from '../../../components/Label/MainLabel'
import StarredButton from '../../../components/Starred/StarredButton'
import { ReactComponent as Attached } from '../../../assets/icons/sent/attached.svg'
import { postStarredV2 } from '../../../actions/starredAction'
import { useDispatch } from 'react-redux'

const SentCell = styled(Table.Cell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
`

const Row = styled(Table.Row)`
  cursor: pointer !important;
`

const StatusContainer = styled.div`
  float: left!important;
  padding-left: 10% !important;
`

const RowTableSent = (props) => {

    const dispatch = useDispatch()
    const formatDate = (notificationDate) => (moment(notificationDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY'))
        ? moment(notificationDate).format('h:mm a')
        : moment(notificationDate).format('D MMM')

    const redirectToNotiDetail = () =>
        props.navigate('/sent-notification-detail/' + props.data.notificationID)

    const getDescriptionText = (description) => {
        let text = convert(description, { wordwrap: 130, ignoreImage: true, ignoreHref: true })
        text = text.split('\n')[0]
        return text
    }

    const hadleStarred = () => {
        const body = { "notificationID": props.data.notificationID }
        dispatch(postStarredV2(body))
    }

    return (
        <Row onClick={redirectToNotiDetail} >
            <SentCell width={1} textAlign='center'>
                <StarredButton
                    active={props.data.starred}
                    onClick={hadleStarred}
                />
            </SentCell>
            <SentCell width={3}>
                <b>{props.data.recipient.firstName + " " + props.data.recipient.secondName + " " + props.data.recipient.surname + " " + props.data.recipient.secondSurname}</b>
            </SentCell>
            <SentCell width={1} textAlign='center'>
                {
                    props.data.haveAttachments &&
                    <Attached />
                }
            </SentCell>
            <SentCell width={8}>
                <b>{props.data.subject}</b>
                <span>&nbsp;&nbsp;</span>
                {getDescriptionText(props.data.description)}
            </SentCell>
            <SentCell width={2} textAlign='center'>
                <StatusContainer>
                    <Label
                        text={props.data.currentStatus.name}
                        icon={props.data.currentStatus.id}
                        color={props.data.currentStatus.color}
                    />
                </StatusContainer>
            </SentCell>
            <Table.Cell width={1} style={{ color: '#7C7C74' }}>{formatDate(props.data.notificationDate)}</Table.Cell>
        </Row>
    )
}

RowTableSent.propTypes = {
    data: PropTypes.object.isRequired
}

export default RowTableSent
