import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import { activePagination} from '../../../actions/filterActions'
import {getProvidersV2} from '../../../actions/providerActions'
import RowTableProviders from '../RowTableProviders'
import GeneralTable from '../../../components/Table/GeneralTable'


const Providers = ({setPaginationLoader}) => {

  const dispatch = useDispatch()
  
  const filterReducer = useSelector(state => state.filterReducer )
  const providers = useSelector(state =>  state.providerReducer.providers )
  const elementsCount = useSelector(state =>  state.providerReducer.elementsCount )

  const [state, setState] = useState({
        loader: true,
        initialCount: 0,
        stateCount: true,
        columnData: [
          {name: "Nombre", width: 3, arrow: false},
          {name: "Documento", width: 2, arrow: false},
          {name: "Teléfono", width: 2, arrow: false},
          {name: "Email", width: 3, arrow: false},
          {name: "Representante Legal", width: 3, arrow: false},
          {name: "Activo", width: 3, arrow: false},
        ],
        init: true,
      })

      useEffect(() => {
        makeProvidersRequest(filterReducer)
      }, [])

      useEffect(() => {
        if (!state.init) {
          makeProvidersRequest(filterReducer);
          setState({...state, init :true, loader:true})
        }
      }, [filterReducer]);

      const orderTranslator = (order) => {
        const options = {
          'masReciente': {"_id": -1},
          'masAntiguo': {"_id": 1},
          'A-Z': {"name": 1},
          'Z-A': {"name": -1}
        }
        return options[order]
      }
    
      const makeProvidersRequest = (filterReducer) => {
        const body= {
          count: filterReducer.count,
          pag: filterReducer.pag,
          status: filterReducer.clientsAndProvidersFilter.clientAndProviderStatus,
          sort: orderTranslator(filterReducer.sort),
          textToSearch: filterReducer.searchBar[0]
        }
        dispatch(getProvidersV2(body)).then(() => {
          dispatch(activePagination(false))
          setState({...state,init: false, loader: false })
          setPaginationLoader(false)
        })
      }



      return(
        <GeneralTable
        list={providers}
        typeList={elementsCount === 0 ? 'default' : 'providers'}
        listHeight={'providers'}
        headerTable={state.columnData}
        rowComponent={RowTableProviders}
        loader={state.loader}/>
      );
  
}

export default  Providers

