import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { activePagination } from '../../../actions/filterActions'
import GeneralTable from '../../../components/Table/GeneralTable'
import { draftsListV2, updateList, removeFromList, deleteAllFromList } from "../../../actions/draftsActions";
import RowTableDrafts from './RowTableDrafts'

const orderTranslator = (order) => {
    const options = {
        'masReciente': { "_id": -1 },
        'masAntiguo': { "_id": 1 },
        'A-Z': { "recipients": 1 },
        'Z-A': { "recipients": -1 }
    }
    return options[order]
};


const AllDrafts = ({activeAll, setPaginationLoader}) => {

    const dispatch = useDispatch()
    
    const draftsListArray = useSelector(state=> state.draftReducer.draftListArray)
    const filterReducer = useSelector(state=> state.filterReducer)

    const [loader, setLoader] = useState(true)
    const [init, setInit] = useState(true)

    useEffect(() => {
        makeDraftsRequest(filterReducer)
      },[])

    useLayoutEffect(() => {
        if(!init){
            makeDraftsRequest(filterReducer);
            setInit(true)
            setLoader(true)
        }
    },[filterReducer])

    const makeDraftsRequest = (filter) => {
        dispatch(deleteAllFromList())
        var body = {
            count: filter.count,
            pag: filter.pag === 0 ? 1 : filter.pag,
            status: "",
            sort: orderTranslator(filter.sort),
            textToSearch: filter.searchBar[0],
        }
        if (filter.draftsFilter.draftsStatus == 'ADJUNTOS') {
            body = Object.assign({ haveAttachments: true }, body)
        } else if (filter.draftsFilter.draftsStatus == 'NOADJUNTOS') {
            body = Object.assign({ haveAttachments: false }, body)
        }else if(filter.draftsFilter.draftsStatus == 'MASIVO'){
            body = Object.assign({ massiveDeliveryType : true }, body)
        }
        dispatch(draftsListV2(body)).then(()=>{
            dispatch(activePagination(false))
            setInit(false)
            setLoader(false)
            setPaginationLoader(false)
        })
    }

    const addCheckedRow = (id) => {
        dispatch(updateList(id))
    }
    
    const removeCheckedRow = (id) => {
        dispatch(removeFromList(id))
    }

    return (
        <GeneralTable
            list={draftsListArray}
            typeList={filterReducer.isSearch === 0 ? 'default' : 'drafts'}
            listHeight={'drafts'}
            headerTable={[]}
            rowComponent={RowTableDrafts}
            addCheckedRow={addCheckedRow}
            removeCheckedRow={removeCheckedRow}
            activeAll={activeAll}
            loader={loader} />
    )
}


export default AllDrafts