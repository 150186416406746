import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { closeWarningModal, warningFormModal } from '../../actions/modalActions'
import { ReactComponent as LeaveIcon } from '../../assets/icons/leaveIcon.svg'
import '../Modal/messageModal.css'
import PropTypes from "prop-types"
import { getUsers, inactiveFormUser, inactiveFormUserEdit, putStateActive, putStateInactive } from '../../actions/usersActions'
import { deleteDraft, deleteDraftListV2 } from '../../actions/draftsActions'
import { getProvidersV2, inactiveFormProvider, inactiveFormProviderEdit, putStateActiveProvider, putStateInactiveProvider } from '../../actions/providerActions'
import { getClients, putStateActiveClient, putStateInactiveClient, getEmployees, inactiveFormClientEdit, inactiveFormClient } from '../../actions/clientActions'
import { useNavigate } from "react-router-dom-v5-compat"
import { inactiveFormAgreement } from '../../actions/agreementActions'

const ModalElement = styled(Modal)`
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`

const WarningModal = (props) => {
    const modalData = (type) => {
        const data = {
            warningLeave: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, esta nueva entidad no quedará guardada",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveUpdate: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, las modificaciones no quedarán actualizadas.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningDeleteDraft: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a eliminar este borrador!",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningDeleteDraftList: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a eliminar este borrador!",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateProviderActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar este operador?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateProviderInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar este operador?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateClientActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar este cliente?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateClientInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar este cliente?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveProvider: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo operador no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            }, 
            warningLeaveClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo cliente no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            }, 
        }
        return data[type]
    }

    const customEqual = (oldValue, newValue) => oldValue === newValue
    const idProvider = sessionStorage.getItem("idProvider")
    const idClient = sessionStorage.getItem("idClient")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const modalState = useSelector(state => state.modalReducer.warningModal)
    const id = useSelector(state => state.modalReducer.id)
    const activeFormClient = useSelector(state => state.clientReducer.activeForm)
    const activeFormProvider = useSelector(state => state.providerReducer.activeForm)
    const activeFormAgreements = useSelector(state => state.agreementReducer.activeForm)
    let listToDelete = useSelector((state) => state.draftReducer.listToDelete, customEqual)
    const  filterReducer  = useSelector((state) => state.filterReducer)
    const paginationReducer = useSelector((state) => state.paginationReducer)
    const { count, pag, searchBar, sort } = useSelector((state) => state.filterReducer)
    const filterDetailReducer = useSelector((state) => state.filterDetailReducer)
    const companySerialID = useSelector((state) => state.clientReducer.detailClient.companySerialID)
    const orderTranslator = (order) => {
        const options = {
          'masReciente': {"_id": -1},
          'masAntiguo': {"_id": 1},
          'A-Z': {"name": 1},
          'Z-A': {"name": -1}
        }
        return options[order]
      }
      
      const body= {
        count: count,
        pag: pag,
        sort: orderTranslator(sort),
        textToSearch: searchBar[0]
      }
      const bodyDetail ={
        count: filterDetailReducer.count,
        pag: filterDetailReducer.pag,
        sort: orderTranslator(filterDetailReducer.sort),
        companySerialID: companySerialID,
        textToSearch: filterDetailReducer.searchBar[0]
      } 

    const onClose = () => {
        if (modalState.type === 'warningDeleteDraft' || modalState.type === 'warningDeleteDraftList') {
            dispatch(closeWarningModal({ type: 'warningDeleteDraft'}))
        }else if(modalState.type !== 'warningLeaveProvider' &&  modalState.type !== 'warningLeaveClient' && modalState.type !== 'warningLeaveUpdate'){
            dispatch(warningFormModal({ type: 'warningLeave' }))
        }
    }

    const handleOnLoad = () => {
        if (modalState.type === 'warningDeleteDraft') {
            const body = {
                arrayDraftSerialID: [id]
            }
            dispatch(deleteDraft(body, filterReducer, paginationReducer))
        } else if (modalState.type === 'warningDeleteDraftList') {
            const body = {
                arrayDraftSerialID: listToDelete
            }
            dispatch(deleteDraftListV2(body, filterReducer, paginationReducer))
            
        }else if (modalState.type === 'warningUpdateProviderActive') {
            dispatch(putStateActiveProvider({ "status": 'ENABLE' }, idProvider)).then(()=>{
                dispatch(getProvidersV2(body))
            })
        } else if (modalState.type === 'warningUpdateProviderInactive') {
            dispatch(putStateInactiveProvider({ "status": 'DISABLE' }, idProvider)).then(()=>{
                dispatch(getProvidersV2(body))
            })
        } else if (modalState.type === 'warningUpdateClientActive') {
            dispatch(putStateActiveClient({ "status": 'ENABLE' }, idClient)).then(()=>{
                dispatch(getClients(body))
            })
        } else if (modalState.type === 'warningUpdateClientInactive') {
            dispatch(putStateInactiveClient({ "status": 'DISABLE' }, idClient)).then(()=>{
                dispatch(getClients(body))
            })
        }else {
            navigate(-1)
            dispatch(inactiveFormClientEdit())
            dispatch(inactiveFormProviderEdit())
            dispatch(inactiveFormClient())
            dispatch(inactiveFormProvider())
            dispatch(inactiveFormAgreement())
        }
        
        if (activeFormProvider === false || activeFormClient === false || activeFormAgreements === false ) {
            dispatch(warningFormModal({ type: 'warningLeave' }))
        }
    }


    const data = modalData(modalState.type)
    return (
        <ModalElement
            open={modalState.status}
            onClose={onClose}
            className={'generalClass'}
        >
            {data && (
            <ModalContent>
                {data
                    && data.icon}
                {data.multipleMessages && !!modalState.message && !!modalState.message.length &&
                    modalState.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!modalState.message 
                            ? data.message
                            : modalState.message}
                        {
                            
                            modalState.type !== 'warningUpdateClientInactive' &&
                            modalState.type !== 'warningUpdateClientActive'  &&
                            modalState.type !== 'warningUpdateProviderInactive' &&
                            modalState.type !== 'warningUpdateProviderActive'  &&
                            <>
                                <br />
                                {modalState.type !== 'warningDeleteDraftList'  && modalState.type !== 'warningDeleteDraft'   && 
                                <br />}
                            </>
                        }
                        {!modalState.message
                            ? data.message2
                            : modalState.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={handleOnLoad}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={onClose}
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
            )}
        </ModalElement>
    )
}
WarningModal.propTypes = {
    modalState: PropTypes.object,
    warningFormModal: PropTypes.func,
    activeForm: PropTypes.bool
}

export default WarningModal