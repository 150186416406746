import { useEffect } from "react";
import { verifyCaptchaV2 } from "../../actions/authActions";
import { RECAPTCHA_KEY_V2 } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RESET_CAPTCHA } from "../../actions/actionsUtilities/types";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";

const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #C3132E;
  margin: 0px 96px 0 7px !important;
`
const Div = styled.div`
justify-content: center;

`
export const Recaptcha = ({ showError, onExpiredCaptcha, accessTime }) => {

    const customEqual = (oldValue, newValue) => oldValue === newValue
    const recaptchaRef = React.createRef();

    var { resetCaptcha } = useSelector(state => state.authReducer, customEqual)
    const dispatch = useDispatch()

    const onChangeReCaptcha = async (token) => {
        if (token) dispatch(verifyCaptchaV2(token))
    }

    useEffect(() => {
        if (resetCaptcha) {
            recaptchaRef.current.reset();
            dispatch({ type: RESET_CAPTCHA })
        }
    }, [resetCaptcha])

    //Recaptcha enterprise
    // useEffect(() => {
    //   if (resetCaptcha) {
    //     window.grecaptcha.enterprise.reset()
    //     dispatch({type:RESET_CAPTCHA})
    //   }
    // }, [resetCaptcha])

    // useEffect(() => {
    //     window.onChangeReCaptcha = onChangeReCaptcha;
    //     try {
    //         window.grecaptcha.enterprise.ready(function () {
    //             try {
    //                 window.grecaptcha.enterprise.render("recaptcha", { "sitekey": RECAPTCHA_KEY });
    //             } catch (error) {
    //             }
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }

    // }, [])

    return (
        <div style={{marginBottom: '15px'}}>
            <Div style={{overflow: 'hidden',
                //display: `${!accessTime ? 'flex' : 'none'}`,
                border: `${showError ? '2px solid #C3132E' : 'none'}`}}   >
                <ReCAPTCHA  style={{height: '76px', width: '302px'}}  ref={recaptchaRef} onExpired={onExpiredCaptcha}
                            sitekey={RECAPTCHA_KEY_V2} onChange={onChangeReCaptcha} />
            </Div>
            {showError && <Text > Debes indicar que no eres un robot </Text> }
            {//Recaptcha enterprise
                /* <div id="recaptcha" style={{ justifyContent: 'center', display: 'flex' }} data-callback='onChangeReCaptcha'
                className="g-recaptcha" data-sitekey={RECAPTCHA_KEY} data-action="login" /> */}
        </div>
    )
}
