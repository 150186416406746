import React, { useState } from 'react'
import styled from 'styled-components'
import { SearchBarDetailGenericButton } from '../../SearchBarGeneric'
import { useSelector } from 'react-redux'

const Divider = styled.div`
`

const ContainerEmployeeSearch = (props) => {
    const [searchBarState, setSearchBarState] = useState("")
    const [popUpOrderState, setPopUpOrderState] = useState("")
    const originalEmployeesCount = useSelector((state) => state.clientReducer.originalEmployeesCount)

    return (
        <Divider>
            <SearchBarDetailGenericButton buttonType={'employeeProvider'} text={'Nuevo usuario'} showButton={true} disabledButton={originalEmployeesCount !== 0? true:false }
            textPlaceHolder={'Buscar'} popUpOrderState={popUpOrderState} searchBarState={searchBarState}
            showFilters={false} paddingLeft={'20px'} filterPosition={props.filterPosition}/>
        </Divider>
    )
}

export default ContainerEmployeeSearch