import React, {useEffect, useState} from 'react'
import AllProviders from './AllProviders'
import {useDispatch, useSelector} from "react-redux";
import ContainerSearchClientsAndProviders from '../../components/Search/SearchClientsAndProviders/ContainerSearchClientsAndProviders';
import WarningModal from '../../components/ModalGeneralForms/WarningModal';

const Providers = (props) => {
  const dispatch = useDispatch()
  const providers = useSelector( state => state.providerReducer.providers)
  const [paginationLoader, setPaginationLoader] = useState(true)
  return (
    <>
      <ContainerSearchClientsAndProviders 
        textPlaceHolder={'Buscar' } 
        buttonType= {'provider'} 
        text={'Nuevo operador'} 
        showAZ={true}
        showButton={true} 
        showFilters={true}
        showExportButton={true} 
        disabledExportButton = {!providers.length > 0}
        reportType={"LIST_PROVIDERS"} 
        providerType={"PROVIDER"}
        showAdvancedSearch={false}
        loader={paginationLoader}
      />
      <WarningModal />
      
      <AllProviders setPaginationLoader={setPaginationLoader}/>
    </>
  )
}

export default Providers