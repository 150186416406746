import { VIEW_PAGINATION, VIEW_PAGINATION_INTERNAL } from "../actions/actionsUtilities/types"

const initialState = {
  total: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEW_PAGINATION:
      return {
        ...state,
        total: action.payload.total,
      }
    case VIEW_PAGINATION_INTERNAL:
      return {
        ...state,
        count: action.payload.count,
        total: action.payload.total,
      }
    default:
      return state
  }
}
