import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Segment, Table, TableHeaderCell } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { CustomComponent } from '../CustomComponent'
import { Dimmer, Loader } from 'semantic-ui-react'
import { ReactComponent as NotFoundNotification } from '../../assets/icons/sent/not_found.svg'
import { ReactComponent as NotFoundClient } from '../../assets/icons/clients/NotFoundClients.svg'
import { ReactComponent as ArrowFilter } from '../../assets/icons/clients/arrow-filter.svg'
import { TableHead } from '@mui/material'
import './styles.css'
import { useSelector } from 'react-redux'

const CustomTable = styled(Table)`
  width: 100% !important;
  float: right !important;
  margin: 0 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: unset !important;
  padding-left: 21.5px;
  padding-right: 21.5px;
  overflow: scroll;
  @media only screen and (max-width: 1024px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    max-width: 100% !important;
  }

  tbody tr:last-child td {
    border-bottom: 1px solid #e8e9e9; 
  }
`

const TableBody = styled(Table.Body)`
${props => !!props.children[0] && !props.children[0].props.noHover &&
    css`
  & tr:hover {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.30);
    border: 1px solid #B2B2B2 !important;
    opacity: 1 !important;
  }
  `}
@media only screen and (max-width: 768px) {
  max-width: 100% !important;
}
`

const Message = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-bottom: 15px;
`

const MessageClients = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-top: 25px;
  margin-bottom: 15px;
`

const HeaderCell = styled(TableHeaderCell)`
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 11.03px 10px 10px 5px !important;
  color: #133F4F !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000 !important;
  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap !important;
    `}
`

const Arrow = styled(ArrowFilter)`
  margin-left: 5px;
  width: 10px
`
const ContainerScroll = styled.div`
  width: 100%;
  height: 71vh;
  overflow-y: scroll;
  overflow-x: none;
  max-width: 100%;
  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
  @media only screen and (max-width: 1024px) {
    height: 71vh;
  }
`
const DivMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const NotFoundCell = styled.tr`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(54px * 10);
  &:hover {
    border: none !important;
    box-shadow: unset !important;
  }
`
const CustomHeadTable = styled(Table)`
  position: relative;
  width: 100% !important;
  padding-left: 21.5px;
  padding-right: 26px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000 !important;
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
  height: ${props => props.height ? props.height : 'auto'};
  @media only screen and (max-width: 1024px) {
    padding-left: 0px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const GeneralTable = (props) => {
  const searchBarvalue = useSelector(state => state.filterReducer.searchBar)
  const detailMassiveStatus = useSelector(state => state.sentReducer.detailMassiveNotification.status)

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  
  function useWindowDimensions() {
    const [windowDimensions = 0, setWindowDimensions] = useState(getWindowDimensions);

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions]);

    return windowDimensions;
  }

  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTop = 0
  }, [props.list])

  const listData = () => {
    const { list, loader } = props
    if (!list.length && !loader) {
      switch (props.typeList) {
        case "notifications":
          return <NotFoundCell >
            <td>
              <DivMessage>
                <Message>¡No existen notificaciones enviadas!</Message>
                <NotFoundNotification />
              </DivMessage>
            </td>
          </NotFoundCell>
        case "clients":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay entidades creadas!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "providers":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay operadores creados!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "users":
          return <>
            <NotFoundCell>
              <td>
                <DivMessage>
                  <NotFoundClient />
                  <MessageClients>¡Aún no hay usuarios creados!</MessageClients>
                </DivMessage>
              </td>
            </NotFoundCell></>
        case "convetions":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay vinculaciones creadas!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "bags":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay bolsas creadas!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "drafts":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡No existen borradores!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "userDocuments":
          return <NotFoundCell>
            <td>
              <DivMessage>
                {!!searchBarvalue[0] && searchBarvalue[0].length !== 0 ?   <Message>¡No existen coincidencias para esta búsqueda!</Message> :<Message>¡No existen documentos enviados!</Message>}
                <NotFoundNotification />
              </DivMessage>
            </td>
          </NotFoundCell>
        case "reports":
          return <NotFoundCell>
            <td>
              <DivMessage>
                <NotFoundClient />
                <MessageClients>¡Aún no hay reportes creados!</MessageClients>
              </DivMessage>
            </td>
          </NotFoundCell>
        case "logs":
            return <NotFoundCell>
              <td>
                <DivMessage>
                  <NotFoundClient />
                  <MessageClients>¡Aún no hay registro de seguimiento!</MessageClients>
                </DivMessage>
              </td>
            </NotFoundCell>
        case "masive":
          if(detailMassiveStatus === "ERROR" || detailMassiveStatus === "READY_SEND_NOTIFICATION"){
            return (
              <tr >
                <td>
                  <DivMessage>
                    <Message>¡No existen registros de notificaciones enviadas!</Message>
                    <NotFoundNotification />
                  </DivMessage>
                </td>
              </tr>
            )
          }else{
            return (
              <tr >
                <td>
                  <DivMessage>
                    <Message>¡No existen coincidencias para esta búsqueda!</Message>
                    <NotFoundNotification />
                  </DivMessage>
                </td>
              </tr>
            )
          }
        default:
          return <tr >
            <td>
              <DivMessage>
                <Message>¡No existen coincidencias para esta búsqueda!</Message>
                <NotFoundNotification />
              </DivMessage>
            </td>
          </tr>
      }
    } else {
      return list
        .map((v, index) =>
          <CustomComponent
            key={index}
            data={v}
            component={props.rowComponent}
            {...props}
          />)
    }
  }

  const listColumn = () => {
    const { list, loader,heigthHeader,noWrap=true } = props
    
    if (!!list.length && !loader) {
      if (props.headerTable !== undefined && !!props.headerTable.length || props.headerTable == [] && !!props.headerTable.length === false) {
        return (
          <CustomHeadTable height= {heigthHeader}>
            <TableHead>
              <Table.Row>
                {props.headerTable.map((column, index) => <HeaderCell key={index} width={column.width} noWrap={noWrap}>{column.name}{column.arrow === true ? <Arrow /> : ''}</HeaderCell>)}
              </Table.Row>
            </TableHead>
          </CustomHeadTable>

        )
      }
    }
  }
  const width = useWindowDimensions().width
  //Compurueba si la pantalla es muy baja
  const heightisLow = useWindowDimensions().height < 900? true:false
  //Si es muy baja la pantalla se va a restar esto a la altura y asi poder mostrar bien la tabla
  //80 es el numero de pixeles que le quiero quitar a cada tabla
  const heigthToEliminate= (80/useWindowDimensions().height)*100

  const options = width > 1024 || width < 768 ? {
      listAttachments: { height: '64vh' },
      masive: { height: '36vh' },
      massiveLoad: { overflowY: 'none !important', overflowX: 'none !important' },
      drafts:  heightisLow?{ height: `calc(68vh - ${heigthToEliminate}vh)` }:{height: '68vh'},
      userDocuments: heightisLow?{ height: `calc(68vh - ${heigthToEliminate}vh)` }:{height: '68vh'},
      agreements: heightisLow?{ height: `calc(68vh - ${heigthToEliminate}vh)` }:{height: '68vh'},
      clients: { height: '75vh' },
      reports: { height: '75vh' },
      providers: { height: '75vh' },
      users: heightisLow?{ height: `calc(68vh - ${heigthToEliminate}vh)` }:{height: '68vh'},
      notifications: heightisLow?{ height: `calc(62vh - ${heigthToEliminate}vh)` }:{height: '62vh'},
      improperClients: { height: '75vh' },
    }
    :
    {
      listAttachments: { height: '64vh' },
      masive: { height: '30vh' },
      massiveLoad: { overflowY: 'none !important', overflowX: 'none !important' },
      drafts: { height: '75vh' },
      userDocuments: { height: '65vh' },
      agreements: { height: '65vh' },
      clients: { height: '75vh' },
      reports: { height: '75vh' },
      providers: { height: '75vh' },
      users: { height: '65vh' },
      notifications: { height: '63vh' }
    }

    const stylesTable = {
      reports: { padding: '0 21.5px 0 21.5px' },
      notifications: { padding: '0 12.5px 0 23px' },
    }
  return (
    <Segment style={{ padding: '0px', margin: '0px', boxShadow: 'unset', border: '0px' }}>
      <Dimmer.Dimmable active={props.loader.toString()}>
        <Dimmer active={props.loader} inverted>
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        {listColumn()}
        <ContainerScroll style={options[props.listHeight]}
          ref={containerRef}>
          <CustomTable style={stylesTable[props.listHeight]} id='table' className={!!props.striped ? 'alter-table' : ''}>
            <TableBody >
              {listData()}
            </TableBody>
          </CustomTable>
        </ContainerScroll>
      </Dimmer.Dimmable>
    </Segment>
  )
}

GeneralTable.propTypes = {
  list: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  loader: PropTypes.bool
}

export default GeneralTable
