import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from 'react-redux'
import './input-from-entities.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"
import { activeFormClient } from "../../actions/clientActions"

const text = styled.p`

`
const text2 = styled.span`
    
`
const InputFormArea = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled, min, max, heightText, paddingText, lineHeight = '50px' } = props
    const inputIdentifiers = ['supportDescription', 'tradeDetails', 'tradeSupport']

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? validator.message(id !== '' && id !== undefined ? id : id, value, validateOptions) : ''

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    const [invalidCharacter, setInvalidCharacter] = useState('')

    useEffect(() => {

        if (value !== undefined) {
            var invalidChar
            if (inputIdentifiers.includes(id)) {

                invalidChar = value.match(/[^A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ()@,-;.¡!¿?&#°$%/='¨*:_>+{}¬“£¢∞§¶•ªº«~^…–´` \u005D \u005B \u005C \u2260]/);
            } else {

                invalidChar = value.match(/[^A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),-;.¡!¿?&#]/);
            }
      
            setInvalidCharacter(invalidChar !== null ? `El carácter " ${invalidChar[0]} " no está permitido en este campo` : '')
        } else setInvalidCharacter('')

    }, [value])

    return (
        <Form.Field className={props.stylesAgreements && 'stylesAgreements'}>
            <div>
                <label style={{ height: heightText, borderRadius: '3px', marginRight: '1px' }} className={errorValidator ? 'labelInputFormEntitiesError' : 'labelInputFormEntities'}>
                    <textarea
                        label={label}
                        id={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        onBlur={() => validator2(value)}
                        onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                        min={min}
                        max={max}
                        className={'textArea'}
                        disabled={disabled}
                        style={{ height: heightText, minHeight: heightText, padding: paddingText, lineHeight: lineHeight, borderRadius: '3px' }}
                        autoComplete="off"
                    />
                    {
                        errorValidator && value === ''
                        &&
                        <>
                            <text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</text2>
                            <text className={(errorValidator) && 'input-error3'}>{errorValidator}</text>
                        </>
                    }
                    {
                        errorValidator === undefined && value !== ''
                        &&
                        <>
                            <span className={'spanLabelInputTop'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === ''
                        &&
                        <>
                            <span className={'spanLabelInput'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === '' && placeholder !== undefined
                        &&
                        <>
                            <p className={'labelInputPlaceholder'}>{placeholder}</p>
                        </>
                    }
                    {
                        errorValidator && value !== '' &&   invalidCharacter == ''
                        &&
                        <>
                            <text2 className={'spanLabelInputTopError'}>{label}</text2>
                            <text className={'input-textarea-error'}>{errorValidator}</text>
                        </>
                    }

                    {
                        invalidCharacter !== '' && value !== ''
                        &&
                        <>
                            <text2 className={'spanLabelInputTopError'}>{label}</text2>
                            <text className={'input-textarea-error'}>{invalidCharacter}</text>
                        </>
                    }
                </label>
            </div>

        </Form.Field>
    )
}


InputFormArea.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormArea