import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/sent/leftArrow.svg'
import { warningFormModalUserandEmployes } from '../../../actions/modalActions'
import WarningModalUserAndEmployes from '../../../components/FormUser/Modal/WarningModalUserAndEmployes'
import { getRolesUser, getRolesUserProvider } from '../../../actions/usersActions'
import FormCreateUsers from '../../../components/FormUser/FormUserNotifier/FormCreateUsers'
import { useNavigate } from 'react-router-dom-v5-compat'

const Container = styled.div`
  margin: 30px;
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    margin: 24px 0px 48px 24px;
  }
`

const Title = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;


`
const LeftArrow = styled.button`
  padding: 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  line-height: 0px;
`

const CreateUsers = () => {

  const dispatch = useDispatch()
  const { activeForm } = useSelector(state => state.userReducer)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [dataProvider, setDataProvider] = useState([])
  const [role, setRole] = useState([])
  const [roleProvider, setRoleProvider] = useState([])

  const handleClick = () => {
    if (activeForm === true) {
      dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    dispatch(getRolesUser()).then(result => {setData(result)})
    dispatch(getRolesUserProvider()).then(result => {setDataProvider(result)})
  }, [dispatch])

  useEffect(() => {

      const dataRole = data.map((data) => {
          return { key: data._id, text: data.name, value: data._id }
      })
      dataRole.unshift({ key: '', text: '', value: '' })
      setRole(dataRole)

      const dataProviderRole = dataProvider.map((data) => {
          return { key: data._id, text: data.name, value: data._id }
      })
      dataProviderRole.unshift({ key: '', text: '', value: '' })
     setRoleProvider(dataProviderRole)

  }, [data, dataProvider])

  return (
    <>
      <Container>
        <LeftArrow
          onClick={handleClick}
        >
        <LeftArrowIcon />
        </LeftArrow>
        <WarningModalUserAndEmployes  type='warningLeaveUser' />
        <Title>Nuevo Usuario</Title>
      </Container>
      <FormCreateUsers role={role} roleProvider={roleProvider}/>
    
    </>
  )
};

export default CreateUsers
