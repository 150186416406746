import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator'
import InputFormTextAndNumber from '../../InputAnimatedGeneralForms/InputFormTextAndNumber'
import InputFormDropdown from '../../InputAnimatedGeneralForms/InputFormDropdown'
import { activeFormUser, postCreateUsersv3 } from '../../../actions/usersActions'
import { getCertificateUserV3 } from '../../../actions/usersActions'
import {getProviderDetail} from '../../../actions/providerActions'
import { refreshStateModule, warningFormModalUserandEmployes } from '../../../actions/modalActions'
import '../index.css'
import useValidator from '../../../helpers/simpleReactValidatorHook'
import { useNavigate } from 'react-router-dom-v5-compat'

const Divider = styled.div`
  width: 500px;
  margin: auto;
  @media only screen and (max-width: 992px) {
    width:90%;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 1rem;
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
`
const ButtonContinue = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
`
const ContainerMessage = styled.div`
  width: 100%;
`
const Message = styled.div`
  width:100%;
  padding: 10px 28px 10px 28px;
  background: #E35F001A;
  border: 1px solid #E35F00;
  border-radius: 4px;
  color: #E35F00;
`
const MessageText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`
const initialState = {
  documentType: '',
  documentNumber: '',
  firstName: '',
  lastName: '',
  address: '',
  email: '',
  role: '',
  isChangedState: false,
  stateFormEditUser: true,
  display: false,
  errors: {},
}

const optionsDocumentType = [
  { key: '', text: '', value: '' },
  { key: 'C.C', text: 'Cédula de Ciudadania', value: 'C.C' },
]

export const FormCreateUsers = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [state, setState] = useState(initialState)
  const [formValidator,setShow,setFields] = useValidator(reactValidatorOptions)
  const moduleType = useSelector((state) => state.modalReducer.refreshModule)
  const activeForm = useSelector((state) => state.userReducer.activeForm)
  const warningModal = useSelector((state) => state.modalReducer.warningModalUserAndEmployes.status)
  const userCertificate = useSelector((state) =>  state.userReducer.userCertificate)
  const haveCertificate = useSelector((state) =>  state.userReducer.haveCertificate)
  const roleUser = useSelector((state) => state.authReducer.user.role)
  const typeProvider = useSelector((state) => state.providerReducer.detailProvider.type)
  const errorsForm = useSelector((state) => state.errorReducer.errorsForm)
  const [bandera, setBandera] = useState(false)

  useEffect(() => {
    const id = sessionStorage.getItem("id")
    if(roleUser === '61b36e2fc34e22351bf8cfb4'){
      dispatch(getProviderDetail(id))
    }
}, []) 
    useEffect(() => {
      if (activeForm === false && warningModal === true) {
        dispatch(activeFormUser())
      }
    
      if (moduleType === true) {
        if (roleUser === '61b36e2fc34e22351bf8cfb4'){
          navigate(-1)
          dispatch(refreshStateModule())
        }else {
          navigate('/users')
          dispatch(refreshStateModule())
          }
      }
    
    }, [activeForm, warningModal, moduleType, dispatch, navigate])


    const handleOnChange = (e, { id, value }) => {
      let name = ''
      
      if (e.target.id) {
        name = e.target.id
        setState((prevValues) => {
          const newValues = { ...prevValues, [name]: value }
          return newValues
        })
      } else {
        name = e.currentTarget.parentNode.parentNode.id
        if(name !== 'documentType'){
          setState((prevValues) => {
            const newValues = { 
              ...prevValues, 
              [name]: value,
              stateFormEditUser: false}  
              return newValues
        })} else {
        setState((prevValues) => {
          const newValues = { ...prevValues, [name]: value }
          return newValues
        })
      }
    }}
  

  const onChange = e => {
    if (state.display && e.target.id === 'documentNumber') {
      setState({ ...state, documentNumber: e.target.value, documentType: state.documentType, stateFormEditUser: false })
    } else {
      setState({ ...state, [e.target.id]: e.target.value, stateFormEditUser: false })
    }
  }

  const handleCancel = () => {
    formValidator.hideMessages()
    dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
  }

  const makeSearchDocument = () => {
    const { documentType, documentNumber } = state
    const idClients = sessionStorage.getItem("id")

    const body = {
      "documentType": documentType,
      "documentNumber": documentNumber,
    }

    const body2 = {
      "documentType": documentType,
      "documentNumber": documentNumber,
      "companySerialID": idClients
    }
    dispatch(getCertificateUserV3(roleUser === '61b36e2fc34e22351bf8cfb4' ? body2 : body)).then(() => {
      setBandera(true)
    })
  }

  useEffect(() => {
    if(bandera === true ){
    if (haveCertificate) {
      const { documentNumber } = state;
      sessionStorage.setItem('documentNumberUser', documentNumber);
      setState((prevState) => ({ ...prevState, display: true }));
      setBandera(false)
    } else {
      setState((prevState) => ({ ...prevState, display: false }));
      setBandera(false)
    }}
  }, [haveCertificate, state.documentNumber, bandera]);

  const handleSearchDocument = (e) => {
    e.preventDefault()
    if (formValidator.allValid()) {
      makeSearchDocument()
    } else {
      setShow(true)
      formValidator.showMessages()
    }
  }

  const makeSaveUserReq = () => {
    const { firstName, lastName, role } = state

    const body = {
      "firstNames": firstName,
      "lastNames": lastName,
      "role": role,
      "preRegisterUserSerialID": userCertificate.preRegisterUserSerialID
    }
    dispatch(postCreateUsersv3(body))
  }
  
  const handleSend = (e) => {
    e.preventDefault()
    if (formValidator.allValid()) {
      makeSaveUserReq()
    } else {
      setShow(true)
      formValidator.showMessages()
    }
  }
    return (  <>
      <Divider>
        <CustomForm>
          <InputFormDropdown
            options={optionsDocumentType}
            placeholder={'Tipo de documento'}
            value={state.documentType}
            handleOnChange={handleOnChange}
            id={'documentType'}
            validator={formValidator}
            validateOptions={'required'}
            error={state.errors}
          />
          <div style={{ margin: '0 0 1em' }}></div>
          <InputFormTextAndNumber
            id={'documentNumber'}
            label={'No. documento'}
            onChange={onChange}
            value={state.documentNumber}
            validator={formValidator}
            validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
            error={state.errors}
          />
          {state.display && <>
            <InputFormTextAndNumber
              id={'firstName'}
              label={'Nombres y apellidos'}
              type={'text'}
              onChange={onChange}
              value={userCertificate.firstName + ' ' + userCertificate.lastName}
              validator={formValidator}
              validateOptions={['required', 'nameOfUser', { min: 1 }, { max: 200 }]}
              error={state.errors}
              readOnly={true}
              disabled={true}
            />
            <InputFormTextAndNumber
              id={'address'}
              label={'Dirección'}
              type={'text'}
              onChange={onChange}
              value={userCertificate.address}
              validator={formValidator}
              validateOptions={['required', 'address', { min: 1 }, { max: 200 }]}
              error={state.errors}
              readOnly={true}
              disabled={true}
            />
            <InputFormTextAndNumber
              id={'email'}
              label={'Email'}
              type={'text'}
              onChange={onChange}
              value={userCertificate.email}
              validator={formValidator}
              validateOptions={['required', 'email', { min: 6 }, { max: 200 }]}
              error={state.errors}
              readOnly={true}
              disabled={true}
            />
            <InputFormDropdown
              options={typeProvider === 'PROVIDER' ? props.roleProvider : props.role}
              placeholder={'Rol'}
              value={state.role}
              handleOnChange={handleOnChange}
              id={'role'}
              validator={formValidator}
              validateOptions={'required'}
              error={state.errors}
            />
            <ContainerButtons>
              <ButtoCancel onClick={handleCancel}>Cancelar</ButtoCancel>
              <ButtoSave
                disabled={state.stateFormEditUser}
                className='putButton'
                onClick={handleSend}
              >
                Guardar
              </ButtoSave>
            </ContainerButtons>
          </>}
          {!state.display && 
          <>
            <ContainerMessage style={{ display: (!!haveCertificate) ? 'none' : 'block' }}>
                {!!errorsForm.message ?
                  errorsForm.message === "Este usuario ya existe" ?
                  <Message>
                    <MessageText>Este documento ya cuenta con un proceso de pre registro activo.</MessageText> 
                    </Message>
                    :
                    errorsForm.message === "Este usuario no cuenta con certificado Centralizado" &&
                    <Message>
                    <MessageText>Este documento no cuenta con un certificado vinculado</MessageText>
                    </Message>
                  :
                  <></>
                }
            </ContainerMessage>
            <ContainerButtons>
              <ButtoCancel onClick={handleCancel}>Cancelar</ButtoCancel>
              <ButtonContinue
                disabled={state.stateFormEditUser}
                className='putButton'
                onClick={handleSearchDocument}
              >
                Continuar
              </ButtonContinue>
            </ContainerButtons>
          </>
          }
        </CustomForm>
      </Divider>
    </>
    )
  }

FormCreateUsers.propTypes = {
  savedClient: PropTypes.array,
  moduleType: PropTypes.bool,
  activeFormUser: PropTypes.func.isRequired,
  activeForm: PropTypes.bool,
  warningModal: PropTypes.bool,
  geographicList: PropTypes.array,
  userCertificate: PropTypes.object,
  haveCertificate: PropTypes.bool,
  postCreateUsersv3: PropTypes.func.isRequired,
}

export default FormCreateUsers
