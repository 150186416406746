import React from 'react'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableSentMasive from './RowTableSentMasive'
import { useDispatch, useSelector } from 'react-redux';
import { removeFromListDownload, updateListDownload } from '../../../../actions/seeNotificationAction';
import { useNavigate } from 'react-router-dom-v5-compat';


export const SentMasiveAll = ({notifications = [], loader, activeAll}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { haveAttachments } = useSelector(state => state.sentReducer.detailMassiveNotification)

  const columnData = [
    { name: "", width: 1, arrow: false },
    { name: "No. documento ", width: 2, arrow: false },
    { name: "", width: 4, arrow: false },
    { name: "Última modificación", width: 5, arrow: false },
    { name: "", width: 4, arrow: false },
  ]

  const addCheckedRow = (id) => {
    dispatch(updateListDownload(id))
  }

  const removeCheckedRow = (id) => {
    dispatch(removeFromListDownload(id))
  }

  return (
      <GeneralTable
          list={notifications}
          typeList="masive"
          listHeight="masive"
          headerTable={columnData}
          rowComponent={RowTableSentMasive}
          addCheckedRow={addCheckedRow}
          removeCheckedRow={removeCheckedRow}
          navigate={navigate}
          loader={loader}
          activeAll={activeAll}
          heigthHeader={'45px'}
      />
  )
}