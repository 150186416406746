import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { activePagination } from '../../../../actions/filterActions'
import { getClients } from '../../../../actions/clientActions'
import RowTableClients from '../../superAdminClients/RowTableClientsSuperAdmin'
import GeneralTable from '../../../../components/Table/GeneralTable'

const Clients = ({setPaginationLoader}) => {

  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(true)
  const columnData = [
      { name: "Nombre", width: 3, arrow: false },
      { name: "Documento", width: 2, arrow: false },
      { name: "Teléfono", width: 2, arrow: false },
      { name: "Email", width: 3, arrow: false },
      { name: "Representante Legal", width: 3, arrow: false },
      { name: "Activo", width: 3, arrow: false },
  ]
  
  const clients = useSelector(state => state.clientReducer.clients)
  const elementsCount = useSelector(state => state.clientReducer.elementsCount)
  const filterReducer = useSelector(state => state.filterReducer)
  const dispatch = useDispatch()

  const makeClientsRequest = useCallback((filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      status: filterReducer.clientsAndProvidersFilter.clientAndProviderStatus,
      sort: orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0]
    }

    dispatch(getClients(body)).then(() => {
      dispatch(activePagination(false))
      setInit(false)
      setLoader(false)
      setPaginationLoader(false)
    })
  
  }, [dispatch])

  useEffect(() => {
    makeClientsRequest(filterReducer)
  }, []) 
  
  useEffect(() => {
    if (!init) {
      makeClientsRequest(filterReducer)
      setLoader(true)
      setInit(true)
    }
  }, [filterReducer])

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "name": 1 },
      'Z-A': { "name": -1 }
    }
    return options[order]
  };

    return (
      <GeneralTable
        list={clients}
        typeList= {elementsCount=== 0 ? 'default' : 'clients'}
        listHeight= {'clients'}
        headerTable={columnData}
        rowComponent={RowTableClients}
        loader={loader} />
    )
}

Clients.propTypes = {
  getClients: PropTypes.func.isRequired,
  actionClearFilter: PropTypes.func,
  actionInitialPagination: PropTypes.func,
  clients: PropTypes.array.isRequired,
  elementsCount: PropTypes.number.isRequired,
  filterReducer: PropTypes.object.isRequired,
} 

export default Clients