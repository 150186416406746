import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { resetPassword } from '../../actions/authActions';
import { cleanErrorsAction } from '../../actions/errorsActions';
import { reactValidatorOptions } from '../../helpers/simpleReactValidator';
import ImageSegment from '../../components/Sengment/ImageSegment';
import PrimaryButton from '../../components/PrimaryButton';
import InputFormMaterial from '../../components/InputForm/InputFormMaterial';
import ModalGeneral from '../../components/ModalLogin/ModalGeneral';
import { useNavigate } from 'react-router-dom-v5-compat';

const CustomButton = styled(PrimaryButton)`
  background: #FFFFFF !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  border: 1px solid #22C1D0 !important;
  color: #22C1D0 !important;
  padding: 16px 32px !important;;
  width: 141px !important;
  margin-right: 28px !important; 
  cursor: pointer;
`
const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px 32px !important;;
  width: 141px !important;
  margin-left: 28px !important; 
`
const LoginForm = styled.div`
  height: 80%;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  & > * {
    margin: 10px 0 !important;
  }
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
`
const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  margin: 35px 8px 0 8px; 
`
const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: rgba(186, 53, 85, 0.1) !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
`
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    error: '',
    message: '',
    email: 'El usuario no esta registrado',
  });
  const [validator] = useState(new SimpleReactValidator(reactValidatorOptions));
  const errorReducer = useSelector(state => state.errorReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onChange = e => {
    setEmail(e.target.value);
  };

  const handleBack = () => {
    navigate('/login-user');
  };

  const setEmailAsync = async e => {
    e.preventDefault();
    if (validator.allValid()) {
      const body = {
        email: email,
      };
      await dispatch(resetPassword(body))
    } else {
      validator.showMessages();
    }
  };

  useEffect(() => {
    dispatch(cleanErrorsAction())
  }, [dispatch]);

  return (
    <>
      <ImageSegment>
        <LoginForm>
          <Title>Olvidé mi contraseña</Title>
          <Text>
            Ingresa tu email y recibirás un correo con las instrucciones para restablecer tu contraseña
          </Text>
          {/* <PopupEmail/> */}
          <CustomForm onSubmit={setEmailAsync}>
            <InputFormMaterial
              id="email"
              label="Email"
              type="text"
              onChange={onChange}
              value={email}
              validator={validator}
              validateOptions="required"
              autocomplete="email"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px' }}>
              <CustomButton fluid={true} txtBtn="Cancelar" onPress={handleBack} />
              <CustomPrimaryButton fluid={true} txtBtn="Enviar" onPress={setEmailAsync} />
            </div>
            {errorReducer.showError && <MessageLogin content={errors} />}
          </CustomForm>
        </LoginForm>
      </ImageSegment>
      <ModalGeneral type="sendEmail" />
    </>
  );
};

export default ForgotPassword;