import axios from 'axios'

import {
  SAVE_NOTIFICATION,
  GET_RECIPIENT,
  SAVE_NOTIFICATION_MASSIVE_SUCCESS,
  SAVE_HIRED,
  GET_ATTRIBUTE_LIST,
  SAVE_FILE_MASSIVE,
  STATE_NOTIFICATION_PROGRESS,
  FORM_ERROR,
} from "../actionsUtilities/types.js"
import { API_ENDPOINT } from "../../config/config"
import { GET_FIND_RECIPIENT_V2, SEND_NOTIFICATION_MULTIPLE, SEND_NOTIFICATION_V2, CANCEL_MASSIVE_SEND_V3, SEND_NOTIFICATION_MASSIVE_V4, GET_NOTIFICATIONS_PROGRESS_V2, GET_NOTIFICATIONS_AVAILABLE_FOR_USER_V2, DELETE_MASSIVE_NOTIFICATION_RECORD_V2, SEND_FILE_MASSIVE_V2,GET_ATTRIBUTE_LIST_ROUTE_V2, VALIDATE_TAGS_FILE_MASSIVE } from '../actionsUtilities/actionsRoutes'
import { getHeaders } from '../../helpers/globalHelpers.js'
import { decrypt, encrypt } from '../../helpers/cypher.js'

export const saveNotificationOne = (body) => async (dispatch) => {
  return axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION_V2}`,
    {'data': body}, { headers: await getHeaders() }
  ).then(async res => {
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: res.data.payload
    })
  }).catch((reason) => {
    return reason.data
  })
}

export const saveNotificationMultiple = (body) => async (dispatch) => {
  return axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION_MULTIPLE}`,
    {'data':body}, { headers: await getHeaders() }
  ).then(async res => {
    dispatch({
      type: SAVE_NOTIFICATION,
      payload: res.data.payload
    })
  }).catch((reason) => {
    return reason.data
  })
}

export const saveNotificationMassiveV2 = (body) => async (dispatch) => {
  return axios.post(
    `${API_ENDPOINT + SEND_NOTIFICATION_MASSIVE_V4}`,
    {'data': body}, { headers: await getHeaders() }
  ).then(async res => {
    dispatch({
      type: SAVE_NOTIFICATION_MASSIVE_SUCCESS,
      payload: res.data.payload
    })
  }).catch(async reason => {
    return reason.data
  })
}


export const getRecipientv2 = (body) => async (dispatch) => {
  return axios.post(
    `${API_ENDPOINT + GET_FIND_RECIPIENT_V2}`,
    {'data': body}, { headers: await getHeaders() }
  ).then(async res => {
    dispatch({
      type: GET_RECIPIENT,
      payload: res.data.payload
    })
  }).catch(async reason => {
    return reason.data
  })
}

export const saveHiredV2 = () => async (dispatch) => {
  return axios.get(
    `${API_ENDPOINT + GET_NOTIFICATIONS_AVAILABLE_FOR_USER_V2}`, { headers: await getHeaders() }
  ).then(async res => {
    dispatch({
      type: SAVE_HIRED,
      payload: res.data.payload
    })
  }).catch(async reason => {
    return reason.data
  })
}

export const getAttributeListV2 = () => async (dispatch) => {
  return axios.get(
    `${API_ENDPOINT+GET_ATTRIBUTE_LIST_ROUTE_V2}`, { headers: await getHeaders() }
  ).then(async res => {
      dispatch({
        type: GET_ATTRIBUTE_LIST,
        payload: res.data.payload
      })
  }).catch((reason) => {
    return reason.data
  })
}

export const consultNotificationsProgressV2 = (body) => async dispatch =>{
  return axios.post(
    `${API_ENDPOINT + GET_NOTIFICATIONS_PROGRESS_V2}`, {'data':body}, { headers: await getHeaders() }
  ).then(async res => {
    dispatch({
      type: STATE_NOTIFICATION_PROGRESS,
      payload: res.data.payload
    })
  }).catch(async (reason)=>{
    return reason.data
  })
}

export const sendMassiveFileV2 = (body) => async (dispatch) => {
  return axios.post(
    `${API_ENDPOINT + SEND_FILE_MASSIVE_V2}`,
    {'data':body}, { headers: await getHeaders() }
  ).then(async res => {
    
    dispatch({
      type: SAVE_FILE_MASSIVE,
      payload: res.data.payload
    })
  }).catch(async reason => {
    return reason.data
  })
} 

export const validateTagsMassiveFile = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  return axios.post(
    `${API_ENDPOINT + VALIDATE_TAGS_FILE_MASSIVE}`,
    {data: encryptedBody }, { headers: await getHeaders() }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      
      type: SAVE_FILE_MASSIVE,
      payload: decryptedData
    })
    return decryptedData
  }).catch(async reason => {
    const decryptedData = await decrypt(reason.response.data.result);
    return decryptedData
  })
} 

export const cancelMassiveSendV2 = (body) => async (dispatch) =>{
  return axios.post(
    `${API_ENDPOINT + CANCEL_MASSIVE_SEND_V3}`, {'data': body}, { headers: await getHeaders() }
  ).then(async res => {
    return res.data
  }).catch(async reason =>{
    return reason.data
  })
}

export const deleteLoadNotificationItemV2 = (body) => async (dispatch) =>{
  return axios.post(
    `${API_ENDPOINT + DELETE_MASSIVE_NOTIFICATION_RECORD_V2}`, {'data': body}, { headers: await getHeaders() }
  ).then(res => {
    return res.data
  }).catch(async reason =>{
    return reason.data
  })
}

export const setErrorForm = data => {
  return {
    type: FORM_ERROR,
    payload: data
  }
}