import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Form } from 'semantic-ui-react'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import CheckboxForm from '../../components/CheckboxForm/CheckboxForm'
import Header from '../../components/Header/Header'
import {
  changeHeaderCheckbox,
  changeWriteNotificationForm,
  clearEditorChange,
  saveHiredV2,
  inactiveFormProvider,
  cleanWriterFormOnly
} from '../../actions/writerActions'
import { ReactComponent as AlertIcon } from '../../assets/icons/write/warning-alet-icon.svg'
import { saveDraftData } from '../../actions/draftsActions'
import { closewriteNotificationModal } from '../../actions/modalActions'
import { clearDraftData } from '../../actions/draftsActions'
import { ReactComponent as Close } from '../../assets/icons/write/close.svg'
import { ReactComponent as DisplayIconOn } from '../../assets/icons/write/display-icon-on.svg'
import { ReactComponent as DisplayIconOff } from '../../assets/icons/write/display-icon-off.svg'
import moment from 'moment'

const Container = styled.div`
  margin: 15px 30px 0 30px ;
  @media only screen and (max-width: 1500px) {
    margin: 10px 16px;
  }
`

const HeaderTitle = styled(Header)`
  color: #133F4F;
  font-weight: 600;
  @media only screen and (max-width: 1500px) {
    font-size: 18px !important;
  }
`
const NotificationCount = styled.p`
  display: none;
  color: #E35F00;
  line-height: 24px;
  position: absolute;
  right: 25%;
  @media only screen and (max-width: 1500px) {
    display: block;
    font-size: 16px !important;
    right: 12%;
  }

  @media only screen and (max-width: 500px) {
    position: inherit;
    left: 0;
  }
`

const TypeNotificationCount = styled.p`
  @media only screen and (max-width: 1500px) {
    display: none;
  }
`

const ChecksBox = styled(Form)`

  @media only screen and (max-width: 1500px) {
    ${props => props.changeViewCheckbox &&
    css`
        display: ${props.changeViewCheckbox.display};
      `}
  }
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: inherit;
  position: relative;
`

const CloseButton = styled.a`
  width:16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 3px 0px;
`
const TextTypeContainer = styled.div`
  width:100%;
  margin 10px auto;
  position: relative;
  display:none;
  @media only screen and (max-width: 1500px) {
    display:block;
  }
`
const TextType = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #22C1D0;
  text-align: left;
  cursor: pointer;
`
const DisplayIconUp = styled(DisplayIconOn)`
  position: absolute;
  right: 0;
`

const DisplayIconDown = styled(DisplayIconOff)`
  position: absolute;
  right: 0;
`
const Text = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-right: 15px;
`
const TextAlert = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21.09px;
`
const TextAlert2 = styled.p`
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.09px;
`
const ListItem = styled.li`
  float: left;
  color: #E35F00;
  width: 100%;
`
const WarningList = styled.ul`
  margin: 0;
  padding-left: 3%;
  width: 90%;
`
const CustomDiv = styled.div`
  display: flex;
  padding: 16px 24px 16px 24px;
  width: 100%;
  background: rgba(227, 95, 0, 0.1);
  border: 1px solid #E35F00;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 10px 0 24px 0;
  align-items:center;
  height:auto;
  min-height: 56px;
  font-weight: 400;
  font-size: 18px !important;
  line-heigt:21.09px;
  gap:10px;
`
const ViewHeader = () => {

  const dispatch = useDispatch()
  const [formValidator, setShow, setFields] = useValidator(reactValidatorOptions)
  const checkboxHeaderDisabled = useSelector(state => state.writerReducer.checkboxHeaderDisabled)
  const files = useSelector(state => state.writerReducer.notificationForm.files)
  const specificFiles = useSelector(state => state.writerReducer.listDocumentsSpecific)
  const readReceipt = useSelector(state => state.writerReducer.notificationForm.readReceipt)
  const notificationSignature = useSelector(state => state.writerReducer.notificationForm.notificationSignature)
  const notificationsAvailable = useSelector(state => state.writerReducer.notificationsAvailable)
  const pushNotification = useSelector(state => state.writerReducer.notificationForm.pushNotification)
  const hiredData = useSelector(state => state.writerReducer.hiredData)
  const draftData = useSelector(state => state.draftReducer.toSaveDraft)
  const updateForm = changeWriteNotificationForm
  const cleanWriterForm = cleanWriterFormOnly
  const [allFiles, setAllFiles] = useState([])

  const [state, setState] = useState({
    notificationSignature: notificationSignature,
    readReceipt: readReceipt,
    pushNotification: pushNotification,
    disableNotificationSignature: false,
    closeTypeNotification: true,
  })

  useEffect(() => {
    setState((prevState) => ({ ...prevState, subjectError: true }))
    setState((prevState) => ({ ...prevState, disableNotificationSignature: checkboxHeaderDisabled }))
    setState((prevState) => ({ ...prevState, notificationSignature: false }, async () => {
      dispatch(updateForm("notificationSignature", notificationSignature))
    }))
  }, [checkboxHeaderDisabled])

  useEffect(() => {
    let fullFiles = []
    if (!!files) {
      if (!!Object.keys(files).length) {
        Object.keys(files).map((file) => {
          const tempFile = files[file]
          fullFiles = [...fullFiles, tempFile]
        })
      }
    }
    if (!!specificFiles) {
      if (!!Object.keys(specificFiles).length) {
        Object.keys(specificFiles).map((file) => {
          const tempFile = specificFiles[file]
          fullFiles = [...fullFiles, { name: tempFile.name, sign: tempFile.isSignedFile }]
        })
      }

    }
    setAllFiles(fullFiles)

  }, [specificFiles, files])

  useEffect(() => {
    dispatch(saveHiredV2())
    if (!!allFiles) {
      allFiles.map((file) => {
        if (file.sign) {
          setState((prevState) => ({ ...prevState, disableNotificationSignature: false }))
          dispatch(changeHeaderCheckbox(true))
          setState((prevState) => ({ ...prevState, notificationSignature: false }))
          dispatch(updateForm("notificationSignature", false))
          return
        }
      })
    }
  }, [allFiles])

  
  useEffect(() => {
    setState((prevState) => ({ ...prevState, readReceipt: readReceipt }))
  }, [readReceipt])

  useEffect(() => {
    setState((prevState) => ({ ...prevState, notificationSignature: notificationSignature }))
  }, [notificationSignature])

  useEffect(() => {
    setState((prevState) => ({ ...prevState, pushNotification: pushNotification }))
  }, [pushNotification])

  useEffect(() => {
    setState((prevState) => ({ ...prevState, hiredData: hiredData }))
  }, [hiredData])

  useEffect(() => {
    validateTypeOfNotification(state.idNotificationType)
  }, [state])

  const validateTypeOfNotification = (id) => {
    dispatch(updateForm(id, state[id]))
    switch (id) {
      case 'notificationSignature':
        dispatch(saveDraftData({ requiredSignature: state[id] }))
        break;
      case 'readReceipt':
        dispatch(saveDraftData({ requiredConfirmationRead: state[id] }))
        break;
      case 'pushNotification':
        dispatch(saveDraftData({ isPushNotification: state[id] }))
        break;
      default:
        break;
    }
  }

  const toggle = (data) => {
    const id = data.target.id
    setState(
      (prevState) => ({ ...prevState, [id]: !prevState[id], idNotificationType: id })
    )
  }

  const clearState = async () => {
    dispatch(closewriteNotificationModal())
    dispatch(inactiveFormProvider())
    dispatch(cleanWriterForm())
    dispatch(clearEditorChange())
    dispatch(clearDraftData())
  }


  const changeViewNotificationType = () => {
    setState((prevState) => ({ ...prevState, closeTypeNotification: !state.closeTypeNotification }))
  }

  const changeDateViewNotificationType = (close) => {
    if (!!close) {
      return { display: `none` }
    } else {
      return { display: `block` }
    }
  }
  
  const formatDate = (date) => {
    return moment(date).format('DD/MMM/YYYY');
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle as='h1'>Nueva Notificación</HeaderTitle>
        <NotificationCount>
          {notificationsAvailable !== null ? `Disponibles: ${notificationsAvailable}` : ''}
        </NotificationCount>
        <CloseButton
          onClick={() => clearState()}
        >
          <Close />
        </CloseButton>
      </HeaderContainer>
      {hiredData.closeToExpiration &&
        <CustomDiv >
          <AlertIcon /><TextAlert><strong>La vinculación actual vence el {formatDate(hiredData.dateToExpiration)}.</strong> Recuerda tener una vinculación aprobada para continuar con el envío de notificaciones</TextAlert>
        </CustomDiv>
      }

      <TextTypeContainer
        onClick={changeViewNotificationType}
        style={!!state.closeTypeNotification ? { "borderBottom": '1px solid #979E9F' } : { "borderBottom": '0' }}
      >
        <TextType>Tipo de Notificación {!!state.closeTypeNotification ? <DisplayIconDown /> : <DisplayIconUp />}</TextType>
      </TextTypeContainer>
      <ChecksBox changeViewCheckbox={changeDateViewNotificationType(state.closeTypeNotification)}>
        <Form.Group inline>
          {
            notificationsAvailable !== null
              ?
              <TypeNotificationCount>
                <Text>
                  Notificaciones disponibles: {notificationsAvailable}
                </Text>
              </TypeNotificationCount>
              : ''
          }
          <CheckboxForm
            label='Firma en notificación'
            onChange={toggle}
            value={state.notificationSignature}
            validator={formValidator}
            validateOptions='required'
            id='notificationSignature'
            disabled={hiredData.servicesList.notificationSignature === false ? true : checkboxHeaderDisabled === true ? true : false}
          />
          <CheckboxForm
            label='Confirmación de lectura'
            onChange={toggle}
            value={state.readReceipt}
            validator={formValidator}
            validateOptions='required'
            id='readReceipt'
            disabled={!hiredData.servicesList.notificationReading}
          />
          <CheckboxForm
            label='Notificación push'
            onChange={toggle}
            value={state.pushNotification}
            validator={formValidator}
            validateOptions='required'
            id='pushNotification'
            disabled={!hiredData.servicesList.pushNotification}
          />
        </Form.Group>
      </ChecksBox>
    </Container>
  )
}

export default ViewHeader