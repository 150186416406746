import React, { useState } from 'react'
import { Image } from "semantic-ui-react"
import './style.css'

const ButtonWhite = (props) => {
  const [state, setState] = useState({
    hovered: false
  })

    return (
      <div
        className="greenHover"
        onMouseOut={() => setState({ hovered: false })}
        onMouseOver={() => setState({ hovered: true })}
        onClick={props.onClick}
      >
        <div className='optionsContent'>
            {
              props.icon &&
              <div
                style={{
                  padding: '0px 10px',
                }}>
                <Image
                  src={props.icon}
                  style={{
                    width: 'auto',
                    margin: 'auto',
                  }}
                />
              </div>
            }
            <div
              style={{
                color: `${props.color}`,
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: '0px',
              }}>
              {props.value.title}
            </div>
          </div>
      </div>
    )
  }

export default ButtonWhite
