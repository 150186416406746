import axios from 'axios'
import { jwtDecode } from "jwt-decode"
import { API_ENDPOINT } from "../config/config"
import {
  SET_ROLES,
  CLEAR_LOGIN,
  CHANGE_STATE_LOGIN_MODAL,
  CLEAR_ERRORS,
  LOGOUT,
  SET_CURRENT_USER,
  STATE_CLEAR_FILTER,
  GET_LIST_CERTIFICATE,
  GET_LIST_CERTIFICATE_2,
  GET_QR,
  RESET_DASHBOARD,
  UPDATE_SESSION_ACTIVE,
  SET_LOGIN_TYPE,
  VALID_TOKEN,
  RESET_CAPTCHA,
  CHANGE_ROLE_STATE_MODAL,
  CLEAR_INTERVAL_TO_BE_NOTIFIED,
  SHOW_AVATAR_INFO,
} from "./actionsUtilities/types.js"

import setAuthToken from "../utils/setAuthToken"
import { reasonDispatch } from "./DispatchGeneric"
import { getHeaders, validateClearCookies } from "../helpers/globalHelpers"
import {
  RECOVER_PASSWORD,
  LOGIN_LIST_CERTIFICATE,
  LOGIN_GET_QR,
  AUTENTICATION_WITH_QR,
  RESET_QR,
  LOGIN_VALIDATE_CERTIFICATE_V2,
  REFRESH_TOKEN_V3,
  SELECT_ROL,
  CHANGE_ROL,
  CHECK_LOGIN_STATUS,
  LOGIN_USER_ROUTEV2,
  VERIFY_CAPTCHA,
  VERIFY_CAPTCHA_V2,
  GET_COOKIES,
  CLEAR_COOKIES,
  UPDATE_VIEW_AVATAR_INFO
} from './actionsUtilities/actionsRoutes.js'
import { createSharedKey, decrypt, encrypt } from '../helpers/cypher'
import axiosRetry from 'axios-retry'
import { axiosConfig } from './actionsUtilities/retryRequest.js'
import { closewriteNotificationModal } from './modalActions.js'
import { deleteCookies } from '../utils/cookies.js'

axiosRetry(axios, axiosConfig);

/**
 * AuthAction loginUser ( set user credentials to server for login attempt )
 *
 * @param history
 * @param {*} userData
 */
export const loginUserCipher = (navigate, userData) => async (dispatch) => {
  const encryptedBody = await encrypt(userData);
  await axios.post(`${API_ENDPOINT + LOGIN_USER_ROUTEV2}`, { data: encryptedBody }, { headers: await getHeaders(true) })
    .then(async res => {
      const { token, changePassword, refreshToken, viewInfoAvatar } = await decrypt(res.data.result);
      if (navigate) {
        setAuthToken(token, refreshToken)
        const decoded = jwtDecode(token)
        dispatch({ type: SHOW_AVATAR_INFO, payload: viewInfoAvatar })
        dispatch(setCurrentUser(decoded))
        if (decoded) redirectAfterLogin(decoded.role, navigate)
        dispatch({ type: SET_LOGIN_TYPE, payload: 'withOutCertificate' })
      }
      dispatch({ type: CLEAR_ERRORS })
    }).catch(async reason => {
      await reasonDispatch(dispatch, reason, false)
      dispatch({ type: RESET_CAPTCHA })
      dispatch({ type: VALID_TOKEN, payload: {} })
    })
}

const redirectAfterLogin = (role, navigate) => {
  if (role === '64d3c96218d350a93692cd7c') {
    navigate('/improperClients')
  } else {
    navigate('/dashboard')
  }
}
export const updateViewAvatarInfo = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  const customHeaders = await getHeaders()
  await axios.post(
    `${API_ENDPOINT + UPDATE_VIEW_AVATAR_INFO}`, { data: encryptedBody }, { headers: customHeaders }
  ).then(async res => {
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}
export const getRefreshToken = (body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  await axios.post(
    `${API_ENDPOINT + REFRESH_TOKEN_V3}`, { data: encryptedBody }, { headers: await getHeaders() }
  ).then(async res => {
    const { token, changePassword, refreshToken } = await decrypt(res.data.result);
    setAuthToken(token.token, refreshToken)
    const decoded = jwtDecode(token.token)
    dispatch(setCurrentUser(decoded))
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const changeStatusSession = (status) => async (dispatch) => {
  dispatch({
    type: UPDATE_SESSION_ACTIVE,
    payload: status
  })
}


export const getListCertificate = (navigate, userData) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(`${API_ENDPOINT}${LOGIN_LIST_CERTIFICATE}?documentNumber=${userData}`
  ).then(async res => {
    const { certificates, pubKey } = res.data.result
    sessionStorage.setItem('pubKeyServer', pubKey)
    createSharedKey(pubKey)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({
      type: GET_LIST_CERTIFICATE_2,
      payload: certificates
    })
    if (navigate) {
      navigate(`/login-certificate/${userData}`)
      // history.push({ pathname: `/login-certificate/${userData}` })
    }
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({ type: RESET_CAPTCHA })
    dispatch({ type: VALID_TOKEN, payload: {} })
  })
}

export const listCertificate = (userData) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })

  await axios.post(`${API_ENDPOINT}${LOGIN_LIST_CERTIFICATE}?userSerialID=${userData}`
  ).then(res => {
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({
      type: GET_LIST_CERTIFICATE,
      payload: res.data.result.certificates
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const validateCertificate = (navigate, body) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  dispatch({ type: CLEAR_ERRORS })
  const encryptedBody = await encrypt(body);

  await axios.post(`${API_ENDPOINT}${LOGIN_VALIDATE_CERTIFICATE_V2}`, { 'data': encryptedBody }, { headers: await getHeaders(true) })
    .then(async res => {
      const decryptedData = await decrypt(res.data.result);
      dispatch({
        type: CHANGE_STATE_LOGIN_MODAL,
        payload: { type: 'progress' }
      })
      if (decryptedData.OTP === false || body.isFirstLogin) {
        dispatch({
          type: CHANGE_STATE_LOGIN_MODAL,
          payload: { type: 'sendEmail' }
        })
      } else {
        navigate(`/login-QR2/${body.userSerialIDs[0]}`)
      }
      dispatch({ type: CLEAR_ERRORS })
    }).catch(async reason => {
      await reasonDispatch(dispatch, reason, false)
      dispatch({
        type: CHANGE_STATE_LOGIN_MODAL,
        payload: { type: 'progress' }
      })
    })
}

export const getQr = (userData) => async (dispatch) => {

  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${LOGIN_GET_QR}?userSerialID=${userData}`
  ).then(res => {

    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    dispatch({
      type: GET_QR,
      payload: res.data.result.response
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const postLogin = (navigate, body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch({ type: CHANGE_STATE_LOGIN_MODAL, payload: { type: 'progress' } })
  dispatch({ type: CLEAR_ERRORS })
  await axios.post(
    `${API_ENDPOINT}${AUTENTICATION_WITH_QR}`, { 'data': encryptedBody }, { headers: await getHeaders(true) },
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);

    dispatch({ type: CHANGE_STATE_LOGIN_MODAL, payload: { type: 'progress' } })
    const { rolesInfo, tokenResult, idCertificate, nameCertificate } = decryptedData
    if (!!tokenResult) {
      const { token, refreshToken, viewInfoAvatar } = tokenResult
      setAuthToken(token, refreshToken)
      const decoded = jwtDecode(token)
      dispatch(setCurrentUser(decoded))
      dispatch({ type: SHOW_AVATAR_INFO, payload: viewInfoAvatar })
      if (decoded) redirectAfterLogin(decoded.role, navigate)
      dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
      dispatch({ type: CLEAR_ERRORS })
    } else {
      dispatch({
        type: SET_ROLES,
        payload: rolesInfo.roles
      })
    }
    dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const selectRole = (history, body) => async (dispatch) => {
  const encryptedBody = await encrypt(body);
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${SELECT_ROL}`, { 'data': encryptedBody }, { headers: await getHeaders(true) },
  ).then(async res => {
    const { tokenResult } = await decrypt(res.data.result);
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })

    const { token, refreshToken, viewInfoAvatar } = tokenResult
    setAuthToken(token, refreshToken)
    const decoded = jwtDecode(token)
    dispatch(setCurrentUser(decoded))
    dispatch({ type: SHOW_AVATAR_INFO, payload: viewInfoAvatar })
    if (decoded) redirectAfterLogin(decoded.role, history)
    dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const changeRole = (history, body) => async (dispatch) => {
  dispatch(closewriteNotificationModal())
  const encryptedBody = await encrypt(body);
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${CHANGE_ROL}`, { 'data': encryptedBody }, { headers: await getHeaders() },
  ).then(async res => {
    const { tokenResult } = await decrypt(res.data.result);
    dispatch({
      type: RESET_DASHBOARD
    })
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })

    const { token, refreshToken } = tokenResult
    setAuthToken(token, refreshToken)
    const decoded = jwtDecode(token)
    dispatch(setCurrentUser(decoded))
    if (decoded) redirectAfterLogin(decoded.role, history)
    dispatch({ type: SET_LOGIN_TYPE, payload: 'withCertificate' })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const resetNewQr = (userData) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: { type: 'progress' }
  })
  await axios.post(
    `${API_ENDPOINT}${RESET_QR}${userData}`
  ).then(res => {
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
    if (res.data.statusCode === 200) {
      dispatch({
        type: CHANGE_STATE_LOGIN_MODAL,
        payload: { type: 'sendEmail' }
      })
    }
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false, true)
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'progress' }
    })
  })
}

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
}

/**
 * AuthAction logoutUser ( set token for close session, use history param for redirect to login page  )
 *
 * @param {history} history
 */
export const logoutUser = (history) => async (dispatch, getState) => {
  if (!!validateClearCookies()) {
    dispatch(clearCookieHttpOnly())
  } else {
    deleteCookies()
  }
  var type = getState().authReducer.typeOfLogin
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  dispatch({ type: RESET_DASHBOARD })
  if (!!history)
    if (type !== '' && type === 'withOutCertificate') {
      history.push({ pathname: '/login-user', state: { message: 'Sesión finalizada exitosamente' } })
    } else {
      history.push({ pathname: '/', state: { message: 'Sesión finalizada exitosamente' } })
    }
  dispatch({
    type: LOGOUT
  })
  dispatch({
    type: STATE_CLEAR_FILTER
  })
  dispatch({
    type: CLEAR_INTERVAL_TO_BE_NOTIFIED
  })
}

export const sessionExpiredLogout = (history) => async (dispatch, getState) => {
  if (!!validateClearCookies()) {
    dispatch(clearCookieHttpOnly())
  } else {
    deleteCookies()
  }
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  dispatch({ type: RESET_DASHBOARD })
  dispatch({ type: CLEAR_INTERVAL_TO_BE_NOTIFIED })
  if (!!history)
    history.push({ pathname: '/sessionExpired', state: { message: 'Sesión finalizada exitosamente' } })
}

export const redirectAfterExpiredSession = (navigate) => async (dispatch, getState) => {
  var type = getState().authReducer.typeOfLogin
  if (!!navigate)
    if (type !== '' && type === 'withOutCertificate') {
      navigate({ pathname: '/login-user', state: { message: 'Sesión finalizada exitosamente' } })
    } else {
      navigate({ pathname: '/', state: { message: 'Sesión finalizada exitosamente' } })
    }
  dispatch({ type: LOGOUT })
  dispatch({ type: STATE_CLEAR_FILTER })
}

export const resetPassword = (userData) => async (dispatch) => {
  await axios.post(`${API_ENDPOINT + RECOVER_PASSWORD}`, userData).then(res => {
    dispatch({
      type: CHANGE_STATE_LOGIN_MODAL,
      payload: { type: 'sendEmailNewPassword' }
    })
    dispatch({ type: CLEAR_ERRORS })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const clearLogin = () => async (dispatch) => {
  dispatch({ type: CLEAR_LOGIN })
}

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT })
}

export const checkLoginStatus = () => async (dispatch, getState) => {
  await axios.post(`${API_ENDPOINT + CHECK_LOGIN_STATUS}`).then(async res => {
    const { pubKey, status, captcha, remainingTime, accessTime } = res.data.result
    if (!sessionStorage.getItem('pubKeyServer')) sessionStorage.setItem('pubKeyServer', pubKey)
    if (!sessionStorage.getItem('secretKey')) await createSharedKey(pubKey)
    // 
    // 
    // dispatch({ type: SET_LOGIN_STATUS, payload: { status: status ? status: 'ALLOWED', captcha: !!captcha, 
    // remainingTime: remainingTime? remainingTime : null, accessTime } })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const verifyCaptcha = (token) => async (dispatch, getState) => {
  await axios.post(`${API_ENDPOINT + VERIFY_CAPTCHA}`, { token: token }).then(async res => {
    dispatch({ type: VALID_TOKEN, payload: res.data.result })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const verifyCaptchaV2 = (token) => async (dispatch, getState) => {
  await axios.post(`${API_ENDPOINT + VERIFY_CAPTCHA_V2}`, { token: token }).then(async res => {
    dispatch({ type: VALID_TOKEN, payload: { valid: res.data.result.success } })
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const showChangeRoleModal = (moreThanOne) => async (dispatch, getState) => {
  if (moreThanOne) dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: 'changeRoleErrorPlusTwoActions' } })
  else dispatch({ type: CHANGE_ROLE_STATE_MODAL, payload: { type: 'changeRoleError' } })
}

export const getCookieHttpOnly = (name) => async (dispatch) => {
  return await axios.get(
    `${API_ENDPOINT + GET_COOKIES}${name}`,
    { headers: { 'x-api-key': sessionStorage.getItem('publicKey') } }
  ).then(async res => {
    const data = await decrypt(res.data.result);
    let valueCookie = data.value
    if (name === '__Host-jwt')
      valueCookie = `Bearer ${valueCookie}`
    return valueCookie
  }).catch(async reason => {
    return reason
  })
}

export const clearCookieHttpOnly = () => async (dispatch) => {
  await axios.get(
    `${API_ENDPOINT + CLEAR_COOKIES}`
  ).then(async res => {
  }).catch(async reason => {
    await reasonDispatch(dispatch, reason, false)
  })
}

export const showAvatarInfo = (viewInfoAvatar) => async (dispatch) => {
  dispatch({ type: SHOW_AVATAR_INFO, payload: viewInfoAvatar })
}