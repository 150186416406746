import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import QRCode from "react-qr-code"
import InputForm from '../../components/InputLogin/InputForm'
import PrimaryButton from '../../components/PrimaryButton'
import BannerImage from '../../assets/icons/login/banner.svg'
import Butterflies from '../../assets/icons/login/butterflies.svg'
import { ReactComponent as Logo } from '../../assets/icons/login/logo.svg'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import useValidator from '../../helpers/simpleReactValidatorHook'
import { Form } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import { getQr, postLogin, resetNewQr } from '../../actions/authActions'
import { Message } from 'semantic-ui-react'
import { createSharedKey, generateKeyTosessionStorage } from '../../helpers/cypher'
import { hexToBase64 } from '../../helpers/globalHelpers'
import ExpirateMessage from '../../components/ExpirateMessage/ExpirateMessage'
import { useNavigate } from "react-router-dom-v5-compat"

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BannerImage});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100vh;

  @media only screen and (max-width: 992px)  {
    background-size: initial;
  }
`
const MainSegment = styled.div`
  width: 440px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const LogoRow = styled.div`
  width: 100%;
  height: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 30px;
`
const Content = styled.div`
  width: 701px;
  height: 436px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;

  @media only screen and (max-width: 992px)  {
    width: 87.47%;
    margin: auto;
    height: auto;
  }
`
const Content2 = styled.div`
  width: 494px;
  height: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #FFFFFF;
  @media only screen and (max-width: 1500px)  {
    width: 100%;
  }
  @media only screen and (max-width: 992px)  {
    width: 85%;
    margin: auto;
  }
`
const ContentButterflies = styled.div`
 
  width: 100%;
  height: 100%;
  background-image: url(${Butterflies});
  background-repeat: no-repeat;
  background-position-x: 100%;
  padding: 56px;
  display:flex;
  flex-direction:column;
  align-items: center;
  @media only screen and (max-width: 992px)  {
    padding: 50px 20px;
  }
`
const Divider = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  height: 260px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000;
`
const Divider3 = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 260px;`

const TextFailde = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7C7C74;
  text-align: center;
`
const ForgotPassword = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #22C1D0;
  cursor: pointer;
  border: none;
`
const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;  
  text-align: center;
  color: #000000;
`
const Text2 = styled.p`
  width: 382px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;  
  text-align: center;
  color: #000000;
`
const Footer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end
`
const DividerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 992px)  {
    margin-top: 24px;
    flex-direction: column;
    gap: 24px;
    width: auto;
  }

`
const CustomForm = styled(Form)`
  max-width:289px !important; 
  display: flex !important;
  flex-direction: column !important;
  background-color: #fff;
  align-items: center !important;
  & > * {
    margin: 10px 0 !important;
  }
  @media only screen and (max-width: 992px)  {
   gap: 20px;
  }
`
const CustomPrimaryButton = styled(PrimaryButton)`
  background: #22C1D0 !important;
  border-radius: 4px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  padding: 16px 32px !important;;
  width: 100% !important;
`
const MessageLogin = styled(Message)`
  width: 100% !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  background: rgba(186, 53, 85, 0.1) !important;
  border: 1px solid #BA3555 !important;
  box-sizing: border-box !important;
  border-radius: 4px;
  color: #BA3555 !important;
  margin: 10px !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
`

const QRContainer = styled.div`
  display: flex;
  justify-content:center;
  height: auto;
  max-width: 244px; 
  width: 244px;
  margin-left: 15px;
  @media only screen and (max-width: 992px)  {
    margin:0;
    width: 216px;
  }
`

const FormContainer = styled.div`
 
  @media only screen and (max-width: 992px)  {
    margin:0;
    width: 100%;
  }
`
const ForgotPassword2 = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #22C1D0;
  cursor: pointer;
  border: none;
  background: white;
`
const QRView = () => {

  const dispatch = useDispatch()
  const customEqual = (oldValue, newValue) => oldValue === newValue
  const { code } = useSelector((state) => state.authReducer, customEqual)
  const { showError, errorsForm } = useSelector((state) => state.errorReducer)
  const [error, setError] = useState('')
  const [codeState, setCodeState] = useState('')
  const [codeNumber, setCodeNumber] = useState('')
  const [validator, showValidationMessage] = useValidator(reactValidatorOptions)
  const { userId, key } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!!key) {
      (async () => {
        const keyBase64 = hexToBase64(key)
        await generateKeyTosessionStorage().then(async () => {
          sessionStorage.setItem('pubKeyServer', keyBase64)
          await createSharedKey(keyBase64)
        })
      })();
    }
  }, [key])

  useEffect(() => {
    if (showError === true) {
      setError(errorsForm)
    }
  }, [showError])

  const onChange = e => {
    setCodeNumber(e.target.value)
  }
  const onChangeReset = () => {
    dispatch(resetNewQr(userId))
  }
  useEffect(() => {
    dispatch(getQr(userId)).then(() => { setCodeState(code.seed) })
  }, [])

  const handleRestart = () => {
    dispatch(getQr(userId)).then(() => { setCodeState(code.seed) })
  }

  const setLogin = (e) => {
    e.preventDefault()
    const body = {
      "userSerialID": userId,
      "OTPcode": codeNumber
    }
    if (validator.allValid()) {
      dispatch(postLogin(navigate, body))
    } else {
      showValidationMessage(true);
    }
  }
  return (
    <>
      {
        !!code && Object.keys(code).length !== 0 ? 
          <Banner>
            <MainSegment>
            <LogoRow>
              <Logo />
            </LogoRow>
            <>
              {code.hasOTP ?
                <Content2>
                  <ContentButterflies>
                    <Text>Ya tienes generado el código de certificado <br/> asociado, ingrésalo desde la aplicación Authenticator.</Text>
                    <CustomForm className='ui form' onSubmit={setLogin}>
                      <InputForm
                        id={'code2'}
                        label={'Código'}
                        type={'text'}
                        onChange={onChange}
                        value={codeNumber}
                        validator={validator}
                        validateOptions={['required', { min: 4 }, { max: 15 }]}
                      />
                      {error.message && <MessageLogin content={error.message} />}
                      <CustomPrimaryButton
                        fluid={true}
                        txtBtn='Ingresar'
                        login={true}
                      />
                    </CustomForm>
                    <Footer>
                      <ForgotPassword2 onClick={onChangeReset}> Perdí mi acceso al código </ForgotPassword2>
                    </Footer>
                  </ContentButterflies>
                </Content2>
                :
                <Content>
                  <ContentButterflies>
                    <>
                      <Text style={{marginBottom:'20px'}}>Escanea el QR con tu aplicación Authenticator e ingresa el código a continuación.</Text>
                      <DividerContent>
                        {
                          error.message === 'Internal Error' ?
                            <Divider>
                              <TextFailde>Error al cargar el código QR</TextFailde>
                              <ForgotPassword onClick={handleRestart} >
                                Cargar nuevamente
                              </ForgotPassword>
                            </Divider>
                            : <QRContainer>
                              {!!code.seed &&
                                <QRCode
                                  size={156}
                                  style={{ height: "auto", width: "100%" }}
                                  value={`otpauth://totp/GSE:FirmayaDriverOTP_${code.idCertificate}?secret=${code.seed}`}
                                  viewBox={`0 0 256 256`}
                                />
                              }
                            </QRContainer>
                        }
                        <FormContainer >
                          <CustomForm className='ui form' onSubmit={setLogin}>
                            <InputForm
                              id={'code'}
                              label={'Código'}
                              type={'text'}
                              onChange={onChange}
                              value={codeNumber}
                              validator={validator}
                              validateOptions={['required', { min: 4 }, { max: 15 }]}
                              autocomplete="code"
                            />
                            {error.message !== 'Internal Error' && showError === true && <MessageLogin content={error.message} />}
                            <CustomPrimaryButton
                              fluid={true}
                              txtBtn='Ingresar'
                              login={true}
                            />
                          </CustomForm>
                        </FormContainer>
                      </DividerContent>
                    </>

                  </ContentButterflies>
                </Content>
              }
            </>
            </MainSegment>
          </Banner>
       : !!code && Object.keys(code).length === 0 && errorsForm.internalCode === 'EIE' || errorsForm.internalCode === 'NEU' ?
        <ExpirateMessage
              text={'¡Este enlace ha expirado!'}
        />
        : 
        <Banner>
          <MainSegment>
            <LogoRow>
              <Logo />
            </LogoRow>
            <Content2>
              <ContentButterflies>
                <DividerContent>
                  <Divider3>
                    <div class="lds-spinner" style={{width: "400px", height: "80%"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  </Divider3>
                </DividerContent>
              </ContentButterflies>
            </Content2>
          </MainSegment>
        </Banner>
      }
    </>
  )
}
export default QRView