import React from 'react'
import PropTypes from "prop-types"
import { useDispatch } from 'react-redux'
import './input-from-massive-load.css'
import styled from "styled-components"
import { Form } from "semantic-ui-react"

const Text = styled.div`

`
const Text2 = styled.span`
    
`
const InputFormLabelsName = ({ ...props }) => {

    const dispatch = useDispatch()
    const { className, placeholder, type, id, onChange, value, label, validator, validateOptions, defaultValue, disabled, readOnly } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator ? validator.message(label !== '' && label !== undefined ? label : placeholder, value, validateOptions) : ''

    return (
        <Form.Field>
            <div>
                <label className={errorValidator ? 'labelInputFormLabelsNameError' : 'labelInputFormLabelsName'}>
                    <input
                        id={id}
                        type={type}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={!errorValidator? placeholder:''}
                        onChange={onChange}
                        required
                        className={className+' InputFormLabelsName'}
                        disabled={disabled}
                        readOnly={readOnly}
                        autoComplete="off"
                    />
                    {
                        errorValidator && value === ''
                        &&
                        <>
                            <Text2 className={errorValidator && value === '' && 'spanLabelInputTopError'}>{label}</Text2>
                            <Text className={(errorValidator) && 'input-error3'}>{errorValidator}</Text>
                        </>
                    }
                    {
                        errorValidator === undefined && value !== ''
                        &&
                        <>
                            <span className={'spanLabelInputTop'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator === undefined && value === ''
                        &&
                        <>
                            <span className={'spanLabelInput'}>{label}</span>
                        </>
                    }
                    {
                        errorValidator && value !== ''
                        &&
                        <>
                            <Text2 className={'spanLabelInputBottomError'}>{label}</Text2>
                            <Text className={'input-error2'}>{errorValidator}</Text>
                        </>
                    }
                </label>
            </div>

        </Form.Field>
    )
}


InputFormLabelsName.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormLabelsName