import React, { useState } from 'react'
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale';
import moment from 'moment';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css";
import { defaultStaticRanges } from '../../../../helpers/defaultRanges';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { UseWindowDimensions } from '../../../../utils/UseWindowsDimentions';


const Divider = styled.div`
    align-items: center;
    background: #EBEBEB;
    color: #133F4F;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 48px;
    margin: auto;
    margin-top: 20px;
    border: 0.5px solid #D6D6D6;
    border-bottom: none;
    
    @media only screen and (min-width: 1440px) {
     margin-left: 0px;
    }
`
const TextDate = styled.p`
    color: #133F4F;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 13px;
    padding-top: 13px;
`
const DropSent = styled(Dropdown)`
  &&& .icon {
    top: 25% !important; 
  }
  &&& .item {
    height: 32px; 
    line-height: 16px; 
    align-items:center;
    font-size: 16px;
  }
  margin-left: 15px;
  display: none !important;
  @media only screen and (max-width: 1024px) {
    display: inline-block !important;
    margin-top: 0!important;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    margin-top: 10px;
  }
`
const options = [
  { key: '', text: '', value: '' },
  { key: 'hoy', text: 'Hoy', value: 'Hoy' },
  { key: 'ultimasemana', text: 'Última semana', value: 'Última semana' },
  { key: 'ultimos15dias', text: 'Últimos 15 días', value: 'Últimos 15 días' },
  { Key: 'ultimomes', text: 'Último mes', value: 'Último mes' },
  { key: 'ultimoaño', text: 'Último año', value: 'Último año' },
  { key: 'todo', text: 'Todo', value: 'Todo' },
]

const DateFilterUserDocumentsAndDocuments = (props) => {
  const today = new Date()
  const oneHundred = new Date("2021-08-01T05:00:00.000Z")
  const { date } = useSelector((state) => state.filterReducer.searchAdvanced)
  let dateBefore = {
    startDate: today,
    endDate: today,
    key: "selection"
  }
  if (date.endDate !== "") {
    dateBefore = date
  }

  const [state, setState] = useState(dateBefore)
  const [active, setActive] = useState(false)
  const [dropValue, setDropValue] = useState('')

  useEffect(() => {
    props.setDateState(state)
  }, [])

  useEffect(() => {
    if (state.startDate.toString() !== oneHundred.toString()) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [state])

  const formatDate = (date) => {
    return moment(date).format('DD MMMM YYYY');
  }

  const handlerDate = (item) => {
    setState({ ...item.selection })
    props.setDateState({ ...item.selection })
  }

  const handleOnChangeDate = (e, { value }) => {
    defaultStaticRanges.forEach(element => {
      if (element.label == value) {
        setState({ ...element.range(), key: "selection" })
        props.setDateState({ ...element.range(), key: "selection" })
      }
    });
    setDropValue(value)
  }


  return (
    <>
      <DropSent selection button placeholder='Período de tiempo'
        options={options}
        style={{ width: "314px", margingBottom: '5px', marginTop: UseWindowDimensions().width > 768 ? '24px' : '0' }}
        value={dropValue}
        onChange={handleOnChangeDate}
        id="state"
      />
      <Divider>
        <TextDate style={{ margin: '0px' }}>{active === false ? '' : formatDate(state.startDate)}</TextDate>
        {
          active === false
            ? <TextDate>Todo</TextDate>
            : <ArrowForwardIcon />
        }
        <TextDate style={{}}>{active === false ? '' : formatDate(state.endDate)}</TextDate>
      </Divider>
      <DateRangePicker
        style={(active === false) ? { "pointer-events": "none", opacity: 0.3 } : {}}
        ranges={[state]}
        onChange={handlerDate}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={UseWindowDimensions().width > 768 ? 2 : 1}
        direction="horizontal"
        locale={es}
        staticRanges={defaultStaticRanges}
        monthDisplayFormat="MMMM YYY"
        maxDate={today}
      />
    </>
  )
}

export default DateFilterUserDocumentsAndDocuments
