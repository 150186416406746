import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/icons/sent/logo.svg'
import { ReactComponent as SessionClosedIcon } from '../../assets/icons/dashboard/sessionClose.svg'
import { useDispatch } from 'react-redux'
import PrimaryButton from '../../components/PrimaryButton'
import { redirectAfterExpiredSession } from '../../actions/authActions'
import { useNavigate } from 'react-router-dom-v5-compat'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: #133F4F;
  color: #FFFF;
  height: 100px;
  margin: 0;
  padding: 0 40px;
  @media only screen and (max-height: 900px)  {
    height:12vh;
  }
  @media only screen and (max-width: 768px)  {
    height:50px;
    padding: 0px 15px;
  }
`

const LogoButton = styled(Logo)`
  @media only screen and (max-width: 768px)  {
    width:150px;
  }
`

const Container2 = styled.div`
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 `

const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 17px;
  font-style: normal;
  line-height: 23.44px;
  text-align: center;
  margin-bottom: 15px !important;
 `

export const SessionClose = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOnClick = () => {
    dispatch(redirectAfterExpiredSession(navigate))
  }

  return (
    <div style={{ height: '100vh' }}>
      <Container>

        <LogoButton />
      </Container>
      <Container2 style={{ marginTop: '-50px' }}>
        <Texto>¡Sesión cerrada por inactividad!</Texto>
        Para continuar, inicia tu sesión nuevamente.
        <SessionClosedIcon style={{ marginBottom: '50px', marginTop: '30px' }} />
        <PrimaryButton txtBtn={'Iniciar sesión'} onPress={handleOnClick} />
      </Container2>
    </div>
  )
}
