import React from 'react'
import Paginacion from '../Paginacion'
import SearchBar from '../SearchBar'
import styled from 'styled-components'
import PopUpOrder from '../PopUpOrder'
import ButtonEntity from '../ButtonNewClient'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2% 0;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    .div1 {
      padding: 1% 0;
      order: 2;
      width: 100%;
    }
    .div2 {
      order: 3;
    }
    .div3 {
      order: 1;
      width: 95%;
    }
    .div4 {
      display: none;
    }
  }

`
const InsideDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    direction: ltr;
  }
`

export const ContainerClientSearch = () => {
  return (
    <Divider>
      <div1 class='div1'>
        <SearchBar textPlaceHolder={'Buscar'} />
      </div1>
      <div2 class='div2'>
        <InsideDiv>
          <PopUpOrder />
          <div4 class='div4'>
            <Paginacion />
          </div4>
        </InsideDiv>
      </div2>
      <div3 class='div3'>
        <ButtonEntity />
      </div3>
    </Divider>
  )
}

export default ContainerClientSearch