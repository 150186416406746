import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import GeneralTable from '../../../../components/Table/GeneralTable';
import {actionClearFilter, activePagination } from '../../../../actions/filterDetailActions'
import {getLogsList} from "../../../../actions/clientActions"
import RowTableLogs from './RowTableLogs'

const Logs = () => {

  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(true)

  const filterReducer = useSelector(state => state.filterDetailReducer)
  const clientDetail = useSelector(state => state.clientReducer.detailClient)
  const logsList = useSelector(state =>  state.clientReducer.logsList)
  const userRole = useSelector(state => state.authReducer.user.roleName)
  const dispatch = useDispatch()

  const makeLogsRequest = useCallback((filterReducer) => {
      const body={
      companySerialID: clientDetail.companySerialID,
      pag: filterReducer.pag,
      count: !!filterReducer.count ? filterReducer.count : 20,
      order: orderTranslator(filterReducer.sort),
      userName: filterReducer.searchBar[0],
      action:filterReducer.logsFilter.action
    }
      dispatch(getLogsList(body)).then(() => {
        dispatch(activePagination(false))
        setInit(false)
        setLoader(false)
      })
  }, [dispatch, clientDetail])

  useEffect(() => {
    if(filterReducer.tabFilter !== 'Logs'){
      setInit(false)
      dispatch(actionClearFilter())
    }else{
      makeLogsRequest(filterReducer)
    }
  }, [filterReducer.tabFilter])

  useEffect(() => {
    if (!init) {
      makeLogsRequest(filterReducer)
      setLoader(true)
      setInit(true)
    }
  },[filterReducer.pag, filterReducer.count, filterReducer.sort, filterReducer.searchBar, filterReducer.logsFilter.action])

  const orderTranslator = (order) => {
    const options = {
      'masReciente':  -1,
      'masAntiguo':  1,
      
    }
    return options[order]
  };

  
  const columnData =[
    { name: "Acción", width: 2, arrow: false },
    { name: "Usuario", width: 2, arrow: false },
    { name: "Fecha y hora", width: 2, arrow: false },
  ]

    return (
      <GeneralTable
        list={logsList}
        typeList="logs"
        headerTable={columnData}
        rowComponent={RowTableLogs}
        loader={loader}
        heigthHeader ={'45px'}
        noWrap={false}
      />
    )
}

Logs.propTypes = {
  
}


export default Logs