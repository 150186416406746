import React from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'
import { warningFormModalUserandEmployes } from '../../../actions/modalActions'
import { ReactComponent as LeaveIcon } from '../../../assets/icons/leaveIcon.svg'
import '../../Modal/messageModal.css'
import PropTypes from "prop-types"
import { getUsers, inactiveFormUser, inactiveFormUserEdit, putStateActive, putStateInactive } from '../../../actions/usersActions'
import {  getEmployees } from '../../../actions/clientActions'
import { useNavigate, useLocation } from 'react-router-dom-v5-compat'

const ModalElement = styled(Modal)`
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 15px !important;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 71px;
  height: 42px;
  margin-Right: 16px;
  cursor: pointer;
`

const WarningModalUserAndEmployes = (props) => {
    const modalData = (type) => {
        const data = {
            warningLeaveUser: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, este nuevo usuario no quedará guardado",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningLeaveUpdate: {
                icon: <LeaveIcon />,
                InitialMessage: "¡Vas a abandonar!",
                message: "Si lo haces, las modificaciones no quedarán actualizadas.",
                message2: '¿Realmente deseas hacerlo?',
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserActive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserInactive: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserActiveProviderAndClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de activar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
            warningUpdateUserInactiveProviderAndClient: {
                icon: <LeaveIcon />,
                InitialMessage: "¿Estas seguro de inactivar usuario?",
                buttonMessage: 'Si',
                buttonMessage2: 'No',
            },
        }
        return data[type]
    }


    const modalState = useSelector((state) => state.modalReducer.warningModalUserAndEmployes)
    const activeForm = useSelector((state) => state.userReducer.activeForm)
    const userSerialID = sessionStorage.getItem("userSerialId")
    const idUser = sessionStorage.getItem("idUser")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const ruta = location.pathname
    const { count, pag, searchBar, sort } = useSelector((state) => state.filterReducer)
    const filterDetailReducer = useSelector((state) => state.filterDetailReducer)
    const companySerialID = useSelector((state) => state.clientReducer.detailClient.companySerialID)
    const orderTranslator = (order) => {
        const options = {
          'masReciente': {"_id": -1},
          'masAntiguo': {"_id": 1},
          'A-Z': {"name": 1},
          'Z-A': {"name": -1}
        }
        return options[order]
      }
      
      const body= {
        count: count,
        pag: pag,
        sort: orderTranslator(sort),
        textToSearch: searchBar[0]
      }
      const bodyDetail ={
        count: filterDetailReducer.count,
        pag: filterDetailReducer.pag,
        sort: orderTranslator(filterDetailReducer.sort),
        companySerialID: companySerialID,
        textToSearch: filterDetailReducer.searchBar[0]
      } 

    const onClose = () => {
        dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
    }

    const handleOnLoad = () => {
        if (modalState.type === 'warningUpdateUserActive') {
            dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
            dispatch(putStateActive({ "status": 'ACTIVE' }, idUser)).then(()=>{
                dispatch(getUsers(body))
            })
        } else if (modalState.type === 'warningUpdateUserInactive') {
            dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
            dispatch(putStateInactive({ "status": 'INACTIVE' }, idUser)).then(()=>{
                dispatch(getUsers(body))
            })
        }else if (modalState.type === 'warningUpdateUserActiveProviderAndClient') {
            dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
            dispatch(putStateActive({ "status": 'ACTIVE' }, userSerialID)).then(()=>{
                dispatch(getEmployees(bodyDetail))
            })
        }else if (modalState.type === 'warningUpdateUserInactiveProviderAndClient') {
            dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
            dispatch(putStateInactive({ "status": 'INACTIVE' }, userSerialID)).then(()=>{
                dispatch(getEmployees(bodyDetail))
            })
        }else {
            navigate(-1)
            dispatch(inactiveFormUserEdit())
            dispatch(inactiveFormUser())
        }
        
        if (activeForm === true){
            dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
        }
    }


    const data = modalData(modalState.type)
    return (
        <ModalElement
            open={modalState.status}
            onClose={onClose}
            className={modalState.type === 'warningUpdateUserActive' || modalState.type === 'warningUpdateUserInactive' ? 'statusUser' : 'generalClass'}
        >
            <ModalContent>
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!modalState.message && !!modalState.message.length &&
                    modalState.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!modalState.message && modalState.type !== 'warningUpdateUserActive' && modalState.type !== 'warningUpdateUserInactive' && modalState.type !== 'warningUpdateUserInactiveProviderAndClient' && modalState.type !== 'warningUpdateUserActiveProviderAndClient'
                            ? data.message
                            : modalState.message
                            }
                        {!modalState.message && modalState.type !== 'warningUpdateUserActive' && modalState.type !== 'warningUpdateUserInactive' && modalState.type !== 'warningUpdateUserInactiveProviderAndClient' && modalState.type !== 'warningUpdateUserActiveProviderAndClient'
                            ? <>
                                <br/>
                                {data.message2}
                              </>
                            : modalState.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={handleOnLoad}
                        >
                            {data.buttonMessage}
                        </Button>
                        <Button2
                            onClick={onClose}
                        >
                            {data.buttonMessage2}
                        </Button2>
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}
WarningModalUserAndEmployes.propTypes = {
    modalState: PropTypes.object,
    warningFormModal: PropTypes.func,
    activeForm: PropTypes.bool
}

export default WarningModalUserAndEmployes
