import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/sent/leftArrow.svg'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { CLEAR_DETAIL_NOTIFICATION_DATA } from '../../../actions/actionsUtilities/types'

const Divider = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 1200px)  {
    width:100%;
    flex-direction: column;
    align-items: flex-start;
  }
`
const Container = styled.div`
  width:50%;
  margin: 30px 30px 10px 30px !important;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 1200px)  {
    margin: 20px 20px 4px 30px !important;
  }
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  align-items: center;
`
const Title = styled.p`
  color: #133F4F;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  @media only screen and (max-width: 1200px)  {
    font-size: 18px;
  }
`
const DateTitle = styled.p`
  color: #000000;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  margin-right: 10px;
  padding-right: 10px;
  text-align: right;
  width:47%;

  @media only screen and (max-width: 1280px)  {
    width:50%;
    margin: 0;

  @media only screen and (max-width: 1200px)  {
    width:100%;
    text-align: left;
    margin-left: 40px;
    margin-top: 4px;
  }
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`

const Header = (props) => {

  const { detailNotification } = useSelector((state) => state.sentReducer)
  const dataUser = detailNotification.userData
  const dispatch = useDispatch()
  const goBack = async () => {
    await props.navigate(-1)
    setTimeout(() => {
      dispatch({ type: CLEAR_DETAIL_NOTIFICATION_DATA })
    }, 200)
  }

  return (
    <>
      <Divider>
        <Container>
          <Arrow>
            <LeftArrow
              onClick={goBack}
            >
              <LeftArrowIcon />
            </LeftArrow>
          </Arrow>
          <TitleContainer>
            {
              dataUser
              && <Title>
                {dataUser.firstName} {dataUser.surname} - C.C. {dataUser.to}
              </Title>
            }
          </TitleContainer>
        </Container>
        {
          detailNotification
          && <DateTitle>
            {moment(detailNotification.notificationDate).format('DD MMM YYYY, h:mm a')}
          </DateTitle>
        }
      </Divider>

    </>
  )
}

Header.propTypes = {
  navigate: PropTypes.any.isRequired,
}

export default Header
