import React from 'react'
import styled from 'styled-components'
import moment from "moment/moment";
import { useSelector} from 'react-redux';

const Container = styled.div`
  margin-bottom: 40px;
`

const ContainerDetailReport = styled.div`
  width: 90%;
  margin: auto;
  margin-top: 40px;
`
const ContainerButtons = styled.div`
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 16px;
    justify-content: flex-end;
`
const ContainerElementContent = styled.div`
  width: 100%;
  padding: 32px;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  margin-botton:10px;
  color: #3C3C3B;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
`
const Divider = styled.div`
`

const StyleDate = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
`
const ButtonReports = styled.button`
  background: none;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  width: 173px;
  height: 48px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 16px;
  @media only screen and (max-width: 1600px) {
    width: 173px;
  }
  @media only screen and (max-width: 1200px) {
    width: 135px;
  }
  @media only screen and (max-width: 1024px) {
    border-radius: 4px;
    width: 100%;
    height: 40px;
  }
  @media only screen and (max-width: 740px) {
    font-size: 14px;
  }
`

const ReportContent = (props) => {
  const formatDate = (agreementDate) => moment(agreementDate).utc().format('DD/MM/YYYY')
  const {detailCsvError, reportDetail, typeReportCurrent} = useSelector((state) => state.reportsReducer)
  const typeReportValue= {
    "WRONG_NOTIFICATION": "Notificaciones erróneas",
    "LIST_CLIENTS": "Listado de clientes",
    "LIST_PROVIDERS": "Listado de operadores",
    "LIST_AGREEMENTS": "Listado de vinculaciones",
    "STATUS_SINGLE": "Reporte de estado",
    "STATUS_SECUNDARY": "Reporte de estado",
    "LIST_MASSIVE": "Reporte de estado"
  }

  const sendType= {
    "STATUS_SINGLE": "Individual",
    "LIST_MASSIVE": "Masivo",
    "STATUS_SECUNDARY": "Campaña específica"
  }

  const validateRenderTypeReport = (reportDetail) =>{
    const typeReport= {
      "STATUS_SINGLE": 
        <Container>
            <ContainerDetailReport>
              <ContainerElementContent>
                <Divider>{"Tipo de reporte: "}<StyleDate>{typeReportValue[reportDetail.reportType]} </StyleDate></Divider>
                <Divider>{"Tipo de envío: "}<StyleDate>{sendType[reportDetail.reportType]} </StyleDate></Divider>
                <Divider>{"Fecha inicio: "}<StyleDate>{formatDate(reportDetail.initialDate)} </StyleDate></Divider>
                <Divider>{"Fecha fin: "}<StyleDate>{formatDate(reportDetail.finalDate)} </StyleDate></Divider>
                <Divider>{"Cliente: "}<StyleDate>{reportDetail.clientReport} </StyleDate></Divider>
                <Divider>{"Estado de entrega de notificación: "}<StyleDate>{reportDetail.notificationStatus.length < 7 && reportDetail.notificationStatus.length > 0 ? reportDetail.notificationStatus.map((status, index) => {return status !== null ? index===0?status:'/'+status: ''} ): 'Todos los estados dentro del rango de fecha'}</StyleDate></Divider>
                <Divider>{"Cédula destinatario: "}<StyleDate>{reportDetail.documentRecipient ? reportDetail.documentRecipient: 'Todas las cédulas dentro del rango de fecha'}</StyleDate></Divider>
                <Divider>{"Asunto de notificación: "}<StyleDate>{reportDetail.subject? reportDetail.subject:'Todos los asuntos dentro del rango de fecha'}</StyleDate></Divider>
                <Divider>{"Nombre campaña: "}<StyleDate>{reportDetail.campaignName ? reportDetail.campaignName :'No aplica'}</StyleDate></Divider>
                {reportDetail.reportStatus === 'PROCESSING' &&
                  <Divider>{"Estado: "}<StyleDate>En proceso... </StyleDate></Divider>  
                } 
              </ContainerElementContent>
            </ContainerDetailReport>
            {reportDetail.reportStatus !== 'PROCESSING' &&
              <ContainerButtons>
                <a href={reportDetail.base64} download={reportDetail.fileName }>
                  <ButtonReports>Descargar CSV</ButtonReports>
                </a>
              </ContainerButtons>
            }
        </Container>
      ,
      "LIST_MASSIVE":
        <Container>
            <ContainerDetailReport>
              <ContainerElementContent>
                <Divider>{"Tipo de reporte: "}<StyleDate>{typeReportValue[reportDetail.reportType]} </StyleDate></Divider>
                <Divider>{"Tipo de envío: "}<StyleDate>{sendType[reportDetail.reportType]} </StyleDate></Divider>
                <Divider>{"Fecha inicio: "}<StyleDate>{formatDate(reportDetail.initialDate)} </StyleDate></Divider>
                <Divider>{"Fecha fin: "}<StyleDate>{formatDate(reportDetail.finalDate)} </StyleDate></Divider>
                <Divider>{"Cliente: "}<StyleDate>{reportDetail.clientReport} </StyleDate></Divider>
                <Divider>{"Estado de entrega de notificación: "}<StyleDate>{'No aplica'}</StyleDate></Divider>
                <Divider>{"Cédula destinatario: "}<StyleDate>{'No aplica'}</StyleDate></Divider>
                <Divider>{"Asunto de notificación: "}<StyleDate>{'No aplica'}</StyleDate></Divider>
                <Divider>{"Nombre campaña: "}<StyleDate>{'Todas las campañas dentro del rango de fecha'}</StyleDate></Divider>
                {reportDetail.reportStatus === 'PROCESSING' &&
                  <Divider>{"Estado: "}<StyleDate>En proceso... </StyleDate></Divider>  
                } 
              </ContainerElementContent>
            </ContainerDetailReport>
            {reportDetail.reportStatus !== 'PROCESSING' &&
              <ContainerButtons>
                <a href={reportDetail.base64} download={reportDetail.fileName }>
                  <ButtonReports>Descargar CSV</ButtonReports>
                </a>
              </ContainerButtons>
            }
        </Container>
      ,
      "STATUS_SECUNDARY":
        <Container>
            <ContainerDetailReport>
              <ContainerElementContent>
                <Divider>{"Tipo de reporte: "}<StyleDate>{typeReportValue[reportDetail.reportType]} </StyleDate></Divider>
                <Divider>{"Tipo de envío: "}<StyleDate>{sendType[reportDetail.reportType]} </StyleDate></Divider>
                <Divider>{"Fecha inicio: "}<StyleDate>{formatDate(reportDetail.initialDate)} </StyleDate></Divider>
                <Divider>{"Fecha fin: "}<StyleDate>{formatDate(reportDetail.finalDate)} </StyleDate></Divider>
                <Divider>{"Cliente: "}<StyleDate>{reportDetail.clientReport} </StyleDate></Divider>
                <Divider>{"Estado de entrega de notificación: "}<StyleDate>{reportDetail.notificationStatus.length < 7 && reportDetail.notificationStatus.length > 0 ? reportDetail.notificationStatus.map((status, index) => {return status !== null ? index===0?status:'/'+status: ''} ): 'Todos los estados dentro del rango de fecha'}</StyleDate></Divider>
                <Divider>{"Cédula destinatario: "}<StyleDate>{'Todas las cédulas dentro del rango de fecha'}</StyleDate></Divider>
                <Divider>{"Asunto de notificación: "}<StyleDate>{'No aplica'}</StyleDate></Divider>
                <Divider>{"Nombre campaña: "}<StyleDate>{reportDetail.campaignName}</StyleDate></Divider>
                {reportDetail.reportStatus === 'PROCESSING' &&
                  <Divider>{"Estado: "}<StyleDate>En proceso... </StyleDate></Divider>  
                } 
              </ContainerElementContent>
            </ContainerDetailReport>
            {reportDetail.reportStatus !== 'PROCESSING' &&
              <ContainerButtons>
                <a href={reportDetail.base64} download={reportDetail.fileName }>
                  <ButtonReports>Descargar CSV</ButtonReports>
                </a>
              </ContainerButtons>
            }
        </Container>
    }
    return (typeReport[reportDetail.reportType])
  }
  
  return (
    <>
    {typeReportValue[typeReportCurrent] === "Notificaciones erróneas"?
      <Container>
          <ContainerDetailReport>
            <ContainerElementContent>
              <Divider>{"Tipo de reporte: "}<StyleDate>{typeReportValue[reportDetail.reportType]} </StyleDate></Divider>
              <Divider>{"Fecha inicio: "}<StyleDate>{formatDate(reportDetail.initialDate)} </StyleDate></Divider>
              <Divider>{"Fecha fin: "}<StyleDate>{formatDate(reportDetail.finalDate)} </StyleDate></Divider>
              <Divider>{"Cédula destinatario: "}<StyleDate>{reportDetail.documentRecipient? reportDetail.documentRecipient: 'No aplica'}</StyleDate></Divider>
              <Divider>{"Asunto de notificación: "}<StyleDate>{reportDetail.subject? reportDetail.subject:'Todos los asuntos dentro del rango de fecha'}</StyleDate></Divider>
              {reportDetail.reportStatus === 'PROCESSING' &&
                <Divider>{"Estado: "}<StyleDate>En proceso... </StyleDate></Divider>  
              } 
            </ContainerElementContent>
          </ContainerDetailReport>
          {reportDetail.reportStatus !== 'PROCESSING' &&
            <ContainerButtons>
              <a href={reportDetail.base64} download={reportDetail.fileName}>
              <ButtonReports>Descargar CSV</ButtonReports>
            </a>
            </ContainerButtons>
          }
      </Container>
      :
      validateRenderTypeReport(reportDetail)
    }
    </>
  )
}

export default ReportContent