import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import userReducer from './userReducer'
import modalReducer from './modalReducer'
import menuReducer from './menuReducer'
import sentReducer from './sentReducer'
import writerReducer from './writerReducer'
import filterReducer from './filterReducer'
import filterDetailReducer from './filterDetailReducer'
import starredReducer from './starredReducer'
import clientReducer from './clientReducer'
import providerReducer from './providerReducer'
import userDocumentReducer from './userDocumentReducer'
import defaultListReducer from './defaultListReducer'
import agreementReducer from './agreementReducer'
import dashboardReducer from './dashboardReducer'
import draftReducer from './draftReducer'
import reportsReducer from './reportsReducer'
import paginationReducer from './paginationReducer'
import improperClientsReducer from './improperClientsReducer' 

export default combineReducers({
  authReducer,
  errorReducer,
  userReducer,
  modalReducer,
  draftReducer,
  menuReducer,
  sentReducer,
  writerReducer,
  filterReducer,
  filterDetailReducer,
  starredReducer,
  clientReducer,
  providerReducer,
  userDocumentReducer,
  defaultListReducer,
  agreementReducer,
  dashboardReducer,
  draftReducer,
  reportsReducer, 
  improperClientsReducer,
  paginationReducer
})
