import React, {  useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import {  activePagination} from '../../../../actions/filterActions'
import { getUserDocumentsV2 } from '../../../../actions/userDocumentsActions'
import GridTableUserDocuments from './GridTableUserDocuments'
import GeneralGrid from '../../../../components/Grid/GeneralGrid'
import GridTableUserDocumentsMix from './GridTableUserDocumentsMix'


const UserDocuments = ({ setPaginationLoader}) => {

    const dispatch =  useDispatch()

    const filterReducer = useSelector(state => state.filterReducer)
    const userDocuments = useSelector(state => state.userDocumentReducer.userDocuments)
    const isSearch = useSelector(state => state.filterReducer.isSearch)
    const sort = useSelector(state => state.filterReducer.sort)

    const [state, setState] = useState({
        loader: true,
        columnDataGrid: [
            {name: "Usuarios", width: 16, arrow: false},
        ],
        columnGridSearch: [
            {name: "Resultados", width: 16, arrow: false},
        ],
        init: true,
    });


    useEffect(() => {
        makeUserDocumentsRequest(filterReducer)
    }, [])




    useEffect(() => {
        if (!state.init) {
            makeUserDocumentsRequest(filterReducer)
            setState((prevstate)=>({...prevstate, init: true, loader: true }))
        }
    }, [filterReducer.sort,filterReducer.searchBar,filterReducer.count,filterReducer.pag,filterReducer.searchAdvanced])

    const makeUserDocumentsRequest = (filterReducer) => {
        const body = {
            pag:  filterReducer.pag,
            limit: filterReducer.count,
            sort: orderTranslator(filterReducer.sort),
            textToSearch: filterReducer.searchBar[0],
            status: filterReducer.searchAdvanced.notificationState,
            typeSearch: filterReducer.searchAdvanced.typeSearch,
            initDate: !!filterReducer.searchAdvanced.date.startDate
                ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
            endDate: !!filterReducer.searchAdvanced.date.endDate
                ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
        }
        dispatch(getUserDocumentsV2(body)).then(() => {
            dispatch(activePagination(false))
            setState((prevstate)=>({...prevstate,init: false, loader: false }))
            setPaginationLoader(false)
        })
    }

    const orderTranslator = (order) => {
        const options = {
            'masReciente': { "lastModification": -1 },
            'masAntiguo': { "lastModification": 1 },
            'A-Z': { "nameElement": 1 },
            'Z-A': { "nameElement": -1 }
        }
        return options[order]
    }


    return (
        <GeneralGrid
            list={userDocuments}
            typeList="userDocuments"
            headerTable={isSearch === true ? state.columnGridSearch : state.columnDataGrid}
            rowComponent={filterReducer.viewTableMix === true ? GridTableUserDocumentsMix : GridTableUserDocuments}
            loader={state.loader}
            isOrder={sort}/>
    )
}

export default (UserDocuments)