import moment from 'moment/moment'
import React, { useState } from 'react'
import { Label } from 'semantic-ui-react'
import styled from 'styled-components'
import './styles.css'
import { ReactComponent as Vector } from '../../assets/icons/Vector.svg'
import { ReactComponent as ShowMore } from '../../assets/icons/showMore.svg'
import { ReactComponent as ShowLess } from '../../assets/icons/showLess.svg'

const CustomLabel = styled(Label)`
background-color: #FFFFFF !important;
width: 95%;
height: 100%;
font-family: 'Roboto';
font-style: normal;
font-weight: 700 !important;
font-size: 16px !important;
padding : 0px !important;
`
const CustomLabelInfo = styled(Label)`
background-color: #FFFFFF !important;
width: 95%;
height: 100%;
font-family: 'Roboto';
font-style: normal;
font-weight: 400 !important;
font-size: 16px !important;
padding : 0px 5% !important;
white-space: nowrap !important;
text-overflow: ellipsis !important;
overflow: hidden !important;
line-height: 19px !important;
@media only screen and (max-width: 400px) {
    font-size: 14px !important;
}
`
const CustomLabelTitle = styled(Label)`
background-color: #FFFFFF !important;
width: 95%;
height: 100%;
font-family: 'Roboto';
font-style: normal;
font-weight: 600 !important;
font-size: 14px !important;
padding : 0px 5% !important;
`
const CustomLabelServiceInfo = styled(Label)`
background-color: #FFFFFF !important;
width: 95%;
height: 100%;
font-family: 'Roboto';
font-style: normal;
font-weight: 400 !important;
font-size: 14px !important;
padding : 0px 1% !important;
line-height: 19px !important;
`
const Button = styled.button`
width: 100px;
height: 28px;
background-color #FFFFFF;
border-width: 0px;
border-radius: 0px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-decoration-line: underline;
color: #22C1D0;
vertical-align: middle;
cursor: pointer;
`
const DividerIcon = styled.div`
  padding : 0px 5% !important;
    display: flex
}
`
const Icon = styled.label`
  margin: 0px 5px 0 0px;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
`

export const DashboardAgreementLabel = ({companyDefinition, agreementCode, agreementStatus, expirationDate, notificationType, operatorName, aproveedNotifications, startDate }) => {

    const [showMore, setShowMore] = useState(false);
    const { sendNotification, notificationSignature, documentSignature, notificationReading, pushNotification } = notificationType;
    const expDate = moment(expirationDate).format('DD/MM/YYYY');
    const strDate = moment(startDate).format('DD/MM/YYYY');

    const handleOnClick = (evt) => {
        evt.preventDefault();
        showMore ? setShowMore(false) : setShowMore(true)
    }
    const states = {
        ACTIVE: "En curso",
        PENDING: "Aprobado",
        INACTIVE: "Anulado",
        EXPIRED: "Vencido"
    }
    return (
        <div className={`${agreementStatus === 'ACTIVE' ? 'labelDashboardActive' : 'labelDashboard'}`}>

            <CustomLabel style={{ color: '#133F4F', textAlign: 'center', margin: '4% 0%' }} >{agreementCode}</CustomLabel>
            <CustomLabelInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>Operado por: {operatorName}</CustomLabelInfo>
            <CustomLabelInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>No. noticaciones: {companyDefinition === 'PUBLIC' ?
             'Ilimitadas': aproveedNotifications}</CustomLabelInfo>
             <CustomLabelInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>Fecha inicio: {strDate}</CustomLabelInfo>
            <CustomLabelInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>Fecha Vencimiento: {expDate}</CustomLabelInfo>
            <CustomLabelInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>Estado: {states[agreementStatus]}</CustomLabelInfo>
            {showMore ? <div style={{ paddingTop: '3px' }}>
                <CustomLabelTitle style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>Servicios de notificación</CustomLabelTitle>
                <DividerIcon style={{ display: `${sendNotification ? 'content' : 'none'}` }}>
                    <Icon> <Vector /> </Icon>
                    <CustomLabelServiceInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>
                        Solo envío
                    </CustomLabelServiceInfo >
                </DividerIcon>
                <DividerIcon style={{ display: `${pushNotification ? 'content' : 'none'}` }}>
                    <Icon> <Vector /> </Icon>
                    <CustomLabelServiceInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>
                        Notificaciones Push
                    </CustomLabelServiceInfo >
                </DividerIcon>
                <DividerIcon style={{ display: `${documentSignature ? 'content' : 'none'}` }}>
                    <Icon> <Vector /> </Icon>
                    <CustomLabelServiceInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>
                        Envío con firma en documentos
                    </CustomLabelServiceInfo >
                </DividerIcon>
                <DividerIcon style={{ display: `${notificationSignature ? 'content' : 'none'}` }}>
                    <Icon> <Vector /> </Icon>
                    <CustomLabelServiceInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>
                        Firma en notificación
                    </CustomLabelServiceInfo >
                </DividerIcon>
                <DividerIcon style={{ display: `${notificationReading ? 'content' : 'none'}` }}>
                    <Icon> <Vector /> </Icon>
                    <CustomLabelServiceInfo style={{ color: `${agreementStatus === 'ACTIVE' ? '#000000' : '#7C7C74'}` }}>
                        Confirmación de lectura
                    </CustomLabelServiceInfo >
                </DividerIcon>
            </div> : null}
            <div style={{ textAlign: 'right', padding: '1% 0 2% 0' }}>
                <Button onClick={handleOnClick}>{showMore ? 'Ver menos' : 'Ver más'} {showMore ? <ShowLess style={{ verticalAlign: '3px' }} /> : <ShowMore style={{ verticalAlign: '3px' }} />} </Button>
            </div>
        </div>
    )
}
