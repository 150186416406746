import React from 'react'
import styled from 'styled-components'
import {connect, useDispatch, useSelector} from 'react-redux'
import MenuIcon from './MenuIcon'
import {changeMessageModal, openwriteNotificationModal} from '../../../actions/modalActions'

const CustomButton = styled.div`
  height: 80px;
  background: #22C1D0 0 0 no-repeat padding-box;
  opacity: 1;
  cursor: pointer;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1330px) and (min-width:769px) {
    height: 72px;
    flex-direction: column;
    align-items: center;
  }
`

const ButtonIcon = styled.div`
  float: left;
  display: flex;
  @media only screen and (max-width: 1280px) and (min-width:769px) {
    width: 100%;
    display: block;
  })
`
const ButtonText = styled.div`
  color: ${props => props.color ? props.color : 'white'};
  float: left;
  @media only screen and (max-width: 1330px) and (min-width:769px) {
    width: 100%;
    padding:0 !important;
    font-size: 16px;
    line-height: 16px;
  })
`

const WriteButton = (props) => {

  const dispatch = useDispatch();
  const {haveAgreements}  = useSelector(state => state.writerReducer.hiredData)
   const handleOpenWrite = ()=>{
    if (haveAgreements === false ) {
      dispatch(changeMessageModal({ type: 'noAgreementsActive' }))
    }else{
      props.openwriteNotificationModal()
    }
   }

  return (
    <CustomButton onClick={() => handleOpenWrite ()}
      style={props.value.visible === true ?{display:'flex'}: {display:'none'}}>
      <ButtonIcon>
        <MenuIcon name={props.value.name} color='white'/>
      </ButtonIcon>
      <ButtonText>
        {props.value.title}
      </ButtonText>
    </CustomButton>

  )
}

const mapDispatchToProps = {
  openwriteNotificationModal
}

export default connect(null, mapDispatchToProps)(WriteButton)
