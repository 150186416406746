import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import RowTableSuperAdminImproperClients from "../RowTableSuperAdminImproperClients";
import _ from "lodash";
import PropTypes from "prop-types";
import GeneralTable from "../../../../components/Table/GeneralTable";
import {
  actionClearFilter,
  actionInitialPagination,
  actionFilterImproperClients,
  activePagination,
} from "../../../../actions/filterActions";
import ModalFilterReports from "../../../../components/Search/SearchReports/ModalFilterReports";
import { getImproperClientsList } from '../../../../actions/improperClientsActions'

const AllSuperAdminImproperClients = ({setPaginationLoader}) => {

  const [state, setState] = useState({
        loader: true,
        columnData: [
          { name: "Nombre", width: 2, arrow: false },
          { name: "Documento", width: 2, arrow: false },
          { name: "Teléfono", width: 2, arrow: false },
          { name: "Email", width: 3, arrow: false },
          { name: "Representante legal", width: 3, arrow: false },
          { name: "Revisión de auditoría", width: 2, arrow: false },
          { name: "Reportes erróneos", width: 3, arrow: false },
        ],
        init: true,
      })

  const filterReducer = useSelector(state => state.filterReducer);
  const reportsListArray = useSelector(state => state.reportsReducer.reportsList);
  const elementsCount = useSelector(state => state.reportsReducer.elementsCount);
  const improperClientsArray = useSelector(state => state.improperClientsReducer.improperClients);

  const dispatch =  useDispatch()

  useEffect(() => {
    makeImproperClientsRequest(filterReducer)
  }, [])



  useEffect(() => {
    if (!state.init) {
      makeImproperClientsRequest(filterReducer);
      setState((prevState)=> ({...prevState,init: true, loader: true }))
    }
  }, [filterReducer])
  

  const makeImproperClientsRequest = (filterReducer) => {    
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      sort: orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      filterPorcentages:filterReducer.improperClientsFilter.filterOptions
    };
    
    dispatch(getImproperClientsList(body)).then(() =>{
      dispatch(activePagination(false))
      setState((prevState)=>({...prevState, init: false, loader: false }))
      setPaginationLoader(false)
    })
  };
  const orderTranslator = (order) => {

    const options = {
      "masReciente": { _id: -1 },
      "masAntiguo": { _id: 1 },
      "A-Z": { name: 1 },
      "Z-A": { name: -1 },
    };
    return options[order];
  };
  

  return (
    <>
        <GeneralTable
          list={improperClientsArray}
          typeList={filterReducer.isSearch ? "default" : "default"}
          listHeight = {'improperClients'}
          headerTable={state.columnData}
          rowComponent={RowTableSuperAdminImproperClients}
          loader={state.loader}
        />

        <ModalFilterReports />
      </>
  )
}

export default (AllSuperAdminImproperClients);



