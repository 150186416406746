import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, Form } from 'semantic-ui-react'
import {Cell, CellDropDown, CellDropDownResponsive} from '../../../../components/Table/CellComponent'
import InputFormLabelsName from "../../../../components/InputDropdown/InputFormLabelsName";
import { setFieldParametrization } from '../../../../actions/writerActions'
import { RESET_FIELD_PARAMETRIZATION } from '../../../../actions/actionsUtilities/types';

const Row = styled(Table.Row)`
  cursor: pointer !important;
  border: 0 !important;
  border-radius: 4px;

  @media only screen and (max-width: 768px){
    box-sizing: content-box;
    display: flex !important;
    flex-direction: column;
  }

  &:hover{
    box-shadow: none;
  }

  &:nth-child(even) {
    background: #F7F7F7;
  }
`
const CellData = styled(Cell)`
  border: 0 !important;
`
const CustomForm = styled(Form)`
  width: 314px;
  display: flex !important;
  flex-direction: column !important;
  margin-top: 8px !important;
  margin-right: 0.2rem !important;
`
const ContainerLabel = styled.div`
  display: block;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const RowResponsiveContainer= styled.div`
  &:nth-child(even) {
    background:#F7F7F7;
  }
  padding: 16px 0px;
`

const ResponsiveColumnContainer= styled.div`
  width: 100%;
  padding: 0px 8px;
  margin-bottom: 16px;
`
const ResponsiveColumnContainerAttribute= styled.div`
  width: 100%;
  padding: 0px 8px;
`

const TextColumnName= styled.label`
font-family: Roboto;
font-size: 16px;
font-weight: 700;
line-height: 21.6px;
letter-spacing: 0px;
text-align: center;
color: #133F4F;
display: flex;
margin-bottom: 4px;
`

const TextColumnValue= styled.label`
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 21.6px;
letter-spacing: 0px;
text-align: left;
color: #3C3C3B;
display: flex;
`

const  RowMassiveLoad = (props) => {

  const dispatch = useDispatch()
  const {listAttributeMassive, fieldParametrizationData} = useSelector((state) => state.writerReducer)
  const [columnList, setColumnList] = useState([])
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if(listAttributeMassive !== undefined){
      var listTypeAttributes =[]
      if(!props.data.attribute.isSpecificFile){
      listTypeAttributes = listAttributeMassive.filter(item => item.valueTag === 'TAG' || item.valueTag === 'DONT_MIND').map( item => { 
        return { text: item.nameTag , value : item.valueTag }; 
      });
      }else{
      listTypeAttributes = listAttributeMassive.filter(item=> item.valueTag === 'READ_FILE' || item.valueTag === 'SIGNED_FILE' || item.valueTag === 'DONT_MIND').map( item => { 
          return { text: item.nameTag , value : item.valueTag }; 
        });

      }
      listTypeAttributes.unshift({ key: '', text: '', value: '' })
      setColumnList(listTypeAttributes)
    }
  }, [listAttributeMassive, setColumnList])

  const changeLabelsValue = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }

  const [labelvalue, setValue] = useState(changeLabelsValue(props.data.attribute.name))
  const [valueAttribute, setValueAttribute] = useState('')

  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  const onChangeLabel = (e, errorValidator) => {
      const valueComplete=  changeLabelsValue(e.target.value)
      
      if(valueComplete[0] !== " " || !valueComplete.length){
        setValue(valueComplete)
        dispatch(setFieldParametrization('tagName',valueComplete, props.data.index))
      }
  }

  const onChangeAttribute= (event, { value }) => {
    const name = event.currentTarget.parentNode.parentNode.id
    if(value === 'TAG'){
      dispatch(setFieldParametrization('columnList',{name: props.data.attribute.name, type: value, tagName:labelvalue}, props.data.index))
    }else{
      dispatch(setFieldParametrization('columnList',{name: props.data.attribute.name, type: value}, props.data.index))
    }
      setValueAttribute(value)
  }

  const splitText = (text) =>{
    if(width > 1800){
      return text.length > 50 ? text.slice(0,49)+'(...)':text
    }else if(width <= 1800 && width >1280){
      return text.length > 40 ? text.slice(0,39)+'(...)':text
    }else if(width <= 1280 && width >1024){
      return text.length > 35 ? text.slice(0,34)+'(...)':text
    }else if(width <= 1024 && width >768){
      return text.length > 30 ? text.slice(0,29)+'(...)':text
    }else if(width <= 768 ){
      return text.length > 20 ? text.slice(0,19)+'(...)':text
    }
  }

  useEffect(() => {
    if(props.data.attribute.name === 'CÉDULA'){
      dispatch({type: RESET_FIELD_PARAMETRIZATION})
      dispatch(setFieldParametrization('columnList',{name: 'CÉDULA', type: 'TAG', tagName:'Cédula'}, props.data.index))
    }else if (props.data.attribute.name === 'CEDULA'){
      dispatch({type: RESET_FIELD_PARAMETRIZATION})
      dispatch(setFieldParametrization('columnList',{name: 'CEDULA', type: 'TAG', tagName:'Cedula'}, props.data.index))
    }
}, [dispatch])


  return (
    <>
    {width > 768 ?
      <Row>
        <CellData width={3} columnName={props.headerTable[0].name} text={splitText( props.data.attribute.name === "CÉDULA" ? "Cédula" : props.data.attribute.name ) }></CellData>
        <CellData width={3} columnName={props.headerTable[1].name} text={splitText(props.data.attribute.example)}></CellData>
        <CellDropDown
          className={'attributeList'}
          width={3} 
          columnName={props.headerTable[2].name}
          name={props.data.attribute.name}
          attribute={props.data.attribute.name === 'CÉDULA' || props.data.attribute.name === 'CEDULA' ? 'TAG': valueAttribute}
          id={props.data.attribute.name} 
          index={props.data.index}
          attributesTypes={columnList}
          onChangeAttribute= {onChangeAttribute}
          isDisabled= {props.data.attribute.name === 'CÉDULA' || props.data.attribute.name === 'CEDULA' ? true : false}
          validator={props.validator}
          validateOptions={['required']}
          error={props.errors}
          inputLabel={props.data.attribute.name === 'CÉDULA' || props.data.attribute.name === 'CEDULA'
          ?
            <ContainerLabel>
              <InputFormLabelsName
                className={'labelName'}
                id={'LabelName'+props.data.index}
                placeholder={'Etiqueta ('+props.data.attribute.name+')'}
                onChange={onChangeLabel}
                type={'text'}
                value={props.data.attribute.name === 'CEDULA'?'Cedula':'Cédula'}
                validator={props.validator}
                validateOptions={['required', 'nameLabels', { min: 3 }, { max: 20 }]}
                error={props.errors}
                disabled={true}
                onClick= {()=> props.validator.current.showMessageFor('Etiqueta ('+props.data.attribute.name+')')}
              />
            </ContainerLabel>
          : valueAttribute === 'TAG' 
          ? 
          <ContainerLabel>
            <InputFormLabelsName
              className={'labelName'}
              id={'LabelName'+props.data.index}
              placeholder={'Etiqueta ('+props.data.attribute.name+')'}
              onChange={onChangeLabel}
              type={'text'}
              value={labelvalue}
              validator={props.validator}
              validateOptions={['required', 'nameLabels', { min: 3 }, { max: 20 }]}
              error={props.errors}
              disabled={props.data.isDisabled}
            />
          </ContainerLabel>
        
        :''}
          />
      </Row>
    :
    <RowResponsiveContainer>
      <ResponsiveColumnContainer>
        <TextColumnName>
          {props.headerTable[0].name}
        </TextColumnName>

        <TextColumnValue>
          {props.data.attribute.name === "CÉDULA" ? "Cédula" : props.data.attribute.name}
        </TextColumnValue>
      </ResponsiveColumnContainer>
      <ResponsiveColumnContainer>
        <TextColumnName>
          {props.headerTable[1].name}
        </TextColumnName>

        <TextColumnValue>
          {props.data.attribute.example}
        </TextColumnValue>
      </ResponsiveColumnContainer>
      <ResponsiveColumnContainerAttribute>
        <TextColumnName>
          {props.headerTable[2].name}
        </TextColumnName>
        
        <CellDropDownResponsive
          className={'attributeList'}
          width={3} 
          columnName={''}
          name={props.data.attribute.name}
          attribute={props.data.attribute.name === 'CÉDULA' || props.data.attribute.name === 'CEDULA' ? 'TAG': valueAttribute}
          id={props.data.attribute.name} 
          index={props.data.index}
          attributesTypes={columnList}
          onChangeAttribute= {onChangeAttribute}
          isDisabled= {props.data.attribute.name === 'CÉDULA' || props.data.attribute.name === 'CEDULA' ? true : false}
          validator={props.validator}
          validateOptions={['required']}
          error={props.errors}
          inputLabel={props.data.attribute.name === 'CÉDULA' || props.data.attribute.name === 'CEDULA'
          ?
            <ContainerLabel>
              <InputFormLabelsName
                className={'labelName'}
                id={'LabelName'+props.data.index}
                placeholder={'Etiqueta ('+props.data.attribute.name+')'}
                onChange={onChangeLabel}
                type={'text'}
                value={props.data.attribute.name === 'CEDULA'?'Cedula':'Cédula'}
                validator={props.validator}
                validateOptions={['required', 'nameLabels', { min: 3 }, { max: 20 }]}
                error={props.errors}
                disabled={true}
                onClick= {()=> props.validator.current.showMessageFor('Etiqueta ('+props.data.attribute.name+')')}
              />
            </ContainerLabel>
          : valueAttribute === 'TAG' 
          ? 
          <ContainerLabel>
            <InputFormLabelsName
              className={'labelName'}
              id={'LabelName'+props.data.index}
              placeholder={'Etiqueta ('+props.data.attribute.name+')'}
              onChange={onChangeLabel}
              type={'text'}
              value={labelvalue}
              validator={props.validator}
              validateOptions={['required', 'nameLabels', { min: 3 }, { max: 20 }]}
              error={props.errors}
              disabled={props.data.isDisabled}
            />
          </ContainerLabel>
        
        :''}
          />
      </ResponsiveColumnContainerAttribute>
    </RowResponsiveContainer>
    }
    </>
  )
}

RowMassiveLoad.propTypes = {
  data: PropTypes.object.isRequired,
}
export default RowMassiveLoad