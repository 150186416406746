import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import { ReactComponent as Arrow } from '../../../assets/icons/sent/arrowWhite.svg'
import { ReactComponent as Download } from '../../../assets/icons/sent/downloadDocument.svg'
import { ReactComponent as PrintIcon } from '../../../assets/icons/sent/print.svg'
import { changeMessageModal, closePDFModal } from '../../../actions/modalActions'
import styled from 'styled-components'
import './attachment.css'

const ContentHeader = styled.div`
  position: absolute;
  top: -50px;
  width:100%;
  height:50px;
  z-index: 30;
`
const Divider = styled.div`
  padding:15px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  z-index: 3;
`
const Divider2 = styled.div`
  padding:15px;
  padding-left:15px;
  z-index: 3;

`
const Divider3 = styled.div`
  padding:15px;
  padding-left:15px;
  z-index: 3;

`
const TextNamePDF = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFF;
  padding-left: 8px;
  z-index:3;
`
const PopupFilterOrder = styled(Popup)`
  top: -8vh !important;
  left: 14% !important;
  background: #fff !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25) !important;
  padding: 0 !important;
  width: 170px !important;
  border: none !important;
  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background:  #fff;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  padding:8px
`

const HeaderPdf = (props) => {

  const dispatch = useDispatch()
  const { pdfModal } = useSelector(state => state.modalReducer)
  const { dataViewPdf } = useSelector(state => state.sentReducer)
  const [show, setShow] = useState(false)

  const handleClick = () => {
    dispatch(closePDFModal())
  }

  const toggle = () => {
    setShow(!show)
  }

  const validateDownload = () => {
    if (dataViewPdf.fileDecodedBase64 === undefined) {
      dispatch(closePDFModal())
      dispatch(changeMessageModal({
            type: 'errorDownloadReport'
          })
      )
    }
  }
  return (
      <ContentHeader>
        <div style={{ float: "left" }}>
          {
              (pdfModal === true && dataViewPdf.length !== 0)
              &&
              <Divider>
                <Arrow
                    onClick={() => { handleClick() }} style={{cursor:'pointer'}} />
                <TextNamePDF >{dataViewPdf.documentName}</TextNamePDF>
              </Divider>
          }
        </div>


        {(!props.errorFile && !!dataViewPdf.fileDecodedBase64) &&

            <div style={{ float: "right", display: "flex" }}>
              {
                  (pdfModal === true && dataViewPdf.length !== 0)
                  &&
                  <Divider3>
                    <a
                        href={dataViewPdf.fileDecodedBase64}
                        download={dataViewPdf.documentName}
                        onClick={validateDownload}
                    >
                      <Download />
                    </a>
                  </Divider3>
              }
              {
                  (pdfModal === true && dataViewPdf.length !== 0)
                  &&
                  <Divider2>
                    <a href={dataViewPdf.fileURL} target="_blank" rel="noopener noreferrer" style={{ color: "#FFFFFF" }}>
                      <PrintIcon />
                    </a>
                  </Divider2>
              }
            </div>
        }
      </ContentHeader>
  )
}

export default HeaderPdf