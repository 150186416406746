import React, { useEffect, useState } from 'react'
import './styles.css'
import { ReactComponent as Complete } from '../../assets/icons/sent/complete.svg'
import { ReactComponent as ErrorReport } from '../../assets/icons/sent/errorReport.svg'
import { ReactComponent as Rejected } from '../../assets/icons/sent/rejected.svg'
import { ReactComponent as Close } from '../../assets/icons/closeDark.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/sent/arrowDown.svg'
import { ReactComponent as ArrowUp } from '../../assets/icons/sent/arrowUp.svg'
import { ReactComponent as CloseWhite } from '../../assets/icons/sent/iconCloseWhite.svg'
import { useDispatch, useSelector } from 'react-redux'
import { chageStateLoadCitizensList, chageStateLoadNotifications, chageStateLoadNotificationsList, consultNotificationsProgressV2, deleteLoadNotificationItemV2 } from '../../actions/writerActions'
import { downloadReportMassive } from '../../actions/seeNotificationAction'
import { changeMessageModalTwo } from '../../actions/modalActions'
import { CHANGE_STATE_NOTIFICATIONS_CHARGING } from '../../actions/actionsUtilities/types'
import { actionSearchBar, inactiveViewMix} from '../../actions/filterActions'

const SendNotifications = (props) => {

    const [Isvisible, setIsVisible] = useState(false);
    const IsVisibleSendNotification = useSelector(state => state.writerReducer.loadNotificationsList);
    const isVisibleAll = useSelector(state => state.writerReducer.loadNotifications)
    const resultNotificationsProgress = useSelector(state => state.writerReducer.resultNotificationsProgress)
    const fileError = useSelector(state => state.sentReducer.citizenReportData)
    const dispatch = useDispatch()
    const [width, setWidth] = useState(window.innerWidth);
    const { notificationsIsCharging } = useSelector(state => state.writerReducer)
  

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(()=>{
        dispatch(consultNotificationsProgressV2({}))
    }
    ,[])
    useEffect(() => {
        const intervalId = setInterval(() => {
        if(IsVisibleSendNotification)
            dispatch(consultNotificationsProgressV2({}))

        }, 1000); 
        return () => {
            clearInterval(intervalId);
        }
    },[IsVisibleSendNotification])


    useEffect(()=>{
        for (let i = 0; i < resultNotificationsProgress.progress.length; i++) {
            const element = resultNotificationsProgress.progress[i];
            if(element.state === 'En espera' || element.state === 'En progreso'){
                dispatch({type: CHANGE_STATE_NOTIFICATIONS_CHARGING, payload: true})
                dispatch(chageStateLoadNotifications(true))
                break
            }else{
                dispatch({type: CHANGE_STATE_NOTIFICATIONS_CHARGING, payload: false})
            }
        }
    },[resultNotificationsProgress, dispatch])

    useEffect(()=>{
        if(resultNotificationsProgress.progress.length === 0){
            dispatch(chageStateLoadNotifications(false))
        }else {
            dispatch(chageStateLoadNotifications(true))
        }
    },[resultNotificationsProgress, dispatch])


    const downloadFileErrors = async (fileSerialID, isReady) =>{
        if(isReady){
            const url = await dispatch(downloadReportMassive({fileSerialID:fileSerialID}))
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "errorBouncedNotifications.csv");
            document.body.appendChild(link);
            link.click();
        }
    }
    const cancelSend =(item) =>{
            dispatch(changeMessageModalTwo({
                type: 'cancelMassiveSend',
                message: ['¡Vas a cancelar este envío!',item.state === 'En progreso'?'Ten presente que las notificaciones ya enviadas las podrás ver en el listado de "Enviados"': '', '¿Realmente deseas hacerlo?'],
                fileSerialID:item.fileSerialID
            }))
    }
    const deleteItem = (item, key) => {
        if(item.state === 'Completado'){
            dispatch(deleteLoadNotificationItemV2({fileSerialID:item.fileSerialID,  newVisibility: false}))
        }else {
            dispatch(changeMessageModalTwo({
                type: 'deleteNotificationItem',
                message: ['¡Vas a eliminar este registro de envío!','Recuerda que podrás descargar el archivo con reportes erróneos ingresando al detalle del envío desde la lista en la sección "Enviados".'],
                fileSerialID: item.fileSerialID,
            }))
        }

    }

    const closeCourtain = () =>{
        if(width <= 768){
            dispatch(chageStateLoadCitizensList(!IsVisibleSendNotification)) 
        } 
        dispatch(chageStateLoadNotificationsList(!IsVisibleSendNotification))
    
    }

    const handleClose = () =>{
        const statusList = ['Completado', 'Incompleto', 'No cargado', 'Error']
        const body = {
            fileSerialIDList : resultNotificationsProgress.progress.filter((item) => statusList.includes(item.state)).map(item=>item.fileSerialID),
            newVisibility: false
        }
        if(body.fileSerialIDList.length > 0){
            dispatch(deleteLoadNotificationItemV2(body))
        }
        dispatch(chageStateLoadNotifications(false))

    }
    return (
        <>
            <div className={
                (!!isVisibleAll) ?
                    (!IsVisibleSendNotification) ? "parentSendNotifications initial" : "parentSendNotifications"
                    :
                    "close"
            }>
                <header className="headerSendNotifications">
                    {notificationsIsCharging &&
                    <span className='centerTag iconHeader'>
                        <div className='loading-container'>
                            <div class="lds-spinner-reportLoading-header" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>
                    </span>
                     }
                    <span className={ !notificationsIsCharging? 'centerTag headerTextFinalized': 'centerTag headerText'} >{!notificationsIsCharging? 'Notificaciones enviadas':'Enviando notificaciones'}</span>
                    <span className='centerTag iconHeader' onClick={() => closeCourtain()}>
                        {(!IsVisibleSendNotification) ? <ArrowUp style={{ margin: '-8px 0px' }} /> : <ArrowDown style={{ margin: '-8px 0px' }} />}
                    </span>
                    {!notificationsIsCharging &&
                        <span className='centerTag iconHeaderClose'>
                            <CloseWhite onClick={() => handleClose()} style={{ margin: '-8px 0px' }} />
                        </span>
                    }
                </header>
                <div className="contentSendNotifications">
                    {!notificationsIsCharging &&
                        <div className='contentRedirectNotifications'>
                            <span className='textRedirect' onClick={()=>{
                                props.history.push('/sent')
                                dispatch(actionSearchBar ('', 'initialState'))
                                dispatch(inactiveViewMix())
                                }}>Ir a notificaciones enviadas</span>
                        </div>
                    }
                    {resultNotificationsProgress.progress.map((item, key) => {

                        return (
                            <> 
                            {item.state === 'Completado' &&
                                <div className='sendNotificationsData' key={key}>
                                    <div className='textSendNotification'>
                                        <p>{item.campaignName}</p>
                                        <p className='loadSendNotification'>{item.totalNotificationsCreated} de {item.totalNotificationsToCreate}</p>
                                    </div>
                                    <div className='stateNotification'>
                                        <Complete style={{ width: '20px', height: '20px', margin: '-4px 0px' }} />
                                        <span className='closeIconNotification' id={key} onClick={()=>deleteItem(item, key)}><Close /></span>
                                    </div>
                                </div>
                            }
                            {item.state === 'Incompleto' &&
                                <div className='sendNotificationsData' key={key}>
                                    <div className='textSendNotification'>
                                        <p>{item.campaignName}</p>
                                        <p className='loadSendNotification'>{item.totalNotificationsCreated} de {item.totalNotificationsToCreate}</p>
                                        <a className={item.isReadyUrlBouncedNotifications?'downloadAttachment':'fileCharging'} onClick={()=>downloadFileErrors(item.fileSerialID,item.isReadyUrlBouncedNotifications)}>{item.isReadyUrlBouncedNotifications?"Descargar archivos con reportes erróneos":"Preparando archivo"}{!item.isReadyUrlBouncedNotifications && <span id="dots"></span>}</a>
                                    </div>
                                    <div className='stateNotification'>
                                        <ErrorReport style={{ width: '20px', height: '20px'}} />
                                        <span className='closeIconNotification' id={key} onClick={()=>deleteItem(item, key)}><Close /></span>
                                    </div>
                                    
                                </div>
                            }
                            {(item.state === 'No cargado' || item.state === 'Error') &&
                                <div className='sendNotificationsData' key={key}>
                                    <div className='textSendNotification'>
                                        <p>{item.campaignName}</p>
                                        <p className='loadSendNotification'>{item.totalNotificationsCreated} de {item.totalNotificationsToCreate}</p>
                                        <a className={item.isReadyUrlBouncedNotifications?'downloadAttachment':'fileCharging'} onClick={()=>downloadFileErrors(item.fileSerialID,item.isReadyUrlBouncedNotifications)}>{item.isReadyUrlBouncedNotifications?"Descargar archivos con reportes erróneos":"Preparando archivo" }{!item.isReadyUrlBouncedNotifications && <span id="dots"></span>}</a>
                                    </div>
                                    <div className='stateNotification'>
                                        <Rejected style={{ width: '20px', height: '20px', margin: '-4px 0px' }} />
                                        <span className='closeIconNotification' id={key} onClick={()=>deleteItem(item, key)} ><Close /></span>
                                    </div>
                                </div>
                            }
                            {item.state === 'En progreso' &&
                                <div className='sendNotificationsData' 
                                onMouseEnter={() => setIsVisible(true, key)}
                                onMouseLeave={() => setIsVisible(false, key)}key={key}>
                                    <div className='textSendNotification'>
                                        <p>{item.campaignName}</p>
                                        <p className='loadSendNotification'>{item.totalNotificationsCreated} de {item.totalNotificationsToCreate}</p>
                                    </div>
                                    <div className='stateNotification'>
                                        <div className='loading-container'>
                                            <div class="lds-spinner-notificationLoading" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        </div>
                                        {Isvisible && (
                                            <span className='closeIconNotification' id={key} onClick={()=>cancelSend(item)} style={{top:'40%'}}><Close /></span>
                                        )}
                                    </div>
                                </div>
                            }
                            {item.state === 'En espera' &&
                            <div className='sendNotificationsData'
                                onMouseEnter={() => setIsVisible(true, key)}
                                onMouseLeave={() => setIsVisible(false, key)}
                                key={key}
                            >
                                <div className='textSendNotification'>
                                    <p>{item.campaignName}</p>
                                    <p className='loadSendNotification'>{item.totalNotificationsCreated} de {item.totalNotificationsToCreate}</p>
                                </div>
                                <div className='stateNotificationText'>
                                    <span className='textOnHold'>En espera...</span>
                                    {Isvisible && (
                                        <span className='closeIconNotification' id={key} onClick={()=>cancelSend(item)}><Close /></span>
                                    )}
                                </div>
                            </div>
                            }
                            </>
                        )
                    })

                    }

                </div>
            </div>
        </>
    )
}


SendNotifications.propTypes = {
}
export default SendNotifications
