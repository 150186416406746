import React, { useState } from 'react'
import { SearchBarGenericReports } from '../SearchBarGeneric'

const ContainerSearchReports = (props) => {

  const [state, setState] = useState({
      searchBarState: "",
      popUpFilterState: "",
      popUpOrderState: "",
  })

    return (  
      <SearchBarGenericReports textPlaceHolder={"Buscar"} searchBarState={state.searchBarState} popUpOrderState={state.popUpOrderState}
      popUpFilterState={state.popUpFilterState} showAdvancedSearch={props.showAdvancedSearch} buttonType= {'reports'} 
      text={'Generar reporte'} customWidthDiv2={props.customWidthDiv2} loader={props.loader}/>

    )
}

export default ContainerSearchReports
