import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'

import { ReactComponent as Starred } from '../../assets/icons/sent/starred.svg'

const CustomButton = styled(Button)`
  svg path {
    ${props => props.starred === 'false' && 'fill: #fff;'}
    ${props => props.starred === 'false' && 'stroke: #979E9F;'}
  }
  margin: 0px;
  padding: 0px 0 !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  width: 24px;
  height: 24px;

  :hover {
    svg path {
      ${props => props.starred === 'false' && 'stroke: #000000;'}
    }
  }
`

const StarredButton = ({ onClick, customStyles, active: initialActive = false, noChange }) => {
  const [active, setActive] = useState(initialActive)

  useEffect(() => {
    setActive(initialActive)
  }, [initialActive])

  const handleChange = (e) => {
    if (!noChange) {
      e.stopPropagation()
      setActive(prevActive => !prevActive)
      onClick(e)
    }
  }

  return (
    <CustomButton
      style={customStyles}
      onClick={handleChange}
      starred={active.toString()}
    >
      <Starred />
    </CustomButton>
  )
}

StarredButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  active: PropTypes.bool,
  noChange: PropTypes.bool
}

export default StarredButton





