import React, { useEffect, useState } from 'react'
import HearderMasive from './HearderMasive'
import { SentMasiveAll } from './TableMasive/SentMasiveAll'
import { downloadMassiveAttachments, downloadReportMassive, getDetailMassiveNotification } from '../../../actions/seeNotificationAction'
import { useDispatch, useSelector } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import { actionMassiveDetailFilter, activePagination } from '../../../actions/filterDetailActions'
import ContainerSearchMassive from '../../../components/Search/ContainerSearchMassive'
import { CheckBoxGeneric } from '../../../components/CheckboxForm/CheckBoxGeneric'
import styled from 'styled-components'
import { ReactComponent as Download } from '../../../assets/icons/download-attachment.svg'
import { ADD_ALL_TO_LIST_TO_DOWNLOAD, CHANGE_TOAST_ID, DELETE_ALL_FROM_LIST_TO_DOWNLOAD, RESET_FILTTER_DETAIL } from '../../../actions/actionsUtilities/types'
import { CheckBoxGenericMinusIcon } from '../../../components/CheckboxForm/CheckBoxGenericMinusIcon'
import { UseWindowDimensions } from '../../../utils/UseWindowsDimentions'
import { toast } from 'react-toastify'
import { ReactComponent as IconClose } from '../../../assets/icons/report/iconCloseDark.svg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { SearchBarDrafts } from '../../../components/Search/SearchBarGeneric'


const Divider = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  padding: 0 0 0.5rem 2.5rem;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 0.5rem 10px !important;
  }
  @media only screen and (max-width: 768px) {
    padding: 0 0 0.5rem 1rem !important;
  }
`

const ContainerWarningMessage = styled.div`
  background: #E8FDFE;
  min-width: 293px;
  width:100%;
  max-height: 40px;
  height: 40px;
  border: 1px solid #4E8D93;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  padding: 12px 24px 12px 24px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;

const ContainerText = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  gap: 3px;
`;

const ContainerTextOne = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0px;
  text-align: left;
  color: #133F4F;
`;

const IconCloseDark = styled(IconClose)`
  cursor: pointer;
}
`;

const orderTranslator = (order) => {
  const options = {
    'masReciente': { "notificationStatus.date": -1 },
    'masAntiguo': { "notificationStatus.date": 1 },
    'A-Z': { "recipient.fullName": 1 },
    'Z-A': { "recipient.fullName": -1 }
  }
  return options[order]
}

const SentMasive = (props) => {
  const dispatch = useDispatch()
  const { notificationID } = useParams()
  const [loader, setLoader] = useState(true)
  const filterReducer = useSelector((state) => state.filterDetailReducer)
  const [isVisible, setIsVisible] = useState(false)
  const [activeAll, setActiveAll] = useState(false)
  const [selectCheckBox, setSelectCheckBox] = useState(false)
  const [init, setInit] = useState(true)
  const windowDimensions = UseWindowDimensions();
  const customEqual = (oldValue, newValue) => oldValue === newValue
  var listToDownload = useSelector((state) => state.sentReducer.listToDownload, customEqual)
  const { detailMassiveNotification, attachmentsDownloadLink } = useSelector((state) => state.sentReducer)
  const { isConfirmationRead, isSignNotification, bouncedCount, notifications, isPushNotification,
    nameCampaing, dateCampaing, elementsCount, elementsCountPage, haveAttachments, statusCount, status } = detailMassiveNotification
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [showMessage, setShowMessage] = useState(false)
  const { downloadingUserDocs, toastId } = useSelector((state) => state.sentReducer)

  useEffect(() => {
    dispatch(actionMassiveDetailFilter())
    return () => {
      dispatch({type:RESET_FILTTER_DETAIL})
    }
  }, [])
  

  useEffect(() => {
    makeNotificationsRequest(filterReducer)
  }, [notificationID, dispatch])


  useEffect(() => {
    if (detailMassiveNotification.fileSerialID) {
      dispatch(downloadReportMassive({ fileSerialID: detailMassiveNotification.fileSerialID })).then(() => {
        setLoader(false)
      })
    }
    dispatch({ type: DELETE_ALL_FROM_LIST_TO_DOWNLOAD })
  }, [detailMassiveNotification, dispatch])

  useEffect(() => {
    if (!init) {
      makeNotificationsRequest(filterReducer)
      setLoader(true)
      setInit(true)
    }
  }, [filterReducer])


  useEffect(() => {
    if (showMessage) {
      if (toastId !== null) {
        toast.dismiss(toastId);
        setTimeout(() => {
          const id = toast(createToast(), {
            autoClose: 5000,
            // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
          });
          dispatch({ type: CHANGE_TOAST_ID, payload: id });

        }, 500);
      } else {
        const id = toast(createToast(), {
          autoClose: 5000,
          // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
        });

        dispatch({ type: CHANGE_TOAST_ID, payload: id });
      }

    }
  }, [showMessage]);


  useEffect(() => {
    if (listToDownload.length > 0) {
      setIsVisible(true)
      setSelectCheckBox(true)
    } else {
      setActiveAll(false)
      setSelectCheckBox(false)
      setIsVisible(false)
    }
  }, [listToDownload])

  const makeNotificationsRequest = (filterReducer) => {
    const body = {
      massiveNotificationSerialID: notificationID,
      count: 10,
      pag: filterReducer.pag,
      textToSearch: filterReducer.searchBar[0],
      status: filterReducer.searchAdvanced.notificationState === "MASSIVE" ? '' : filterReducer.searchAdvanced.notificationState,
      sort: orderTranslator(filterReducer.sort),
    }
    dispatch(getDetailMassiveNotification(body)).then(() => {
     
      setInit(false)
      dispatch(activePagination(false))
    })
  }

  const handleSelectAll = (e) => {
    e.preventDefault()
    setActiveAll(!activeAll)
    setIsVisible(!isVisible)
    setSelectCheckBox(!selectCheckBox)
    if (!activeAll) {
      dispatch({ type: ADD_ALL_TO_LIST_TO_DOWNLOAD })
    } else {
      dispatch({ type: DELETE_ALL_FROM_LIST_TO_DOWNLOAD })
    }
  }

  const handleClickDownload = async () => {
    setShowMessage(false);
    setShowMessage(true);
    setLoadingDownload(true);
    dispatch(downloadMassiveAttachments({ notificationSerialIDList: listToDownload }))
        .finally(() => {
          setLoadingDownload(false);
          setShowMessage(false);
        });
  }

  const createToast = () => (
      <ContainerWarningMessage>
        <ContainerText>
          <ContainerTextOne>Descargando archivos</ContainerTextOne>
        </ContainerText>
        <IconCloseDark onClick={closeToast} />
      </ContainerWarningMessage>
  );

  const closeToast = () => {
    setShowMessage(false);
    toast.dismiss()
  };


  return (
      <Segment style={{ margin: '0px', padding: '0px' }}>
        <Dimmer.Dimmable>
          <Dimmer active={loader} inverted page={false} >
            <Loader size='medium'>Cargando...</Loader>
          </Dimmer>
          <HearderMasive isPushNotification={isPushNotification} bouncedCount={bouncedCount} isConfirmationRead={isConfirmationRead} nameCampaing={nameCampaing}
                         isSignNotification={isSignNotification} dateCampaing={dateCampaing} statusCount={statusCount} status={status} />
          <ContainerSearchMassive showAdvancedSearch={false} filterSmall={windowDimensions.width > 768 ? false : true} />
          {haveAttachments &&
              <Divider style={{ display: 'flex', padding: '0 0 0.5rem 2.2rem', height: '30px' }}>
                {listToDownload.length === elementsCountPage ?
                    <CheckBoxGeneric onChange={handleSelectAll} checked={selectCheckBox} />
                    :
                    <CheckBoxGenericMinusIcon onChange={handleSelectAll} checked={selectCheckBox} />
                }
                {(isVisible && haveAttachments || downloadingUserDocs) &&
                    <div style={{ display: 'flex', color: '#22C1D0', opacity: loadingDownload || downloadingUserDocs ? '0.3' : '', cursor: loadingDownload || downloadingUserDocs ? 'no-drop' : 'pointer', alignItems: 'center' }}
                         onClick={!loadingDownload || downloadingUserDocs ? handleClickDownload : undefined}>
                      {loadingDownload || downloadingUserDocs
                          ? <i class={"loading spinner icon"} style={{ margin: '2px 6px 0px 28.6px' }}></i>
                          : <Download style={{ margin: '2px 6px 0px 28.6px' }} />
                      }
                      <div style={{ cursor: loadingDownload || downloadingUserDocs ? 'no-drop' : 'pointer', marginLeft: loadingDownload || downloadingUserDocs ? '4px' : '' }} >{loadingDownload || downloadingUserDocs ? 'Descargando adjuntos' : 'Descargar adjuntos'}</div>
                    </div>}
              </Divider>
          }
          <SentMasiveAll notifications={notifications} loader={false} activeAll={activeAll} />
        </Dimmer.Dimmable>
      </Segment>
  )
}

export default SentMasive