import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import moment from "moment/moment";
import { useDispatch } from 'react-redux'
import { getAgreementEdit } from '../../../actions/agreementActions'
import { Cell, CellEdit} from '../../../components/Table/CellComponent';
import { useNavigate } from 'react-router-dom-v5-compat';

const Row = styled(Table.Row)`
cursor: pointer !important;
height: 50px !important;
padding-right: 2px;
@media only screenand (max-width: 1500px) {
    padding-right: 0px;
}
@media only screen and (max-width: 768px) {
  height: auto !important;
  display: flex !important;
  flex-direction: column;
  border-bottom: 1px solid #979E9F !important;
  padding: 0.5em 0em!important;
  box-sizing: content-box;
}
  ${props => props.rowcolor && css`
    color: ${props.rowcolor}
  `}
`

const RowTableAgreementsSuperAdmin = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const colorRow = (agreementStatus) => {
    if (agreementStatus === 'EXPIRED') {
      return `rgba(60, 60, 59, 0.3)`
    }
    return `#3C3C3B`
  }

  const redirectToAgreementDetail = () => {
    sessionStorage.setItem("idAgreement", props.data.id)
    navigate('/agreementsDetail/' + props.data.id)
  }

  const [isVisible, setIsVisible] = useState(false);
  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')
  const handleClick = (e) => {
    e.stopPropagation()
    sessionStorage.setItem("idAgreement", props.data.id)
    dispatch(getAgreementEdit(props.data.id))
    if (props.data.statusName === 'Aprobado') {
      navigate('/editAgreements')
    }
  }
  
  return (
    <Row rowcolor={colorRow(props.data.status)} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}
      onClick={redirectToAgreementDetail}>
      <Cell width={2} columnName={props.headerTable[0].name} text={props.data.agreementCode}></Cell>
      <Cell width={2} columnName={props.headerTable[1].name} text={props.data.nameEntity}></Cell>
      <Cell width={3} columnName={props.headerTable[3].name} text={props.data.nameProvider}></Cell>
      <Cell width={2} columnName={props.headerTable[2].name} text={formatDate(props.data.subscriptionDate)}></Cell>
      <Cell width={2} columnName={props.headerTable[4].name} text={formatDate(props.data.startDate)}></Cell>
      <Cell width={2} columnName={props.headerTable[5].name} text={formatDate(props.data.endDate)}></Cell>
      <CellEdit isVisible={props.data.statusName === 'En curso' || props.data.statusName ===  'Vencido' || props.data.statusName ===  'Anulado' ? false : isVisible} inProgress={props.data.statusName === 'En curso' ? true: false}
      columnName={props.headerTable[6].name} text={props.data.statusName} clickEdit= {handleClick}></CellEdit>
    </Row>
  )
}

RowTableAgreementsSuperAdmin.propTypes = {
  data: PropTypes.object.isRequired
};

export default RowTableAgreementsSuperAdmin