import React, { useEffect} from 'react'
import styled, { css } from 'styled-components'
import { Tab, Menu } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import MenuItemTab from '../../../components/Tab/MenuImageTab'
import AllLogs from './Logs/AllLogs'
import { setOriginalNumberOfEmployeees } from '../../../actions/clientActions'
import { actionSearchBar } from '../../../actions/filterDetailActions'
import { CollapsableDetailAgreement } from './CollapsableDetailAgreement'

const CustomTab = styled(Tab)`
  .active.item {
    color: #22C1D0 !important;
    border-color: #22C1D0 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-bottom-width: 4px !important;
    margin: 0 0 -4px !important;
  }
  .active.item div svg path {
    fill: #22C1D0 !important;
  }
  .ui.menu {
    display:grid;
    grid-row-gap: 2px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 25px 20px 0 20px !important;
    border-bottom: 2px solid #E4E4E4 !important;
  }
  .ui.secondary.pointing.menu .item {
    padding: 0;
    height: 50px !important;
    font-family: 'Roboto';
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #133F4F;
  }

  @media only screen and (max-width: 1024px) {
    .ui.secondary.pointing.menu .item {
      font-size: 14px !important;
    }
  }

`

const CustomMenuItem = styled(Menu.Item)`
  display: flex !important;
  flex: 1 !important;
  justify-content: center;

  ${({ additionalStyles }) => additionalStyles && css`
    /* Aquí puedes agregar los estilos adicionales según el parámetro additionalStyles */
    ${additionalStyles}
  `}
`;

const Content = styled(Tab.Pane)`
  border: none !important;
  padding: 0 !important;
`

const TabDetailAgreements = (props) => {
    const { tabFilter } = useSelector(state => state.filterReducer)

    const { employeesCount,originalEmployeesCount } = useSelector((state) => state.clientReducer);
    const dispatch = useDispatch();


    useEffect(() => {
        if (employeesCount > originalEmployeesCount ) {
            dispatch(setOriginalNumberOfEmployeees(employeesCount))
        }
    }, [employeesCount]);


    useEffect(() => {
        return () => {
            dispatch(setOriginalNumberOfEmployeees(-1));
            dispatch(actionSearchBar('', 'initialState'));
        };
    }, []);



    const panes =  props.isResponsive === false ?
        [
            {
                menuItem: (
                    <CustomMenuItem key='Logs'>
                        <MenuItemTab icon='default' title='Seguimiento' />
                    </CustomMenuItem>
                ),
                render: () => (<Content >
                    {
                        <AllLogs
                            tabFilter='Logs'
                            filterPosition={props.filterPosition}
                            generalLoader={props.generalLoader}

                        />
                    }
                </Content>)
            }
        ]
        :
        //Responsive Panes
        [
            {
                menuItem: (
                    <CustomMenuItem key='logs'>
                        <MenuItemTab icon='default' title='Seguimiento' />
                    </CustomMenuItem>
                ),
                render: () => (<Content>
                    <AllLogs
                        tabFilter='logs'
                        filterPosition={props.filterPosition}
                        generalLoader={props.generalLoader}
                    />
                </Content>)
            }



        ]

    return (
        <MainSegment>
            {props.isResponsive && <CollapsableDetailAgreement/>}
            <CustomTab
                menu={{ secondary: true, pointing: true }}
                panes={panes}
            />
        </MainSegment>
    )
}

export default TabDetailAgreements