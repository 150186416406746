import React, { useState, useEffect } from 'react'
import { Form, Grid, Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import { useNavigate } from "react-router-dom-v5-compat"
import cogoToast from '@successtar/cogo-toast'
import './index.css'
import modulo from 'modulo-11'
import {
  activeFormProvider,
  changeFormProvider,
  getProviderEditV2,
  putProviderEditV2
} from '../../actions/providerActions'
import useValidator from '../../helpers/simpleReactValidatorHook'
import InputFormTextAndNumber from '../InputAnimatedGeneralForms/InputFormTextAndNumber'
import InputFormDropdown from '../InputAnimatedGeneralForms/InputFormDropdown'
import InputDV from '../InputAnimatedGeneralForms/InputDV'
import InputSwitch from '../InputAnimatedGeneralForms/InputSwitch'
import { refreshStateModule, warningFormModal } from '../../actions/modalActions'
import { toastOptions } from "../../helpers/globalHelpers"
import { getGeographicList } from "../../actions/defaultListActions"
import { FormContact } from '../FormContact/FormContact'
import { AttachmentButtonForm } from '../InputAnimatedGeneralForms/AttachmentButtonForm'

const Divider = styled.div`
  padding-left: 160px;
  padding-right: 160px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 15px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    margin:0;
  }
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
`
const Title2 = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding-Top: 22px
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1200px) {
    display: block;
  }
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:right;
    width:49%;
    margin:0;
  }
`
const ColumnGrid = styled(Grid.Column)`
  width:50% !important;
  @media only screen and (max-width: 1200px) {
    width: 100% !important;
  }
`
const ColumnGridDividerOne = styled(Grid.Column)`
  width:40% !important;
  @media only screen and (max-width: 1200px) {
    width: 80% !important;
  }
`
const ColumnGridDividerTwo = styled(Grid.Column)`
  width:10% !important;
  @media only screen and (max-width: 1200px) {
    width: 20% !important;
  }
`
const Title3 = styled.p`
  color: #133F4F;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
`

const optionsProviderDefinition = [
  { key: '', text: '', value: '' },
  { key: 'PUBLIC', text: 'PÚBLICA', value: 'PUBLIC' },
  { key: 'PRIVATE', text: 'PRIVADA', value: 'PRIVATE' },
]

const optionsTypePersonCompany = [
  { key: '', text: '', value: '' },
  { key: 'LEGAL_PERSON', text: 'PERSONA JURÍDICA', value: 'LEGAL_PERSON' },
]

const optionsTypeDocumentCompany = [
  { key: '', text: '', value: '' },
  { key: 'NIT', text: 'NIT', value: 'NIT' },
  { key: 'ID_EXTRANJERO', text: 'ID EXTRANJERO', value: 'ID_EXTRANJERO' },
]

const optionsTypeDocumentLegalManager = [
  { key: '', text: '', value: '' },
  { key: 'C.C', text: 'C.C', value: 'C.C' },
  { key: 'C.E', text: 'C.E', value: 'C.E' },
  { key: 'DNI', text: 'DNI', value: 'DNI' },
  { key: 'NIT', text: 'NIT', value: 'NIT' },
  { key: 'NUI', text: 'NUI', value: 'NUI' },
  { key: 'PAS', text: 'PAS', value: 'PAS' },
  { key: 'PEP', text: 'PEP', value: 'PEP' },
  { key: 'R.C', text: 'R.C', value: 'R.C' },
  { key: 'T.I', text: 'T.I', value: 'T.I' },
]

const FormEditProvider = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const { refreshModule } = useSelector(state => state.modalReducer)
  const { warningModal } = useSelector(state => state.modalReducer)
  const { activeForm } = useSelector(state => state.providerReducer)
  const { providerActiveEdit } = useSelector(state => state.providerReducer)
  const { stateFormEditProvider } = useSelector(state => state.providerReducer)
  const { geographicList } = useSelector(state => state.defaultListReducer)
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [departmentList, setDepartmentList] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [cityList, setCityList] = useState([])
  const [selectedCity, setSelectedCity] = useState('')
  const {
    codeCompany,
    companySerialID,
    name,
    providerDefinition,
    typePersonCompany,
    typeDocumentCompany,
    numberDocumentCompany,
    DV,
    phone,
    email,
    domain = '',
    address = '',
    country,
    department,
    city,
    legalManagerName,
    legalManagerLastName,
    typeDocumentLegalManager,
    numberDocumentLegalManager,
    status,
    contactInformation ,
    supportFiles,
    supportDescription
  } = providerActiveEdit
  const [errors] = useState({})
  const [errorFile, setErrorFile] = useState(false)
  const [validator, showValidationMessage,setFields] = useValidator(reactValidatorOptions)
  const [supportDeleteFiles, setSupportDeleteFiles] = useState([])
  const [messagesShown, setMessageShown] = useState(false)

  
  useEffect(() => {
    if(!!messagesShown){
      validateErrors()
    }
  }, [messagesShown])

  useEffect(() => {
    const formattedList = geographicList.map((x) => {
      if (x.code === country) {
        setSelectedCountry(x.code)
        if (!!x.departments) {
          const formattedList2 = x.departments.map((x) => {
            if (x.code === department) {
              setSelectedDepartment(x.code)
              if (!!x.cities) {
                const formattedList3 = x.cities.map((x) => {
                  if (x.code === city) {
                    setSelectedCity(x.code)
                  }
                  return { key: x.code, text: x.name, value: x.code }
                })
                setCityList(formattedList3)
              } else {
                cogoToast.info('No fue posible obtener la lista geográfica', toastOptions)
              }
            }
            return { key: x.code, text: x.name, value: x.code }
          })
          setDepartmentList(formattedList2)
        } else {
          cogoToast.info('No fue posible obtener la lista geográfica', toastOptions)
        }
      }
      return { key: x.code, text: x.name, value: x.code }
    })
    setCountryList(formattedList)
  }, [city, country, department, geographicList])

  useEffect(() => {
    if (activeForm === false && warningModal.status === true) {
      dispatch(activeFormProvider())
    }

    if (refreshModule === true) {
      navigate(-1)
      dispatch(refreshStateModule())
    }

  }, [activeForm, warningModal, refreshModule, dispatch, navigate])

  useEffect(() => {
    const idClients = sessionStorage.getItem("id")
    dispatch(getProviderEditV2(idClients)).then(() => setLoader(false))
    async function geography() {
      await dispatch(getGeographicList())
    }
    geography().then(() => { })
  }, [dispatch])

  const handleChange = (checked) => {
    if (checked === true) {
      dispatch(changeFormProvider('status', 'ENABLE'))
    } else {
      dispatch(changeFormProvider('status', 'DISABLE'))
    }
    dispatch(activeFormProvider())
  }

  const handleOnChange = (event, { value }) => {
    let name =''
    if(event.target.id){
      name = event.target.id
    }else{
      name= event.currentTarget.parentNode.parentNode.id
    }
    dispatch(changeFormProvider([name], value))
  }

  const trimStart = (input)=> {
    let start = 0;
    while (start < input.length && input[start] === ' ') {
        start++;
    }
    return input.substring(start);
  }

  const onChange = e => {
    const name = e.target.id
    const fieldlabel = e.target.getAttribute('label');
    let value = e.target.value; 

    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = trimStart(value);
    const diffLength = value.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    value = trimmedValue;

    dispatch(changeFormProvider([name], value)).then(()=>{
      const input = document.getElementById(name);
      input.setSelectionRange(cursorPosition, cursorPosition);
    })
  
    setFields(name)
  }


  const onEmailChange = e =>{
    let value = e.target.value; 
    const fieldlabel = e.target.getAttribute('label');
    const name = e.target.id


    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    const trimmedValue = trimStart(value);
    const diffLength = value.length - trimmedValue.length;

    const cursorPosition = Math.max(start - diffLength, 0);
    value = trimmedValue;

    dispatch(changeFormProvider([name], value)).then(()=>{
      const input = document.getElementById(name);
      input.setSelectionRange(cursorPosition, cursorPosition);
    })
    setFields(name)
  }


  const onBlurMail = (e) =>{
    dispatch(changeFormProvider('email', email.toLowerCase()))
  }

  const onBlurDomain = (e) =>{
    dispatch(changeFormProvider('domain', domain.toLowerCase()))
  }


  const onAddressChange = e =>{
    if(address.length <200 || e.target.value.length <200){
      const nameValue = e.target.id
      dispatch(changeFormProvider([nameValue], e.target.value))
    }
  }

  const handleOnBlurName = () => {
    return name.length >= 3 ? true:false;
  }

  const handleCancel = () => {
    dispatch(warningFormModal({ type: 'warningLeaveUpdate' }))
  }

  const getNewFiles = (files) => {
    let newFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (!('originalFileName' in files[i])) {
       newFiles.push(files[i])
      }
    }
    return newFiles;
  }

  const validateDiferentContactInformation = (contactinfo) => {
    if (contactinfo !== undefined) {
      const contact1 = contactinfo[0]
      const contact2 = contactinfo[1]

      if (
        contact1.name.trim() !== contact2.name.trim() ||
        contact1.surNames.trim() !== contact2.surNames.trim() ||
        contact1.email.trim() !== contact2.email.trim() ||
        contact1.phoneContact.trim() !== contact2.phoneContact.trim() ||
        contact1.occupation.trim() !== contact2.occupation.trim()
      ) {
        return true; 
      }

      return false;
    }
  }

  const makeSaveNotificationReq = () => {
      const body = {
        "codeCompany":codeCompany,
        "name": name,
        "providerDefinition": providerDefinition,
        "typePersonCompany": typePersonCompany,
        "typeDocumentCompany": typeDocumentCompany,
        "numberDocumentCompany": numberDocumentCompany,
        "DV": DV,
        "phone":  phone === undefined  ? '' :phone,
        "email": email,
        "domain": domain,
        "address": address,
        "country": country,
        "department": department,
        "city": city,
        "legalManagerName": legalManagerName,
        "legalManagerLastName": legalManagerLastName,
        "typeDocumentLegalManager": typeDocumentLegalManager,
        "numberDocumentLegalManager": numberDocumentLegalManager,
        "status": status,
        "companySerialID": companySerialID,
        "supportFiles":getNewFiles(supportFiles),
        "contactInformation":contactInformation,
        'supportDescription':supportDescription
      }
      if (supportDeleteFiles.length > 0) {
        body.supportDeleteFiles = supportDeleteFiles;
      }
      dispatch(putProviderEditV2(body))
  }

  const handleSend = (e) => {
    e.preventDefault()
    if (validator.allValid()) {
      if (supportFiles.length > 0) {
           makeSaveNotificationReq()
      }else{
        setErrorFile(true)
        setMessageShown(true)
      }
    } else {
      showValidationMessage(true);
      if (supportFiles.length ===0) {
        setErrorFile(true)
      }
      setMessageShown(true)
    }
  }

  const calculateDV = () =>{
    let generatedDV = ''
    try {
      generatedDV = modulo.calc(numberDocumentCompany).toString()
      dispatch(changeFormProvider(['DV'], generatedDV))
    } catch (error) {
      
    }
  }

  const setContactInformation= (index, property, value) => {
    const updatedContactInformation = [...contactInformation];
    updatedContactInformation[index][property] = value;
    dispatch(changeFormProvider(['contactInformation'], updatedContactInformation))
    setFields(`${property}${index+1}`)
  };

  const setSupportFiles = (supportFiles) => {
    dispatch(changeFormProvider(['supportFiles'], supportFiles))
  }

  const validateErrors = () => {
    const elementError= document.querySelector('.labelInputFormEntitiesError, .divInputDropdownFormEntitiesError, .supportFilesError')
    if(elementError.className.includes('divInputDropdownFormEntitiesError')){
      elementError.firstChild.focus()
    }else{
      elementError.focus()
    }
    setMessageShown(false)
  }

  return (
    <>
      <Dimmer active={loader} inverted>
        <Loader size='medium'>Cargando...</Loader>
      </Dimmer>
      <Divider>
        <CustomForm>
          <Title>Datos del operador</Title>
          <Grid>
            <ColumnGrid>
            <InputFormTextAndNumber
                id={"codeCompany"}
                label={"Código de operador"}
                type={"text"}
                onChange={onChange}
                value={codeCompany}
                validator={validator}
                validateOptions={[
                  "required",
                  'providerClientCode'
                ]}
                error={errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'name'}
                label={'Nombre'}
                type={'text'}
                onChange={onChange}
                value={name}
                validator={validator}
                validateOptions={'required|nameOfCompany'}
                error={errors}
                onBlur ={handleOnBlurName}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsProviderDefinition}
                placeholder={'Tipo de empresa'}
                value={providerDefinition}
                handleOnChange={handleOnChange}
                id={'providerDefinition'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypePersonCompany}
                placeholder={'Tipo de persona'}
                value={typePersonCompany}
                handleOnChange={handleOnChange}
                id={'typePersonCompany'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeDocumentCompany}
                placeholder={'Tipo de documento'}
                value={typeDocumentCompany}
                handleOnChange={handleOnChange}
                id={'typeDocumentCompany'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGridDividerOne>
              <InputFormTextAndNumber
                id={'numberDocumentCompany'}
                label={'No. documento'}
                type={'text'}
                onChange={onChange}
                value={numberDocumentCompany}
                validator={validator}
                validateOptions={['numeric', { min: 8 }, { max: 12 }, 'required']}
                error={errors}
                onBlur ={calculateDV}
              />
            </ColumnGridDividerOne>
            <ColumnGridDividerTwo>
              <InputDV
                id={'DV'}
                label={'DV'}
                type={'text'}
                onChange={onChange}
                value={DV}
                validator={validator}
                validateOptions={typeDocumentCompany === 'NIT' ? ['numeric', { min: 1 }, { max: 1 }, 'required'] : ['numeric', { min: 1 }, { max: 1 }, 'required']}
                error={errors}
              />
            </ColumnGridDividerTwo>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'phone'}
                label={'Teléfono'}
                type={'text'}
                onChange={onChange}
                value={phone ? phone: ''}
                validator={validator}
                validateOptions={['phone', { min: 7 }, { max: 15 }]}
                error={errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'email'}
                label={'Email'}
                type={'text'}
                onChange={onEmailChange}
                value={email}
                validator={validator}
                validateOptions={['email', 'required']}
                error={errors}
                onBlur={onBlurMail}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'domain'}
                label={'Dominio'}
                type={'text'}
                onChange={onEmailChange}
                value={domain}
                validator={validator}
                validateOptions={['url', { min: 1 }, { max: 50 }]}
                error={errors}
                onBlur={onBlurDomain}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'address'}
                label={'Dirección'}
                type={'text'}
                onChange={onChange}
                value={address}
                validator={validator}
                validateOptions={['address',{ min: 0 }, { max: 200 }]}
                error={errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={countryList}
                placeholder={'País'}
                value={selectedCountry}
                handleOnChange={handleOnChange}
                id={'country'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={departmentList}
                placeholder={'Departamento'}
                value={selectedDepartment}
                handleOnChange={handleOnChange}
                id={'department'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormDropdown
                options={cityList}
                placeholder={'Ciudad'}
                value={selectedCity}
                handleOnChange={handleOnChange}
                id={'city'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
          </Grid>          
          <Title2>Datos representante legal</Title2>
          <Grid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'legalManagerName'}
                label={'Nombres representante legal'}
                type={'text'}
                onChange={onChange}
                value={legalManagerName}
                validator={validator}
                validateOptions={['nameOfProviderClient', { min: 1 }, { max: 100 }, 'required']}
                error={errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'legalManagerLastName'}
                label={'Apellidos representante legal'}
                type={'text'}
                onChange={onChange}
                value={legalManagerLastName}
                validator={validator}
                validateOptions={['nameOfProviderClient', { min: 1 }, { max: 100 },'required']}
                error={errors}
              />
            </ColumnGrid>
          </Grid>
          <Grid>
            <ColumnGrid>
              <InputFormDropdown
                options={optionsTypeDocumentLegalManager}
                placeholder={'Tipo de documento representante legal'}
                value={typeDocumentLegalManager}
                handleOnChange={handleOnChange}
                id={'typeDocumentLegalManager'}
                validator={validator}
                validateOptions={['required']}
                error={errors}
              />
            </ColumnGrid>
            <ColumnGrid>
              <InputFormTextAndNumber
                id={'numberDocumentLegalManager'}
                label={'No. documento representante legal'}
                onChange={onChange}
                value={numberDocumentLegalManager}
                validator={validator}
                validateOptions={['numeric', { min: 4 }, { max: 15 }, 'required']}
                error={errors}
              />
            </ColumnGrid>
          </Grid>
          <AttachmentButtonForm setSupportFiles={setSupportFiles} supportFiles={supportFiles} setErrorFile={setErrorFile} formValidator={validator} errorFile={errorFile} supportDeleteFiles={supportDeleteFiles} setSupportDeleteFiles={setSupportDeleteFiles} supportDetail={supportDescription } onChange={onChange} validator={validator} errors={errors}/>
          <FormContact setContactInformation={setContactInformation} contactInformation={contactInformation} formValidator={validator} errors={errors}
    
          />
          <Grid>
            <ColumnGrid>
              <InputSwitch
                checked={status === 'ENABLE'}
                onChange={handleChange}
              />
            </ColumnGrid>
          </Grid>

          <ContainerButtons>
            <ButtoCancel
              onClick={handleCancel}
            >Cancelar
            </ButtoCancel>
            <ButtoSave
              disabled={stateFormEditProvider}
              className='putButton'
              onClick={handleSend}
            >Actualizar
            </ButtoSave>
          </ContainerButtons>
        </CustomForm>
      </Divider>
    </>
  )
}

export default FormEditProvider
