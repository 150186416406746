import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

const Divider3 = styled.div`
  background: rgba(227, 95, 0, 0.05);
  border: 1px solid #e35f00;
  border-radius: 4px;
  padding:  16px 24px ;
  margin-inline: 24px;
  margin-top: 14px;
  display: flex;
  color: #E35F00;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

const ButtonViewClients = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  white-space:nowrap;
  cursor:pointer;
`

export const AlertWrongUseNotifications = () => {
  const navigate = useNavigate()
  const {info} = useSelector(state => state.dashboardReducer)
  const [totalClientsWithWrongUse, setTotalClientsWithWrongUse] = useState(0)

  useEffect(() => {
    if (info?.result?.totalClientsWithWrongUse) {
      setTotalClientsWithWrongUse(info.result.totalClientsWithWrongUse);
    }
  }, [info]);
  
  return (
    <>
      {

      totalClientsWithWrongUse > 0 &&
        <Divider3> 
          <p><strong>{totalClientsWithWrongUse} clientes</strong> con notificaciones reportadas por uso indebido.</p>
          <ButtonViewClients onClick={() => navigate('/improperClients')}>Ver clientes</ButtonViewClients>
        </Divider3>
      
      }
    </>
  )
}
