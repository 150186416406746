import React, { useState } from 'react'
import { SearchBarGenericCreate } from './SearchBarGeneric'

const ContainerGenericSearchBar = (props) => {

  const [searchBarState, setSearchBarState] = useState("")
  const [popUpOrderState, setPopUpOrderState] = useState("")
 
    return (
     <SearchBarGenericCreate customWidthDiv2={props.customWidthDiv2} textPlaceHolder={props.textPlaceHolder} searchBarState={searchBarState} 
                      buttonType= {props.buttonType} text={props.text} popUpOrderState={popUpOrderState} loader={props.loader}/>
    )
}

export default ContainerGenericSearchBar