import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/icons/sent/leftArrow.svg'
import { warningFormModalUserandEmployes } from '../../../../../actions/modalActions'
import { useNavigate } from "react-router-dom-v5-compat"
import FormCreateEmployes from '../../../../../components/FormUser/FormEmployesClientsAndProviders/FormCreateEmployes'
import { getProviderDetail, getRolesProvider } from '../../../../../actions/providerActions'
import WarningModalUserAndEmployes from '../../../../../components/FormUser/Modal/WarningModalUserAndEmployes'
import WarningModal from '../../../../../components/ModalGeneralForms/WarningModal'

const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`

const CreateEmployee = () => {

    const dispatch = useDispatch()
    const { activeForm } = useSelector(state => state.userReducer)
    const { detailProvider } = useSelector(state => state.providerReducer)
    const [data, setData] = useState([])
    const [role, setRole] = useState([])
    const navigate = useNavigate()
    const idClients = sessionStorage.getItem("id")

    const handleClick = () => {
        if (activeForm === true) {
            dispatch(warningFormModalUserandEmployes({ type: 'warningLeaveUser' }))
        } else {
            navigate(`/providers-detail/${detailProvider.companySerialID}`)
        }
    }

    useEffect(() => {
        dispatch(getProviderDetail(idClients))
        dispatch(getRolesProvider()).then(resul => { setData(resul) })
    }, [dispatch])

    useEffect(() => {
        const dataRole = data.map((data) => {
            return { key: data._id, text: data.name, value: data._id }
        })
        dataRole.unshift({ key: '', text: '', value: '' })
        setRole(dataRole)
    }, [data])
    return (
        <>
            <Container>
                <Arrow>
                    <LeftArrow
                        onClick={handleClick}
                    >
                        <LeftArrowIcon />
                        <WarningModal type='warningLeaveUpdate' />
                    </LeftArrow>
                </Arrow>
                <TitleContainer>
                    <Title>{detailProvider.name} / Nuevo usuario</Title>
                </TitleContainer>
            </Container>
            <FormCreateEmployes
             role={role} 
            /> 
            <WarningModalUserAndEmployes type='warningLeaveUser'/>
            
        </>
    )
};

export default CreateEmployee