import React, { useState, useEffect } from 'react'
import { MainSegment } from '../../../../../components/Sengment/MainSegment'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/icons/sent/leftArrow.svg'
import { warningFormModalUserandEmployes } from '../../../../../actions/modalActions'
import { useHistory } from 'react-router-dom'
import EditFormEmployes from '../../../../../components/FormUser/FormEmployesClientsAndProviders/EditFormEmployes'
import { getRolesClient } from '../../../../../actions/clientActions'
import { getRolesProvider } from '../../../../../actions/providerActions'
import WarningModalUserAndEmployes from '../../../../../components/FormUser/Modal/WarningModalUserAndEmployes'
import { useNavigate } from "react-router-dom-v5-compat"

const Container = styled.div`
  margin: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
`
const Title = styled.p`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;

`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`
const EditUserEmployee = () => {

  const dispatch = useDispatch()
  const roleActiveEdit = useSelector(state => state.userReducer.userActiveEdit.role)
  //const activeFormClient  = useSelector(state => state.clientReducer.activeForm)
  //const activeFormProvider  = useSelector(state => state.providerReducer.activeForm)
  const {activeForm} = useSelector(state => state.userReducer)
  const { employeeActiveEdit } = useSelector(state => state.clientReducer)
  const [roleEmployee, setRoleEmployee] = useState([])
  const [role, setRole] = useState([])
  const navigate = useNavigate()

  const handleClick = () => {
    if (activeForm === true) {
      dispatch(warningFormModalUserandEmployes({type: 'warningLeaveUpdate'}))
    }  else {
      navigate(-1)
    }
  }

  useEffect(() => {
    if(roleActiveEdit === "Notificador"){
      dispatch(getRolesClient()).then(resul => { setRoleEmployee(resul) })
    }else{
      dispatch(getRolesProvider()).then(resul => { setRoleEmployee(resul) })
    }
  }, [roleActiveEdit, dispatch])

  useEffect(() => {
    const dataRole = roleEmployee.map((data) => {
      return { key: data._id, text: data.name, value: data._id }
    })
    dataRole.unshift({ key: '', text: '', value: '' })
    setRole(dataRole)
  }, [roleEmployee])

  return (
    <MainSegment>
      <Container>
        <Arrow>
          <LeftArrow
            onClick={handleClick}
          >
            <LeftArrowIcon />
            <WarningModalUserAndEmployes type='warningLeaveUpdate' />
          </LeftArrow>
        </Arrow>
        <TitleContainer>
          <Title>Editar usuario</Title>
        </TitleContainer>
      </Container>
      <EditFormEmployes role={role}/>
      
    </MainSegment>
  )
}
export default EditUserEmployee