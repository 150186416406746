import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContainerSearchAgreementsOperator from '../../../components/Search/SearchAgreements/operatorAgreements/ContainerSearchAgreementsOperator'
import AllAgreements from "./allAgreements/AllAgreementsOperator";
import { useSelector } from 'react-redux';

const OperatorAgreements = () => {
  const agreements = useSelector(state => state.agreementReducer.agreementListArray )
  const [paginationLoader, setPaginationLoader] = useState(true)

  return (
    <>
      <ContainerSearchAgreementsOperator 
        showAZ={true}
        reportType={"LIST_AGREEMENTS"} 
        providerType={"AGREEMENT"}
        disabledExportButton={!agreements.length > 0}
        showAdvancedSearch={false}
        loader={paginationLoader}
      />
      <AllAgreements setPaginationLoader={setPaginationLoader}/>
    </>
  )
}

OperatorAgreements.propTypes = {
  history: PropTypes.any.isRequired,
}
export default OperatorAgreements
