import React from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import CreateProviders from './CreateProviders'


const AddClients = () => {
  return (
    <MainSegment>
      <CreateProviders />
    </MainSegment>
  )
}



export default AddClients