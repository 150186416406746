import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContainerGenericSearchBar from '../../components/Search/ContainerGenericSearchBar'
import AllUsers from './AllUsers'
import WarningModalUserAndEmployes from '../../components/FormUser/Modal/WarningModalUserAndEmployes'

const Users = (props) => {

  const [paginationLoader, setPaginationLoader] = useState(true)

  return (
    <>
      <ContainerGenericSearchBar customWidthDiv2={'25%'} textPlaceHolder={'Nombre de usuario'} buttonType= {'user'} text={'Nuevo usuario'} loader={paginationLoader}/>
      <WarningModalUserAndEmployes />
      <AllUsers navigate={props.navigate} noHover={true} setPaginationLoader={setPaginationLoader}/>
    </>
  )
}

Users.propTypes = {
  navigate: PropTypes.any.isRequired,
}

export default Users