import React from 'react'
import { MainSegment } from '../../../../../components/Sengment/MainSegment'
import CreateEmployee from './CreateEmployee'

const AddEmployee = () => {
  return (
    <MainSegment>
      <CreateEmployee />
    </MainSegment>
  )
}
export default AddEmployee