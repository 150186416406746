import {
  GET_COMPANY_USERS,
  GET_USERS_LIST,
  GET_USER_EDIT,
  CHANGE_STATE_USER,
  SAVE_USER,
  EDIT_USER,
  INACTIVE_STATE_USER_FORM,
  CHANGE_EDIT_USER,
  ACTIVE_FORM_EDIT_USER,
  INACTIVE_FORM_EDIT_USER,
  SAVE_USER_CERTIFICATE,
  CHANGE_HAVE_CERTIFICATE,
  LOGOUT,
} from "../actions/actionsUtilities/types.js"

const initialState = {
  companyUsers: [],
  listUsers: [],
  userActiveEdit: {},
  activeForm: false,
  savedUser: [],
  userPut: [],
  stateFormEdit: true,
  preRegisterUserData: {},
  stateRegister: true,
  userCertificate: {},
  haveCertificate: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_USERS:
      return { ...state, companyUsers: action.payload }
    case GET_USERS_LIST:
      return {
        ...state,
        listUsers: action.payload.user,
        elementsCount: action.payload.elementsCount,
      }
    case GET_USER_EDIT:
      return {
        ...state,
        namesUserEdit: `${action.payload.firstName} ${action.payload.lastName}`,
        userActiveEdit: { ...action.payload }
      }
    case CHANGE_STATE_USER:
      return {
        ...state,
        activeForm: true,
      }
    case SAVE_USER:
      return {
        ...state,
        savedUser: action.payload
      }
    case EDIT_USER:
      return {
        ...state,
        userPut: action.payload,
      }
    case INACTIVE_STATE_USER_FORM:
      return {
        ...state,
        activeForm: false,
      }
    case CHANGE_EDIT_USER:
      return {
        ...state,
        userActiveEdit: {
          ...state.userActiveEdit,
          [action.payload.key]: action.payload.value
        }
      }
    case ACTIVE_FORM_EDIT_USER:
      return {
        ...state,
        stateFormEdit: false,
      }
    case INACTIVE_FORM_EDIT_USER:
      return {
        ...state,
        stateFormEdit: true,
      }
    case SAVE_USER_CERTIFICATE:
      return {
        ...state,
        userCertificate: action.payload,
        haveCertificate: true,
      }
    case CHANGE_HAVE_CERTIFICATE:
      return {
        ...state,
        haveCertificate: action.payload,
      }
    case LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
