import React, { useState } from 'react'
import ContainerSearchAgreementsSuperAdmin from '../../../components/Search/SearchAgreements/superAdminAgreements/ContainerSearchAgreementsSuperAdmin'
import AllAgreements from "./allAgreements/AllAgreementsSuperAdmin";
import { useSelector } from 'react-redux';

const OperatorAgreements = () => {

  const agreements = useSelector(state => state.agreementReducer.agreementListArray)
  const [paginationLoader, setPaginationLoader] = useState(true)
  return (
    <>
      <ContainerSearchAgreementsSuperAdmin 
        showAZ={true}
        reportType={"LIST_AGREEMENTS"} 
        providerType={"AGREEMENT"} 
        disabledExportButton={!agreements.length > 0}
        loader={paginationLoader}
      />
      <AllAgreements setPaginationLoader={setPaginationLoader} />
      
    </>
  )
}

export default OperatorAgreements
