import React, {  useEffect, useState } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import {  activePagination } from '../../../../../actions/filterDetailActions'
import { detailAttachmentsV2, viewPDF, updateList, removeFromList, deleteAllFromList } from '../../../../../actions/seeAttachmentAction'
import RowTableAttachmentDetail from './RowTableAttachmentDetail'
import GeneralTable from '../../../../../components/Table/GeneralTable'



const AllAttachmentDetailList = ({activeAll,setState,state}) => {

  const dispatch =  useDispatch()
  const { attachmentID } = useParams();



const filterReducer = useSelector(state =>state.filterDetailReducer )
const listAttachments = useSelector(state =>state.userDocumentReducer.detailAttachment)
const typeIcon = useSelector(state =>state.userDocumentReducer.typeIcon)
const stateNotificationStarred = useSelector(state =>state.starredReducer.stateNotificationStarred)

const  columnDataList =  [
  { name: "", width: 1, arrow: false },
  { name: "", width: 1, arrow: false },
  { name: "Nombre documento", width: 5, arrow: false },
  { name: "Fecha de envío", width: 3, arrow: false },
  { name: "", width: 3, arrow: false },
  { name: "", width: 2, arrow: false },
  { name: "", width: 2, arrow: false },
]

const columnDataGrid =  [
  { name: "Documentos", width: 16, arrow: false },
]

  useEffect(() => {
      makeDetailAttachmentsRequest(filterReducer)
      dispatch(viewPDF({ "viewPDF": false }))
  }, [])


  useEffect(() => {
    if (!state.init) {
      makeDetailAttachmentsRequest(filterReducer)
      setState({ init: true, loader: true })
    }
  }, [filterReducer])

  useEffect(() => {
    if (!!filterReducer.starred) {
      makeDetailAttachmentsRequest(filterReducer)
    }
  }, [stateNotificationStarred])


  const makeDetailAttachmentsRequest = (filterReducer) => {
    dispatch(deleteAllFromList())
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      limit: 20,
      sort: orderTranslator(filterReducer.sort),
      status: validateStatus(filterReducer.searchAdvanced.notificationState),
      initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
      endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
      starred: filterReducer.starred,
      attachmentsName: filterReducer.searchBar[0],
      to: attachmentID,
    }

    if (filterReducer.searchAdvanced.notificationState === 'REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'REQUIRED_SIGNATURE' ) {
      body.requiredSignature = true;
    }

    if (filterReducer.searchAdvanced.notificationState === 'NO_REQUIRED_SIGNATURE' || filterReducer.requiredSignature  === 'NO_REQUIRED_SIGNATURE') {
      body.requiredSignature = false;
    }

    if (body.initDate === "") {
      delete body.initDate
      delete body.endDate
    }

    dispatch(detailAttachmentsV2(body))
      .then(() => {
        dispatch(activePagination(false))
        setState((prevstate) => ({...prevstate, init: false, loader: false }))
      })
  }


  const validateStatus = (status) =>{
    return status === 'REQUIRED_SIGNATURE'  || status === 'NO_REQUIRED_SIGNATURE' ? '' : status
  }

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "fileOriginalName": 1 },
      'Z-A': { "fileOriginalName": -1 }
    }
    return options[order]
  }

  const addCheckedRow = (id) => {
    dispatch(updateList(id))
  }

  const removeCheckedRow = (id) => {
    dispatch(removeFromList(id))
  }

  return (
    <GeneralTable
        list={listAttachments}
        typeList="listAttachments"
        listHeight={'listAttachments'}
        headerTable={(typeIcon === 1) ? columnDataList : columnDataGrid}
        rowComponent={RowTableAttachmentDetail}
        loader={state.loader}
        addCheckedRow={addCheckedRow}
        removeCheckedRow={removeCheckedRow}
        activeAll={activeAll} 
        to={attachmentID}/>
  )
}

export default (AllAttachmentDetailList)

