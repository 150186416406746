import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { logoutUser, getRefreshToken, sessionExpiredLogout } from "../../actions/authActions"
import { deleteLoadNotificationItemV2 } from '../../actions/writerActions'
import { getCookieHeaders } from '../../helpers/globalHelpers'
import { changeMessageModal } from '../../actions/modalActions'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const getCookie = async () => {
  return await getCookieHeaders('__Host-rfsht')
}

const IdleTimerComponentsC = (props) => {
  const customEqual = (oldValue, newValue) => oldValue === newValue
  const { id } = useSelector((state) => state.authReducer.user)
  const { citizenIsCharging, notificationsIsCharging } = useSelector((state) => state.writerReducer)
  const { iat } = useSelector((state) => state.authReducer.user, customEqual)
  const refreshTokenTime = new Date((iat + 240) * 1000)
  const { resultNotificationsProgress } = useSelector(state => state.writerReducer)
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const history = useHistory()

  const [stateCitizenIsCharging, setStateCitizenIsCharging] = useState(citizenIsCharging)
  const [stateNotificationsIsCharging, setStateNotificationsIsCharging] = useState(notificationsIsCharging)

  useEffect(() => {
    setStateCitizenIsCharging((prevState) => (citizenIsCharging))
  }, [citizenIsCharging])
  
  useEffect(() => {
    setStateNotificationsIsCharging((prevState) => (notificationsIsCharging))
  }, [notificationsIsCharging])

  const onIdle = () => {
    const statusList = ['Completado', 'Incompleto', 'No cargado', 'Error']
    const body = {
      fileSerialIDList: resultNotificationsProgress.progress.filter((item) => statusList.includes(item.state)).map(item => item.fileSerialID),
      newVisibility: false
    }
    if (body.fileSerialIDList.length > 0) {
      dispatch(deleteLoadNotificationItemV2(body))
    }
    onLogout()
  }

  const onAction = () => {
    setIsActive(true)
  }

  const { reset, getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 300000, // 5 minutos
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('citizenIsChargingState', stateCitizenIsCharging);
      // console.log('citizenIsCharging', citizenIsCharging);
      // console.log('notificationsIsCharging', stateNotificationsIsCharging);
      if (citizenIsCharging || notificationsIsCharging) reset()
      else {
        if (getRemainingTime() / 1000 < 35) {
          dispatch(changeMessageModal({ type: 'sessionWillClose', modalStatus: true }))
        }
      }
    }, 10000) //10 segundos

    return () =>   clearInterval(interval) 
  }, [useState,stateCitizenIsCharging, stateNotificationsIsCharging])

  useEffect(() => {
    const interval = setInterval(() => {
      const rfshTokenTime = refreshTokenTime
      if (new Date() >= rfshTokenTime) {
        onRefreshToken()
      }
    }, 30000) //30 segundos
    return () => {
      clearInterval(interval)
    }
  }, [isActive, iat])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('segundos para cerrar sesión', getRemainingTime() / 1000)
  //   }, 1000)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  useEffect(() => {
    if (isActive === false) {
      if (citizenIsCharging === true || notificationsIsCharging === true) {
        setIsActive(true)
      }
    }
  }, [isActive])

  const onRefreshToken = async () => {
    const refreshToken = await getCookie()
    const body = {
      id: id,
      refreshToken: refreshToken
    }
    if (isActive === true || citizenIsCharging === true || notificationsIsCharging === true) {
      dispatch(getRefreshToken(body))
    }
  }

  const onLogout = () => {
    if (!citizenIsCharging && !notificationsIsCharging) {
      dispatch(sessionExpiredLogout(history))
    }
  }

  return (
    <div></div>
  )
}

export default IdleTimerComponentsC