import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { ReactComponent as EditIcon } from '../../../assets/icons/clients/EditIcon.svg'
import styled from 'styled-components'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { getClientDetail } from '../../../actions/clientActions'
import { getProviderDetail } from '../../../actions/providerActions'
import { useNavigate } from "react-router-dom-v5-compat"
import { ReactComponent as DawnArrow } from '../../../assets/icons/DawnArrow.svg'
import { ReactComponent as UpArrow } from '../../../assets/icons/UpArrow.svg'
import { useLocation } from "react-router-dom-v5-compat"

const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  border-radius: 4px;
  margin: 16px;
  overflow: hidden;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
  margin: 0px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
  font-size: 12px;
}

`
const Title2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
  margin: 0px;
  margin-left: 3px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
  font-size: 12px;
}
`
const Divider = styled.div`
  display: flex
`
const ButtonEdit = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  padding: 8px 8px;

  @media only screen and (max-width: 1366px) and (min-width: 1025px)  {
    svg{
    width: 100 !important;
    height: 16;
    fill: none;
}
}
`
const ButtonDetail = styled.button`
  background: #FFFFFF;
  width:100%;
  border:none;
`

const ButtonDiv = styled.div`
  padding :1rem;
  display: flex;
  justify-content: space-between;
  color: #133F4F;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
`
const StyleDateAgreements = styled.text`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  padding-left:5px;
  color: #22C1D0;
  text-decoration-line: underline;
  cursor: pointer;
`
const BoxFiles = styled.div`
  gap: 0;
  max-height: 53px !important;
  height: 53px !important;
  overflow-y: auto;
  overflow-x:hidden;
  ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;   
  -ms-hyphens: auto;          
  -moz-hyphens: auto;         
  -webkit-hyphens: auto;
  hyphens: auto;
`

export const CollapsableDetailClient = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleColapsado = () => {
    setIsCollapsed(!isCollapsed);
  };


  const dispatch = useDispatch()
  const { detailClient } = useSelector(state => state.clientReducer)
  const { detailProvider } = useSelector(state => state.providerReducer)
  const { role } = useSelector(state => state.authReducer.user)
  const navigate = useNavigate()
  const location = useLocation()
  const ruta = location.pathname

  useEffect(() => {
    const idClients = sessionStorage.getItem("id")
    if (ruta === `/clients-detail/${idClients}`) {
      dispatch(getClientDetail(idClients))
    } else {
      dispatch(getProviderDetail(idClients))
    }
  }, [dispatch, ruta])




  const handleEditProvider = () => {
    navigate('/editProviders')
  }

  const handleEditClient = () => {
    navigate('/editClients')
  }

  return (

    <Container>
      <ButtonDetail onClick={toggleColapsado} >
        <ButtonDiv>
          <p>Detalles</p>
          {isCollapsed ? <DawnArrow/> : <UpArrow/>}
        </ButtonDiv>
      </ButtonDetail>
      {!isCollapsed && (
        <Grid style ={{margin: '16px'}}>
        <Grid.Column computer={13} tablet={16}>
          <Grid  stackable stretched>
            <Grid.Column computer={5} tablet={8} >
              <Divider><Title>Tipo Empresa:</Title><Title2>{detailClient.definition === 'PRIVATE' ? 'Privada' : 'Pública'}</Title2></Divider>
              <Divider><Title>Tipo persona:</Title><Title2>Jurídica</Title2></Divider>
              <Divider><Title>Tipo documento:</Title><Title2>{detailClient.typeDocumentCompany}</Title2></Divider>
              <Divider><Title>Documento:</Title><Title2>{detailClient.numberDocumentCompany}-{detailClient.DV}</Title2></Divider>
              <Divider><Title>Teléfono:</Title><Title2>{detailClient.phone}</Title2></Divider>
            </Grid.Column>
            <Grid.Column computer={6} tablet={8} >
              <Divider><Title>Email:</Title><Title2>{detailClient.email}</Title2></Divider>
              <Divider><Title>Dominio:</Title><Title2>{detailClient.domain}</Title2></Divider>
              <Divider><Title>Dirección:</Title><Title2>{detailClient.address}</Title2></Divider>
              <Divider><Title>País:</Title><Title2>{detailClient.countryName}</Title2></Divider>
              <Divider><Title>Departamento:</Title><Title2>{detailClient.departmentName}</Title2></Divider>
            </Grid.Column>
            <Grid.Column computer={5} tablet={16}>
              <Divider><Title>Ciudad:</Title><Title2>{detailClient.cityName}</Title2></Divider>
              <Divider><Title>Representante legal:</Title><Title2>{detailClient.legalManagerName} {detailClient.legalManagerLastName}</Title2></Divider>
              <Divider><Title>Documento Repres. Legal:</Title><Title2>{detailClient.typeDocumentLegalManager} {detailClient.numberDocumentLegalManager}</Title2></Divider>
              <Divider><Title>Habilitación de Empresa:</Title><Title2>{detailClient.status === 'ENABLE' ? 'Activa' : 'Inactiva'}</Title2></Divider>
              <Divider>
                <Title style={{whiteSpace:'nowrap', marginRight:'10px'}}>Soporte de autorización: </Title> 
                <BoxFiles>

                          {detailClient.supportFiles?.map((supportFile, i) =>
                            <>
                              <a href={supportFile.base64} download={supportFile.originalFileName} > <StyleDateAgreements key={supportFile.fileName}>{supportFile.originalFileName}</StyleDateAgreements></a>
                              {detailClient.supportFiles[i + 1] === undefined ? '' : <b key={i + 'supportFile'}>{' '}/</b>}
                            </>
                          )}
                </BoxFiles>
              </Divider>
            </Grid.Column>
            
              <Grid.Column width={12}>
              </Grid.Column>
             
          </Grid> 
        </Grid.Column>
       
        <Grid.Column computer={3} tablet={16} >
         
          {
              role === '61b36e2fc34e22351bf8cfb4'
              &&
              <ButtonEdit
                onClick={handleEditClient}
              >
                <EditIcon />
              </ButtonEdit>
            } 
        </Grid.Column>
      </Grid>
      )}
    </Container>

  )
}
