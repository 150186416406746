import React from 'react';
import moment from 'moment/moment.js'

let invalidChar = '';

export const reactValidatorOptions = {
  
  element: (message) => <div className="invalid-feedback">{message}</div>,
  validators: {
    password: {
      message: 'Formato de contraseña incorrecta.',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^((?=.*?[0-9])(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[-_@.,;+{}()/*&%$#!¡¿?'|áéíóúÁÉÍÓÚ])(?!.*?[ ])).{8,}$/)
      },
      required: true  // optional
    },
    pasaporte: {
      message: 'Pasaporte no valido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /(^[A-Z0-9]{6,15})$/)
      },
    },
    documento: {
      message: 'Numero de documento no valido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /(^[0-9]{3,12}$)/)
      },
    },
    void: {
      message: '',
      rule: () => {
        return true
      },
    },
    nameTextLength: {
      message: 'Este campo solo puede contener caracteres alfabéticos y debe tener entre 3 y 50 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z áéíóúüñÁÉÍÓÚÜÑ]){3,50}$/)
      },
    },
    minTextLength: {
      message: 'La longitud mínima es de 3 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ]){3,100}$/)
      },
    },
    maxTextLength: {
      message: 'La longitud maxima es de 100 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ]){3,100}$/)
      },
    },

    nit: {
      message: 'Formato no valido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^(\d){8,12}(-)(\d)$/)
      },
    },
    phones: {
      message: 'El número de teléfono no posee un formato válido',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([+][0-9]{2}[ ]?)?[0-9]{7,10}$/)
      },
    },

    legalManagerName: {
      message: 'Este campo solo acepta letras y debe contener entre 3 y 30 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z áéíóúüñÁÉÍÓÚÜÑ]){3,30}$/)
      },
    },
    url: {
      message: 'Este campo solo acepta urls',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^(https?:\/\/)?([\da-z.-]+)\.([A-Za-z0-9.]{2,6})([/\w .-]*)*\/?$/)
      },
    },
    nameCompany: {
      message: 'Este campo solo acepta letras, números y debe contener entre 3 y 100caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ]){3,100}$/)
      },
    },
    agreementCode: {
      message: 'Este campo solo acepta letras, números y debe contener entre 3 y 15 caracteres y máximo un guión',
      rule: (val, params, validator) => {
        // Asegurarse de que el valor no sea solo guiones
        if (val.replace(/-/g, '').length === 0) {
          return false;
        }
        // Comprobar la longitud de la cadena
        if (val.length < 3 || val.length > 15) {
          return false;
        }
        // Validar el valor con la nueva regex
        // return validator.helpers.testRegex(val, /^([A-Za-z0-9]*-?){1,2}[A-Za-z0-9]*$/);
        return validator.helpers.testRegex(val, /^[A-Za-z0-9]*-?[A-Za-z0-9]*$/);
      },
    },
    descriptionArea: {
      message: 'Este campo solo acepta letras, números y debe contener entre 1 y 100 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),-;.¡!¿?&#]{1,100}$/)
      },
    },
    descriptionCanceleAgreement: {
      message: 'Este campo debe contener entre 20 y 1000 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ -_@.,;+{}()/*&%$#!¡¿?']){20,1000}$/)
      },
    },
    email: {
      message: 'El formato del correo es incorrecto',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/)
      },
    },
    quantityBag: {
      message: 'Este campo solo puede contener caracteres numéricos y debe tener entre 1 y 20 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /(^[0-9]{1,20}$)/)
      },
    },
    isNumber: {
      message: 'Este campo solo admite valores numericos',
      rule: (val) => {
        return parseInt(val).toString() !== 'NaN'
      },
    },
    noNegative: {
      message: 'Este campo no admite valores negativos',
      rule: (val) => {
        return parseInt(val) >= -1
      },
    },
    noDecimal: {
      message: 'Este campo solo admite valores enteros',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^\d+$/)
      },
    },
    noZero: {
      message: 'Cantidad superior a 1',
      rule: (val) => {
        return val > 0
      },
    },
    lengthCorreo100: {
      message: 'Este campo solo puede contener 100 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([a-zA-Z0-9@._-]){5,100}$/)
      },
    },
    campaign: {
      message: 'Este campo no acepta caracteres especiales ni caracteres latinos. (Pueden ser usados los caracteres "-" y "_")',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[A-Za-z0-9_ -]+$/)
      },
    },
    dateAfterCurrent: {
      message: 'Esta fecha debe ser mayor a la actual',
      rule: (val) => {
        return new Date(val) > new Date(new Date().toDateString())
      },
    },
    dateStart: {
      message: 'La fecha debe ser mayor o igual a hoy',
      rule: (val) => {
        return moment(val).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")
      },
    },
    dateEnd: {
      message: 'La fecha debe ser mayor a hoy',
      rule: (val) => {
        return moment(val).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD")
      },
    },
    dateEdit: {
      message: 'La fecha debe tener formato "YYYY-MM-DD" ',
      rule: (val) => {
        return moment(val).format("YYYY-MM-DD") === moment(val).format("YYYY-MM-DD")
      },
    },
    dateSubscription: {
      message: 'La fecha debe ser menor o igual a hoy',
      rule: (val) => {
        return new Date(val) < new Date(new Date().toDateString())
      },
    },
    tradeNumber: {
      message: 'Este campo solo admite valores numericos entre 4 y 15 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[0-9\b]{4,15}$/g)
      }
    },
    datesRange: {
      message: 'Debe seleccionar una fecha o un rango de fechas.',
      rule: (val) => {
        const dates = val.split(" - ");
        return !!dates.length && !!dates[0]
      },
    },
    description: {
      message: 'Este campo debe contener entre 3 y 100 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^.{3,100}$/g)
      }
    },
    onlyNumbers: {
      message: 'Este campo solo admite valores numericos',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[0-9\b]+$/)
      }
    },
    subject: {
      message: 'Este campo debe contener al menos 3 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^.{3,100}$/g)
      }
    },
    nameOfCompany: {
      message: 'Este campo solo acepta letras, números y debe contener entre 3 y 100 caracteres',
      rule: (val, params, validator) => {
        const regex = /^[A-Za-z0-9\s_-áéíóúüñÁÉÍÓÚÜÑ .&]+$/;
        const isValidFormat = validator.helpers.testRegex(val, regex);
        const isValidLength = val.length >= 3 && val.length <= 100;

        return isValidFormat && isValidLength;
      },
    },
    nameOfUser: {
      message: 'Este campo solo acepta letras, números y debe contener entre 3 y 200 caracteres',
      rule: (val, params, validator) => {
        const regex = /^[A-Za-z0-9\s_-áéíóúüñÁÉÍÓÚÜÑ .&]+$/;
        const isValidFormat = validator.helpers.testRegex(val, regex);
        const isValidLength = val.length >= 3 && val.length <= 200;

        return isValidFormat && isValidLength;
      },
    },
    nameOfProviderClient: {
      message: 'Este campo solo acepta letras y debe contener entre 3 y 100 caracteres',
      rule: (val, params, validator) => {
        const regex = /^[A-Za-z\s_-áéíóúüñÁÉÍÓÚÜÑ .&]+$/; 
        const isValidFormat = validator.helpers.testRegex(val, regex);
        const isValidLength = val.length >= 3 && val.length <= 100;
    
        return isValidFormat && isValidLength;
      },
    },
    numberDocumentCompany: {
      message: 'La longitud mínima es de 8 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([0-9]).{8,12}$/g)
      }
    },
    address: {
      message: 'La longitud mínima es de 1 caracter y máximo de 200',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[A-Za-z0-9\s].{0,200}$/g)
      }
    },
    agreenmentCode: {
      message: 'El formato del Código de vinculación es alfanumérico y carácter especial "–"',
      rule: (val) => {
        return val.includes('-')
      }
    },
    subjectNotification: {
      message: 'No se permiten carácteres especiales "–"',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ()].{1,100}$/g)
      }
    },
    subjectNotificationTwo: {
      message: 'Este campo no acepta caracteres especiales',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[\u0000-\u1F5FF\u1F680-\u1F6FF\u1F1E0-\u1F1FF]{3,300}$/)
      },
    },
    commentsUserAndAuditor: {
      message: 'Este campo solo acepta letras, números y debe contener entre 1 y 1000 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ -_@.,;+{}()/*&%$#!¡¿?']){1,1000}$/)
      },
    },
    nameLabels: {
      message: 'No se aceptan caracteres especiales',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^([A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ])+$/)
      }
    },
    phone: {
      message: 'La longitud maxima es de 15 caracteres numéricos',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val,/^\d{1,15}$/)
      }
    },
    providerClientCode: {
      message: 'Este campo solo acepta letras, números y debe contener máximo 10 caracteres',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^[A-Za-z0-9áéíóúüñÁÉÍÓÚÜÑ]{1,10}$/)
      },
    },
    supportDescription: {
      message: 'Este campo solo acepta letras, números y debe contener entre 1 y 100 caracteres',
      rule: (val, params, validator) => {
        invalidChar = val.match(/[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),-;.¡!¿?&#°$%/='¨*:_>+{}¬“£¢∞§¶•ªº«~^…–´` \u005D \u005B \u005C \u2260]/);
        return validator.helpers.testRegex(val, /[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),-;.¡!¿?&#°$%/='¨*:_>+{}¬“£¢∞§¶•ªº«~^…–´` \u005D \u005B \u005C \u2260]{1,100}$/)
      },
    },
    occupation: {
      message: 'Este campo solo acepta letras y números y debe contener entre 3 y 50 caracteres',
      rule: (val, params, validator) => {
        invalidChar = val.match(/[^A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),-;.¡!¿?&#]/);
        return validator.helpers.testRegex(val, /^[A-Za-z0-9 áéíóúüñÁÉÍÓÚÜÑ(),-;.¡!¿?&#]{3,50}$/)
      },
    },
    quantityAgreement: {
      message: 'Este valor debe ser superior o igual a 1',
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(val, /^(?!0+$)[0-9]+$/)
      },
    },
    
  },
  messages: {
    required: 'Campo obligatorio',
    emaill: 'Incluye el signo @ en la dirección de correo electrónico.',
    min: 'La longitud mínima es de :min caracteres.',
    max: 'La longitud maxima es de :max caracteres.',
    numeric: 'Este campo debe ser un número.',
    URL: 'Formato de dominio no válido',
  }
}


