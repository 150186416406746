import React, { Component } from 'react'
import {
  Dropdown,
  Form
} from 'semantic-ui-react'
import './index.css'
import styled from "styled-components"

const TextPlaceholder = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: absolute;
  top: -12px;
  left: 15px;
  z-index: 3;
`
const DropdownGeneric = styled(Dropdown)`
&&& .icon {
  top: 25% !important; 
}
&&& .item {
  height: 32px; 
  line-height: 16px; 
  align-items:center;
  font-size: 16px;
}
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
`

const options = [
  { key: '', text: '', value: '' },
  { key: 'UNREAD', text: 'No leido', value: 'UNREAD' },
  { key: 'READ', text: 'Leido ', value: 'READ' },
  { key: 'PENDING', text: 'Pendiente', value: 'PENDING' },
  { Key: 'REJECTED', text: 'Rechazado', value: 'REJECTED' },
  { key: 'COMPLETE', text: 'Completado', value: 'SIGNED' },
  { key: 'ERROR', text: 'Error', value: 'ERROR' },
]

const FormFilterAttachment = (props) => {
  return (
    <Form onSubmit={props.handleSubmit}>
        <DropdownGeneric
          selection
          button
          options={options}
          style={{ width: "314px", marginBottom: "16px" }}
          value={props.state.notificationState}
          onChange={props.handleOnChange}
          id="state"
        />
        {
          props.state.notificationState === ''
            ? <TextPlaceholder>Estado</TextPlaceholder>
            : ''
        }
      </Form >
  )
}


export default FormFilterAttachment
