import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { changeMessageModal, changeMessageModalReport } from '../../../actions/modalActions'
import Event from './Event'
import './events.css'
import { ReactComponent as EventIncons } from '../../../assets/icons/eventsIcon.svg'
import { ReactComponent as EventIconsCloset } from '../../../assets/icons/eventIconCloset.svg'
import ReportModal from '../../../components/ModalReport/ReportModal'

const Container = styled.div`
  width: 100%;
  border: 1px solid #C6C6C6;
  border-radius: 8px;

  @media only screen and (max-width: 1200px)  {
    border: none;
  }
`
const Divider = styled.div`
  border: none;
  
  @media only screen and (max-width: 1200px)  {
    border: 1px solid #C6C6C6;
    margin-bottom: 8px;
    border-radius: 8px;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #EBEBEB;
  border-radius: 8px 8px 0 0;
  padding: 0 15px;

  @media only screen and (max-width: 1200px)  {
    justify-content: space-between;
  }
`
const Title = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  padding-top: 15px;
`
const EventList = styled.div`
  width: 100%;
  height: auto;
  max-height: 800px;
  overflow-y: auto;
  padding: 17px 30px;
  @media only screen and (max-width: 1200px)  {
    display: none;
  }
`
const EventList2 = styled.div`
  display: none;

  @media only screen and (max-width: 1200px)  {
    display: block;
    width: 100%;
    height: auto;
    max-height: 800px;
    overflow-y: auto;
    padding: 17px 30px;
  }
`

const NotificationEvents = (props) => {

  const reportData = useSelector((state) => state.sentReducer.reportData)
  const [state, setState] = useState({initialState: false})
  const dispatch = useDispatch()

  const validateDownload = () => {
    dispatch(changeMessageModalReport({
      type:'warningReport'
    }))
  }

  const viewsEvent = () => {
    setState({initialState: !state.initialState})
  }
  
    return (
      <Container>
        <Divider>
          <Header>
            <Title>Eventos</Title>
            {
              state.initialState === true 
              ? <EventIconsCloset
              className={'iconS'} 
              onClick={viewsEvent}
              />
              : <EventIncons
              className={'iconS'} 
              onClick={viewsEvent}
              />
            }
          </Header>
          <EventList>
            <Event 
              notificationID={props.notificationID} 
              />
          </EventList>
          {
            state.initialState === true
            ?
            <EventList2 className={state.initialState === true ? 'list' : 'withoutList'}>
                <Event 
                  notificationID={props.notificationID} 
                />
            </EventList2>
            :''
          }
        </Divider>
        
        <ReportModal/>
      </Container>
    )
}


export default NotificationEvents

