import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionFilterAgreements } from '../../../actions/filterActions'
import { changeDataCreateReport } from '../../../actions/reportsActions'
import ButtonIcon from '../../../components/Button/IconButton'
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg'
import { ReactComponent as Reviewed } from '../../../assets/icons/reviewed.svg'
import '../main.css'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    padding: 0px 0px 0px 0px;
  }
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px;
`

const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

export const PopUpFilterAgreements = ({ reportType, providerType }) => {

  const agreementStatusReducer = useSelector(state => state.filterReducer.agreementFilter.agreementStatus)

  const [customState, setCustomState] = useState({
    show: false,
    agreementStatus: agreementStatusReducer,
    pathname: '',
    dataCreateReport: {
      reportType: reportType,
      status: ["ACTIVE", "PENDING", "INACTIVE", "EXPIRED"],
      providerType: providerType
    }
  })

  const dispatch = useDispatch()
  

  useEffect(() => {
    dispatch(changeDataCreateReport(customState.dataCreateReport))
  }, [])

  useEffect(() => {
    dispatch(actionFilterAgreements(customState.agreementStatus))
  }, [customState.agreementStatus])

  useEffect(() => {
    dispatch(changeDataCreateReport(customState.dataCreateReport))
  }, [customState.dataCreateReport])

  const toggle = () => {
    setCustomState((prevState) => ({ ...prevState, agreementStatus: agreementStatusReducer, show: !customState.show }))
  }

  const toggleAfterSelect = () => {
    setCustomState((prevState) => ({ ...prevState, show: !customState.show }))
  }

  const changeStatus = (optionSelected) => {
    setCustomState((prevState) => ({
      ...prevState, agreementStatus: optionSelected,
      dataCreateReport: {
        ...customState.dataCreateReport,
        status: optionSelected === '' ? ["ACTIVE", "PENDING", "INACTIVE", "EXPIRED"] : optionSelected,
      }
    }))
    toggleAfterSelect()
  }

  return (
    <Divider>
      <PopupFilter position='bottom left' on='click' pinned open={customState.show} onClose={toggle}
        trigger={
          <ButtonIcon onClick={toggle}>
            <Texto> Filtrar </Texto>
            <Filter />
          </ButtonIcon>
        }>
        <ContainerOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('ACTIVE')} >
            {customState.agreementStatus === 'ACTIVE' && <Reviewed className='iconReReviewed' />}
            {customState.agreementStatus !== 'ACTIVE' ? <Text>En curso</Text> : 'En curso'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('PENDING')} >
            {customState.agreementStatus === 'PENDING' && <Reviewed className='iconReReviewed' />}
            {customState.agreementStatus !== 'PENDING' ? <Text>Aprobado</Text> : 'Aprobado'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('INACTIVE')} >
            {customState.agreementStatus === 'INACTIVE' && <Reviewed className='iconReReviewed' />}
            {customState.agreementStatus !== 'INACTIVE' ? <Text>Anulado</Text> : 'Anulado'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('EXPIRED')} >
            {customState.agreementStatus === 'EXPIRED' && <Reviewed className='iconReReviewed' />}
            {customState.agreementStatus !== 'EXPIRED' ? <Text>Vencido</Text> : 'Vencido'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeStatus('')} >
            {customState.agreementStatus === '' && <Reviewed className='iconReReviewed' />}
            {customState.agreementStatus !== '' ? <Text>Limpiar filtro</Text> : 'Limpiar filtro'}
          </ButtonOption>
        </ContainerOption>
      </PopupFilter>
    </Divider>
  )
}

export default PopUpFilterAgreements
