import React from 'react'
import Switch from "react-switch";
import './input-from-entities.css'


export const InputSwitch = (props) => {
    return (
        <label htmlFor="material-switch">
            <span className={'spanSwitch'}>Activo</span>
            <Switch
                checked={props.checked}
                onChange={props.onChange}
                onColor="#133F4F"
                offColor="#6A848E"
                onHandleColor="#FFFFFF"
                handleDiameter={15}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={22}
                width={36}
                className="react-switch"
                id="material-switch"
            />
        </label>
    )
}

export default InputSwitch