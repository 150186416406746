import React, {  useState } from 'react'
import { Tab, Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import { MainSegment } from '../../components/Sengment/MainSegment'
import MenuItemTab from '../../components/Tab/MenuImageTab'
import Notifications from '../../views/sent/Notifications.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_TAB_FILTER } from '../../actions/actionsUtilities/types.js'

const CustomTab = styled(Tab)`
  .active.item {
    color: #22C1D0 !important;
    border-color: #22C1D0 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-bottom-width: 4px !important;
    margin: 0 0 -4px !important;
  }
  .active.item div svg path {
    fill: #22C1D0 !important;
  }
  .ui.menu {
    margin: 25px 20px 0 20px !important;
    border-bottom: 4px solid #E4E4E4 !important;
  }
  .ui.secondary.pointing.menu .item {
    vertical-align: top;
    height: 64px !important;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 0;
  }
@media only screen and (max-width: 1024px) {
  .ui.menu {
    margin: 2% 2% 0 2% !important;
  }
}
@media only screen and (max-width: 665px) {
  .ui.menu {
    margin: 2% 2% !important;
    padding: 15px 0 0 0;
  }
  .ui.secondary.pointing.menu .item {
    padding: 0.5em 0.5em;
  }
}
`

const CustomMenuItem = styled(Menu.Item)`
  display: flex !important;
  flex: 1 !important;
  justify-content: center;
  padding: 20px 20px;
`

const Content = styled(Tab.Pane)`
  border: none !important;
  padding: 0 !important;
`

const Sent = (props) => {
  const indexTab= {
    all: 0,
    readConfirmation: 1,
    notificationSignature: 2,
    docSignature: 3,
    push: 4,
  }

  const { tabFilter } = useSelector(state => state.filterReducer)
  const [activeIndex, setActiveIndex] = useState(indexTab[tabFilter])
  const { blockChangeTap } = useSelector(state => state.sentReducer)
  const dispatch = useDispatch()

  const handleOnChange = (index) =>{
    if (!blockChangeTap){
      dispatch({type: CHANGE_TAB_FILTER})
      setActiveIndex(index)
    } 
  }

  const panes = [
    {
      menuItem: (
        <CustomMenuItem key='all' onClick = {e =>(handleOnChange(0))}>
          <MenuItemTab icon='all' title='Todas' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <Notifications
          tabFilter='all'
          navigate={props.navigate}
        />
      </Content>)
    },
    {
      menuItem: (
        <CustomMenuItem key='readConfirmation' onClick = {e =>(handleOnChange(1))}>
          <MenuItemTab icon='readConfirmation' title='Confirmación de lectura'/>
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <Notifications
          tabFilter='readConfirmation'
          navigate={props.navigate}
        />
      </Content>)
    },
    {
      menuItem: (
        <CustomMenuItem key='notificationSignature' onClick = {e =>(handleOnChange(2))}>
          <MenuItemTab icon='notificationSignature' title='Firma de notificación' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <Notifications
          tabFilter='notificationSignature'
          navigate={props.navigate}
        />
      </Content>)
    },
    {
      menuItem: (
        <CustomMenuItem key='docSignature' onClick = {e =>(handleOnChange(3))}>
          <MenuItemTab icon='docSignature' title='Firma de documentos' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <Notifications
          tabFilter='docSignature'
          navigate={props.navigate}
        />
      </Content>)
    },
    //---------------------------------------------------------------------------
    {
      menuItem: (
        <CustomMenuItem key='push' onClick = {e =>(handleOnChange(4))}>
          <MenuItemTab icon='push' title='Notificaciones Push' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <Notifications
          tabFilter='push'
          navigate={props.navigate}
        />
      </Content>)
    },
  ]

  return (
    <MainSegment>
      <CustomTab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={activeIndex}
      />
    </MainSegment>
  )
}

export default Sent
