import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Modal,Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import {
  Checkbox
} from 'semantic-ui-react'
import { changeMessageModal, changeMessageModalReport } from '../../actions/modalActions'
import { ReactComponent as DonwloadReportIcon } from '../../assets/icons/sent/donwloadReport.svg'
import { ReactComponent as DonwloadWhiteIcon } from '../../assets/icons/sent/donwloadWhite.svg'
import './reportModal.css'
import { changeCheckbookReport, downloadReportV2 } from '../../actions/seeNotificationAction'

const ModalElement = styled(Modal)`
  height: 403px !important;
  padding: 40px !important;
  width: 496px !important;
`
const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
`
const ModalActions = styled.div`
  margin-top: 32px;
  display: flex;
  gap:32px;
  
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: 23px;
  text-align: center;
  margin-bottom: 32px !important;
`
const Texto2 = styled.p`
  width: 198px;
  height: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`
const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 128px;
  height: 48px;
`
const Button2 = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 232px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  
`
const CustomCheckbox = styled(Checkbox)`
  & label, & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 2px solid #22C1D0 !important;
    width: 18px;
    height: 18px;
  }

  & label:before {
    border: 2px solid #133F4F !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label{
    opacity: 0.2 !important;
  }
  
  padding-right: 10px !important;

  .ui.checkbox .box:after, .ui.checkbox label:after {
    size: 12px
}
`

const ReportModal = () => {

  const { reportModal } = useSelector(state => state.modalReducer)
  const { addPrint } = useSelector(state => state.sentReducer)
  const { reportData } = useSelector(state => state.sentReducer)
  const { notificationID } = useParams()
  const dispatch = useDispatch()

    const modalData = (type) => {
        const data = {
            warningReport: {
                icon: <DonwloadReportIcon />,
                InitialMessage: "Descargar informe",
                message: "Incluir estampa cronológica",
                buttonMessage: 'Cancelar',
                buttonMessage2: 'Descargar informe',
            },
        }
        return data[type]
    }

    useEffect(() => {
      if(reportModal.status === true){
        dispatch(downloadReportV2(addPrint, notificationID)).then(() => {})
      }
    }, [reportModal.status,addPrint])

    useEffect(() => {
    }, [reportData])

    const toggle = (e) => {
      let name = e.target.id;
      dispatch(changeCheckbookReport(
          [name], !addPrint
      ))
    }

    const onClose = () => {
      dispatch(changeMessageModalReport({ type: 'warningReport' }))
      dispatch(changeCheckbookReport(
        false
      ))
    }

    const handleOnLoad = () => {
      if (reportData.base64 === undefined) {
        dispatch(changeMessageModalReport({ type: 'warningReport' }))
        dispatch(changeMessageModal({
          type: 'errorDownloadReport'
        })
      )}
      dispatch(changeMessageModalReport({ type: 'warningReport' }))
      //DESCARGA DEL PDF
      var string = reportData.base64;
      var pdfAsArray = convertDataURIToBinary(string);
      var blob = new Blob([pdfAsArray], { type: 'application/pdf' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = `${reportData.fileName ? reportData.fileName :reportData.filename}.pdf`; // Cambia el nombre del archivo si lo necesitas
      a.style.display = 'none';
      document.body.appendChild(a);
      // INICIAR DESCARGA
      a.click();
      // LIMPIEZA DE OBJETOS Y RECURSOS
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      dispatch(changeCheckbookReport(
        false
      ))
    }

    function convertDataURIToBinary(dataURI) {
      var BASE64_MARKER = ';base64,';
      var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));
  
      for (var i = 0; i < rawLength; i++) {
          array[i] = raw.charCodeAt(i);
      }
  
      return array;
  }

    const data = modalData(reportModal.type)
    
    return (
        <ModalElement
            open={reportModal.status}
            onClose={onClose}
        >
            <ModalContent>
                {!!data.icon
                    && data.icon}
                {data.multipleMessages && !!reportModal.message && !!reportModal.message.length &&
                    reportModal.message.map((message) => (
                        <>
                            <ModalMessage>
                                {message}
                            </ModalMessage>
                            <br />
                        </>
                    ))
                }
                {!data.multipleMessages &&
                    <ModalMessage>
                        <Texto>{data.InitialMessage}</Texto>
                        {!reportModal.message
                            ? 
                              <CustomCheckbox
                                control={Checkbox}
                                label={data.message}
                                type='checkbox'
                                onChange={toggle}
                                checked={addPrint}
                                id="addPrint"
                                disabled={reportData.loading}
                              />
                            : reportModal.message}
                        <br />
                        {!reportModal.message
                            ? data.message2
                            : reportModal.message}
                    </ModalMessage>
                }
                {
                    <ModalActions>
                        <Button
                            onClick={onClose}
                        >
                            {data.buttonMessage}
                        </Button>
                        {reportData.loading ? 
                        <Button2>
                            <Loader active inline='centered' />
                        </Button2>
                        :
                        <a
                          download={reportData.fileName}
                          onClick={handleOnLoad}
                        > 
                          <Button2
                          >
                            <DonwloadWhiteIcon
                              className='icon'
                            />
                            <p>{data.buttonMessage2}</p>
                        </Button2>
                        </a>}
                    </ModalActions>
                }
            </ModalContent>
        </ModalElement>
    )
}

export default ReportModal