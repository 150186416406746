import React, { useState } from "react"
import PropTypes from "prop-types"
import { Form } from "semantic-ui-react"
import styled from 'styled-components'
import './input-from-entities.css'
import { useDispatch } from "react-redux"
import { activeFormClient } from "../../actions/clientActions"
import { ReactComponent as SearchIcon } from '../../assets/icons/reports/search.svg'

const Divider = styled.div`
  display: flex;
`
const FormCustom = styled(Form.Select)`
    height: 47px !important;
    max-height: 45px !important;
    border:none !important;
    .dropdown.icon{
    display: none !important;
  }
`

const InputFormDropdown = ({ ...props }) => {

    const dispatch = useDispatch()
    const { placeholder, options, value, id, handleOnChange, onChanegSearch, validator, validateOptions, disabled =false , searchQuery,onFocus } = props
    const [selected, setSelected] = useState(false);

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }
    return (
        <div className={disabled === true ? 'container-dropdown-disable':"container-dropdown"}>
            <label className={errorValidator ? 'labelInputFormEntitiesError' : 'labelInputFormEntities'} style={{height: '50px'}} >

                <FormCustom
                    upward={false}
                    name={id}
                    selection
                    search
                    fluid
                    options={options}
                    className={'inputFormEntities'}
                    value={value}
                    onChange={handleOnChange}
                    onSearchChange={onChanegSearch}
                    selectOnBlur={false}
                    id={id}
                    autoComplete='off'
                    allowAdditions={false}
                    style={{border:'none'}}
                    disabled={disabled}
                    onFocus={()=>{
                                    setSelected(true)
                                    onFocus()}
                                }
                    onBlur={()=>setSelected(false)}
                    
                />
                {errorValidator && searchQuery === '' ?
                    <>
                        <label className={errorValidator && value === '' && 'spanLabelInputTopError'}>{placeholder}</label>
                        <label className={(errorValidator) &&"input-error3"}>{errorValidator}</label>
                    </>
                    :
                    <span className={searchQuery !== '' || value !== '' || selected ? 'labelInputDropdownFormEntitiesTop' : 'labelInputDropdownFormEntities'} style={{ display: 'flex', alignItems: 'center', gap:'8px',zIndex:'5'}}>{searchQuery === '' && value === '' && !selected?<SearchIcon/>:''}{placeholder}</span>
                }
            </label>
        </div>
    )
}


InputFormDropdown.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputFormDropdown