import * as jose from "jose";

const base64ToUint8Array = (base64) => {
  const binaryString = atob(base64);
  const len = binaryString.length
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

const generateKey = async () => {
  return await window.crypto.subtle.generateKey({ name: "ECDH", namedCurve: "P-256" }, true, ["deriveKey", "deriveBits"]);
}

export const generateKeyTosessionStorage = async () => {
  return await generateKey().then(async (key) => {
    await window.crypto.subtle.exportKey("jwk", key.privateKey).then( (exportedKey) => {
      sessionStorage.setItem('privateKey', JSON.stringify(exportedKey));
    }).catch((error) => {
      console.error(error);
    });

    await window.crypto.subtle.exportKey("raw", key.publicKey).then((exportedKey) => {
      sessionStorage.setItem('publicKey', btoa(String.fromCharCode.apply(null, new Uint8Array(exportedKey))))
    }).catch((error) => {
      console.error(error);
    })
  });
}

export const createSharedKey = async (serverPublicKey) => {
  try {
    var privateKey = JSON.parse(sessionStorage.getItem('privateKey'));
    const importedPrivateKey = await window.crypto.subtle.importKey("jwk", privateKey, { name: "ECDH", namedCurve: "P-256", },
      false, ["deriveBits"]);

    const importedServerPublicKey = await window.crypto.subtle.importKey("raw", base64ToUint8Array(serverPublicKey),
      { name: "ECDH", namedCurve: "P-256" }, false, []);

    const sharedSecret = await window.crypto.subtle.deriveBits({ name: "ECDH", public: importedServerPublicKey }, importedPrivateKey, 256);
    
    sessionStorage.setItem('secretKey', btoa(String.fromCharCode.apply(null, new Uint8Array(sharedSecret))))
  } catch (error) {
    console.log(error);
  }
}
const encoder = new TextEncoder();
const decoder = new TextDecoder();

export const encrypt = async (data) => {
  try {
    return await new jose.CompactEncrypt(encoder.encode(JSON.stringify(data)))
      .setProtectedHeader({ alg: 'dir', enc: 'A256GCM' })
      .encrypt(base64ToUint8Array(sessionStorage.getItem('secretKey')));
  } catch (error) {
    console.error("Error while trying to encrypt", error);
    return null;
  }
};

export const decrypt = async (data) => {
  try {
    const { plaintext } = await jose.compactDecrypt(data, base64ToUint8Array(sessionStorage.getItem('secretKey')));
    return JSON.parse(decoder.decode(plaintext));
  } catch (error) {
    console.error("Error while trying to decrypt:", error);
    return null;
  }
};