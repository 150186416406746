
import { getCookieHeaders } from "../../helpers/globalHelpers";
import { store } from "../../store/store";
import { getRefreshToken } from "../authActions";

const { dispatch, getState } = store;
const getCookie = () =>{
    return getCookieHeaders('__Host-rfsht')
}

export const axiosConfig = {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 2000;
    },
    retryCondition: (error) => {
        return error.response && error.response.status === 401;
    },
    onRetry: () => {
        getCookie().then((result)=>{
            const refreshToken = result
            if (refreshToken) {
                dispatch(
                    getRefreshToken({
                        id: getState().authReducer.user.id,
                        refreshToken: refreshToken
                    })
                )
            }
        })
    }
}