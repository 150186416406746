import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Form } from 'semantic-ui-react'
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/sent/leftArrow.svg'
import InputFormMassiveLoad from "../../../components/InputDropdown/InputFormMassiveLoad";
import { openwriteNotificationModal } from '../../../actions/modalActions'
import { RESET_FIELD_PARAMETRIZATION } from '../../../actions/actionsUtilities/types'
import { useNavigate } from 'react-router-dom-v5-compat'


const Container = styled.div`
  margin: 17px 30px;
  margin-left:30px;
  margin-bottom:0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 768px){
    margin: 8px 8px 0 8px;
    gap: 24px;
  }
`
const ContainerCompanyName = styled.div`
  width:30%;
  margin: 30px 32px 0 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  @media only screen and (max-width: 850px)  {
    width:80%;
  }

  @media only screen and (max-width: 768px){
    width: 100%;
    padding: 0px 8px;
    margin: 0;
  }
`

const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin-left: 24px;

  @media only screen and (max-width: 768px){
    margin-left: 0;
  }
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #133F4F;
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;

  @media only screen and (max-width: 768px){
    margin-top: 24px;
  }
`
const ContainerText = styled.div`
  display:block;
  margin: 21px 30px 24px 30px;

  @media only screen and (max-width: 768px){
    margin: 0;
    padding: 24px 8px 0px 8px;
  }
`

const Text = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 24px;
color: #133F4F;
`

const Header = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOpenModalWrite = () => {
    dispatch({type: RESET_FIELD_PARAMETRIZATION})
    dispatch(openwriteNotificationModal())
    navigate('/dashboard')
  }
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      event.preventDefault()
    }
  }
  return (
    <>
      <Container>
        <Arrow>
          <LeftArrowIcon onClick={handleOpenModalWrite}/>
        </Arrow>
        <TitleContainer>
          {
            <Title>
              {'Carga masiva'}
            </Title>
          }
        </TitleContainer>
      </Container>
      <CustomForm> 
        <ContainerCompanyName>
          <InputFormMassiveLoad
            id={'companyName'}
            label={'Nombre de la campaña'}
            placeholder={'Ingrese el nombre de la campaña'}
            onChange={props.onChangeCompanyName}
            type={'text'}
            value={props.companyName}
            validator={props.validator}
            validateOptions={['required', 'nameOfCompany', { min: 1 }, { max: 100 }]}
            errors={props.errors}
            onKeyDown={handleKeyPress}
            />
        </ContainerCompanyName>
        <ContainerText>
            <Text>Parametrización de campos</Text>
        </ContainerText>
      </CustomForm>
    </>
  )
}

Header.propTypes = {
  navigate: PropTypes.any.isRequired,
}

export default Header
