import React from 'react'
import { Tab, Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import { MainSegment } from '../../components/Sengment/MainSegment'
import MenuItemTab from '../../components/Tab/MenuImageTab'
import AllNotificationsStarreds from './AllNotificationsStarreds/AllNotificationsStarreds.jsx'


const CustomTab = styled(Tab)`
  .active.item {
    color: #22C1D0 !important;
    border-color: #22C1D0 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-bottom-width: 4px !important;
    margin: 0 0 -4px !important;
  }
  .active.item div svg path {
    fill: #22C1D0 !important;
  }
  .ui.menu {
    margin: 25px 20px 0 20px !important;
    border-bottom: 4px solid #E4E4E4 !important;
  }
  .ui.secondary.pointing.menu .item {
    height: 50px !important;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

@media only screen and (min-width: 960px) and (max-width: 1024px) {
  .ui.secondary.pointing.menu .item {
    font-size: 14px;
  }
}
`

const CustomMenuItem = styled(Menu.Item)`
  display: flex !important;
  flex: 1 !important;
  justify-content: center;
`

const Content = styled(Tab.Pane)`
  border: none !important;
  padding: 0 !important;
`
const Starred = (props) => {

  const panes = [
    {
      menuItem: (
        <CustomMenuItem key='all'>
          <MenuItemTab icon='all' title='Todas' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <AllNotificationsStarreds
          tabFilter='all'
          navigate={props.navigate}
        />
      </Content>)
    },
    {
      menuItem: (
        <CustomMenuItem key='readConfirmation'>
          <MenuItemTab icon='readConfirmation' title='Confirmación de lectura' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <AllNotificationsStarreds
          tabFilter='readConfirmation'
          navigate={props.navigate}
        />
      </Content>)
    },
    {
      menuItem: (
        <CustomMenuItem key='notificationSignature'>
          <MenuItemTab icon='notificationSignature' title='Firma de notificación' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <AllNotificationsStarreds
          tabFilter='notificationSignature'
          navigate={props.navigate}
        />
      </Content>)
    },
    {
      menuItem: (
        <CustomMenuItem key='docSignature'>
          <MenuItemTab icon='docSignature' title='Firma de documentos' />
        </CustomMenuItem>
      ),
      render: () => (<Content>
        <AllNotificationsStarreds
          tabFilter='docSignature'
          navigate={props.navigate}
        />
      </Content>)
    },
  ]
  return (
    <MainSegment>
      <CustomTab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
    </MainSegment>
  )
}

export default Starred
