import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionFilter, actionStarredFilter } from '../../actions/filterDetailActions'
import ButtonIcon from '../../components/Button/IconButton'
import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import { ReactComponent as Reviewed } from '../../assets/icons/reviewed.svg'
import './main.css'
import { chageStateLoadNotificationsList, chageStateLoadCitizensList } from '../../actions/writerActions'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px
`

const PopUpFilterDetailUserDocument = (props) => {

  const stateSearch = useSelector(state => state.filterDetailReducer.searchAdvanced.notificationState)
  const statusLoadNotifications = useSelector(state => state.writerReducer.loadNotifications)

  const [show, setShow] = useState(false)
  const [notificationState, setNotificationState] = useState('')
  const [starred, setStarred] = useState(false)

  const [changeAdvanced, setChangeAdvanced] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (notificationState !== 'STARRED') {
      if (!changeAdvanced) {
        dispatch(actionFilter(notificationState))
        setShow(false);
      } else {
        setChangeAdvanced(false);
      }
    }

  }, [notificationState]);

  useEffect(() => {
    if (notificationState === 'STARRED') {
      dispatch(actionStarredFilter(starred))
      setShow(false);
    }
  }, [notificationState, starred]);

  const toggle = () => {
    if (notificationState !== stateSearch) {
      setChangeAdvanced(true)
    }
    if (notificationState === "STARRED" && stateSearch === '') {
      setNotificationState("STARRED")
      setChangeAdvanced(false)
    } else {
      setNotificationState(stateSearch)
    }
    setShow(!show)
    if (statusLoadNotifications)
      dispatch(chageStateLoadNotificationsList(false))
    else
      dispatch(chageStateLoadCitizensList(false))
  }

  const changeStatus = (status) => {
    if (!props.loader) {
      if (status === 'STARRED') {
        setNotificationState(status)
        setStarred(true)
      } else {
        setNotificationState(status)
        setStarred(false)
      }
    }
  }


  return (
    <Divider>
      <PopupFilter
        position='bottom left'
        on='click'
        pinned
        open={show}
        onClose={toggle}
        trigger={
          <ButtonIcon onClick={toggle}>
            <Texto>
              Filtrar
            </Texto>
            <Filter style={props.filterSmall ? { width: '20px' } : {}} />
          </ButtonIcon>
        }
      >
        {
          props.gender === 'Male'
            ?
            <>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('READ')}
                style={{ "paddingTop": "14px", "paddingBottom": "22px" }}
              >
                {
                  notificationState === 'READ'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'READ'
                    ? <Text>Leído</Text>
                    : 'Leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('UNREAD')}
                style={{ "paddingTop": "14px", "paddingBottom": "22px" }}
              >
                {
                  notificationState === 'UNREAD'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'UNREAD'
                    ? <Text>No leído</Text>
                    : 'No leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('PENDING')}
              >
                {
                  notificationState === 'PENDING'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'PENDING'
                    ? <Text>Pendiente</Text>
                    : 'Pendiente'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('SIGNED')}
              >
                {
                  notificationState === 'SIGNED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'SIGNED'
                    ? <Text>Completado</Text>
                    : 'Completado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('REJECTED')}
              >
                {
                  notificationState === 'REJECTED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'REJECTED'
                    ? <Text>Rechazado</Text>
                    : 'Rechazado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('ERROR')}
              >
                {
                  notificationState === 'ERROR'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'ERROR'
                    ? <Text>Error</Text>
                    : 'Error'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('STARRED')}
              >
                {
                  notificationState === 'STARRED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'STARRED'
                    ? <Text>Destacado</Text>
                    : 'Destacado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('REQUIRED_SIGNATURE')}
              >
                {
                  notificationState === 'REQUIRED_SIGNATURE'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'REQUIRED_SIGNATURE'
                    ? <Text>Con solicitud de firma</Text>
                    : 'Con solicitud de firma'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('')}
                style={{ "paddingBottom": "14px", "paddingTop": "5px" }}
              >
                {
                  notificationState === ''
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== ''
                    ? <Text>Limpiar filtro</Text>
                    : 'Limpiar filtro'
                }
              </ButtonOption>
            </>
            :
            <>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('READ')}
                style={{ "paddingTop": "14px", "paddingBottom": "22px" }}
              >
                {
                  notificationState === 'READ'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'READ'
                    ? <Text>Leído</Text>
                    : 'Leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('UNREAD')}
                style={{ "paddingTop": "14px", "paddingBottom": "22px" }}
              >
                {
                  notificationState === 'UNREAD'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'UNREAD'
                    ? <Text>No leído</Text>
                    : 'No leído'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('PENDING')}
              >
                {
                  notificationState === 'PENDING'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'PENDING'
                    ? <Text>Pendiente</Text>
                    : 'Pendiente'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('SIGNED')}
              >
                {
                  notificationState === 'SIGNED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'SIGNED'
                    ? <Text>Completado</Text>
                    : 'Completado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('REJECTED')}
              >
                {
                  notificationState === 'REJECTED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'REJECTED'
                    ? <Text>Rechazado</Text>
                    : 'Rechazado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('ERROR')}
              >
                {
                  notificationState === 'ERROR'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'ERROR'
                    ? <Text>Error</Text>
                    : 'Error'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('STARRED')}
              >
                {
                  notificationState === 'STARRED'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'STARRED'
                    ? <Text>Destacado</Text>
                    : 'Destacado'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('REQUIRED_SIGNATURE')}
              >
                {
                  notificationState === 'REQUIRED_SIGNATURE'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'REQUIRED_SIGNATURE'
                    ? <Text>Con solicitud de firma</Text>
                    : 'Con solicitud de firma'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('NO_REQUIRED_SIGNATURE')}
              >
                {
                  notificationState === 'NO_REQUIRED_SIGNATURE'
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== 'NO_REQUIRED_SIGNATURE'
                    ? <Text>Sin solicitud de firma</Text>
                    : 'Sin solicitud de firma'
                }
              </ButtonOption>
              <ButtonOption
                className='buttonOptionHover'
                onClick={() => changeStatus('')}
                style={{ "paddingBottom": "14px", "paddingTop": "5px" }}
              >
                {
                  notificationState === ''
                  && <Reviewed className='iconReReviewed' />
                }
                {
                  notificationState !== ''
                    ? <Text>Limpiar filtro</Text>
                    : 'Limpiar filtro'
                }
              </ButtonOption>
            </>
        }
      </PopupFilter>
    </Divider>
  )
}

export default PopUpFilterDetailUserDocument
