import React from 'react'
import styled from 'styled-components'
import { useDispatch} from 'react-redux'
import { openDateModal, changeStateSearchReportsModal } from '../../actions/modalActions'
import ModalFilterDetailDateAttachment from './SearchAttachment/AdvancedSearchAttachment/ModalFilterDetailDateAttachment'

const Button = styled.button`
  background: #22C1D0;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #FFFFFF;
  width:100%;
  height: 48px;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    border-radius: 4px;
    width: 100%;
    height: 40px;
  }
  @media only screen and (max-width: 740px) {
    font-size: 14px;
  }
`

const ButtonReports = styled.button`
  background: none;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  color: #22C1D0;
  width: 208px;
  height: 48px;
  font-family: Roboto;
  font-weight: Bold;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1600px) {
    width: 180px;
  }
  @media only screen and (max-width: 1200px) {
    width: 135px;
  }
  @media only screen and (max-width: 1024px) {
    border-radius: 4px;
    width: 100%;
    height: 40px;
  }
  @media only screen and (max-width: 740px) {
    font-size: 14px;
  }
`

const Text = styled.p`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const Text2 = styled.p`
  display: none;
  align-items: center;
  @media only screen and (max-width: 768px) {
    margin: 0 0 0em;
    display: flex;
  }
`
const button = {
  attachments: <ModalFilterDetailDateAttachment />,
}

const ButtonDetailAdvanceSearch = (props) => {

  const dispatch = useDispatch()
  
    return (
      <>
      {props.typeButton === 'reports'? 
      <ButtonReports onClick={()=>dispatch(changeStateSearchReportsModal())}>
      <Text2>
        Avanzada
      </Text2>
      <Text>
        Búsqueda avanzada
      </Text>
    </ButtonReports>
    
      :
      <Button onClick={()=>dispatch(openDateModal())}>
      <Text2>
        Avanzada
      </Text2>
      <Text>
        Búsqueda avanzada
      </Text>
    </Button>
    }
    {button[props.typeButton]}
      </>
    )
}

export default ButtonDetailAdvanceSearch
