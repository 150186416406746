import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Menu } from 'semantic-ui-react'
import styled from 'styled-components'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import MenuItemTab from '../../../components/Tab/MenuImageTab'
import AllUsers from './Users/AllUsers'
import AllLogs from './Logs/AllLogs'
import { setOriginalNumberOfEmployeees } from '../../../actions/clientActions'
import { actionSearchBar } from '../../../actions/filterDetailActions'
import { CollapsableDetailProvider } from './CollapsableDetailProvider'

const CustomTab = styled(Tab)`
  .active.item {
    color: #22C1D0 !important;
    border-color: #22C1D0 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-bottom-width: 4px !important;
    margin: 0 0 -4px !important;
  }
  .active.item div svg path {
    fill: #22C1D0 !important;
  }
  .ui.menu {
    display:grid;
    grid-row-gap: 2px;
    grid-template-columns: 1fr 1fr 1fr; 
    padding: 25px 20px 0 20px !important;
    border-bottom: 2px solid #E4E4E4 !important;
  }
  .ui.secondary.pointing.menu .item {
    height: 64px !important;
    font-family: 'Roboto';
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #133F4F;
  }

@media only screen and (min-width: 960px) and (max-width: 1024px) {
  .ui.secondary.pointing.menu .item {
    font-size: 14px;
  }
}
`

const CustomMenuItem = styled(Menu.Item)`
    display: flex !important;
    flex: 1 !important;
    justify-content: center;
    padding: 0 !important;
`

const Content = styled(Tab.Pane)`
  border: none !important;
  padding: 0 !important;
`

const TabDetailProviders = (props) => {
    const { tabFilter } = useSelector(state => state.filterReducer)

    const { employeesCount,originalEmployeesCount } = useSelector((state) => state.clientReducer);
    const dispatch = useDispatch();
  
    
    useEffect(() => {
      if (employeesCount > originalEmployeesCount ) {
        dispatch(setOriginalNumberOfEmployeees(employeesCount))
      }
      }, [employeesCount]);
    

      useEffect(() => {
        return () => {
          dispatch(setOriginalNumberOfEmployeees(-1));
          dispatch(actionSearchBar('', 'initialState'));
        };
      }, []);
      


    const panes =  props.isResponsive === false ?
    [
        {
            menuItem: (
                <CustomMenuItem key='users'>
                    <MenuItemTab icon='default' title='Usuarios' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                <AllUsers
                    tabFilter='users'
                    filterPosition={props.filterPosition}
                />
            </Content>)
        },
        {
          menuItem: (
              <CustomMenuItem key='Logs' >
                  <MenuItemTab icon='default' title='Seguimiento' />
              </CustomMenuItem>
          ),
          render: () => (<Content>
              {
                      <AllLogs
                          tabFilter='Logs'
                          filterPosition={props.filterPosition}
                      />
              }
          </Content>)
      }
    ]
    :

    //Responsive Panes
    [
        
        {
            menuItem: (
                <CustomMenuItem key='users'>
                    <MenuItemTab icon='default' title='Usuarios' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                <AllUsers
                    tabFilter='users'
                    filterPosition={props.filterPosition}
                />
            </Content>)
        },
        {
          menuItem: (
              <CustomMenuItem key='logs'>
                  <MenuItemTab icon='default' title='Seguimiento' />
              </CustomMenuItem>
          ),
          render: () => (<Content>
              <AllLogs
                  tabFilter='logs'
                  filterPosition={props.filterPosition}
              />
          </Content>)
      }
        
        
        
    ]

    return (
        <MainSegment>
             {props.isResponsive && <CollapsableDetailProvider collapsable={true}/>}
            <CustomTab
                menu={{ secondary: true, pointing: true }}
                panes={panes}
            />
        </MainSegment>
    )
}

export default TabDetailProviders