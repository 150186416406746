import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { closeDateModal } from '../../../actions/modalActions'
import { actionSearchAdvanced, inactiveViewMix, activeViewMix } from '../../../actions/filterActions'
import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import FormFilterUserDocumentsAndDocuments from './FormFilterUserDocumentsAndDocuments/FormFilterUserDocumentsAndDocuments'
import DateFilterUserDocumentsAndDocuments from './DateFilterUserDocumentsAndDocuments/DateFilterUserDocumentsAndDocuments'
import { ModalFilterSignatureSelection } from '../SearchAttachment/AdvancedSearchAttachment/ModalFilterSignatureSelection'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Texto = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
const TextoHide = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 0em;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
const TextoHide2 = styled.p`
  display: none;
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
const Texto2 = styled.p`
  color: #000000;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 0 0 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 15px;
`

const ButtonDeleteFilter = styled.button`
  color: #22C1D0;
  border: none;
  background: #FFFFFF;
  text-decoration: underline;
  padding-right: 64px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 10px;
    width: 47%;
  }
`
const ButtonStartFilter = styled.button`
  color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #22C1D0;
  background: #22C1D0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  height: 48px;
  width: 193px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-right: 2%;
    width: 47%;
  }
`
const ModalDate = styled.div`
  width:100%;
  padding-top: 0px !important;
  @media only screen and (max-width: 1024px) {
    width:100% !important;
    padding-left:0 !important;
}
`

const ModalSent = styled.div`
  width: 80% !important;
  max-width: 854px;
  min-height: 575px;
  height: auto; 
  position: relative;
  padding: 20px 27px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  margin-top: 0rem;
  margin-left: 10rem;
  align-items:center;
  @media only screen and (max-width: 1200px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100% !important;
    max-height: unset;
    height: -webkit-fill-available;
    margin: -16px 0 0 0 !important;
    
  }
`

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-center; 
  justify-content: center;
  align-items: center;
  z-index:4000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25.76px;
  right: 24px;
  border: none;
  background: none;
  cursor: pointer;
`;



const ModalFilterDateUserDocumentsAndDocuments = () => {

  const [state, setState] = useState({
          userDocument: true,
          document: false,
          notificationState: '',
          showWarning: false,
          date: {},
          selected:''
        })

  const dateModal = useSelector(state=> state.modalReducer.dateModal)
  const stateSearch = useSelector(state=> state.filterReducer.searchAdvanced.notificationState)
  const dateSearch = useSelector(state=> state.filterReducer.searchAdvanced.date)
  const filter = useSelector(state=> state.filterReducer)
  const modalState = useSelector(state=> state.modalReducer.dateModal)

  const dispatch =  useDispatch()




  useEffect(() => {
    setState((prevState)=>({...prevState, notificationState: stateSearch }))
  }, [stateSearch])

  const handleOnChange = (e, { value }) => {
    setState((prevState) => ({...prevState,notificationState: value}))
  }

  const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const toggleCheckbox = async (e) => {
    let name = e.target.id;
    if (name === 'userDocument') {
      if (state.userDocument && !state.document) {
        setState((prevState)=>({ ...prevState, showWarning: true }))
        await timeout(3000)
        setState((prevState)=>({...prevState, showWarning: false }))
      } else {
        setState((prevState)=>({
          ...prevState,
          [name]: !state[name],
          notificationState: ''
        }))
      }
    } else if (name === 'document') {
      if (state.document && !state.userDocument) {
        setState((prevState)=>({ ...prevState, showWarning: true }))
        await timeout(3000)
        setState((prevState)=>({ ...prevState, showWarning: false }))
      } else {
        setState((prevState)=>({
          ...prevState,
          [name]: !state[name],
          notificationState: ''
        }))
      }
    }
  }

  const reset = () => {
    setState((prevState)=>({
      ...prevState,
      userDocument: true,
      document: false,
      notificationState: '',
      date: {}
    }))
  }

  const setDateState = (date) => {
    setState((prevState)=>({...prevState, date: date }))
  }


  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "lastModification": -1 },
      'masAntiguo': { "lastModification": 1 },
      'A-Z': { "nameElement": 1 },
      'Z-A': { "nameElement": -1 }
    }
    return options[order]
  }


  const signedFilterTranslator = (order) => {
    const options = {
      'REQUIRED_SIGNATURE': true,
      'NO_REQUIRED_SIGNATURE': false
    }
    return options[order]
  }

  const makeBodyRequest = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      searchAdvanced: {
        date: state.date,
        notificationState: state.notificationState,
        typeSearch: state.userDocument === true && state.document === false ? '1' : state.userDocument === false && state.document === true ? '2' : state.userDocument === true && state.document === true ? '3' : ''
      }
    }
    if(body.searchAdvanced.typeSearch === '2' && state.selected !== ''){
      body.searchAdvanced.signDocumentFilter = signedFilterTranslator(state.selected)
    }
    dispatch(actionSearchAdvanced(body))
    dispatch(closeDateModal())
  }

  const makeBodyRequestDeleteFilter = () => {
    const body = {
      pag: 1,
      sort: 'masReciente',
      searchAdvanced: {
        date: {
          startDate: "",
          endDate: ""
        },
        notificationState: ''
      }
    } 
    setState((prevState) => ({...prevState, selected: ''}))
    dispatch(actionSearchAdvanced(body))
    dispatch(closeDateModal())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    makeBodyRequest()
    dispatch(activeViewMix())
  }

  const handleDeleteFilter = (e) => {
    e.preventDefault()
    makeBodyRequestDeleteFilter()
    reset()
    if (!!filter.searchBar[0] === false) {
      dispatch(inactiveViewMix())
    }
  }

  const validClose = () => {
    setState((prevState)=> ({
      ...prevState,
      notificationState: stateSearch
    }))
    return dispatch(closeDateModal())
  }

  const setSelected = (option) =>{
    setState((prevState) => ({
      ...prevState,
      selected: option
    }))
  }
  
  return (
    <>
        {modalState &&
          <Overlay>
            <ModalSent >
              <ModalDate>
                <Divider>
                  <TextoHide>Búsqueda avanzada</TextoHide>
                  <Texto>Mostrar</Texto>
                  <CloseButton onClick={validClose}>
                    <Close />
                  </CloseButton>
                </Divider>
                <TextoHide2>Mostrar</TextoHide2>
                <div >
                  <FormFilterUserDocumentsAndDocuments
                    state={state}
                    handleOnChange={handleOnChange}
                    handleSubmit={handleSubmit}
                    toggleCheckbox={toggleCheckbox}
                  />
                  {!state.userDocument && state.document &&
                  <>
                    <Texto>Ver documentos</Texto>
                    <ModalFilterSignatureSelection selected={state.selected} setSelected={setSelected}/>
                  </>

                  }
                  <Texto2>Período de tiempo</Texto2>
                </div>
                <DateFilterUserDocumentsAndDocuments setDateState={setDateState} />
              </ModalDate>
              <Divider2>
                <ButtonDeleteFilter onClick={handleDeleteFilter}>
                  Borrar filtros
                </ButtonDeleteFilter>
                <ButtonStartFilter onClick={handleSubmit}>
                  Aplicar filtro
                </ButtonStartFilter>
              </Divider2>
            </ModalSent>
          </Overlay>
        }
      </>
  )
}

export default (ModalFilterDateUserDocumentsAndDocuments) 