import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import GeneralTable from '../../../components/Table/GeneralTable'
import RowTableUSers from '../RowTableUsers'
import { getUsers } from '../../../actions/usersActions'
import { activePagination } from '../../../actions/filterActions'

const Users = ({ navigate, noHover, setPaginationLoader }) => {
  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(true)
  const columnData = [
    { name: "Nombre", width: 5, arrow: false },
    { name: "Documento", width: 2, arrow: false },
    { name: "Email", width: 3, arrow: false },
    { name: "Rol", width: 2, arrow: false },
    { name: "Activo", width: 3, arrow: false },
  ]

  const users = useSelector(state => state.userReducer.listUsers)
  const filterReducer = useSelector(state => state.filterReducer)
  const dispatch = useDispatch()

  const makeUsersRequest = useCallback((filter) => {
    const body = {
      count: filter.count,
      pag: filter.pag,
      sort: orderTranslator(filter.sort),
      textToSearch: filter.searchBar[0],
    }

    setLoader(true)
    dispatch(getUsers(body)).then(() => {
      dispatch(activePagination(false))
      setInit(false)
      setLoader(false)
      setPaginationLoader(false)
    })
  }, [dispatch])

  useEffect(() => {
    makeUsersRequest(filterReducer)
  }, [])

  useEffect(() =>{
    if(!init){
      makeUsersRequest(filterReducer)
      setInit(true)
      setLoader(true)
    }
  },[filterReducer])

  const orderTranslator = (order) => {
    const options = {
      'masReciente': { "_id": -1 },
      'masAntiguo': { "_id": 1 },
      'A-Z': { "firstName": 1 },
      'Z-A': { "firstName": -1 },
    }
    return options[order]
  }

  return (
    <GeneralTable
      list={users}
      navigate={navigate}
      headerTable={columnData}
      rowComponent={RowTableUSers}
      loader={loader}
      typeList={filterReducer.isSearch ? 'default' : 'users'}
      listHeight={'users'}
      noHover={noHover}
    />
  )
}

Users.propTypes = {
  navigate: PropTypes.any.isRequired,
  noHover: PropTypes.bool,
}

export default Users