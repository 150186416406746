import React from 'react'
import PropTypes from 'prop-types'
import { Segment, Table, TableHeaderCell } from 'semantic-ui-react'
import styled from 'styled-components'
import { CustomComponent } from '../CustomComponent'
import { Dimmer, Loader } from 'semantic-ui-react'
import { ReactComponent as NotFoundNotification } from '../../assets/icons/sent/not_found.svg'
import { ReactComponent as NotFoundClient } from '../../assets/icons/clients/NotFoundClients.svg'
import { ReactComponent as ArrowFilter } from '../../assets/icons/clients/arrow-filter.svg'
import { TableHead } from '@mui/material'

const CustomTable = styled(Table)`
  width: 100% !important;
  float: right !important;
  margin: 0 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: unset !important;
  padding-left: 25px !important;
  overflow: scroll;
`

const TableBody = styled(Table.Body)`
  padding: 0 20px 0 20px !important;
`

const NotFoundCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(54px * 10);

  &:hover {
    border: none !important;
    box-shadow: unset !important;
  }
`
const Message = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-bottom: 15px;
`

const MessageClients = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #133F4F;
  margin-top: 25px;
  margin-bottom: 15px;
`

const HeaderCell = styled(TableHeaderCell)`
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 1px !important;
  padding: 10px !important;
  color: #133F4F !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000 !important;
`

const Arrow = styled(ArrowFilter)`
  margin-left: 5px;
  width: 10px
`
const ContainerScroll = styled.div`
  width: 100%;
  height: 66vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const CustomHeadTable = styled(Table)`
  position: relative;
  width: 100% !important;
  padding-left: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #000000 !important;
  margin: 0 !important;
  border: 0 !important;
  border-radius: 0 !important;
`

const GeneralGrid = (props) => {

  const listData = () => {
    const { list, loader } = props
    if (!list.length && !loader) {
      switch (props.typeList) {
        case "notifications":
          return <NotFoundCell>
            <Message>¡No existen notificaciones enviadas!</Message>
            <NotFoundNotification />
          </NotFoundCell>

        case "clients":
          return <NotFoundCell>
            <NotFoundClient />
            <MessageClients>¡Aún no hay entidades creadas!</MessageClients>
          </NotFoundCell>

        case "providers":
          return <NotFoundCell>
            <NotFoundClient />
            <MessageClients>¡Aún no hay operadores creados!</MessageClients>
          </NotFoundCell>

        case "users":
          return <NotFoundCell>
            <NotFoundClient />
            <MessageClients>¡Aún no hay usuarios creados!</MessageClients>
          </NotFoundCell>

        case "convetions":
          return <NotFoundCell>
            <NotFoundClient />
            <MessageClients>¡Aún no hay vinculaciones creadas!</MessageClients>
          </NotFoundCell>

        case "bags":
          return <NotFoundCell>
            <NotFoundClient />
            <MessageClients>¡Aún no hay bolsas creadas!</MessageClients>
          </NotFoundCell>

        default:
          return <NotFoundCell>
            <Message>¡No existen coincidencias para esta búsqueda!</Message>
            <NotFoundNotification />
          </NotFoundCell>
      }
    } else {
      let letterBefore = ''
      let initial = false
      return list
        .map((v, index) => {
          let letterCurrent = v.nameElement.substr(0, 1).toUpperCase()
          if (letterBefore !== letterCurrent) {
            if (letterBefore === '') {
              initial = true
            } else {
              initial = false
            }
            letterBefore = letterCurrent
          } else {
            letterCurrent = ''
          }
          return (
            <CustomComponent
              key={index}
              data={v}
              component={props.rowComponent}
              letter={letterCurrent}
              isInitial={initial}
              {...props}
            />)
        })

    }
  }

  const listColumn = () => {
    const { list, loader } = props


    if (!list.length && !loader) {
    } else {
      if (props.headerTable !== undefined && !!props.headerTable.length) {
        return (

          <CustomHeadTable>
            <TableHead>
              <Table.Row>
                {props.headerTable.map((column, index) => <HeaderCell key={index}
                  width={column.width}>{column.name}{column.arrow === true ?
                    <Arrow /> : ''}</HeaderCell>)}
              </Table.Row>
            </TableHead>
          </CustomHeadTable>

        )
      }
    }
  }
  return (
    <Segment style={{ padding: '0px', margin: '0px', boxShadow: 'unset', border: '0px' }}>

      <Dimmer.Dimmable active={props.loader.toString()}>
        <Dimmer active={props.loader} inverted>
          <Loader size='medium'>Cargando...</Loader>
        </Dimmer>
        {listColumn()}
        <ContainerScroll style={(props.typeList === 'listAttachments') ? { height: '64vh' } : {}}>
          <CustomTable>
            <TableBody>
              {listData()}
            </TableBody>
          </CustomTable>
        </ContainerScroll>
      </Dimmer.Dimmable>
    </Segment>
  )
}

GeneralGrid.propTypes = {
  list: PropTypes.array.isRequired,
  rowComponent: PropTypes.func.isRequired,
  loader: PropTypes.bool
}

export default GeneralGrid
