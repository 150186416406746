import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import './assets/css/base.css';
import './assets/css/colors.css';
import 'semantic-ui-css/semantic.min.css'
import Routes from './routes/routes';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<h1>Loading</h1>} persistor={persistor}>
      <Routes />
    </PersistGate>
  </Provider>
);

export default App;
