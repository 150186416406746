import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../../assets/icons/sent/logo.svg'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: #133F4F;
  color: #FFFF;
  height: 100px;
  margin: 0;
  padding: 0 40px;
  @media only screen and (max-height: 900px)  {
    height:12vh;
  }
  @media only screen and (max-width: 768px)  {
    height:50px;
    padding: 0px 15px;
  }
`


const Navbar = () => {
  return (
    <Container>
      <Logo />
    </Container>
  )
}

export default Navbar