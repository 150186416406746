import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionUpdateTabFilter } from '../../../../actions/filterDetailActions'
import { SearchBarDetailGenericButtonWithExport } from '../../../../components/Search/SearchBarGeneric'
import Logs from './Logs'
import { createReportService, getBase64ReportService } from '../../../../interceptors/services/reports.service'

const AllConvetions = (props) => {

    const dispatch = useDispatch()

    const [searchBarState, setSearchBarState] = useState("");
    const [popUpOrderState, setPopUpOrderState] = useState("");
    const agreementSerialID = sessionStorage.getItem('idAgreement')
    const agreementSerialIDS = useSelector(state => state.agreementReducer.detailAgreement.id)
    const { action } = useSelector(state => state.filterDetailReducer.logsFilter)


    const [loaderExport, setLoaderExport] = useState(false)
    const [createReport, setCreateReport] = useState(false)
    const [responseBase64Report, setResponseBase64Report] = useState()
    const { logsList, logsCount } = useSelector(state => state.agreementReducer)

    useEffect(() => {
        dispatch(actionUpdateTabFilter('Logs'))
    }, [dispatch])

    // useEffect(() => {
    //     if(!!createReport.success){
    //     const body = {
    //         reportSerialID: createReport.reportSerialID
    //     }
    //       fetchGetBase64Report(body)
    //     }else{
    //         setLoaderExport(false)
    //     }
    // }, [createReport])

    useEffect(() => {
        if (!!loaderExport) {
            downloadReport(responseBase64Report.base64, createReport.fileName)
            setLoaderExport(false)
        }
    }, [responseBase64Report])
    
    const actionTranslator = (action) => {
        if (action !== '') {
            return [action]
        }
        return []
    }

    const handleClickExport = () => {
        setLoaderExport(true)
        const body = {
            reportType: "LOGS_AGREEMENTS",
            action: actionTranslator(action),
            agreementSerialID: agreementSerialIDS
        }
        fetchCreateExport(body);
    }

    const fetchCreateExport = async (body) => {
        await createReportService(body).then((report) => {
            if (!!report)
                getBase64ReportService({ reportSerialID: report.reportSerialID }).then((base64) => {
                    if (!!base64) {
                        downloadReport(base64.base64, report.fileName)
                        setLoaderExport(false)
                    }
                })
        })
        // try {
        //     const data = await createReportService(body);
        //     setCreateReport(data);
        // } catch (error) {
        //     console.error('Error en createReportService:', error);
        //     setLoaderExport(false)
        // }
    }

    const fetchGetBase64Report = async (body) => {
        const data = await getBase64ReportService(body)
        setResponseBase64Report(data)
    }

    const downloadReport = (base64, fileName) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = base64;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <>
            <SearchBarDetailGenericButtonWithExport showButton={false} textPlaceHolder={'Buscar usuario'} popUpOrderState={popUpOrderState}
                searchBarState={searchBarState} showAZ={false} showCleanFilter={true} options={'logs'} filterAgreements={false} 
                agreementsLogs={true} filterPosition={props.filterPosition} handleClickExport={handleClickExport} 
                loaderExport={loaderExport} disabledExportButton={!logsCount > 0}/>
            <Logs agreementSerialID={agreementSerialIDS} generalLoader={props.generalLoader}/>
        </>
    )
}

AllConvetions.propTypes = {
}

export default AllConvetions 