import React, { useState} from 'react'
import { Table, } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Cell, CellOptions} from '../../components/Table/CellComponent'
import { useDispatch } from 'react-redux'
import { getUserEdit} from '../../actions/usersActions'
import { refreshStateModule, warningFormModalUserandEmployes } from '../../actions/modalActions'
import { useNavigate } from 'react-router-dom-v5-compat'

const Row = styled(Table.Row)`
  /*cursor: pointer !important;*/
  overflow-x: hidden;
  overflow: hidden;
  height: 50px !important;
  max-height: 50px!important;
  @media only screen and (min-width: 100px) and (max-width: 768px) {
    height:unset;
    display: flex !important;
    flex-direction: column;
    border-bottom: 1px solid #B2B2B2 !important;
    padding: 0.5em 0em!important;
    width: 100%;
    box-sizing: content-box;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px 0px;
    background: rgb(255, 255, 255) !important;
    border: 1px solid rgb(178, 178, 178) !important;
    opacity: 1 !important;
  }
`

const RowTableUSers = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleChangeActive = () => {
    sessionStorage.setItem("idUser", props.data.id)
    const idUser = sessionStorage.getItem("idUser") 
    dispatch(getUserEdit({"idUser":idUser}))
    dispatch(warningFormModalUserandEmployes({ type: 'warningUpdateUserActive' }))
  }

  const handleChangeInactive = () => {
    sessionStorage.setItem("idUser", props.data.id)
    const idUser = sessionStorage.getItem("idUser")
    dispatch(getUserEdit({"idUser":idUser}))
    dispatch(warningFormModalUserandEmployes({ type: 'warningUpdateUserInactive' }))
  }

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(refreshStateModule())
    sessionStorage.setItem("idUser", props.data.id)
    navigate('/editUsers')
  }

  return (
    <Row onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      <Cell width={5} columnName={props.headerTable[0].name} text={props.data.firstName + " " + props.data.lastName}/>
      <Cell width={2} columnName={props.headerTable[1].name} text={props.data.document}/>
      <Cell width={3} columnName={props.headerTable[2].name} text={props.data.email}/>
      <Cell width={2} columnName={props.headerTable[3].name} text={props.data.role}/>
      <CellOptions isVisible={isVisible} columnName={props.headerTable[4].name} clickEdit={handleClick}
       active={props.data.status} handleChangeActive={handleChangeActive} handleChangeInactive={handleChangeInactive} customMargin={true}/>
    </Row>
  )
}

RowTableUSers.propTypes = {
  data: PropTypes.object.isRequired
}

export default RowTableUSers