import React from "react"
import PropTypes from "prop-types"
import './input-from-entities.css'
import { useDispatch } from "react-redux"
import { activeFormClient } from "../../actions/clientActions"

const InputDV = ({ ...props }) => {
    const dispatch = useDispatch()
    const { placeholder, type, id, onChange, value, label, validator, validateOptions, required, disabled } = props

    let errorValidator = null;
    if (validator && validateOptions) errorValidator = validator.message('', value, validateOptions);

    const validator2 = (value) => {
        if (value !== "") {
            dispatch(activeFormClient())
        }
    }

    return (
        <div>
            <label className={errorValidator ? 'labelInputFormEntitiesError' : 'labelInputFormEntities'}>
                <input
                    id={id}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={() => validator2(value)}
                    onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                    required={!!required}
                    className={'inputFormEntitiesDV'}
                    disabled={true}
                />
                {
                    errorValidator && value === ''
                    &&
                    <>
                        <span className={errorValidator && value === '' && 'spanLabelInputDVTopError'}>{label}</span>
                    </>
                }
                {
                    errorValidator === undefined && value !== ''
                    &&
                    <>
                        <span className={'spanLabelInputDVTop'}>{label}</span>
                    </>
                }
                {
                    errorValidator === undefined && value === ''
                    &&
                    <>
                        <span className={'spanLabelInputDV'}>{label}</span>
                    </>
                }
                {
                    errorValidator && value !== ''
                    &&
                    <>
                        <span className={'spanLabelInputDVTopError'}>{label}</span>
                    </>
                }
            </label>
        </div>
    )
}


InputDV.propTypes = {
    labelProps: PropTypes.object,
    id: PropTypes.string,
}
export default InputDV