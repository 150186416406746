import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import SimpleReactValidator from 'simple-react-validator'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import { ReactComponent as Pdf } from '../../assets/icons/pdf.svg'
import { changeAttachedDocSign, changeWriteNotificationForm, changeHeaderCheckbox } from '../../actions/writerActions'
import { CloseSvgIcon } from "../IconSVG/CloseSvgIcon";
import _ from "lodash";
import PropTypes from "prop-types";
import '../CheckboxForm/signature-checkbox.css'
import { Progress } from 'semantic-ui-react'

const CloseIcon = styled(CloseSvgIcon)`
  width: 100%;
  height: 100%;
  color: #133F4F;
  padding-top: 3px;
  padding-left: 3px;
`
const CloseButton = styled.div`
  float: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
  width: 20px;
  height: 20px;

  @media screen and (max-width:1500px){
    visibility:visible;
}
`

const Divider = styled.div`
  background: #FFFFFF;
  border: 1px solid #B2B2B2;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  margin-bottom: 5px;

  &:hover ${CloseButton} {
    visibility: visible;
  }

  width: 100%;
  ${props => props.fileheight &&
    css`
            height: ${props.fileheight};
          `}
  height: 40px;
`
const Divider2 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`
const DividerSvg = styled.div`
  float: left;
  width: 15px;
  height: 15px;
`

const Text = styled.div`
  float: left;
  max-width: 25vw;
  width: auto;
  color: #133F4F;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 1% 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
`
const TextMuted = styled.div`
  float: left;
  max-width: 25vw;
  width: 60%;
  color: #899FA7;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 0 0 1% 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
`
const Text2 = styled.div`
  float: left;
  height: 100%;
  width: 50%
  font-size: 14px;
  padding-left: 2%;
  overflow: hidden;
  color:#C3132E;
`
const ProgressBar = styled(Progress)`
  width: 20%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5rem !important;
  & > .bar {
    background-color: #009BDD !important;
  }
`

const TextLongName = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #C3132E;
  margin-left: auto;
  padding-left: 8px;
  padding-right: 10px;
  @media screen and (max-width:768px){
    margin-left: 0;
    padding-left: 2%;
}
`

const ChargingFileCard = (props) => {
/*
  constructor(props) {
    super(props)
    this.validator = new SimpleReactValidator(reactValidatorOptions)
  }*/
 const dispatch = useDispatch()
 const checkboxHeaderDisabled = useSelector(state => state.writerReducer.checkboxHeaderDisabled)

  useEffect(()=>{
    loadNotes(props.data.size)
  },[])

  const [state, setState] = useState({
    progress: 0
  })

  const fileHeight = (listSize) => (`${(100 / (listSize))}%`)


  const removeCustomFile = async (indexInfo) => {
    const realFiles = Object.keys(props.files).map((row) => {
      return props.files[row]
    })
    realFiles.splice(indexInfo, 1)
    let disable = false
    for (const file of realFiles) {
      if (file.sign) {
        disable = true
      }
    }
    if (checkboxHeaderDisabled !== disable)
      await dispatch(changeHeaderCheckbox(disable))
    const descriptionFile = realFiles.map((file) => {
      return {
        base64: file.base64,
        type: file.type,
        name: file.name,
        sign: file.sign,
        size: file.size,
        id: file.id
      }
    }
    )
    const filesObject = _.keyBy(descriptionFile, 'id')
    await dispatch(changeWriteNotificationForm('chargingFiles', filesObject))
  }

  const loadNotes = async (size) => {
    var progress;
    for (let i = 20; i <= 100; i = i + 20) {
      await new Promise((resolve, reject) => {
        setTimeout(function () { resolve(); }, size * 0.005);
      });
      if (props.filesLoaded) {
        i = 100
      }
      progress = state.progress;
      progress = i;
      setState(prevState => ({...prevState,  progress: progress }))
    }
  }

  const truncateFileName = (fileName) => {
    const maxLength = 40;
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    let truncatedLength = fileNameWithoutExtension.length;

    if (truncatedLength > maxLength) {
      truncatedLength -= 3;
    }

    let truncatedName = fileNameWithoutExtension.substring(0, maxLength);
    if (truncatedLength > maxLength) {
      const numberMatch = fileNameWithoutExtension.match(/\(\d+\)$/);
      if (numberMatch) {
        const numberLength = numberMatch[0].length;
        if (maxLength >= numberLength) {
          truncatedName = truncatedName.substring(0, maxLength) + '...' + numberMatch[0];
        } else {
          truncatedName += '...';
        }
      } else {
        truncatedName += '...';
      }
    }

    return truncatedName + '.pdf';
  };


    return (
      <Divider
        fileheight={fileHeight(Object.keys(props.files).length)}
      >
        <Divider2>
          <DividerSvg>
            <Pdf width='16px' height='16px' />
          </DividerSvg>


          {!props.errorLoadingFiles ? <TextMuted>{truncateFileName(props.data.name)}</TextMuted> : <Text>{truncateFileName(props.data.name)}</Text>}
          {!props.errorLoadingFiles && <ProgressBar percent={state.progress} active size='tiny' />}
          {
            (props.errorLoadingFiles && !props.data.isLongName) &&
            <Text2>Error al cargar este documento</Text2>

          }

          {!!props.data.isLongName &&

            <Text2>Error al cargar, nombre del documento máx. 120 caracteres.</Text2>}

        </Divider2>
        {state.progress === 100 && props.errorLoadingFiles &&
          <CloseButton
            onClick={() => props.deleteChargingFile(props.index)}
          >
            <CloseIcon />
          </CloseButton>
        }
      </Divider>
    )
}


export default ChargingFileCard
