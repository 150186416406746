import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import GeneralTable from '../../../../components/Table/GeneralTable'
import { RowTableConvetionsPrivate } from './RowTableConvetionsPrivate'
import RowTableConvetionsPublic from './RowTableConvetionsPublic'
import {actionClearFilter,activePagination } from '../../../../actions/filterDetailActions'
import { makeClientAgreementRequestV2 } from '../../../../actions/agreementActions'

const orderTranslator = (order) => {
  const options = {
    'masReciente': { "_id": -1 },
    'masAntiguo': { "_id": 1 },
    'A-Z': { "agreementCode": 1 },
    'Z-A': { "agreementCode": -1 }
  }
  return options[order]
};


const columnDataPrivate = [
  { name: "Cód. vinculación", width: 2, arrow: false },
  { name: "Operador", width: 2, arrow: false },
  { name: "Fecha inicio", width: 2, arrow: false },
  { name: "Fecha Vencimiento", width: 2, arrow: false },
  { name: "Notificaciones enviadas", width: 2, arrow: false },
  { name: "Notificaciones por consumir", width: 2, arrow: false },
  { name: "Estado", width: 2, arrow: false },
]


const columnDataPublic = [
  { name: "Cód. vinculación", width: 2, arrow: false },
  { name: "Operador", width: 2, arrow: false },
  { name: "Fecha inicio", width: 2, arrow: false },
  { name: "Fecha Vencimiento", width: 2, arrow: false },
  { name: "Notificaciones enviadas", width: 2, arrow: false },
  { name: "Estado", width: 2, arrow: false },
]

const Convetions = () => {

  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(true)
  const customEqual = (oldValue, newValue) => oldValue === newValue

  const filterReducer = useSelector(state => state.filterDetailReducer)
  const clientDetail = useSelector(state => state.clientReducer.detailClient, customEqual)
  const agreementListArray = useSelector(state => state.agreementReducer.agreementListArray)
  const userRole = useSelector(state => state.authReducer.user.roleName)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!init) {
      setInit(true)
      setLoader(true)
      makeAgreementsRequest(filterReducer)
    }
  }, [filterReducer])

  useEffect(() => {
    if (filterReducer.tabFilter !== 'Agreements') {
      setInit(false)
      dispatch(actionClearFilter())
    } else {
      makeAgreementsRequest(filterReducer)
    }
  }, [filterReducer.tabFilter])

  const makeAgreementsRequest = (filterReducer) => {
    const body = {
      clientSerialID: sessionStorage.getItem("id"),
      pag: filterReducer.pag,
      count: filterReducer.count || 20,
      sort: orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0],
      status: filterReducer.agreementFilter.agreementStatus,
    }
    dispatch(makeClientAgreementRequestV2(body)).then(() => {
      dispatch(activePagination(false))
      setInit(false)
      setLoader(false)
    })
  }

  const getHeaderTable = () => {
    if (clientDetail.definition === 'PRIVATE') {
      if (userRole !== 'Operador') {
        return columnDataPrivate;
      } else {
        // Eliminar el objeto con nombre 'Operador' del array
        return columnDataPrivate.filter(item => item.name !== 'Operador');
      }
    } else {
      if (userRole !== 'Operador') {
        return columnDataPublic;
      } else {
        // Eliminar el objeto con nombre 'Operador' del array
        return columnDataPublic.filter(item => item.name !== 'Operador');
      }
    }
  }
  return (
    <GeneralTable
      list={agreementListArray}
      typeList="convetions"
      headerTable={getHeaderTable()}
      rowComponent={clientDetail.definition === 'PRIVATE' ? RowTableConvetionsPrivate : RowTableConvetionsPublic}
      loader={loader}
      heigthHeader={'68px'}
      noWrap={false}
    />
  )
}

export default Convetions