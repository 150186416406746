import React from "react"
import { Button } from "semantic-ui-react"
import styled, { css } from "styled-components"

/**
 * PrimaryButton constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const CustomButton = styled(Button)`
  background-color: #22C1D0 !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 16px !important;
  ${props => !props.basic && css`color: white !important;`} ${props => !!props.login && css`
    box-shadow: 0 5px 3px #0000004D !important;
    border-radius: 11px !important;
  `}
  margin: 0 !important;
  opacity: 1 !important;
  @media only screen and (max-width: 1024px){
    /*width: 119px; */
  }
`

const PrimaryButton = ({ ...props }) => {
  const { txtBtn, onPress, type, fluid, loading, disable, basic, background, style, compact, className, sending } = props
  return (
    <CustomButton
      className={className}
      background={background}
      loading={sending? false:loading}
      disabled={disable}
      fluid={fluid}
      basic={basic}
      type={type === '' || type === undefined ? "submit" : type}
      onClick={onPress}
      style={style}
      compact={compact}
    >
      {!sending && txtBtn }
      {sending &&
        <div style={{ display: 'flex', gap: '5px',justifyContent: 'center', alignItems: 'center' }}>
          {txtBtn}
          <div className='loading-container-button'>
            <div class="lds-spinner-sendingWrite" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>

        </div>
      }
    </CustomButton>
  )
}

export default PrimaryButton
