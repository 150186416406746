import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UserDocumentsGrid from './viewGrid/AllUserDocuments/UserDocumentsGrid'
import UserDocumentsList from './viewList/AllUserDocuments/UserDocumentsList'
import ContainerUserDocumentSearch from '../../components/Search/ContainerUserDocumentSearch';
import { PDFViewer } from '../../components/viewPdf/PDFViewer';
import { CLEAR_ATTACHMENT_DETAIL } from '../../actions/actionsUtilities/types';
import { UseWindowDimensions } from '../../utils/UseWindowsDimentions'

const UserDocuments = (props) => {

  const { viewList } = useSelector(state => state.userDocumentReducer)
  const [paginationLoader, setPaginationLoader] = useState(true)
  const windowDimensions = UseWindowDimensions();
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch({type:CLEAR_ATTACHMENT_DETAIL})
  },[])
  return (
    <>
      <ContainerUserDocumentSearch showAZ={false} filterSmall={windowDimensions.width > 768 ? false : true} textPlaceHolder={'Número de identificación del destinatario o nombre de documento '} loader={paginationLoader} />
      <PDFViewer/>
      {viewList === true ? <UserDocumentsList  setPaginationLoader={setPaginationLoader}/> : <UserDocumentsGrid  setPaginationLoader={setPaginationLoader}/> }
    </>
  )
}

export default UserDocuments
