import React, { Component, useEffect, useState } from 'react'
import { MainSegment } from '../../components/Sengment/MainSegment'
import AllReports from './allReports/AllReports'
import ContainerSearchReports from '../../components/Search/SearchReports/ContainerSearchReports'
import { useDispatch } from 'react-redux'
import { RESET_SEARCHBAR } from '../../actions/actionsUtilities/types'

const Reports = (props) => {
  
  const dispatch =  useDispatch();

  useEffect(() => {
   
    return () => {
      dispatch({
        type:RESET_SEARCHBAR
      })
    }
  }, [])
  
  const [paginationLoader, setPaginationLoader] = useState(true)

    return (
      <MainSegment>
        <ContainerSearchReports showAdvancedSearch={true} loader={paginationLoader} />
        <AllReports setPaginationLoader={setPaginationLoader}/>
      </MainSegment>
    )
}

export default Reports
