import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'
import HeaderPdf from './HeaderPdf'
import {closePDFModal } from '../../../actions/modalActions'
import './PDF.css'
import { ReactComponent as InfoIconFiles } from '../../../assets/icons/write/infoFiles.svg'
import PrimaryButton from '../../../components/PrimaryButton'

const ModalForPDF = styled(Modal)`
margin-top:130px !important;
width:100%;
`
const ContentPdf = styled.iframe`
width:70%;
 height:100vh;
 @media only screen and (max-width: 1450px)   {
    width: 70% !important;
  }
  @media only screen and (max-width: 1280px)   {
    width: 90% !important;
  }
  @media only screen and (max-width: 600px)   {
    width: 98% !important;
  }
`

const Overlay = styled.div`
  width: 100vw !important;
  height: 100vh !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0px;
  left:0;
  z-index:5000;
  oveflow:hidden;
`

const ModalContainer = styled.div`
  max-height: 100vh;
  min-height: 100vh;
  height: 100vh;
  position: relative;
  background: trasparent;
  display: flex;
  align-items:center
`

const ModalElement = styled.div`
  width: 496px !important;
  height: auto !important;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  display:flex;
`

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  gap: 32px;
`
const ModalMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding: 0 ;
`
const ModalActions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`
const Texto = styled.p`
  color: #133F4F;
  font-family: Roboto;
  font-weight: 900;
  font-size: 20px;
  font-style: normal;
  line-height: 23.44px;
  text-align: center;
`


export const PDF = () => {

    const dispatch = useDispatch()
    const { pdfModal } = useSelector(state => state.modalReducer)
    const { dataViewPdf } = useSelector(state => state.sentReducer)
    const [errorFile,setErrorFile] = useState(false)

    const handleClick = () => {
        dispatch(closePDFModal())
    }

    const onClose = () => {
        dispatch(closePDFModal())
    }

    useEffect(()=>{
        setErrorFile(false)
        if(!!dataViewPdf.fileDecodedBase64){
            const pdfSize = getFileSizeInBytes(dataViewPdf.fileDecodedBase64)
            if(pdfSize>1468006) setErrorFile(true)
            else setErrorFile(false)
        }
    },[dataViewPdf])

    const getFileSizeInBytes = (base64String) => {
        const base64Url = base64String.split(',')[1];
        const decodedData = atob(base64Url);
        const bytes = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
            bytes[i] = decodedData.charCodeAt(i);
        }
        return bytes.length;
    };

    return (
        <>
            <ModalForPDF
                open={pdfModal}
                onClose={handleClick}
                className="pdf-modal"
            >
                {!dataViewPdf.fileDecodedBase64 || errorFile ?
                    <ModalForPDF.Description  >
                        <Overlay>
                            <HeaderPdf errorFile={errorFile}/>
                            <ModalContainer>
                                <ModalElement >
                                    <ModalContent >
                                        <InfoIconFiles />
                                        <Texto >¡Lo sentimos!</Texto>
                                        <ModalMessage>Ha ocurrido un error al visualizar el documento.</ModalMessage>
                                        <ModalActions>
                                            <PrimaryButton txtBtn={'Entendido'} onPress={onClose} />
                                        </ModalActions>
                                    </ModalContent>
                                </ModalElement>
                            </ModalContainer>
                        </Overlay>
                    </ModalForPDF.Description>
                    :
                    <>
                        <HeaderPdf/>
                        <ModalForPDF.Description  >
                            <center errorFile={errorFile}>
                                <ContentPdf className={'pdfView'} src={!!dataViewPdf.fileDecodedBase64 ?`${dataViewPdf.fileDecodedBase64}#view=FitH&toolbar=0&navpanes=0&scrollbar=0"`:'about:blank'} frameborder="0" width="" height="940px" />
                            </center>
                        </ModalForPDF.Description>
                    </>
                }
            </ModalForPDF>
        </>
    )
}