import React from 'react'
import {SearchBarGenericButtonType} from '../SearchBarGeneric'
import PopUpFilterClientsAndProviders from '../SearchClientsAndProviders/PopUpFilterClientsAndProviders';

export const ContainerSearchClientsAndProviders = (props) => {
  return (
    <SearchBarGenericButtonType 
    buttonType={props.buttonType} 
    text={props.text} 
    showButton={props.showButton} 
    showFilters= {props.showFilters}
    textPlaceHolder={props.textPlaceHolder} 
    showAZ={props.showAZ} 
    dataFilter={<PopUpFilterClientsAndProviders reportType={props.reportType} providerType={props.providerType}/>} 
    showOldestNewest={false} 
    showExportButton={props.showExportButton}
    disabledExportButton={props.disabledExportButton}
    customWidthDiv2={props.customWidthDiv2}
    showAdvancedSearch={props.showAdvancedSearch}
    loader={props.loader}
    />
  )
}

export default ContainerSearchClientsAndProviders
