import React from "react";
import styled from "styled-components";
import { ReactComponent as LeftArrowIcon } from "../../../assets/icons/sent/leftArrow.svg";
import { Checkbox, Form } from "semantic-ui-react";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { clearReportMassiveData, downloadReportMassive } from "../../../actions/seeNotificationAction";
import MainLabelWithQuantity from "../../../components/Label/MainLabelWithQuantity";
import { LabelMassiveStatus } from "../../../components/Label/LabelMassiveStatus";
import { useNavigate } from "react-router-dom-v5-compat";

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;
const Container = styled.div`
  margin: 30px 30px 10px 30px !important;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-height: 900px) {
    margin: 10px 30px 10px 30px !important;
  }
  @media only screen and (max-width: 768px) {
    margin: 10px 30px 10px 7px !important;
  }
`;
const Arrow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
`;
const Title = styled.p`
  color: #133f4f;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 16px 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 600px)  {
    8px
  }
`;
const DateTitle = styled.p`
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-right: 40px;
  @media only screen and (max-width: 768px) {
  display:none;
  }
`;

const DateTitle2 = styled.p`
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-right: 40px;
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    margin-inline: 20px;
    margin:10px 0 15px 7px !important;
  }
`;
const Divider2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  margin: 1.75%;
  gap:  24px;
  flex-wrap:wrap;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-left:unset;
    margin:8px 6.95px 12px 8px;
    gap:  8px;
  }
`;
const Divider3 = styled.div`
  width: 332px;
  min-height: 144px;
  background: #E35F000D;
  outline: 1px solid #e35f00;
  border-radius: 4px;
  padding: 16px;
  padding-inline: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 820px) {
    width: 48%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding:8px;
    min-height: 56px;
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 1880px) and (min-width: 1180px) {
    width: 22.7%;
  }
  @media only screen and (max-width: 1180px) and (min-width: 769px){
    width: 23%;
  }
`;
const Divider7 = styled.div`
  width: 332px;
  min-height: 144px;
  background: #E35F000D;
  outline: 1px solid #e35f00;
  border-radius: 4px;
  padding: 16px;
  padding-inline: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 820px) {
    width: 48%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding:8px;
    min-height: 76px;
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 1880px) and (min-width: 1180px) {
    width: 22.7%;
  }
  @media only screen and (max-width: 1180px) and (min-width: 769px){
    width: 23%;
  }
`;
const Divider4 = styled.div`

  width: 45%;
  min-height: 144px;
  border-radius: 4px;
  outline: 1px solid var(--CD-GRIS-A, #979E9F);
  background: #FFF; 
  padding: 16px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-height: 950px) {
    min-height: 100px;
  }
  @media only screen and (max-height: 750px) {
    min-height: 50px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding:16px;
    min-height: unset;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 1880px) and (min-width: 1180px){
    width: 50%;
    padding:16px;
    min-height: unset;
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 1180px) and (min-width: 769px){
    width: 45%;
  }
`;
const Divider5 = styled.div`
  display: flex;
  flexDirection: row; 
  gap: 0 16px; 
  flex-wrap: wrap; 
  @media only screen and (max-width: 1715px) {
    flex-wrap: wrap; 
  }
`;
const Divider6 = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 2%; 
  @media only screen and (max-width: 1715px) {
    // flex-direction: row; 
    // flex-wrap:wrap;
  }
`;
const TitleContent3 = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #e35f00;
  @media only screen and (max-width: 768px) {
    text-align:left;
    font-size: 14px;
    max-width: 178px;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: 1880px) and (min-width: 769px){
    white-space: wrap;
  }
`;
const TitleContent = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #e35f00;
  @media only screen and (max-width: 768px) {
    text-align:left;
    font-size: 14px;
    max-width: 178px;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: 1880px) and (min-width: 769px){
    white-space: wrap;
  }
`;
const TitleContent2 = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #e35f00;
  @media only screen and (max-width: 768px) {
    text-align:left;
    font-size: 14px;
    max-width: 178px;
    overflow-wrap: break-word;
  }
  @media only screen and (max-width: 1880px) and (min-width: 769px){
    white-space: wrap;
  }
`;
const TextContent = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #e35f00;
  margin: 8px 0 0 0;
  @media only screen and (max-width: 768px) {
    text-align:left;
  }
`;
const ButtonContent = styled.button`
  width: 195px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #22c1d0;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #22c1d0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 146px;
    height: 32px;
    margin:0;
    //padding-block: 8px;
    font-size: 14px;
    margin-right: 4px;
    padding: 8px 15px;
    text-align: left;
  }
  @media only screen and (max-width: 1180px) and (min-width: 769px){
    width: 100%;
  }
`;
const FormCheck = styled(Form)`
  margin-left: 25px;
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-left: 25px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
   margin-left: 1%;
 
  }
`;
const CustomCheckbox = styled(Checkbox)`
  & label,
  & + label {
    text-align: left !important;
    letter-spacing: 0 !important;
    color: #000000 !important;
    opacity: 1 !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 5px;
    cursor: default !important;
  }
  
  input:checked ~ .box:after, input:checked ~ label:after {
    color: #FFFFFF !important;
    background: #22C1D0 0 0 no-repeat padding-box !important;
    box-shadow: 0 2px 4px #0000001A !important;
    border-radius: 2px !important;
    border: 2px solid #22c1d0 !important;
    width: 18px;
    height: 18px;
    cursor: default !important;
  }

  & label:before {
    color: #133f4f !important;
    border: 2px solid #133f4f !important;
    border-radius: 2px !important;
  }

  input[disabled] ~ label {
    opacity: 0.3 !important;
    color: #000000 !important;
  }

  padding-right: 10px !important;

  .ui.checkbox .box:after,
  .ui.checkbox label:after {
    size: 12px;
  }
`;
const ContainerChip = styled.div`
  margin: 24px 22.5px !important;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  @media only screen and (max-height: 900px) {
    margin: 10px 30px 10px 30px !important;
  }
  @media only screen and (max-width: 768px) {
    margin: 10px 30px 10px 7px !important;
  }
`;

const TextChip = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0;

`;



const HearderMasive = ({ nameCampaing, dateCampaing, bouncedCount, isConfirmationRead, isSignNotification, isPushNotification, statusCount, status = '' }) => {
  const { INITIALIZED = 0, FINALIZED = 0, UNREAD = 0, READ = 0, PENDING = 0, REJECTED = 0,
    SIGNED = 0, BOUNCED = 0, RELEASE = 0, ERROR = 0 } = statusCount
  const formatDate = moment(dateCampaing).format("DD MMM YYYY, h:mm a");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const closeView = () => {
    navigate(-1);
    setTimeout(() => {
      dispatch(clearReportMassiveData())
    }, 200)
  };
  const citizenReportData = useSelector(
    (state) => state.sentReducer.citizenReportData
  );

  const { isReadyUrlBouncedNotifications } = citizenReportData
  const urlCitizenErrorFileName = citizenReportData.urlCitizenError ? citizenReportData.urlCitizenError.split('/').pop() : '';
  const urlBouncedNotificationsFileName = citizenReportData.urlBouncedNotifications ? citizenReportData.urlBouncedNotifications.split('/').pop() : '';
  const {errorsForm} = useSelector(state => state.errorReducer)

  return (
    <>
      <Divider>
        <Container>
          <Arrow>
            <LeftArrow onClick={closeView}>
              <LeftArrowIcon />
            </LeftArrow>
          </Arrow>
          <Title>Envío masivo - {nameCampaing} </Title>
        </Container>
        <DateTitle> {formatDate}</DateTitle>
      </Divider>
      <ContainerChip>
        <TextChip>
          Estado del envío masivo:
        </TextChip>
        <LabelMassiveStatus status={status} />
      </ContainerChip>
      <DateTitle2> {formatDate}</DateTitle2>
      <Divider2>
        <Divider3>
          <div>
            <TitleContent>Ciudadanos no cargados</TitleContent>
            <TextContent>
              {!!citizenReportData.totalCitizenVerifiedWithError && citizenReportData.totalCitizenVerifiedWithError !== 0 ?
                '(' + citizenReportData.totalCitizenVerifiedWithError + ' de ' + citizenReportData.totalCitizen + ')'
                :
                '(0)'
              }
            </TextContent>
          </div>
          {(!!citizenReportData.totalCitizenVerifiedWithError && citizenReportData.totalCitizenVerifiedWithError !== 0) && (
            <a href={citizenReportData.base64CitizenError} download={urlCitizenErrorFileName}>
              <ButtonContent>Descargar archivo</ButtonContent>
            </a>
          )}
        </Divider3>
        <Divider7>
          <div>
            <TitleContent3>Notificaciones con reportes erróneos</TitleContent3>
            <TextContent>
              {!!citizenReportData.totalBouncedNotificationsLogs ? '(' + citizenReportData.totalBouncedNotificationsLogs + ')' : '(0)'}
            </TextContent>
          </div>
          {status !== 'SEND_NOTIFICATION' &&(
          citizenReportData.totalBouncedNotificationsLogs?
             (citizenReportData.totalBouncedNotificationsLogs !== 0) && 
            isReadyUrlBouncedNotifications === true
              ?
              <a href={citizenReportData.base64LogsBouncedNotifications} download={urlBouncedNotificationsFileName}>
                <ButtonContent>Descargar archivo</ButtonContent>
              </a>
              :
              !errorsForm.success && errorsForm.message?.includes("La información no está disponible en este momento. Debe esperar a que el proceso de verificación de ciudadanos termine.") ?
              <></>
              :
              <ButtonContent style={{ cursor: 'default' }}>
                Preparando archivo...
              </ButtonContent>
              :
              <></>
        )}
        </Divider7>
        <Divider4>
          <TitleContent2 >Notificaciones por estado</TitleContent2>
          <Divider6 >
            <Divider5 >
              <MainLabelWithQuantity iconMargin={'-2px'} icon={1} quantity={UNREAD} />
              <MainLabelWithQuantity iconMargin={'-5px'} icon={2} quantity={READ} />
              <MainLabelWithQuantity icon={5} quantity={SIGNED} />
              <MainLabelWithQuantity icon={3} quantity={PENDING} />
              <MainLabelWithQuantity icon={4} quantity={REJECTED} />
              <MainLabelWithQuantity icon={9} quantity={RELEASE} />
              <MainLabelWithQuantity iconMargin={'-2px'} icon={8} quantity={ERROR} />
            </Divider5>
          </Divider6>
        </Divider4>
      </Divider2>
      <FormCheck>
        <CustomCheckbox
          label="Con firma de notificación"
          type="checkbox"
          checked={isSignNotification}
          id="reqSignature"
          disabled={!isSignNotification}
        />
        <CustomCheckbox
          label="Confirmación de lectura "
          type="checkbox"
          checked={isConfirmationRead}
          id="reqReadEvent"
          disabled={!isConfirmationRead}
        />
        <CustomCheckbox
          label="Notificación push "
          type="checkbox"
          checked={isPushNotification}
          id="reqPushNotification"
          disabled={!isPushNotification}
        />
      </FormCheck>
    </>
  );
};

export default HearderMasive;
