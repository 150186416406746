import React from 'react'
import { SearchBarGenericInitial } from '../SearchBarGeneric'
import PopUpFilterImproperClients from '../../Search/SearchImproperClients/PopUpFilterImproperClients';

export const ContainerSearchImproperClients = (props) => {
  return (
      
    <SearchBarGenericInitial showButton={false} showFilters= {props.showFilters} showAZ={props.showAZ} 
    showOldestNewest={props.showOldestNewest} textPlaceHolder={'Buscar'}
    popUpFilter={<PopUpFilterImproperClients/>} showPopUpFilter={props.showPopUpFilter} loader={props.loader}/>
  )
}

export default ContainerSearchImproperClients
