import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import '../components/HomeMenu/sideBar.css'
import { HomeMenu } from "../components/HomeMenu/HomeMenu"
import Login from "../views/auth/Login"
import LoginWithNumberDocument from '../views/auth/LoginWithNumberDocument'
import LoginWithCertificate from '../views/auth/LoginWithCertificate'
import ForgotPassword from '../views/auth/ForgotPassword'

import QRView from '../views/auth/QRView'
import ViewWithOutQr from '../views/auth/ViewWithOutQr'
import { SessionClose } from '../views/auth/SessionClose'
import { CompatRoute, CompatRouter } from 'react-router-dom-v5-compat'
import RequireAuth from './RequireAuth'

let history = createBrowserHistory()

/**
 * Browser Router (export a browser router with respective routes,
 * PrivateRoute middleware params(param1: Component, param2: 'accepted roles separated with colon eg. "Admin, Guest"')
 * can you send "" in param2 for accept all loggedIn users)
 *
 * @export class
 * @class Routes
 * @extends {React.Component}
 */
export const Routes = () => {

  return (
    <BrowserRouter>
        <CompatRouter history={history}>
          {
            // !Object.keys(this.props.user).length &&

            <Switch>
              <CompatRoute exact path="/" component={() => <LoginWithNumberDocument/>} />
              <CompatRoute path="/login-user" component={() => <Login />} />
              <CompatRoute exact path="/login" component={() => <LoginWithNumberDocument history={history} />} />
              <CompatRoute exact path="/login-certificate/:userId" component={() => <LoginWithCertificate />} />
              <CompatRoute exact path="/login-certificate/:userId/:key" component={() => <LoginWithCertificate />} />
              <CompatRoute exact path="/login-QR/:userId/:key" component={() => <QRView />} />
              <CompatRoute exact path="/login-QR/:userId" component={() => <QRView />} />
              <CompatRoute exact path="/login-QR2/:userId" component={() => <ViewWithOutQr />} />
              <CompatRoute exact path="/forgotPassword" component={() => <ForgotPassword />} />
              <CompatRoute path="/sessionExpired" component={() => <SessionClose />} />
              <CompatRoute
                path=""
                component={() =>
                  <RequireAuth redirectTo="/login-user">
                    <HomeMenu  history={history}/>
                  </RequireAuth>
                }
              />
              {/* <CompatRoute path="*" component={() => <Container><RoutesWithNotFound /></Container>} /> */}
            </Switch>
          }

          {/* {
            !!Object.keys(this.props.user).length &&
            <CompatRoute element = {
              <PrivateComponent element={HomeMenu} history={history} />
            }> 
            </CompatRoute>
          } */}
        </CompatRouter>
      </BrowserRouter>
  )
}



export default Routes
