import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContainerSearch from '../../components/Search/ContainerSearch'
import AllNotifications from './AllNotifications'

const Notifications = (props) => {

    const [paginationLoader, setPaginationLoader] = useState(true)
    const [state, setState] = useState({
      loader: true,
      init: false,
    })

  return (
    <>
      <ContainerSearch showAdvancedSearch={true} loader={paginationLoader} generalLoader={state.loader}/>
      <AllNotifications tabFilter={props.tabFilter} navigate={props.navigate} setPaginationLoader={setPaginationLoader} state={state} setState={setState}/>
    </>
  )
}

Notifications.propTypes = {
  navigate: PropTypes.any.isRequired,
}

export default Notifications
