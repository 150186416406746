import React from 'react'
import './popupAlertReport.css'
import { Popup } from 'semantic-ui-react'
import {ReactComponent as HelpIcon} from "../../../assets/icons/reports/help.svg"

export const PopupAlertReport = (props) => {
    const modalData = (type) => {
        const data = {
            warningIndividual : {
                message: 'El reporte será generado con las notificaciones enviadas en el rango de fecha seleccionado y con la siguiente información: Número de consecutivo, NIT, remitente, asunto de la notificación, fecha y hora de envío, cédula del destinatario y estado de la notificación.'
            },
            warningMassive : {
                message: 'El reporte será generado con las campañas enviadas en el rango de fecha seleccionado y con la siguiente información: Número de consecutivo, NIT, remitente, asunto de la notificación, fecha y hora de envío, número de destinatarios y nombre de campaña.'
            },
            warningspecificCampaign: {
                message: 'El reporte será generado con las notificaciones enviadas de una campaña específica en el rango de fecha seleccionado y con la siguiente información: Número de consecutivo, NIT, remitente, fecha y hora de envío, cédula destinatario y estado de la notificación.'
            }
        }
        return data[type]
    }

    const data = modalData(props.type)
  return (
    <Popup
        content={!!data && data.message}
        className='activeIcon'
        on='click'
        pinned
        basic={true}
        wide={'very'}
        trigger={<HelpIcon content='Button' className={props.initialDate === '' || props.finalDate === '' ? 'disabledIcon': props.active} />}
  />
  )
}