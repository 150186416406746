import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import SimpleReactValidator from 'simple-react-validator'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { reactValidatorOptions } from '../../helpers/simpleReactValidator'
import { Form, Progress } from 'semantic-ui-react'
import InputFormTextAndNumber from '../InputAnimatedGeneralForms/InputFormTextAndNumber'
import InputFormArea from '../InputAnimatedGeneralForms/InputTextArea'
import InputCheckbokForm from '../InputAnimatedGeneralForms/InputCheckboxForm'
import InputFormDate from '../InputAnimatedGeneralForms/InputDate'
import './index.css'
import { refreshStateModule, warningFormModal } from '../../actions/modalActions'
import { activeFormAgreement, postCreateAgreementV2, getProviderList2, getClientList2, getProviderList2V2 } from '../../actions/agreementActions'
import InputAttachButton from '../InputAnimatedGeneralForms/InputAttachButton'
import { fileToBase64Clean } from '../../helpers/filesLib'
import { ReactComponent as Ex } from '../../assets/icons/ex.svg'
import InputSelectAgreement from '../InputAnimatedGeneralForms/InputSelectAgreement'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useValidator from '../../helpers/simpleReactValidatorHook'

const Divider = styled.div`
  max-width: 496px !important;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    min-width: 350px;
    height: 100vh;
    padding: 0px 7px;
  }
`
const CustomForm = styled(Form)`
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    min-width: 330px;
    margin:0;
  }
`
const ContainerButtons = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  margin-bottom: 1rem;
  @media only screen and (max-width: 1200px) {
    gap: 16px;
  }
`
const Divider3 = styled.div`
  margin-bottom: 24px;
  @media only screen and (max-height: 800px) {
    margin-bottom: 14px;
  }
`
const Divider4 = styled.div`
    margin-bottom: 24px;
    @media only screen and (max-height: 800px) {
        margin-bottom: 14px;
    }
`
const ContainerFormDate = styled.div`
  display: flex;
  justify-content: space-between !important;
    @media only screen and (max-height: 800px) {
        margin-bottom: 13px;
    }
  @media only screen and (max-width: 700px) {
    display: block;
    margin-bottom: 23px;
  }
`
const ContainerPositionDateOne = styled.div`
  width: 48%;
  margin-bottom: 23px;
    @media only screen and (max-height: 800px) {
        margin-bottom: 13px;
    }
  @media only screen and (max-width: 700px) {
    width:100%;
    float:left;
  }
`
const ContainerPositionDateTwo = styled.div`
    width: 48%;
    @media only screen and (max-width: 700px) {
        width:100%;
        margin-top: 0px;
        float:right;
    }
`
const Container = styled.div`
  border: 1px solid #E35F00;
  background: rgba(227, 95, 0, 0.1);
  color: #E35F00;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1rem;
  padding: 10px 28px;
  text-align: center;
`
const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
`
const Title2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #133F4F;
  margin: 24px 0px !important;
  @media only screen and (max-height: 800px) {
    margin-bottom: 14px !important;
  }
`
const TextNameFile = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  margin: 0px !important;
`
const TextNameFile2 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px !important;
  color: #000000;
  font-size: 14px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
   margin: 0 0 0.25rem !important;
`
const Container2 = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #C3132E;
  padding: 16px;
  @media only screen and (max-width: 768px) {
    padding: 16px 0px;

  }
`
const ButtoCancel = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #22C1D0;
  text-align: center;
  width: 128px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #FFFFFF;
  margin-right: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:left;
    width:49%;
    margin:0;
  }
`
const ButtoSave = styled.button`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: center;
  width: 190px;
  height: 48px;
  border: 1px solid #22C1D0;
  border-radius: 4px;
  background: #22C1D0;
  margin-left: 8px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    float:right;
    width:49%;
    margin:0;
  }
`
const ProgressBar = styled(Progress)`
  width: 100%;
  height: 3px;
  border-radius: 0px !important;
  margin: 0 0 0.5em !important;
  & > .bar {
    background-color: #009BDD !important;
  }
`
const DividerAttachments = styled.div`
    display: flex;
    width: 100% ;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`

const DivFilesNames = styled.div`
    display: flex;
    flex-direction: column;
    
    @media (max-width: 1200px) { 
        width: calc(100% - 180px);
    }

    @media (max-width: 768px) {
        margin-top: 16px;
        width: 100%;
    }
`;
const initialState = {
    companyProviderID: '',
    companyClientID: '',
    startDate: '',
    endDate: '',
    agreementCode: '',
    subscriptionDate: '',
    tradeNumber: '',
    tradeDate: '',
    tradeDetails: '',
    tradeSupport: '',
    agreementsFiles: [],
    bagName: '',
    quantity: '',
    sendNotification: true,
    pushNotification: false,
    notificationReading: false,
    notificationSignature: false,
    documentSignature: false,
    providerList: [],
    clientList: [],
    progress: [0, 0, 0],
    providerDefinition: '',
    clientDefinition: '',
    isChangedState: false,
    errorFile: false,
    errorQuantity: false,
    maxQuantityAchieved: false,
    isVisible: false,
    errorProvider: false,
    errorClient: false,
    fileTypeError: false,
    repeatedFileError: false,
    messagesShown: false,
}


export const FormCreateAgreements = () => {
    const customEqual = (oldValue, newValue) => oldValue === newValue

    const [customState, setCustomState] = useState(initialState)
    const [validator, showValidationMessage, setFields] = useValidator(reactValidatorOptions)
    const history = useHistory()
    const dispatch = useDispatch()
    const [progress, setProgress] = useState([0, 0, 0,0, 0, 0,0, 0, 0,0])

    const { refreshModule } = useSelector(state => state.modalReducer)
    const { status } = useSelector(state => state.modalReducer.warningModal)
    const { savedAgreement, activeForm, providerList, clientList } = useSelector(state => state.agreementReducer, customEqual)

    useEffect(() => {
        dispatch(getProviderList2V2())
        dispatch(getClientList2())
    }, [])

    useEffect(() => {
        const dataProvider = providerList.map((x) => {
            return { text: x.name, key: x.companySerialID, value: x.companySerialID }
        })
        dataProvider.unshift({ text: '', key: '', value: '' })
        setCustomState((prevState) => ({ ...prevState, providerList: dataProvider }))
    }, [providerList])

    useEffect(() => {
        const dataClient = clientList.map((x) => {
            return { text: x.name, key: x.companySerialID, value: x.companySerialID }
        })
        dataClient.unshift({ text: '', key: '', value: '' })
        setCustomState((prevState) => ({ ...prevState, clientList: dataClient }))
    }, [clientList])

    useEffect(() => {
        if (activeForm === false && status === true) {
            dispatch(activeFormAgreement())
        }
    }, [activeForm, status])

    useEffect(() => {
        if (refreshModule === true) {
            history.goBack()
            dispatch(refreshStateModule())
        }
    }, [refreshModule])

    useEffect(() => {
        dispatch(activeFormAgreement())
        setCustomState((prevState) => ({ ...prevState, errorProvider: false }))
        providerList.map((x) => {
            if (x.companySerialID === customState.companyProviderID) {
                setCustomState((prevState) => ({ ...prevState, providerDefinition: x.providerDefinition }))
            }
        })
    }, [customState.companyProviderID])

    useEffect(() => {
        dispatch(activeFormAgreement())
        setCustomState((prevState) => ({ ...prevState, errorClient: false }))

        clientList.map((x) => {
            if (x.companySerialID === customState.companyClientID) {
                setCustomState((prevState) => ({ ...prevState, clientDefinition: x.providerDefinition }))

            }
        })
    }, [customState.companyClientID])

    useEffect(() => {
        if (!!customState.messagesShown) {
            validateErrors()
        }
    }, [customState.messagesShown])

    const validateErrors = () => {
        const elementError = document.querySelector('.inputDropdownFormEntitiesError, .labelInputFormEntitiesError, .labelInputFormDateError, .supportFilesError')
        if (elementError !== null) {
            if (elementError.className.includes('inputDropdownFormEntitiesError')) {
                elementError.firstChild.focus()
            } else {
                elementError.firstChild.focus()
            }
            setCustomState((prevState) => ({ ...prevState, messagesShown: false }))
        }
    }

    const handleOnChangeProviders = (event, { value }) => {
        let dataValue = value
        if (event.currentTarget.hasOwnProperty('name')) {
            dataValue = event.currentTarget.getAttribute('name')
        } else {
            if (value === undefined) dataValue = event.currentTarget.getAttribute('name')
        }
        setCustomState((prevState) => ({ ...prevState, companyProviderID: dataValue }))
        if (customState.companyProviderID !== '') setCustomState((prevState) => ({ ...prevState, errorProvider: false }))
    }

    const handleOnChangeClients = (event, { value }) => {
        let dataValue = value
        if (event.currentTarget.hasOwnProperty('name')) {
            dataValue = event.currentTarget.getAttribute('name')
        } else {
            if (value === undefined) dataValue = event.currentTarget.getAttribute('name')
        }
        setCustomState((prevState) => ({ ...prevState, companyClientID: dataValue }))
        if (customState.companyClientID !== '') setCustomState((prevState) => ({ ...prevState, errorClient: false }))
    }

    const onChange = (e) => {
        const { id } = e.target;
        setCustomState((prevState) => ({
            ...prevState, [id]: e.target.value,
            errorQuantity: id === 'quantity' ? false : customState.errorQuantity, // Reset errorQuantity only for 'quantity' field
        }));
        isValidFieldName(e.target.getAttribute('label')) ?
            validator.showMessageFor(e.target.getAttribute('label')) :
            validator.showMessageFor(id);
    }

    const isValidFieldName = (fieldName) => {
        return validator.fields.hasOwnProperty(fieldName);
    }

    const handleCancel = () => {
        validator.hideMessages()
        dispatch(warningFormModal({ type: 'warningLeaveAgreements' }))
    }

    const toggle = (e) => {
        let name = e.target.id;
        setCustomState((prevState) => ({ ...prevState, [name]: !customState[name] }))
    }

    const existFileInArray = (fileName) => {
        return customState.agreementsFiles.some(existingFile => existingFile.fileName === fileName);
    }

    const onChangeHandler = async (e) => {
        const file = e.target.files
        let fileInfo = {}
        if (file.length + customState.agreementsFiles.length <= 10) {
            if (file.length) {
                for (let i = 0; i < file.length; i++) {

                    const fileName = file[i].name;
                    let uniqueFileName = fileName;
                    let counter = 1;

                    while (existFileInArray(uniqueFileName)) {
                        const extensionIndex = fileName.lastIndexOf('.');
                        const name = fileName.substring(0, extensionIndex);
                        const extension = fileName.substring(extensionIndex);
                        uniqueFileName = `${name}(${counter})${extension}`;
                        counter++;
                    }

                    fileInfo = {
                        fileId: uuidv4(),
                        base64: await fileToBase64Clean(uniqueFileName, file[i]),
                        fileName: uniqueFileName,
                        fileExtension: file[i].type.split("/").pop(),
                    }

                    if (fileInfo.fileExtension === "pdf") {
                        setCustomState((prevState) => ({ ...prevState, agreementsFiles: [...prevState.agreementsFiles, fileInfo] }))
                        loadNotes(customState.agreementsFiles.length + i, file[i].size / 2500);

                    } else {
                        setCustomState((prevState) => ({ ...prevState, fileTypeError: true }))
                        setTimeout(() => {
                            setCustomState((prevState) => ({ ...prevState, fileTypeError: false }))
                        }, 3000);
                    }
                    setCustomState((prevState) => ({ ...prevState, errorFile: false }))
                }
            }
        } else {
            setCustomState((prevState) => ({ ...prevState, maxQuantityAchieved: true }))
            setTimeout(() => {
                setCustomState((prevState) => ({ ...prevState, maxQuantityAchieved: false }))
            }, 3000);
        }

    }

    const handleRemove = async (e) => {
        const files = customState.agreementsFiles
        files.splice(e.currentTarget.id, 1)
        let progressaux = progress
        let indexToDelete = 0
        for (let index = 0; index < progressaux.length; index++) {
            if (progressaux[index] ===100) {
                indexToDelete = index
            } 
        }

        progressaux[indexToDelete] = 0
        setCustomState((prevState) => ({ ...prevState, agreementsFiles: files }))
    }

    const loadNotes = async (number, size) => {
        for (let i = 10; i <= 100; i = i + 10) {
            await new Promise((resolve, reject) => {
                setTimeout(function () { resolve(); }, size);
            });
            setProgress((prevProgress) => {
                const newProgress = [...prevProgress];  
                newProgress[number] = i;
                return newProgress;  
            });
        }
    }

    const makeSaveNotificationReq = () => {
        const { companyProviderID, companyClientID, startDate, endDate, agreementCode, subscriptionDate, tradeNumber, tradeDate,
            tradeDetails, tradeSupport, agreementsFiles, bagName, quantity, sendNotification, pushNotification, notificationReading,
            notificationSignature, documentSignature } = customState
        let files = []
        for (let i = 0; i < agreementsFiles.length; i++) {
            files.push({
                "fileName": agreementsFiles[i].fileName,
                "base64": agreementsFiles[i].base64
            })
        }
        const body = {
            "companyProviderID": companyProviderID,
            "companyClientID": companyClientID,
            "startDate": new Date(startDate).toISOString(),
            "endDate": new Date(endDate).toISOString(),
            "agreementCode": agreementCode,
            "subscriptionDate": new Date(subscriptionDate).toISOString(),
            "tradeNumber": tradeNumber,
            "tradeDate": new Date(tradeDate).toISOString(),
            "tradeDetails": tradeDetails,
            "tradeSupport": tradeSupport,
            "agreementsFiles": files,
            "bagName": bagName,
            "quantity": customState.clientDefinition === 'PRIVATE' ? quantity : '',
            "sendNotification": sendNotification,
            "pushNotification": pushNotification,
            "notificationReading": notificationReading,
            "notificationSignature": notificationSignature,
            "documentSignature": documentSignature,
        }
        dispatch(postCreateAgreementV2(body))
    }

    const validateQuantity = () => {
        if (customState.clientDefinition === 'PRIVATE') {
            const NumberQuantity = Number(customState.quantity);
            return NumberQuantity > 0
        } else {
            return true;
        }
    }

    const setMessagesShown = (value) => {
        setCustomState((prevState) => ({ ...prevState, messagesShown: value }))
    }

    const handleSend = (e) => {
        e.preventDefault()
        if (validator.allValid() && customState.agreementsFiles.length !== 0 && customState.startDate < customState.endDate && validateQuantity()) {
            makeSaveNotificationReq()
        } else {
            showValidationMessage(true)
            if (customState.agreementsFiles.length === 0) {
                setCustomState((prevState) => ({ ...prevState, errorFile: true }))
            }
            if (customState.companyProviderID === '') {
                setCustomState((prevState) => ({ ...prevState, errorProvider: true }))
            }
            if (customState.companyClientID === '') {
                setCustomState((prevState) => ({ ...prevState, errorClient: true }))
            }
            if (!validateQuantity()) {
                setCustomState((prevState) => ({ ...prevState, errorQuantity: true }))
            }
            setCustomState((prevState) => ({ ...prevState, messagesShown: true }))
        }
    }

    const setIsVisible = (isVisible) => {
        setCustomState((prevState) => ({ ...prevState, isVisible: isVisible }))
    }

    const onBlurAgreementCode = () => {
        return checkAgreementFormat(customState.agreementCode);
    }

    const checkAgreementFormat = (value) => {
        const pattern = /^[A-Za-z]-\d{2,13}$/; // Expresion regular para el formato
        return pattern.test(value);
    }

    const truncateFileName = (fileName) => {
        const maxLength = 40;
        const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
        let truncatedLength = fileNameWithoutExtension.length;

        if (truncatedLength > maxLength) {
            truncatedLength -= 3;
        }

        let truncatedName = fileNameWithoutExtension.substring(0, maxLength);
        if (truncatedLength > maxLength) {
            const numberMatch = fileNameWithoutExtension.match(/\(\d+\)$/);
            if (numberMatch) {
                const numberLength = numberMatch[0].length;
                if (maxLength >= numberLength) {
                    truncatedName = truncatedName.substring(0, maxLength) + '...' + numberMatch[0];
                } else {
                    truncatedName += '...';
                }
            } else {
                truncatedName += '...';
            }
        }

        return truncatedName;
    };

    return (
        <Divider>
            <CustomForm>
                <Title>Datos de vinculación</Title>
                <Divider3 >
                    <InputSelectAgreement
                        data={customState.providerList}
                        value={customState.companyProviderID}
                        validator={validator}
                        errorProvider={customState.errorProvider}
                        handleOnChange={handleOnChangeProviders}
                        idName='companyProviderID'
                        placeholder={'Operador'}
                        name={'providerSelect'}
                    />
                </Divider3>
                <Divider4 >
                    <InputSelectAgreement
                        data={customState.clientList}
                        value={customState.companyClientID}
                        validator={validator}
                        errorClient={customState.errorClient}
                        handleOnChange={handleOnChangeClients}
                        idName='companyClientID'
                        placeholder={'Cliente'}
                        name={'clientSelect'}
                    />
                </Divider4>
                {customState.companyProviderID !== "" && customState.companyClientID !== ""
                    && <Container>
                        <p>La vinculación creada será <strong>entre el <br />operador y el cliente</strong> seleccionados.</p>
                    </Container>
                }
                <ContainerFormDate>
                    <ContainerPositionDateOne>
                        <InputFormDate
                            type="date"
                            id="startDate"
                            label={'Fecha inicio'}
                            value={customState.startDate}
                            endDate={customState.endDate}
                            onChange={onChange}
                            validator={validator}
                            validateOptions={['required', 'dateStart']}
                            error={customState.errors}
                        />
                    </ContainerPositionDateOne>
                    <ContainerPositionDateTwo>
                        <InputFormDate
                            type="date"
                            id="endDate"
                            label={'Fecha vencimiento'}
                            value={customState.endDate}
                            onChange={onChange}
                            validator={validator}
                            validateOptions={['required', 'dateEnd']}
                            error={customState.errors}
                        />
                    </ContainerPositionDateTwo>
                </ContainerFormDate>
                <InputFormTextAndNumber
                    id={'quantity'}
                    label={'Cantidad envío de notificaciones'}
                    type={'text'}
                    onChange={onChange}
                    value={customState.clientDefinition !== 'PUBLIC' ? customState.quantity : 'Ilimitadas'}
                    disabled={customState.clientDefinition === 'PUBLIC' ? true : false}
                    validator={validator}
                    validateOptions={customState.clientDefinition === 'PRIVATE' ? ['required', 'numeric', 'quantityAgreement', { min: 1 }] : []}
                    error={customState.errors}
                    fixedMargin={true}
                    stylesAgreements
                    errorQuantity={customState.errorQuantity}
                />
                <InputFormTextAndNumber id={'agreementCode'}
                    label={'Código de vinculación (ej: P-01010101)'}
                    type={'text'}
                    onChange={onChange}
                    value={customState.agreementCode}
                    validator={validator}
                    validateOptions={['agreementCode', 'required']}
                    error={customState.errors}
                    fixedMargin={true}
                    stylesAgreements
                    setMessageShown={setMessagesShown}
                />
                <InputFormDate
                    type="date"
                    id="subscriptionDate"
                    label={'Fecha de suscripción'}
                    value={customState.subscriptionDate}
                    onChange={onChange}
                    validator={validator}
                    validateOptions={['required', 'dateSubscription']}
                    error={customState.errors}
                    stylesAgreements
                />
                <InputFormTextAndNumber
                    id={'tradeNumber'}
                    label={'Número de oficio'}
                    type={'text'}
                    onChange={onChange}
                    value={customState.tradeNumber}
                    validator={validator}
                    validateOptions={['required', 'numeric', { min: 4 }, { max: 15 }]}
                    error={customState.errors}
                    fixedMargin={true}
                    stylesAgreements
                />
                <InputFormDate
                    type="date"
                    id="tradeDate"
                    label={'Fecha de oficio'}
                    value={customState.tradeDate}
                    onChange={onChange}
                    validator={validator}
                    validateOptions={['required', 'dateSubscription']}
                    error={customState.errors}
                    stylesAgreements
                />
                <InputFormArea
                    id={'tradeDetails'}
                    label={'Detalle vinculación'}
                    type={'text'}
                    onChange={onChange}
                    value={customState.tradeDetails}
                    validator={validator}
                    validateOptions={['required', { min: 1 }, { max: 100 }]}
                    error={customState.errors}
                    min={1}
                    max={100}
                    heightText={'96px'}
                    stylesAgreements
                />
                <InputFormArea
                    id={'tradeSupport'}
                    label={'Soporte'}
                    type={'text'}
                    onChange={onChange}
                    value={customState.tradeSupport}
                    validator={validator}
                    validateOptions={['required', { min: 1 }, { max: 100 }]}
                    error={customState.errors}
                    min={1}
                    max={100}
                    heightText={'96px'}
                    stylesAgreements
                />
                <DividerAttachments>
                    <InputAttachButton
                        disabled={customState.agreementsFiles !== undefined && customState.agreementsFiles.length > 9 ? true : false}
                        onSelectFile={onChangeHandler}
                        value={customState.agreementsFiles}
                        accept='application/pdf'
                        className={!!customState.errorFile ? 'supportFilesError' : ''}
                        id='agreementsFiles'
                        validator={validator}
                        validateOptions={['required', { min: 1 },]}
                    />
                    {customState.agreementsFiles.length !== 0
                        && <DivFilesNames>
                            {customState.agreementsFiles.map(({ fileName }, i) =>
                                <div className='file' key={i} style={{ display: 'flex', flexDirection: 'column' }}
                                    onMouseEnter={() => setIsVisible(true, i)} onMouseLeave={() => setIsVisible(false, i)}>
                                    {progress[i] === 100 ?
                                        <div style={{ display: 'flex' }} >
                                            <TextNameFile2>{truncateFileName(fileName)}</TextNameFile2>.pdf
                                            <Ex className='hide' id={i} onClick={handleRemove} style={{
                                                minWidth: '12px',
                                                minHeight: '12px', margin: '5px 0 0 10px', cursor: 'pointer'
                                            }} />
                                        </div> : <>
                                            <div style={{ display: 'flex', opacity: '0.3' }} >
                                                <TextNameFile>{truncateFileName(fileName)}</TextNameFile>.pdf
                                            </div>
                                            <ProgressBar percent={progress[i]} active size='tiny'  style={{ display: 'flex' }}/>
                                        </>}
                                </div>)}
                        </DivFilesNames >
                    }
                    {customState.errorFile === true && <Container2> Debes adjuntar la vinculación </Container2>}
                </DividerAttachments>
                {customState.maxQuantityAchieved === true &&
                    <Container style={{ margin: '10px 0px' }}>
                        <p> Solo puedes adjuntar hasta 10 documentos.</p>
                    </Container>
                }
                {customState.fileTypeError === true &&
                    <Container style={{ margin: '10px 0px' }}>
                        <p> Solo puedes adjuntar documentos PDF.</p>
                    </Container>
                }
                {customState.repeatedFileError === true &&
                    <Container style={{ margin: '10px 0px' }}>
                        <p> No puedes adjuntar documentos con el mismo nombre</p>
                    </Container>
                }
                <Title2>Servicios de notificación</Title2>
                <InputCheckbokForm
                    label='Solo envío'
                    value={customState.sendNotification}
                    validator={validator}
                    validateOptions='required'
                    id='sendNotification'
                />
                <InputCheckbokForm
                    label='Envió con notificación push'
                    onChange={toggle}
                    value={customState.pushNotification}
                    validator={validator}
                    validateOptions='required'
                    id='pushNotification'
                />
                <InputCheckbokForm
                    label='Envió con confirmación de lectura'
                    onChange={toggle}
                    value={customState.notificationReading}
                    validator={validator}
                    validateOptions='required'
                    id='notificationReading'
                />
                <InputCheckbokForm
                    label='Envió con firma en notificación'
                    onChange={toggle}
                    value={customState.notificationSignature}
                    validator={validator}
                    validateOptions='required'
                    id='notificationSignature'
                />
                <InputCheckbokForm
                    label='Envió con firma en documentos'
                    onChange={toggle}
                    value={customState.documentSignature}
                    validator={validator}
                    validateOptions='required'
                    id='documentSignature'
                />
                {
                    customState.sendNotification === true && customState.pushNotification === true && customState.notificationReading === true
                    && customState.notificationSignature === true && customState.documentSignature === true
                    &&
                    <Container>
                        <p>Recuerda que, aunque selecciones todos los servicios, al momento de crear una notificación solo podrás solicitar
                            <strong>&nbsp;firma en notificación o firma en documentos.</strong></p>
                    </Container>
                }
                <ContainerButtons>
                    <ButtoCancel onClick={handleCancel}  >Cancelar</ButtoCancel>
                    <ButtoSave onClick={handleSend}>Crear vinculación</ButtoSave>
                </ContainerButtons>
            </CustomForm>
        </Divider >
    )
}

export default FormCreateAgreements