import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContainerSearchAgreementsClient from '../../../components/Search/SearchAgreements/clientAgreements/ContainerSearchAgreementsClient'
import AllAgreements from "./allAgreements/AllAgreementsClient";
import {useSelector } from 'react-redux';

const ClientAgreements = () => {
  const agreements = useSelector(state => state.agreementReducer.agreementListArray )
  const [paginationLoader, setPaginationLoader] = useState(true)
  return (
    <>
      <ContainerSearchAgreementsClient 
        showAZ={true}
        reportType={"LIST_AGREEMENTS"} 
        providerType={"AGREEMENT"}   
        disabledExportButton={!agreements.length > 0}
        customWidthDiv2={'50vw'}
        showAdvancedSearch={false}
        loader={paginationLoader}
      />
      <AllAgreements setPaginationLoader={setPaginationLoader} />
    </>
  )
}

ClientAgreements.propTypes = {
  history: PropTypes.any.isRequired,
}
export default ClientAgreements
