import React from 'react'
import ContainerSearch from '../../components/Search/SearchClients/ContainerClientSearch'
import AllClients from './AllClients'
import styled from 'styled-components';
import Paginacion from '../../components/Search/Paginacion'

const DivFooter = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2% 0;
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`
const Clients = () => {
  
  return (
    <>
      <ContainerSearch />
      <AllClients/>
      <DivFooter>
        <Paginacion />
      </DivFooter>
    </>
  )
}

export default Clients