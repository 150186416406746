import cogoToast from '@successtar/cogo-toast';

import { getCookieHeaders, toastOptions } from "../helpers/globalHelpers"
import setAuthToken from "../utils/setAuthToken"
import { useDispatch, useSelector } from "react-redux"
import { getRefreshToken, logoutUser } from '../actions/authActions';
import { cleanErrorsAction } from '../actions/errorsActions';

const getCookie = () => {
  return getCookieHeaders('__Host-rfsht')
}

export const errors = (props, reducer) => {
  const dispatch = useDispatch()
  if (reducer.errors.statusCode) {
    switch (reducer.errors.statusCode) {
      case 400:
        try {
          cogoToast.info(reducer.errors.result.errors.error, toastOptions)
        } catch (e) {
          console.error(reducer.errors)
        }
        break
      case 401:
        getCookie().then((result) => {
          const tokenR = result
          const body = {
            id: props.id,
            refreshToken: tokenR
          }
          dispatch(getRefreshToken(body))
        })
        break
      case 403:
        cogoToast.info('Permisos insuficientes.', toastOptions)
        props.history.push({ pathname: '/' })
        break
      case 404:
        cogoToast.info('No encontrado.', toastOptions)
        break
      case 422:
        try {
          cogoToast.info(reducer.errors.result.errors.error, toastOptions)
        } catch (e) {
          console.error(reducer.errors)
        }
        break
      case 503:
        props.history.push({ pathname: '/dashboard' })

        break
      case 600:
        dispatch(logoutUser(props.history))
        setAuthToken(false)
        break
      default:
        cogoToast.info(reducer.errors.statusDescription, toastOptions)
    }
    dispatch(cleanErrorsAction())
  }
}

export const errorsLogin = (props, reducer) => {
  if (reducer.errors.statusCode !== props.errorReducer.errors.statusCode && reducer.errors.statusCode) {
    switch (reducer.errors.statusCode) {
      case 400:
        cogoToast.error('Algo paso: ' + reducer.errors.result.errors.error, toastOptions)
        break
      case 401:
        break
      case 503:
        cogoToast.error(`Este servicio (${reducer.errors.result.reason.split(' ')[1]}) no esta disponible, intenta mas tarde.`, toastOptions)
        break
      case 404:
        cogoToast.error('No se encontró el usuario.', toastOptions)
        break
      default:
        cogoToast.info(reducer.errors.statusDescription, toastOptions)
    }
    props.cleanErrorsAction()
  }
}

export const errorsLoginProps = (props) => {
  switch (props.errorReducer.errors.statusCode) {
    case 400:
      cogoToast.error('Algo paso: ' + props.errorReducer.errors.result.errors.error, toastOptions)
      break
    case 401:
      break
    case 503:
      cogoToast.error(`Este servicio (${props.errorReducer.errors.result.reason.split(' ')[1]}) no esta disponible, intenta mas tarde.`, toastOptions)
      break
    case 404:
      cogoToast.error('No se encontró el usuario.', toastOptions)
      break
    default:
      cogoToast.info(props.errorReducer.errors.statusDescription, toastOptions)
  }
  props.cleanErrorsAction()
}

export const userPermissionsRedirect = (history, permissions, permission) => {
  if (userPermissions(permissions, permission)) {
    history.push({ pathname: '/' })
    cogoToast.info('Permisos insuficientes.', toastOptions)
  }
}

export const userPermissions = (permissions, permission) => {
  let found = false
  if (permissions) {
    permissions.forEach(value => {
      if (value === permission) found = true
    })
  }
  return !found
}
