import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContainerSearchClientsAndProviders from '../../../components/Search/SearchClientsAndProviders/ContainerSearchClientsAndProviders'
import AllClients from './AllClients'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom-v5-compat"

const OperatorClients = (props) => {

  const clients = useSelector(state => state.clientReducer.clients)
  const [paginationLoader, setPaginationLoader] = useState(true)
  const navigate = useNavigate()

  return (
    <>
      <ContainerSearchClientsAndProviders 
        textPlaceHolder={'Buscar' } 
        showButton={false} 
        showAZ={true}
        showExportButton={true} 
        disabledExportButton={!clients.length > 0} 
        showFilters={true}
        reportType={"LIST_CLIENTS"} 
        providerType={"CLIENT"}
        showAdvancedSearch={false}
        loader={paginationLoader}
      />
      <AllClients
        navigate={navigate}
        setPaginationLoader={setPaginationLoader}
      />
    </>
  )
}

export default OperatorClients