import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralTable from '../../../../components/Table/GeneralTable';
import {  activePagination } from '../../../../actions/filterDetailActions'
import { getLogsList } from "../../../../actions/agreementActions";
import _ from 'lodash'
import RowTableLogs from './RowTableLogs';
import { useNavigate } from 'react-router-dom-v5-compat';

const orderTranslator = (order) => {
  const options = {
    'masReciente': -1,
    'masAntiguo': 1,
  }
  return options[order]
};

const columnData = [
  { name: "Acción", width: 2, arrow: false },
  { name: "Usuario", width: 2, arrow: false },
  { name: "Fecha y hora", width: 2, arrow: false },
]

export const Logs = ({ agreementSerialID,GeneralLoader }) => {

  const [loader, setLoader] = useState(false)
  const [init, setInit] = useState(true)
  const dispatch = useDispatch()

  const filterReducer = useSelector(state => state.filterDetailReducer)
  const { logsList } = useSelector(state => state.agreementReducer)
  
  useEffect(() => {
    if (GeneralLoader === false) {
      setLoader(true)
    }
  }, [])

  useEffect(() => {
    if (!init) {
      setInit(true)
      setLoader(true)
      makeLogsRequest(filterReducer);
    }
  }, [filterReducer])

  useEffect(() => {
    if (GeneralLoader === false) {
      setLoader(true)
    }
    makeLogsRequest(filterReducer);
  }, [agreementSerialID])


  // useEffect(() => {
  // console.log('1');

  //   if (filterReducer.tabFilter !== 'Logs') {
  //     setInit(false)
  //     dispatch(actionClearFilter())
  // console.log('2');

  //   } else {
  // console.log('3');

  //     makeLogsRequest(filterReducer);
  //   }
  // }, [])

  const makeLogsRequest = (filterReducer) => {
    const body = {
      agreementSerialID: agreementSerialID,
      pag: filterReducer.pag,
      count: !!filterReducer.count ? filterReducer.count : 20,
      order: orderTranslator(filterReducer.sort),
      userName: filterReducer.searchBar[0],
      action: !!filterReducer.logsFilter && !!filterReducer.logsFilter.action ? filterReducer.logsFilter.action : ''
    }
    if (agreementSerialID !== undefined) {
      dispatch(getLogsList(body)).then(() => {
        dispatch(activePagination(false))
        setInit(false)
        setLoader(false)
      })
    }
  };

  return (
    <GeneralTable
      list={logsList}
      typeList="logs"
      headerTable={columnData}
      rowComponent={RowTableLogs}
      loader={loader}
      heigthHeader={'45px'}
      noWrap={false}
    />
  )
}

export default Logs