import React, { useEffect, useState } from 'react'
import { ReactComponent as IconAttach } from '../../../../../assets/icons/userDocuments/Attachment-icon.svg'
import styled, { css } from 'styled-components'
import StarredButton from '../../../../../components/Starred/StarredButton'
import OrderInitialLetter from '../../../../../components/Search/OrderInitialLetter'
import MainLabel from '../../../../../components/Label/MainLabelMale'
import moment from 'moment'
import { getPDF, viewPDF } from '../../../../../actions/seeAttachmentAction'
import { useDispatch, useSelector } from 'react-redux'
import { exportDocumentsToPDF, userDocumentStarred } from '../../../../../actions/userDocumentsActions'
import { ReactComponent as IconClose } from '../../../../../assets/icons/report/iconCloseDark.svg';
import { ReactComponent as Download } from '../../../../../assets/icons/download-attachment.svg'
import { CheckBoxGeneric } from '../../../../../components/CheckboxForm/CheckBoxGeneric'
import { toast } from 'react-toastify'
import { CHANGE_TOAST_ID } from '../../../../../actions/actionsUtilities/types'

const ContainerGrid = styled.div`
  width:308px;
  height: 120px;
  margin: 12px 10px;
  float:left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;

`
const Divider2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Divider3 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
`
const Divider4 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: start;
  align-items: center;
  cursor: pointer;
`

const Text = styled.p`
  color: #000000;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 180px;
  
`
const Text2 = styled.p`
  color: #7C7C74;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`

const TextRequiredSignature = styled.p`
  margin-left: 8px;
  margin-bottom: 0px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${props =>
    props.isCut &&
    css`
      overflow: hidden;
    `}
`;

const ContainerWarningMessage = styled.div`
  background: #E8FDFE;
  min-width: 293px;
  width:100%;
  max-height: 40px;
  height: 40px;
  border: 1px solid #4E8D93;
  border-radius: 4px;
  top: 8px;
  right: 8px;
  padding: 12px 24px 12px 24px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;

const ContainerText= styled.div`
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  gap: 3px;
`;

const ContainerTextOne = styled.label`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0px;
  text-align: left;
  color: #133F4F;
`;

const IconCloseDark = styled(IconClose)`
  cursor: pointer;
}
`;

const CardAttachmentDetail = (props) => {

  const [stateLocalStarred,  setStateLocalStarred] = useState(props.data.starred)
  const filterReducer = useSelector(state => state.filterDetailReducer)
  const [showMessage, setShowMessage] = useState(false)
  const {toastId} = useSelector((state) => state.sentReducer)

  useEffect(() => {
    if (showMessage) {
        if (toastId !== null) {
            toast.dismiss(toastId);
            setTimeout(() => {
                const id = toast(createToast(), { autoClose: 5000 ,
                    // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
                } );
                dispatch({type:CHANGE_TOAST_ID,payload:id});
                
            }, 500);
        }else{
            const id = toast(createToast(), { autoClose: 5000 ,
                                            // onClose: () => {dispatch({type:CHANGE_TOAST_ID,payload:null});} ,
                                        } );
                                        
            dispatch({type:CHANGE_TOAST_ID,payload:id});
        }
        
    }
  }, [showMessage]);

  const createToast = () => (
    <ContainerWarningMessage>
        <ContainerText>
            <ContainerTextOne>Descargando archivos</ContainerTextOne>
        </ContainerText>
        <IconCloseDark onClick={closeToast} />
    </ContainerWarningMessage>
  );

  const closeToast = () => {
    setShowMessage(false);
    toast.dismiss()
  };

  const orderTranslator = (order) => {
    const options = {
        'masReciente': { "_id": -1 },
        'masAntiguo': { "_id": 1 },
        'A-Z': { "fileOriginalName": 1 },
        'Z-A': { "fileOriginalName": -1 }
    }
    return options[order]
}


  const bodyPagination = {
    count: filterReducer.count,
    pag: filterReducer.pag,
    limit: 20,
    sort: orderTranslator(filterReducer.sort),
    status: filterReducer.searchAdvanced.notificationState,
    initDate: !!filterReducer.searchAdvanced.date.startDate
        ? moment(filterReducer.searchAdvanced.date.startDate).format('YYYY-MM-DD') : '',
    endDate: !!filterReducer.searchAdvanced.date.endDate
        ? moment(filterReducer.searchAdvanced.date.endDate).format('YYYY-MM-DD') : '',
    starred: filterReducer.starred,
    attachmentsName: filterReducer.searchBar[0],
    to: props.to,
}

  const dispatch = useDispatch()

  const formatDate = (date) => {
    return moment(date).format('L');
  }

  const validateUrl = (url) => {
    if (url === undefined) {
      props.changeMessageModal({
        type: 'errorDownloadReport'
      })

      return false
    } else {
      return true
    }
  }

  const viewPdf = () => {
    
    if (validateUrl(props.data.fileUrlEncrypted) === true) {
      dispatch(viewPDF({ "urlPDF": props.data.fileUrlEncrypted, "viewPDF": true, "documentName": props.data.fileName + "." + props.data.fileExtension, "base64": props.data.fileDecodedBase64 }))
      dispatch(getPDF({
        fileURL: props.data.fileUrlEncrypted,
        fileName: props.data.fileName
      }))
    } else {
   
    }
  }

  const hadleStarred = () => {
    setStateLocalStarred(!stateLocalStarred)
    const body = { "attachmentSerialID": props.data.documentId }
    dispatch(userDocumentStarred(body))
  }

  const [selectDocument, setSelectDocument] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setSelectDocument(props.activeAll)
  }, [props.activeAll])

  useEffect(() => {
    setSelectDocument(false)
    setStateLocalStarred(props.data.starred)
  }, [props.data])

  const handleSelectDocument = (e) => {
    e.stopPropagation()
    setSelectDocument(!selectDocument)
    if (!selectDocument) {
      props.addCheckedRow(props.data.documentId)
    } else {
      props.removeCheckedRow(props.data.documentId)
    }
  }

  const handleClickDownload = (e) => {
    setShowMessage(false);
    setShowMessage(true);
    e.stopPropagation()
    dispatch(exportDocumentsToPDF({ attachmentSerialIDList: [props.data.documentId] }))
    .finally(() => {
      setShowMessage(false);
    });
  }
  
  return (
    <>
      {props.isOrder === "A-Z" || props.isOrder === "Z-A"
        ?
        <OrderInitialLetter
          letter={props.letter}
          initial={props.isInitial}
        />
        : ''

      }
      <ContainerGrid  onClick={viewPdf}onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
        <Divider4 > 
          <IconAttach />
          <Text>  {props.data.fileName + "." + props.data.fileExtension}</Text>

        </Divider4>
        <Divider3>
          <Text2>Fecha envío: {formatDate(props.data.sendDate)}</Text2>
          <MainLabel
            text={props.data.currentStatus.name}
            icon={props.data.currentStatus.id}
            color={props.data.currentStatus.color}
          />
        </Divider3>
        <Divider3>
          <Divider4 >

            <CheckBoxGeneric onChange={handleSelectDocument} checked={selectDocument} style={{minWidth:'21px', minHeight:'21px', justifyConten:'center', alignItems:'center'}} />
            <StarredButton active={ stateLocalStarred } onClick={hadleStarred}
            />
          </Divider4>
          {props.data.reqSignature && <TextRequiredSignature isCut ={(isVisible && !selectDocument || !!showMessage)}>Con solicitud de firma</TextRequiredSignature>}
          { !showMessage ?

            (isVisible && !selectDocument) ? <div style={{ display: 'flex', color: '#22C1D0', alignItems:'center' }}>
              <Download style={{ cursor: 'pointer', margin: '0px 6px 0px 0px', minWidth: '20px' }} onClick={handleClickDownload} />
              <div style={{ overflowWrap: 'break-word', cursor: 'pointer', fontSize:'14px' }}
                onClick={handleClickDownload}>Descargar</div>
              </div>
              :
              <div style={{ margin: '2px 6px 0px 0px', minWidth: '92px', display: 'flex', minHeight:'24px'}}></div>
              :
              <div style={{display:'flex', alignItems:'center', color: '#22C1D0', a: 'center', fontSize: '14px',opacity:'0.3',cursor:'no-drop' }} >
                  <i class={"loading spinner icon"} style={{ marginRight:'5px' }}></i>
                  <div style={{ marginLeft:'5px' }} >Descargando</div>
                </div>
            }
        </Divider3>
      </ContainerGrid>
    </>
  )
}

export default CardAttachmentDetail