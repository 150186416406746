import React from 'react'
import { MainSegment } from '../../../../components/Sengment/MainSegment'
import CreateAgreements from './CreateAgreements'

export const AddAgreements = () => {
    return (
        <MainSegment>
            <CreateAgreements />
        </MainSegment>
    )
}
export default AddAgreements