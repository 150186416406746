import React, { Component, useState } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import AllSuperAdminImproperClients from './allSuperAdminImproperClients/AllSuperAdminImproperClients'
import ContainerSearchImproperClients from '../../../components/Search/SearchImproperClients/ContainerSearchImproperClients'

const SuperAdminImproperClients = (props) => {
  const [paginationLoader, setPaginationLoader] = useState(true)
  return (
    <MainSegment>
      <ContainerSearchImproperClients showAdvancedSearch={true} showFilters={true} showOldestNewest={false} showAZ={true} showPopUpFilter={true} loader={paginationLoader}/>
      <AllSuperAdminImproperClients setPaginationLoader={setPaginationLoader}/>
    </MainSegment>
  )
}

export default SuperAdminImproperClients
