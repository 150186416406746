import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as Document } from "../../../assets/icons/write/document.svg";
import { ReactComponent as DocumentDots } from "../../../assets/icons/write/document-dots.svg";
import { ReactComponent as NotificationPill } from "../../../assets/icons/write/notification-pill.svg";
import { ReactComponent as DownArrow } from "../../../assets/icons/write/down-arrow.svg";
import { ReactComponent as SeparatorLine } from "../../../assets/icons/write/separator-line.svg";
import { ReactComponent as IconBack } from '../../../assets/icons/write/bx_bx-arrow-back.svg'
import { ReactComponent as IconShare} from '../../../assets/icons/write/share_black_24dp 1.svg'
import { ReactComponent as IconMenu} from '../../../assets/icons/write/iconMenuPoints.svg'
import DOMPurify from 'dompurify'
import _ from "lodash";
import { changeDatePreview } from "../../../actions/writerActions";

const HeaderWsp = styled.div`
  background: linear-gradient(180deg, #0A4A83 0%, #003057 100%, #003057 100%);  
  max-width: 313px;
  max-height: 48px;
  width:100%;
  height: 48px;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
`

const DividerHeaderWsp = styled.div`
  display: flex;
  gap: 20px;
`

const IconBackWsp = styled(IconBack)`
  display: flex;
`
const IconShareWsp = styled(IconShare)`
  display: flex;
  width: 21px;
`
const IconMenuPoints = styled(IconMenu)`
  display: flex;
`
const PreviewMessage = () => {

  const dispatch = useDispatch()
  const form = useSelector(state => state.writerReducer.notificationForm)
  const specificFiles = useSelector(state => state.writerReducer.listDocumentsSpecific)
  const checkboxHeaderDisabled = useSelector(state => state.writerReducer.checkboxHeaderDisabled)
  const datePreview = useSelector(state => state.writerReducer.datePreview)
  const user = useSelector(state => state.authReducer.user)
  const statusFormNotification = useSelector(state => state.writerReducer.statusFormNotification)
  const [initialStatusFormNotification, setInitialStatusFormNotification] = useState(statusFormNotification)
  useEffect(()=>{
    if(initialStatusFormNotification !== statusFormNotification){
      if (statusFormNotification) {
        let date = new Date();
        dispatch(changeDatePreview(
          date.toLocaleString("en-AU").replace(",", " - ")
        ))
      }
    }
  },[statusFormNotification])

    return (
      <>
        {statusFormNotification === true && (
          <>
            <div className={"contents-previewMessage " +
            (!checkboxHeaderDisabled &&
              !form.notificationSignature
                ? ""
                : "active-check-notificationSigned")
            }
            >
           
              <HeaderWsp>
                <DividerHeaderWsp>
                  <IconBackWsp/>
                </DividerHeaderWsp>
                <DividerHeaderWsp>
                  <IconShareWsp/>
                  <IconMenuPoints/>
                </DividerHeaderWsp>       
              </HeaderWsp>
              <div className="notification-message-container-pro">
                <div className={!form.notificationSignature ?"notification-date onlyDate":'notification-date'}>
                {!form.notificationSignature 
                    ? ''
                    :<NotificationPill/>
                }
                <div className="text-date">
                  {datePreview}
                </div>
                </div>
                <div className="notification-title-container">  
                  <div className="notification-title">
                    {user.companyID.name}
                  </div>
                  <div className="down-arrow-title">
                    <DownArrow width="100%" height="100%" />
                  </div>
                </div>

                <div className="notification-subtitle">
                  {form.subject}
                </div>
                <div className="separator-line">
                    <SeparatorLine width="100%" height="100%" />
                </div>
                <div className="notification-description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(form.textEditor),
                    }}
                  />
                </div>
                {!_.isEmpty(form.files) && form.textEditor &&
                <div className="separator-line">
                    <SeparatorLine width="100%" height="100%" />
                </div>
                }
                <div className="notification-documents">
                  {Object.keys(specificFiles)?.map((key) => (
                    <div className="element-document" key={key}>
                      <div className="document-icon">
                        <Document />
                      </div>
                      <div className="document-name">
                        {specificFiles[key].name}
                      </div>
                      <div className="document-options">
                        <DocumentDots />
                      </div>
                    </div>
                  ))}
                  {Object.keys(form.files)?.map((key) => (
                    <div className="element-document" key={key}>
                      <div className="document-icon">
                        <Document />
                      </div>
                      <div className="document-name">
                        {form.files[key].name}
                      </div>
                      <div className="document-options">
                        <DocumentDots />
                      </div>
                    </div>
                  ))}
                </div>
                <div className={'notification-buttons-sign ' +
                (!form.notificationSignature
                    ? "close"
                    : "")
                }
                >
                  <button className='button-sign-rejected'>RECHAZAR</button>
                  <button className='button-sign-active'>FIRMAR</button>
                </div>
              </div>
            </div>
            
          </>
        )}
      </>
    );
}

export default PreviewMessage
