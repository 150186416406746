import React, { useState } from 'react'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import AllAuditorImproperClients from './allAuditorImproperClients/AllAuditorImproperClients'
import ContainerSearchImproperClients from '../../../components/Search/SearchImproperClients/ContainerSearchImproperClients'

export const AuditorImproperClients = (props)=> {
  const [paginationLoader, setPaginationLoader] = useState(true)
    return (
      <MainSegment>
        <ContainerSearchImproperClients showAdvancedSearch={true} showFilters={true} showOldestNewest={false} showAZ={true} showPopUpFilter={true} loader={paginationLoader}/>
        <AllAuditorImproperClients history={props.history} setPaginationLoader={setPaginationLoader}/>
      </MainSegment>
    )
}

export default AuditorImproperClients
