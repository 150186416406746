import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'semantic-ui-react'
import styled from 'styled-components'
import { actionFilterImproperClients } from '../../../actions/filterActions'
import ButtonIcon from '../../../components/Button/IconButton'
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg'
import { ReactComponent as Reviewed } from '../../../assets/icons/reviewed.svg'
import '../main.css'

const Divider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 20px;
  @media only screen and (max-width: 1280px) {
    padding: 0px 0px 0px 0px;
  }
`
const Texto = styled.div`
  color: #7C7C74;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const PopupFilter = styled(Popup)`
  background: #fff !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 0 !important;
  width: 190px !important;
  left: -1vw !important;
  border: none !important;

  &:before {
    display: none !important;
  }
`
const ButtonOption = styled.button`
  color: #7C7C74;
  border: 0;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  height: 32px;
  text-align: left;
  width: 100%;
  border-radius: 4px;
`
const Text = styled.p`
  color: #7C7C74;
  font-weight: normal;
  font-size: 14px;
  padding-left: 40px;
`

const ContainerOption = styled.div`
  padding: 8px 0px !important;
`

export const PopUpFilterImproperClients = () => {

  const [customState, setCustomState] = useState({
    show: false,
    filterOptions: '',
    pathname: ''
  })

  const dispatch = useDispatch()
  const filterOptionsReducer = useSelector((state) => state.filterReducer.improperClientsFilter.filterOptions)

  useEffect(() => {
    dispatch(actionFilterImproperClients(customState.filterOptions))
    setCustomState((prevState)=>({...prevState,show:false}))
  }, [customState.filterOptions])

  const toggle = () => {
    setCustomState((prevState) => ({ ...prevState, filterOptions: filterOptionsReducer, show: !customState.show }))
  }

  const changeOptions = (optionSelected) => {
    setCustomState((prevState) => ({ ...prevState, filterOptions: optionSelected }))
  }

  return (
    <Divider>
      <PopupFilter position='bottom left' on='click' pinned open={customState.show} onClose={toggle}
        trigger={
          <ButtonIcon onClick={toggle}>
            <Texto> Filtrar </Texto>
            <Filter />
          </ButtonIcon>
        }
      >
        <ContainerOption>
          <ButtonOption
            className='buttonOptionHover'
            onClick={() => changeOptions('')}
          >
            {customState.filterOptions === '' && <Reviewed className='iconReReviewed' />}
            {customState.filterOptions !== '' ? <Text>Todos</Text> : 'Todos'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeOptions('1')}           >
            {customState.filterOptions === '1' && <Reviewed className='iconReReviewed' />}
            {customState.filterOptions !== '1' ? <Text>Uso indebido alto</Text> : 'Uso indebido alto'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeOptions('2')}           >
            {customState.filterOptions === '2' && <Reviewed className='iconReReviewed' />}
            {customState.filterOptions !== '2' ? <Text>Del 60% al 80%</Text> : 'Del 60% al 80%'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeOptions('3')}           >
            {customState.filterOptions === '3' && <Reviewed className='iconReReviewed' />}
            {customState.filterOptions !== '3' ? <Text>Del 40% al 60%</Text> : 'Del 40% al 60%'}
          </ButtonOption>
          <ButtonOption className='buttonOptionHover' onClick={() => changeOptions('4')}          >
            {customState.filterOptions === '4' && <Reviewed className='iconReReviewed' />}
            {customState.filterOptions !== '4' ? <Text>Menos del 40%</Text> : 'Menos del 40%'}
          </ButtonOption>
        </ContainerOption>
      </PopupFilter>
    </Divider>
  )
}

export default PopUpFilterImproperClients
