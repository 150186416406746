import {
  LOADING_MODAL,
  CHANGE_STATE_OPEN_DATE_MODAL,
  CHANGE_STATE_CLOSE_DATE_MODAL,
  CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL,
  CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL,
  CHANGE_STATE_MESSAGE_MODAL,
  CHANGE_STATE_WARNING_FORM_MODAL,
  SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL,
  REFRESH_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL,
  LOADING_SEND_MASSIVE_MODAL,
  SHOW_RESULT_SEND_MASSIVE_MODAL, LOAD_RESULT_SEND_MASSIVE_MODAL,
  CHANGE_STATE_OPEN_PDF_MODAL,
  CHANGE_STATE_CLOSE_PDF_MODAL,
  CHANGE_STATE_REFRESH_MODULE,
  CHANGE_STATE_LOGIN_MODAL,
  WRITE_NOTIFICATION_MODAL_OPEN,
  WRITE_NOTIFICATION_MODAL_CLOSE,
  CHANGE_STATE_REPORT_MODAL,
  STATE_VALIDATE_FILE_MASIVE,
  CHANGE_STATE_LOADING_FILE_MASIVE,
  CHANGE_STATE_REPORTS_SEARCH_MODAL,
  CHANGE_STATE_REPORTS_LOADING_MODAL,
  CHANGE_STATE_WARNING_FORM_MODAL_DRAFTS,
  CLOSE_WARNING_FORM_MODAL_DRAFTS,
  STATE_RECORDS_FILE_MASSIVE,
  STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
  CLEAR_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
  CHANGE_STATE_MASSIVE_INCOMPLETE_MODAL,
  CHANGE_STATE_MESSAGE_MODAL_TWO,
  CHANGE_STATE_FORM_CANCEL_AGREEMENT,
  CHANGE_STATE_MODAL_REASON_CANCEL_AGREEMENT,
  CHANGE_STATE_WARNING_FORM_MODAL_WRITE,
  HIDE_MESSAGE_MODAL,
  CHANGE_STATE_WARNING_FORM_MODAL_USER_AND_EMPLOYES
} from "./actionsUtilities/types"
import axios from "axios";
import { API_ENDPOINT } from "../config/config";
import { RECORDS_FILE_MASIVE, RECORDS_FILE_MASIVE_V2, MODAL_MASIVE_V2  } from "./actionsUtilities/actionsRoutes";
import { getCookieHeaders, getHeaders } from "../helpers/globalHelpers";
import { decrypt, encrypt } from "../helpers/cypher";

export const openwriteNotificationModal = () => async dispatch => {
  dispatch({  type: WRITE_NOTIFICATION_MODAL_OPEN })
}

export const closewriteNotificationModal = () => ({ type: WRITE_NOTIFICATION_MODAL_CLOSE })


export const loadingModal = (state) => async dispatch => {
  dispatch({ type: LOADING_MODAL, payload: state })
}

export const changeMessageModal = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: messageObject
  })
}

export const changeMessageModalTwo = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL_TWO,
    payload: messageObject
  })
}

export const hideMessageModal = () => (dispatch) => {
  dispatch({
    type: HIDE_MESSAGE_MODAL
  })
}



export const changeStateMassiveIncompleteModal = (data) => async (dispatch) => {
  dispatch({ type: CHANGE_STATE_MASSIVE_INCOMPLETE_MODAL, payload: data })
}

export const changeReportsLoadingModal = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_REPORTS_LOADING_MODAL,
    payload: messageObject
  })
}


export const changeMassiveNotificationModal = () => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: { type: 'massiveNotificationAlert' }
  })
}

export const changeDeleteDraftModal = () => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: { type: 'deleteDraft' }
  })
}

export const changeDeleteDraftListModal = () => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: { type: 'deleteDraftList' }
  })
}

export const changeReportLoadingModal = () => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MESSAGE_MODAL,
    payload: { type: 'reportLoadingMessage' }
  })
}

export const changeMessageModalLogin = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOGIN_MODAL,
    payload: messageObject
  })
}

export const openDateModal = () => ({ type: CHANGE_STATE_OPEN_DATE_MODAL })

export const showStateErrorMessageSendMassiveModal = () => ({ type: SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL, payload:'visible' })

export const refreshStateErrorMessageSendMassiveModal = () => ({ type: REFRESH_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL})

export const changeStateSearchReportsModal = () => ({ type: CHANGE_STATE_REPORTS_SEARCH_MODAL })

export const saveDataMassive = (body) => async (dispatch) => {
  dispatch({
    type: CHANGE_STATE_LOADING_FILE_MASIVE,
    payload: body
  })
}


export const sendMassiveFileV2 = (body, navigate) => async (dispatch) => {
  if(!!body.fileName){
    const headers = await getHeaders();
    const encryptedBody = await encrypt(body);
    dispatch({
      type: CHANGE_STATE_LOADING_FILE_MASIVE,
      payload: { type: 'progress' }
    })
    dispatch({
      type: CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL,
    })
    return await axios.post(
      `${API_ENDPOINT + MODAL_MASIVE_V2}`,
      {data: encryptedBody },
      { headers }
    ).then(async res => {
      const decryptedData = await decrypt(res.data.result);
      dispatch({ type: SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL, payload:'hidden' })
      dispatch({ type: WRITE_NOTIFICATION_MODAL_CLOSE })
      dispatch({ type: CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL })
      dispatch({
        type: CHANGE_STATE_LOADING_FILE_MASIVE,
        payload: { type: 'progress' }
      })
      dispatch({
        type: STATE_VALIDATE_FILE_MASIVE,
        payload: decryptedData
      })
      if(navigate){
        navigate('/massiveLoad')
      }
    }).catch(async (err) => {
      dispatch({
        type: CHANGE_STATE_LOADING_FILE_MASIVE,
        payload: { type: 'progress' }
      })
      dispatch({
        type: CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL,
      })
      const decryptedData = await decrypt(err.response.data.result);
      if(err.response.status === 422 && decryptedData.message === 'La bolsa actual no cubre la cantidad de ciudadanos en el archivo cargado.'){
        dispatch(changeMessageModal({type: 'errorBag', quantyCitizen: decryptedData.quantyCitizen}))
        console.error(err)
      }else {
        dispatch({ type: SHOW_STATE_ERROR_MESSAGE_SEND_MASSIVE_MODAL, payload:{status:'visible', message: 'El formato del archivo fue modificado o no contiene la información correcta, verifícalo y vuelve a intentarlo.'} })
      }
      return err
    })
  }
}


export const recordsMassiveFileV2 = (body) => async (dispatch) => {
  const headers = await getHeaders();
  const encryptedBody = await encrypt(body);
  return await axios.post(
    `${API_ENDPOINT + RECORDS_FILE_MASIVE_V2}`, {data: encryptedBody }, { headers }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    dispatch({
      type: STATE_RECORDS_FILE_MASSIVE,
      payload: decryptedData
    })
  }).catch(err => {

  })
}

export const recordsMassiveFileParametrization = (body) => async (dispatch) => {
  const headers = { 'Authorization': await getCookieHeaders('__Host-jwt') }
  return await axios.post(
    `${API_ENDPOINT + RECORDS_FILE_MASIVE}`, body, { headers }
  ).then(res => {
    const result = res.data.result
    if (result.length !== 0) {
      dispatch({
        type: STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
        payload: result
      })
    } else {
      dispatch({
        type: STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
        payload: {
          listFileSearchMassive: [{
            citizenValidate: 0,
            totalRecordsVerified: 0,
            totalRecordsToVerify: 0,
          }]
        }
      })
    }
  }).catch(err => {
    
  })
}

export const recordsMassiveFileParametrizationV2 = (body) => async (dispatch) => {
  const headers = await getHeaders();
  const encryptedBody = await encrypt(body);
  return await axios.post(
    `${API_ENDPOINT + RECORDS_FILE_MASIVE_V2}`, { data: encryptedBody }, { headers }
  ).then(async res => {
    const decryptedData = await decrypt(res.data.result);
    if (decryptedData.length !== 0) {
      dispatch({
        type: STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
        payload: decryptedData
      })
    } else {
      dispatch({
        type: STATE_RECORDS_FILE_MASSIVE_PARAMETRIZATION,
        payload: {
          listFileSearchMassive: [{
            citizenValidate: 0,
            totalRecordsVerified: 0,
            totalRecordsToVerify: 0,
          }]
        }
      })
    }
  }).catch(err => {
  })
}

export const clearRecordsMassiveFileParametrization = () => ({ type: CLEAR_RECORDS_FILE_MASSIVE_PARAMETRIZATION })

export const loadingSendMassiveModal = (data) => dispatch => {
  dispatch({ type: LOADING_SEND_MASSIVE_MODAL, payload: data })
}

export const loadResultsMassiveModal = (data) => dispatch => {
  dispatch({ type: LOAD_RESULT_SEND_MASSIVE_MODAL, payload: data })
}

export const showResultsMassiveModal = (data) => dispatch => {
  dispatch({ type: SHOW_RESULT_SEND_MASSIVE_MODAL, payload: data })
}

export const closeDateModal = () => ({ type: CHANGE_STATE_CLOSE_DATE_MODAL })

export const openSendMassiveModal = () => ({ type: CHANGE_STATE_OPEN_SEND_MASSIVE_MODAL })

export const closeSendMassiveModal = () => ({ type: CHANGE_STATE_CLOSE_SEND_MASSIVE_MODAL })

export const warningFormModal = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_WARNING_FORM_MODAL,
    payload: messageObject
  })
}

export const warningFormModalUserandEmployes = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_WARNING_FORM_MODAL_USER_AND_EMPLOYES,
    payload: messageObject
  })
}

export const warningFormModalWrite = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_WARNING_FORM_MODAL_WRITE,
    payload: messageObject
  })
}

export const warningFormModalDrafts = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_WARNING_FORM_MODAL_DRAFTS,
    payload: messageObject
  })
}
export const closeWarningModal = () => (dispatch) => {
  dispatch({
    type: CLOSE_WARNING_FORM_MODAL_DRAFTS
  })
}


export const openPDFModal = () => ({ type: CHANGE_STATE_OPEN_PDF_MODAL })

export const closePDFModal = () => ({ type: CHANGE_STATE_CLOSE_PDF_MODAL })

export const refreshStateModule = () => ({ type: CHANGE_STATE_REFRESH_MODULE, payload: false })

export const changeMessageModalReport = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_REPORT_MODAL,
    payload: messageObject
  })
}

export const warningModalCanceleAgreement = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_FORM_CANCEL_AGREEMENT,
    payload: messageObject
  })
}

export const warningModalReasonCanceleAgreement = (messageObject) => (dispatch) => {
  dispatch({
    type: CHANGE_STATE_MODAL_REASON_CANCEL_AGREEMENT,
    payload: messageObject
  })
}