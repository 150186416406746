import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {activePagination} from '../../../../actions/filterActions'
import GeneralTable from '../../../../components/Table/GeneralTable'
import RowTableAgreementsSuperAdmin from "../RowTableAgreementsSuperAdmin";
import {agreementListV2} from "../../../../actions/agreementActions";

const columnData = [
  {name: "Cód. vinculación", width: 2, arrow: false},
  {name: "Cliente", width: 2, arrow: false},
  {name: "Operador", width: 3, arrow: false},
  {name: "Fecha suscripción", width: 2, arrow: false},
  {name: "Fecha inicio", width: 2, arrow: false},
  {name: "Fecha fin", width: 2, arrow: false},
  {name: "Estado", width: 3, arrow: false},
]

const orderTranslator = (order) => {
  const options = {
    'masReciente': {"_id": -1},
    'masAntiguo': {"_id": 1},
    'A-Z': {"agreementCode": 1},
    'Z-A': {"agreementCode": -1}
  }
  return options[order]
};

export const AllAgreementsSuperAdmin = ({setPaginationLoader}) => {

  const [loader, setLoader] = useState(true)
  const [init, setInit] = useState(true)
  const customEqual = (oldValue, newValue) => oldValue === newValue
  const filterReducer = useSelector(state => state.filterReducer, customEqual)
  const {agreementListArray} = useSelector(state => state.agreementReducer, customEqual)

  const dispatch = useDispatch()

  useEffect(() => {
    makeAgreementRequest(filterReducer)
  }, [])

  useEffect(() => {
    if (!init) {
      makeAgreementRequest(filterReducer);
      setInit(true)
      setLoader(true)
    }
  }, [filterReducer])
  
  const makeAgreementRequest = (filterReducer) => {
    const body = {
      count: filterReducer.count,
      pag: filterReducer.pag,
      status: filterReducer.agreementFilter.agreementStatus,
      sort: orderTranslator(filterReducer.sort),
      textToSearch: filterReducer.searchBar[0]
    }
    dispatch(agreementListV2(body)).then(() => {
      dispatch(activePagination(false))
      setInit(false)
      setLoader(false)
      setPaginationLoader(false)
    })
  }

  return (
    <GeneralTable
      list={agreementListArray}
      typeList={filterReducer.isSearch === true ? 'default' : 'convetions'}
      listHeight={'agreements'}
      headerTable={columnData}
      rowComponent={RowTableAgreementsSuperAdmin}
      loader={loader}/>
  )
}

export default AllAgreementsSuperAdmin