import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { actionPagination, activePagination } from '../../actions/filterDetailActions'
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg'
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg'

const Divider = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 19px;

  @media only screen and (max-width: 768px) {
    gap: 9px;
  }
`
const Text = styled.p`
  width: max-content;
  color: #7C7C74;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0.5rem !important;
  @media only screen and (max-width: 768px) {
    font-size: 15px;
  }
`
const DividerIcon = styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    display: flex
  } 
  @media only screen and (max-width: 768px) {
    gap: 5px;
  }
`
const LeftArrow = styled.button`
  padding: 0;
  margin: 0 5px 0 5px;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  ${props => !!props.isDiabledLeft && css`
  opacity: 0.3;
  cursor: default;
  `}

`
const RightArrow = styled.button`
  padding: 0;
  margin: 0 0 0 0;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: pointer;
  ${props => !!props.isDiabledRight && css`
  opacity: 0.3;
  cursor: default;
  `}
`

const PaginationDetail = () => {
  const dispatch = useDispatch()

  const activePag = useSelector( state => state.menuReducer.activePagination)
  const filterReducer = useSelector( state => state.filterDetailReducer)
  const paginationReducer = useSelector( state => state.paginationReducer)

  const [state, setState] = useState({
    first: ((filterReducer.count * filterReducer.pag) - filterReducer.count) + 1 ,
    last: filterReducer.count * filterReducer.pag,
    pag: filterReducer.pag,
    totalCount: filterReducer.count,
    total: 0,
    disabledLeft: false,
    disabledRight: false,
  })

  let refreshPag = filterReducer.pag
  let refreshFirst = state.first
  let refreshLast = filterReducer.count
  let refreshTotal = state.total
  let refreshTotalCount = filterReducer.count

  useEffect(()=>{
    setState((prevState)=>({
      ...prevState,
      total: paginationReducer.total, 
      pag: filterReducer.pag, 
      first: ((filterReducer.count * filterReducer.pag) - filterReducer.count) + 1, 
      last: ((filterReducer.count * filterReducer.pag) < paginationReducer.total) ? (filterReducer.count * filterReducer.pag) : paginationReducer.total 
    }))
    dispatch(actionPagination(state.pag))
    validateDisableArrow()
  },[])

  useEffect(()=>{
    if (state.pag !== filterReducer.pag) {
      dispatch(actionPagination(state.pag))
      validateDisableArrow()
    } else {
      if (state.pag !== 1 && filterReducer.pag === 1) {
        refreshPag = 1
        refreshFirst = 1
        refreshLast = filterReducer.count * state.pag
      }
    }
  },[state.pag])

  useEffect(()=>{
      refreshPag= filterReducer.pag <= 0 ?  1 : filterReducer.pag
      refreshTotal = paginationReducer.total
      refreshLast= filterReducer.count * refreshPag
      refreshFirst= ((filterReducer.count * refreshPag) - filterReducer.count) + 1
      setState((prevState)=>({
        ...prevState,
        total: refreshTotal, totalCount: refreshTotalCount, pag: refreshPag, first: refreshFirst, 
        last: (refreshTotal >= refreshLast ? (refreshLast === 0 ? refreshLast: refreshLast) : refreshTotal) 
      }))
      validateDisableArrow()
  },[paginationReducer.total])

  const handleClickIncrement = () => {
    if (state.last < state.total) {
      setState((prevState) => ({
        ...prevState,
        first: prevState.first + prevState.totalCount,
        last: (prevState.last + prevState.totalCount) > state.total ? state.total : (prevState.last + prevState.totalCount),
        pag: state.pag + 1,
      }))
      dispatch(activePagination(true))
    }
  }

  const handleClickDecrement = () => {
    if (state.last > state.totalCount) {
      setState((prevState) => ({
        ...prevState,
        first: prevState.first - prevState.totalCount,
        last: prevState.first - 1,
        pag: state.pag - 1,
      }))
      dispatch(activePagination(true))
    }
  }

  const validateDisableArrow = () => {
    const validateDisabled=  (paginationReducer.total - (filterReducer.count * state.pag))
    if(paginationReducer.total > 0){
      if(state.pag > 1){
        if(validateDisabled < 1){
          setState((prevState) => ({
            ...prevState,
            disabledLeft: false, 
            disabledRight: true
          }))
        }else{
          setState((prevState) => ({
            ...prevState,
            disabledLeft: false, 
            disabledRight: false }))
        }
      }else{
        if(validateDisabled < 1){
          setState((prevState) => ({
            ...prevState,
            disabledRight: true 
          }))
        }else{
          setState((prevState) => ({
            ...prevState,
            disabledRight: false
          }))
        }
        setState((prevState) => ({
          ...prevState,
          disabledLeft: true
        }))
      }
    }else{
      setState((prevState) => ({
        ...prevState,
        disabledLeft: true, 
        disabledRight: true 
      }))
    }
  }

  const changeFormatPaginationNumber = (num) =>{
    return Intl.NumberFormat("es-CL").format(num)
  }

    return (
      <Divider>
          {
              state.total === undefined || state.total === 0
              ? 
              <Text> 0 - 0 de 0 </Text>
              : 
              <Text>{changeFormatPaginationNumber(state.first)} - {changeFormatPaginationNumber(state.last)} de {changeFormatPaginationNumber(state.total)}</Text>
          }
        <DividerIcon>
          <LeftArrow onClick={handleClickDecrement} disabled={activePag} isDiabledLeft={state.disabledLeft}>
            <LeftArrowIcon />
          </LeftArrow>
          <RightArrow onClick={handleClickIncrement}  disabled={activePag} isDiabledRight={state.disabledRight}>
            <RightArrowIcon />
          </RightArrow>
        </DividerIcon>
      </Divider>
    )
}

export default PaginationDetail
