import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { detailNotificationOneV2 } from '../../../actions/seeNotificationAction'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import Header from './Header'
import NotificationContent from './NotificationContent'
import NotificationEvents from './NotificationEvents'
import { PDF } from './PDF'
import './attachment.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useNavigate } from 'react-router-dom-v5-compat'

const Container = styled.div`
  margin: 0px 30px 0 22px !important;
  
  @media only screen and (max-width: 1200px)  {
    margin: 0px 30px 0 22px !important;
  }
`
const ContainerGrid = styled.div`
  width: 65%;
  margin: 0px 10px;
  float: left;
  padding: 0px 10px;

  @media only screen and (max-width: 1200px)  {
    width: 100%;
    margin: 0px 0px;
    padding: 10px 3px;
  }
`
const ContainerGrid2 = styled.div`
  width: 30%;
  margin: 0px 10px;
  float: left;
  padding: 0px 10px;

  @media only screen and (max-width: 1200px)  {
    width: 100.5%;
    margin: 0px 0px;
    padding: 0px 0px 0px 10px;
  }
`
const ContainerScroll = styled.div`
  width: 100%;
  height: 89.3vh;
  overflow-y: scroll;

  ::-webkit-scrollbar-track {
    background: #CCCCCC;
  }

  ::-webkit-scrollbar-thumb {
    background: #9D9D9C !important;
  }

  ::-webkit-scrollbar:vertical {
    width: 5px;
  }
`
const NotificationDetail = () => {
  const {addPrint} = useSelector((state) => state.sentReducer)
  const {reportData} = useSelector((state) => state.sentReducer)
  const dispatch = useDispatch()
  const { notificationID } = useParams()
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const body = { "notificationID": notificationID }
    dispatch(detailNotificationOneV2(body)).then(() => setLoader(false))
  }, [notificationID, dispatch])

 

  return (
    <>
      <MainSegment>
        <Dimmer.Dimmable active={loader.toString()}>
          <Dimmer active={loader} inverted>
            <Loader size='medium'>Cargando...</Loader>
          </Dimmer>
          <ContainerScroll>
            <Header navigate={navigate} />
            <PDF />
            <Container>
                <ContainerGrid>
                  <NotificationContent />
                 </ContainerGrid> 
                <ContainerGrid2>
                  <NotificationEvents />
                </ContainerGrid2>
            </Container>
          </ContainerScroll>
        </Dimmer.Dimmable>
      </MainSegment >
    </>
  )
}

export default NotificationDetail
