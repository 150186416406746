import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import '../../index.css'
import { changeDocumentCurrent } from '../../../../actions/seeAttachmentAction'
import Label from '../../../../components/Label/MainLabelMale'
import { ReactComponent as IconFile } from '../../../../assets/icons/userDocuments/fileIcon.svg'
import { ReactComponent as IconAttachment } from '../../../../assets/icons/userDocuments/Attachment-icon.svg'
import StarredButton from '../../../../components/Starred/StarredButton'
import OrderInitialLetter from '../../../../components/Search/OrderInitialLetter'
import {viewPDF, getPDF } from '../../../../actions/seeAttachmentAction'
import { changeMessageModal } from '../../../../actions/modalActions'
import { actionClearFilter } from '../../../../actions/filterDetailActions'
import { userDocumentStarred } from '../../../../actions/userDocumentsActions'
import { useNavigate } from 'react-router-dom-v5-compat'

const ContainerGrid = styled.div`
  width:300px;
  height: 148px;
  margin: 12px 10px;
  float:left;
  border: 1px solid #DADADA;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.typeSearch === "2" ? 'space-around' : 'flex-start'};
&:hover {
  background: #FFFFFF 0 0 no-repeat padding-box !important;
  box-shadow: 0 2px 2px #00000059 !important;
  border: 1px solid #B2B2B2 !important;
  opacity: 1 !important;
}
`

const SourceIcon= styled(IconFile)`
  float: left;
  margin-right:8px;
`
const AttachmentIcon= styled(IconAttachment)`
  margin-right:8px;
  float: left;
`
const ContainerText = styled.div`
  float: left;
  display: flex;
  justify-content: space-between;
`
const ContainerText2 = styled.div`
  float: left;
  width: 100%;
  margin:3px 0px;
  padding: 0px 0px;
`
const ContainerText3 = styled.div`
  display:flex;
  float: left;
  width: 100%;
  font-size: 14px !important;
  margin:3px 0px;
  padding: 0px 0px;
`

const Text = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 150px;
`

const TextTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`
const TextTitleU = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`

const TextDate = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #3C3C3B;
`

const StatusContainer = styled.div`
float: left;
width: 100%;
display: flex;
justify-content: space-between;
`

const ContainerStarred= styled.div`
  float: right;
`

const TextRequiredSignature = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;
`

const RowTableUserDocuments = (props) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [stateLocalStarred,  setStateLocalStarred] = useState(props.data.typeSearch !== '1'? props.data.starred : false)
  const redirectToAttachments = () => {
    if (props.data.typeSearch === '1') {
      props.changeDocumentCurrent(props.data.documentNumberRecipient)
      navigate({ pathname: '/userDocuments-detail/' + props.data.documentNumberRecipient })
      dispatch(actionClearFilter())
    }else{
      viewPdf()
    }
  }

  const validateUrl = (url) => {
    if (url === undefined) {
      props.changeMessageModal({
        type: 'errorDownloadReport'
      })
      return false
    } else {
      return true
    }
  }

  const viewPdf = () => {
    if (validateUrl(props.data.fileURL) === true) {
      props.viewPDF({ "urlPDF": props.data.fileURL, "viewPDF": true, "documentName": props.data.fileName})
      props.getPDF({
        fileURL: props.data.fileUrlEncrypted,
        fileName: props.data.fileName
      })
    }
  }

  const hadleStarred = () => {
    setStateLocalStarred(!stateLocalStarred)
    const body = { "attachmentSerialID": props.data.attachmentSerialID }
    dispatch(userDocumentStarred(body))
  }

  useEffect(() => {
    if(props.data.typeSearch !== '1'){
      setStateLocalStarred(props.data.starred)
    }
  }, [props.data])

  const formatDate = (userDocumentDate) => moment(userDocumentDate).format('DD/MM/YYYY')
  
    return (
      <>
      {props.isOrder === "A-Z" || props.isOrder === "Z-A"
      ?
        <OrderInitialLetter
          letter= {props.letter}
          initial={props.isInitial} 
        />
        : ''
      }
      <ContainerGrid onClick={redirectToAttachments} typeSearch = { props.data.typeSearch} >
        {
          props.data.typeSearch === "2"
            ?
            <>
              <ContainerText>
                <div style={{maxWidth:'80%'}}>
                  <AttachmentIcon/>
                  <TextTitle>{props.data.nameElement}.pdf</TextTitle>
                </div>
                <StarredButton
                onClick={hadleStarred}
                active={stateLocalStarred}
                />
              </ContainerText>
              <ContainerText3>
                <Text>CC: {props.data.documentNumberRecipient}</Text>
              </ContainerText3>
              <ContainerText2>
                <TextDate>Fecha envío: {formatDate(props.data.date)}</TextDate>
              </ContainerText2>
            </>
            :
            <>
            <ContainerText>
              <div style={{maxWidth:'80%'}}>
                <SourceIcon/>
                <TextTitleU>CC: {props.data.documentNumberRecipient}</TextTitleU>
              </div>
            </ContainerText>
            <ContainerText2>
              <TextDate>Fecha modific: {formatDate(props.data.date)}</TextDate>
            </ContainerText2>
            </>
        }
      <StatusContainer>
          {
            props.data.status === 'Pendiente'
              ?
              <Label
                className={'labelDocument'}
                text={props.data.status}
                icon={3}
                color={'#E35F00'}
              />
              : props.data.status === 'Firmado'
                ?
                <Label
                  className={'labelDocument'}
                  text={props.data.status}
                  icon={6}
                  color={'#009838'}
                />
                : props.data.status === 'No Leido'
                  ?
                  <Label
                    className={'labelDocument'}
                    text={props.data.status}
                    icon={1}
                    color={'#0E3B52'}
                  />
                  : props.data.status === 'Leido'
                    ?
                    <Label
                      className={'labelDocument'}
                      text={props.data.status}
                      icon={2}
                      color={'#A4A4A4'}
                    />
                    : props.data.status === 'Rechazado'
                      ?
                      <Label
                        className={'labelDocument'}
                        text={props.data.status}
                        icon={4}
                        color={'#BA3555'}
                      />
                      : props.data.status === 'Rebotado'
                        ?
                        <Label
                          className={'labelDocument'}
                          text={props.data.status}
                          icon={7}
                          color={'#E49300'}
                        />
                        : props.data.status === 'Error'
                          ?
                          <Label
                            className={'labelDocument'}
                            text={props.data.status}
                            icon={8}
                            color={'#FF0000'}
                          />
                          : ''
          }
          {props.data.requiredSignature == true && <TextRequiredSignature>Con solicitud de firma</TextRequiredSignature>}
        </StatusContainer>
    </ContainerGrid>
    </>
  )
};

RowTableUserDocuments.propTypes = {
  data: PropTypes.object.isRequired,
  changeDocumentCurrent: PropTypes.func,
};

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  changeDocumentCurrent,
  viewPDF,
  changeMessageModal,
  getPDF
}

export default connect(mapStateToProps, mapDispatchToProps)(RowTableUserDocuments)