import React, { Component } from 'react'
import {
  Dropdown,
  Form
} from 'semantic-ui-react'
import './index.css'
import styled from "styled-components"


const TextPlaceholder = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  position: absolute;
  top: -12px;
  left: 15px;
  z-index: 3;
`
const DropdownGeneric = styled(Dropdown)`
&&& .icon {
  top: 25% !important;
}

&&& .item {
  height: 32px; 
  line-height: 16px; 
  align-items:center;
  font-size: 16px;
}
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
`

const options = [
  { key: '', text: '', value: '' },
  { key: 'STATE', text: 'Reporte de estado', value: ["STATUS_SINGLE", "STATUS_SECUNDARY", "LIST_MASSIVE"] },
  { key: 'WRONG', text: 'Notificaciones erróneas', value: 'WRONG_NOTIFICATION' },
]


export const FormFilter = (props) => {
  return (
    <Form onSubmit={props.handleSubmit}>
    <>
      <DropdownGeneric
        selection
        button
        options={options}
        style={{ width: "314px", marginBottom: "5px" }}
        value={props.state.reportType}
        onChange={props.handleOnChange}
        id="state"
      />
      {
        props.state.reportType === ''
          ? <TextPlaceholder>Tipo de reporte</TextPlaceholder>
          : ''
      }
    </>
  </Form >
  )
}


export default FormFilter
