import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Menu } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import { MainSegment } from '../../../components/Sengment/MainSegment'
import MenuItemTab from '../../../components/Tab/MenuImageTab'
import AllConvetions from './Convetions/AllConvetions'
import AllLogs from './Logs/AllLogs'
import AllUsers from './Users/AllUsers'
import { DetailClientYProvider } from '../../../components/DetailClientsYProvider/DetailClientYProvider'
import { CollapsableDetailClient } from './CollapsableDetailClient'
import { setOriginalNumberOfEmployeees } from '../../../actions/clientActions'
import { actionSearchBar } from '../../../actions/filterDetailActions'
import { useNavigate } from "react-router-dom-v5-compat"

const CustomTab = styled(Tab)`
  .active.item {
    color: #22C1D0 !important;
    border-color: #22C1D0 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-bottom-width: 4px !important;
    margin: 0 0 -4px !important;
  }
  .active.item div svg path {
    fill: #22C1D0 !important;
  }
  .ui.menu {
    display:grid;
    grid-row-gap:2px;
    grid-template-columns: 1fr 1fr 1fr; 
    padding: 25px 20px 0 20px !important;
    border-bottom: 2px solid #E4E4E4 !important;
  }
  .ui.secondary.pointing.menu .item {
    height: 64px !important;
    font-family: 'Roboto';
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #133F4F;
  }

@media only screen and (min-width: 960px) and (max-width: 1024px) {
  .ui.secondary.pointing.menu .item {
    font-size: 14px;
  }
}
`




const CustomMenuItem = styled(Menu.Item)`
  display: flex !important;
  flex: 1 !important;
  justify-content: center;
  padding: 0 !important;

  ${({ additionalStyles }) => additionalStyles && css`
    /* Aquí puedes agregar los estilos adicionales según el parámetro additionalStyles */
    ${additionalStyles}
  `}
`;

const Content = styled(Tab.Pane)`
  border: none !important;
  padding: 0 !important;
`

const DivDetail = styled.div`
    grid-column: 1 / 3;
    grid-row: 1;
`

const TabDetailClient = (props) => {
    const { tabFilter } = useSelector((state) => state.filterReducer);
    const { employeesCount,originalEmployeesCount  } = useSelector((state) => state.clientReducer);
    const dispatch = useDispatch()
    const navigate = useNavigate()
  
  
    useEffect(() => {
    if (employeesCount > originalEmployeesCount ) {
        dispatch(setOriginalNumberOfEmployeees(employeesCount))
      }
    }, [employeesCount]);

    useEffect(() => {
      return () => {
        dispatch(setOriginalNumberOfEmployeees(-1));
        dispatch(actionSearchBar('', 'initialState'));
      };
    }, [])
    
    
    const panes = props.isResponsive === false ?
    [
        props.userRole!=='Operador' ? {
            menuItem: (
                <CustomMenuItem key='users'  >
                    <MenuItemTab icon='default' title='Usuarios' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                <AllUsers
                    tabFilter='users'
                    navigate={navigate}
                    filterPosition={props.filterPosition}
                />
            </Content>)
        }:null,
        {
            menuItem: (
                <CustomMenuItem key='Agreements' >
                    <MenuItemTab icon='default' title='Vinculaciones' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                {
                        <AllConvetions
                            tabFilter='Agreements'
                            navigate={navigate}
                            filterPosition={props.filterPosition}
                        />
                }
            </Content>)
        },
        props.userRole!=='Operador' && {
            menuItem: (
                <CustomMenuItem key='Logs' >
                    <MenuItemTab icon='default' title='Seguimiento' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                {
                        <AllLogs
                            tabFilter='Logs'
                            navigate={navigate}
                            filterPosition={props.filterPosition}
                        />
                }
            </Content>)
        }
    ]
    : 
    [
        props.userRole==='Operador' ? {
            menuItem: (
                <CustomMenuItem key='Details'additionalStyles={props.userRole!=='Operador' && css`
                grid-column: 1 / 3;
                grid-row: 1;
                border-bottom: 4px solid #E4E4E4 !important;
              `}>
                    <MenuItemTab icon='default' title='Detalles' />
                </CustomMenuItem>
            ),
            render: () => (
               <DetailClientYProvider/>
            )
        }:null,
        props.userRole!=='Operador' && {
            menuItem: (
                <CustomMenuItem key='users' disabled={!props.showUsers}>
                    <MenuItemTab icon='default' title='Usuarios' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                <AllUsers
                    tabFilter='users'
                    navigate={navigate}
                    filterPosition={props.filterPosition}
                />
            </Content>)
        },
        {
            menuItem: (
                <CustomMenuItem key='Agreements' >
                    <MenuItemTab icon='default' title='Vinculaciones' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                {
                        <AllConvetions
                            tabFilter='Agreements'
                            navigate={navigate}
                            filterPosition={props.filterPosition}
                        />
                }
            </Content>)
        },
        props.userRole!=='Operador' && {
            menuItem: (
                <CustomMenuItem key='logs'>
                    <MenuItemTab icon='default' title='Seguimiento' />
                </CustomMenuItem>
            ),
            render: () => (<Content>
                <AllLogs
                    tabFilter='logs'
                    navigate={navigate}
                    filterPosition={props.filterPosition}
                />
            </Content>)
        }
    ]


    const clearPanes = (panes) => {

        const filteredPanes = panes.filter((pane) => pane !== null);
        return filteredPanes;
        
    }
    
    return (
        <MainSegment>
            {props.userRole!=='Operador' && props.isResponsive && <CollapsableDetailClient collapsable={true}/>}
            <CustomTab
                menu={{ secondary: true, pointing: true }}
                panes={clearPanes(panes)}
            />
        </MainSegment>
    )
}

export default TabDetailClient